/* eslint-disable */
import React, { useRef, useContext, useState, useEffect, createRef } from "react";
import { useLocation } from "react-router-dom";
import { MapLayer, MapConsumer, MapContainer, Marker, Polyline, TileLayer, useMapEvent, Pane, Tooltip, Popup, Polygon, Circle } from 'react-leaflet'
import ReactLeafletDriftMarker from "react-leaflet-drift-marker";
import { LocationContext } from "../../contexts/location-context";
import { MapContext } from "../../contexts/map-context";
import { MissionContext } from "../../contexts/mission-context";
import { ADSBContext } from "../../contexts/adsb-context";
import { TileProviderSelector } from "./components/tile-provider-selector/tile-provider-selector";
import AntPath from "./ant-path";
import icons from "../../helpers/icons";
import { AircraftContext } from "../../contexts/aircraft-context";
// import { AircraftIndicator } from "./components/aircraft-indicator/aircraft-indicator";
import { RiskAssessmentContext } from "../../contexts/risk-assessment-context";
import { ToggleMapOptions } from "./components/toggle-map-options/toggle-map-options";
import { useLeafletContext } from "@react-leaflet/core";

import L from "leaflet";
import "leaflet-geotiff-2";
import "leaflet-geotiff-2/dist/leaflet-geotiff-plotty";
import { ToggleButton } from "../buttons/toggle-button/toggle-button";
import { Check, Close, MarginTwoTone, ZoomIn, ZoomInMap, ZoomOut, ZoomOutMap } from "@mui/icons-material";
import UtilityService from "../../helpers/utility-service";
import { ConfigContext } from "../../contexts/config-context";
import { CenterAircraft } from "../../screens/cockpit-screen/components/center-aircraft/center-aircraft";
import { ZoomControlButton } from "../../screens/cockpit-screen/components/zoom-control-button/zoom-control-button";
import { ToggleDetailedView } from "./components/toggle-detailed-view/toggle-detailed-view";
import { RIDContext } from "../../contexts/rid-context";
import DriftMarker from "leaflet-drift-marker/lib";
import { FileSystemContext } from "../../contexts/file-system-context";
import { Waypoint } from "../../helpers/waypoint";
import { CockpitContext } from "../../contexts/cockpit-context";
import { HeadingCompass } from "../heading-compass/heading-compass";

// import oradeaFacilityMap from "../../assets/map-layers/oradea_facility_map.tiff";

const GeoTiffLayer = (props) => {
	const context = useLeafletContext()
	const { url, minValue, maxValue, options, colorScale } = props


	useEffect(() => {
		// fromUrl(url).then(res => res.getImage().then(img => { console.log(img.getWidth(), img.getHeight()); img.readRasters().then(rasters => { const [red, green, blue] = rasters; console.log(red.length / 98)})}));//console.log(img.getWidth(), img.getHeight(), img.getTileWidth(), img.getTileHeight())));

		const container = context.layerContainer || context.map
		const renderer = new L.LeafletGeotiff.Plotty({ applyDisplayRange: true, displayMin: minValue, displayMax: maxValue, clampLow: true, clampHigh: true, colorScale: colorScale || 'viridis' });
		const tiffLayer = new L.LeafletGeotiff(url, { ...options, opacity: 0.7, renderer, pane: 'risk-distribution-pane' });
		// console.log(tiffLayer);
		// console.log(tiffLayer._image);
		// tiffLayer._image.style = { opacity: 0.7 };

		container.addLayer(tiffLayer);

		return () => {
			container.removeLayer(tiffLayer)
		}
	}, [url])

	return null
}

export const MapDisplay = ({ children, tileSelectorPosition }) => {
	const {
		selectedTileProvider: tileProvider,
		defaultMapCenter,
		defaultZoom,
		setBounds,
		setZoom,
		currentTool,
		mapRef,
		moveToLocation,
		groundRiskPointsVisible,
		markerLabelsVisible,
		adsbVisible,
		facilityMapsVisible,
		customLocation,
		clearCustomLocation,
		facilityMapPolygons,
		zoom,
		precipitationVisible,
		cloudsVisible,
	} = useContext(MapContext);

	const { allMissions } = useContext(FileSystemContext);

	const { visibleFlights: RIDFlights } = useContext(RIDContext);

	const {
		riskAssessmentPoints,
		evaluateGroundRisk,
		getGroundImpactDensity,
		groundImpactDensity,
		sampledPointIndex,
		currentDensityType,
	} = useContext(RiskAssessmentContext);

	const { weatherOverlays } = useContext(ConfigContext);

	const { adsbData } = useContext(ADSBContext)

	const { location } = useContext(LocationContext);
	const currentLocation = useLocation();

	const page = currentLocation.pathname;


	const {
		currentPath: {
			waypoints: pathWaypoints,
		},
		rallyPoints,
		addWaypoint,
		updateWaypoint,
		addRallyPoint,
		updateRallyPoint,
		altitudeDatum,
		
		// selectedPath,
		// selectPath,
	} = useContext(MissionContext);
	const {
		selectedPath,
		selectPath,
		availableMissions,
		selectToPoint,
		selectedToPoint,
	} = useContext(CockpitContext);
	// console.log(availableMissions);

	const aircraftMarkers = useRef({});
	const ridAircraftMarkers = useRef({});
	const currentAircraftPaths = useRef({});
	const ridAircraftPaths = useRef({});
	const directPaths = useRef({});
	const targetPaths = useRef({});

	const { setSelectedAircraft, selectedAircraft, connectedAircraft } = useContext(AircraftContext);
	const currentAircraft = useRef(null);
	const [selectedFlightPath, setSelectedFlightPath] = useState(null);
	const [missionRallyPoints, setMissionRallyPoints] = useState(null);
	const [uploadedMissionWaypoints, setUploadedMissionWaypoints] = useState({});
	const [uploadedRallyItems, setUploadedRallyItems] = useState({});
	const [headedPosition, setHeadedPosition] = useState(null);
	const [currentAircraftPosition, setCurrentAircraftPosition] = useState(null);
	const [currentOrbitDraft, setCurrentOrbitDraft] = useState(null);

	let intersectingFacilityFields = [];
	if (facilityMapPolygons != null && pathWaypoints.length > 0) {
		intersectingFacilityFields = UtilityService.findIntersectingFacilityFields(facilityMapPolygons, pathWaypoints);
	}

	const subscribedAircraft = useRef({});
	const subscriptions = useRef([]);

	useEffect(() => {
		for (const flight of RIDFlights) {
			const flightId = flight.id;
			const acMarker = ridAircraftMarkers.current[flightId]

			if (acMarker != null) {
				const lastReport = (flight.reports || [])[0];
				if (lastReport != null && !flight.stale) {
					acMarker.setIcon(icons.map.aircraftMarkerIconRotatable(lastReport.heading, 'white'));
					try {
						acMarker.setLatLng({ lat: lastReport.point.coordinates[1], lng: lastReport.point.coordinates[0] });
					} catch (err) {
						console.log('LatLng err 1:', err);
					}
				} else {
					// if (flight.stale) {

					// }
				}
			}
		}
	}, [RIDFlights]);

	useEffect(() => {
		for (const ac of Object.values(aircraftMarkers.current)) {
			if (!connectedAircraft.includes(ac)) {
				if (subscriptions.current[ac.connectionId] != null) {
					subscriptions.current[ac.connectionId](); // Unsubscribe
				}
				delete aircraftMarkers.current[ac.connectionId];
				delete currentAircraftPaths.current[ac.connectionId];
				delete subscribedAircraft.current[ac.connectionId];
				delete directPaths.current[ac.connectionId];
				delete targetPaths.current[ac.connectionId];
			}
		}

		for (const aircraft of connectedAircraft) {
			if (aircraft != null && selectedAircraft != null && subscribedAircraft[aircraft.connectionId] == null) {
				console.log(aircraft.connectionId);
				subscribedAircraft.current[selectedAircraft.connectionId] = aircraft

				// if (aircraft != currentAircraft.current) {
				// 	for (const sub of subscriptions.current) {
				// 		sub();
				// 		subscriptions.current = [];
				// 	}
				// }

				aircraft.generalState.subscribe(() => {
					const { isArmed } = aircraft.generalState.getState();
					const marker = aircraftMarkers.current[aircraft.connectionId];
					const position = aircraft.positionState.getState();
					const { heading } = position;

					if (isArmed) {
						marker.setIcon(icons.map.aircraftMarkerIconRotatable(heading, aircraft.color))
					} else {
						marker.setIcon(icons.map.aircraftMarkerDisarmedIconRotatable(heading, aircraft.color));
					}
				})

				setCurrentOrbitDraft(aircraft.positionState.orbitDraft);

				currentAircraft.current = aircraft;
				subscriptions.current[aircraft.connectionId] = aircraft.positionState.subscribe(() => {
					const position = aircraft.positionState.getState();

					setCurrentOrbitDraft(position.orbitDraft);
					// console.log(aircraft.connectionId);
					if (aircraftMarkers.current[aircraft.connectionId] != null) {
						const { latitude, longitude, heading, path, pendingDirectPoint, targetPosition } = position;
						const marker = aircraftMarkers.current[aircraft.connectionId];
						const { isArmed } = aircraft.generalState.getState();
						try {
							marker.setLatLng({ lat: latitude, lng: longitude });
						} catch (err) {
							console.log('LatLng err 2:', err);
						}

						if (isArmed) {
							marker.setIcon(icons.map.aircraftMarkerIconRotatable(heading, aircraft.color))
						} else {
							marker.setIcon(icons.map.aircraftMarkerDisarmedIconRotatable(heading, aircraft.color));
						}

						if (currentAircraftPaths.current[aircraft.connectionId] != null) {
							const currentPathPolyline = currentAircraftPaths.current[aircraft.connectionId];
							try {
								currentPathPolyline.setLatLngs(
									path != null && path.length > 1 ?
										path.map(el => ({ lat: el.latitude || 0, lng: el.longitude || 0 }))
										:
										[[0, 0], [0, 0]]
								);
							} catch (err) {
								console.log('LatLng err 3:', err);
							}
						}

						if (directPaths.current[aircraft.connectionId] != null) {
							const currentDirectPathPathPolyline = directPaths.current[aircraft.connectionId];

							if (pendingDirectPoint != null) {
								try {
									currentDirectPathPathPolyline.setLatLngs([[latitude || 0, longitude || 0], [pendingDirectPoint.latitude || 0, pendingDirectPoint.longitude || 0]]);
								} catch (err) {
									console.log('LatLng err 4:', err);
								}
							} else {
								try {
									currentDirectPathPathPolyline.setLatLngs([[latitude || 0, longitude || 0], [latitude || 0, longitude || 0]]);
								} catch (err) {
									console.log('LatLng err 5:', err);
								}
							}
						}

						if (targetPaths.current[aircraft.connectionId] != null) {
							const currentTargetPathPathPolyline = targetPaths.current[aircraft.connectionId];


							if (targetPosition != null) {
								// console.log(targetPosition);
								currentTargetPathPathPolyline.setLatLngs([[latitude || 0, longitude || 0], [targetPosition.latitude || 0, targetPosition.longitude || 0]]);
							} else {
								currentTargetPathPathPolyline.setLatLngs([[latitude || 0, longitude || 0], [latitude || 0, longitude || 0]]);
							}
						}
					}
					// setCurrentAircraftPosition(position);
				})


				const { selectedFlightPath: selectedPath, rallyPoints: selectedRallyPoints, uploadedMissionItems } = aircraft.missionState.getState();
				setSelectedFlightPath(selectedPath);
				setMissionRallyPoints(selectedRallyPoints);

				uploadedMissionWaypoints[aircraft.connectionId] = uploadedMissionItems.mission;
				uploadedRallyItems[aircraft.connectionId] = uploadedMissionItems.rally;

				setUploadedMissionWaypoints({ ...uploadedMissionWaypoints });
				setUploadedRallyItems({ ...uploadedRallyItems });

				// const aircraft = { ...selectedAircraft };
				// console.log(aircraft.aircraftName);

				aircraft.missionState.subscribe(() => {
					const { selectedFlightPath: selectedPath, rallyPoints: selectedRallyPoints, uploadedMissionItems, isOrbiting } = aircraft.missionState.getState();
					console.log('IS ORBITING: ', isOrbiting);
					console.log('Updated mission');
					if (selectedPath != null) {
						const { waypoints } = selectedPath;
						if (waypoints.length > 0) {
							moveToLocation(waypoints[0]);
						}
					}

					// if (selectedPath != selectedFlightPath) {
					// 	console.log('selectedPath');
					setSelectedFlightPath(selectedPath);
					// }

					// if (missionRallyPoints != selectedRallyPoints) {
					setMissionRallyPoints(selectedRallyPoints);
					// }

					if (uploadedMissionItems.mission != uploadedMissionWaypoints[aircraft.connectionId]) {
						uploadedMissionWaypoints[aircraft.connectionId] = uploadedMissionItems.mission;
						uploadedRallyItems[aircraft.connectionId] = uploadedMissionItems.rally;

						setUploadedMissionWaypoints({ ...uploadedMissionWaypoints });
						setUploadedRallyItems({ ...uploadedRallyItems });
					}

					const { currentSeq } = aircraft.missionState.getState();
					const headedWp = uploadedMissionItems.mission.find(wp => wp.seq == currentSeq);
					if (headedWp != headedPosition) {
						setHeadedPosition(headedWp);
					}
				});
			}
		}
		if (selectedAircraft == null) {
			setSelectedFlightPath(null);
			setMissionRallyPoints(null);
			setUploadedMissionWaypoints({});
			setUploadedRallyItems({});
			setHeadedPosition([]);
		}
	}, [selectedAircraft, connectedAircraft]);

	// Is map centered to user location
	const userLocationLoaded = useRef(false);

	const { pathname } = useLocation();

	const [mapMode, setMapMode] = useState('2D');

	/**
	 * Calculate new map viewport corner coordinates
	 */
	const changeViewport = (map) => {
		if (mapRef.current == null) {
			mapRef.current = map;
		}

		const newBounds = map.getBounds();
		const zoom = map.getZoom();

		const bounds = [{
			latitude: newBounds._northEast.lat,
			longitude: newBounds._northEast.lng,
		}, {
			latitude: newBounds._northEast.lat,
			longitude: newBounds._southWest.lng,
		}, {
			latitude: newBounds._southWest.lat,
			longitude: newBounds._southWest.lng,
		}, {
			latitude: newBounds._southWest.lat,
			longitude: newBounds._northEast.lng,
		}];

		setZoom(zoom);
		setBounds(bounds);
	}

	/**
	 * Location on map selected
	 */
	const locationSelected = ({ lat: latitude, lng: longitude }) => {
		if (pathname === '/mission-planning') {
			if (currentTool === 'edit-path') {
				addWaypoint(latitude, longitude);
			} else if (currentTool === 'edit-rtl') {
				addRallyPoint(latitude, longitude);
			}
		}
	}

	const [gotoLocation, setGotoLocation] = useState(null);
	const locationRightClick = ({ lat: latitude, lng: longitude }) => {
		if ((pathname === '/cockpit' || pathname === 'cockpit-v2') && selectedAircraft != null) {
			setGotoLocation({ latitude, longitude })
		}
	}

	const getPathMidpoints = () => {
		const positions = [];

		for (let i = 1; i < pathWaypoints.length; i += 1) {
			const prevWp = pathWaypoints[i - 1];
			const currWp = pathWaypoints[i];

			positions.push({
				latitude: (prevWp.latitude + currWp.latitude) / 2,
				longitude: (prevWp.longitude + currWp.longitude) / 2,
			});
		}

		return positions;
	}

	const drawPath = (
		waypoints,
		type = 'planning',
		faded = false,
		showTooltip = true,
		aircraftColor,
		pathTitle,
		clearFirstMarker,
		onLndClick,
		isSelectedRoute,
	) => {
		// console.log(faded);
		if (type === 'planning') {
			return (
				<div key="planning-ant-path">
					{
						<AntPath
							key={`path-${currentTool}-${waypoints.map(wp => `${wp.latitude},${wp.longitude}`).join(',')}`}
							positions={waypoints.map(wp => wp.getPosition())}
							options={{
								delay: 5000,
								dashArray: [5, 10],
								weight: 3,
								color: currentTool == 'edit-path' ? "#E981FFFF" : "#FFFFFF",
								pulseColor: "white",
								paused: false,
								reverse: false,
								hardwareAccelerated: true,
							}}
						/>
					}
					{
						(clearFirstMarker ? waypoints.slice(1) : waypoints).map((wp, index) => wp.getMarker({
							onClick: (e, waypoint) => { },
							...(currentTool != 'handTool' && { onPositionUpdated: (e, position) => updateWaypoint(index, { position }) }),
							draggable: pathname === '/mission-planning' && currentTool == 'edit-path',
							index: index + 1,
							markerLabelsVisible,
						}, altitudeDatum, page))
					}
				</div>
			)
		} else {
			return (
				<div key="uploaded-path">
					{
						<AntPath
							key={`uploaded-path-${selectedAircraft}-${aircraftColor}-${waypoints.map(wp => `${wp.latitude},${wp.longitude}`).join(',')}`}
							positions={waypoints != null && waypoints.length > 1 ? waypoints.map(wp => wp.getPosition()) : [[0, 0], [0, 0]]}
							// color={ `${aircraftColor}${faded ? '55' : 'AA'}` || "#FFFFFFAA" }
							options={{
								delay: 10000,
								dashArray: [5, 5],
								weight: 3,
								color: `${aircraftColor}${faded ? '55' : 'FF'}`,
								pulseColor: faded ? '#FFFFFF88' : 'white',
								paused: false,
								reverse: false,
								hardwareAccelerated: true,
							}}

						// options={{
						// 	weight: 3,
						// 	color: "#FFFFFF",
						// }}
						/>
					}
					{
						(clearFirstMarker ? waypoints.slice(1) : waypoints).map((wp, index) => wp.getMarker({
							onClick: (e, waypoint) => {
								if (onLndClick) {
									onLndClick(waypoint);
								}
							},
							...(currentTool != 'handTool' && { onPositionUpdated: (e, position) => updateWaypoint(index, { position }) }),
							draggable: pathname === '/mission-planning' && currentTool == 'edit-path',
							index: index + 1,
							pathTitle: index == (clearFirstMarker ? waypoints.length - 2 : waypoints.length - 1) && pathTitle,
							markerLabelsVisible: showTooltip && markerLabelsVisible,
							opacity: faded ? 0.5 : 1,
							isSelectedRoute,
						}, altitudeDatum, page))
					}
				</div>
			)
		}
	}

	// Center map by user location
	if (!userLocationLoaded.current && location != null) {
		if (mapRef.current != null) {
			setTimeout(() => {
				userLocationLoaded.current = true;
				mapRef.current.panTo(new L.LatLng(location.latitude, location.longitude));
			}, 100);
		}
	}


	const quadIcon = icons.map.quadIcon;
	const quadHeadingIcon = icons.map.quadHeadingIcon;
	const heliIcon = icons.map.heliIcon;
	const heliHeadingIcon = icons.map.heliHeadingIcon;
	const gliderIcon = icons.map.gliderIcon;
	const gliderHeadingIcon = icons.map.gliderHeadingIcon;
	
	return (
		<div
			className="map-display"
			key="map-display"
		>
			<div className={`cockpit-map-tools ${pathname === '/cockpit-v2' && 'left'} ${pathname === '/fleet-management' && 'right'}  ${selectedPath && 'up'}`}>
				{(pathname === '/cockpit' || pathname === '/cockpit-v2') && selectedAircraft && selectedAircraft.generalState.getState().isConnected && <CenterAircraft />}
				<ZoomControlButton icon={<ZoomIn onClick={() => mapRef.current.zoomIn()} />} />
				<ZoomControlButton icon={<ZoomOut onClick={() => mapRef.current.zoomOut()} />} />
			</div>
			{/* <ToggleDetailedView/> */}
			{/* <HeadingCompass/> */}
			<ToggleMapOptions key="toggle-map-options-component" position={tileSelectorPosition} className={`${pathname === '/cockpit-v2' && 'cockpit-left-panel-eye'} ${selectedPath && 'up'}`}/>
			<TileProviderSelector key="tile-provider-selector-component" position={tileSelectorPosition} className={`${pathname === '/cockpit-v2' && 'cockpit-left-panel-map'}  ${selectedPath && 'up'}`} />
			<MapContainer
				key='map-container'
				style={{
					height: '100%',
					cursor: 'pointer',
				}}
				center={[
					defaultMapCenter.latitude,
					defaultMapCenter.longitude
				]}
				zoom={defaultZoom}
				scrollWheelZoom={true}
				zoomControl={false}
				whenCreated={(map) => changeViewport(map)}
			>
				<MapConsumer key="map-consumer">
					{(map) => {
						useMapEvent('load', () => {
							changeViewport(map)
						});

						useMapEvent('moveend', () => {
							changeViewport(map)
						});

						useMapEvent('dblclick', (e) => {
							locationRightClick(e.latlng.wrap())
						})

						useMapEvent('click', (e) => {
							locationSelected(e.latlng.wrap())
						})

						useMapEvent('contextmenu', (e) => {
							locationRightClick(e.latlng.wrap())
						})

						return null
					}}
				</MapConsumer>

				<TileLayer
					className={`tile-layer ${tileSelectorPosition}`}
					key={`active-tile-layer-${tileProvider.uri}`}
					// attribution={tileProvider.attribution}
					url={tileProvider.uri}
					maxNativeZoom={18}
					maxZoom={24}
				/>

				<Pane key="weather-pane" name="weather-pane" style={{ zIndex: 300 }}>
					{
						precipitationVisible &&
						<TileLayer
							key="precipitation-tile-layer"
							url={weatherOverlays.precipitation.uri}
						/>
					}

					{
						cloudsVisible &&
						<TileLayer
							key="clouds-tile-layer"
							url={weatherOverlays.clouds.uri}
						/>
					}
				</Pane>



				{children}

				<Pane key="risk-distribution-pane" name="risk-distribution-pane" style={{ zIndex: 300 }}>
					{
						pathname === '/mission-planning' &&
						<div key="geotiff-risk-overlay">
							{
								groundImpactDensity != null && (
									<GeoTiffLayer key="geotiff-tile" url={groundImpactDensity.url} minValue={groundImpactDensity.minValue} maxValue={groundImpactDensity.maxValue} />
								)
							}
						</div>
					}
				</Pane>

				<Pane key="goto-location" name="goto-location" style={{ zIndex: 300 }}>
					{
						gotoLocation != null &&
						<Popup zoomAnimation={false} key={`${gotoLocation.latitude, gotoLocation.longitude}`} position={[gotoLocation.latitude, gotoLocation.longitude]} onClose={() => setGotoLocation(null)}>
							<a href="#" onClick={() => {
								setGotoLocation(null);
								selectedAircraft.gotoWaypoint(gotoLocation.latitude, gotoLocation.longitude)
							}}>
								Fly here
							</a>
							<br/>
							<a href="#" onClick={() => {
								setGotoLocation(null);
								selectedAircraft.createOrbit(gotoLocation.latitude, gotoLocation.longitude)
							}}>
								Orbit around
							</a>
						</Popup>
					}
				</Pane>

				<Pane key="path-pane" name="path-pane" style={{ zIndex: 500 }}>
					{/* Mission planning screen */}
					{
						pathname === '/mission-planning' &&
						<>

							{/* Current path */}
							{drawPath(pathWaypoints, 'planning')}

							{/* Path midpoints */}
							{
								currentTool == 'edit-path' &&
								getPathMidpoints().map((point, index) => (
									<Marker
										key={`midpoint-marker-${index}`}
										eventHandlers={{
											click: (e) => {
												addWaypoint(point.latitude, point.longitude, index + 1);
											}
										}} position={[point.latitude, point.longitude]} icon={icons.map.addButtonIcon} />
								))
							}

							{/* Rally points */}
							{rallyPoints.map((rp, index) => rp.getMarker({
								index: index + 1,
								draggable: currentTool == 'edit-rtl',
								markerLabelsVisible,
								onPositionUpdated: (e, position) => updateRallyPoint(index, { position })
							}))}

							{/* Ground risk points */}
							{
								groundRiskPointsVisible && riskAssessmentPoints && riskAssessmentPoints.filter(point =>
									point.ballisticDescent > 0 || point.uncontrolledGlide > 0 || point.parachuteDescent > 0
								).map((point, index) => (
									<Marker
										key={`risk-point-marker-${index}`}
										eventHandlers={{
											// click: () => getGroundImpactDensity(index)
										}}
										position={[point.lat, point.lon]} icon={icons.map.riskPoint(point.groundRiskScore || 50, sampledPointIndex == index, point)} >
										<Popup key={`risk-point-marker-popup-${index}`} direction="bottom" offset={[0, -20]}>
											{point.ballisticDescent > 0 && <ToggleButton className='dark' label={<span><b>Ballistic descent:</b> {point.ballisticDescent.toPrecision(3)}</span>} defaultState={currentDensityType === 'ballistic_descent'} onChange={() => getGroundImpactDensity(index, 'ballistic_descent')} />}
											{point.uncontrolledGlide > 0 && <ToggleButton className='dark' label={<span><b>Uncontrolled glide:</b> {point.uncontrolledGlide.toPrecision(3)}</span>} defaultState={currentDensityType === 'uncontrolled_glide'} onChange={() => getGroundImpactDensity(index, 'uncontrolled_glide')} />}
											{point.parachuteDescent > 0 && <ToggleButton className='dark' label={<span><b>Parachute descent:</b> {point.parachuteDescent.toPrecision(3)} </span>} defaultState={currentDensityType === 'parachute_descent'} onChange={() => getGroundImpactDensity(index, 'parachute_descent')} />}
										</Popup>
									</Marker>
								))
							}
						</>
					}

					{/* Cockpit screen */}
					{
						(pathname === '/cockpit' || pathname === '/cockpit-v2') &&
						<>
							{/* Selected Path */}
							{selectedFlightPath && selectedFlightPath.waypoints.length > 0 && drawPath(selectedFlightPath.waypoints, 'candidate-path', true)}

							{/* Rally points */}
							{selectedFlightPath != null && missionRallyPoints && missionRallyPoints.map((rp, index) => rp.getMarker({
								index: index + 1,
								draggable: false,
								markerLabelsVisible,
								opacity: 0.5,
							}, page))}
						</>
					}

					{
						pathname === '/cockpit-v2' &&
						<>
							{/* Available missions */}
							{availableMissions != null &&
								availableMissions.takeOffPoints.map(top =>
									top.toPoint.getMarker({
										markerLabelsVisible: true,
										draggable: false,
										label: 'T/O',
										onClick: () => selectToPoint(top)
									}, 'AGL', pathname)
								)
							}


							{selectedToPoint != null &&
								selectedToPoint.paths.map(path => (
									drawPath(
										path.waypoints,
										'',
										false,
										true,
										path == selectedPath ? '#0000FF' : '#FFFFFF',
										`${path.mission}:${path.title}`,
										true,
										() => selectPath(path),
										path == selectedPath,
									)
								))
							}
						</>
					}


					{/* Home screen / Cockpit screen */}
					{
						(pathname === '/home' || pathname === '/cockpit' || pathname === '/cockpit-v2') &&
						selectedAircraft != null && connectedAircraft.map(aircraft => (
							<div className="uploaded-mission-items" key={`${selectedAircraft.connectionId}-${aircraft.connectionId}-selected`}>
								{/* Uploaded Path */}
								{
									uploadedMissionWaypoints[aircraft.connectionId] && uploadedMissionWaypoints[aircraft.connectionId].length > 0
									&& drawPath(uploadedMissionWaypoints[aircraft.connectionId], 'uploaded', selectedAircraft.connectionId != aircraft.connectionId, selectedAircraft.connectionId == aircraft.connectionId, aircraft.color)
								}

								{/* Headed path */}
								{/* { uploadedMissionWaypoints && 
											uploadedMissionWaypoints.length > 0 &&  
											selectedAircraft != null && 
											(
												( selectedAircraft.generalState.getState().customMode == 'AUTO' && headedPosition)
											) &&
											<AntPath positions={[
												[
													currentAircraftPosition.latitude, currentAircraftPosition.longitude
												], 
												[
													headedPosition.latitude,
													headedPosition.longitude,
												]
											]
										}
											delay={200}
										/>
										} */}

								{/* Rally points */}
								{uploadedRallyItems[aircraft.connectionId] && uploadedRallyItems[aircraft.connectionId].map((rp, index) => rp.getMarker({
									index: index + 1,
									draggable: false,
									markerLabelsVisible: markerLabelsVisible && selectedAircraft.connectionId == aircraft.connectionId,
								}))}
							</div>
						))
					}

					{
						connectedAircraft.map(aircraft =>
							<div key="current-aircraft-position-wrapper">
								<Polyline ref={(ref) => { if (ref != null && currentAircraftPaths.current[aircraft.connectionId] != ref) { currentAircraftPaths.current[aircraft.connectionId] = ref } }} key={`aircraft-path-${aircraft.connectionId}`} positions={
									aircraft.positionState.getState().path.length > 1 ?
										aircraft.positionState.getState().path.map(el => [el.latitude || 0, el.longitude || 0])
										:
										[[0, 0], [0, 0]]
								} color={aircraft.color} />

								<AntPath
									key={`direct-${aircraft.connectionId}`}
									ref={(ref) => { if (ref != null && directPaths.current[aircraft.connectionId] != ref) { directPaths.current[aircraft.connectionId] = ref } }}
									positions={[
										[
											aircraft.positionState.getState().latitude || 0,
											aircraft.positionState.getState().longitude || 0,
										],
										[
											(aircraft.positionState.getState().pendingDirectPoint || {}).latitude || aircraft.positionState.getState().latitude || 0,
											(aircraft.positionState.getState().pendingDirectPoint || {}).longitude || aircraft.positionState.getState().longitude || 0,
										]
									]

									}
									options={{
										delay: 5000,
										dashArray: [5, 10],
										weight: 3,
										color: "#FFFFFF",
										pulseColor: "white",
										paused: false,
										reverse: false,
										hardwareAccelerated: true,
									}}
								/>
								<AntPath
									key={`target-${aircraft.connectionId}`}
									ref={(ref) => { if (ref != null && targetPaths.current[aircraft.connectionId] != ref) { targetPaths.current[aircraft.connectionId] = ref } }}
									positions={[
										[
											aircraft.positionState.getState().latitude || 0,
											aircraft.positionState.getState().longitude || 0,
										],
										[
											(aircraft.positionState.getState().targetPosition || {}).latitude || aircraft.positionState.getState().latitude || 0,
											(aircraft.positionState.getState().targetPosition || {}).longitude || aircraft.positionState.getState().longitude || 0
										]
									]

									}
									options={{
										delay: 5000,
										dashArray: [5, 10],
										weight: 3,
										color: aircraft.color,
										pulseColor: "white",
										paused: false,
										reverse: false,
										hardwareAccelerated: true,
									}}
								/>

								<Marker
									ref={(ref) => { if (ref != null && aircraftMarkers.current[aircraft.connectionId] != ref) { aircraftMarkers.current[aircraft.connectionId] = ref } }}
									key={`aircraft-${aircraft.connectionId}`}
									duration={100}
									eventHandlers={{
										click: () => {
											const { latitude, longitude } = aircraft.positionState.getState();
											moveToLocation({ latitude, longitude });
											setSelectedAircraft(aircraft);
										}
									}}
									position={{
										lat: aircraft.positionState.getState().latitude || 0,
										lng: aircraft.positionState.getState().longitude || 0
									}}
									icon={
										aircraft.generalState.getState().isArmed ?
											icons.map.aircraftMarkerIconRotatable(aircraft.positionState.getState().heading, aircraft.color)

											:
											icons.map.aircraftMarkerDisarmedIconRotatable(aircraft.positionState.getState().heading || 0, aircraft.color)
										// 	icons.map.aircraftMarkerIconRotatable(currentAircraftPosition.heading)
										// 	:
										// 	icons.map.aircraftMarkerDisarmedIconRotatable(currentAircraftPosition.heading)
									}
								>

									<Tooltip
										className="marker-tooltip"
										direction="bottom"
										opacity={1}
										offset={[0, 0]}
										permanent
									>
										<span style={{ color: aircraft.color }}>{aircraft.aircraftName}</span>
									</Tooltip>
								</Marker>
							</div>
						)
					}
				</Pane>
				{/* <Pane key="aircraft-marker-pane" name="aircraft-marker-pane" style={{ zIndex: 500 }}>
							{ connectedAircraft.map(aircraft => (
								<AircraftIndicator aircraft={aircraft}/>
							))}
						</Pane> */}
						

				<Pane key="orbit-draft-pane" name="orbit-draft-pane" style={{ zIndex: 300 }}>
					{
						currentOrbitDraft && 
						<>
						<Marker 
								zIndexOffset={100}
								draggable
								opacity={0.7}
								eventHandlers={{
									drag: (e) => {
										selectedAircraft.updateOrbit(e.target.getLatLng())
									  },
								}}
								icon={icons.map.rtlBlueIcon} 
								position={UtilityService.displacementPoint(
									{ 
										latitude: currentOrbitDraft.latitude, 
										longitude: currentOrbitDraft.longitude 
									},
									currentOrbitDraft.radius
								)}
							>
								<Popup>
								<Close onClick={() => selectedAircraft.clearOrbit()}/> <Check onClick={() => selectedAircraft.startOrbit()}/>
								</Popup>
							</Marker>
							<Circle 
								fill={false} 
								color="white" 
								dashArray="5" 
								opacity={0.7}
								dashOffset="5" 
								center={[currentOrbitDraft.latitude, currentOrbitDraft.longitude]} radius={currentOrbitDraft.radius}
							>
								<Tooltip permanent>
									{currentOrbitDraft.radius}m
								</Tooltip>
								
							</Circle>
							
						</>
					}
				</Pane>
				<Pane key="adsb-pane" name="adsb-pane" style={{ zIndex: 500 }}>
					{
						adsbVisible ? adsbData.filter(ac => !ac.on_ground && ac.latitude != null && ac.longitude != null).map(aircraft => (
							// aircraft.marker.getMarker({ 
							// 	heading: aircraft.true_track, 
							// 	velocity: aircraft.velocity,
							// 	callsign: aircraft.callsign, 
							// 	vertical_rate: aircraft.vertical_rate,
							// 	markerLabelsVisible, 
							// 	country: aircraft.origin_country,
							// 	altitude: Math.round(aircraft.geo_altitude || aircraft.baro_altitude || 0),
							// }))
							<Marker
								// duration={100} 
								className="adsb-aircraft-marker"
								key={`adsb-aircraft-marker-${aircraft.id}`}
								draggable={false}
								position={[aircraft.latitude, aircraft.longitude]}
								icon={icons.map.adsbAircraft(aircraft.true_track)}
							>
								{markerLabelsVisible &&
									<Tooltip
										key="adsb-marker-tooltip"
										className="marker-tooltip"
										direction="bottom"
										opacity={1}
										// offset={[-15,15]}
										permanent
									>
										<p className="waypoint-tooltip">
											{<span>{aircraft.callsign}<br />{Math.round(aircraft.geo_altitude || aircraft.baro_altitude || 0)} AMSL</span>}
										</p>
									</Tooltip>
								}

								<Popup key="adsb-marker-popup">
									<p><b>Altitude:</b> {Math.round(aircraft.geo_altitude || aircraft.baro_altitude || 0)} AMSL</p>
									<p><b>Speed:</b> {aircraft.velocity} KTS</p>
									<p><b>Vertical Rate:</b> {aircraft.vertical_rate > 0 ? '+' + aircraft.vertical_rate : aircraft.vertical_rate} m/s</p>
									<p><b>Country:</b> {aircraft.country}</p>
								</Popup>
							</Marker>
						)
						)
							: null
					}
				</Pane>


				<Pane key="search-locations" name="search-locations" style={{ zIndex: 500 }}>
					{
						customLocation &&
						<Marker key="search-location-marker" position={[customLocation.latitude, customLocation.longitude]} icon={icons.map.rtlBlueIcon}>
							<Tooltip
								key="search-location-marker-tooltip"
								className="marker-tooltip"
								direction="right"
								opacity={1}
								offset={[-15, 30]}
								permanent
							>
								{customLocation.label}
							</Tooltip>
							<Popup key="search-location-marker-popup">
								<p onClick={() => clearCustomLocation()}>Remove Marker</p>
								{
									pathname === '/mission-planning' &&
									<>
										<p onClick={() => { addWaypoint(customLocation.latitude, customLocation.longitude, null, customLocation.label); clearCustomLocation() }}>Convert to Waypoint</p>
										<p onClick={() => { addRallyPoint(customLocation.latitude, customLocation.longitude); clearCustomLocation() }}>Convert to Rally Point</p>
									</>
								}
							</Popup>
						</Marker>
					}
				</Pane>

				{facilityMapsVisible && facilityMapPolygons != null && zoom > 12 &&
					<Pane key="facility-maps" name="facility-maps" style={{ zIndex: 300 }}>
						{facilityMapPolygons.map(poly => (
							<Polygon key={Math.random()} positions={poly.coordinates}>
								<Tooltip direction='center' className="facility-polygon-tooltip">
									{Math.floor(poly.value * 0.3048)}
								</Tooltip>
							</Polygon>
						))}
						{/* <GeoTiffLayer url={'/map-layers/oradea_facility_map.tiff'} minValue={0} maxValue={10000} colorScale="greys"/> */}
					</Pane>
				}

				{
					pathWaypoints.length > 0 && facilityMapPolygons &&
					<Pane key="conflicted-facility-maps" name="conflicted-facility-maps" style={{ zIndex: 310 }}>
						{intersectingFacilityFields.filter(el => el.violation).map((poly, index) => (
							<Polygon key={Math.random()} positions={poly.coordinates} color="red" className="facility-polygon-collision">
								<Tooltip direction='center' permanent={true} className="facility-polygon-collision-tooltip">
									{Math.floor(poly.value * 0.3048)}
								</Tooltip>
							</Polygon>
						))}
					</Pane>
				}

				{RIDFlights != null && RIDFlights.length > 0 &&
					<Pane key="rid-flights-pane" name="rid-flights-pane" style={{ zIndex: 310 }}>
						{
							RIDFlights.map(flight => (
								<>
									{
										console.log(flight.mission.waypoints.map(wp => [wp.point.coordinates[1], wp.point.coordinates[0]]))
									}
									{
										flight.mission.waypoints.length > 1 &&
										<Polyline
											ref={(ref) => { if (ref != null && ridAircraftPaths.current[flight.id] != ref) { ridAircraftPaths.current[flight.id] = ref } }}
											key={`rid-path-${flight.id}`}
											dashArray={[5, 5]}
											positions={flight.mission.waypoints.map(wp => [wp.point.coordinates[1], wp.point.coordinates[0]])}
											color="white"
										>
											<Popup>
												<p>Mission: {flight.mission.title}</p>
												<p>Start time: {flight.mission.start_time}</p>
												<p>End time: {flight.mission.end_time}</p>
											</Popup>
										</Polyline>
										// drawPath(, 'rid', true, false, 'gray')
									}
									{flight.reports != null && !flight.stale && flight.reports.length > 0 && new Date() - new Date(flight.reports[0].timestamp) < 3000 &&
										<Marker
											ref={(ref) => { if (ref != null && ridAircraftMarkers.current[flight.id] != ref) { ridAircraftMarkers.current[flight.id] = ref } }}
											duration={100}
											className="rid-aircraft-marker"
											key={`rid-aircraft-marker-${flight.id}`}
											draggable={false}
											position={[0, 0]}
											icon={icons.map.aircraftMarkerIconRotatable(0, 'transparent')}
										>
											<Tooltip
												className="marker-tooltip"
												direction="bottom"
												opacity={1}
												offset={[0, 0]}
												permanent
											>
												<span style={{ color: 'white' }}>{flight.aircraft.registration_number}</span>
												<br />
												<span style={{ color: 'white' }}>ALT {flight.reports[0].altitude} AMSL</span>
											</Tooltip>
										</Marker>
									}
								</>
							))
						}
					</Pane>
				}
				{/* test icons */}
				<Pane>
							<Marker
								icon={heliIcon}
								key={`heli-icon`}
								position={[0,0]}
							></Marker>
						</Pane>
						<Pane>
							<Marker
								icon={heliHeadingIcon}
								key={`heli-heading-icon`}
								position={[0,0.1]}
							></Marker>
						</Pane>
						<Pane>
							<Marker
								icon={quadIcon}
								key={`quad-icon`}
								position={[0.1,0]}
							>
								<Tooltip permanent>Jeeej</Tooltip>
							</Marker>
						</Pane>
						<Pane>
							<Marker
								icon={quadHeadingIcon}
								key={`quad-heading-icon`}
								position={[0.1,0.1]}
							></Marker>
						</Pane>
						<Pane>
							<Marker
								icon={gliderIcon}
								key={`glider-icon`}
								position={[0.2,0]}
							></Marker>
						</Pane>
						<Pane>
							<Marker
								icon={gliderHeadingIcon}
								key={`glider-heading-icon`}
								position={[0.2,0.1]}
							></Marker>
						</Pane>
			</MapContainer>
		</div>
	);
}
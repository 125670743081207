import React, { useContext, useEffect, useRef, useState } from 'react';
import { Panel } from '../../../../shared-components/layout/panel/panel';
import { AvTimer, Timer } from '@mui/icons-material';
import { motion } from "framer-motion";

import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import { Button } from '@mui/material';
import { FlightContext } from '../../../../contexts/flight-context';
import { AircraftContext } from '../../../../contexts/aircraft-context';
import { InterfaceContext } from '../../../../contexts/interface-context';

export const CockpitScheduledFlights = () => {
    // const [toggled, setToggled] = useState(false);
    const {scheduledFlightsPanelVisible, setScheduledFlightsPanelVisible} = useContext(InterfaceContext);

    const { flights } = useContext(FlightContext);
    const { selectedAircraft } = useContext(AircraftContext);
    const [aircraftFlights, setAircraftFlights] = useState([]);
    const [startedFlight, setStartedFlight] = useState(null);
 
    useEffect(() => {
        if (flights != null && flights.length > 0 && selectedAircraft != null) {
            const currentAircraftFlights = flights
                                .filter(fl => new Date(fl.data.startTime).getTime() >= Date.now() - 3600000 && fl.status !== 'FINISHED')
                                .sort((a, b) => new Date(a.data.startTime) - new Date(b.data.startTime));

            setAircraftFlights(currentAircraftFlights);
            setStartedFlight((currentAircraftFlights.find(el => el.status === 'STARTED') || {}).id);
        }
    }, [selectedAircraft, flights]);
// console.log("AF", aircraftFlights, "sf", startedFlight);
    return (
        // <div className={`cockpit-scheduled-flights ${toggled && 'toggled'}`}>
            <motion.div
                key="cockpit-scheduled-flights-panel"
                initial={{ x: 150, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                exit={{ x: 150, opacity: 0 }}
                transition={{ duration: 0.2, ease: "easeOut", delay: 0.3 }}
                className={`cockpit-scheduled-flights ${scheduledFlightsPanelVisible && 'toggled'}`}
            >
                {/* <InfoCard
				key="active-flights-info-card"
				// primaryGradient={"#0F3B60"}
				// secondaryGradient={"#081355"}
			> */}
                <Panel
                    key="scheduled-flights-info-panel"
                    className={`scheduled-flights-info-panel`}
                    title={"Scheduled flights"}
                    icon={<FormatListBulletedIcon />}
                    backgroundColor="#060d1f"
                    expanded={scheduledFlightsPanelVisible}
                    onClick={(e) =>{setScheduledFlightsPanelVisible(!scheduledFlightsPanelVisible);}}//e.stopPropagation()
                >
                    <div className='info-panel-content'>
                        {
                            startedFlight ? 
                            aircraftFlights.filter(fl => fl.id === startedFlight).map(fl => (
                                <CockpitScheduledFlightItem
                                    flightId={fl.id}
                                    title={fl.data.title}
                                    startedFlight={startedFlight}
                                    description={fl.data.description}
                                    startTime={new Date(fl.data.startTime)}
                                />
                            ))
                            :
                            aircraftFlights.length > 0 ? aircraftFlights.map(fl => (
                                <CockpitScheduledFlightItem
                                    flightId={fl.id}
                                    title={fl.data.title}
                                    startedFlight={startedFlight}
                                    description={fl.data.description}
                                    startTime={new Date(fl.data.startTime)}
                                />
                            )) : <span className='no-scheduled-flights'>There are no scheduled flights</span>
                        }
                        {/* <CockpitScheduledFlightItem
                            title='Oradea Flights'
                            description={"Description of flight"}
                            status={'late'}
                            statusText={"Late 3 min"} />
                        <CockpitScheduledFlightItem title='Oradea Flights' description={"Description of flight"} status={'scheduled'} statusText={"2023-01-01 23:23"} />
                        <CockpitScheduledFlightItem title='Oradea Flights' description={"Description of flight"} status={'scheduled'} statusText={"2023-01-01 23:23"} />
                        <CockpitScheduledFlightItem title='Oradea Flights' description={"Description of flight"} status={'scheduled'} statusText={"2023-01-01 23:23"} /> */}
                    </div>
                </Panel>
            </motion.div>
        // </div>
    )
}


export const CockpitScheduledFlightItem = ({ flightId, title, description, onOpenDetails, startTime, startedFlight }) => {
    const { updateFlight } = useContext(FlightContext);
    const [scheduledFlightStarted, setScheduledFlightStarted] = useState(false);
    const [statusText, setStatusText] = useState(null);
    const [status, setStatus] = useState(null);

    const checkInterval = useRef(null);

    useEffect(() => {
        if (checkInterval.current == null) {
            checkInterval.current = setInterval(() => {
                if (startTime.getTime() > (Date.now() + 600000)) {
                    if (status !== 'scheduled') {
                        setStatus('scheduled');
                        setStatusText(`${startTime.toISOString().split(':00.')[0].replace('T', ' ')}`);
                    } 
                } else if (startTime.getTime() > Date.now()) {
                    const message = `EXP IN ${Math.ceil((startTime.getTime() - Date.now()) / 60000)} MIN`;
                    if (status !== 'waiting') {
                        setStatus('waiting');
                    }

                    if (statusText !== message) {
                        setStatusText(message);
                    }
                } else {
                    const message = `LATE ${Math.ceil((Date.now() - startTime.getTime()) / 60000)} MIN`;
                    if (status !== 'late') {
                        setStatus('late');
                    }


                    if (statusText !== message) {
                        setStatusText(message);
                    }
                }
            }, 1000);
        }

        return () => { if (checkInterval.current != null) { clearInterval(checkInterval.current) } }
    }, []);

    const onStartFlight = (id) => {
        console.log('Kurac')
        updateFlight(id, 'STARTED')
    }
    const onEndFlight = (id) => {
        setScheduledFlightStarted(false);
        updateFlight(id, 'FINISHED')
    }
    return (
        <div className='cockpit-scheduled-flight-item'>
            <div className='cockpit-scheduled-flight-item-details'>
                <div className='cockpit-scheduled-flight-item-title-wrap'>
                    <span className='cockpit-scheduled-flight-item-title'>
                        {title}
                    </span>
                    <span className='cockpit-scheduled-flight-item-description'>
                        {description}
                    </span>
                </div>
                <span className='cockpit-scheduled-flight-item-details pointer' onClick={(e) => {e.stopPropagation();onOpenDetails && onOpenDetails('2');}}>
                    View Details
                </span>
            </div>
            <div className='cockpit-scheduled-flight-item-actions'>
                { console.log(startedFlight, flightId)}
                {startedFlight != null && flightId === startedFlight ?
                    <Button
                        disabled={startedFlight != null && flightId !== startedFlight}
                        className={`uppercase cockpit-scheduled-flight-item-button red`}
                        onClick={(e) =>{e.stopPropagation(); onEndFlight(flightId);}}>End flight</Button> :
                    <Button
                        disabled={startedFlight != null && flightId !== startedFlight}
                        className={`uppercase cockpit-scheduled-flight-item-button`}
                        onClick={(e) => {e.stopPropagation();onStartFlight(flightId);}}>
                        Start flight
                    </Button>}
                {statusText &&
                    <div className={`${status == 'late' ? 'red' : status == 'waiting' ? "yellow" : 'white'} cockpit-scheduled-flight-item-status uppercase`}>
                        <AvTimer className='timer-icon' />
                        <span >{statusText}</span>
                    </div>}
            </div>
        </div>
    )
}
import React, { createContext, useEffect, useState } from "react";
import axios from "axios";
// import getIp from "external-ip";
// import ipLocation from "iplocation";

export const LocationContext = createContext();

const LocationContextProvider = ({ children }) => {
	const [location, setLocation] = useState(null);
	
	/**
	 * Get user location based on public IP
	 */
	const getIPLocation = () => {
		axios.get(`https://geolocation-db.com/json/`)
			.then(res => setLocation(res.data))
			.catch(err => console.log(err));
	}

	useEffect(() => {
		getIPLocation();
	}, []);

	return (
		<LocationContext.Provider value={{ location }}>
			{ children }
		</LocationContext.Provider>
	)
}

export default LocationContextProvider;
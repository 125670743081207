import React, { useContext, useEffect, useRef, useState } from 'react';
import { AircraftContext } from '../../../../contexts/aircraft-context';
import { SideMenu } from '../../../../shared-components/containers/side-menu/side-menu';

export const FlightModeSelectorPanel = () => {
    const { selectedAircraft } = useContext(AircraftContext);
    const [flightMode, setFlightMode] = useState(selectedAircraft != null ? selectedAircraft.generalState.getState().customMode : null);
    const currentAircraft = useRef(null);
    const subscriptions = useRef([]);


    useEffect(() => {
        if (selectedAircraft != currentAircraft.current) {
            currentAircraft.current = selectedAircraft;
            
            if (currentAircraft.current != null) {
				for (const sub of subscriptions.current) {
					sub();
					subscriptions.current = [];
				}
			} 

            if (selectedAircraft != null) {
                setFlightMode(selectedAircraft.generalState.getState().customMode);
                subscriptions.current.push(selectedAircraft.generalState.subscribe(() => {
                    setFlightMode(selectedAircraft.generalState.getState().customMode);
                }))
            }
        }
    }, [selectedAircraft])

    return (
        <SideMenu 
			key="flight-mode-selector-panel"
			className="flight-mode-selector-panel"
			direction="left"
		>
            {/* <Stack direction='horizontal' halign='space-between' valign="center"> */}
                <p className='uppercase'>Flight Mode</p>
             
                    <select 
                        className='flight-mode-selector needsclick'
                        
                        value={flightMode}
                        onChange={(e) => selectedAircraft != null && selectedAircraft.setFlightMode(e.target.value)}
                    >
                        <option value='STABILIZE' className='uppercase'>STABILIZE</option>
                        <option value='LOITER' className='uppercase'>LOITER</option>
                        <option value='ALT_HOLD' className='uppercase'>ALTITUDE HOLD</option>
                        <option value='GUIDED' className='uppercase'>GUIDED</option>
                        <option value='AUTO' className='uppercase'>AUTO</option>
                        <option value='LAND' className='uppercase'>LAND</option>
                        <option value='RTL' className='uppercase'>RTL</option>
                        <option value='BRAKE' className='uppercase'>BRAKE</option> 
                    </select>
             
            {/* </Stack> */}
        </SideMenu>
    )
}
import React, { useContext, useState } from 'react';
import { SideMenu } from '../../../../shared-components/containers/side-menu/side-menu';
import { SideMenuItem } from '../../../../shared-components/containers/side-menu/components/side-menu-item/side-menu-item';
import Icons from '../../../../helpers/icons';
import { SkyModal } from '../../../../shared-components/containers/sky-modal/sky-modal';
import { PreflightCheck } from './components/preflight-check/preflight-check';
import { AircraftContext } from '../../../../contexts/aircraft-context';
import { Modal } from '@mui/material';
import { Close } from '@mui/icons-material';

export const CockpitSideMenu = () => {
    const { selectedAircraft } = useContext(AircraftContext);
    const [activePanel, setActivePanel] = useState(false);//selectedAircraft == null && 'preflight-panel');
    
    return(
        <SideMenu 
            key="cockpit-side-menu"
            className="cockpit-side-menu"
            direction="right"
        >
            <SideMenuItem
                onClick={() => setActivePanel(activePanel === "preflight-panel" ? null : "preflight-panel")}
                isActive={activePanel === 'mission-plan'}
                icon={<img src={Icons.regular.aircraftMarkerIcon} alt='aircraft' style={{width: "30px", height:"30px"}}/>}
                title='Connect to Aircraft'
                tooltipPosition='right'
              
                // actions={[
                // 	{
                // 		label: "Load",
                // 		action: () => {},
                // 	},
                // 	{
                // 		label: "Save",
                // 		blink: !isSaved,
                // 		action: () => {},
                // 	}
                // ]}
            >
               
              
            </SideMenuItem>
{/* 
            <SideMenuItem
                hasPanel
                panelTitle="Connect To Aircraft"
                onClick={() => setActivePanel(activePanel == 'mission-pl3an' ? null : 'mission-pl3an')}
                isActive={activePanel === 'mission-pl3an'}
                icon={<img src={Icons.cockpit.takeoff} alt='takeoff' style={{width: "30px", height:"30px"}}/>}
                title='Connect To Aircraft'
                tooltipPosition='right'
            >
                <div>
                    <p>Side menu content</p>
                </div>
            </SideMenuItem>

            <SideMenuItem
                hasPanel
                panelTitle="Connect To Aircraft"
                onClick={() => setActivePanel(activePanel == 'mission-pla2n' ? null : 'mission-pla2n')}
                isActive={activePanel === 'mission-pla2n'}
                icon={<img src={Icons.cockpit.takeoff} alt='takeoff' style={{width: "30px", height:"30px"}}/>}
                tooltipPosition='right'
                title='Connect to Aircraft'
            >
                <div>
                    <p>Side menu content</p>
                </div>
            </SideMenuItem> */}

            {/* Mission plan */}
         <SkyModal 
            key="preflight-panel-modal"
            modalVisible={activePanel == "preflight-panel"}
            disableScrollbar={true}
            onClose={() => setActivePanel(null)} 
            title='Connect To Aircraft' 
            // actionButtons={[{}]}
            className='preflight-panel'
            >
            {/* <Modal
                open={activePanel == "preflight-panel"}
                onClose={() => setActivePanel(null)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                
                className='preflight-panel'
            > */}
                {/* children, 
                actionButtons, 
                modalVisible,
                disableInputs,
                sendInfo,
                editInfo,
                removeItem,
                labelText,
                editButtonsActive, */}
                {/* <>
                    <div className='preflight-modal-panel-header'>
                        <span>Connect To Aircraft</span>
                        <Close/>
                    </div> */}
                    <PreflightCheck onClose={() => setActivePanel(null)}/>
                {/* </>
                </Modal> */}
            </SkyModal>
        </SideMenu>
    )
}
import React, { useContext, useRef, useState } from "react"

// Icons
import CloudIcon from '@mui/icons-material/Cloud';
import WarningIcon from '@mui/icons-material/Warning';
import RefreshIcon from '@mui/icons-material/Refresh';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';

// Components
import { InfoCard } from "../../../../shared-components/containers/info-card/info-card";
import { InfoPanel } from "../../../../shared-components/layout/info-panel/info-panel";

// Contexts
import { WeatherContext } from "../../../../contexts/weather-context";
import UtilityService from "../../../../helpers/utility-service";
import { MapContext } from "../../../../contexts/map-context";
import { IconText } from "../../../../shared-components/layout/icon-text/icon-text";
import ThermostatIcon from '@mui/icons-material/Thermostat';
import { Cloud, Air, ArrowUpward, Opacity, RemoveRedEye, FireExtinguisher, Thermostat, WbSunny } from '@mui/icons-material';

import { Stack } from "../../../../shared-components/layout/stack/stack";
import PerfectScrollbar from 'react-perfect-scrollbar';

/**
 * Sidebar weather information widget
 * @returns 
 */
export const WeatherInformationWidget = () => {
	const { mapCenter, zoom } = useContext(MapContext);
	const { 
		isBusy, 
		currentWeather, 
		reloadWeather, 
		error, 
		lastUpdateTimestamp, 
		shouldUpdateWeather,
	 } = useContext(WeatherContext);
	const reloadInterval = useRef(null);
	const updatedTimeInterval = useRef(null);
	const [now, setNow] = useState(UtilityService.getCurrentTime(true));
	const [activeForecastTab, setActiveForecastTab] = useState('conditions');
	
	let lastUpdateMinutes = 0;

	if (mapCenter != null) {
		if (zoom > 12 && shouldUpdateWeather(mapCenter) && !isBusy) {
		
			// Initialize regular weather update
			setTimeout(() => reloadWeather(mapCenter), 100);

			if (reloadInterval.current != null) {
				clearTimeout(reloadInterval.current);
			}
			reloadInterval.current = setInterval(() => reloadWeather(mapCenter), 600001);
		} 
	}

	// Update age of weather report in minutes		
	lastUpdateMinutes = Math.floor((UtilityService.getCurrentTime(true)	- lastUpdateTimestamp) / 60);

	// Initialize regular report age update
	if (updatedTimeInterval.current == null) {
		updatedTimeInterval.current = setInterval(() => {
			setNow(UtilityService.getCurrentTime(true))
		}, 40000);	
	}

	return (
		<InfoCard 
			className="weather-information-widget"
			primaryGradient={"#0F3B60"}
			secondaryGradient={"#081355"}
		>
			{ isBusy && currentWeather == null? 
				<p>Loading...</p>
				:
				(
					error != null ?
					(
						<InfoPanel
							className='information-dock-panel'
							title='Weather Report'
							actionIcons={[{icon: <RefreshIcon/>, action: () => reloadWeather(mapCenter, true)}]}
							titleIcon={<Cloud/>}
							action={() => reloadWeather(mapCenter, true)} 
							subtitles = {[
								{
									title: (
										<span>Failed to load weather</span>
									),
								}]
							}
						/>
					)
					: currentWeather != null &&
					(<>
						<InfoPanel
							style={{ ...(isBusy && { opacity: 0.7 }) }}
							className='information-dock-panel'
							title='Weather Report'
							actionIcons={[{icon: <RefreshIcon/>, action: () => reloadWeather(mapCenter, true)}]}
							titleIcon={<CloudIcon/>}
							action={() => reloadWeather(mapCenter, true)} 
							subtitles = {error ? 
								[
									{
										title: (
											<span>Failed to load weather</span>
										),
									}
								]
									:
								[
									{
										title: (
											<span>
												Near <span className='bold'>{currentWeather.location.name}</span>
											</span>
										), 
										size: 'medium'
									}, 
									{
										title: (
											<span>
												Updated { lastUpdateMinutes < 1 ? 
													'less than 1 minute ' 
													: 
													(lastUpdateMinutes < 2 ? '1 minute ' 
														: `${lastUpdateMinutes} minutes `)} ago
											</span>
										), 
										size: 'small'
									}
								]}>
						</InfoPanel>
						{ error == null &&
						<>
							<div className='current-weather-info'>
								<Stack direction='vertical'>
									<Stack direction='horizontal' halign='start' valign="center">
										<img className="current-condition-icon" src={`http:${currentWeather.current.condition.icon}`}/>
										<span>{ currentWeather.current.condition.text}</span>
									</Stack>
									<Stack direction='horizontal' halign='space-between' width='100%'>
										<IconText direction='horizontal' icon={<Thermostat/>} text={`${currentWeather.current.temp_c} ºC`}/>
										<IconText direction='horizontal' icon={<Cloud/>} text={`${currentWeather.current.cloud} %`}/>
										<IconText direction='horizontal' icon={<ArrowUpward style={{ transform: `rotate(${currentWeather.current.wind_degree}deg)`}}/>} text={<span className='smallfont'>{currentWeather.current.wind_degree}° <br/> {(currentWeather.current.wind_kph * 0.54).toFixed(1)} kts</span>}/>
									</Stack>
									<Stack direction='horizontal' halign='space-between' width='100%'>
										<IconText direction='horizontal' icon={<Opacity/>} text={`${(currentWeather.current.precip_in * 100).toFixed(1)} %`}/>
										<IconText direction='horizontal' icon={<RemoveRedEye/>} text={`${currentWeather.current.vis_km} Km`}/>
										<IconText direction='horizontal' icon={<FireExtinguisher/>} text={`${currentWeather.current.pressure_mb} mb`}/>

									</Stack>
								</Stack>
								
							</div>

							{
								currentWeather.alerts != null &&
									<InfoPanel 
										className='information-dock-panel weather-information-alerts'
										title='Weather Alerts'
										titleIcon={<WarningIcon className={`${currentWeather.alerts.alert.length > 0 && 'blink warn'}`}/>}
										>
											<PerfectScrollbar  options={{wheelPropagation: false}}>
											{ currentWeather.alerts.alert.length === 0 ? 
												<p>No weather alerts.</p>
												:
												currentWeather.alerts.alert.map((a,index) => (<p key={`weather-headline-${index}`}><b>{ a.headline }</b><br/>{ a.desc }</p>))
											}
											</PerfectScrollbar>
									</InfoPanel>
							}
							{
									currentWeather.forecast != null &&
									<InfoPanel 
										className='information-dock-panel forecast-panel'
										title='Forecast'
										titleIcon={<AccessTimeFilledIcon/>}
										actionIcons={[
											{icon: <WbSunny style={{...(activeForecastTab === 'conditions' && { color: "white", opacity:1 })}}/>, action: () => setActiveForecastTab('conditions')},
											{icon: <Cloud style={{...(activeForecastTab === 'clouds' && { color: "white", opacity:1 })}}/>, action: () => setActiveForecastTab('clouds')},
											{icon: <ArrowUpward style={{...(activeForecastTab === 'wind' && { color: "white", opacity:1 })}}/>, action: () => setActiveForecastTab('wind')},
											{icon: <Opacity style={{...(activeForecastTab === 'precipitation' && { color: "white", opacity:1 })}}/>, action: () => setActiveForecastTab('precipitation')},
											{icon: <RemoveRedEye style={{...(activeForecastTab === 'visibility' && { color: "white", opacity:1 })}}/>, action: () => setActiveForecastTab('visibility')},
											{icon: <Thermostat style={{...(activeForecastTab === 'temperature' && { color: "white", opacity:1 })}}/>, action: () => setActiveForecastTab('temperature')},
											]}>
											<div style={{ width: 250, overflow: 'hidden'}}>
												<PerfectScrollbar>
													
														<Stack direction='horizontal' halign='space-between'>
															{ currentWeather.forecast.forecastday.map((day,dayIndex) => 
																day.hour.filter(hour => hour.time_epoch > now).map((hour,hourIndex) => (
																	<IconText 
																	    key={`current-${dayIndex}-${hourIndex}`}
																		direction='vertical'
																		halign='center' 
																		icon={ activeForecastTab === 'conditions' ? 
																						<img className="forecast-condition-icon" src={`http:${hour.condition.icon}`}/>
																					:
																					activeForecastTab === 'clouds'  ?
																						<Cloud/>
																					:
																					activeForecastTab === 'wind'  ?
																						<ArrowUpward style={{ transform: `rotate(${hour.wind_degree}deg)`}}/>
																					:
																					activeForecastTab === 'precipitation'  ?
																						<Opacity/>
																					:
																					activeForecastTab === 'visibility'  ?
																						<RemoveRedEye/>
																					:
																					activeForecastTab === 'temperature'  ?
																							<Thermostat/>
																					:
																					null
																				}
																		text={<p className='weather-forecast'>
																			<span className='bold weather-status'>
																					{ activeForecastTab === 'conditions' ? 
																						hour.condition.text 
																					:
																					activeForecastTab === 'clouds'  ?
																						hour.cloud + ' %'
																					:
																					activeForecastTab === 'wind'  ?
																						(<span>{(hour.wind_kph * 0.54).toFixed(1)} kts <br/> {hour.wind_degree}º</span>)
																					:
																					activeForecastTab === 'precipitation'  ?
																						hour.chance_of_rain + '  %'
																					:
																					activeForecastTab === 'visibility'  ?
																						hour.vis_km + '  Km'
																					:
																					activeForecastTab === 'temperature'  ?
																							hour.temp_c + '  ºC'
																					:
																					null
																					}
																				</span>
																				<br/>
																				<span className='bold' style={{ marginTop: 5 }}>
																					{ UtilityService.timeFromTimestamp(hour.time_epoch * 1000) }
																				</span>
																				<br/>
																				<span className="uppercase">
																					{ UtilityService.dateFromTimestamp(hour.time_epoch * 1000) }	
																				</span>
																			</p>
																		}/>
																	)
																)

															
															)
														}
														</Stack>
												</PerfectScrollbar>
											</div>
									</InfoPanel>
							}
						</>
						}
					</>
					)
				)
			}
		</InfoCard>
	)
}
import React, { useContext, useState } from 'react';
import { FleetManagementContext } from '../../../../contexts/fleet-management-context';
import UtilityService from '../../../../helpers/utility-service';
import { FleetManagementTableContent } from '../fleet-management-table-content/fleet-management-table-content';
import { FMDashboardPanel } from '../fm-dashboard-panel/fm-dashboard-panel';
import {equipmentSchema} from '../../../../contexts/schemas/equipment-schema';


export const FMEquipmentPanel = () => {
    const {equipment} = useContext(FleetManagementContext);
	const [equipmentFilter, setEquipmentFilter] = useState("");
    return(
        /* EQUIPMENT  */
        <div className="fleet-management-card-wrap">
            <FMDashboardPanel 
                className='fleet-management-card tabbed-card' 
                onFilter={(filter) => setEquipmentFilter(filter)}  
                dashboardPanelToolbar={true}
                title={equipmentSchema.entityName}//{schemas.equipmentSchema.entityName}
                entityName={equipmentSchema.entityName}//{schemas.equipmentSchema.entityName}
                schema={equipmentSchema}//{schemas.equipmentSchema }
                createItem={() => {}}
                // {async (data) => {
                //     await fleetManagementService.createEquipment(data);
                //     equipmentTableRef.current.loadData();  
                // }}
            >
                <FleetManagementTableContent   
                    data={equipment}
                    loadData={()=>{}}//{() => fleetManagementService.fetchAllEquipment()}
                    loadSingleItem={()=>{}}//{(id) => fleetManagementService.fetchSingleEquipment(id)}
                    updateSingleItem={()=>{}}//{(id, updateObj) => fleetManagementService.updateEquipment(id, updateObj)}
                    deleteSingleItem={()=>{}}//{(id) => fleetManagementService.deleteEquipment(id)}
                    getTitle={(item) => item.name}
                    schema = {equipmentSchema}//{schemas.equipmentSchema}
                    filter = {equipmentFilter}//{equipmentFilter}
                    filterFunction = {(row, filter)=>UtilityService.isRowFiltered(row,filter)}
                    // calcCycleColor = {(cycles)=>calcCycleColor(cycles)}
                />  
            </FMDashboardPanel>
        </div> 
    )
}
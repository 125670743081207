import { v4 } from "uuid";
import { getDistance, computeDestinationPoint } from "geolib";
const GeoTree = require('geo-tree');

const uuidv4 = v4;

class UtilityService {
	/**
	 * Generate UUID
	 * @returns 
	 */
	static generateId() { return uuidv4().toUpperCase(); }

	/**
	 * Get current timestamp in seconds
	 * @returns 
	 */
	 static getCurrentTime = (seconds=false) => {
		return Math.trunc(new Date().getTime() / (seconds ? 1000 : 1));
	}

	/**
	 * Compute distance between two geo points
	 * @param {*} pointA 
	 * @param {*} pointB 
	 * @returns 
	 */
	static distance = (pointA, pointB, accuracy=1) => {
		return getDistance(pointA, pointB, accuracy);
	}

	
	/**
	 * Compute displacement point
	 * @param {*} point 
	 * @param {*} distance 
	 * @param {*} bearing 
	 * @returns 
	 */
	static displacementPoint( point, distance, bearing=90 ) {
		const displacedPoint = computeDestinationPoint(point, distance, bearing);
		return [displacedPoint.latitude, displacedPoint.longitude];
	}

	/**
	 * Convert date string to <Day number> <Month>
	 */
	static dateFromTimestamp(timestamp) {
		const dateString = new Date(timestamp).toISOString().split('T')[0];
		const [year, month, day] = dateString.split('-');
		let monthName = null;

		switch(month) {
			case '01': monthName = 'Jan'; break;
			case '02': monthName = 'Feb'; break;
			case '03': monthName = 'Mar'; break;
			case '04': monthName = 'Apr'; break;
			case '05': monthName = 'May'; break;
			case '06': monthName = 'Jun'; break;
			case '07': monthName = 'Jul'; break;
			case '08': monthName = 'Aug'; break;
			case '09': monthName = 'Sep'; break;
			case '10': monthName = 'Oct'; break;
			case '11': monthName = 'Nov'; break;
			case '12': monthName = 'Dec'; break;
			default: monthName = '';
		}

		return `${day} ${monthName}`;
	}

	/**
	 * Convert milliseconds timestamp to time string <HH>:<MM>
	 */
	static timeFromTimestamp(timestamp) {
		const date = new Date(timestamp);
		const isoString = date.toISOString();
		const time = isoString.split('T')[1];
		const [hh, mm, sms] = time.split(':');
		return `${hh}:${mm}`;
	}

	/**
	 * Calculate parameter of a line that passes through points A and B
	 * @param {*} pointA 
	 * @param {*} pointB 
	 * @returns 
	 */
	static getLineThroughPoints(pointA, pointB) {
		const k = (pointB.y - pointA.y) / (pointB.x - pointA.x);
		const n = pointA.y + k * pointA.x;
		return { k, n }
	}

	/**
	 * Calculate distance between point and line
	 * @param {*} point 
	 * @param {*} k 
	 * @param {*} n 
	 * @returns 
	 */
	static getDistanceBetweenPointAndLine(point, k, n) {
		let x = null;
		let y = null;

		if (k == 0) {
			x = point.x;
			y = n;
		} else {
			const kp = - 1 / k;
			const np = point.y - kp * point.x;

			x = (np - n) / (k - kp);
			y = k * x + n;
		}

		return y - point.y;
	}

	// Deep clone object
	static clone(obj) {
		return JSON.parse(JSON.stringify(obj));
	}

	// Calculate bearing of two points
	static calcBearing(wp1, wp2) {
		let { latitude: lat1, longitude: lng1 } = wp1;
		let { latitude: lat2, longitude: lng2 } = wp2;

		lat1 = lat1 * Math.PI / 180;
		lng1 = lng1 * Math.PI / 180;
		lat2 = lat2 * Math.PI / 180;
		lng2 = lng2 * Math.PI / 180;

		const y = Math.sin(lng2-lng1) * Math.cos(lat2);
		const x = Math.cos(lat1)*Math.sin(lat2) -
							Math.sin(lat1)*Math.cos(lat2)*Math.cos(lng2-lng1);
		const t = Math.atan2(y, x);
		const brng = (t*180/Math.PI + 360) % 360; // in degrees  
		
		return Math.round(brng);
	} 

	/**
	 * Get value from object from given . path
	 */
	static getObjectValueFromPath(obj, path) {
		if (obj == null) {
			return null;
		}

		const steps = path.split('.');

		let pendingObj = obj;
		for (const step of steps) {
				if (pendingObj[step] != null) {
						pendingObj = pendingObj[step];
				} else {
						return null;
				}
		}

		return pendingObj;
	}

	/**
	 * Get coordinates of a geopoint shifted by dNorth/dEast meters
	 * @param {} point 
	 * @param {*} dNorth 
	 * @param {*} dEast 
	 */
	static getShiftedPoint({ latitude, longitude }, dNorth, dEast) {
		//Earth’s radius, sphere
		const R=6378137

		//Coordinate offsets in radians
		const dLat = dNorth / R;
		const dLon = dEast / (R * Math.cos(Math.PI * latitude / 180));

		//OffsetPosition, decimal degrees
		const newLatitude = latitude + dLat * 180 / Math.PI;
		const newLongitude = longitude + dLon * 180 / Math.PI;
		
		return { latitude: newLatitude, longitude: newLongitude };
	}

	/**
	 * Filter object array by any field value
	 */
	static isRowFiltered(row, filter) {
		for (const key in row) {
			const value = `${row[key]}`.toLowerCase();
	
			if (value.includes(filter.toLowerCase())) {
				return true;
			}
		}
	
		return false;
		
	}

	/** 
	 * Transform object by schema
	 */
	static transformObject(el, attributes) {
        const obj = {};
            
        for (const attribute of attributes) {
            if (!['remote-table'].includes(attribute.type)) {
                if (attribute.path != null) {
                    obj[attribute.key] = UtilityService.getFromPath(el, attribute.path);
                    if (attribute.type === 'table') {
                        if (obj[attribute.key] == null) {
                            obj[attribute.key] = []
                        } else {
                            obj[attribute.key] = obj[attribute.key].map(subEl => this.transformObject(subEl, attribute.attributes));
                        }
                    }
                }

                if (attribute.type === 'remote-select') {
                    obj[`${attribute.key}-displayValue`] = UtilityService.getFromPath(el, attribute.displayValuePath) || obj[attribute.key];
                }
            }
        }

        return obj;
    }

	/**
	 * Get value from object by following complex path
	 * @param {} obj 
	 * @param {*} path 
	 * @returns 
	 */
	static getFromPath(obj, path) {
        if (obj == null) {
            return null;
        }

        const steps = path.split('.');

        let pendingObj = obj;
        for (const step of steps) {
            if (pendingObj[step] != null) {
                pendingObj = pendingObj[step];
            } else {
                return null;
            }
        }

        return pendingObj;
    }
	static chunkArray(myArray, chunk_size){
        const arrayLength = myArray.length;
        const tempArray = [];
        
        for (let index = 0; index < arrayLength; index += chunk_size) {
            const myChunk = myArray.slice(index, index+chunk_size);
            // Do something if you want with the group
            tempArray.push(myChunk);
        }
    
        return tempArray;
    }
	/**
	 * Calc battery cycle color mark
	 * @param {} cycles 
	 * @returns 
	 */
	static calcCycleColor(cycles) {
        let color = 'transparent';
        if (cycles == "Serviceable" || (cycles >= 0 && cycles <= 200)){
            color = '#3FD17D';
        } 
        if (cycles >= 200 && cycles < 430) {
            color = '#E5A135';
        }
        if (cycles >= 430){
            color = '#D62F21';
        }
        return color;
    };

	static createFormData(data) {
		const bodyFormData = new FormData();

		for (const key of Object.keys(data)) {
			const value = data[key];

			if (value instanceof File) {
				console.log('It is a file')
				bodyFormData.append(
					"file",
					value,
					value.name
				)
			} else {
				if (Array.isArray(value)) {
					bodyFormData.append(`${key}[]`, value);
				} else {
					bodyFormData.append(key, value);
				}
				
			}
		}

		return bodyFormData;
	}

	static sampleLineBetweenPoints(p0, p1, resolution=10) {    
		const dist = UtilityService.distance(p0, p1);
		const points = [];
		// console.log("P0", p0, "P1", p1);
		let i = 0;
		while (true) {
				if (i > dist) {
						i = dist;
				}

				var x;
				const percent = (i / dist);
				if (p0.latitude !== p1.latitude)
						x = p0.latitude + percent * (p1.latitude - p0.latitude);
				else 
						x = p0.latitude;
		
				var y;
				if (p0.longitude !== p1.longitude)
						y = p0.longitude + percent * (p1.longitude - p0.longitude);
				else
						y = p0.longitude;
		
				var p = {
						latitude: x,
						longitude: y, 
						altitude: parseFloat(((p0.amsl + percent * (p1.amsl - p0.amsl)).toFixed(4))),
				};

				points.push(p);

				if (i == dist) {
						break;
				}

				i += resolution;
		}

		// points.push({
		//     x: p1.position[0],
		//     y: p1.position[1],
		//     altitude: parseFloat((p1.altitude).toFixed(4)),
		// });

		return points;
	}

	static samplePoints(waypoints, resolution=10) {
		const pointSamples = [];
		let prevPoint = null;

		for (let i = 0; i < waypoints.length; i += 1) {
			const waypoint = waypoints[i];

			if (prevPoint == null) {
				pointSamples.push({ latitude: waypoint.latitude, longitude: waypoint.longitude });
				prevPoint = { latitude: waypoint.latitude, longitude: waypoint.longitude };
			} else {
				const newSamples = UtilityService.sampleLineBetweenPoints(prevPoint, { latitude: waypoint.latitude, longitude: waypoint.longitude }, resolution);
				pointSamples.push(...newSamples);
				prevPoint = { latitude: waypoint.latitude, longitude: waypoint.longitude };
			}
		}

		return pointSamples;
	}

	static toArrayBuffer(buff) {
    return buff.buffer.slice(buff.byteOffset, buff.byteOffset + buff.byteLength);
	}

	static findIntersectingFacilityFields(polygons, waypoints) {
		const sampledWaypoints = [];

		for (let i = 1; i < waypoints.length; i += 1) {
			const p0 = waypoints[i - 1];
			const p1 = waypoints[i];

			sampledWaypoints.push(...UtilityService.sampleLineBetweenPoints(p0, p1, 5));
		}

		const waypointsTree = new GeoTree();
		waypointsTree.insert(sampledWaypoints.map(wp => ({ lat: wp.latitude, lng: wp.longitude, data: { altitude: wp.altitude } })));
		// const waypointsTree = new KDBush(sampledWaypoints, wp => wp.latitude, wp => wp.longitude, 64);
		const intersections = [];

		for (const polygon of polygons) {
			const { coordinates, value } = polygon;
			// console.log(coordinates);
			const lats = coordinates.map(c => c[0]);
			const lons = coordinates.map(c => c[1]);

			const minLat = Math.min(...lats);
			const maxLat = Math.max(...lats);

			const minLon = Math.min(...lons);
			const maxLon = Math.max(...lons);
			
			// console.log(minLat, minLon, maxLat, maxLon);

			const results = waypointsTree.find({lat: minLat, lng: minLon}, {lat: maxLat, lng: maxLon});
			// TODO: filter by altitude
			// console.log(results);

			let violated = false;
			if (results.length > 0) {
				for (const res of results) {
					const { altitude } = res;

					if (altitude >= Math.ceil(polygon.value * 0.3048)) {
						intersections.push({...polygon, violation: true });
						violated = true;
						break;
					}
				}

				if (!violated) {
					intersections.push({...polygon, violation: false });
				}
			}
		}

		return intersections;
	}

	static distanceToNauticalMiles(distance) {
		return (distance / 1852).toFixed(2);
	}
	static distanceToKilometers(distance) {
		return (distance / 1000).toFixed(2);
	}
	static numberWithCommas(x) {
		return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	}

}

export default UtilityService;
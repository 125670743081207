import MAVLinkConstants from '../../../protocol-services/mavlink-constants';

function getMavResult(result) {
	for (const key in MAVLinkConstants.MAV_RESULT) {
		if (MAVLinkConstants.MAV_RESULT[key] == result) {
			return key;
		}
	}
}

// COMMAND_ACK (#77)
export function commandAck(message, aircraft) {

	const {
		command, // MAV_CMD - Command ID
		result, // MAV_RESULT - Result of command
		progress, // Also used as result_param1
		result_param2, // Additional parameter of the result
		target_system, // System ID of the target recipient
		target_component, // Component ID of the target recipient
	} = message;

	const {
		pendingAcks, // Map of expecting acks {}
	} = aircraft;

	console.log(console.log(command, result));

	// Unexpected command acknowledgement
	if (pendingAcks[command] == null || pendingAcks[command].length == 0) {
		console.log('Unexpected COMMAND_ACK');
		return;
	}

	// Get first waiting ack for the given command
	const firstCommand = pendingAcks[command].shift();
	const {
		value,
		onAccepted,
		onFailed
	} = firstCommand;

	// Check if the command was accepted
	let accepted = false;
	if (result == MAVLinkConstants.MAV_RESULT.MAV_RESULT_ACCEPTED) {
		accepted = true;
	}

	const statusMessage = getMavResult(result);

	switch (command) {
		// ARM/DISARM
		case MAVLinkConstants.MAV_CMD.MAV_CMD_COMPONENT_ARM_DISARM:
			processArmDisarmAck(accepted, value, statusMessage, onAccepted, onFailed);
			break;

			// SET FLIGHT MODE
		case MAVLinkConstants.MAV_CMD.MAV_CMD_DO_SET_MODE:
			processSetModeAck(accepted, value, statusMessage, onAccepted, onFailed);
			break;

		case MAVLinkConstants.MAV_CMD.MAV_CMD_NAV_TAKEOFF:
			processTakeOffAck(accepted, value, statusMessage, onAccepted, onFailed);
			break;

		case MAVLinkConstants.MAV_CMD.MAV_CMD_NAV_LAND:
			processLandAck(accepted, value, statusMessage, onAccepted, onFailed);
			break;

		case MAVLinkConstants.MAV_CMD.MAV_CMD_DO_ORBIT:
			processOrbitAck(accepted, value, statusMessage, onAccepted, onFailed);
			break;

			// case MavlinkConstants.MAC_CMD.MAV_CMD_DO_CHANGE_SPEED:
	}
}

// case MAVLinkConstants.MAV_CMD.MAV_CMD_MISSION_START:
// 	if (accepted) {
// 		console.log('Mission started');
// 		this.logBlackBox(null, 'MISSION START ACK');
// 		if (!this.vehicleState.airborn) {
// 			this.vehicleState.takeOffPosition = {...this.vehicleState.position};
// 		}
// 	} else {
// 		const statusMessage = this.getMavResult(result);
// 		content = `MAV_CMD_MISSION_START returned status: ${result}, ${statusMessage}`;
// 		this.addNotification(title, message);
// 	}
// 	break;

// case MAVLinkConstants.MAV_CMD.MAV_CMD_NAV_TAKEOFF:
// 	if (accepted) {
// 		this.vehicleState.airborn = true;
// 		this.vehicleState.takeOffPosition = this.vehicleState.position;
// 		this.vehicleState.landingPosition = null
// 		this.logBlackBox(null, 'TAKEOFF ACK');

// 		title = `Taking off`;
// 		content = `Aircraft is taking off`
// 		this.addNotification(title, content);

// 	} else {
// 		title = `Failed to takeoff`;
// 		content = `MAV_CMD_NAV_TAKEOFF returned status: ${result}, ${statusMessage}`
// 		this.addNotification(title, content);
// 	}
// 	break;

// case MAVLinkConstants.MAV_CMD.MAV_CMD_NAV_LAND:
// 	if (accepted) {
// 		this.vehicleState.flightMode = 'LAND';
// 		this.vehicleState.currentWaypoint = null;
// 		this.vehicleState.landingPosition = {...this.vehicleState.position};
// 		this.logBlackBox(null, 'LAND ACK');
// 	} else {
// 		title = `Failed to land`;
// 		content = `MAV_CMD_NAV_LAND returned status: ${result}, ${statusMessage}`;
// 		this.addNotification(title, content);
// 	}
// 	break;

// case MAVLinkConstants.MAV_CMD.MAV_CMD_OVERRIDE_GOTO:
// 	if (accepted) {
// 		this.logBlackBox(null, 'GO TO WAYPOINT ACK');
// 	} else {
// 		title = `Failed to navigate to waypoint`;
// 		content = `MAV_CMD_OVERRIDE_GOTO returned status: ${result}, ${statusMessage}`;
// 		this.addNotification(title, content);
// 	}
// 	break;



// ARM/DISARM
function processArmDisarmAck(accepted, armed, statusMessage, onAccepted = null, onFailed = null) {
	if (accepted) {
		if (onAccepted) {
			onAccepted(armed);
		}
	} else {
		if (onFailed) {
			onFailed(armed, statusMessage)
		}
	}
}

// SET FLIGHT MODE
function processSetModeAck(accepted, mode, statusMessage, onAccepted = null, onFailed = null) {
	if (accepted) {
		if (onAccepted) {
			onAccepted(mode);
		}
	} else {
		if (onFailed) {
			onFailed(mode, statusMessage);
		}
	}
}

// TAKE OFF
function processTakeOffAck(accepted, mode, statusMessage, onAccepted = null, onFailed = null) {
	if (accepted) {
		if (onAccepted) {
			onAccepted(mode);
		}
	} else {
		if (onFailed) {
			onFailed(mode, statusMessage);
		}
	}
}

// LAND
function processLandAck(accepted, mode, statusMessage, onAccepted = null, onFailed = null) {
	if (accepted) {
		if (onAccepted) {
			onAccepted(mode);
		}
	} else {
		if (onFailed) {
			onFailed(mode, statusMessage);
		}
	}
}

// DO_ORBIT
function processOrbitAck(accepted, value, statusMessage, onAccepted, onFailed) {
	if (accepted) {
		if (onAccepted) {
			onAccepted();
		}
	} else {
		if (onFailed) {
			onFailed(statusMessage);
		}
	}
}
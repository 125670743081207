import React, { useContext, useEffect, useRef, useState } from 'react';
import { ThrottleProgressBar } from './components/throttle-progress-bar/throttle-progress-bar';
import {VerticalSpeedBar} from './components/vertical-speed-bar/vertical-speed-bar';
import {PrimaryFlightDisplay} from './components/primary-flight-display/primary-flight-display';
import { StatusBoard } from './components/status-board/status-board';
import { AircraftContext } from '../../../../contexts/aircraft-context';
import { TerrainAwarenessWidget } from '../terrain-awareness-widget/terrain-awareness-widget';
import { motion } from 'framer-motion';
import {FlightDetailsBar} from './components/flight-details-bar/flight-details-bar';
import { Maximize, Minimize } from '@mui/icons-material';

export const CockpitControlPanel = ({ visible }) => {
    const { selectedAircraft } = useContext(AircraftContext);
    const [generalState, setGeneralState] = useState({});
    const [hudState, setHudState] = useState({});
    const [minimize, setMinimize] = useState(false);
    const currentAircraft = useRef(null);
    const [flightMode, setFlightMode] = useState(selectedAircraft != null ? selectedAircraft.generalState.getState().customMode : null);
    const subscriptions = useRef([]);

    useEffect(() => {
        if (currentAircraft.current != selectedAircraft) {
            currentAircraft.current = selectedAircraft;

            if (currentAircraft.current != null) {
                for (const sub of subscriptions.current) {
                    sub();
                    subscriptions.current = [];
                }
            }

            if (selectedAircraft != null) {
                
                setFlightMode(selectedAircraft.generalState.getState().customMode);
                subscriptions.current.push(selectedAircraft.generalState.subscribe(() => {
                    setFlightMode(selectedAircraft.generalState.getState().customMode);
                }))
                
                setGeneralState(selectedAircraft.generalState.getState());
                subscriptions.current.push(selectedAircraft.generalState.subscribe(() => {
                    console.log(selectedAircraft.generalState.getState());
                    setGeneralState(selectedAircraft.generalState.getState());
                }))

                subscriptions.current.push(selectedAircraft.hudState.subscribe(() => {
                    setHudState(selectedAircraft.hudState.getState());
                }));
            } else {
                setGeneralState({});
                setHudState({});
            }
        }
    }, [selectedAircraft])

    return(
        <div 
            key="cockpit-control-panel"
            className={`cockpit-control-panel ${minimize ? 'minimized' : visible ? 'visible' : 'hidden'}`} 
        >
            <div className='cockpit-control-header' style={{ ...(selectedAircraft != null && { backgroundColor: selectedAircraft.color }) }}>
                <p>{currentAircraft.current ? currentAircraft.current.aircraftName : 'N/A'}</p>
                <p className='minimize-icon'>{minimize ? <Maximize  onClick={()=>setMinimize(false)}/> : <Minimize onClick={()=>setMinimize(true)}/>}</p>
            </div>
            <div className='cockpit-control-panel-content'>
                    
                <div className='cockpit-control-panel-content-left'>
                    <div className='rpm-throttle-wrap'>
                        <div className='rpm-wrap'>
                            <span className='bold'>RPM:</span>
                            <span >1234</span>
                        </div>
                        <ThrottleProgressBar progressValue={hudState.throttle || 0}/>
                    </div>
                    
                    <PrimaryFlightDisplay 
                        heading={hudState.heading || 0}
                        pitch={hudState.pitch ? hudState.pitch : 0} 
                        roll={hudState.roll ? hudState.roll : 0} 
                        altitude={hudState.altitude ? hudState.altitude : 0} 
                        relativeAltitude={hudState.relativeAltitude ? hudState.relativeAltitude : 0} 
                        rangefinderAltitude={hudState.rangefinderAltitude ? hudState.rangefinderAltitude : 0}
                        airspeed={hudState.airspeed ? hudState.airspeed : 0}
                    />
                    <VerticalSpeedBar 
                        verticalSpeedValue={hudState.climb ? hudState.climb: 0}
                        min={-2}
                        max={2} /> 
                </div> 

                <TerrainAwarenessWidget/>
                 {/* <FlightDetailsBar
                        flightDetails = {flightDetails}
                        pathProgress={pathProgress}
                        previousWaypoint={previousWaypoint}
                        nextWaypoint={nextWaypoint}
                        missionPath={missionPath}
                        onSetFlightMode = {(flightMode) => aircraft.setFlightMode(flightMode)}
                        onNextWaypointChanged={(seq) => aircraft.setNextMissionItem(seq)}
                        className='cockpit-control-panel-content-center'/> */}

    
                <StatusBoard
                    key={`panel-${selectedAircraft ? selectedAircraft.aircraftId : 'none'}`}
                    className='cockpit-control-panel-content-right'
                    connectionType = {`${generalState.connectionType}`}//{connectionType}  (${generalState.signalStrength != null ? generalState.signalStrength : 'N/A'})
                    signalStrength={`${generalState.signalStrength != null ? generalState.signalStrength : 'N/A'}`}
                    status = {generalState.isArmed ? 'ARMED' : 'NOT ARMED'} 
                    sysState = {generalState.systemStatus ? generalState.systemStatus.label : "N/A"}
                    destinationETA="N/A"//{`${remainingTime / 60 < 10 ? '0' : ''}${Math.ceil((remainingTime - (remainingTime % 60)) / 60)}:${((remainingTime % 60) < 10) ? '0' : ''}${remainingTime % 60}`} 
                    destinationDist="N/A"//{totalDistance.toFixed(1)}
                    pathWPnum = {10}
                    missionPlan = {[]}//{missionPlan}
                    flightPath ={[]} //{flightPath}
                    pathLength={432}
                    flightMode={flightMode}
                    selectedAircraft={selectedAircraft}
                />
                    
                   
 
            </div>
        </div>
    )
}
import { BarChart } from '@mui/icons-material';
import React, { useContext, useState } from 'react';
import { MissionContext } from '../../../../../../contexts/mission-context';
import { RiskAssessmentContext } from '../../../../../../contexts/risk-assessment-context';
import { OverviewButton } from '../../../../../../shared-components/buttons/overview-button/overview-button';
import { RiskAssessmentModal } from '../../risk_assessment-modal';


export const RiskAssessmentPanel = () => {
    const [risky, setRisky] = useState(false);
    const [riskPanelActive, setRiskPanelActive] = useState(false);
    const { groundRiskScore } = useContext(RiskAssessmentContext);
    const { addMissionWarning, removeMissionWarning } = useContext(MissionContext);

    const status = groundRiskScore == null ?
        'neutral'
        :
        groundRiskScore.totalScore < 1e-6 ?
        'good'
        :
        'bad';

        if (groundRiskScore != null && groundRiskScore.totalScore >= 1e-6) {
            addMissionWarning('ground-risk', { type: 'error', text: 'Ground risk too high'})
        } else {
            removeMissionWarning('ground-risk')
        }

    return(
        <div className={`risk-assessment-panel ${riskPanelActive && 'active'}`}>
            {!riskPanelActive &&<OverviewButton status={status} name='Risk assessment' icon={<BarChart/>} onClick={()=> {setRiskPanelActive(true)}} blink={risky}/>}
           
            {riskPanelActive && 
                <div className='risk-assessment-panel-wrap'>
                    <RiskAssessmentModal visible={riskPanelActive} onClose={()=> {setRiskPanelActive(false)}}/>
                </div>
            }
            
        </div>
    )
}
const batteryReducer = (state = { remaining: 0, batteries: {}}, action) => {
	if (action.type == 'SET_REMAINING') {
		return { ...state, remaining: action.payload };
	}

	if (action.type == 'SET_TIMESTAMP') {
		return { ...state, lastTimestamp: action.payload };
	}

	if (action.payload) {
		const { id, current, currentConsumed, voltage, temperature } = action.payload;
		if (state.batteries[id] == null) {
			state.batteries[id] = {};
		}

		switch (action.type) {
			case 'SET_CURRENT':
				state.batteries[id].current = current;
				return { ...state };
			case 'SET_VOLTAGE':
				state.batteries[id].voltage = voltage;
				return { ...state };
			case 'SET_CURRENT_CONSUMED':
				state.batteries[id].currentConsumed = currentConsumed;
				return { ...state };
			case 'SET_TEMPERATURE':
				state.batteries[id].temperature = temperature;
				return { ...state };
			default:
				return state;
		}
	}
	return state;
}

export default batteryReducer

export const createSetters = (store) => ({
		setCurrent: (id, current) => store.dispatch({ type: 'SET_CURRENT', payload: { id, current } }),
		setVoltage: (id, voltage) => store.dispatch({ type: 'SET_VOLTAGE', payload: { id, voltage} }),
		setCurrentConsumed: (id, currentConsumed) => store.dispatch({ type: 'SET_CURRENT_CONSUMED', payload: { id, currentConsumed } }),
		setTemperature: (id, temperature) => store.dispatch({ type: 'SET_TEMPERATURE', payload: { id, temperature } }),
		setRemaining: (remaining) => store.dispatch({ type: 'SET_REMAINING', payload: remaining }),
		setTimestamp: (timestamp) => store.dispatch({ type: 'SET_TIMESTAMP', payload: timestamp }),
	});
import { CheckBox, CheckBoxOutlineBlank, CheckBoxOutlineBlankOutlined } from '@mui/icons-material';
import React, { useContext, useRef, useState } from 'react';
import { InputFieldV2 } from '../../../../../../shared-components/inputs/input-field-v2/input-field-v2';
import { SelectInput } from '../../../../../../shared-components/inputs/select-input/select-input';
import { OutlineButton } from '../../../../../../shared-components/buttons/outline-button/outline-button';
import { FleetManagementContext } from '../../../../../../contexts/fleet-management-context';
import { MissionContext } from '../../../../../../contexts/mission-context';
import { RiskAssessmentContext } from '../../../../../../contexts/risk-assessment-context';
import { toast } from 'react-toastify';
import { Loading } from '../../../../../../shared-components/layout/loading/loading';

export const RiskAssessmentGround = () => {
    const { allAircraft } = useContext(FleetManagementContext);
    const { currentPath } = useContext(MissionContext);
    const { 
        evaluateGroundRisk, 
        groundRiskScore,
        currentSamplingDistance,
        currentAircraftId,
        currentParachuteTerminalVelocity,
        currentWindSpeed,
        currentWindDirection,
        isBusy,
        currentCalculateBallisticDescent,
        currentCalculateParachuteDescent,
        currentCalculateUncontrolledGlide,
    } = useContext(RiskAssessmentContext);

    const { missionAircraftType } = useContext(MissionContext);
    const selectedAircraftType = missionAircraftType != null ? 
            missionAircraftType.data.fields.reduce((prev, curr) => {
                    prev[curr.param] = curr.value;
                    return prev;
                }, 
            {}) 
        : 
            {};

    console.log(groundRiskScore);

    const [parachuteDescent, setParachuteDescent] = useState(selectedAircraftType.parachute_t_vel != null && currentCalculateBallisticDescent);
    const [ballisticDescent, setBallisticDescent] = useState(currentCalculateParachuteDescent);
    const [uncontrolledGlide, setUncontrolledGlide] = useState(currentCalculateUncontrolledGlide);

    const pathSamplingResolution = useRef(currentSamplingDistance);
    const windSpeed = useRef(currentWindSpeed);
    const windDirection = useRef(currentWindDirection); 

    const evaluatePath = () => {
        if (missionAircraftType == null) {
            toast.error('Mission aircraft type not selected');
            return;
        }
        if (
            pathSamplingResolution.current == null || `${pathSamplingResolution.current}`.length == 0
            || windSpeed.current == null || `${windSpeed.current}`.trim().length == 0
            || windDirection.current == null || `${windDirection.current}`.trim().length == 0
        ) {
            toast.error('Please fill all required inputs');
            return;
        }

        if (!(ballisticDescent || parachuteDescent || uncontrolledGlide)) {
            toast.error('Please select analysis type');
            return;
        }

        if (currentPath.waypoints.length == 0) {
            toast.error('Current path has not waypoints');
            return;
        }

        evaluateGroundRisk(
            missionAircraftType,
            currentPath.waypoints, 
            pathSamplingResolution.current,
            selectedAircraftType.parachute_t_vel,
            windSpeed.current,
            windDirection.current,
            { 
                calculateBallisticDescent: ballisticDescent, 
                calculateParachuteDescent: parachuteDescent,
                calculateUncontrolledGlide: uncontrolledGlide,
            }
        );
    }

    return( 
        <div className='risk-assessment-ground'>
            <div className='risk-assessment-ground-form risk-assessment-ground-section'>
                <div className='risk-assessment-form-group'>
                    <div className='risk-assessment-form-element'>
                        {/* <SelectInput onChange={(e) => selectedAircraftId.current = e.target.value} options={allAircraft.map(ac => ({ name: ac.data.name, value: ac.id }))}/> */}
                        <div className='uppercase blue bold'>Airframe type</div>
                        <InputFieldV2 disabled value={selectedAircraftType.aircraft_type} className='input-field-v2'/>
                    </div>
                    <div className='risk-assessment-form-element'>
                        <div className='uppercase blue bold'>Parachute t. vel.</div>
                        { selectedAircraftType.parachute_t_vel != null ?
                            <InputFieldV2 defaultValue={selectedAircraftType.parachute_t_vel} className='input-field-v2' unit={"M/S"}/>
                            :
                            <InputFieldV2 disabled defaultValue="N/A" className='input-field-v2'/>
                        }
                    </div>
                </div>
                <div className='risk-assessment-form-group'>
                    <div className='risk-assessment-form-element'>
                         <div className='uppercase blue bold'>Wind Speed</div>
                        <InputFieldV2 defaultValue={currentWindSpeed} onChange={(e) => windSpeed.current = parseFloat(e.target.value)} className='input-field-v2' unit={"M/S"}/>
                    </div>
                    <div className='risk-assessment-form-element'>
                        <div className='uppercase blue bold'>Wind Direction</div>
                        <InputFieldV2 defaultValue={currentWindDirection} onChange={(e) => windDirection.current = parseFloat(e.target.value)} className='input-field-v2' unit={"DEG"}/>
                    </div>
                    <div className='risk-assessment-form-element'>
                        <div className='uppercase blue bold'>Path Resolution</div>
                        <InputFieldV2 defaultValue={currentSamplingDistance} className='input-field-v2' unit={"M"}/>
                    </div>
                </div>
                <div className='risk-assessment-form-group'>
                    <div className='uppercase bold title-color'>Analysis type</div>
                    <div className='risk-assessment-form-element'>
                        <div className='uppercase blue bold'>Ballistic Descent</div>
                        <div className="checkbox-v2-container pointer" onClick={() => setBallisticDescent(!ballisticDescent)}>
                            {ballisticDescent ? 
                            <CheckBox/>:<CheckBoxOutlineBlankOutlined/> }
                        </div>
                    </div>
                    { selectedAircraftType.aircraft_type == 'fixed_wing' &&
                    <div className='risk-assessment-form-element'>
                        <div className='uppercase blue bold'>Uncontrolled Glide</div>
                        <div className="checkbox-v2-container pointer" onClick={() => setUncontrolledGlide(!uncontrolledGlide)}>
                            {uncontrolledGlide ? 
                            <CheckBox/>:<CheckBoxOutlineBlankOutlined/> }
                        </div>
                    </div>
                    }
                    { selectedAircraftType.parachute_t_vel != null &&
                    <div className='risk-assessment-form-element'>
                        <div className='uppercase blue bold'>Parachute Descent</div>
                        <div className="checkbox-v2-container pointer" onClick={() => setParachuteDescent(!parachuteDescent)}>
                            {parachuteDescent ? 
                            <CheckBox/>:<CheckBoxOutlineBlankOutlined/> }
                        </div>
                    </div>  }
                </div>
                <div className='evaluate-risk-button'>
                <OutlineButton onClick={() => evaluatePath()} title='evaluate risk' size='large'/>
            </div>
            </div>
            <div className='risk-assessment-ground-evauluation risk-assessment-ground-section'>
                <p className='blue uppercase bold'>Ground risk</p>
                { groundRiskScore == null && !isBusy ?
                (<p style={{ color: 'white' }}>Ground risk not evaluated</p>)
                :
                isBusy ? (<div className='loading-wrapper'><Loading position='absolute' width='100px' height='100px'/></div> )
                :
                    <table className='analysis-table'>
                        <thead>
                            <th className='uppercase analysis-type blue'>Analysis type</th>
                            <th className='uppercase blue'>Risk level</th>
                            <th className='uppercase blue'>Score</th>
                        </thead>
                        <tbody>
                            {ballisticDescent && <tr>
                                <td className='uppercase bold'>Ballistic descent</td>
                                <td className={`uppercase`}>{groundRiskScore.ballisticDescent < 1e-6 ? 'ACCEPTABLE' : 'NOT ACCEPTABLE'}</td>
                                <td className='bold'>{groundRiskScore.ballisticDescent != null ? groundRiskScore.ballisticDescent.toPrecision(2): ''}</td>
                            </tr>}
                            {uncontrolledGlide && <tr>
                                <td className='uppercase bold'>Uncontrolled Glide</td>
                                <td className={`uppercase`}>{groundRiskScore.uncontrolledGlide < 1e-6 ? 'ACCEPTABLE' : 'NOT ACCEPTABLE'}</td>
                                <td className='bold'>{groundRiskScore.uncontrolledGlide != null ? groundRiskScore.uncontrolledGlide.toPrecision(2) : ''}</td>
                            </tr>}
                            {parachuteDescent && <tr>
                                <td className='uppercase bold'>Parachute Descent</td>
                                <td className={`uppercase`}>{groundRiskScore.parachuteDescent < 1e-6 ? 'ACCEPTABLE' : 'NOT ACCEPTABLE'}</td>
                                <td className='bold'>{groundRiskScore.parachuteDescent != null ? groundRiskScore.parachuteDescent.toPrecision(2) : ''}</td>
                            </tr>}
                        </tbody>
                    </table>
                }
            </div>
            
        </div>
    )
}
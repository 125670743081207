import { Battery20, Battery20Outlined, Battery30Outlined, Battery50Outlined, Battery60Outlined, Battery80Outlined, Battery90Outlined, BatteryAlertOutlined, BatteryFullOutlined, FolderOutlined, MonitorHeart, SignalCellular0Bar, SignalCellular0BarOutlined, SignalCellular1Bar, SignalCellular2Bar, SignalCellular3Bar, SignalCellular4Bar } from "@mui/icons-material"
import { motion } from "framer-motion";
import React, { useContext, useEffect, useRef, useState } from "react"
import { AircraftContext } from "../../../../contexts/aircraft-context"
import FileSystemContextProvider from "../../../../contexts/file-system-context"
import { FileDialog } from "../../../../shared-components/containers/file-dialog/file-dialog"
import { CockpitHeaderPanel } from "./components/cockpit-header-panel/cockpit-header-panel"
import SatelliteAltIcon from '@mui/icons-material/SatelliteAlt';
import { SkyModal } from "../../../../shared-components/containers/sky-modal/sky-modal";

const missionSchema = { attributes: [
	{
			"key": "title",
			"label": "Title",
			"path": "data.title",
	},
	{
			"key": "createdAtDate",
			"label": "Creation Date",
			"path": "createdAt",
			"mapValues": (value) => value.split('T')[0],  //  "2021-01-01T12:34:56" -> "2021-01-01" 
	},
	{
			"key": "createdAtDate",
			"label": "Creation Time",
			"path": "createdAt",
			"mapValues": (value) => value.split('T')[1],  //  "2021-01-01T12:34:56" -> "12:34:56""
	},
	{
			"key": "flightPaths",
			"label": "#Flight Paths",
			"path": "data.flightPaths",
			"mapValues": (value) => value != null ? value.length : 0,  // "[{...},{...}]" -> 2
	},
]};

//aircraft.generalState()

export const CockpitHeader = () => {
	const { selectedAircraft } = useContext(AircraftContext);
	const [isVisibleOpenDialog, setIsVisibleOpenDialog] = useState(false);
	// const [isVisibleBatteryInfo, setIsVisibleBatteryInfo] = useState(false);
	const [activeTool, setActiveTool] = useState(null);
	const [batteryPercentage, setBatteryPercentage] = useState(0);
	const [batteryData, setBatteryData] = useState([]);
	const [gpsData, setGpsData] = useState({});
	const [FDMessage, setFDMessage] = useState(false);
	const currentAircraft = useRef(null);
	const subscriptions = useRef([]);

	const [signalStrength, setSignalStrength] = useState(0);
	const [connectionType, setConnectionType] = useState('LTE');
	
	useEffect(() => {
		if (selectedAircraft != currentAircraft.current) {
			currentAircraft.current = selectedAircraft;

			if (currentAircraft.current != null) {
				for (const sub of subscriptions.current) {
					sub();
					subscriptions.current = [];
				}
			}

			if (selectedAircraft != null) {
				const gpsData = selectedAircraft.gpsRawState.getState();
				setGpsData(gpsData);

				subscriptions.current.push(selectedAircraft.gpsRawState.subscribe(() => {
					const gpsData = selectedAircraft.gpsRawState.getState();
					setGpsData(gpsData);
				}));


				const { remaining, batteries } = selectedAircraft.batteryState.getState();
				const { connectionType: currentConnectionType, signalStrength: currentSignalStrength } = selectedAircraft.generalState.getState();
				setSignalStrength(currentSignalStrength);
				setConnectionType(currentConnectionType);


				setBatteryPercentage(remaining);

				const data = [];
				for (const id of Object.keys(batteries)) {
					data.push([
						{
							name: "Remaining",
							value: remaining,
							unit: '%'
						},
						{
							name: "Current",
							value: batteries[id].current,
							unit: 'mA'
						},
						{
							name: "Consumed Current",
							value: batteries[id].currentConsumed,
							unit: 'mAh'
						},
						{
							name: "Voltage",
							value: batteries[id].voltage,
							unit: 'V'
						},
						{
							name: "Temperature",
							value: batteries[id].temperature,
							unit: 'ºC'
						}
					]);
				}
				setBatteryData(data);

				subscriptions.current.push(selectedAircraft.generalState.subscribe(() => {
					const { connectionType: currentConnectionType, signalStrength: currentSignalStrength } = selectedAircraft.generalState.getState();
					setSignalStrength(currentSignalStrength);
					setConnectionType(currentConnectionType);
					
				}));


				subscriptions.current.push(selectedAircraft.batteryState.subscribe(() => {
					const { remaining, batteries } = selectedAircraft.batteryState.getState();
						setBatteryPercentage(remaining);

						const data = [];
						for (const id of Object.keys(batteries)) {
						data.push([	
							{
								name: "Remaining",
								value: remaining,
								unit: '%'
							},
							{
								name: "Current",
								value: batteries[id].current,
								unit: 'mA'
							},
							{
								name: "Consumed Current",
								value: batteries[id].currentConsumed,
								unit: 'mAh'
							},
							{
								name: "Voltage",
								value: batteries[id].voltage,
								unit: 'V'
							},
							{
								name: "Temperature",
								value: batteries[id].temperature,
								unit: 'ºC'
							}
						]);
					}
					setBatteryData(data);
				}))
			}
		}
	})

	const openFileDialog = () => {
		setActiveTool('open-tool'); 
		setIsVisibleOpenDialog(true);
	}
	const openTooltip = () => {
		setFDMessage(true);
	}

	return (
		<motion.div 
			className="cockpit-header"
			key="cockpit-header"
			initial={{ y: -150, opacity: 0 }}
			animate={{ y: 0, opacity: 1 }}
			exit={{ y: -150, opacity: 0 }}
			transition={{ duration: 0.2, ease: "easeOut", delay: 0.3 }}>
			<FileSystemContextProvider>
				<div 
					className={`cockpit-header-tool ${activeTool=='open-tool' ? 'active' :""}`} 
					onClick={() => { selectedAircraft ? openFileDialog() : openTooltip()}}>
					
					<SkyModal 
					key="fd-message"
					modalVisible={FDMessage}
					disableScrollbar={true}
					onClose={() => setFDMessage(false)} 
					title='Information' 
					className="fd-message">
						<span>Please select aircraft first</span>
					</SkyModal>
					<FolderOutlined/>
				</div>

				<div 
					className={`cockpit-header-tool ${activeTool=='battery-tool' ? 'active' :""}`} 
					onClick={() => {setActiveTool(activeTool === 'battery-tool' ? null : 'battery-tool');}}>
					{batteryPercentage >= 0 && batteryPercentage <= 5 && <BatteryAlertOutlined className='red'/>}
					{batteryPercentage >= 6 && batteryPercentage <= 25 && <Battery20 className='orange'/>}
					{batteryPercentage >= 26 && batteryPercentage <= 45 && <Battery30Outlined className='yellow'/>}
					{batteryPercentage >= 46 && batteryPercentage <= 55 && <Battery50Outlined className='green'/>}
					{batteryPercentage >= 56 && batteryPercentage <= 75 && <Battery60Outlined className='green'/>}
					{batteryPercentage >= 76 && batteryPercentage <= 85 && <Battery80Outlined className='green'/>}
					{batteryPercentage >= 86 && batteryPercentage <= 95 && <Battery90Outlined className='green'/>}
					{batteryPercentage >= 96 && batteryPercentage <= 100 && <BatteryFullOutlined className='green'/>}
				
					{ activeTool === 'battery-tool' && 
					<CockpitHeaderPanel title='Battery Information'>
						{batteryData && batteryData.map((battery, index) => battery.map((data, index) => (
							<div className='data-info-rowed' key={`battery-data-info-${index}`}>
								<p className='uppercase data-info-name'>
									{data.name}
								</p>
								<p>
									{data.value} {data.unit}
								</p>
							</div>
							))
						)}
					</CockpitHeaderPanel>
			}
				</div>

				<div 
					className={`cockpit-header-tool ${activeTool=='satellite-info' ? 'active' :""}`} 
					onClick={() =>{setActiveTool(activeTool === 'satellite-info' ? null : 'satellite-info');}}>
					<SatelliteAltIcon/>
						{ activeTool === 'satellite-info' && 
						<CockpitHeaderPanel title="GPS Status">
							
								<div key={`satellite-data-info`}>
								{
									gpsData.latitude != null ?
									<>
										<div className="data-info-rowed">
											<p className='uppercase data-info-name'>Latitude:</p>
											<p className='uppercase'>{ gpsData.latitude.toFixed(7) }</p>
										</div>
																				
										<div className="data-info-rowed">
											<p className='uppercase data-info-name'>Longitude:</p>
											<p className='uppercase'>{ gpsData.longitude.toFixed(7) }</p>
										</div>
										<div className="data-info-rowed">
											<p className='uppercase data-info-name'>Altitude:</p>
											<p className=''> { gpsData.altitude.toFixed(2) }m </p>
										</div>
										<div className="data-info-rowed">
											<p className='uppercase data-info-name'>H dilution:</p>
											<p className='uppercase'>{ gpsData.horizontalDilution || '?' }</p>
										</div>
										<div className="data-info-rowed">
											<p className='uppercase data-info-name'>V dilution:</p>
											<p className='uppercase'>   { gpsData.verticalDilution || '?' }</p> 
										</div>
										<div className="data-info-rowed">
											<p className='uppercase data-info-name'>Visible sat:</p>
											<p className='uppercase'>  { gpsData.numSatellites || '?' }</p>
											{/* <p className='uppercase data-info-name'>Ground Speed:</p>
																					<p className='uppercase'>  { gps.groundSpeed || '?' }m/s</p>
																					*/}
										</div>
										<div className="data-info-rowed">
											<p className='uppercase data-info-name'>H acc:</p>
											<p className=''>  { gpsData.hAcc || '?' } m</p>
										</div>
										<div className="data-info-rowed">
											<p className='uppercase data-info-name'>V acc:</p>
											<p className=''>  { gpsData.vAcc || '?' } m</p>
										</div>
									</>
                               
                                :
                                
                                    <p key='gps-missing'>GPS data not available</p>
                                
                            }
									
							</div>
							
						</CockpitHeaderPanel>
					}					
				</div>
				<div 
					className={`cockpit-header-tool { ${activeTool=='signal-tool' ? 'active' :""}`} 
					onClick={() => {setActiveTool(activeTool === 'signal-tool' ? null : 'signal-tool');}}>
					{signalStrength === null && <SignalCellular0BarOutlined className='salmon'/>}
					{signalStrength === 0 && <SignalCellular0Bar className='red'/>}
					{signalStrength === 1 && <SignalCellular1Bar className='red'/>}
					{signalStrength === 2 && <SignalCellular2Bar className='orange'/>}
					{signalStrength === 3 && <SignalCellular3Bar className='yellow'/>}
					{signalStrength === 4 && <SignalCellular4Bar className='green'/>}
					{signalStrength === 5 && <SignalCellular4Bar className='green'/>}
					{/* {signalStrength == 86 && signalStrength <= 100 && <SignalCellular4Bar className='green'/>} */}
				
					{ activeTool === 'signal-tool' && 
					<CockpitHeaderPanel title='Signal Information'>
							<div className='data-info-rowed'>
								<p className='uppercase data-info-name'>
									Signal Strength: </p>
								<span className="bold"> {signalStrength ? signalStrength : "No data"}</span>
							</div>
							<div className='data-info-rowed'>
								<p className='uppercase data-info-name'>
									Connection Type: </p>
								<span className="bold"> {connectionType} </span>
								
							</div>
						{/* {batteryData && batteryData.map((battery, index) => battery.map((data, index) => (
							<div className='data-info-rowed' key={`battery-data-info-${index}`}>
								<p className='uppercase data-info-name'>
									{data.name}
								</p>
								<p>
									{data.value} {data.unit}
								</p>
							</div>
							))
						)} */}
					</CockpitHeaderPanel>
			}
				</div>
				<div 
					className={`cockpit-header-tool ${activeTool=='status-state' && 'wider'} ${activeTool=='status-state' ? 'active' :""}`} 
					onClick={() =>{setActiveTool(activeTool === 'status-state' ? null : 'status-state');}}>
					<MonitorHeart/>
						{ activeTool === 'status-state' && 
						<CockpitHeaderPanel title="Status Messages">
							<div className='status-state-content'>
								{(selectedAircraft.statusState.getState().logs && selectedAircraft.statusState.getState().logs.length > 0) ? 
									selectedAircraft.statusState.getState().logs.map((log, index) => (
									
										<div className={`status-state-item`} key={`status-state-item-${index}`}>
											<p className={`status-state-item-status uppercase bold
											${log.status == 'good' && 'green'} 
											${log.status == 'bad' && 'red'}
											${log.status == 'info' && 'green'}`}>
												[{log.status == 'good' ? "OK" : log.status == 'bad' ? "NOT OK" : "INFO"}] 
												<span className='blue'> {log.timestamp.toISOString()} </span>
											</p>
											
											<span className="white"> {log.message}</span>
										</div>
							
									)) : <p>No status messages</p>}
								
							</div>
						</CockpitHeaderPanel>
						}
				</div>

			{ isVisibleOpenDialog &&
				<FileDialog 
					dialogType="load" 
					schema={missionSchema} 
					entityName={"mission"} 
					onLoad={(mission) => { selectedAircraft.loadMissionPlan({ id: mission.id, ...mission.data }) }} 
					onClose={() => {setIsVisibleOpenDialog(false); setActiveTool(null);}}/>
			}
		
			</FileSystemContextProvider>
		</motion.div>
	)
}
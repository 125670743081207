import React, { createContext, useContext, useRef, useEffect, useState } from "react";
import axios from "axios";
import UtilityService from "../helpers/utility-service";
import { ConfigContext } from "./config-context";
import { FleetManagementContext } from "./fleet-management-context";
import { ConstructionOutlined, ContentPasteSearchOutlined } from "@mui/icons-material";
import { toast } from "react-toastify";
import { MissionContext } from "./mission-context";
const { fromUrl } = require('geotiff');

export const RiskAssessmentContext = createContext();

const RiskAssessmentContextProvider = ({ children }) =>{
	const { services: {
		riskService: {
			host,
			port,
		}
	}} = useContext(ConfigContext);
	const [groundRiskScore, setGroundRiskScore] = useState(null);
	const [riskAssessmentPoints, setRiskAssessmentPoints] = useState(null);
	const [isBusy, setIsBusy] = useState(false);
	const [groundImpactDensity, setGroundImpactDensity] = useState(null);

	const { autopilots } = useContext(FleetManagementContext);
	
	const currentAircraftId = useRef(null);
	const currentSamplingDistance = useRef(50.0);
	const currentParachuteTerminalVelocity = useRef(5.0);
	const aircraftData = useRef(null);
	const weatherData = useRef(null);
	const [sampledPointIndex, setSampledPointIndex] = useState(null);
	const currentDensityType = useRef(null);
	const currentWindSpeed = useRef(null);
	const currentWindDirection = useRef(null);
	const currentCalculateBallisticDescent = useRef(null);
	const currentCalculateUncontrolledGlide = useRef(null);
	const currentCalculateParachuteDescent = useRef(null);

	const evaluateGroundRisk = async (
		selectedAircraftType,
		path, 
		samplingDistance, 
		parachuteTerminalVelocity, 
		windSpeed, 
		windDirection,
		{ calculateBallisticDescent=true, calculateUncontrolledGlide=false, calculateParachuteDescent=true }
	) => {
		// "waypoints": [{"lat": 41.347929, "lon": 19.846724, "altitude": 0.0, "horizontal_planned_speed": 20, "vertical_planned_speed": 2.5},...]
		setIsBusy(true);
		currentCalculateBallisticDescent.current = calculateBallisticDescent;
		currentCalculateUncontrolledGlide.current = calculateUncontrolledGlide;
		currentCalculateParachuteDescent.current = calculateParachuteDescent;

		// const selectedAircraft = allAircraft.find(el => el.id === aircraftId);
		// const selectedAircraftType = aircraftTypes.find(el => el.id === selectedAircraft.data.type);
		console.log(selectedAircraftType);
		const selectedAutopilot = autopilots.find(el => el.id === selectedAircraftType.data.autopilot);

		// currentAircraftId.current = aircraftId;
		currentSamplingDistance.current = samplingDistance;
		currentParachuteTerminalVelocity.current = parachuteTerminalVelocity;
		currentWindSpeed.current = windSpeed;
		currentWindDirection.current = windDirection;

		const preparedPath = path.map(
			point => ({ 
				lat: point.latitude, 
				lon: point.longitude, 
				altitude: point.agl,
				horizontal_planned_speed: point.speed, 
				vertical_planned_speed: 2.5, // TOOD: Get from aircraft config
			}))

		const aircraftTypeConfig = selectedAircraftType.data.fields.reduce((prev, curr) => {
			prev[curr.param] = curr.value;
			return prev;
		}, {});

		console.log(selectedAircraftType);
		console.log(aircraftTypeConfig);

		const autopilotConfig = selectedAutopilot.data.fields.reduce((prev, curr) => {
			prev[curr.param] = curr.value;
			return prev;
		}, {});		

		if (
			autopilotConfig.platform_type == null 
			|| selectedAutopilot.data.title == null
			|| selectedAutopilot.data.version == null
			|| autopilotConfig.speed_up == null
			|| autopilotConfig.speed_down == null
			|| autopilotConfig.waypoint_radius == null
			|| aircraftTypeConfig.drag_coefficient == null
			|| aircraftTypeConfig.mass == null
		) {
			toast.error('Missing configuration for selected aircraft');
			setIsBusy(false);
			return;
		}

		const samplingRequestData = {
			waypoints: preparedPath,
			autopilot: {
				autopilot_type: selectedAutopilot.data.title.toLowerCase(), // "ardupilot",
				autopilot_version: selectedAutopilot.data.version, // "4.0.7",
				configuration: {
					platform_type: autopilotConfig.platform_type, // "copter",
					speed_up: parseFloat(autopilotConfig.speed_up), // 5.0,
					speed_down: parseFloat(autopilotConfig.speed_down), // 4.0,
					waypoint_radius: parseFloat(autopilotConfig.waypoint_radius), // 0.0
				}
			},
			sampling_distance: parseFloat(samplingDistance),
		}

		console.log(samplingRequestData);


		// Sample points at RISK:/trajectory_sampling/
		let samplingPoints = [];
		try {
			const { sampling_points } = (await axios.post(`${host}:${port}/trajectory_sampling/`, samplingRequestData)).data;
			samplingPoints = sampling_points;

			console.log(samplingPoints)
		} catch(err) {
			console.log(err);
			setIsBusy(false);
			return;
		}
		// const samplingPoints = [];

		aircraftData.current = {
			aircraft_type: aircraftTypeConfig.aircraft_type, // "fixed_wing",
			parachute_terminal_velocity: parachuteTerminalVelocity, // 5.0
			drag_coefficient: parseFloat(aircraftTypeConfig.drag_coefficient), //0.7,
			failure_case_probabilities: {
				pr_ballistic_descent: aircraftTypeConfig.pr_ballistic_descent, // 0.005,
				pr_uncontrolled_glide: aircraftTypeConfig.pr_uncontrolled_glide, //0.001,
				pr_parachute_descent: aircraftTypeConfig.pr_parachute_descent// 0.001,
			},
			frontal_area: parseFloat(aircraftTypeConfig.frontal_area), // 0.3,
			glide_ratio: parseFloat(aircraftTypeConfig.glide_ratio), // 10.0,
			length: parseFloat(aircraftTypeConfig.length), // 1.5,
			mass: parseFloat(aircraftTypeConfig.mass), // 13.5,
			navigation_performance: {
				tse_x_scale: parseFloat(aircraftTypeConfig.tse_x_scale), // 10.0,
				tse_y_scale: parseFloat(aircraftTypeConfig.tse_y_scale), //20.0
			},
			width: parseFloat(aircraftTypeConfig.width), // 2.5
		};

		weatherData.current = {
			"wind_direction": {
				"kappa": 3.82,
				"mean": windDirection * Math.PI / 180,
			},
			"wind_speed": {
				"mean": windSpeed,
				"scale": 3
			}
		};

		const riskRequestData = {
			aircraft_data: aircraftData.current,
			analysis_type: "ballistic_descent",
			sampling_points: samplingPoints,
			weather_data: weatherData.current,
		};

		console.log(riskRequestData);
		try {
			const ball = calculateBallisticDescent ? (await axios.post(`${host}:${port}/ground_risk_analysis/`, riskRequestData)).data : {};
			console.log(ball);
			const { expected_ground_fatalities: ballisticDescent, risk_vector_scaled: ballisticVector } = ball;
			const { expected_ground_fatalities: uncontrolledGlide, risk_vector_scaled: uncontrolledVector } = calculateUncontrolledGlide ? (await axios.post(`${host}:${port}/ground_risk_analysis/`, { ...riskRequestData, analysis_type: 'uncontrolled_glide' })).data : {};
			const { expected_ground_fatalities: parachuteDescent, risk_vector_scaled: parachuteVector } = calculateParachuteDescent ? (await axios.post(`${host}:${port}/ground_risk_analysis/`, { ...riskRequestData, analysis_type: 'parachute_descent' })).data : {};

			console.log(ballisticVector);

			setGroundRiskScore({ 
				totalScore: Math.max(
					calculateBallisticDescent ? ballisticDescent : 0, 
					calculateUncontrolledGlide ? uncontrolledGlide : 0, 
					calculateParachuteDescent ? parachuteDescent : 0
				), 
				ballisticDescent, 
				uncontrolledGlide, 
				parachuteDescent,
			});

			setRiskAssessmentPoints(samplingPoints.map((point, index) => ({ 
				...point,
				ballisticDescent: calculateBallisticDescent ? ballisticVector[index] : 0 ,
				uncontrolledGlide: calculateUncontrolledGlide ? uncontrolledVector[index] : 0,
				parachuteDescent: calculateParachuteDescent ? parachuteVector[index] : 0,
				groundRiskScore: Math.max(
					calculateBallisticDescent ? ballisticVector[index] : 0, 
					calculateUncontrolledGlide ? uncontrolledVector[index] : 0, 
					calculateParachuteDescent ? parachuteVector[index] : 0),
			})), 10);
		} catch (err) {
			console.log(err);
		}

		setIsBusy(false);
	}

	const getGroundImpactDensity = async (pointIndex, analysisType) => {
		setGroundImpactDensity(null);
		setTimeout(async () => {
			if (sampledPointIndex === pointIndex && currentDensityType.current == analysisType) {
				currentDensityType.current = null
				setSampledPointIndex(null);
				return;
			} else 

			setSampledPointIndex(pointIndex);
			currentDensityType.current = analysisType;
			
			const samplingPoint = { 
				...riskAssessmentPoints[pointIndex], 
				groundRiskScore: undefined,
				ballisticDescent: undefined,
				uncontrolledGlide: undefined,
				parachuteDescent: undefined,
			};

			const requestData = {
				aircraft_data: aircraftData.current,
				sampling_point: samplingPoint,
				analysis_type: analysisType,
				weather_data: weatherData.current,
			}

			const res = (await axios({
				method: 'POST',
				url: `${host}:${port}/ground_impact_density/`,
				responseType: 'blob',
				data: requestData })).data;

			const blobData = new Blob([res]);
			const url = window.URL.createObjectURL(blobData);

			const image = await fromUrl(url)
			const [values] = await image.readRasters();
			const minValue = Math.min(...values);
			const maxValue = Math.max(...values) + 1e-10;
			setGroundImpactDensity({ url, minValue, maxValue });
		},10);
	}

	return (
		<RiskAssessmentContext.Provider value={{
			groundRiskScore,
			setGroundRiskScore,
			riskAssessmentPoints,
			setRiskAssessmentPoints,
			evaluateGroundRisk,
			currentSamplingDistance: currentSamplingDistance.current,
			currentParachuteTerminalVelocity: currentParachuteTerminalVelocity.current,
			isBusy,
			getGroundImpactDensity,
			groundImpactDensity,
			sampledPointIndex,
			currentDensityType: currentDensityType.current,
			currentWindSpeed: currentWindSpeed.current,
			currentWindDirection: currentWindDirection.current,
			currentCalculateBallisticDescent: currentCalculateBallisticDescent.current,
			currentCalculateParachuteDescent: currentCalculateParachuteDescent.current,
			currentCalculateUncontrolledGlide: currentCalculateUncontrolledGlide.current,
		}}>
			{ children }
		</RiskAssessmentContext.Provider>
	)
}

export default RiskAssessmentContextProvider
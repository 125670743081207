import React, { createContext, useContext, useEffect, useRef, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { Waypoint } from "../helpers/waypoint";
import { RallyPoint } from "../helpers/rally-point";
import { TerrainContext } from "./terrain-context";
import { AuthContext } from "./auth-context";
import { ConfigContext } from "./config-context";
import { MapContext } from "./map-context";
import { AircraftContext } from "./aircraft-context";
import { FileSystemContext } from "./file-system-context";
import UtilityService from "../helpers/utility-service";
import { RiskAssessmentContext } from "./risk-assessment-context";
import { useLocation } from "react-router-dom";
import { AuthContextV2 } from "./auth-context-v2";
import { CockpitContext } from "./cockpit-context";

const defaultFlightPath = {
	id: UtilityService.generateId(),
	title: 'Primary path',
	waypoints: [],
	isPrimary: true,
};

const defaultMissionMeta = {
	id: null,
	title: null,
	description: null,
	aircraftId: null
};

export const MissionContext = createContext();

const MissionContextProvider = ({ children }) => {
	const { getToken } = useContext(AuthContextV2);
	const { setRiskAssessmentPoints, setGroundRiskScore } = useContext(RiskAssessmentContext);

	const {
		services: {
			missionPlanService: {
				host,
				port,
				audience,
				scopes: {
					getAllMissionPlans: getAllMissionPlansScope,
					saveMissionPlan: saveMissionPlansScope,
				}
			}
		}
	} = useContext(ConfigContext);

	const {
		addSegment,
		updateSegment,
		removeSegment,
		createNewTerrainPath,
		deleteTerrainPath,
		selectTerrainPath,
		createMultipleTerrainPaths,
		clearTerrainPath,
		setCurrentCockpitPath, 
		setCurrentMissionPath
	} = useContext(TerrainContext);

	const {selectedPath} = useContext(CockpitContext);

	const { selectedAircraft } = useContext(AircraftContext);
	const [missionAircraftType, setMissionAircraftType] = useState(null);

	const { moveToLocation } = useContext(MapContext);
	const { getFolders, allMissions } = useContext(FileSystemContext);

	const [error, setError] = useState(null);
	const [flightPaths, setFlightPaths] = useState([{ ...defaultFlightPath, waypoints: [] }]);
	const [rallyPoints, setRallyPoints] = useState([]);
	const [currentPath, setCurrentPath] = useState(flightPaths[0]);
	const [missionMeta, setMissionMeta] = useState(defaultMissionMeta);
	const [selectedWaypoint, setSelectedWaypoint] = useState(null);
	const [altitudeDatum, setAltitudeDatum] = useState('AGL');
	const [isSaved, setIsSaved] = useState(true);
	const [missionWarningsMap, setMissionWarningsMap] = useState({})
	const secondaryPathsCounter = useRef(0);
	const location = useLocation();

	// useEffect(() => {
	// 	if(location.pathname === '/cockpit-v2') {
	// 		setCurrentCockpitPath(selectedPath);
	// 		console.log(selectedPath);
	// 	} else if(location.pathname === '/mission-planning') {
	// 		setCurrentMissionPath(selectedPath);
	// 		console.log(selectedPath);
	// 	}
			
	// }, [location, selectedPath])

	// const [selectedToPoint, setSelectedToPoint] = useState(null);
	
	// useEffect(() => {
	// 	if (missionPlanningAircraft != null && allMissions != null && allMissions.length > 0) {
	// 		// List available aircraft missions
	// 		const missions = allMissions
	// 			.filter(mission =>
	// 				mission.data.missionAircraftType != null
	// 				&& mission.data.missionAircraftType.id == missionPlanningAircraft.data.type
	// 			);

	// 		const availableflightPaths = [].concat(...missions
	// 			.map(mission => (
	// 				mission.data.flightPaths.map(path => ({
	// 					waypoints: path.waypoints,
	// 					mission: mission.data.title,
	// 					title: path.title,
	// 				}))
	// 			)))
	// 			.filter(path => path.waypoints.length > 0)
	// 			.map(path => ({
	// 				...path, toPoint: {
	// 					latitude: path.waypoints[0].latitude,
	// 					longitude: path.waypoints[0].longitude,
	// 					altitude: path.waypoints[0].altitude,
	// 					speed: path.waypoints[0].speed,
	// 				}
	// 			}
	// 			))
	// 			.sort((a, b) => {
	// 				return UtilityService.distance({
	// 					latitude: a.toPoint.latitude,
	// 					longitude: a.toPoint.longitude
	// 				},
	// 					{
	// 						latitude: b.toPoint.latitude,
	// 						longitude: b.toPoint.longitude
	// 					}, 0.5)
	// 			})

	// 		const takeOffPoints = [];

	// 		for (const path of availableflightPaths) {
	// 			if (takeOffPoints.length === 0
	// 				|| UtilityService.distance({
	// 					latitude: path.toPoint.latitude,
	// 					longitude: path.toPoint.longitude,
	// 				},
	// 					{
	// 						latitude: takeOffPoints[takeOffPoints.length - 1].toPoint.latitude,
	// 						longitude: takeOffPoints[takeOffPoints.length - 1].toPoint.longitude,
	// 					}, 0.5) > 2) {
	// 				takeOffPoints.push({
	// 					paths: [path],
	// 					toPoint:
	// 						new Waypoint(
	// 							path.toPoint.latitude,
	// 							path.toPoint.longitude,
	// 							`T/O`,
	// 							path.toPoint.altitude,
	// 							path.toPoint.speed,
	// 							0,
	// 							true,
	// 						),
						
	// 				})
	// 			} else {
	// 				takeOffPoints[takeOffPoints.length - 1].paths.push(path);
	// 			}
	// 		}

	// 		// console.log(takeOffPoints);

	// 		setAvailableMissions({ takeOffPoints });
	// 	} else {
	// 		setSelectedToPoint(null);
	// 		setAvailableMissions(null);
	// 		setSelectedPath(null);
	// 	}
	// }, [missionPlanningAircraft, allMissions]);

	// const selectToPoint = (toPoint) => {
	// 	setSelectedToPoint(toPoint);
	// 	setSelectedPath(null);
	// }

	// const selectPath = (path) => {
	// 	setSelectedPath(path)
	// 	createMultipleTerrainPaths([path]);
	// }


	/**
	 * Update take off / landing markers
	 */
	const updatePath = (path) => {
		(path || currentPath).waypoints.forEach((wp, index) => {
			if (index == 0) {
				wp.isTakeOff = true;
				wp.isLanding = false;
			}
			else if (index == (path || currentPath).waypoints.length - 1) {
				wp.isTakeOff = false;
				wp.isLanding = true;
			}
			else {
				wp.isTakeOff = false;
				wp.isLanding = false;
			}
		});
		setCurrentPath({ ...currentPath });
	}

	/**
	 * Add new waypoint to path
	 * @param {} latitude 
	 * @param {*} longitude 
	 * @param {*} label 
	 */
	const addWaypoint = (latitude, longitude, index = null, label = null, altitude = null, speed = null, shouldUpdatePath = true) => {
		const { waypoints } = currentPath;

		let wpAltitude = altitude;

		if (altitude == null) {
			if (index == null) {
				if (currentPath.waypoints.length > 0) {
					// [..., wp]
					if (altitudeDatum != 'AGL') {
						wpAltitude = currentPath.waypoints[currentPath.waypoints.length - 1].altitude;
					} else {
						wpAltitude = 'X';
					}
				}
				// [wp]
			} else {
				if (index <= currentPath.waypoints.length - 1) {
					if (index > 0) {
						// [..., wp, ...]
						wpAltitude = Math.round((currentPath.waypoints[index - 1].altitude + currentPath.waypoints[index].altitude) / 2);
					} else {
						// [wp, ...]
						wpAltitude = currentPath.waypoints[index].altitude;
					}
				} else {
					// [..., wp]
					if (index > 0) {
						wpAltitude = currentPath.waypoints[index - 1].altitude;
					}
				}
			}
		}

		const waypoint = new Waypoint(parseFloat(latitude.toFixed(7)), parseFloat(longitude.toFixed(7)), label, wpAltitude, speed);
		// console.log("WP FORM ADD",waypoint);
		if (index == null) {
			waypoints.push(waypoint);
			console.log("WPS", waypoints)
		} else {
			waypoints.splice(index, 0, waypoint);
		}

		if (shouldUpdatePath) {
			updatePath()
		}

		setRiskAssessmentPoints([]);
		setGroundRiskScore(null);
		setCurrentPath({ ...currentPath });
		addSegment(waypoint, index != null ? index : waypoints.length - 1);
		setIsSaved(false);
	}

	/**
	 * Update waypoint data
	 * @param {*} waypoint 
	 * @param {*} updateObj 
	 */
	const updateWaypoint = (waypointIndex, {
		position,
		label,
		altitude,
		speed,
	}) => {
		console.log('Updating waypoint');
		console.log({
			position,
			label,
			altitude,
			speed,
		});
		console.log(altitudeDatum);

		const waypoint = currentPath.waypoints[waypointIndex];

		if (position != null) {
			const { latitude, longitude } = position;
			waypoint.latitude = latitude;
			waypoint.longitude = longitude;
			waypoint.agl = null;
			waypoint.amsl = null;
			updateSegment(waypoint, waypointIndex);
			// TODO: Update elevation
		}

		if (label) {
			waypoint.label = label;
		}

		let dif = 0;
		if (altitude != null) {
			switch (altitudeDatum) {
				case 'ALP':
					dif = altitude - waypoint.alp;
					waypoint.alp = altitude;
					waypoint.agl += dif;
					waypoint.amsl += dif;
					break;

				case 'AGL':
					dif = altitude - waypoint.agl;
					waypoint.agl = altitude;
					waypoint.alp += dif;
					waypoint.amsl += dif;
					break;

				case 'AMSL':
					dif = altitude - waypoint.amsl;
					waypoint.amsl = altitude;
					waypoint.alp += dif;
					waypoint.agl += dif;
					break;
				default: break;
			}
			waypoint.altitude = waypoint.alp;
			console.log(waypoint);
		}

		if (speed != null) {
			waypoint.speed = speed;
		}

		setRiskAssessmentPoints([]);
		setGroundRiskScore(null);
		setCurrentPath({ ...currentPath });
		setIsSaved(false);
	}

	/**
	 * Update rally point data
	 * @param {*} waypoint 
	 * @param {*} updateObj 
	 */
	const updateRallyPoint = (rallyPointIndex, {
		position,
		label,
	}) => {
		const rallyPoint = rallyPoints[rallyPointIndex];

		if (position != null) {
			const { latitude, longitude } = position;
			rallyPoint.latitude = latitude;
			rallyPoint.longitude = longitude;

			// TODO: Update elevation
		}

		if (label) {
			rallyPoint.label = label;
		}

		setRallyPoints([...rallyPoints]);
		setIsSaved(false);
	}

	/**
	 * Remove waypoint from current path
	 * @param {*} waypoint 
	 */
	const removeWaypoint = (index, updateTerrain = true) => {
		const { waypoints } = currentPath;
		const removedWaypoint = waypoints[index];

		if (removedWaypoint.isLanding) {
			waypoints[index - 1].isLanding = true;
		}
		if (removedWaypoint.isTakeOff && waypoints.length > 1) {
			waypoints[index + 1].isTakeOff = true;
		}

		waypoints.splice(index, 1);

		updatePath();
		setRiskAssessmentPoints([]);
		setGroundRiskScore(null);
		removeSegment(index, updateTerrain)
		setCurrentPath({ ...currentPath });
		setIsSaved(false);
	}

	/**
	 * Add new rally point
	 * @param {} latitude 
	 * @param {*} longitude 
	 * @param {*} label 
	 */
	const addRallyPoint = (latitude, longitude, label = null) => {
		setRallyPoints([...rallyPoints, new RallyPoint(latitude, longitude, label)]);
		setIsSaved(false);
	}

	/**
	 * Remove rally point
	 * @param {*} rallypoint index
	 */
	const removeRallyPoint = (index) => {
		rallyPoints.splice(index, 1);
		setRallyPoints([...rallyPoints]);
		setIsSaved(false);
	}

	/**
	 * Reorder waypoints
	 * @param {*} fromIndex 
	 * @param {*} toIndex 
	 */
	const moveWaypoint = (fromIndex, toIndex) => {
		if (fromIndex == toIndex) { return };
		const waypoint = currentPath.waypoints[fromIndex];
		removeWaypoint(fromIndex, false);
		addWaypoint(waypoint.latitude, waypoint.longitude, toIndex, waypoint.label, waypoint.altitude, waypoint.speed);

		updatePath();
		setRiskAssessmentPoints([]);
		setGroundRiskScore(null);
		setIsSaved(false);
	}

	/**
	 * Prepare mission object for saving
	 */
	const createMissionObject = () => {
		const missionObject = { ...missionMeta };
		const preparedFlightPaths = [];

		for (let i = 0; i < flightPaths.length; i += 1) {
			const path = flightPaths[i];

			preparedFlightPaths.push({
				title: path.title,
				primary: path.isPrimary ? true : false,
				index: i,
				waypoints: path.waypoints.map(wp => ({
					id: wp.id,
					type: wp.type,
					title: wp.title || wp.label,
					label: wp.label,
					latitude: wp.latitude,
					longitude: wp.longitude,
					altitude: wp.altitude,
					speed: wp.speed,
				}))
			})
		}

		missionObject.flightPaths = preparedFlightPaths;
		missionObject.rtlPoints = rallyPoints.map(rp => ({
			id: rp.id,
			title: rp.title,
			label: rp.label,
			latitude: rp.latitude,
			longitude: rp.longitude,
			altitude: rp.altitude,
			speed: rp.speed,
		}));

		return missionObject;
	}

	/**
	 * Upload current mission plan to connected aircraft
	 */
	const uploadMissionToAircraft = (navigate) => {
		const mission = createMissionObject();

		if (selectedAircraft != null) {
			selectedAircraft.loadMissionPlan(mission);
			navigate('/cockpit');
		}
	}

	/**
	 * Update flight path metadata
	 * @param {} param0 
	 */
	const updateFlightPathInfo = ({ title, isPrimary }) => {
		if (title) currentPath.title = title;
		if (isPrimary != null) currentPath.isPrimary = isPrimary;
		setCurrentPath(currentPath)
		setFlightPaths([...flightPaths]);
		setIsSaved(false);
	}

	/**
	 * Update mission metadata
	 * @param {} param0 
	 */
	const updateMissionMeta = ({ title, description, aircraftId }) => {
		setMissionMeta({
			...missionMeta,
			...(title && { title }),
			...(description && { description }),
			...(aircraftId && { aircraftId }),
		});
		setIsSaved(false);
	}

	/**
	 * Copy existing flight path
	 */
	const copyFlightPath = () => {
		const newPath = {
			...currentPath,
			id: UtilityService.generateId(),
			waypoints: currentPath.waypoints.map(wp => {
				const newWp = new Waypoint(wp.latitude, wp.longitude, wp.label, wp.altitude, wp.speed);
				newWp.isTakeOff = wp.isTakeOff;
				newWp.isLanding = wp.isLanding;
				return newWp;
			}
			),
			isPrimary: false,
			title: `Secondary path (${secondaryPathsCounter.current + 1})`,
		}

		secondaryPathsCounter.current += 1;

		setCurrentPath(newPath);
		setFlightPaths([...flightPaths, newPath]);
		createNewTerrainPath(newPath); // Terrain context
		setIsSaved(false);
	}

	/**
	 * Delete flight path
	 */
	const deleteFlightPath = (pathIndex) => {
		let newCurrentPathIndex = null;
		const { isPrimary } = flightPaths[pathIndex];

		if (flightPaths.length > 1) {
			if (pathIndex < flightPaths.length - 1) {
				newCurrentPathIndex = pathIndex + 1;
				if (isPrimary) {
					flightPaths[newCurrentPathIndex].isPrimary = true;
				}
				setCurrentPath(flightPaths[newCurrentPathIndex]);
			} else {
				newCurrentPathIndex = pathIndex - 1;
				if (isPrimary) {
					flightPaths[newCurrentPathIndex].isPrimary = true;
				}
				setCurrentPath(flightPaths[newCurrentPathIndex]);
			}



			flightPaths.splice(pathIndex, 1);
			setFlightPaths([...flightPaths]);

			setRiskAssessmentPoints([]);
			setGroundRiskScore(null);
			deleteTerrainPath(pathIndex) // Terrain context
			setIsSaved(false);
		}
	}

	/**
	 * Select flight path as current
	 */
	const selectFlightPath = (pathIndex) => {
		// console.log(currentPath);
		setCurrentPath(flightPaths[pathIndex]);
		selectTerrainPath(pathIndex);// Terrain context

	}

	/** 
	 * Load mission plan from server
	 */
	const loadMissionPlan = (missionPlan) => {
		const {
			id,
			data: {
				title,
				description,
				aircraftId,
				missionAircraftType,

				flightPaths,
				rtlPoints,
			}
		} = missionPlan;

		setMissionWarningsMap([]);

		setMissionMeta({
			id,
			title,
			description,
			aircraftId,
		});

		setMissionAircraftType(missionAircraftType);

		const loadedFlightPaths = flightPaths.map(fp => ({
			title: fp.title,
			isPrimary: fp.primary,
			waypoints: fp.waypoints.map(wp => new Waypoint(wp.latitude, wp.longitude, wp.label, wp.altitude, wp.speed)),
		}));

		loadedFlightPaths.forEach(path => updatePath(path))

		const loadedRallyPoints = rtlPoints.map(rp => new RallyPoint(rp.latitude, rp.longitude, rp.label));

		if (loadedFlightPaths.length > 0) {
			setCurrentPath(loadedFlightPaths[0]);
			setFlightPaths(loadedFlightPaths);

			createMultipleTerrainPaths(loadedFlightPaths);
			moveToLocation(loadedFlightPaths[0].waypoints[0]);
		} else {
			const newDefaultFlightPath = { ...defaultFlightPath, waypoints: [] };
			updatePath(flightPaths);
			setCurrentPath(newDefaultFlightPath);
			setFlightPaths(newDefaultFlightPath);
		}

		setRiskAssessmentPoints([]);
		setGroundRiskScore(null);
		setRallyPoints(loadedRallyPoints);
		setIsSaved(true);
		toast.success(`${title} loaded`);
	}

	/**
	 * Fetch saved mission plans from server
	 */
	const fetchSavedMissionPlans = async () => {
		const token = getToken(getAllMissionPlansScope, audience)

		try {
			const res = await axios.get(`${host}:${port}/mission-plan`, {
				headers: {
					Authorization: `Bearer ${token}`,
				}
			});
			return res.data;
		} catch (err) {
			console.log(err);
			setError(err.message);
			return null;
		}
	}

	const getMissionFolders = async () => {
		const token = getToken(getAllMissionPlansScope, audience)

		try {
			const res = await axios.get(`${host}:${port}/mission-plan`, {
				headers: {
					Authorization: `Bearer ${token}`,
				}
			});
			return res.data;
		} catch (err) {
			console.log(err);
			setError(err.message);
			return null;
		}



		// const { showLoading, selectedFolder } = this.state;

		// if (!showLoading) {
		// 		this.setState({ showLoading: true });
		// }

		// const { entityName: rawEntity } = this.props;
		// const entity = rawEntity == 'Mission' ? 'mission' : 'flight-path';

		// fileManagementService.getFolders(entity).then(data => {
		// 		console.log(data);
		// 		this.setState({ 
		// 				folderTree: data, 
		// 				selectedFolder: selectedFolder ?  data.find(folder => folder.id == selectedFolder.id) : data[0], 
		// 				showLoading: false 
		// 		});
		// }).catch(err => this.setState({ showLoading: false }));
	}

	/**
	 * Save current mission plan
	 */
	const saveMissionPlan = async (filename = null, folderId = null, overwrite = false, onComplete = null) => {
		const missionObject = createMissionObject();

		console.log(missionMeta);
		console.log('adsadasdasda');

		// Save new mission
		if (!overwrite) {
			const token = getToken(saveMissionPlansScope, audience)

			try {
				const res = await axios.post(`${host}:${port}/mission-plan`,
					{ ...missionObject, title: filename, folderId, missionAircraftType },
					{
						headers: {
							Authorization: `Bearer ${token}`,
						}
					}
				);

				const { id } = res.data.data;
				setMissionMeta({ ...missionMeta, id, folderId, title: filename });
				setIsSaved(true);
				toast.success('Mission saved');
				if (onComplete) {
					onComplete();
				}
			} catch (err) {
				console.log(err);
				setError(err.message);
				toast.error('Failed to save mission');
				return false;
			}

			// Overwrite existing mission
		} else {
			console.log(missionMeta);
			if (missionMeta.id == null) {
				throw new Error('Mission not previously saved');
			}

			const token = getToken(saveMissionPlansScope, audience)

			try {
				const res = await axios.put(`${host}:${port}/mission-plan/${missionMeta.id}`,
					{ ...missionObject, title: filename || missionMeta.title,  missionAircraftType },
					{
						headers: {
							Authorization: `Bearer ${token}`,
						}
					}
				);

				toast.success('Mission saved');
				setIsSaved(true);
			} catch (err) {
				console.log(err);
				setError(err.message);
				toast.error('Failed to save mission');
				return false;
			}
		}

		await getFolders('mission');
		return true;
	}

	/**
	 * Set current path as primary
	 */
	const setPrimaryPath = () => {
		if (!currentPath.isPrimary) {
			flightPaths.find(path => path.isPrimary).isPrimary = false;
			currentPath.isPrimary = true;
			setCurrentPath({ ...currentPath });
		}
	}

	/**
	 * Add new mission warning
	 */
	const addMissionWarning = (key, warningObj) => {
		if (missionWarningsMap[key] == null) {
			missionWarningsMap[key] = warningObj;
			setMissionWarningsMap({ ...missionWarningsMap });
		}
	}

	/**
	 * Remove existing mission warning
	 */
	const removeMissionWarning = (key) => {
		if (missionWarningsMap[key] != null) {
			delete missionWarningsMap[key];
			setMissionWarningsMap({ ...missionWarningsMap });
		}
	}

	const clearCurrentPath = () => {
		const currentPathIndex = flightPaths.findIndex(el => el == currentPath);
		const newPath = { ...currentPath, waypoints: [] };
		flightPaths[currentPathIndex] = newPath;
		setRiskAssessmentPoints([]);
		setMissionWarningsMap([]);
		setGroundRiskScore(null);
		clearTerrainPath();
		setCurrentPath(newPath);
	}

	const clearRallyPoints = () => {
		setRallyPoints([]);
	}

	const createNewMission = () => {
		clearCurrentPath();
		setMissionWarningsMap([]);
		const initialPath = { ...defaultFlightPath, waypoints: [] };
		setCurrentPath(initialPath);
		setFlightPaths([initialPath]);
		setRallyPoints([]);
		setMissionMeta({ ...defaultMissionMeta });
		setSelectedWaypoint(null);
		setIsSaved(true);
	}

	return (
		<MissionContext.Provider value={{
			error,
			missionMeta,
			flightPaths,
			currentPath,
			rallyPoints,
			isSaved,
			addWaypoint,
			moveWaypoint,
			updateWaypoint,
			removeWaypoint,
			addRallyPoint,
			updateRallyPoint,
			removeRallyPoint,
			selectWaypoint: setSelectedWaypoint,
			createMissionObject,
			uploadMissionToAircraft,
			updateFlightPathInfo,
			updateMissionMeta,
			deleteFlightPath,
			selectFlightPath,
			loadMissionPlan,
			fetchSavedMissionPlans,
			copyFlightPath,
			setPrimaryPath,
			saveMissionPlan,
			altitudeDatum,
			setAltitudeDatum,
			getMissionFolders,
			missionWarnings: Object.values(missionWarningsMap),
			addMissionWarning,
			removeMissionWarning,
			clearCurrentPath,
			clearRallyPoints,
			createNewMission,
			missionAircraftType,
			setMissionAircraftType,
		
			
		
		}}>
			{children}
		</MissionContext.Provider>
	)
}

export default MissionContextProvider;
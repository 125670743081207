
import React, {useState} from 'react';


export const OverviewButton = ({status, icon, name, onClick}) => {


    return (
        <div className='overview-button-wrap' onClick={() => onClick()}>
            <span className={`status-indicator ${ status == 'bad' && 'blink' } ${status}`}></span>
            <span className="button-icon">{icon && icon}</span>
            {name && name}
        </div>

    )
}
import React from "react";
import { Marker, Tooltip } from 'react-leaflet'
import Icons from "./icons";
import { Point } from "./point";

export class RallyPoint extends Point {
	constructor(latitude, longitude, label=null, seq=null) {
		super(latitude, longitude, 'RTL', label, null, null, seq);
	}

	getIconUrl() {
		return Icons.regular.rtlIcon;
	}

	getMarker(options) {
		return (
			<Marker 
				className="rallypoint-marker"
				key={`rallypoint-marker-${this.id}`}
				opacity={options.opacity || 1} 
				eventHandlers={{
					click: (e) => {
						if (options.onClick) {
							options.onClick(e, this);
						}
					},
					dragend: (e) => {
						if (options.onPositionUpdated) {
							const { lat: latitude, lng: longitude } = e.target._latlng.wrap();
							options.onPositionUpdated(e, { latitude, longitude });
						}
					}
				}}
				draggable={options.draggable != null ? options.draggable : true}
				position={[this.latitude, this.longitude]} 
				icon={Icons.map.rtlIcon}
			>
				{ options.markerLabelsVisible &&
						<Tooltip 
						className="marker-tooltip"
						direction="right" 
						opacity={1} 
						offset={[-15,30]}
						permanent
					>
						<p className="waypoint-tooltip">
							{ options.index != null && <span>RTL{options.index }</span> }
						</p>
					</Tooltip>
				}

			</Marker>
		)
	}
}
import React, { useContext, useEffect, useRef, useState } from "react"

import {SkyModal} from "../../../../shared-components/containers/sky-modal/sky-modal";
import { PanelTabs, Tab } from "../../../../shared-components/containers/panel-tabs/panel-tabs";
// import InputIcon from "../ui-layout/input-icon/input-icon";

// import {FleetManagementActionButtons} from "../fleet-management-action-buttons/fleet-management-action-buttons";
import {Loading} from "../../../../shared-components/layout/loading/loading";
import {TableFrame} from "../../../../shared-components/containers/table-frame/table-frame";
import {ListInput} from '../../../../shared-components/inputs/list-input/list-input';


import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { InputField } from "../../../../shared-components/inputs/input-field/input-field";

import UtilityService from '../../../../helpers/utility-service';
import { FleetManagementActionButtons } from "../fleet-management-action-buttons/fleet-management-action-buttons";
import { FleetManagementContext } from "../../../../contexts/fleet-management-context";
import { StatusTokenLabel } from "../status-token-label/status-token-label";


export const FleetManagementEditModal = (
		{schema, 
		data: originalData,
		title, 
		modalVisible, 
		numCols, 
		entityName, 
		shouldDisplayImage, 
		noImage, 
		additionalTabs,
		onClose,
		loadSingleItem,
		transformObject,
		itemId
	 }) => {

	const [data, setData] = useState(originalData);

	const clearDisabledAttributes = (obj, attributes) => {
		const objCopy = {...obj};
		for (const att of attributes) {
			console.log(att.key);
			if (att.disabled || att.fixed || att.autogenerated) {
				delete objCopy[att.key];
			}
		}

		Object.keys(obj).filter(key => key.includes('-displayValue')).forEach(key => { delete objCopy[key] });

		console.log(objCopy);
		return objCopy;
	}


		// const [itemId, setItemId] = useState(itemId);
		const [disableInputs, setDisableInputs] = useState(true);
		const [showLoader, setShowLoader] = useState(false);
		// const [data, setData] = useState(null);
		const [remoteSelects, setRemoteSelects] = useState(
			schema.attributes.filter(att => att.type === 'remote-select').reduce((prev, att) =>
				{
					const refId = att.refId || UtilityService.generateId();
					att.refId = refId;
					prev[refId] = {
						loadData: att.options.loadData,
						keyPath: att.options.keyPath,
						labelPath: att.options.labelPath,
						options: [],
					};

					return prev;
				}, {})
		);

		const [remoteTables, setRemoteTables] = useState({});
		// console.log("EDIT MDOAL",schema);
		// const localTitle = useRef(title);
		// const entityName = useRef(entityName);
		const viewNumCols = numCols || 1;
		// this.title = this.props.title;
		// this.entityName = this.props.entityName;

		// const { schema } = this.props;
		// const { remoteSelects, remoteTables } = this.state;

		const fleetManagementContext = useContext(FleetManagementContext);

		const pendingObj = useRef(clearDisabledAttributes(data, schema.attributes));
		
	// 	this.loadRemoteSelects();
	// 	this.reload();

	useEffect(() => {
		loadRemoteSelects();
	},[data]);
	
	const reload = async () => {
		const rawData = await loadSingleItem(itemId);
		const data = transformObject(rawData);
		pendingObj.current = clearDisabledAttributes(data, schema.attributes);
	}

	const loadRemoteSelects = async () => {
		for (const refId of Object.keys(remoteSelects)) {
			const remoteSelect = remoteSelects[refId];
			const { loadData, keyPath, labelPath } = remoteSelect;

			const rawData = loadData(fleetManagementContext);
			remoteSelect.options = rawData.map(el => ({
				key: UtilityService.getFromPath(el, keyPath),
				label: labelPath(el),
			}));
		}

		console.log(remoteSelects);
		setRemoteSelects({...remoteSelects});
	}

	const toggleEditable = () => {
		
		setDisableInputs(!disableInputs);
	}

	const deleteItem = async () => {
		const id = itemId;

		setShowLoader(true)
		setDisableInputs(true);
		
		try {
			await schema.removeSingleItem(fleetManagementContext, id);
			setShowLoader(false);
			toast(`${schema.entityName} removed`, {
				style: {
					backgroundColor: 'green',
					zIndex: 100000,
				},
				autoClose: 3000,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
			});
		} catch(err) {
			console.log(err);
			toast("Failed to delete", {
				style: {
					backgroundColor: 'red',
					zIndex: 100000,
				},
				autoClose: 3000,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				});
		} finally {
			onClose();
			setShowLoader(false);
		}
	}

	const saveEdit = async() => {
		const id = itemId;

		setShowLoader(true);
		setDisableInputs(true);
		
		try {
			console.log(pendingObj.current);
			await schema.updateSingleItem(fleetManagementContext, id, clearDisabledAttributes(pendingObj.current, schema.attributes));
			
			const rawData = await schema.loadSingleItem(fleetManagementContext, id);
			const data = UtilityService.transformObject(rawData, schema.attributes);
			pendingObj.current = clearDisabledAttributes(data, schema.attributes);
			setData(data);

			toast(`${schema.entityName} updated`, {
				style: {
					backgroundColor: 'green',
					zIndex: 100000,
				},
				autoClose: 3000,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				});
		} catch(err) {
			console.log(err);
			toast("Failed to update", {
				style: {
					backgroundColor: 'red',
					zIndex: 100000,
				},
				autoClose: 3000,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				});
		} finally {
			setShowLoader(false);
		}
	}

	const executeAction = async (actionFunc, elId) => {
		try {
			await actionFunc(fleetManagementContext, elId);
			setTimeout(() => {
				const id = itemId;
				
				const rawData = schema.loadSingleItem(fleetManagementContext, id);
				console.log(rawData);
				const data = UtilityService.transformObject(rawData, schema.attributes);
				console.log({...data});
				pendingObj.current = clearDisabledAttributes(data, schema.attributes);
				setData(data);
				toast(`${schema.entityName} Updated`, {
					style: {
						backgroundColor: 'green'
					},
					autoClose: 3000,
					hideProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					});
				},100);
			} catch(err) {
				console.log(err);
				toast("Failed to update", {
					style: {
						backgroundColor: 'red'
					},
					autoClose: 3000,
					hideProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					});
			} finally {
				setShowLoader(false);
			}
	}


		

		return (
			
			<SkyModal 
				className='modal-rpaircraft'
				title={title || 'Unknown Title'}
				modalVisible={modalVisible}
				onClose={() => onClose()}
				editButtonsActive={true}
				disableScrollbar={true}
				disableInputs={disableInputs}
				sendInfo={async ()=> await saveEdit()}
				editInfo={()=>toggleEditable()}
				removeItem={async ()=>await deleteItem()}
				labelText={`Edit ${entityName}`}

				// actionButtons={[
				// {
				// 	title: "Cancel", 
				// 	action: () => onClose(),
				// 	btnType:"secondary",

				// },{
				// 	title: "Cancel", 
				// 	action: () => onClose(),
				// 	btnType:"secondary",

				// },{
				// 	title: "Cancel", 
				// 	action: () => onClose(),
				// 	btnType:"secondary",

				// }]} 
				>
				<div className='modal-edit-wrap'>
					<PanelTabs>
						<Tab title='Details' scrollable={true}>
							<div className="tab-content-container">
								{/* <PerfectScrollbar> */}
							{/* <PerfectScrollbar> */}
								<div className='fm-details'>

								{
									data == null || showLoader &&
										<Loading position="absolute"/>
								}

									<div className='edit-info-wrap'>
									{ data && shouldDisplayImage && (
										<div className='modal-rpaircraft-image-wrap'>
										{ data.image ? (
												<img src={data.image} alt='RPA'/>
										 ) : 
										(	
											
												<img src={noImage} alt='rpaircraft'/>
											
										)}

											{ !disableInputs &&
												<div className="edit-image">
													<label>Upload image</label>
													<input 
														type="file"
														onChange={(e) => pendingObj.current.image = e.target.files[0]}
													/>
												</div>
											}
										</div>
									) }
									{ data && UtilityService.chunkArray(schema.attributes, Math.ceil(schema.attributes.length / (schema.attributes.length / 7))).map((slice, sliceIndex) => (
										<div className='edit-info' key={`slice-${sliceIndex}`}>
											{/* <PerfectScrollbar option={{ wheelPropagation: false }}> */}
											{
												slice.filter(att => !att.hidden).map((att, index) => {
													switch(att.type) {
														case 'text': 
														case 'number': 
														case 'datetime-local': 
														case 'time': 
														case 'file': 
														return (
															// <InputIcon 
															<InputField
																className='filled-input'
																// id={`${schema.entityName}-details-${att.name}`}
																placeholder={att.name}
																defaultValue={data[att.key]}
																type={att.type === 'file' ? 'text' : att.type}
																min={att.min}
																max={att.max}
																step={att.step || 1}
																onChange={(e) =>pendingObj.current[att.key] = (att.type != 'file' ? e.target.value : e.target.files[0])}
																alt=''
																disabled={disableInputs || att.disabled || att.type === 'file' }
																label={att.name}/>
														)
														case 'list':
															return (
																<ListInput editable={!disableInputs}
																	items={data[att.key] || []}
																	onUpdate={(updatedList) => {
																		console.log(updatedList);
																		pendingObj.current[att.key] = updatedList
																	}}
																	schema={att.itemSchema}
																	title={att.name}
																/>
															)
														case 'select':
															return (
																<div className='details-select'>
																	<label htmlFor='personnel-details-role'>{att.name}</label>
																	<select 
																		className='filled-input needsclick'
																		placeholder={att.name}
																		defaultValue={data[att.key]} 
																		style={{ padding: 0, color: 'white' }}
																		onChange={(e) => { pendingObj.current[att.key] = e.target.value }}
																		disabled={disableInputs || att.disabled}
																	>
																		{
																				
																				att.options.map((option) => 
																						<option key={option.key}>{option.label}</option>
																				)
																		}
																	
																	</select>
																</div>
															
															) 
															case 'remote-select':
																return (
																	<div className='details-select'>
																		<label htmlFor='details-role'>{att.name}</label>
																		<select 
																			className='filled-input needsclick'
																			placeholder={att.name} 
																			style={{ padding: 0, color: 'white' }}
																			onChange={(e) => { e.target.value === '-' ? pendingObj.current[att.key] = null : pendingObj.current[att.key] = e.target.value }}
																			disabled={disableInputs || att.disabled}
																		>
																			<option key={null} value={null}>-</option>
																			{
																					remoteSelects && remoteSelects[att.refId].options.map((option) => 
																							<option selected={data[att.key] === option.key} key={option.key} value={option.key}>{option.label}</option>
																					)
																			}
																		
																	</select>
																</div>
															)
														case 'table':
															return (
																<div>
																	<label htmlFor='personnel-details-role'>{att.name}</label>
																
																	<TableFrame 
																		tableHeader={att.attributes.filter(att => !att.hidden && !['remote-table', 'table'].includes(att.type)).map(att => att.name)}
																		className='single-battery-set-table-info'>
																		{
																			data[att.key].map((tableRow, rowIndex) => (
																				<tr key={Math.random()} className='pointer'>
																				{
																						att.attributes.filter(att => !att.hidden && !['remote-table', 'table'].includes(att.type)).map((tableAttribute, fieldIndex) => (
																								<td key={`${schema.entityName}-row-${rowIndex}-${fieldIndex}`}>
																										{ 
																												tableAttribute.key == 'cycles' ? 
																														<StatusTokenLabel label = {tableRow[tableAttribute.key]} color={UtilityService.calcCycleColor(parseInt(tableRow[tableAttribute.key]))}/>
																														: 
																															tableAttribute.type === 'button' ?
																															(<button onClick={async () => await executeAction(tableAttribute.action, tableRow.id)} className="primary">{tableAttribute.label}</button>)
																															:
																															tableRow[tableAttribute.key]
																										}
																								</td>
																						))
																				}
																				</tr>
																			))
																	}
																	</TableFrame>
																</div>
															)
														default: return(null)
													}
												})
											}
										</div>
									))}
									</div>

									{/* <FleetManagementActionButtons 
											disableInputs={disableInputs}
											sendInfo={()=>saveEdit()}
											editInfo={()=>toggleEditable()}
											removeItem={()=>deleteItem()}
											labelText={`Edit ${entityName}`}
									/> */}
							</div>
							</div>
						</Tab>
						{ additionalTabs.map(el => 
						(<Tab
							scrollable={true}
						 title={el.title}>
							 <div className="tab-content-container">
								
							 		<el.component id={itemId} />
								
							</div>
						 </Tab>
						))
						}
					</PanelTabs>
				
				</div>
			
			 </SkyModal> 
		)
	
}

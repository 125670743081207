import React from "react";
import { Speed, Polyline, Home, AirplanemodeActive, List, Person, Paragliding } from '@mui/icons-material'

const appUri = 'https://gcs.skyynetwork.io';
const config = {
	version: '0.1.6',
	logs: false,

	loginRoute: `https://skyy-api.auth.eu-central-1.amazoncognito.com/oauth2/authorize?client_id=1k3pjtmcoai6lmf7td01aph09n&response_type=token&scope=skyy%2Fremote_id.read+skyy%2Fremote_id.write&redirect_uri=${appUri}/verify`,
	logoutRoute: `https://skyy-api.auth.eu-central-1.amazoncognito.com/logout?client_id=1k3pjtmcoai6lmf7td01aph09n&response_type=token&redirect_uri=${appUri}/verify`,
	services: {
		authService: {
			// host: 'http://ec2-3-17-13-212.us-east-2.compute.amazonaws.com',
			host: 'http://localhost',
			port: 10001,
		},
		riskService: {
			// host: 'http://gcs.skyy.network',
			host: 'http://skyyriskserver-env.eba-jmunqk3m.us-east-2.elasticbeanstalk.com',
			port: 80,
		},
		telemetryService: {
			host: 'http://localhost',
			// host: 'http://ec2-3-142-189-246.us-east-2.compute.amazonaws.com',
			port: 10010,
		},
		airportService: { 
			metarHost: 'https://www.aviationweather.gov/adds/dataserver_current/httpparam',
			tafHost: 'https://www.aviationweather.gov/adds/dataserver_current/httpparam',
			sigmetHost: 'https://www.aviationweather.gov/adds/dataserver_current/httpparam',
			notamHost: 'https://www.notams.faa.gov/dinsQueryWeb/icaoRadiusSearchMapAction.do',
			maxAirportProximity: 20, // miles
			maxReportAge: 7200, // seconds
			minReportAge: 600,  // seconds
			maxReportValidityRadius: 3000, // m
		},
		weatherService: {
			currentWeatherHost: 'https://api.weatherapi.com/v1/current.json',
			forecastHost: 'https://api.weatherapi.com/v1/forecast.json',
			key: 'c99ff5aa7c884ff488b161220222401',
			minReportAge: 600, // seconds
			maxReportValidityRadius: 3000, // m
		},
		flightService: {
			host: 'https://mission.skyynetwork.io',
			// host: 'http://localhost',
			port: 443,
			// host: 'http://localhost',
			// port: 10002,
			audience: 'uss.skyy.network.services.mission_service',
			scopes: {
				getAllFlights: 'gcs.mission_planning'
			}
		},
		missionPlanService: {
			host: 'https://mission.skyynetwork.io',
			// host: 'http://localhost',
			port: 443,
			audience: 'uss.skyy.network.services.mission_service',
			scopes: {
				getAllMissionPlans: 'gcs.mission_planning',
				saveMissionPlan: 'gcs.mission_planning',
			}
		},
		terrainService: {
			host: 'https://api.airmap.com/elevation/v1/ele',
			terrainResolution: 30,
		},
		c2Service: {
			// host: 'ec2-18-220-119-126.us-east-2.compute.amazonaws.com',
			// host: 'localhost',
			host: 'wss://b6f27b1088394f93817da422efc3794e.s2.eu.hivemq.cloud',
			// port: 9001,
			port: 8884,
			// audience: 'uss.skyy.network.services.c2_service',
			// scopes: {
			// 	c2Connection: 'gcs.c2',
			// },
			connectionTimeouts: {
				LTE: 5, // seconds
				SERIAL: 5, // seconds
				SAT: 30, // seconds
			}
		},
		geocodeService: {
			host: 'https://nominatim.openstreetmap.org/search',
		},
		fleetManagementService: {
			host: 'https://mission.skyynetwork.io',
			port: 443,
			// host: 'http://localhost',
			// port: 10002,
			audience: 'uss.skyy.network.services.mission_service',
			scopes: {
				getAircraft: 'gcs.fleet_management',
				getAircraftTypes: 'gcs.fleet_management',
				getAutopilots: 'gcs.fleet_management',
				getBatteries: 'gcs.fleet_management',
				getBatterySets: 'gcs.fleet_management',
				getEquipment: 'gcs.fleet_management',
				getPersonnel: 'gcs.fleet_management',
				getMaintenance: 'gcs.fleet_management',
				getDocuments: 'gcs.fleet_management',
				getChecklists: 'gcs.mission_planning',
			},
		},
		adsbService: {
			host: 'https://aleksandar.skyy:skyyaccess@opensky-network.org/api'
		},
		corsService: {
			host: 'https://mission.skyynetwork.io',
			// host: 'http://localhost',
			port: 443,
			scope: 'gcs.mission_planning',
			audience: 'uss.skyy.network.services.mission_service',
		},
	},
	credentials: [
		{
			audience: 'uss.skyy.network.services.mission_service',
			label: 'Mission Service Credentials',
		},
		{
			audience: 'uss.skyy.network.services.dp_service',
			label: 'Display Provider Service Credentials'
		},
		{
			audience: 'uss.skyy.network.services.c2_service',
			label: 'C2 Service Credentials',
		},
	],
	mapConfig: {
		defaultMapCenter: { 
			latitude: 43.00000,
			longitude: 20.00000, 
		},
		defaultZoom: 14,
		mapTileProviders: [
			{ 
				label: 'Satellite', 
				uri: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}', 
				attribution: 'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community',
				thumbnail: require('./assets/map/thumbnails/satellite-map.png'),
			},
			{ 
				label: 'Street Dark', 
				uri: 'https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png?api_key=54e49560-0096-4260-9e56-84d5a68e8fcb', 
				attribution: '&copy; <a href="https://stadiamaps.com/">Stadia Maps</a>, &copy; <a href="https://openmaptiles.org/">OpenMapTiles</a> &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors',
				thumbnail: require('./assets/map/thumbnails/dark-map.png'),
			},
			{ 
				label: 'Topo', 
				uri: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}', 
				attribution: 'Tiles &copy; Esri &mdash; Esri, DeLorme, NAVTEQ, TomTom, Intermap, iPC, USGS, FAO, NPS, NRCAN, GeoBase, Kadaster NL, Ordnance Survey, Esri Japan, METI, Esri China (Hong Kong), and the GIS User Community',
				thumbnail: require('./assets/map/thumbnails/topo-map.png'),
			},
			// { 
			// 	label: 'VFR', 
			// 	uri: 'http://ec2-3-129-73-112.us-east-2.compute.amazonaws.com:8080/geoserver/gwc/service/tms/1.0.0/SkyyRiskAnalysis%3Apopulation_density@EPSG%3A900913@png/{z}/{x}/{-y}.png', 
			// 	attribution: 'Tiles &copy; Esri &mdash; Esri, DeLorme, NAVTEQ, TomTom, Intermap, iPC, USGS, FAO, NPS, NRCAN, GeoBase, Kadaster NL, Ordnance Survey, Esri Japan, METI, Esri China (Hong Kong), and the GIS User Community',
			// 	thumbnail: require('./assets/map/thumbnails/topo-map.png'),
			// },
		]
	},
	weatherOverlays: {
		precipitation: {
			label: 'Precipitation',
			uri: 'http://tile.openweathermap.org/map/precipitation_new/{z}/{x}/{y}.png?appid=7a040e51e244fc74df6c0bfe06a0e591',
		},
		clouds: {
			label: 'Clouds',
			uri: 'http://tile.openweathermap.org/map/clouds_new/{z}/{x}/{y}.png?appid=7a040e51e244fc74df6c0bfe06a0e591',
		}
	},
	homepageShortcuts: [
		{
			label: 'Mission Planning',
			path: '/mission-planning',
			icon: (<Polyline/>),	
		},
		{
			label: 'Cockpit panel',
			path: '/cockpit',
			icon: (<Speed/>),	
		}
	],
	menuItems: [
		{
			path: '/home',
			icon: (<Home/>),
		},
		{
			path: '/mission-planning',
			icon: (<Polyline/>),
		},
		{
			path: '/cockpit',
			icon: (<Speed/>),
		},
		{
			path: '/cockpit-v2',
			icon: (<Paragliding/>),
		},
		{
			path: '/fleet-management',
			icon: (<AirplanemodeActive/>),
		},
		{
			path: '/flights',
			icon: (<List/>),
		},
		// {
		// 	path: '/login',
		// 	icon: (<Person/>),
		// }
	]
};

export default config;
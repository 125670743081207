import React, { useState, useContext } from "react";
import { motion } from "framer-motion";
import { PanelTabs, Tab } from "../../shared-components/containers/panel-tabs/panel-tabs";
import { InputField } from "../../shared-components/inputs/input-field/input-field";
import { FlightLogs } from "./components/flight-logs/flight-logs";
import { ScheduleFlight } from "./components/schedule-flight/schedule-flight";
import { FlightContext } from "../../contexts/flight-context";

export const FlightsScreen = () => {
	const { flights } = useContext(FlightContext);
	
	console.log(flights);

	/*
	{
    "id": "9eabd618-c94d-4ccb-9b39-54c718517c91",
    "isAuthorized": true,
    "aircraftId": "AC1",
    "userId": "operator1",
    "pilotId": "Pilot1",
    "status": "SCHEDULED",
    "data": {
        "notes": [],
        "title": "Flight 1",
        "endTime": "2022-02-01T00:10:00.000",
        "payload": [],
        "equipment": [],
        "startTime": "2022-02-01T00:00:00.000",
        "batterySet": "B1",
        "description": "Desc",
        "missionPlan": {
            "id": "M1",
            "rtl": [],
            "title": "Mission 1",
            "flightPaths": []
        },
        "authorizationId": "8b2935c5-7c0a-4d12-b299-2f34ef4b5f2f",
        "riskEstimations": [
            {
                "path": "Primary path",
                "groundRiskScore": 0.00000123
            }
        ]
    },
    "createdAt": "2022-05-09T15:55:23.000Z",
    "updatedAt": "2022-05-09T16:00:21.000Z"
}
	*/

	// const [flights] = useState([
	// 	{
	// 		flightId: 'W98CH6RW87E',
	// 		operation: 'Vila delivery',
	// 		status: 'planned',
	// 		startTime: '2022-01-01T01:23:45.012',
	// 		endTime: '2022-01-01T01:28:22.311',
	// 		operator: 'operator1',
	// 		aircraft: 'SKYY005283',
	// 	},
	// 	{
	// 		flightId: 'W98CH6RW87E',
	// 		operation: 'Vila delivery',
	// 		status: 'complete',
	// 		startTime: '2022-01-01T01:23:45.012',
	// 		endTime: '2022-01-01T01:28:22.311',
	// 		operator: 'operator1',
	// 		aircraft: 'SKYY005283',
	// 	},
	// 	{
	// 		flightId: 'W98CH6RW87E',
	// 		operation: 'Vila delivery',
	// 		status: 'canceled',
	// 		startTime: '2022-01-01T01:23:45.012',
	// 		endTime: '2022-01-01T01:28:22.311',
	// 		operator: 'operator1',
	// 		aircraft: 'SKYY005283',
	// 	}
	// ])

	return (
		<div className="flights-motion-wrap" key='flights-motion-wrap'>
			<motion.div
					key="flights-motion"
					initial={{ y:-500, opacity:0}}
					animate={{ y:0 , opacity:1}}
					exit={{ y:-500, opacity:0 }}
					transition={{ duration:0.3, delay: 0.15, ease: 'easeInOut'}}		
			>
				<div className="flights-screen">
					<h1 className="page-title">Flights</h1>

					<div className="flights-content">
						<PanelTabs>
						<Tab
							scrollable={true}
						 	title="FLIGHT LOGS">
								 <motion.div
								key="schedule-flight-step0"
								initial={{ y:-500, opacity:0}}
								animate={{ y:0 , opacity:1}}
								exit={{ y:-500, opacity:0 }}
								transition={{ duration:0.2, delay: 0.1, ease: 'easeInOut'}}
								
								>
									<FlightLogs className='tab-content-container' flights={flights} />
								</motion.div>
						 </Tab>

						 <Tab
							scrollable={true}
						 	title="SCHEDULE FLIGHT">
								<motion.div
								key="schedule-flight-step0"
								initial={{ y:-500, opacity:0}}
								animate={{ y:0 , opacity:1}}
								exit={{ y:-500, opacity:0 }}
								transition={{ duration:0.2, delay: 0.1, ease: 'easeInOut'}}
								// className='schedule-flight-motion'		
								>
								<ScheduleFlight className='tab-content-container' flights={flights}/>
							</motion.div>
						 </Tab>
						</PanelTabs>
					</div>
				</div>
			</motion.div>
		</div>
	)
}
import React, { createContext, useEffect, useRef, useState } from "react";

export const InterfaceContext = createContext();

const InterfaceContextProvider = ({ children }) => {
    const [infoDockOpened, setInfoDockOpened] = useState(true);
	const [mapOptionsVisible, setMapOptionsVisible] = useState(false);
	const [menuBarVisible, setMenuBarVisible] = useState(false);
	const [detailedView, setDetailedView] = useState(true);
	const [activeFlightsPanelVisible, setActiveFlightsPanelVisible] = useState(true);
	const [scheduledFlightsPanelVisible, setScheduledFlightsPanelVisible] = useState(false);


	return (
		<InterfaceContext.Provider value={{
			detailedView, 
			setDetailedView, 
			infoDockOpened, 
			setInfoDockOpened, 
			mapOptionsVisible, 
			setMapOptionsVisible, 
			menuBarVisible, 
			setMenuBarVisible,
			activeFlightsPanelVisible,
			setActiveFlightsPanelVisible,
			scheduledFlightsPanelVisible,
			setScheduledFlightsPanelVisible,

			}}>
			{ children }
		</InterfaceContext.Provider>
	)
}

export default InterfaceContextProvider;
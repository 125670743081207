export const autopilotSchema = {
	entityName: 'Autopilot',
	loadSingleItem: (fleetManagementContext, id) => fleetManagementContext.getSingleAutopilot(id),
	updateSingleItem: async (fleetManagementContext, id, data) => await fleetManagementContext.updateAutopilot(id, data),
	removeSingleItem: async (fleetManagementContext, id) => await fleetManagementContext.removeAutopilot(id),
	createSingleItem: async (fleetManagementContext, data) => await fleetManagementContext.createAutopilot(data),

	attributes: [
		{   
			key: 'id',
			path: 'id',
			name: 'ID',
			type: 'text',
			hidden: true,
			disabled: true,
			autogenerated: true,
		},
        {   
			key: 'title',
			path: 'data.title',
			name: 'Title',
			type: 'text',
			hidden: false,
			disabled: false,
			autogenerated: false,
		},
        {   
			key: 'version',
			path: 'data.version',
			name: 'Version',
			type: 'text',
			hidden: false,
			disabled: false,
			autogenerated: false,
		},
        {
			key: 'fields',
			path: 'data.fields',
			name: 'Autopilot config',
			editOnly: true,
			type: 'list',
			itemSchema: [{
				key: 'param',
				title: 'Parameter',
				type: 'text',
			}, 
			{
				key: 'value',
				title: 'Value',
				type: 'text',
			}]
		},
    ]
}
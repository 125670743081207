import { Fab } from '@mui/material';
import React from 'react';


export const ZoomControlButton = ({icon, onClick}) => {

    return(
        // <div className='zoom-control-button-wrap' onClick={() => onClick()}>
            <Fab className='zoom-control-button-wrap' aria-label='zoom-control' color="primary" onClick={() => onClick()}>{icon}</Fab>
        // </div>
    )
}
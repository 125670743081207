// HEARTBEAT (#0)
export function systemTime (message, aircraft) {
	const {
        time_unix_usec,		// Timestamp (UNIX epoch time).
		time_boot_ms, 		// Timestamp (time since system boot)
	} = message;

    aircraft.time_unix_usec = time_unix_usec;
    aircraft.time_boot_ms = time_boot_ms;
}

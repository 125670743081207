import {
	createStore
} from "redux";
import {
	toast
} from "react-toastify";
import MessageProcessors from "./message-processors/mavlink/copter/index";
import MAVLinkConstants from "./protocol-services/mavlink-constants";

// General state reducer
import generalStateReducer, {
	createSetters as createGeneralSetters
} from "./reducers/mavlink/copter/general-state-reducer";

// Position state reducer
import positionStateReducer, {
	createSetters as createPositionSetters
} from "./reducers/mavlink/copter/position-state-reducer";

// Position state reducer
import missionStateReducer, {
	createSetters as createMissionSetters
} from "./reducers/mavlink/copter/mission-state-reducer";

// Confirmation state reducer
import confirmationStateReducer, {
	createSetters as createConfirmationSetters
} from "./reducers/mavlink/copter/confirmation-state-reducer";

// VFR HUD state reducer
import hudStateReducer, {
	createSetters as createHudSetters
} from "./reducers/mavlink/copter/hud-state-reducer";

// Attitude state reducer
import attitudeStateReducer, {
	createSetters as createAttitudeSetters
} from "./reducers/mavlink/copter/attitude-state-reducer";

// Battery state reducer
import batteryStateReducer, {
	createSetters as createBatterySetters
} from "./reducers/mavlink/copter/battery-state-reducer";

// GPS raw state reducer
import gpsRawStateReducer, {
	createSetters as createGpsRawSetters
} from "./reducers/mavlink/copter/gps-raw-state-reducer";

// Status state reducer
import statusStateReducer, {
	createSetters as createStatusSetters
} from "./reducers/mavlink/copter/status-state-reducer";

import AircraftConnection from "./aircraft-connection";
import {
	Waypoint
} from "../waypoint";
import {
	RallyPoint
} from "../rally-point";
import UtilityService from "../utility-service";

class Aircraft {
	constructor(
		operator,
		aircraftData,
		aircraftId,
		aircraftName,
		initialConnectionType,
		c2ServiceConfig,
		connectingUser,
		protocol = 'mavlink',
		listeners,
		port,
		config = null,
		isVirtual = false,
		playbackLogs = [],
	) {
		this.connectionId = UtilityService.generateId();
		this.aircraftId = aircraftId;
		this.aircraftName = aircraftName;
		this.initialConnectionType = initialConnectionType;
		this.c2ServiceConfig = c2ServiceConfig;
		this.connectingUser = connectingUser;
		this.protocol = protocol;
		this.port = port;

		this.connection = null;
		this.config = config;

		this.listeners = listeners;

		this.connectionCaller = null
		this.connectionTimeout = null;

		this.operator = operator;
		this.aircraftData = aircraftData;

		console.log(aircraftData);

		// Redux stores
		// ------------------------------

		// General state
		this.generalState = createStore(generalStateReducer);
		this.generalStateSetters = createGeneralSetters(this.generalState);

		// Position state
		this.positionState = createStore(positionStateReducer);
		this.positionStateSetters = createPositionSetters(this.positionState);

		// Mission state
		this.missionState = createStore(missionStateReducer);
		this.missionStateSetters = createMissionSetters(this.missionState);

		// Waiting for confirmation state
		this.confirmationState = createStore(confirmationStateReducer);
		this.confirmationStateSetters = createConfirmationSetters(this.confirmationState);

		// VFR HUD state
		this.hudState = createStore(hudStateReducer);
		this.hudStateSetters = createHudSetters(this.hudState);

		// Attitude state
		this.attitudeState = createStore(attitudeStateReducer);
		this.attitudeStateSetters = createAttitudeSetters(this.attitudeState);

		// Battery state
		this.batteryState = createStore(batteryStateReducer);
		this.batteryStateSetters = createBatterySetters(this.batteryState);

		// GPS raw state
		this.gpsRawState = createStore(gpsRawStateReducer);
		this.gpsRawStateSetters = createGpsRawSetters(this.gpsRawState);

		// Status state
		this.statusState = createStore(statusStateReducer);
		this.statusStateSetters = createStatusSetters(this.statusState);

		this.heartbeatInterval = null;

		this.location = {
			latitude: 44,
			longitude: 22
		};

		this.pendingAcks = {};
		this.pendingMissionAcks = [];
		this.expectingMissionTypeItems = null;
		this.missionCount = {
			mission: 0,
			rally: 0,
		}
		this.commandTimers = {};

		this.forwardMovingInterval = null;

		this.pendingUploadedItems = {
			mission: [],
			rally: []
		};

		this.toastIds = {
			connectionToastId: null
		};

		// Virtual aircraft params
		this.isVirtual = isVirtual;
		this.playbackLogs = playbackLogs;

		this.wasConnected = false;
	}

	/**
	 * Confirm aircraft connection
	 */
	setConnected(isConnected, silent = false, tmp = false) {
		console.log('SET CONNECTED', silent, tmp)
		const {
			isConnected: currentConnected
		} = !this.generalState.getState();
		if (currentConnected == isConnected) {
			return;
		}

		if (isConnected) {
			if (this.heartbeatInterval == null) {
				this.heartbeatInterval = setInterval(() => { this.connection.heartbeat()}, 1000)
			}
			this.wasConnected = true;
			setTimeout(() => {
				console.log(this.generalState.getState().connectionType);
				if (this.generalState.getState().connectionType != 'SAT') {
					this.downloadMissionPlan();
				}
			}, 1500);
			if (this.connectionCaller != null) {
				this.connectionCaller.resolve();
				this.connectionCaller = null;
			}
			if (!silent) {
				if (this.toastIds.connectionToastId) {
					toast.update(this.toastIds.connectionToastId, {
						render: `${this.aircraftName} connected`,
						type: toast.TYPE.SUCCESS,
						autoClose: 2000,
						isLoading: false,
						closeButton: true,
					});
					this.toastIds.connectionToastId = null;
				} else {
					toast.success(`${this.aircraftName} connected`);
				}
			}

			if (this.listeners.onConnected) {
				this.listeners.onConnected(this);
			}
		} else {
			if (this.connectionTimeout) {
				clearInterval(this.connectionTimeout);
				this.connectionTimeout = null;
			}
			if (this.heartbeatInterval != null) {
				clearInterval(this.heartbeatInterval);
				this.heartbeatInterval = null;
			}
			if (!silent) {
				toast.info(`${this.aircraftName} disconnected`);
			}
			if (this.listeners.onDisconnected) {
				this.listeners.onDisconnected(this, tmp);
			}
		}
		this.generalStateSetters.setIsConnected(isConnected);
	}

	// Disconnect aircraft
	disconnect(silent = false) {
		const command = () => {
			this.generalStateSetters.setIsDisconnected();
			this.missionStateSetters.setUploadedMissionItems({ mission: [], rally: []});
			if (this.connection) {
				if (this.connectionTimeout) {
					clearInterval(this.connectionTimeout);
					this.connectionTimeout = null;
				}
				if (this.heartbeatInterval != null) {
					clearInterval(this.heartbeatInterval);
					this.heartbeatInterval = null;
				}
				this.connection.disconnect();
				this.setConnected(false, silent);
			}
				// this.setFlightMode('GUIDED', false, () => {
				// 	this.clearMissionItems(false, false, () => {
				// 		this.generalStateSetters.setIsSubscribed(false);
				// 		this.setConnected(false, silent);
				// 		clearTimeout(this.connectionTimeout);
				// 		if (this.connection) {
				// 			this.connection.disconnect();
				// 		}
				// 	})
				// })
			
		};

		if (silent) {
			command();
		} else {
			this.confirmationStateSetters.setWaitingCommand({
				command,
				caption: 'Disconnect from aircraft'
			})
		}
	}

	/**
	 * Subscribe to aircraft messages via C2/Serial channel
	 */
	createConnection() {
		return new Promise((resolve, reject) => {
			const {
				connectionType
			} = this.generalState.getState();

			this.connection = new AircraftConnection(this, connectionType || this.initialConnectionType, this.port, this.config, this.connectingUser, this.isVirtual, this.playbackLogs);
			this.connection.connect()
				.then(() => {
					console.log('Subscribed to aircraft channel');
					this.generalStateSetters.setIsSubscribed(true);
					this.generalStateSetters.setConnectionType(this.connection.connectionType);
					resolve();
				})
				.catch(err => {
					console.log(err);
					toast.error('Failed to subscribe to aircraft channel');
					this.generalStateSetters.setIsSubscribed(false);
					this.generalStateSetters.setIsConnected(false);
					reject();
				})
		});
	}

	/**
	 * Subscribe and wait for aircraft heartbeat
	 */
	connect() {
		this.toastIds.connectionToastId = toast(`Connecting to ${this.aircraftName}`, {
			isLoading: true,
			autoClose: false
		});
		return new Promise((resolve, reject) => {
			let connectionTimeout = null;
			const {
				connectionTimeouts
			} = this.c2ServiceConfig;
			const timeoutPeriod = connectionTimeouts[this.initialConnectionType];

			// Listen to connection status change
			const unsubscribe = this.generalState.subscribe(() => {
				if (this.generalState.getState().isConnected) {
					clearTimeout(connectionTimeout);
					unsubscribe();
					resolve()
				}
			})

			console.log(timeoutPeriod * 1000);

			this.createConnection()
				.then(() => {
					this.generalStateSetters.setConnectionType(this.initialConnectionType);
					connectionTimeout = setTimeout(() => {
						if (this.toastIds.connectionToastId) {
							toast.update(this.toastIds.connectionToastId, {
								render: `No response from the aircraft after ${timeoutPeriod} seconds`,
								isLoading: false,
								type: toast.TYPE.INFO,
								autoClose: 2000
							});
						}
						this.disconnect(true);
						reject();
					}, timeoutPeriod * 1000);
				})
				.catch((err) => {
					toast.update(this.toastIds.connectionToastId, {
						render: `Failed to create connection or no response`,
						isLoading: false,
						type: toast.TYPE.INFO,
						autoClose: 2000
					});
					reject(err)
				});
		})
	}

	/**
	 * Start playback from logs
	 */
	startPlayback(startTime) {
		if (this.isVirtual && this.playbackLogs.length > 0) {
			this.setConnected(true);
			this.connection.startPlayback(startTime);
		}
	}

	/**
	 * Stop playback from logs
	 */
	stopPlayback() {
		if (this.isVirtual && this.playbackLogs.length > 0) {
			this.connection.stopPlayback();
		}
	}

	/**
	 * General message handler
	 * @param {} message 
	 */
	handleMessage(message) {
		const {
			connectionTimeouts
		} = this.c2ServiceConfig;
		const {
			connectionType,
			isConnected,
			isSubscribed,
			isDisconnected,
		} = this.generalState.getState();

		if (isDisconnected) {
			return;
		}

		const timeoutPeriod = connectionTimeouts[connectionType || this.initialConnectionType];

		if (!isConnected && isSubscribed) {
			this.setConnected(true);
		}

		if (this.connectionTimeout) {
			clearTimeout(this.connectionTimeout);
		}

		// Message timeout
		if (isSubscribed) {
			this.connectionTimeout = setTimeout(() => {
				clearTimeout(this.connectionTimeout);
				this.setConnected(false, false, true);
				toast.error(`${this.aircraftName} lost connection, connection timeout`);
			}, timeoutPeriod * 1000);
		}
 
		switch (message._name) {
			case 'HEARTBEAT':
				MessageProcessors.heartbeat(message, this);
				break;
			case 'COMMAND_ACK':
				MessageProcessors.commandAck(message, this);
				break;
			case 'MISSION_ACK':
				console.log('MISSION ACK ACK ACK')
				MessageProcessors.missionAck(message, this);
				break;
			case 'MISSION_REQUEST':
			case 'MISSION_REQUEST_INT':
				MessageProcessors.missionRequestInt(message, this);
				break;
			case 'MISSION_COUNT':
				MessageProcessors.missionCount(message, this);
				break;
			case 'MISSION_CURRENT':
				MessageProcessors.missionCurrent(message, this);
				break;
				// case 'MISSION_ITEM': console.log(message); break;
			case 'MISSION_ITEM_INT':
				MessageProcessors.missionItemInt(message, this);
				break;
			case 'GLOBAL_POSITION_INT':
				MessageProcessors.globalPositionInt(message, this);
				break;
			case 'DISTANCE_SENSOR':
				MessageProcessors.distanceSensor(message, this);
				break;
			case 'VFR_HUD':
				MessageProcessors.vfrHud(message, this);
				break;
			case 'ATTITUDE':
				MessageProcessors.attitude(message, this);
				break;
			case 'BATTERY_STATUS':
				MessageProcessors.batteryStatus(message, this);
				break;
			case 'GPS_RAW_INT':
				MessageProcessors.gpsRawInt(message, this);
				break;
			case 'POSITION_TARGET_GLOBAL_INT':
				MessageProcessors.positionTargetGlobalInt(message, this);
				break;
			case 'SYSTEM_TIME':
				MessageProcessors.systemTime(message, this);
				break;
			case 'ESTIMATOR_STATUS':
				MessageProcessors.estimatorStatus(message, this);
				break;
			case 'SYS_STATUS':
				MessageProcessors.sysStatus(message, this);
				break;
			default:
				break;
		}
	}

	/**
	 * Handle control message
	 * @param {*} message 
	 */
	handleControlMessage(message) {
		// console.log(message);
		// {'route': 'cell', 'cell': {'bars': 4}, 'sat': {'bars': 0}}
		let conType = 'N/A';
		let signalStrength = 0;

		if (message.route === 'cell') {
			conType = 'LTE';
		} else if (message.route === 'sat') {
			conType = 'SAT';
		}

		signalStrength = message[message.route].bars;

		if (conType != this.generalState.getState().connectionType) {
			this.generalStateSetters.setConnectionType(conType);
		}

		if (signalStrength != this.generalState.getState().signalStrength) {
			this.generalStateSetters.setSignalStrength(signalStrength);
		}

		// console.log({ conType, signalStrength });
	}

	/**
	 * Wait for pending command confirmation
	 * @param {*} command 
	 * @param {*} newValue 
	 * @param {*} onAccepted 
	 * @param {*} onFailed 
	 */
	waitForAck(command, newValue, onAccepted, onFailed) {
		if (this.pendingAcks[command] == null) {
			this.pendingAcks[command] = [];
		}
		this.pendingAcks[command].push({
			value: newValue,
			onAccepted,
			onFailed
		});
	}

	/**
	 * Wait for pending mission command confirmation
	 * @param {*} command 
	 * @param {*} onAccepted 
	 * @param {*} onFailed 
	 */
	waitForMissionAck(action, missionType, onAccepted, onFailed) {
		console.log('Pending mission ack:');
		console.log({
			action,
			onAccepted,
			onFailed,
			missionType
		});
		this.pendingMissionAcks.push({
			action,
			onAccepted,
			onFailed,
			missionType
		});
	}

	/**
	 * Execute command on interval, limited number of times
	 * @param {*} command 
	 * @param {*} commandName 
	 * @param {*} delay 
	 * @param {*} maxRepeats 
	 */
	startTimeout(command, commandName, delay = 1000, maxRepeats = 3, timeoutMessage = null, toastId) {
		const {
			commandTimers
		} = this;
		this.stopTimeout(commandName);

		command(0);

		commandTimers[commandName] = {
			repeats: 1,
			maxRepeats: maxRepeats,
			timer: setInterval(() => {
				if (commandTimers[commandName].repeats == commandTimers[commandName].maxRepeats) {
					if (timeoutMessage) {
						if (toastId == null) {
							toast.error('Command acknowledgement not received, ' + timeoutMessage);
						} else {
							toast.update(toastId, {
								render: 'Command acknowledgement not received, ' + timeoutMessage,
								type: toast.TYPE.ERROR,
								autoClose: 2000,
								closeButton: true
							})
						}
						// this.operationProgress = 0; // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
					}
					this.stopTimeout(commandName);
					return;
				}

				command(commandTimers[commandName].repeats);
				commandTimers[commandName].repeats += 1;
			}, delay),
		};
	}

	/**
	 * Stop repeated execution
	 * @param {} commandName 
	 */
	stopTimeout(commandName) {
		const {
			commandTimers
		} = this;

		if (commandTimers[commandName] != null) {
			clearInterval(commandTimers[commandName].timer);
			commandTimers[commandName] = null;
		}
	}

	/**
	 * Load mission plan
	 * @param {} mission 
	 */
	loadMissionPlan(mission) {
		const {
			title,
			description,
			flightPaths, // 		// 1: {id: "5F244A7F-0FA2-4CB9-B675-1B273ED3D786", type: "WP", title: "9133", label: "9133", latitude: 14.060411381337271, …}
			rtlPoints,
		} = mission;

		console.log(mission);
		this.currentMissionMeta = {
			title,
			description,
		}

		for (const path of flightPaths) {
			path.waypoints = path.waypoints.map((wp, index) => {
				const newWp = new Waypoint(wp.latitude, wp.longitude, wp.label, wp.altitude, wp.speed);
				if (index == 0) {
					newWp.isTakeOff = true;
					newWp.isLanding = false;
				}

				if (index == path.waypoints.length - 1) {
					newWp.isTakeOff = false;
					newWp.isLanding = true;
				}
				return newWp;
			});
		}

		this.missionStateSetters.setFlightPaths(flightPaths);
		this.missionStateSetters.setRallyPoints(
			rtlPoints.map(rp => new RallyPoint(rp.latitude, rp.longitude, rp.label, rp.altitude, rp.speed))
		);

		const selectedFlightPath = flightPaths.find(path => path.primary);
		this.missionStateSetters.setSelectedFlightPath(selectedFlightPath);

		this.prepareMissionItems();
	}

	clearPath() {
		const caption = 'Clear current flight trail?';
		const command = () => {
			this.positionStateSetters.setPath([]);
		}

		this.confirmationStateSetters.setWaitingCommand({
			command,
			caption
		});
	}

	// Commands
	// ---------------------------------------------

	_armDisarm(toArm, waitForConfirmation = true, callback = null) {
		return new Promise((resolve, reject) => {
			const {
				isConnected
			} = this.generalState.getState();

			if (isConnected) {
				const {
					customMode
				} = this.generalState.getState();
				if (['LAND'].includes(customMode)) {
					toast.info(`Can't ${toArm ? 'arm' : 'disarm'} in ${customMode} flight mode`);
					return;
				}

				const command = () => {
					const commandName = toArm ? 'armVehicle' : 'disarmVehicle';

					const command = MAVLinkConstants.MAV_CMD.MAV_CMD_COMPONENT_ARM_DISARM;
					this.toastIds.armDisarmToastId = toast(toArm ? "Arming vehicle" : "Disarming vehicle", {
						isLoading: true,
						autoClose: false
					});

					// On armed/disarmed
					const onAccepted = () => {
						this.stopTimeout(commandName);
						this.generalStateSetters.setIsArmed(toArm);

						const title = `Aircraft ${toArm ? 'armed' : 'disarmed'}`;
						toast.update(this.toastIds.armDisarmToastId, {
							render: title,
							isLoading: false,
							type: toast.TYPE.SUCCESS,
							autoClose: 2000
						});

						if (callback != null) {
							callback();
						}
						if (toArm) {
							this.takeOffTime = new Date().toISOString();

							if (this.flightId == null) {
								// this.flightStarted = true;
								// this.flightEnded = false;
								// console.log(this.equipment);
								// fleetManagementService.createFlight({
								// 	status: 'PENDING',
								// 	missionPlan: this.vehicleState.missionPlan || 'Unnamed',
								// 	flightPath: this.vehicleState.flightPath || 'Unnamed',
								// 	equipment: this.equipment,
								// 	payloadWeight: this.payloadWeight,
								// 	pilotId: this.pilotId,
								// 	aircraftId: this.rawId,
								// 	takeOffTime: this.takeOffTime,
								// 	waypoints: this.vehicleState.missionItems, // missionCount
								// 	rtl: this.vehicleState.rallypointItems, // missionRallyCount
								// }).then(res => {
								// 	console.log(this.equipment);
								// 	console.log(res);
								// 	this.flightId = res.data.id
								// });
							}
						} else {
							// if (this.flightId != null) {
							// 	this.flightStarted = false;
							// 	this.flightEnded = true;
							// 	this.takeOffTime = null;
							// 	fleetManagementService.updateFlight(this.flightId,{ 
							// 		status: 'COMPLETE',
							// 		landingTime: new Date().toISOString(),
							// 	}).then(() => this.flightId = null);
							// }
						}
						resolve()
					};

					// On failed to arm/disarm
					const onFailed = (toArm, statusMessage) => {
						this.stopTimeout(commandName);
						const title = `Failed to ${toArm ? 'arm' : 'disarm'} aircraft`;
						// const content = `Command MAV_CMD_COMPONENT_ARM_DISARM returned status: ${statusMessage}`;
						toast.update(this.toastIds.armDisarmToastId, {
							render: title,
							isLoading: false,
							type: toast.TYPE.ERROR,
							autoClose: 2000
						});
						reject();
					};

					// Wait for command ack
					this.waitForAck(command, true, onAccepted, onFailed);

					// Start command timeout
					const maxRepeats = 3;
					const timeoutMessage = `${toArm ? 'Arm' : 'Disarm'} command not acknowledged after ${maxRepeats} attempts`;

					this.startTimeout(
						(confirmation) => toArm ? this.connection.armVehicle(confirmation) : this.connection.disarmVehicle(confirmation),
						commandName,
						1000,
						maxRepeats,
						timeoutMessage
					);
				}

				// Wait for slide-to-confirm
				if (waitForConfirmation) {
					const caption = `${toArm ? 'Arm' : 'Disarm'} vehicle`;
					this.confirmationStateSetters.setWaitingCommand({
						command,
						caption
					});
				} else {
					command();
				}
			} else {
				reject();
			}
		})
	}

	arm(waitForConfirmation = true, callback = null) {
		return this._armDisarm(true, waitForConfirmation, callback);
	}

	disarm(waitForConfirmation = true) {
		return this._armDisarm(false, waitForConfirmation);
	}

	/**
	 * Set flight mode
	 */
	setFlightMode(flightMode, waitForConfirmation = true, callback = null) {
		return new Promise((resolve, reject) => {
			// if (waitForConfirmation && this.vehicleState.waitingCommand != null) {
			// 	return;
			// }

			// console.log(flightMode);
			// console.log(this.generalState.getState().systemStatus.label);

			if (flightMode == 'STABILIZE' && this.generalState.getState().systemStatus != null && this.generalState.getState().systemStatus.label == 'Active') {
				toast.info('SWITCHING TO STABILIZE IS PROHIBITED WHILE ACTIVE');
				reject();
				return;
			}

			const {
				isConnected
			} = this.generalState.getState();

			if (isConnected) {
				// If switched to RTL
				// set pending RTL status to true

				const command = () => {
					this.toastIds.flightModeToastId = toast("Changing flight mode", {
						isLoading: true,
						autoClose: false
					});
					const command = MAVLinkConstants.MAV_CMD.MAV_CMD_DO_SET_MODE;
					const commandLabel = 'setFlightMode';

					const onAccepted = (mode) => {
						this.stopTimeout(commandLabel);

						if (flightMode != 'GUIDED') {
							this.positionStateSetters.setPendingDirectPoint(null);
						}

						// Set new mode
						this.generalStateSetters.setCustomMode(flightMode);

						const title = `${mode} flight mode set`;
						toast.update(this.toastIds.flightModeToastId, {
							render: title,
							isLoading: false,
							type: toast.TYPE.SUCCESS,
							autoClose: 2000
						});

						if (callback) {
							callback();
						}

						const now = new Date();
						const endTime = new Date(now.getTime() + 10 * 60000);
						const missionState = this.missionState.getState();
						const missionWaypoints = missionState.preparedMissionItems.mission.filter(mi => mi.action == 'goto' || mi.action == 'land').map((wp, index, waypoints) => ({
							latitude: wp.latitude,
							longitude: wp.longitude,
							altitude: wp.action == 'land' ? waypoints[index - 1].altitude : wp.altitude
						}))

						// If switched to AUTO flight mode
						// send `start mission` command
						if (mode == 'AUTO') {

							console.log(this.aircraftData);
							// Register flight
							// const flightRegistrationObj = {
							// 	label: `SKYY1-${this.aircraftId}`,
							// 	aircraft: {
							// 		registration_number: this.aircraftData.registrationNumber,
							// 		serial_number: this.aircraftData.serialNumber,
							// 		aircraft_type: this.aircraftData.type.title
							// 	},
							// 	operator: {
							// 		first_name: this.operator.firstName,
							// 		last_name: this.operator.lastName,
							// 		license_number: '',
							// 	},
							// 	mission: {
							// 		title: this.currentMissionMeta.title,
							// 		description: this.currentMissionMeta.description || '',
							// 		start_time: now.toISOString(),
							// 		end_time: endTime.toISOString(),
							// 		waypoints: missionWaypoints,
							// 		volumes: [
							// 			{
							// 				start_time: now.toISOString(),
							// 				end_time: endTime.toISOString(),
							// 				polygon: {
							// 					coordinates: [
							// 						{
							// 							longitude: Math.min(...missionWaypoints.map(wp => wp.longitude)),
							// 							latitude: Math.min(...missionWaypoints.map(wp => wp.latitude))
							// 						},
							// 						{
							// 							longitude: Math.max(...missionWaypoints.map(wp => wp.longitude)),
							// 							latitude: Math.min(...missionWaypoints.map(wp => wp.latitude))
							// 						},
							// 						{
							// 							longitude: Math.max(...missionWaypoints.map(wp => wp.longitude)),
							// 							latitude: Math.max(...missionWaypoints.map(wp => wp.latitude))
							// 						},
							// 						{
							// 							longitude: Math.min(...missionWaypoints.map(wp => wp.longitude)),
							// 							latitude: Math.max(...missionWaypoints.map(wp => wp.latitude))
							// 						}
							// 					]
							// 				},
							// 				low_altitude: Math.min(...missionWaypoints.map(wp => wp.altitude)),
							// 				hi_altitude: Math.max(...missionWaypoints.map(wp => wp.altitude))
							// 			}
							// 		]
							// 	}
							// }

							// this.listeners.onMissionStarted && this.listeners.onMissionStarted(flightRegistrationObj).then(() => {
							this.connection.startMission(1, this.missionCount.mission - 1);
							// this.generalStateSetters.setIsMissionInProgress(true);
							// });
						}
						resolve();
					}

					const onFailed = (mode, statusMessage) => {
						console.log(statusMessage);
						this.stopTimeout(commandLabel);
						const title = `Failed to set flight mode ${mode}`;
						toast.update(this.toastIds.flightModeToastId, {
							render: title,
							closeButton: true,
							isLoading: false,
							type: toast.TYPE.ERROR,
							autoClose: 2000
						});
						reject();
					}

					const maxRepeats = 3;
					const timeoutMessage = `Set flight mode command not acknowledged after ${maxRepeats} attempts`;

					this.waitForAck(command, flightMode, onAccepted, onFailed);

					this.startTimeout(
						(confirmation) => this.connection.setFlightMode(flightMode, confirmation),
						commandLabel,
						1000,
						maxRepeats,
						timeoutMessage
					);
				}

				const onAbortCommand = () => {
					reject();
					// this.vehicleState.isPendingRtl = false;
				}

				if (waitForConfirmation) {
					const caption = `Set flight mode to ${flightMode}`;
					this.confirmationStateSetters.setWaitingCommand({
						command,
						caption,
						onAbort: onAbortCommand
					});
				} else {
					command();
				}
			} else {
				resolve();
			}
		})
	}

	/**
	 * Take Off
	 */
	takeOff(waitForConfirmation = true) {
		return new Promise((resolve, reject) => {
			const {
				isConnected,
				isArmed,
				customMode
			} = this.generalState.getState();
			const {
				latitude,
				longitude
			} = this.positionState.getState();

			if (isConnected) {
				const values = [{
					key: 'altitude',
					type: 'number',
					default: 10,
					unit: 'm',
					title: 'T/O altitude',
				}];

				// Check GUIDED flight mode
				if (customMode != 'GUIDED') {
					toast.info('Take off enabled only in guided flight mode');
					return;
					// this.setFlightMode('GUIDED', false);
				}

				// Arm vehicle
				if (!isArmed) {
					toast.info('Aircraft not armed');
					return;
				}

				if (latitude == null || longitude == null) {
					toast.info('GPS loc not established');
					return;
				}

				const command = ({
					altitude
				}) => {

					// this.logBlackBox(`TAKE OFF`, null);
					const command = MAVLinkConstants.MAV_CMD.MAV_CMD_NAV_TAKEOFF;
					const commandName = 'takeOff';

					const onAccepted = () => {
						this.stopTimeout(commandName);
						const title = 'Aircraft taking Off';
						// const message = 'Vehicle has accepted T/O command';
						toast.success(title);
						// this.addNotification(title, message);
						// this.vehicleState.currentTargetValues.targetAltitude = altitude;
						resolve();
					}

					const onFailed = (statusMessage) => {
						this.stopTimeout(commandName);
						const title = 'Failed to Take Off';
						// const message = `Command MAV_CMD_NAV_TAKEOFF failed, status: ${statusMessage} attempts`;
						toast.error(title);
						reject();
					}

					this.waitForAck(command, true, onAccepted, onFailed);

					const maxRepeats = 3;
					const timeoutMessage = `Take off command not acknowledged after ${maxRepeats} attempts`;

					this.startTimeout(
						() => this.connection.takeOff(
							latitude,
							longitude,
							altitude,
						),
						commandName,
						5000,
						maxRepeats,
						timeoutMessage
					);
				}

				if (waitForConfirmation) {
					const caption = "Take off";
					this.confirmationStateSetters.setWaitingCommand({
						command,
						caption,
						values
					});
				} else {
					command();
				}
			} else {
				reject();
			}
		});
	}

	/**
	 * Prepare mission items for upload
	 */
	prepareMissionItems(isLoiter=false) {
		const {
			selectedFlightPath,
			rallyPoints
		} = this.missionState.getState();

		// if (savePrevious) {
		// 	this.missionStateSetters.setSecondaryMissionItems(oldPreparedMissionItems);	
		// }

		const preparedMissionItems = {
			mission: [],
			rally: [],
		};

		const {
			waypoints
		} = selectedFlightPath;

		if (isLoiter) {
			const loiterPoint = selectedFlightPath.waypoints[0];
			const missionItem = {
				latitude: loiterPoint.latitude,
				longitude: loiterPoint.longitude,
				altitude: loiterPoint.altitude,
				radius: loiterPoint.radius,
				speed: loiterPoint.speed,	
			}
			preparedMissionItems.mission = [
					{ ...missionItem, seq: 0, action: 'takeoff' },
					{ ...missionItem, seq: 1, action: 'takeoff' },
					{ ...missionItem, seq: 2, action: 'speed' },
					{ ...missionItem, seq: 3, action: 'loiter' },
				];

			console.log(JSON.stringify(preparedMissionItems.mission, null, 2));

			preparedMissionItems.rally = []
				
			this.missionStateSetters.setPreparedMissionItems(preparedMissionItems);
			return;
		}

		let seq = 0;
		for (let i = 0; i < waypoints.length; i += 1) {
			const waypoint = selectedFlightPath.waypoints[i];
			const missionItem = {
				latitude: waypoint.latitude,
				longitude: waypoint.longitude,
				altitude: waypoint.altitude,
				seq: seq++,
			};

			if (i == 0) {
				missionItem.action = 'goto';
			} else if (i == waypoints.length - 1) {
				missionItem.action = 'land';
				missionItem.altitude = 0;
			} else {
				missionItem.action = 'goto';
			}

			preparedMissionItems.mission.push(missionItem);
			if (i == 0) {
				preparedMissionItems.mission.push({
					...missionItem,
					action: 'takeoff',
					seq: seq++
				});
				preparedMissionItems.mission.push({
					...missionItem,
					action: 'goto',
					seq: seq++
				});
			}

			if (missionItem.action != 'land') {
				preparedMissionItems.mission.push({
					...missionItem,
					speed: waypoint.speed,
					action: 'speed',
					seq: seq++,
				});
			}

			if (missionItem == 'takeoff') {
				preparedMissionItems.mission.push({
					...missionItem,
					speed: waypoint.speed,
					action: 'goto',
					seq: seq++,
				});
			}
		}

		for (let i = 0; i < rallyPoints.length; i += 1) {
			const rallyPoint = rallyPoints[i];
			const preparedRallyPoint = {
				latitude: rallyPoint.latitude,
				longitude: rallyPoint.longitude,
				altitude: rallyPoint.altitude,
				seq: i,
			};
			preparedMissionItems.rally.push(preparedRallyPoint);

			if (i == 0) {
				preparedMissionItems.rally.push({
					...preparedRallyPoint,
					seq: i + 1
				});
				i++;
			}
		}

		console.log(preparedMissionItems.mission);

		this.missionStateSetters.setPreparedMissionItems(preparedMissionItems);
	}

	selectFlightPath(flightPath) {
		this.missionStateSetters.setSelectedFlightPath(flightPath);
		if (flightPath != null) {
			this.prepareMissionItems();
		}
	}

	uploadMissionPlan(waitForConfirmation=true) {
		const {
			preparedMissionItems,
			selectedFlightPath
		} = this.missionState.getState();
		const {
			mission: missionItems,
			rally: rallyItems
		} = preparedMissionItems;

		const missionItemsCount = missionItems.length;
		const rallyItemsCount = rallyItems.length;

		if ((missionItemsCount == 0 && rallyItemsCount == 0) || selectedFlightPath == null) {
			toast.info('No mission waypoints or rallypoints loaded');
			return;
		}

		const command = () => {
			const announceMissionItemsCommand = () => {
				console.log(`Announcing ${missionItemsCount} mission items`);
				this.connection.missionCount(missionItemsCount, 'mission');
			};

			const announceRallyItemsCommand = () => {
				this.connection.missionCount(rallyItemsCount, 'rally');
			};

			this.toastIds.missionItemsUpload = toast('Uploading mission items', {
				isLoading: true,
				autoClose: false
			});
			this.waitForMissionAck( // Wait for mission items to get uploaded
				'upload-items',
				'mission',
				() => {
					console.log('Uploaded, mission ACK received');
					toast.update(this.toastIds.missionItemsUpload, {
						type: toast.TYPE.SUCCESS,
						isLoading: false,
						render: 'Mission items uploaded',
						autoClose: 2000,
						closeButton: true
					});
					this.stopTimeout(`mission-item-mission-${missionItems[missionItems.length - 1].seq}`)
					this.missionStateSetters.setSelectedFlightPath(null);

					if (rallyItemsCount == 0) { // Request uploaded items
						this.generalStateSetters.setIsMissionUploaded(true);
						console.log('Path uploaded');
						this.downloadMissionPlan();
						return;
					}

					this.toastIds.missionItemsUpload = toast('Uploading rallypoint items', {
						isLoading: true,
						autoClose: false
					});
					this.waitForMissionAck( // Wait for rally items to get uploaded
						'upload-items',
						'rally',
						() => {
							toast.update(this.toastIds.missionItemsUpload, {
								type: toast.TYPE.SUCCESS,
								isLoading: false,
								render: 'Mission items uploaded',
								autoClose: 2000,
								closeButton: true
							});
							this.stopTimeout(`mission-item-rally-${rallyItems[rallyItems.length - 1].seq}`)
							this.missionStateSetters.setRallyPoints(null);
							this.downloadMissionPlan(); // Request uploaded items
						},
						() => {
							toast.update(this.toastIds.missionItemsUpload, {
								type: toast.TYPE.ERROR,
								isLoading: false,
								render: 'Failed to upload mission items',
								autoClose: 2000,
								closeButton: true
							})
						},
					);

					this.startTimeout(
						announceRallyItemsCommand,
						'announce-rally-items',
						1000,
						3,
						'Failed to upload rallypoint items, no response from the aircraft',
						this.toastIds.missionItemsUpload,
					);
				},
				() => {
					toast.update(this.toastIds.missionItemsUpload, {
						type: toast.TYPE.ERROR,
						isLoading: false,
						render: 'Failed to upload rally points',
						autoClose: 2000,
						closeButton: true
					})
				},
			)

			this.startTimeout(
				announceMissionItemsCommand,
				'announce-mission-items',
				1000,
				3,
				'Failed to upload mission items, no response from the aircraft',
				this.toastIds.missionItemsUpload,
			);
		}

		const caption = 'Upload mission plan';

		if (waitForConfirmation) {
			this.confirmationStateSetters.setWaitingCommand({
				command,
				caption
			});
		} else {
			command();
		}
	}

	sendMissionItem(seq, missionType) {
		const commandName = `mission-item-${missionType}-${seq}`;

		let missionItem = null;
		const {
			preparedMissionItems: {
				mission,
				rally
			}
		} = this.missionState.getState();

		if (missionType == 'mission') {
			toast.update(this.toastIds.missionItemsUpload, {
				render: `Upload mission items (${seq + 1}/${mission.length})`
			});
			if (seq > mission[0].seq) {
				const previousCommandName = `mission-item-${missionType}-${seq - 1}`;
				this.stopTimeout(previousCommandName);
			}

			missionItem = mission.find(item => item.seq === seq);
		} else {
			if (seq > rally[0].seq) {
				const previousCommandName = `mission-item-${missionType}-${seq - 1}`;
				toast.update(this.toastIds.missionItemsUpload, {
					render: `Upload rallypoints items (${seq + 1}/${rally.length})`
				});
				this.stopTimeout(previousCommandName);
			}

			missionItem = rally.find(item => item.seq === seq);
		}

		const command = () => this.connection.writeMissionItem(
			missionItem.latitude,
			missionItem.longitude,
			missionItem.altitude,
			missionItem.speed,
			missionItem.seq,
			missionItem.action,
			missionType,
			missionItem.radius,
		);

		this.startTimeout(command, commandName, 3000, 3, 'Mission upload timed out');
	}

	downloadMissionPlan(missionType = 'mission') {
		const command = () => {
			this.expectingMissionTypeItems = missionType;
			this.connection.requestMission(missionType);
		}
		this.startTimeout(command, `download-${missionType}-items`);
	}

	requestMissionItem(seq, missionType) {
		const command = () => {
			const toastIdLabel = missionType == 'mission' ? 'downloadMissionItemsToastId' : 'downloadRallyItemsToastId';
			const expectedNumItems = this.missionCount[missionType];

			toast.update(this.toastIds[toastIdLabel], {
				render: `Downloading ${missionType == 'mission' ? 'mission' : 'rallypoint'} items (${seq}/${expectedNumItems})`,
				closeButton: true,
			});
			this.connection.requestMissionItem(seq, missionType);
		};
		this.startTimeout(
			command,
			`mission-item-${missionType}-${seq}-download`,
			2000,
			3,
			`Failed to download item ${seq}`
		);
	}

	decodeSecondaryMission() {
		const {
			secondaryMission,
		} = this.missionState.getState();

		// const pendingUploadedItems = {
		// 	mission: [],
		// 	rally: [],
		// };
		// 
		// for (const wp of secondaryMissionItems.mission) {
		// 	if (wp.seq > 0) {
		// 		const preparedItem = {
		// 			latitude: wp.latitude,
		// 			longitude: wp.longitude,
		// 			altitude: wp.altitude,
		// 			speed: wp.speed,
		// 		}
		// 		pendingUploadedItems.mission.push(preparedItem)
		// 	}
		// }

		// for (const rp of secondaryMissionItems.rally) {
		// 	const preparedItem = {
		// 		latitude: rp.latitude,
		// 		longitude: rp.longitude,
		// 		altitude: rp.altitude,
		// 	}
		// 	pendingUploadedItems.mission.push(preparedItem)
		// }

		// this.missionStateSetters.setPend
		this.missionStateSetters.setFlightPaths([{ title: 'Paused mission', waypoints: secondaryMission.mission }]);
		this.missionStateSetters.setSelectedFlightPath({ title: 'Paused mission', waypoints: secondaryMission.mission });
		this.missionStateSetters.setRallyPoints(secondaryMission.rally);
		this.prepareMissionItems();
	}

	decodeDownloadedMission() {
		const {
			pendingUploadedItems,
			missionStateSetters
		} = this;

		this.toastIds.downloadMissionItemsToastId = null;
		this.toastIds.downloadRallyItemsToastId = null;
		const preparedMissionItems = [];
		const preparedRallyItems = [];

		// Mission items
		let lastWaypoint = null;
		let pointNum = 1;
		let lastSpeed = null;

		console.log(pendingUploadedItems.mission);
		const startIndex = pendingUploadedItems.mission.findIndex(el => el.command == MAVLinkConstants.MAV_CMD.MAV_CMD_NAV_TAKEOFF);

		if (startIndex >= 0) {
			for (let i = startIndex; i < pendingUploadedItems.mission.length; i += 1) {
				const rawItem = pendingUploadedItems.mission[i];

				if (rawItem == null) {
					break;
				}

				const {
					command,
					param2: speed,
					param3: radius,
					latitude,
					longitude,
					altitude,
					seq,
				} = rawItem;

				switch (command) {
					case MAVLinkConstants.MAV_CMD.MAV_CMD_NAV_TAKEOFF:
						if (lastWaypoint) {
							preparedMissionItems.push(lastWaypoint);
						}
						lastWaypoint = new Waypoint(latitude, longitude, `WP${pointNum++}`, altitude, speed || lastSpeed, seq);
						lastWaypoint.isTakeOff = true;
						lastWaypoint.isLanding = false;
						break;
					case MAVLinkConstants.MAV_CMD.MAV_CMD_NAV_LOITER_TURNS:
						if (lastWaypoint) {
							preparedMissionItems.push(lastWaypoint);
						}
						lastWaypoint = new Waypoint(latitude, longitude, `WP${pointNum++}`, altitude, speed || lastSpeed, seq, false, false, true, radius);
						break;
					case MAVLinkConstants.MAV_CMD.MAV_CMD_NAV_LAND:
						if (lastWaypoint) {
							preparedMissionItems.push(lastWaypoint);
						}
						lastWaypoint = new Waypoint(latitude, longitude, `WP${pointNum++}`, altitude, speed || lastSpeed, seq);
						lastWaypoint.isTakeOff = false;
						lastWaypoint.isLanding = true;
						break;
					case MAVLinkConstants.MAV_CMD.MAV_CMD_NAV_WAYPOINT:
						if (lastWaypoint) {
							if (lastWaypoint.latitude == rawItem.latitude && lastWaypoint.longitude == rawItem.longitude) {
								continue;
							} else {
								preparedMissionItems.push(lastWaypoint);
							}
						}
						lastWaypoint = new Waypoint(latitude, longitude, `WP${pointNum++}`, altitude, speed || lastSpeed, seq);
						lastWaypoint.isTakeOff = false;
						lastWaypoint.isLanding = false;
						break;
					case MAVLinkConstants.MAV_CMD.MAV_CMD_DO_CHANGE_SPEED:
						if (lastWaypoint) {
							lastWaypoint.speed = speed;
							lastSpeed = speed;
						}
						break;
					default:
						break;
				}
			}

			if (lastWaypoint != null) {
				preparedMissionItems.push(lastWaypoint);
			}
		}

		// Rallypoints
		let rallyNum = 1;
		for (let i = 1; i < pendingUploadedItems.rally.length; i += 1) {
			const rawItem = pendingUploadedItems.rally[i];

			const {
				seq,
				latitude,
				longitude,
			} = rawItem;

			preparedRallyItems.push(new RallyPoint(latitude, longitude, `RTL${rallyNum++}`, seq))
		}

		console.log(preparedMissionItems);
		missionStateSetters.setUploadedMissionItems({
			mission: preparedMissionItems,
			rally: preparedRallyItems
		});

		if (this.shouldPrepareOldMission) {
			console.log('SHOULD DECODE SECONDARY MISSION')
			this.decodeSecondaryMission();
			this.shouldPrepareOldMission = false;
		} else {
			console.log('SHOULD NOT DECODE SECONDARY MISSION')
		}
	}

	startMission() {
		const command = () => {
			const {
				isMissionUploaded,
				isMissionStarted,
				customMode
			} = this.generalState.getState();

			if (!isMissionUploaded) {
				toast.info('Mission not uploaded!');
				return;
			}

			if (isMissionStarted) {
				toast.info('Mission already started!');
				return;
			}

			console.log(customMode);

			if (customMode == 'AUTO' || customMode == 'LAND' || customMode == 'RTL') {
				this.setFlightMode('GUIDED', false, () => {
					this.arm(false, () => {
						this.setFlightMode('AUTO', false);
					})
				});
			} else {
				this.arm(false, () => {
					this.setFlightMode('AUTO', false);
				})
			}
		}

		const caption = 'Start mission';
		this.confirmationStateSetters.setWaitingCommand({
			command,
			caption
		});
	};



	clearMissionItems(waitForConfirmation = true, downloadMissionItems = true, callback = null) {
		return new Promise((resolve, reject) => {
			if (this.generalState.getState().customMode == 'AUTO') {
				toast.info(`Can't clear mission items in AUTO flight mode`);
				return;
			}

			const {
				isConnected
			} = this.generalState.getState();

			if (isConnected) {
				const command = () => {
					this.toastIds.clearWaypoints = toast('Clearing mission items', {
						isLoading: true,
						autoClose: false
					});
					const onAccepted = () => {
						this.stopTimeout('clear-waypoints');
						toast.update(this.toastIds.clearWaypoints, {
							render: 'Mission items cleared',
							autoClose: 2000,
							closeButton: true,
							isLoading: false,
							type: toast.TYPE.SUCCESS
						})

						this.waitForMissionAck('clear-rtl', 'mission', () => {
								this.stopTimeout('clear-rally');
								toast.update(this.toastIds.clearRallypoints, {
									render: 'Rallypoint items cleared',
									autoClose: 2000,
									closeButton: true,
									isLoading: false,
									type: toast.TYPE.SUCCESS
								})

								if (downloadMissionItems) {
									this.downloadMissionPlan();
								}

								if (callback) {
									callback();
								}
								resolve();
							},
							() => {
								this.stopTimeout('clear-rally');
								toast.update(this.toastIds.clearRallypoints, {
									render: 'Failed to clear rallypoint items',
									autoClose: 2000,
									closeButton: true,
									isLoading: false,
									type: toast.TYPE.ERROR
								})
								if (downloadMissionItems) {
									this.downloadMissionPlan();
								}
								reject();
							});

						this.toastIds.clearRallypoints = toast('Clearing rallypoint items', {
							isLoading: true,
							autoClose: false
						});
						this.startTimeout(() => this.connection.clearAllRtl(), 'clear-rally', 1000, 3, 'Failed to delete rallypoint items, no response', this.toastIds.clearRallypoints);
					}

					const onFailed = () => {
						this.stopTimeout('clear-waypoints');
						toast.update(this.toastIds.clearWaypoints, {
							render: 'Failed to clear mission items',
							autoClose: 2000,
							closeButton: true,
							isLoading: false,
							type: toast.TYPE.ERROR
						});
						reject()
					}

					this.waitForMissionAck('clear-waypoints', 'mission', onAccepted, onFailed);
					this.startTimeout(() => this.connection.clearAllWaypoints(), 'clear-waypoints', 1000, 3, 'Failed to delete mission items, no response', this.toastIds.clearWaypoints)
				}

				if (waitForConfirmation) {
					const caption = 'Clear all mission items from the aircraft';
					this.confirmationStateSetters.setWaitingCommand({
						command,
						caption
					});
				} else {
					command();
				}
			}
		})
	}

	setNextMissionItem(seq, waitForConfirmation = true) {
		const wpNum = this.missionState.getState().uploadedMissionItems.mission.findIndex(el => el.seq == seq) + 1;
		const command = () => {
			this.toastIds.setNextMissionToastId = toast('Changing next waypoint', {
				isLoading: true,
				autoClose: false
			});
			const onAccepted = () => {
				this.stopTimeout('set-next-waypoint');
				toast.update(this.toastIds.setNextMissionToastId, {
					render: `Next waypoint set to WP${wpNum}`,
					type: toast.TYPE.SUCCESS,
					isLoading: false,
					autoClose: 2000
				});
			}
			const onFailed = () => {
				this.stopTimeout('set-next-waypoint');
				toast.update(this.toastIds.setNextMissionToastId, {
					render: 'Failed to change next waypoint',
					type: toast.TYPE.ERROR,
					isLoading: false,
					autoClose: 2000
				});
			}

			this.waitForAck('set-next-waypoint', seq, onAccepted, onFailed);

			const timeoutMessage = 'Failed to change next waypoint, timed out';
			this.startTimeout(() => this.connection.setMissionCurrent(seq), 'set-next-waypoint', 1000, 3, timeoutMessage, this.toastIds.setNextMissionToastId);

			this.connection.setMissionCurrent(parseInt(seq));
			// this.vehicleState.currentWaypointOverride = seq;
		}

		if (waitForConfirmation) {
			const caption = `Set next waypoint to WP${wpNum}`;
			this.confirmationStateSetters.setWaitingCommand({
				command,
				caption
			});
		} else {
			command();
		}
	}

	/**
	 * Set next waypoinint
	 * @param {} latitude
	 * @param {} longitude
	 * @param {} altitude
	 */
	// TODO: Repeated move to location
	gotoWaypoint(latitude, longitude, altitude = null, yaw = null, waitForConfirmation = true) {
		const caption = "Move to location";

		const {
			isConnected
		} = this.generalState.getState();
		console.log(isConnected ? 'Aircraft is Connected' : 'Aircraft is NOT Connected')

		if (isConnected) {
			// if (this.vehicleState.waitingCommand != null) {
			// 	if (this.vehicleState.waitingCommand.caption == caption) {
			// 		this.clearWaitingCommand();
			// 	} else {
			// 		return;
			// 	}
			// }
			const {
				pendingDirectPoint: currentPendingDirectPoint
			} = this.positionState.getState();
			console.log(currentPendingDirectPoint);

			this.positionStateSetters.setPendingDirectPoint({
				latitude,
				longitude
			})

			const {
				relativeAltitude
			} = this.positionState.getState();

			const values = [{
				key: 'altitude',
				title: 'Target Altitude',
				default: altitude == null ? Math.round(relativeAltitude) : altitude,
				type: 'number',
				unit: 'm'
			}];

			const command = ({
				altitude
			}) => {
				const {
					customMode: flightMode
				} = this.generalState.getState();

				if (flightMode != 'GUIDED') {
					this.setFlightMode('GUIDED', false);
				}

				this.directedWaypoint = [latitude, longitude];

				this.connection.gotoWaypoint(this.missionCount.mission, latitude, longitude, altitude, yaw);
				// this.vehicleState.currentTargetValues.targetAltitude = altitude;
			}

			const onAbortCommand = () => {
				this.positionStateSetters.setPendingDirectPoint(currentPendingDirectPoint);
			}

			if (waitForConfirmation) {
				this.confirmationStateSetters.setWaitingCommand({
					command,
					caption,
					values,
					onAbort: onAbortCommand
				});
				this.addWaiting(command, caption, values, onAbortCommand);
			} else {
				command({
					altitude
				});
			}
		}
	}

	setYaw(angle) {
		const heading = angle % 360 < 0 ? (360 + angle) % 360 : angle % 360;
		const {
			heading: currentHeading
		} = this.hudState.getState();
		let direction = 0;

		if (heading < currentHeading) {
			direction = -1;

			if (Math.abs(heading - currentHeading) > 360 - Math.abs(heading - currentHeading)) {
				direction = 1;
			}
		} else {
			direction = 1;

			if (Math.abs(heading - currentHeading) > 360 - Math.abs(heading - currentHeading)) {
				direction = -1;
			}
		}

		this.connection.setYaw(heading, direction);
	}

	setAltitude(amslAltitude) {
		// this.connection.setAltitude(altitude);
		let {
			heading,
			latitude,
			longitude
		} = this.positionState.getState();
		const {
			altitude: currentAMSLAltitude
		} = this.hudState.getState();

		const {
			time_boot_ms
		} = this.positionState.getState();
		this.moveForward(0);
		this.connection.setAmslAltitude(time_boot_ms, latitude, longitude, amslAltitude);
	}

	setSpeed(speed) {
		this.connection.setSpeed(speed);
	}

	moveForward(speed) {
		if (this.forwardMovingInterval != null) {
			clearInterval(this.forwardMovingInterval)
		}

		if (speed > 0) {
			this.forwardMovingInterval = setInterval(() => {
				// if (!this.isConnected) {
				// clearInterval(this.forwardMovingInterval);
				// return;
				// }
				const {
					time_boot_ms
				} = this.positionState.getState();
				console.log(speed);
				this.connection.moveForward(time_boot_ms, speed);
			}, 500);
		} else {
			const {
				time_boot_ms
			} = this.positionState.getState();
			this.connection.moveForward(time_boot_ms, 0);
		}
	}

	orbit(latitude, longitude, waitForConfirmation = true) {
		return new Promise((resolve, reject) => {
			const command = ({
				radius,
				altitude
			}) => {

				// this.logBlackBox(`TAKE OFF`, null);
				// const command = MAVLinkConstants.MAV_CMD.MAV_CMD_DO_ORBIT;
				// const commandName = 'orbit';

				// const onAccepted = () => {
				// 	this.stopTimeout(commandName);
				// 	const title = 'Moving to orbit';
				// 	// const message = 'Vehicle has accepted T/O command';
				// 	toast.success(title);
				// 	// this.addNotification(title, message);
				// 	// this.vehicleState.currentTargetValues.targetAltitude = altitude;
				// 	resolve();
				// }

				// const onFailed = (statusMessage) => {
				// 	this.stopTimeout(commandName);
				// 	const title = 'Failed to Start Orbit';
				// 	// const message = `Command MAV_CMD_NAV_TAKEOFF failed, status: ${statusMessage} attempts`;
				// 	toast.error(title);
				// 	reject();
				// }

				// this.waitForAck(command, true, onAccepted, onFailed);

				// const maxRepeats = 3;
				// const timeoutMessage = `Orbit command not acknowledged after ${maxRepeats} attempts`;

		
				const loiterWp = new Waypoint(latitude, longitude, 'Orbit center', altitude, null, 0, false, false, true, radius);

				this.missionStateSetters.setSelectedFlightPath({ waypoints: [ loiterWp ]});
				this.missionStateSetters.setRallyPoints([]);

				this.prepareMissionItems(true);
				this.uploadMissionPlan();

				// this.startTimeout(
				// 	() => this.connection.orbit(
				// 		latitude,
				// 		longitude,
				// 		altitude,
				// 	),
				// 	commandName,
				// 	5000,
				// 	maxRepeats,
				// 	timeoutMessage
				// );

				
			}

			const {
				relativeAltitude
			} = this.positionState.getState();

			const values = [{
				key: 'altitude',
				title: 'Target Altitude',
				default: Math.round(relativeAltitude),
				type: 'number',
				unit: 'm'
			},
			{
				key: 'radius',
				title: 'Orbir Radius',
				default: 10,
				type: 'number',
				unit: 'm'
			}];

			if (waitForConfirmation) {
				const caption = "Take off";
				this.confirmationStateSetters.setWaitingCommand({
					command,
					caption,
					values
				});
			} else {
				command();
			}
		});
	}

	createOrbit(latitude, longitude, radius=100) {
		const orbitDraft = {
			latitude, 
			longitude, 
			radius,
		}

		this.positionStateSetters.setOrbitDraft(orbitDraft);
	}

	clearOrbit() {
		this.positionStateSetters.setOrbitDraft(null);
	}

	updateOrbit({lat, lng}) {
		const { orbitDraft } = this.positionState.getState();
		const newRadius = UtilityService.distance(orbitDraft, { latitude: lat, longitude: lng });
		this.createOrbit(orbitDraft.latitude, orbitDraft.longitude, newRadius);
	}

	async stopOrbit() {
		await this.setFlightMode('BRAKE');
		await this.clearMissionItems(false);
		this.uploadMissionPlan(false);
		// this.
	}

	startOrbit(waitForConfirmation=true) {
		const { orbitDraft } = this.positionState.getState();

		if (orbitDraft == null) {
			return;
		}

		const { latitude, longitude, radius } = orbitDraft;

		const command = async ({ altitude, speed }) => {
			const loiterWp = new Waypoint(latitude, longitude, 'Orbit center', altitude, speed, 0, false, false, true, radius);
			const { uploadedMissionItems } = this.missionState.getState();

			this.missionStateSetters.setSecondaryMissionItems(uploadedMissionItems);
			this.missionStateSetters.setSelectedFlightPath({ waypoints: [ loiterWp ]});
			this.missionStateSetters.setRallyPoints([]);

			this.shouldPrepareOldMission = true;
			this.prepareMissionItems(true);
			await this.setFlightMode('GUIDED', false);
			this.uploadMissionPlan(false);
			this.clearOrbit();
		}

		const { relativeAltitude } = this.positionState.getState();

		const values = [{
			key: 'altitude',
			title: 'Orbit Altitude',
			default: Math.round(relativeAltitude),
			type: 'number',
			unit: 'm'
		},
		{
			key: 'speed',
			title: 'Orbit speed',
			default: 5,
			type: 'number',
			unit: 'm/s'
		}];

		if (waitForConfirmation) {
			const caption = "Upload orbit info";
			this.confirmationStateSetters.setWaitingCommand({
				command,
				caption,
				values
			});
		} else {
			command();
		}
	}
}

export default Aircraft;
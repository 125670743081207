import { Button } from '@mui/material';
import React from 'react';
import { Stack } from '../../layout/stack/stack';


export const OutlineButton = ({icon, title, onClick, color, className, size='small'}) => {
    return (
        // <Stack className={`outline-button-wrap ${color && color} ${className && className}`} onClick={() => onClick()} direction='horizontal' halign={'center'} valign="center">
        
        <Button variant="outlined" size={size} className={`outline-button-wrap ${color && color} ${className && className}`} onClick={() => onClick()}> 
            {icon && <span>{icon}</span>}
            {title && <span className='uppercase bold'>{title}</span>}
        </Button>
        // </Stack>
    )

}
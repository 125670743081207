import { FileOpenOutlined, Folder, FolderOutlined, SaveOutlined } from "@mui/icons-material"
import { useContext, useState } from "react"
import { toast } from "react-toastify"
import { MissionContext } from "../../../../contexts/mission-context"
import { FileDialog } from "../../../../shared-components/containers/file-dialog/file-dialog"
import { InputField } from "../../../../shared-components/inputs/input-field/input-field"
import { SelectInput } from "../../../../shared-components/inputs/select-input/select-input"
import { Stack } from "../../../../shared-components/layout/stack/stack"

import { missionSchema } from "../../../../contexts/schemas/mission-schema";
import { FileSystemContext } from "../../../../contexts/file-system-context"
import { Panel } from "../../../../shared-components/layout/panel/panel"
import { FleetManagementContext } from "../../../../contexts/fleet-management-context"
import { Article } from "@mui/icons-material";
import { TextareaAutosize } from "@mui/material"
import { EditData } from "../../../../shared-components/inputs/edit-data/edit-data"
import { ConfirmationDialog } from "../../../../shared-components/containers/confirmation-dialog/confirmation-dialog"

export const MissionInfo = () => {
    const datumValues = [
        { name: "AGL", value: "AGL" }, 
        { name: "AMSL", value: "AMSL" },
        { name: "ALP", value: "ALP" },
    ];

    const { 
        updateMissionMeta, 
        missionMeta,
        altitudeDatum,
        setAltitudeDatum,
        saveMissionPlan,
        loadMissionPlan,
        createNewMission,
        missionAircraftType,
        setMissionAircraftType,
    } = useContext(MissionContext);

    const { aircraftTypes } = useContext(FleetManagementContext);

    const { getFolders } = useContext(FileSystemContext);

    const [openFileDialogVisible, setOpenFileDialogVisible] = useState(false);
	const [saveFileDialogVisible, setSaveFileDialogVisible] = useState(false);

	const saveMission = () => {
        console.log(missionMeta);
		if (missionMeta.id != null) {
			saveMissionPlan(null, null, true)
		} else {
			toast.info('Mission not previously saved');
		}
	}

	const saveMissionAs = () => {
		if (missionMeta.title == null || missionMeta.title.trim().length == 0) {
			toast.info('Missing mission title');
		} else {
			setSaveFileDialogVisible(true);
		}
	}
    const [waitingConfirmation,setWaitingConfirmation] = useState(false);
    console.log("Mission meta", missionMeta);
    return (
        <div className="mission-info">
        <Panel title={"Mission Details"} backgroundColor='#060d1f' icon={<Article/>}>
            <Stack direction="vertical" halign="space-between" valign={'flex-start'}>
                <InputField 
                    className="info-input"
                    defaultValue={missionMeta.title}
                    onSubmit={(e) => updateMissionMeta({ title: e.target.value.trim() })} 
                    label="Mission Plan Title"
                    onBlur={(e) => updateMissionMeta({ title: e.target.value.trim() })}
                    placeholder="Untitled mission"
                />
                 <InputField 
                    className="info-input"
                    defaultValue={missionMeta.description}
                    onSubmit={(e) => updateMissionMeta({ description: e.target.value.trim() })} 
                    label="Mission Description"
                    onBlur={(e) => updateMissionMeta({ description: e.target.value.trim() })} 
                    placeholder="No description"
                />
                <SelectInput 
                    className="info-input" 
                    label="Altitude datum" 
                    options={datumValues}
                    value={altitudeDatum}
                    onChange={(e) => setAltitudeDatum(e.target.value)}
                />

                <SelectInput 
                    className="info-input wide" 
                    label="Aircraft Type" 
                    options={aircraftTypes != null ? aircraftTypes.map(at => ({ value: at.id, name: at.data.title })): []}
                    value={missionAircraftType ? missionAircraftType.id : null}
                    onChange={(e) => setMissionAircraftType(aircraftTypes.find(el => el.id == e.target.value))}
                />  
                <div className="action-buttons">
                    <Stack direction="horizontal" halign="space-between" valign="center">
                        <Stack className="action-button" direction="vertical" valign="center" halign="center" onClick={() => setOpenFileDialogVisible(true)}>
                            <FolderOutlined/><span>LOAD</span>
                        </Stack>
                        <Stack className="action-button" direction="vertical" valign="center" halign="center" onClick={() => saveMissionAs()}>
                            <SaveOutlined/><span className='save-as'>SAVE AS</span>
                        </Stack>
                        {missionMeta.id != null  &&  
                            <Stack className="action-button" direction="vertical" valign="center" halign="center" onClick={() => saveMission()}>
                                <SaveOutlined/><span>SAVE</span>
                            </Stack>}
                        <Stack className="action-button" direction="vertical" valign="center" halign="center" onClick={() => setWaitingConfirmation(true)}>
                            <FileOpenOutlined/> <span>NEW</span>
                        </Stack>
                    </Stack>
                </div>
            </Stack>
            <ConfirmationDialog 
                    visible={waitingConfirmation}
                    message={"Create new mission?"}
                    onConfirm={() => createNewMission()}
                    onCancel={() => setWaitingConfirmation(false)}
                    onClose={() => setWaitingConfirmation(false)}
                />
            { openFileDialogVisible && 
				<FileDialog
					dialogType="load" 
					entityName='Mission' 
					schema={missionSchema} 
					onClose={()=> setOpenFileDialogVisible(false)}
					onLoad={(missionPlan) => { loadMissionPlan(missionPlan); setOpenFileDialogVisible(false) }}
				/>
			}

			
				{ 
				 saveFileDialogVisible && 
					<FileDialog 
						dialogType="save" 
						entityName='Mission' 
						schema={missionSchema} 
						currentFileName={missionMeta.title}
						onClose={()=> setSaveFileDialogVisible(false)}
						onSave={({ filename, folderId}) => saveMissionPlan(filename, folderId, false, () => getFolders('mission'))}
					/>
				}
        </Panel>
        </div>
    )
}
import React, {useState, useRef} from 'react';
import { motion } from 'framer-motion';

export const SideMenu = ({children, direction, className}) => {
    return(
        <motion.div
			 key="side-menu-motion"
			 initial={{ opacity: 0, x: direction == 'left' ? -50 : 50}}
			 animate={{ opacity: 1, x:0 }}
             exit={{ opacity: 0, x: direction == 'left' ? -50 : 50}}
			 transition={{ duration: 0.2, delay: 0.15, ease: 'easeInOut' }}
             className={`side-menu-wrap ${className} ${direction}`} 
         >
			{children}
		</motion.div>
    );
}
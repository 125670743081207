import React, { useContext } from 'react';
import { motion } from "framer-motion"
import { InformationDock } from './components/information-dock/information-dock';
import { WeatherInformationWidget } from './components/weather-information-widget/weather-information-widget';
import { AirportInfoWidget } from './components/airport-info-widget/airport-info-widget';
import { ConfigContext } from '../../contexts/config-context';
import { Shortcuts } from './components/shortcuts/shortcuts';

import PerfectScrollbar from 'react-perfect-scrollbar';

export const HomeScreen = () => {
    const { homepageShortcuts } = useContext(ConfigContext);

	return (
		<div className="home-screen">
			{/* <div className="blink" style={{color: 'white', backgroundColor: 'red', position: 'absolute', left: 20, top: 20,}}>
				ASDASD
			</div> */}
			    <InformationDock key="info-dock-element" >
                <PerfectScrollbar>
                <motion.div 
                    initial={{ opacity: 0, x: -50}}
                    animate={{ opacity: 1, x:0 }}
                    transition={{ duration: 0.3, delay: 0.1, ease: [0.130, 0.850, 0.375, 0.960] }}
                >
                    <WeatherInformationWidget/>
                </motion.div>

                <motion.div 
                    key="airport-info-div"
                    initial={{ opacity: 0, x: -50}}
                    animate={{ opacity: 1, x:0 }}
                    transition={{ duration: 0.3, delay: 0.15, ease: [0.130, 0.850, 0.375, 0.960] }}
                >
                    <AirportInfoWidget/>
                </motion.div>
                </PerfectScrollbar>
			</InformationDock>

            <motion.div 
                initial={{ opacity: 0, y: 55 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 55 }}
                transition={{ duration: 0.3, delay: 0.2, ease: [0.130, 0.850, 0.375, 0.960] }}
            >
                {/* <Shortcuts shortcuts={ homepageShortcuts }/> */}
            </motion.div>
		</div>
	)
}

import React from 'react';

export const ThrottleProgressBar = ({progressValue}) => {

        return(
            <section className='throttle-progress-bar-wrap'>
                <p className='throttle-percentage-sign'>%</p>
                <div className='throttle-progress-bar'>
                    <progress max="100" value={progressValue}></progress>
                    {/* <br/> */}
                </div>
                <div className="progress-value">{progressValue}</div>
            </section>
        );
}


import React, { createContext, useContext, useEffect, useRef, useState } from "react";
import axios from "axios";
import { ConfigContext } from "./config-context";
import { MapContext } from "./map-context";
import { ADSBAircraft } from "../helpers/adsb-aircraft";

export const ADSBContext = createContext();

const ADSBContextProvider = ({ children }) => {
	const { currentBounds, adsbVisible, zoom } = useContext(MapContext);
	const [adsbData, setAdsbData] = useState([]);
	const serviceInterval = useRef(null);

	const { services: {
		adsbService: {
			host, port,
		}
	} } = useContext(ConfigContext);

	useEffect(() => {
		startService();
	}, [currentBounds, adsbVisible, zoom]);
	
	const startService = () => {
		clearInterval(serviceInterval.current);
		getAdsbData();
		serviceInterval.current = setInterval(() => getAdsbData(), 3000)
	}

	const getAdsbData = () => {
			if (adsbVisible && zoom > 3) {
				axios.get(`${host}/states/all`, {
					params: {
						lamin: Math.min(currentBounds[0].latitude, currentBounds[1].latitude, currentBounds[2].latitude, currentBounds[3].latitude),
						lomin: Math.min(currentBounds[0].longitude, currentBounds[1].longitude, currentBounds[2].longitude, currentBounds[3].longitude),
						lamax: Math.max(currentBounds[0].latitude, currentBounds[1].latitude, currentBounds[2].latitude, currentBounds[3].latitude),
						lomax: Math.max(currentBounds[0].longitude, currentBounds[1].longitude, currentBounds[2].longitude, currentBounds[3].longitude),
					}
				}).then(res => {
					let newState = [];
					if (res.data.states == null) {
						return 
					}
					else	{
						newState = res.data.states.map(([
							icao24, 
							callsign, 
							origin_country, 
							time_position, 
							last_contact, 
							longitude, 
							latitude, 
							baro_altitude, 
							on_ground,
							velocity,
							true_track,
							vertical_rate,
							sensors,
							geo_altitude,
							squawk,
							spi,
							position_source,
						])=> ({
							icao24, 
							callsign, 
							origin_country, 
							time_position, 
							last_contact, 
							longitude, 
							latitude, 
							baro_altitude, 
							on_ground,
							velocity,
							true_track,
							vertical_rate,
							sensors,
							geo_altitude,
							squawk,
							spi,
							position_source,
							marker: new ADSBAircraft(latitude, longitude)
						 }))
					}
					if (adsbData != newState) {
						const update = [];
						for (const ac of newState) {
							const currentAircraft = adsbData.find(el => el.icao24 == ac.icao24);
							if (currentAircraft == null) {
								update.push(ac);
							} else {
								if (currentAircraft.time_position < ac.time_position) {
									update.push(ac);
								} else {
									update.push(currentAircraft);
								}
							}
						}
						setAdsbData(update);
						console.log(newState);
					} 
				})
			}
	}

	return (
		<ADSBContext.Provider value={{ adsbData }}>
			{ children }
		</ADSBContext.Provider>
	)
}

export default ADSBContextProvider;
const gpsRawReducer = (state = { remaining: 0, batteries: {}}, action) => {
	switch (action.type) {
		case 'SET_DATA':
			return action.payload;
		default:
			return state;
	}
}

export default gpsRawReducer

export const createSetters = (store) => ({
		setData: (data) => store.dispatch({ type: 'SET_DATA', payload: data }),
	});
import { combineReducers } from "redux";

// isSubscribed
const isSubscribedReducer = (state = false, action) => {
	switch (action.type) {
		case 'SET_IS_SUBSCRIBED':
			return action.payload;
		default: return state;
	}
}

// isConnected
const isConnectedReducer = (state = false, action) => {
	switch (action.type) {
		case 'SET_IS_CONNECTED':
			return action.payload;
		default: return state;
	}
}

// autopilotType
const autopilotTypeReducer = (state = null, action) => {
	switch (action.type) {
		case 'SET_AUTOPILOT_TYPE':
			return action.payload;
		default: return state;
	}
}

// baseMode
const baseModeReducer = (state = null, action) => {
	switch (action.type) {
		case 'SET_BASE_MODE':
			return action.payload;
		default: return state;
	}
}

// customMode
const customModeReducer = (state = null, action) => {
	switch (action.type) {
		case 'SET_CUSTOM_MODE':
			return action.payload;
		default: return state;
	}
}

// flightMode
const flightModeReducer = (state = null, action) => {
	switch (action.type) {
		case 'SET_FLIGHT_MODE':
			return action.payload;
		default: return state;
	}
}

// isArmed
const isArmedReducer = (state = null	, action) => {
	switch (action.type) {
		case 'SET_ARMED':
			return true;
		case 'SET_DISARMED':
			return false;
		default: return state;
	}
}

// mavlinkVersion
const mavlinkVersionReducer = (state = null, action) => {
	switch (action.type) {
		case 'SET_MAV_VERSION':
			return action.payload;
		default:
			return state;
	}
}

// connectionType
const connectionTypeReducer = (state = null, action) => {
	switch (action.type) {
		case 'SET_CONNECTION_TYPE':
			return action.payload;
		default:
			return state;
	}
}

// connectionType
const signalStrengthReducer = (state = null, action) => {
	switch (action.type) {
		case 'SET_SIGNAL_STRENGTH':
			return action.payload;
		default:
			return state;
	}
}

// vehicleType
const vehicleTypeReducer = (state = null, action) => {
	switch (action.type) {
		case 'SET_VEHICLE_TYPE':
			return action.payload;
		default:
			return state;
	}
}

// systemStatus
const systemStatusReducer = (state = null, action) => {
	switch (action.type) {
		case 'SET_SYSTEM_STATUS':
			return action.payload;
		default:
			return state;
	}
}

// isAirborn
const isAirbornReducer = (state = null, action) => {
	switch (action.type) {
		case 'SET_IS_AIRBORN':
			return action.payload;
		default:
			return state;
	}
}

// isMissionInProgress
const isMissionInProgressReducer = (state = null, action) => {
	switch (action.type) {
		case 'SET_MISSION_IN_PROGRESS':
			return action.payload;
		default:
			return state;
	}
}

// isMissionUploaded
const isMissionUploadedReducer = (state = null, action) => {
	switch (action.type) {
		case 'SET_MISSION_UPLOADED':
			return action.payload;
		default:
			return state;
	}
}

const isDisconnectedReducer = (state = false, action) => {
	switch (action.type) {
		case 'SET_IS_DISCONNECTED':
			return action.payload;
		default: return state;
	}
}


export default combineReducers({
		isDisconnected: isDisconnectedReducer,
		isSubscribed: isSubscribedReducer,
		isConnected: isConnectedReducer,
		connectionType: connectionTypeReducer,
		autopilotType: autopilotTypeReducer,
		baseMode: baseModeReducer,
		customMode: customModeReducer,
		// flightMode: flightModeReducer,
		isArmed: isArmedReducer,
		mavlinkVersion: mavlinkVersionReducer,
		vehicleType: vehicleTypeReducer,
		systemStatus: systemStatusReducer,
		isAirborn: isAirbornReducer,
		isMissionInProgress: isMissionInProgressReducer,
		isMissionUploaded: isMissionUploadedReducer,
		signalStrength: signalStrengthReducer,
	});

export const createSetters = (store) => ({
		setIsSubscribed: (isSubscribed) => store.dispatch({ type: 'SET_IS_SUBSCRIBED', payload: isSubscribed }),
		setIsConnected: (isConnected) => store.dispatch({ type: 'SET_IS_CONNECTED', payload: isConnected }),
		setIsDisconnected: () => store.dispatch({ type: 'SET_IS_DISCONNECTED', payload: true}),
		setConnectionType: (connectionType) => store.dispatch({ type: 'SET_CONNECTION_TYPE', payload: connectionType }),
		setSignalStrength: (signalStrength) => store.dispatch({ type: 'SET_SIGNAL_STRENGTH', payload: signalStrength }),
		setAutopilotType: (autopilotType) => store.dispatch({ type: 'SET_AUTOPILOT_TYPE', payload: autopilotType }),
		setBaseMode: (baseMode) => store.dispatch({ type: 'SET_BASE_MODE', payload: baseMode }),
		setCustomMode: (customMode) => store.dispatch({ type: 'SET_CUSTOM_MODE', payload: customMode }),
		// setFlightMode: (flightMode) => store.dispatch({ type: 'SET_FLIGHT_MODE', payload: flightMode }),
		setIsArmed: (isArmed) => store.dispatch(isArmed ? { type: 'SET_ARMED' } : { type: 'SET_DISARMED' }),
		setMavlinkVersion: (mavlinkVersion) => store.dispatch({ type: 'SET_MAV_VERSION', payload: mavlinkVersion }),
		// setVehicleType: (vehicleType) => store.dispatch({ type: 'SET_VEHICLE_TYPE', payload: vehicleType }),
		setVehicleType: (vehicleType) => {},
		setSystemStatus: (systemStatus) => store.dispatch({ type: 'SET_SYSTEM_STATUS', payload: systemStatus }),
		setIsAirborn: (isAirborn) => store.dispatch({ type: 'SET_IS_AIRBORN', payload: isAirborn }),
		setIsMissionInProgress: (isMissionInProgress) => store.dispatch({ type: 'SET_MISSION_IN_PROGRESS', payload: isMissionInProgress }),
		setIsMissionUploaded: (isMissionUploaded) => store.dispatch({ type: 'SET_MISSION_UPLOADED', payload: isMissionUploaded })
	});
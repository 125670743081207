import React, { useRef, useState, useContext, useEffect } from 'react';
import { CockpitSideMenu } from './components/cockpit-side-menu/cockpit-side-menu';
import { CockpitToolbar } from './components/cockpit-toolbar/cockpit-toolbar';
import { CockpitControlPanel } from './components/cockpit-control-panel/cockpit-control-panel';
import { SlideConfirmation } from '../../shared-components/layout/slide-confirmation/slide-confirmation';
import { CockpitHeader } from './components/cockpit-header/cockpit-header';

import { AircraftContext } from '../../contexts/aircraft-context';
import { motion } from 'framer-motion';
import { FlightModeSelectorPanel } from './components/flight-mode-selector-panel/flight-mode-selector-panel';
import { CockpitMissionPath } from './components/cockpit-mission-path/cockpit-mission-path';
import { ActiveFlightsPanel } from '../../shared-components/containers/active-flights-panel/active-flights-panel';
import { HeadingCompass } from '../../shared-components/heading-compass/heading-compass';
import { CockpitScheduledFlights } from './components/cockpit-scheduled-flights/cockpit-scheduled-flights';
import { ConnectionLostNotification } from './components/connection-lost-notification/connection-lost-notification';
import { OrbitToolbar } from './components/orbit-toolbar/orbit-toolbar';

export const CockpitScreen = () => {
	const currentAircraft = useRef(null);
	const [waitingConfirmation, setWaitingConfirmation] = useState(null);
	const [isAircraftConnected, setIsAircraftConnected] = useState(false);
	const [customMode, setCustomMode] = useState(false);
	const [waypoints, setWaypoints] = useState([]);
	const { selectedAircraft } = useContext(AircraftContext);
	const subscriptions = useRef([]);
	const [isOrbiting, setIsOrbiting] = useState(false);

	useEffect(() => {
		subscribe();
	},[selectedAircraft])

	const subscribe = () => {
		if (selectedAircraft != currentAircraft.current) {
			if (currentAircraft.current != null) {
				for (const sub of subscriptions.current) {
					sub();
					subscriptions.current = [];
				}
			} else {
				setCustomMode('STABILIZE');
			}

			currentAircraft.current = selectedAircraft;

			if (selectedAircraft != null) {
				setWaitingConfirmation(selectedAircraft.confirmationState.getState());
				setIsAircraftConnected(selectedAircraft.generalState.getState().isConnected);
				setCustomMode(selectedAircraft.generalState.getState().customMode);

				subscriptions.current.push(selectedAircraft.confirmationState.subscribe(() => {
					setWaitingConfirmation(selectedAircraft.confirmationState.getState());
				}))

				subscriptions.current.push(selectedAircraft.generalState.subscribe(() => {
					setIsAircraftConnected(selectedAircraft.generalState.getState().isConnected);
					setCustomMode(selectedAircraft.generalState.getState().customMode);
				}))

				subscriptions.current.push(selectedAircraft.missionState.subscribe(() => {
					setWaypoints(selectedAircraft.missionState.getState().uploadedMissionItems.mission);
					setIsOrbiting(selectedAircraft.missionState.getState().isOrbiting);
				}))
			}
		}
	}

	if (currentAircraft.current != selectedAircraft) {
		subscribe();
	}
	
	return (
		<div className="cockpit-screen" key="cockpit-screen">
			{selectedAircraft && selectedAircraft.wasConnected && !isAircraftConnected && <ConnectionLostNotification/>}
			{selectedAircraft && isAircraftConnected && <CockpitHeader/>}
			{selectedAircraft && isAircraftConnected && <FlightModeSelectorPanel/>}
			{selectedAircraft && isAircraftConnected && <CockpitToolbar/>}
			{selectedAircraft && isAircraftConnected && <HeadingCompass aircraft={selectedAircraft}/>}
			
			<div className='cockpit-right-toolbars'>
				<ActiveFlightsPanel/>
				<CockpitScheduledFlights/>
				<CockpitSideMenu/>
			</div>
		
			
			<CockpitControlPanel visible={selectedAircraft}/>
			{ waypoints.length > 0 &&
				<CockpitMissionPath/>
			}
			
			{selectedAircraft && isOrbiting && <OrbitToolbar selectedAircraft={selectedAircraft}/>}
			<SlideConfirmation 
				key={`slide-confirmation-element-${selectedAircraft ? selectedAircraft.aircraftId : ''}`}
				visible={waitingConfirmation != null && waitingConfirmation.caption != null}
				caption={waitingConfirmation ? waitingConfirmation.caption : null}
				values={waitingConfirmation ? waitingConfirmation.values : null}
				onCancel={() => {
					if (waitingConfirmation) {
						selectedAircraft.confirmationStateSetters.setWaitingCommand(null);
						if (waitingConfirmation.onAbort) { waitingConfirmation.onAbort() }
					}
				}}
				onConfirm={(values) => {
					if (waitingConfirmation && waitingConfirmation.command) {
						waitingConfirmation.command(values);
					}
						selectedAircraft.confirmationStateSetters.setWaitingCommand(null);
				}}
			/>
		</div>
	)
}
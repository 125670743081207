import { useContext, useEffect, useState } from "react"
import { Link, useLocation, useNavigate } from "react-router-dom";
import { AuthContextV2 } from "../../contexts/auth-context-v2";
import Cookies from 'js-cookie';
import { ConfigContext } from "../../contexts/config-context";

export const TokenVerification = () => {
    const { logoutRoute } = useContext(ConfigContext);
    const { isValid, setToken, setIdToken, idToken } = useContext(AuthContextV2);
    const navigate = useNavigate();
    const { hash } = useLocation();

    const [error, setError] = useState(null);
    const [accessToken, setAccessToken] = useState(null);

    useEffect(() => {
        const hashAccessTokenData = hash.split('access_token=')[1] || null;
        const hashIdTokenData = hash.split('id_token=')[1] || null;
        let hashAccessToken = null;
        let hashIdToken = null;
    
        if (hashAccessTokenData != null) {
            hashAccessToken = hashAccessTokenData.split('&')[0];
        }

        if (hashIdTokenData != null) {
            hashIdToken = hashIdTokenData.split('&')[0];
        }

        
    
        if (accessToken == null) {
            setIdToken(hashIdToken || Cookies.get('id_token') || -1);
            setAccessToken(hashAccessToken || Cookies.get('token') || -1);
            setToken(hashAccessToken || Cookies.get('token') || -1);
        }
        
    }, [hash]);

    
    
    useEffect(() => {
        if (accessToken != null && error == null) {
            console.log(accessToken);            
            if (accessToken === -1 || !isValid(accessToken)) {
                setError('Unauthorized');
                console.log(logoutRoute);
                window.location.href = logoutRoute;
            } else {
                Cookies.set('token', accessToken);
                Cookies.set('id_token', idToken);
                setToken(accessToken);
                navigate('/dashboard')
                console.log('OK');
            }
        }
    }, [accessToken])

    return (
        <>
            <div>{ error || 'Loading...'}</div>
            <div>{error != null && <Link to={logoutRoute}>Login</Link>}</div>
            </>
    )
}
import React, { useContext, useState } from 'react';
import { Stack } from '../../layout/stack/stack';
import { Close } from '@mui/icons-material';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Fab } from '@mui/material';

export const CirclePanel = ({icon, className, children, title, panelVisible, setPanelVisible,}) => {
    
    return( 
        <div className={`circle-panel-wrap ${className}`}>
            <div className={`circle-panel-icon-content-wrap ${panelVisible && 'colored'}`} >
                {!panelVisible && 
                    <Fab color="#060d1f" aria-label='eye-tool' className={`map-options-icon ${panelVisible && 'colored'}`} onClick={()=> {setPanelVisible(true)}}>
                        {icon}
                    </Fab>
                }
                {panelVisible && 
                <div className='circle-panel-content-close'>
                    <Stack direction={"horizontal"} halign='space-between' valign={'start'}>
                        <span className='map-options-title small uppercase bold'>{title}</span>
                        <Close onClick={() => setPanelVisible(false)}/>
                    </Stack>
                    <div className='circle-panel-content'>
                        <PerfectScrollbar>
                            {children}
                        </PerfectScrollbar>
                    </div>
                </div>
                }
            </div>

        </div>
    )
}
import React, { useContext, useState, useEffect } from 'react'
import {
  Route,
  Routes,
  Navigate,
  useNavigate,
} from "react-router-dom";
// import loggly from "loggly";
import { useLocation } from 'react-router-dom';
import { AnimatePresence, } from "framer-motion"
import { ToastContainer } from 'react-toastify';

import 'leaflet/dist/leaflet.css';
import './App.css';
import 'react-perfect-scrollbar/dist/css/styles.min.css';
import 'react-toastify/dist/ReactToastify.css';
import FastClick from 'fastclick';

// Screens
import { HomeScreen } from './screens/home-screen/home-screen';
import { CockpitScreen } from './screens/cockpit-screen/cockpit-screen';
import { MissionPlanningScreen } from './screens/mission-planning-screen/mission-planning-screen';
import { FleetManagementScreen } from './screens/fleet-management-screen/fleet-management-screen';
import { LoginScreen } from './screens/login-screen/login-screen';
import { AuthContext } from './contexts/auth-context';
import WeatherContextProvider from './contexts/weather-context';
import { MapDisplay } from './shared-components/map-display/map-display';
import MapContextProvider from './contexts/map-context';
import LocationContextProvider from './contexts/location-context';
import { MenuBar } from './shared-components/containers/menu-bar/menu-bar';
import { ActiveFlightsPanel } from './shared-components/containers/active-flights-panel/active-flights-panel';
import FlightContextProvider from './contexts/flight-context';
import AirportContextProvider from './contexts/airport-context';
import MissionContextProvider from './contexts/mission-context';
import TerrainContextProvider from './contexts/terrain-context';
import { SearchLocation } from './shared-components/inputs/search-location/search-location';
import { motion } from 'framer-motion';
import AircraftContextProvider from './contexts/aircraft-context';
import FleetManagementContextProvider from './contexts/fleet-management-context';
import RiskAssessmentContextProvider from './contexts/risk-assessment-context';
import FileSystemContextProvider from './contexts/file-system-context';
import ADSBContextProvider from './contexts/adsb-context';
import InterfaceContextProvider, { InterfaceContext } from './contexts/interface-context';
import { FlightsScreen } from './screens/flights-screen/flights-screen';
import { ConfigContext } from './contexts/config-context';
import RIDContextProvider from './contexts/rid-context';
import { CockpitScreenV2 } from './screens/cockpit-screen-v2/cockpit-screen-v2';
import { ArrowBack, ArrowBackIos, Home } from '@mui/icons-material';
import WaypointsLibraryContextProvider from './contexts/waypoints-library-context';
import CockpitContextProvider from './contexts/cockpit-context';
import { TokenVerification } from './screens/token-verification/token-verification';
import { AuthContextV2 } from './contexts/auth-context-v2';
import { IconButton } from '@mui/material';

// const client = loggly.createClient({
//   token: "94cb7135-4b0f-4c05-9479-72a7808fc699",
//   subdomain: "skyy",
//   auth: {
//     username: "aleksandar@skyy.network",
//     password: "s1sSB2E%rB#p"
//   },
//   //
//   // Optional: Tag to send with EVERY log message
//   //
//   tags: ['GCS-errors']
// });

// // Silently processing unhandled exceptions
// process.on('uncaughtException', function(err) {
//   console.log(err);
//   client.log(err);
// });

window.addEventListener('load', () => {
  FastClick.attach(document.body);
});



export const App = () => {
  const location = useLocation();
  const { version } = useContext(ConfigContext);
  const { getToken } = useContext(AuthContextV2);
  const navigate = useNavigate();
  useEffect(() => {
    const unloadCallback = (event) => {
      event.preventDefault();
      event.returnValue = "";
      return "";
    };

    // if (location.pathname != '/verify') {
      window.addEventListener("beforeunload", unloadCallback);
      return () => window.removeEventListener("beforeunload", unloadCallback);
    // }  
  }, []);

  return (
    <div>
      <FleetManagementContextProvider>
        <AircraftContextProvider>
          <TerrainContextProvider>
            <MapContextProvider>
              <RIDContextProvider>
                <ADSBContextProvider>
                  <FileSystemContextProvider>
                    <RiskAssessmentContextProvider>
                      <WaypointsLibraryContextProvider>
                      <CockpitContextProvider>
                      <MissionContextProvider>
                        <FlightContextProvider>
                         
                          <LocationContextProvider>
                            <AirportContextProvider>
                              <WeatherContextProvider>
                                <ToastContainer style={{ top: 28, zIndex: 999999 }} theme="dark" position="top-center" hideProgressBar={true} autoClose={2000} />
                                <InterfaceContextProvider>

                                  { getToken() && (
                                    <>
                                  { location.pathname !== '/cockpit-v2' && <MenuBar marginRight={location.pathname === '/cockpit-v2' && true} />}
                                  <motion.div
                                    className="search-bar"
                                    key="search-bar"
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    exit={{ opacity: 0 }}
                                    transition={{ duration: 0.5, ease: "easeOut", delay: 0.2 }}>

                                    {location.pathname !== '/cockpit-v2' && <SearchLocation />}
                                    {location.pathname === '/cockpit-v2' &&  <div className='go-back' onClick={() => navigate('/home')}><IconButton aria-label="go-back"><ArrowBackIos/><Home/></IconButton></div>}
                                  </motion.div>
                                  {/* { location.pathname === '/cockpit' && 
                                  <ActiveFlightsPanel primaryGradient={"#0F3B60"} secondaryGradient={"#081355"}/>
                                } */}
                                  <div className='gcs-version-info'>
                                    <p className='version-info'>v{version}</p>
                                  </div>
                                  <MapDisplay key={'map-display-wrap'} tileSelectorPosition={location.pathname === '/home' || location.pathname === '/cockpit' ? 'right' : 'left'} />
                                  </>)}
                                  <AnimatePresence exitBeforeEnter initial={false}>
                                    <Routes location={location} key={location.pathname}>
                                      {getToken() ?
                                        <>
                                          <Route path="/verify" element={<TokenVerification />} />
                                          <Route path="/home" element={<HomeScreen />} />
                                          <Route path="/cockpit" element={<CockpitScreen />} />
                                          <Route path="/cockpit-v2" element={<CockpitScreenV2 />} />
                                          <Route path="/mission-planning" element={<MissionPlanningScreen />} />
                                          <Route path="/fleet-management" element={<FleetManagementScreen />} />
                                          <Route path="/flights" element={<FlightsScreen />} />
                                          <Route path="*" element={<Navigate to="/home" />} />
                                        </>
                                        :
                                        <>
                                          <Route path="/verify" element={<TokenVerification />} />
                                          <Route path="*" element={<Navigate to="/verify" />} />
                                        </>
                                      }

                                    </Routes>
                                  </AnimatePresence>
                                </InterfaceContextProvider>
                              </WeatherContextProvider>
                            </AirportContextProvider>
                          </LocationContextProvider>
                         
                        </FlightContextProvider>
                      </MissionContextProvider>
                      </CockpitContextProvider>
                      </WaypointsLibraryContextProvider>
                    </RiskAssessmentContextProvider>
                  </FileSystemContextProvider>
                </ADSBContextProvider>
              </RIDContextProvider>
            </MapContextProvider>
          </TerrainContextProvider>
        </AircraftContextProvider>
      </FleetManagementContextProvider>
    </div>
  )
}

// import logo from './logo.svg';
// import './App.css';

// function App() {
//   return (
//     <div className="App">
//       <header className="App-header">
//         <img src={logo} className="App-logo" alt="logo" />
//         <p>
//           Edit <code>src/App.js</code> and save to reload.
//         </p>
//         <a
//           className="App-link"
//           href="https://reactjs.org"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           Learn React
//         </a>
//       </header>
//     </div>
//   );
// }

// export default App;

import React, {useState, useRef, createRef} from 'react';

import {Loading} from "../../layout/loading/loading";

export const EditData = (
    {
    value,
    // defaultValue,
    className,
    prefixText,
    postfixText,
    uppercase,
    loadingWidth='20px',
    loadingHeight='10px',
    loadingPosition,
    inputWidth,
    type,
    onSubmit,
    maxLength,
    fieldType,
    min,
    disabled=false
    }) => {

    const [sync, setSync] = useState(true);
    const inputRef = createRef(null);
    const localDefaultValue = useRef(value);
    const localPendingValue = useRef(value);
    // defaultValue = localDefaultValue.current;

    if (localDefaultValue.current != value) {
        localPendingValue.current = value;

        if (inputRef.current != null) {
            inputRef.current = value;
        }
    }

    const visible = localDefaultValue.current != null;
    const inputBorder = sync ? 'green' : 'orange';

    const onUpdate = (e) => {
        if (!sync) {

            if (type == 'integer' && isNaN(parseInt(localPendingValue.current))) {
                localPendingValue.current = value;
                inputRef.current.value = localPendingValue.current;
                setSync(true);
                return;
            }

            if (type == 'float' && isNaN(parseFloat(localPendingValue.current))) {
                localPendingValue.current = value;
                inputRef.current.value = localPendingValue.current;
                setSync(true);
                return;
            }

            let newValue = null;
            switch(type) {
                case 'integer': newValue = parseInt(e.target.value); break;
                case 'float': newValue = parseFloat(e.target.value); break;
                default: newValue = e.target.value; break;
            }

            // console.log('NEW VALUE: ', newValue);
            onSubmit(newValue);
            localDefaultValue.current = newValue;
            localPendingValue.current = newValue;
            // inputRef.current.value = localPendingValue.current;
            setSync(true);
        }
    }

    console.log(fieldType, maxLength);
    
        return(
            <div key={`edit-${postfixText}-${value}`} className={'edit-data-wrap' + (className ? className: "")} >
                <p className={'edit-data-title ' + (uppercase ? "uppercase": "")}>{prefixText}</p>
                {visible ?
                 
                        <input 
                            key={`edit-${postfixText}-${value}-input`}
                            ref={inputRef}
                            style={{ borderColor: inputBorder, width: inputWidth }}
                            onKeyDown={(e) => e.key=='Enter' && onUpdate(e)}
                            onBlur={(e) => onUpdate(e)}
                            onChange={(e) => {
                                localPendingValue.current = e.target.value
                                setSync(localPendingValue.current == localDefaultValue.current);
                            }}
                            defaultValue={value}
                            type={fieldType}
                            disabled={disabled}
                        />:
                    <Loading width={loadingWidth} height={loadingHeight} position={loadingPosition}/>}
                 <i style={{ borderColor: inputBorder, width: inputWidth }}>{postfixText}</i>
            </div>
        );
    
}

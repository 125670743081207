import React, { useState } from "react";
import PerfectScrollbar from 'react-perfect-scrollbar';
import { FleetManagementEditModal } from "../fleet-management-edit-modal/fleet-management-edit-modal";
import { Loading } from "../../../../shared-components/layout/loading/loading";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import UtilityService from "../../services/utility-service";
import UtilityService from "../../../../helpers/utility-service";
import {RPATile} from "../rpa-tile/rpa-tile";


import noimage from '../../../../assets/fleet-managment-icons/drone_no_image.svg';

export const FleetManagementGridContent = ({
	data,
	loadSingleItem,
	updateSingleItem,
	deleteSingleItem,
	schema, 
	editViewNumCols, 
	additionalTabs,
	getTitle,
	getSubtitle,
	subtitleLabel,
	getImage,
	getStatus,
	getIdNum,
	filter, 
	filterFunction,
	calcCycleColor,
}) => {
	
	
	// constructor(props) {
	// 	super(props);

	// 	this.state = {
	// 		selectedItem: null,
	// 		editModalVisible: false,
	// 		data: [],
	// 		showLoader: false,
	// 	}
	// }
	const [selectedItem,setSelectedItem] = useState(null);
	const [editModalVisible, setEditModalVisible] = useState(false);
	// const [data, setData] = useState([]);
	const [showLoader, setShowLoader] = useState(false);
	console.log("Aircraft SHCEMA", schema);

		return (
				<PerfectScrollbar options={{wheelPropagation: false}}>
						{ showLoader && <Loading/> } 

						<div className='rpa-tiles-wrap'>
						
								{
										data.map(el => UtilityService.transformObject(el, schema.attributes)).filter(row => filterFunction(row, filter)).map(row => (
												
												<RPATile 
													data={{
														title: getTitle(row),
														subtitle: getSubtitle(row),
														subtitleLabel: subtitleLabel,
														idNum: getIdNum(row),
														image: getImage(row),
														status: getStatus(row),
													}} 
													// data={data}
													key={Math.random()} 
													onClick={()=>{ setSelectedItem(row); setEditModalVisible(true) }}
												/>
												)) 
								} 
						</div>
		
		
				{ selectedItem && 
						 <FleetManagementEditModal 
						 		transformData={false}
						 		data={selectedItem}
								noImage={noimage}
								shouldDisplayImage={true}
								numCols={editViewNumCols || 1}
								modalVisible={editModalVisible}
								schema={schema}
								itemId={selectedItem.id}
								onClose={() => {setEditModalVisible(false); setSelectedItem(null)}}
								// loadSingleItem={async (id) => await loadSingleItem(id)}
								// updateSingleItem={async (id, updateObj) => { await updateSingleItem(id, updateObj); await loadData() }}
								// deleteSingleItem={async (id) => { await deleteSingleItem(id); notifyDelete(); setEditModalVisible(false);  await loadData(); }}
								title={(selectedItem) => getTitle(selectedItem)}
								entityName={schema.entityName}
								transformObject={(obj) => UtilityService.transformObject(obj, schema.attributes)}
								additionalTabs={additionalTabs || []}
						/>
				}
			</PerfectScrollbar>
		);
	}


export const flightlogSchema = {
	loadSingleItem: (flightlogContext, id) => flightlogContext.getSingleMaintenance(id),
	createSingleItem: async (flightlogContext, data) => await flightlogContext.createMaintenance(data),

	entityName: 'Flight Logs',
	
	attributes: [
		{   
				key: 'id',
				path: 'id',
				name: 'ID',
				type: 'text',
				hidden: true,
				disabled: true,
				autogenerated: true,
		},
		{
				key: 'att',
				path: 'data.att',
				name: 'Attitude information',
                type: 'list',
                itemSchema: [{
                    key: 'AHRS',
                    title: 'AHRS',
                    type: 'text',
                }, 
                {
                    key: 'roll',
                    title: 'Roll',
                    type: 'text',
                }, 
                {
                    key: 'yaw',
                    title: 'Yaw',
                    type: 'text',
                }, 
                {
                    key: 'pitch',
                    title: 'Pitch',
                    type: 'text',
                }, 
                {
                    key: 'hdg',
                    title: 'Heading',
                    type: 'text',
                }]
		},
        {
            key: 'cmd',
            path: 'data.cmd',
            name: 'Commands received from GCS',
            type: 'list',
            itemSchema: [{
                key: 'ctot',
                title: 'Ctot',
                type: 'text',
            }, 
            {
                key: 'cld',
                title: 'Cld',
                type: 'text',
            }, 
            {
                key: 'CMDalt ',
                title: 'CMD alt ',
                type: 'text',
            }, 
            {
                key: 'CMDlatlng',
                title: 'CMD lat / lng',
                type: 'text',
            }, ]
    },
    {
        key: 'batteryInfo',
        path: 'data.batteryInfo',
        name: 'Current (battery information)',
        type: 'list',
        itemSchema: [{
            key: 'pilotInputThrottle',
            title: 'Pilot Input Throttle',
            type: 'text',
        }, 
        {
            key: 'integratedThrottle',
            title: 'Integrated throttle',
            type: 'text',
        }, 
        {
            key: 'batteryVoltageInVolts',
            title: 'Battery voltage in volts * 100',
            type: 'text',
        }, 
        {
            key: 'boardVoltage',
            title: 'Board Voltage',
            type: 'text',
        },{
            key: 'totalCurrent',
            title: 'Total Current Drawn from Battery',
            type: 'text',
        },  ]
},
        {
            key: 'flightStartTime',
            path: 'data.type',
            name: 'Flight Start Time',
            type: 'text',
        },
        {
            key: 'flightEndTime',
            path: 'data.type',
            name: 'Flight End Time',
            type: 'text',
        },
		{
            key: 'date',
            path: 'data.type',
            name: 'Date',
            type: 'text',
        },
		{
			key: 'startLocation',
			path: 'data.startLocation',
			name: 'Start Location',
			type: 'text',
		},
        {
			key: 'endLocation',
			path: 'data.endLocation',
			name: 'End Location',
			type: 'text',
		},
		{
			key: 'maxAltitude',
			path: 'data.maxAltitude',
			name: 'Max Altitude',
			type: 'text',
		},
		{
			key: 'speedKMh',
			path: 'data.speedKMh',
			name: 'Speed (Km/h)',
			type: 'text',
		},
		{
			key: 'speedMs',
			path: 'data.speedMs',
			name: 'Speed M/s',
			type: 'text',
		},
        {
			key: 'speedMph',
			path: 'data.speedMph',
			name: 'Speed (Mp/h)',
			type: 'text',
		},
		{
			key: 'batteryStartV',
			path: 'data.batteryStartV',
			name: 'Battery Start Voltage',
			type: 'text',
		},
        {
			key: 'batterySagV',
			path: 'data.batterySagV',
			name: 'Battery Sag Voltage',
			type: 'text',
		},
        {
			key: 'batteryEndV',
			path: 'data.batteryEndV',
			name: 'Battery End Voltage',
			type: 'text',
		},
        {
			key: 'batterySagV',
			path: 'data.batterySagV',
			name: 'Battery Sag Voltage',
			type: 'text',
		},
		{
			key: 'checklistCompletePre',
			path: 'data.checklistCompletePre',
			name: 'Checklist Preflight',
			type: 'text',
		},
		{
			key: 'checklistCompletePost',
			path: 'data.checklistCompletePost',
			name: 'Checklist Postflight',
			type: 'text',
		},
		{
			key: 'timeRemaining',
			path: 'data.timeRemaining',
			name: 'Time Remaining',
			type: 'text',
		},
		{
			key: 'description',
			path: 'data.description',
			name: 'Description',
			type: 'text',
		},


		// {
		// 	key: 'role',
		// 	path: 'data.role',
		// 	name: 'Role',
		// 	type: 'text',
		// },
	]
};
import L from 'leaflet';

import compass from "../assets/map-icons/compass-icon.svg";
import path from "../assets/map-icons/path-icon.svg";
import intersectionWaypoint from "../assets/map-icons/intersection-icon-waypoint.svg";
import intersectionIcon from "../assets/map-icons/intersection-icon.svg";
import intersectionIconSelected from "../assets/map-icons/intersection-icon-selected.svg";
import intersectionIconTakeoff from "../assets/map-icons/intersection-icon-takeoff.svg";
import intersectionIconLanding from "../assets/map-icons/intersection-icon-landing.svg";
import vor from "../assets/map-icons/VOR-DME.svg";
import ndb from "../assets/map-icons/NDB.svg";
import airport from "../assets/map-icons/airport-icon.svg";
import radar from "../assets/map-icons/radar-icon.svg";
import radioCenter from "../assets/map-icons/radio-center-icon.svg";
import addButton from "../assets/map-icons/add-button-icon-2.png";
import mapLocation from "../assets/map-icons/map-location-icon.svg";
import rtl from "../assets/map-icons/rtl-icon-red.svg";
import rtlWhite from "../assets/map-icons/rtl-icon.svg";
import rtlDisabled from "../assets/map-icons/rtl-icon-gray.svg";
import rtlBlue from "../assets/map-icons/rtl-icon-blue.svg";
import aircraftMarker from "../assets/map-icons/aircraft-marker-icon.svg";
import aircraftMarkerDisarmed from "../assets/map-icons/aircraft-marker-disarmed-icon.svg";
import aircraftMarkerStatic from "../assets/map-icons/aircraft-marker-static-icon.svg";
import adsbAircraftMarker from "../assets/map-icons/adsb-aircraft-icon.svg";
import place from "../assets/map-icons/place.svg";
import waypointOutline from "../assets/map-icons/waypoint-outline.svg";

import start from "../assets/cockpit-icons/start.png";
import pause from "../assets/cockpit-icons/pause.png";
import arm from "../assets/cockpit-icons/arm.png";
import disarm from "../assets/cockpit-icons/disarm.png";
import takeoff from "../assets/cockpit-icons/take-off.png";
import land from "../assets/cockpit-icons/land.png";
import open from "../assets/cockpit-icons/open.png";
import upload from "../assets/cockpit-icons/upload.png";
import clear from "../assets/cockpit-icons/clear.png";
import pathCockpit from "../assets/cockpit-icons/path.svg";
// import disarm from "../../assets/cockpit-icons/disarm.png";

import quadIconSVG from '../assets/map-icons/quad.svg';
import quadHeadingIconSVG from '../assets/map-icons/quad-heading.svg';
import heliIconSVG from '../assets/map-icons/heli.svg';
import heliHeadingIconSVG from '../assets/map-icons/heli-heading.svg';
import gliderIconSVG from '../assets/map-icons/glider.svg';
import gliderHeadingIconSVG from '../assets/map-icons/glider-heading.svg';


const waypointIcon = L.icon({
    ...L.Icon.Default.prototype.options,
    iconUrl: intersectionIcon,
    iconRetinaUrl: intersectionIcon,
    shadowUrl: intersectionIcon,
    iconSize: [30, 30],
    shadowSize: [0, 0],
    iconAnchor: [15, 15]
});

const waypointIconSelected = L.icon({
    ...L.Icon.Default.prototype.options,
    iconUrl: intersectionIconSelected,
    iconRetinaUrl: intersectionIconSelected,
    shadowUrl: intersectionIconSelected,
    iconSize: [20, 18],
    shadowSize: [0, 0],
    iconAnchor: [10, 9]
});

const takeoffIcon = L.icon({
    ...L.Icon.Default.prototype.options,
    iconUrl: intersectionIconTakeoff,
    iconRetinaUrl: intersectionIconTakeoff,
    shadowUrl: intersectionIconTakeoff,
    iconSize: [30, 30],
    shadowSize: [0, 0],
    iconAnchor: [15, 15]
});

const landingIcon = L.icon({
    ...L.Icon.Default.prototype.options,
    iconUrl: intersectionIconLanding,
    iconRetinaUrl: intersectionIconLanding,
    shadowUrl: intersectionIconLanding,
    iconSize: [30, 30],
    shadowSize: [0, 0],
    iconAnchor: [15, 15]
});

const vorIcon = L.icon({
    ...L.Icon.Default.prototype.options,
    iconUrl: vor,
    iconRetinaUrl: vor,
    shadowUrl: vor,
    iconSize: [20, 18],
    shadowSize: [0, 0],
    iconAnchor: [10, 9]
});

const ndbIcon = L.icon({
    ...L.Icon.Default.prototype.options,
    iconUrl: ndb,
    iconRetinaUrl: ndb,
    shadowUrl: ndb,
    iconSize: [20, 18],
    shadowSize: [0, 0],
    iconAnchor: [10, 9]
});

const intersectionWaypointIcon = L.icon({
    ...L.Icon.Default.prototype.options,
    iconUrl: intersectionWaypoint,
    iconRetinaUrl: intersectionWaypoint,
    shadowUrl: intersectionWaypoint,
    iconSize: [20, 18],
    shadowSize: [0, 0],
    iconAnchor: [10, 9]
});

const airportIcon = L.icon({
    ...L.Icon.Default.prototype.options,
    iconUrl: airport,
    iconRetinaUrl: airport,
    shadowUrl: airport,
    iconSize: [20, 20],
    shadowSize: [0, 0],
    iconAnchor: [10, 20],
});

const radarIcon = L.icon({
    ...L.Icon.Default.prototype.options,
    iconUrl: radar,
    iconRetinaUrl: radar,
    shadowUrl: radar,
    iconSize: [20, 20],
    shadowSize: [0, 0],
    iconAnchor: [10, 20],
});

const radioCenterIcon = L.icon({
    ...L.Icon.Default.prototype.options,
    iconUrl: radioCenter,
    iconRetinaUrl: radioCenter,
    shadowUrl: radioCenter,
    iconSize: [20, 20],
    shadowSize: [0, 0],
    iconAnchor: [10, 20],
});

const addButtonIcon = L.icon({
    ...L.Icon.Default.prototype.options,
    iconUrl: addButton,
    iconRetinaUrl: addButton,
    shadowUrl: addButton,
    iconSize: [20, 20],
    shadowSize: [0, 0],
    iconAnchor: [10, 10],
});

const mapLocationIcon = L.icon({
    ...L.Icon.Default.prototype.options,
    iconUrl: mapLocation,
    iconRetinaUrl: mapLocation,
    shadowUrl: mapLocation,
    iconSize: [20, 30],
    shadowSize: [0, 0],
    iconAnchor: [10, 30],
});

const rtlIcon = L.icon({
    ...L.Icon.Default.prototype.options,
    iconUrl: rtl,
    iconRetinaUrl: rtl,
    shadowUrl: rtl,
    iconSize: [20, 20],
    shadowSize: [0, 0],
    iconAnchor: [10, 10],
});

const rtlBlueIcon = L.icon({
    ...L.Icon.Default.prototype.options,
    iconUrl: rtlBlue,
    iconRetinaUrl: rtlBlue,
    shadowUrl: rtlBlue,
    iconSize: [20, 20],
    shadowSize: [0, 0],
    iconAnchor: [10, 10],
});

const rtlDisabledIcon = L.icon({
    ...L.Icon.Default.prototype.options,
    iconUrl: rtlDisabled,
    iconRetinaUrl: rtlDisabled,
    shadowUrl: rtlDisabled,
    iconSize: [20, 20],
    shadowSize: [0, 0],
    iconAnchor: [10, 10],
});

const aircraftMarkerIcon = L.icon({
    ...L.Icon.Default.prototype.options,
    iconUrl: aircraftMarker,
    iconRetinaUrl: aircraftMarker,
    shadowUrl: aircraftMarker,
    iconSize: [32, 32],
    shadowSize: [0, 0],
    iconAnchor: [16, 16],
});

const aircraftMarkerDisarmedIcon = L.icon({
    ...L.Icon.Default.prototype.options,
    iconUrl: aircraftMarkerDisarmed,
    iconRetinaUrl: aircraftMarkerDisarmed,
    shadowUrl: aircraftMarkerDisarmed,
    iconSize: [32, 32],
    shadowSize: [0, 0],
    iconAnchor: [16, 16],
});

const adsbAircraftMarkerIcon = L.icon({
    ...L.Icon.Default.prototype.options,
    iconUrl: adsbAircraftMarker,
    iconRetinaUrl: adsbAircraftMarker,
    shadowUrl: adsbAircraftMarker,
    iconSize: [30, 30],
    shadowSize: [0, 0],
    iconAnchor: [15, 15],
});

const aircraftMarkerStaticIcon = L.icon({
    ...L.Icon.Default.prototype.options,
    iconUrl: aircraftMarkerStatic,
    iconRetinaUrl: aircraftMarkerStatic,
    shadowUrl: aircraftMarkerStatic,
    iconSize: [46, 55],
    shadowSize: [0, 0],
    iconAnchor: [23, 27],
});


const quadIcon = L.icon({
    ...L.Icon.Default.prototype.options,
    iconUrl: quadIconSVG,
    iconRetinaUrl: quadIconSVG,
    shadowUrl: quadIconSVG,
    iconSize: [30, 30],
    shadowSize: [0, 0],
    iconAnchor: [15, 15]
});

const quadHeadingIcon = L.icon({
    ...L.Icon.Default.prototype.options,
    iconUrl: quadHeadingIconSVG,
    iconRetinaUrl: quadHeadingIconSVG,
    shadowUrl: quadHeadingIconSVG,
	iconSize: [35, 35],
    shadowSize: [0, 0],
    iconAnchor: [16, 16]
});

const heliIcon = L.icon({
    ...L.Icon.Default.prototype.options,
    iconUrl: heliIconSVG,
    iconRetinaUrl: heliIconSVG,
    shadowUrl: heliIconSVG,
    iconSize: [30, 30],
    shadowSize: [0, 0],
    iconAnchor: [15, 15]
});

const heliHeadingIcon = L.icon({
    ...L.Icon.Default.prototype.options,
    iconUrl: heliHeadingIconSVG,
    iconRetinaUrl: heliHeadingIconSVG,
    shadowUrl: heliHeadingIconSVG,
	iconSize: [35, 35],
    shadowSize: [0, 0],
    iconAnchor: [16, 16]
});

const gliderIcon = L.icon({
    ...L.Icon.Default.prototype.options,
    iconUrl: gliderIconSVG,
    iconRetinaUrl: gliderIconSVG,
    shadowUrl: gliderIconSVG,
    iconSize: [30, 30],
    shadowSize: [0, 0],
    iconAnchor: [15, 15]
});

const gliderHeadingIcon = L.icon({
    ...L.Icon.Default.prototype.options,
    iconUrl: gliderHeadingIconSVG,
    iconRetinaUrl: gliderHeadingIconSVG,
    shadowUrl: gliderHeadingIconSVG,
	iconSize: [35, 35],
    shadowSize: [0, 0],
    iconAnchor: [16, 16]
});


export default {
    regular: {
        aircraftMarkerStaticIcon: aircraftMarkerStatic,
        aircraftMarkerDisarmedIcon: aircraftMarkerDisarmed,
        aircraftMarkerIcon: aircraftMarker,
        rtlIcon: rtlWhite,
        rtlBlueIcon: rtlBlue,
        rtlDisabledIcon: rtlDisabled,
        mapLocationIcon: mapLocation,
        addButtonIcon: addButton,
        airportIcon: airport,
        radarIcon: radar,
        radioCenterIcon: radioCenter,
        waypointIcon: intersectionIcon,
        waypointIconOutline: waypointOutline,
        waypointIconSelected: intersectionIconSelected,
        takeoffIcon: intersectionIconTakeoff,
        landingIcon: intersectionIconLanding,
        vorIcon: vor,
        ndbIcon: ndb,
        intersectionWaypointIcon: intersectionWaypoint,
        compassIcon: compass,
        pathIcon: path,
        placeIcon: place,
        gliderIcon: gliderIcon,
        heliIcon: heliIcon,
        quadIcon: quadIcon,
    },
    map: {
        riskPoint: (score, selected) => (
            L.divIcon({
                iconSize: [20, 20],
                iconAnchor: [10, 10],
                className: 'aircraft-marker',
                html: `<span 
                        class="risk-marker ${selected && 'selected'}"
                        style="background-color: ${score > 1e-6 ? 'red' : 'lime'}" 
                        >
                        </span>`
            })
        ),
        adsbAircraft: (rotation) => (
            L.divIcon({
                iconSize: [32, 32],
                iconAnchor: [16, 16],
                className: 'adsb-aircraft-marker',
                html: `<img 
                        style="transform: rotate(${rotation}deg);"
                        height="32" 
                        width="32" 
                        src=${adsbAircraftMarker}>`
            })
        ),
        aircraftMarkerStaticIcon,
        aircraftMarkerDisarmedIconRotatable: (rotation, aircraftColor) => (
            L.divIcon({
                iconSize: [32, 32],
                iconAnchor: [16, 16],
                className: 'aircraft-marker',
                html: `<span 
                            style="transform: rotate(${rotation}deg); background-color: ${aircraftColor}"
                            class="aircraft-marker-icon">
                            <span class="aircraft-marker-icon-dot"></span>
                        </span>`
            })
        ),
        aircraftMarkerIconRotatable: (rotation, aircraftColor) => {
            return (
            L.divIcon({
                iconSize: [32, 32],
                iconAnchor: [16, 16],
                className: 'aircraft-marker',
                html: `<span 
                            style="transform: rotate(${rotation}deg); background-color: white"
                            class="aircraft-marker-icon">
                            <span class="aircraft-marker-icon-dot" style="background-color: ${aircraftColor}"></span>
                        </span>`
                    })
        )},
        aircraftMarkerIcon,
        rtlIcon,
        rtlBlueIcon,
        rtlDisabledIcon,
        mapLocationIcon,
        addButtonIcon,
        radarIcon,
        radioCenterIcon,
        airportIcon,
        waypointIcon,
        waypointIconSelected,
        takeoffIcon,
        landingIcon,
        vorIcon,
        ndbIcon,
        intersectionWaypointIcon,
        gliderIcon,
        gliderHeadingIcon,
        gliderMarkerIconRotatable: (rotation) => {
            return (
            L.divIcon({
                iconSize: [35, 35],
                shadowSize: [0, 0],
                iconAnchor: [16, 16],
                className: 'aircraft-marker',
                html:`<img 
                style="transform: rotate(${rotation}deg);"
                height="35" 
                width="35" 
                src=${gliderHeadingIcon}>`
                    })
        )},
        quadIcon,
        quadHeadingIcon,
        quadMarkerIconRotatable: (rotation) => {
            return (
            L.divIcon({
                iconSize: [35, 35],
                shadowSize: [0, 0],
                iconAnchor: [16, 16],
                className: 'aircraft-marker',
                html: `<img 
                style="transform: rotate(${rotation}deg);"
                height="35" 
                width="35" 
                src=${quadHeadingIcon}>`
                    })
        )},
        heliIcon,
        heliHeadingIcon,
        heliMarkerIconRotatable: (rotation) => {
            return (
            L.divIcon({
                iconSize: [35, 35],
                shadowSize: [0, 0],
                iconAnchor: [16, 16],
                className: 'aircraft-marker',
                html: `<img 
                style="transform: rotate(${rotation}deg);"
                height="35" 
                width="35" 
                src=${heliHeadingIcon}>`
                    })
        )},
    },
    cockpit: {
        start: start,
        pause: pause,
        arm: arm,
        disarm: disarm,
        rtl: rtl,
        takeoff: takeoff,
        land: land,
        open: open,
        upload: upload,
        clear: clear,
        path: path
    }
};

import React, { useRef } from 'react';

// class VerticalSpeedBar extends React.Component {
export const VerticalSpeedBar = ({verticalSpeedValue, min, max}) => {
    
    // const {verticalSpeedValue: rawSpeed, min, max } = this.props;
    // const verticalSpeedValue = parseFloat(rawSpeed);
    // const { scale } = this;

    //     const { angle, scaledH } = this.calcAngle(verticalSpeedValue);


    //     //const scaleValues = this.calcScale(min,max);

    const scaleBarValues = [2,1,0,-1,-2];
    const scale = useRef(scaleBarValues.map((value)=>(
        <p className='vertical-bar-numerical-value' key={`vertical-bar-numerical-${value}`}>{value}</p>
    )));

    const previousValues = useRef({ previousSpeed: 0.0, previousScaledH: 38.61, eps: 0.01});
    
    const calcScale = (min,max)=>{
        const scale = [];
        let b=0;
        for(let i=min; i <= max; i++ ) {
            scale[b] = i;
            b++;
        }
        return scale;
    }
    
    const calcAngle = (verticalSpeedValue) => {
        const { previousSpeed, previousAngle, eps, previousScaledH } = previousValues.current;

        if (Math.abs(previousSpeed - verticalSpeedValue) < eps) {
            return { angle: previousAngle, scaledH: previousScaledH };
        }

        const b = verticalSpeedValue * 22;
        const a = 38.61;
        const h = Math.sqrt(a * a + b * b);

        const angle = verticalSpeedValue > 0 ? Math.acos(a / h) : -Math.acos(a / h);
        
        previousValues.current.previousSpeed = verticalSpeedValue;
        previousValues.current.previousAngle = angle;
        const scaledH = h / 40;
        previousValues.current.previousScaledH = scaledH;

        return { angle, scaledH };
    }

    // //a= value * 20 kateta, 40
    // render() {
        const rawSpeed = verticalSpeedValue;
        const verticalSpeedValueFloat = parseFloat(rawSpeed);

        const { angle, scaledH } = calcAngle(verticalSpeedValueFloat);
        const scaleValues = calcScale(min,max);
        return(
            <section className='vertical-speed-bar-wrap'>
               <div className='vertical-speed-percentage-sign'>
                   <p>m/s</p>
               </div>
               <div className='vertical-speed-bar'>
                   <div className='vertical-speed-bar-top'>
                        <div className='vertical-speed-bar-numerical'>
                           { scale.current }
                        </div>
                        <div className='vertical-speed-bar-hand'>
                            <span className='vertical-speed-bar-hand-line' style={{ transform: `rotate(${angle}rad) scale(${scaledH})`}}></span>
                        </div>
                    </div>
                    <div className='vertical-speed-bar-value'>
                        <p>{parseFloat(verticalSpeedValue.toFixed(2)) == 0.00 ? 
                        "0.00" 
                        :
                        verticalSpeedValue.toFixed(2)}</p> 
                    </div>
               </div>
            </section>
        );
    
}

// export default React.memo(VerticalSpeedBar);
import UtilityService from "../../../../utility-service";

// GLOBAL_POSITION_INT (#33)
export function globalPositionInt(message, aircraft) {
	const {
		time_boot_ms,
		lat,
		lon,
		alt,
		relative_alt,
		vx,
		vy,
		vz,
		hdg,
	} = message;

	const { positionState, positionStateSetters } = aircraft;
	const { path, lastTimestamp, latitude: currentLatitude, longitude: currentLongitude, heading: currentHeading, altitude: currentAltitude } = positionState.getState();
	const now = Date.now();

	const processedLatitude = lat / 10000000;
	const processedLongitude = lon / 10000000;
	const processedAltitude = alt / 1000;
	const processedRelativeAltitude = relative_alt / 1000;
	const processedHeading = hdg / 100;

	const newPosition = { latitude: processedLatitude, longitude: processedLongitude, altitude: processedAltitude };

	if (path.length > 0) {
		const lastPosition = path[path.length - 1];
		const distance = UtilityService.distance(lastPosition, newPosition);
		if (distance > 3) {
			path.push(newPosition);
		}
	} else {
		path.push(newPosition);
	}

	if (lastTimestamp == null || (now - lastTimestamp > 50 && (
		Math.abs(currentHeading - processedHeading)  >= 1 ||
		Math.abs(currentLatitude - processedLatitude) >= 0.0000001 ||
		Math.abs(currentLongitude - processedLongitude) >= 0.0000001 ||
		Math.abs(currentAltitude - processedAltitude) >= 0.1
			))) {
				
		positionStateSetters.setPosition({
			latitude: processedLatitude,
			longitude: processedLongitude,
			altitude: processedAltitude,
			relativeAltitude: processedRelativeAltitude,
			heading: processedHeading,
			path,
			lastTimestamp: now,
			time_boot_ms,
		})
	}
}
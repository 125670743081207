import C2ConnectionService from "./c2-connection-service";
import MAVLinkService from "./protocol-services/mavlink-service";
import CommandApi from "./protocol-services/command-api";
import _ from "underscore";
import MavLinkPacketSplitter from "./message-splitting/mavlink";

class AircraftConnection {
	constructor(aircraft, connectionType, port, config, connectingUser, isVirtual=false, logs=null) {
		console.log(aircraft, connectionType, port);
		this.aircraft = aircraft;
		this.connectionType = connectionType;
		this.connection = null;
		this.mavlinkService = new MAVLinkService(2);
		this.commandApi = CommandApi((message) => this.sendMessage(message), this.mavlinkService, 1, 1);
		this.port = port;
		this.config = config;
		this.connectingUser = connectingUser

		this.isVirtual = isVirtual;
		this.logs = logs;
		this.virtualPlaybackTime = 0;
		this.currentLog = 0;
		this.isReplayActive = false;
		this.playbackInterval = null;
	}

	incrementTime() {
		this.virtualPlaybackTime += 10;
	}

	stopPlayback() {
		if (this.playbackInterval != null) {
			clearInterval(this.playbackInterval);
		}
		this.isReplayActive = false;
	}

	_onControlMessage(message) {
		this.aircraft.handleControlMessage(JSON.parse(message));
	}

	startPlayback(startTime) {
		if (this.logs.length === 0) {
			return;
		}

		if (this.playbackInterval != null) {
			clearInterval(this.playbackInterval);
		}

		this.virtualPlaybackTime = startTime;
		// Move to current log by start timestamp
		for (this.currentLog = 0; this.currentLog.timestamp < startTime && this.currentLog < this.logs.length; this.currentLog += 1) {}
		if (this.currentLog >= this.logs.length) {
			this.stopPlayback();
			return;
		}

		this.isReplayActive = true;
		this.playbackInterval = setInterval(() => {
			for (;this.currentLog < this.logs.length && this.logs[this.currentLog].timestamp <= this.virtualPlaybackTime; this.currentLog += 1) {
				this._onData(this.logs[this.currentLog].message);
			}
			if (this.currentLog >= this.logs.length) {
				this.stopPlayback();
			}
			this.incrementTime();
		}, 10)
		

	}

	/**
	 * General message handler
	 */
	_onData(rawData) {
		// if (this.mavRead != null) {
			// this.virtualPort.writeToComputer(rawData);
			// this.mavRead.write(rawData);
		// }
		this.splitter.transform(rawData, this.parseMessage.bind(this));
	}

	parseMessage(data) {
		if (this.config.logs) {
			console.log(`Message: ${data}`);
		}
		switch (this.aircraft.protocol) {
			case 'mavlink':
				try {
					const { message: decodedMessage, mavlinkVersion } = this.mavlinkService.decode(data);
					if (decodedMessage != null) {
						this.aircraft.handleMessage(decodedMessage, mavlinkVersion);
					}
				} catch (err) {
					console.log(err);
				}
				break;
			default: throw new Error(`Unsupported protocol: ${this.aircraft.protocol}`);
		}
	}

	// async processSerial(port) {
	// 	const splitter = new MavLinkPacketSplitter();
	// 	while (port.readable && !this.disconnected) {
	// 		this.reader = port.readable.getReader();

	// 		try {
	// 		  while (true) {
	// 			const { value, done } = await this.reader.read();

	// 			if (done) {
	// 			  // Allow the serial port to be closed later.
	// 			  this.reader.releaseLock();
	// 			  break;
	// 			}
	// 			if (value) {
	// 				if (this.config.logs) {
	// 					console.log(`Data chunk: ${value}`)
	// 				}
	// 			  	this._onData(value, (data) => splitter.transform(value));
	// 			}
	// 		  }
	// 		} catch (error) {
	// 			console.log(error);
	// 			if (this.disconnected) {
	// 				await this.reader.releaseLock();
	// 				await this.port.close();
	// 				return;
	// 			}
	// 		  // TODO: Handle non-fatal read error.
	// 		}
	// 	  }
	// 	//   if (this.disconnected) {
	// 	// 	console.log('Disconnected');
	// 	// 	await port.close();
	// 	// 	return;
	// 	// }
	// }

	/**
	 * Subscribe to aircraft's communication channel
	 */
	connect() {
		this.splitter = new MavLinkPacketSplitter();
		// this.mavReadStream = new Stream.Readable();
		// this.mavRead = this.mavReadStream.pipe();
		// this.mavRead.on('data', (message) => this.parseMessage);

		// this.virtualPort = new VirtualSerialPort('COM10', { baudrate: 57600 }); // still works if NODE_ENV is set to development!
		// this.virtualPort.on('open', (err) => {
		// 	if (!err) {
		// 		console.log(this.virtualPort);
		// 		console.log(this.virtualPort.pipe);
		// 		this.mavRead = this.virtualPort.pipe(new MavLinkPacketSplitter());
		// 		console.log(this.mavRead);
		// 		this.mavRead.on('data', (message) => this.parseMessage(message));
		// 	} else {
		// 		console.log(err);
		// 	}
		// })


		return new Promise(async (resolve, reject) => {
			const { host, port } = this.aircraft.c2ServiceConfig;
			let c2Connection = null;

			console.log(this.connectionType);

			switch (this.connectionType) {
				case 'SERIAL':
					c2Connection = C2ConnectionService.createC2Connection({ host: 'localhost', port: 8978 }, 'SERIAL', null);
					break;
				case 'LTE':
					c2Connection = C2ConnectionService.createC2Connection({ host, port }, this.aircraft.aircraftId, this.connectingUser);
					break;
				default: throw new Error(`Failed to create connection, unsupported connection type: ${this.connectionType}`)
			}
			
			c2Connection.onMessage((data) => this._onData(data));
			c2Connection.onControlMessage((rawMessage) => this._onControlMessage(rawMessage))
			// c2Connection.onConnected(() => this.aircraft.setConnected());
			// c2Connection.onDisonnected(() => this.aircraft.disonnect());
			c2Connection.onSubscribed(() => resolve());
			c2Connection.connect()//.then(() => { this.aircraft.setConnected(true);});

			this.connection = c2Connection;
		});
	}

	disconnect() {
		// switch(this.connectionType) {
		// case 'LTE':
		this.connection.disconnect();
		C2ConnectionService.destroyC2Connection(this.aircraft.aircraftId);
		this.connection = null;
		console.log('Disconnected');
		// break;
		// case 'SERIAL': 
		// 	// this.port.readable.releaseLock();
		// 	// this.echoStream.cancel();
		// 	this.disconnected = true;
		// 	this.reader.releaseLock();
		// 	// this.reader.cancel();
		// 	// this.echoStream.cancel().then(() => this.port.close())
		// 	break;
		// default: throw new Error(`Failed to close connection, unsupported connection type: ${this.connectionType}`);
		// }
	}

	async sendMessage(messageBuffer) {
		const { isConnected } = this.aircraft.generalState.getState();
		if (isConnected) {
			// if (this.connectionType != 'SERIAL') {
			this.connection.emit(Buffer.from(messageBuffer));
			// console.log(messageBuffer)
			// } else {
			// 	const writer = this.port.writable.getWriter()
			// 	await writer.write(messageBuffer);
			// 	writer.releaseLock();
			// }
		}
	}

	// Commands
	heartbeat(confirmation = 0) {
		this.commandApi.heartbeat(confirmation);
	}

	armVehicle(confirmation = 0) {
		this.commandApi.armVehicle(confirmation);
	}

	disarmVehicle(confirmation = 0) {
		this.commandApi.disarmVehicle(confirmation);
	}

	setFlightMode(mode, confirmation = 0) {
		this.commandApi.setFlightMode(mode, confirmation);
	}

	writePartialWaypointlList(startIndex, endIndex) {
		this.commandApi.writePartialWaypointsList(startIndex, endIndex);
	}

	missionCount(numItems, missionType) {
		this.commandApi.missionCount(numItems, missionType);
	}

	writeMissionItem(latitude, longitude, altitude, speed, seq, action, missionType, radius) {
		this.commandApi.writeMissionItem(seq, latitude, longitude, altitude, speed, action, missionType, radius);
	}

	announceMissionItems(startIndex, endIndex, missionType) {
		this.commandApi.announceMissionItems(startIndex, endIndex, missionType);
	}

	requestMission(missionType) {
		this.commandApi.requestMission(missionType);
	}

	requestMissionItem(seq, missionType) {
		this.commandApi.requestMissionItem(seq, missionType);
	}

	startMission(startWaypoint, lastWaypoint) {
		this.commandApi.startMission(startWaypoint, lastWaypoint);
	}

	takeOff(latitude, longitude, altitude) {
		this.commandApi.takeOff(latitude, longitude, altitude);
	}

	clearAllWaypoints() {
		this.commandApi.clearAllWaypoints();
	}

	clearAllRtl() {
		this.commandApi.clearAllRtl()
	}

	setMissionCurrent(seq) {
		this.commandApi.setMissionCurrent(seq);
	}

	gotoWaypoint(wpNumber, latitude, longitude, altitude, yaw) {
		this.commandApi.gotoWaypoint(wpNumber, latitude, longitude, altitude, yaw);
	}

	setYaw(angle, direction) {
		this.commandApi.setYaw(angle, direction);
	}

	setAltitude(altitude) {
		this.commandApi.setAltitude(altitude);
	}

	setSpeed(speed) {
		this.commandApi.setSpeed(speed);
	}

	moveForward(time_boot_ms, speed) {
		this.commandApi.moveForward(time_boot_ms, speed);
	}

	setAmslAltitude(time_boot_ms, latitude, longitude, amslAltitude) {
		this.commandApi.setAmslAltitude(time_boot_ms, latitude, longitude, amslAltitude);
	}

	orbit(latitude, longitude, altitude, radius) {
		this.commandApi.orbit(latitude, longitude, altitude, radius);
	}
}

export default AircraftConnection; 
import { color } from "@mui/system";
import React, { createContext, useContext, useEffect, useRef, useState } from "react";
import Aircraft from "../helpers/aircraft/aircraft";
import { AuthContext } from "./auth-context";
import Cookies from 'js-cookie';
import { ConfigContext } from "./config-context";
import { toast } from "react-toastify";
import axios from "axios";
import { FleetManagementContext } from "./fleet-management-context";
import { RIDContext } from "./rid-context";
import { AuthContextV2 } from "./auth-context-v2";
import jwtDecode from "jwt-decode";

export const AircraftContext = createContext();

const AircraftContextProvider = ({ children }) => {
	const { idToken, pilotUser } = useContext(AuthContextV2);
	const { services: { 
		c2Service: c2ServiceConfig,
		telemetryService: telemetryServiceConfig,
	} } = useContext(ConfigContext);
	const config = useContext(ConfigContext);
	const { 
		personnel,
		allAircraft,
		aircraftTypes,
	 } = useContext(FleetManagementContext);

	const availableAircraftColors = useRef(['#008eff', '#ffa500', '#8bff7b', '#cb43c2']);

	const [connectedAircraft, setConnectedAircraft] = useState([]);
	const [selectedAircraft, setSelectedAircraft] = useState(null);
	const localAircraftList = useRef([]);
	const cookiesData = useRef(null);

	const registerFlight = (flightRegistrationObj) => {
        return axios.post(`${telemetryServiceConfig.host}:${telemetryServiceConfig.port}/flights`, flightRegistrationObj)
	}

	const getAircraft = (aircraftKey) => {
		console.log(aircraftKey);
		console.log(allAircraft);

		const aircraft = allAircraft.find(ac => ac.data.key == aircraftKey);
		if (aircraft == null) {
			return null;
		}

		return {...aircraft.data};
	}

	const getAircraftType = (aircraftTypeId) => {
		console.log(aircraftTypeId);
		console.log(aircraftTypes);
		return (aircraftTypes.find(at => at.id == aircraftTypeId) || {}).data;
	}

	const connectToAircraft = (aircraftId, aircraftName, initialConnectionType, navigate, port, autoconnect=true, initialMission=null) => {
		return new Promise((resolve, reject) => {
			const decodedToken = jwtDecode(idToken);
			console.log(decodedToken);
			const connectingUser = { username: decodedToken['cognito:username'], c2Password: decodedToken['custom:c2_password'] };
			let operator = null;
			const aircraftData = getAircraft(aircraftId);
			console.log(aircraftData);
			if (aircraftData != null) {
				console.log(aircraftData);
				aircraftData.type = getAircraftType(aircraftData.type);
			}

			if (pilotUser != null) {
				const foundPerson = personnel.find(el => el.data.userId == pilotUser);
				if (foundPerson != null) {
					operator = foundPerson.data;
				}
			}
			
			const aircraft = new Aircraft(
				operator,
				aircraftData,
				aircraftId, 
				aircraftName, 
				initialConnectionType, 
				c2ServiceConfig, 
				connectingUser, 
				'mavlink', 
				{
					onConnected: (aircraft) => { 
						// if (navigate) {
							resolve();
							setSelectedAircraft(aircraft); 
							// navigate('/cockpit-v2');
						// }
					},
					onDisconnected: (aircraft, temporary) => {
						if (!temporary) {
							removeAircraft(aircraft);
						}
					},
					onMissionStarted: async (flightRegistrationObj) => {
						// console.log(flightRegistrationObj);
						// return registerFlight(flightRegistrationObj);
					}
				},
				port,
				config,
			);

			if (initialMission != null) {
				aircraft.loadMissionPlan(initialMission)
			}

			const selectedColor = availableAircraftColors.current[0];
			availableAircraftColors.current = availableAircraftColors.current.slice(1);
			aircraft.color = selectedColor;

			Cookies.set('connected-aircraft', JSON.stringify(localAircraftList.current.map(ac => ({
				aircraftId: ac.aircraftId,
				aircraftName: ac.aircraftName, 
				initialConnectionType: ac.initialConnectionType,
				regNum: ac.aircraftData.registrationNumber,
			}))));

			setSelectedAircraft(aircraft);
			if (autoconnect) {
				aircraft.connect()
					.then(() => {
						// console.log('Connected');
						// localAircraftList.current = [...localAircraftList.current, aircraft];
						// console.log(localAircraftList.current);
						
						// setConnectedAircraft([...localAircraftList.current]);
						// resolve()
						localAircraftList.current = [...localAircraftList.current, aircraft];
						setConnectedAircraft([...localAircraftList.current]);
					})
					.catch((err) => reject(err));
				} else {
					localAircraftList.current = [...localAircraftList.current, aircraft];
					setConnectedAircraft([...localAircraftList.current]);
				}
		});	
	}

	const removeAircraft = (aircraft) => {
		console.log({...aircraft});
		availableAircraftColors.current = [aircraft.color, ...availableAircraftColors.current];
		setSelectedAircraft(localAircraftList.current.find(ac => ac.aircraftId != aircraft.aircraftId));
		setConnectedAircraft(localAircraftList.current.filter(ac => ac.aircraftId != aircraft.aircraftId));
		localAircraftList.current = localAircraftList.current.filter(ac => ac.aircraftId != aircraft.aircraftId);
		Cookies.set('connected-aircraft', JSON.stringify(localAircraftList.current.map(ac => ({
			aircraftId: ac.aircraftId,
			aircraftName: ac.aircraftName, 
			initialConnectionType: ac.initialConnectionType,
			regNum: ac.aircraftData.registrationNumber,
		}))));	
	}

	useEffect(() => {
		if (cookiesData.current == null && pilotUser != null && personnel != null && personnel.length > 0 && aircraftTypes != null && aircraftTypes.length > 0) {
			const prevConnected = Cookies.get('connected-aircraft');
			cookiesData.current = prevConnected;
			if (prevConnected != null) {
				const parsed = JSON.parse(prevConnected);

				if (parsed.length > 0) {
					for (const { aircraftId, aircraftName, initialConnectionType } of parsed) {
						connectToAircraft(aircraftId, aircraftName, initialConnectionType);
					}
				}
			}
		}
	}, [pilotUser, personnel, aircraftTypes]);

	return (
		<AircraftContext.Provider value={{
				connectedAircraft,
				selectedAircraft,
				setSelectedAircraft,
				connectToAircraft,
			}}
		>
			{ children }
		</AircraftContext.Provider>
	)
}

export default AircraftContextProvider;

import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { MissionContext } from '../../../../contexts/mission-context';
import { OutlineButton } from '../../../../shared-components/buttons/outline-button/outline-button';
import { InputField } from '../../../../shared-components/inputs/input-field/input-field';
import { Stack } from '../../../../shared-components/layout/stack/stack';
import { UnitSelectorPanel } from '../unit-selector-panel/unit-selector-panel';
import { MissionItem } from './components/mission-item-v2/mission-item-v2';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { MissionPlanTabs } from '../mission-plan-tabs/mission-plan-tabs';
import UtilityService from '../../../../helpers/utility-service';
import {EditData} from "../../../../shared-components/inputs/edit-data/edit-data";
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Accordion } from '@mui/material';
import { Panel } from '../../../../shared-components/layout/panel/panel';
import { WaypointsLibrary } from './components/waypoints-library/waypoints-library';
import { WaypointsLibraryContext } from '../../../../contexts/waypoints-library-context';


export const WaypointPanel = ({ activeTab }) => {
    const { 
		currentPath: { 
			waypoints,
            isPrimary,
		}, 
        moveWaypoint,
		currentPath,
		flightPaths,
		selectFlightPath,
		copyFlightPath,
		deleteFlightPath,
		setPrimaryPath,
		clearCurrentPath,
        updateFlightPathInfo,
        uploadMissionToAircraft,
	} = useContext(MissionContext);

    const [isEditPathTitle, setIsEditPathTitle] = useState(false);
	// const pathIndex = flightPaths.findIndex(fp => fp.id == currentPath.id);
    const [pathIndex, setPathIndex] = useState(flightPaths.findIndex(fp => fp.title == currentPath.title));
	const navigate = useNavigate();
    const [openWP, setOpenWP] = useState(false);
    const [openStatusPathTitle, setOpenStatusPathTitle] = useState(false);
    const waypointsTotalDistance =  waypoints.length < 2 ? 0 : waypoints.reduce((prev, wp, index) => index == 0 ? prev : prev + UtilityService.distance(waypoints[index - 1], wp), 0)
    const {fetchAllFolders, allFolders, libraryVisible, setLibraryVisible} = useContext(WaypointsLibraryContext);

    useEffect(() => {
        setPathIndex(flightPaths.findIndex(fp => fp.title == currentPath.title));
    }, [currentPath])

    const onOpenLibrary = () => {
        setLibraryVisible(!libraryVisible);
        
    }

    return (
        <div className='waypoints-panel'>
            <Panel  
                title="Path"
                backgroundColor="#060d1f"
                expanded={false}
                className='path-title-panel'
                onClick={()=>{}}
                 onChange = {(e,expanded) => {
                    console.log("Step1",openStatusPathTitle);
                    if(expanded){
                        setOpenStatusPathTitle(false);
                    }else {
                        setOpenStatusPathTitle(true);
                    }
                    console.log("Step2",openStatusPathTitle);
                }}
               >
            <Stack 
                direction="horizontal"
                halign='space-between' 
                valign='start'
            >	        
			    <p className='uppercase section-title bold title-color path-title'> Path Title </p>
				{/* <LabelIconButton title='Primary path' className={`primary-path-button ${currentPath.isPrimary && 'active'}`} icon={<Check/> } onClick={()=>{ if(!currentPath.isPrimary) { setPrimaryPath() }}}/> */}
			</Stack>
            <Stack direction='horizontal' halign='space-between' valign='space-between'  >
            {
                isEditPathTitle ?
                    <InputField 
                        defaultValue={currentPath.title} 
                        type="text" 
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') { 
                                updateFlightPathInfo({ title: e.target.value.trim()})
                                setIsEditPathTitle(false);
                            }
                        }}
                    />
                    :
                <select 
                    key={`select-path-${currentPath.title}`}
                    value={pathIndex}
                    onChange={(e) => {selectFlightPath(parseInt(e.target.value)); setPathIndex(parseInt(e.target.value))}}
                    className="waypoints-select-path-title needsclick"
                >
                    {
                        flightPaths.map((path, index) => (
                            <option key={`path-${path.title}`} value={index}>{ path.title }{path.isPrimary ? '*' : ''}</option>
                        ))
                    }
                </select>
            }
            <OutlineButton title='Primary' onClick={() =>{ setPrimaryPath();}} className='primary' color={isPrimary ? 'green' : 'gray'}/>
            </Stack>
            <Stack className='mission-plan-panel-button-wrap' direction='horizontal' halign='space-between' valign='space-between' onClick={(e)=>e.stopPropagation()}>
                <OutlineButton title='Rename' onClick={() => setIsEditPathTitle(!isEditPathTitle)}/>
                <OutlineButton title='Clone' onClick={() => copyFlightPath()}/>
                <OutlineButton title='Delete' color="red" onClick={() => deleteFlightPath(pathIndex)}/>
            </Stack>
            </Panel>
            <Stack className='mission-plan-panel-button-title-wrap' direction='horizontal' halign='space-between' valign='center' >
                <p className='title-color section-title uppercase bold'>Waypoints</p>
                <Stack  direction='horizontal' halign='space-between' valign='center' > 
                    {waypoints.length > 0 && <OutlineButton className='clear-button' title='Clear' onClick={() => clearCurrentPath()} color="red"/>}

                    <OutlineButton title='Library' onClick={() =>{onOpenLibrary()}} color="#EBE427"/>
                </Stack>
                {libraryVisible && <WaypointsLibrary folders={allFolders}/>}
            </Stack>
            {/* {openLibrary && <WaypointsLibrary/>} */}
            <div className={`mission-plan-wps-wrap ${openStatusPathTitle && 'shorter'}`}>
                <DragDropContext onDragEnd={(result) => moveWaypoint(result.source.index, result.destination.index)}>
                    <Droppable droppableId="waypoints">
                        {(provided) => (
                            <ul className="waypoints-list" {...provided.droppableProps} ref={provided.innerRef}>                    
                                <PerfectScrollbar>    
                                    {
                                        waypoints.length == 0 ?
                                        <p className="no-waypoints-message">Use the tools in the left sidebar to add waypoints</p>
                                        :
                                        <div className="mission-items-wrap">
                                            { waypoints.map((wp, index) => index === 0 ? (
                                                <Draggable key={wp.id} draggableId={wp.id} index={index}>
                                                    {(provided) => (
                                                    <li ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                        <MissionItem
                                                            key={`${wp.id}-${wp.index}-${wp.latitude}-${wp.longitude}-${wp.altitude}-${wp.alp}-${wp.agl}-${wp.amsl}-${wp.speed}`}
                                                            missionItem={wp}
                                                            index={index}
                                                        />
                                                    </li>)}
                                                </Draggable>
                                            ):(
                                                <Draggable key={wp.id} draggableId={wp.id} index={index}>
                                                    {(provided) => (
                                                    <li ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                        <MissionItem
                                                            key={`${wp.id}-${wp.index}-${wp.latitude}-${wp.longitude}-${wp.altitude}-${wp.alp}-${wp.agl}-${wp.amsl}-${wp.speed}`}
                                                            missionItem={wp}
                                                            heading={UtilityService.calcBearing(waypoints[index - 1], wp)} 
                                                            distance={UtilityService.distance(waypoints[index - 1], wp)} 
                                                            index={index} 
                                                        />
                                                    </li> )}
                                                </Draggable>
                                                ))
                                            }
                                        </div>
                                    }
                                </PerfectScrollbar>
                            </ul>
                        )}
                    </Droppable>
                </DragDropContext>
            </div>
            <div className='mission-plan-distance-button-wrap'>
                <p className='uppercase bold'>Total ground distance <span className='total-distance'>{
                    waypointsTotalDistance < 1000 ? `${waypointsTotalDistance} m` :
                    `${UtilityService.distanceToKilometers(waypointsTotalDistance)} km`}</span></p>
                <OutlineButton onClick={() => uploadMissionToAircraft(navigate)} title='Upload to aircraft' size="large"/>
            </div> 
        </div>
    )
}

import React, { useContext, useRef, useState, useEffect } from 'react';
import { InputField } from '../../../../../../shared-components/inputs/input-field/input-field';
import { Stack } from '../../../../../../shared-components/layout/stack/stack';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Add, BatteryStdOutlined, CheckBox, Close, HomeRepairService, Inventory } from '@mui/icons-material';
import { SelectInput } from '../../../../../../shared-components/inputs/select-input/select-input';
import { FleetManagementContext } from '../../../../../../contexts/fleet-management-context';
import { FlightContext } from '../../../../../../contexts/flight-context';
import { Box, Chip,InputLabel,ListSubheader, MenuItem, OutlinedInput } from '@mui/material';
import Select from '@mui/material/Select';
import { MultiSelect } from "react-multi-select-component";

const ITEM_HEIGHT = 28;
const ITEM_PADDING_TOP = 3;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 200,
            color: "#FFFFFF",
        },
    },
};

export const AircraftSetup = () => {
    const {batterySets, equipment, allAircraft} = useContext(FleetManagementContext);
    const [selectedEquipment, setSelectedEquipment] = useState([]);
    const [equipmentItem, setEquipmentItem] = useState([]);
    const [payload, setPayload] = useState([]);
    const selectedAircraft = useRef(allAircraft ? allAircraft[0] : null);
    const [currentAircraft, setCurrentAircraft] = useState(allAircraft[0] ? allAircraft[0] : null);
    let payloadItem = "";
    const { scheduleFlightData } = useContext(FlightContext);

    useEffect(() => {
        if (allAircraft.length > 0 && scheduleFlightData.current.aircraftId == null) {
            scheduleFlightData.current.aircraftId = allAircraft[0].id;
            scheduleFlightData.current.payload = [];
            scheduleFlightData.current.equipment = [];
        }
    }, []);

    const changeEquipment = (equip) => {
        scheduleFlightData.current.equipment = [...selectedEquipment, equip]; 
        setEquipmentItem(equip);
        setSelectedEquipment([...selectedEquipment, equip]);
        
        console.log(scheduleFlightData.current);
    }
    const removeEquipment = (i) => {
        
        setSelectedEquipment(selectedEquipment.filter((item, index) => index !== i));
        scheduleFlightData.current.equipment = selectedEquipment; 
    }

    const changePayload = (load) => {
        setPayload(load);
        scheduleFlightData.current.payload = load;
    }
    
    const changeAircraft = (craft) => {
        
        setCurrentAircraft(allAircraft.find(ac => ac.id === craft)); 
        selectedAircraft.current = craft; 
        scheduleFlightData.current.aircraft = craft;
        scheduleFlightData.current.aircraftId = craft; //added
        console.log(scheduleFlightData.current);
    }
    return(
        <div className='schedule-flight-column-body'>
             <Stack className='schedule-flight-column-body-section' direction='vertical' halign='start' valign='start'>
                <SelectInput 
                    value={scheduleFlightData.current.aircraftId}
                    options={allAircraft ? allAircraft.map(ac => ({ value: ac.id, name: ac.data.name })) : []} 
                    label='Select Aircraft' 
                    onChange={(e)=> changeAircraft(e.target.value) } 
                    
                    />
                { currentAircraft && 
                    <div className='schedule-flight-aircraft-image-wrap'>
                        <img src={currentAircraft.data.image} alt={currentAircraft.data.regnum}/>
                    </div>
                }
            </Stack>
            <Stack className='schedule-flight-column-body-section' direction='vertical' halign='start' valign='start'>
            <SelectInput 
                // value={selectedBatterySet.current.data.name || ""}
                options={batterySets ? batterySets.map(ac => ({ value: ac.id, name: ac.data.name })) : []} 
                label='Select Battery Set' 
                onChange={(e)=> {scheduleFlightData.current.batterySet = e.target.value;}}
                icon={<BatteryStdOutlined/>}
                />
            <Stack 
                direction={'horizontal'} 
                halign={'center'}
                valign={'end'}
                >
                <SelectInput 
                    options={equipment ? equipment.map(ac => ({ value: ac.id, name: ac.data.name })) : []} 
                    label='Equipment' 
                    onChange={(e)=>setEquipmentItem(equipment.filter(item => item.id === e.target.value))} 
                    icon={<HomeRepairService/>}
                    />
                <Add className='add-equipment' onClick={() => equipmentItem != null && changeEquipment(equipmentItem)}/>
            </Stack>
            <div className='schedule-flight-payload-content'>
                {/* <PerfectScrollbar> */}
               {selectedEquipment && selectedEquipment.length > 0 && 
                //.filter(el => selectedEquipment.map(eq => eq.value).includes(el.id))
                
                selectedEquipment.map((p, index) => (
                    <Stack 
                        direction={'horizontal'} 
                        halign={'space-between'}
                        valign={'center'}
                        className='schedule-flight-p' 
                        key={`selected-equipment-${index}`}
                        
                        >
                            {console.log('p', p)}
                            <p>{p[0].data.name}</p>
                            <div
                            
                            onClick={() => removeEquipment(index)}>
                                <Close/>
                            </div>
                    </Stack>
                ))}
                {/* </PerfectScrollbar> */}
            </div>
        </Stack>
       
        <Stack className='schedule-flight-column-body-section' direction='vertical' halign='start' valign='start'>
            <Stack 
                direction={'horizontal'} 
                halign={'center'}
                valign={'end'}
                >
                <InputField 
                    label='Payload' 
                    key={Math.random()}
                    onChange={(e)=> payloadItem = e.target.value } 
                    labelIcon={<Inventory/>}
                    />
                <Add className='add-payload' onClick={() => payloadItem != null && payloadItem.trim().length > 0 && changePayload([...payload, payloadItem])}/>
            </Stack>
            <div className='schedule-flight-payload-content'>
                {/* <PerfectScrollbar> */}
               {payload && payload.length > 0   && 
                //.filter(el => selectedEquipment.map(eq => eq.value).includes(el.id))
                
                payload.map((p, index) => (
                    <Stack 
                        direction={'horizontal'} 
                        halign={'space-between'}
                        valign={'center'}
                        className='schedule-flight-p' 
                        key={`selected-equipment-${index}`}
                        
                        >
                            {console.log('p', p)}
                            <p>{p}</p>
                            <div key={`schedule-flight-delete-${index}`} 
                                onClick={() => changePayload(payload.filter((item,itemIndex)=> itemIndex !== index))}><Close/>
                            </div>
                            {/* <div
                            
                            onClick={() => removeEquipment(index)}>
                                <Close/>
                            </div> */}
                    </Stack>
                ))}
                {/* </PerfectScrollbar> */}
            </div>
        </Stack>
    </div>
    )
}
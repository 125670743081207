

import React from 'react';
import { OutlineButton } from '../../../../../../shared-components/buttons/outline-button/outline-button';
import { Button } from '@mui/material';
import { SignalCellular0Bar, SignalCellular0BarOutlined, SignalCellular1Bar, SignalCellular2Bar, SignalCellular3Bar, SignalCellular4Bar } from '@mui/icons-material';


export const StatusBoard = ({
    selectedAircraft,
    connectionType, 
    signalStrength,
    status, 
    sysState, 
    destinationETA, 
    destinationDist,
    pathWPnum,
    pathLength,
    flightMode, 
    missionPlan,
    flightPath,
    className }) => {

       

        return(
            <section className={`status-board-wrap ${className ? className : ("")}`}>
                 {/* <div className='status-board-item button' > */}
                    {/* <p className='status-board-title uppercase'>Clear path</p> */}
                    {/* <OutlineButton title="Clear path"  onClick={()=>selectedAircraft.clearPath()} /> */}
                {/* </div> */}
                <div className='status-board-items-wrap'>
                    <div className='status-board-item'>
                        <p className='status-board-title uppercase'>Conn Type</p>
                        <p className='status-board-value uppercase rowed big'>{connectionType ? connectionType : "N/A"}
                            {signalStrength === "N/A" && <SignalCellular0BarOutlined className='signal-icon salmon'/>}
                            {signalStrength === 0 && <SignalCellular0Bar className='signal-icon red'/>}
                            {signalStrength === 1 && <SignalCellular1Bar className='signal-icon red'/>}
                            {signalStrength === 2 && <SignalCellular2Bar className='signal-icon orange'/>}
                            {signalStrength === 3 && <SignalCellular3Bar className='signal-icon yellow'/>}
                            {signalStrength === 4 && <SignalCellular4Bar className='signal-icon green'/>}
                            {signalStrength === 5 && <SignalCellular4Bar className='signal-icon green'/>}
                            {signalStrength}
                        
                        </p>
                    </div>
                    {/* <div className='status-board-item'>
                        <p className='status-board-title uppercase'>Conn Strength</p>
                        <p className='status-board-value rowed uppercase big'>
                            {signalStrength === "N/A" && <SignalCellular0BarOutlined className='signal-icon salmon'/>}
                            {signalStrength === 0 && <SignalCellular0Bar className='signal-icon red'/>}
                            {signalStrength === 1 && <SignalCellular1Bar className='signal-icon red'/>}
                            {signalStrength === 2 && <SignalCellular2Bar className='signal-icon orange'/>}
                            {signalStrength === 3 && <SignalCellular3Bar className='signal-icon yellow'/>}
                            {signalStrength === 4 && <SignalCellular4Bar className='signal-icon green'/>}
                            {signalStrength === 5 && <SignalCellular4Bar className='signal-icon green'/>}
                            {signalStrength}
                        </p>
                    </div> */}
                    <div className='status-board-item'>
                        <p className='status-board-title uppercase'>Status</p>
                        <p className={`status-board-value uppercase big ${status.toLowerCase() == 'armed' ? 'green' : 'red'}`}>{status ? status : "N/A"}</p>
                    </div>
                    <div className='status-board-item'>
                        <p className='status-board-title uppercase'>Sys State</p>
                        <p className='status-board-value uppercase big'>{sysState ? sysState : "N/A"}</p>
                    </div>
                    {/* <div className='status-board-item'>
                        <p className='status-board-title uppercase'>Destination</p>
                        <div className='status-board-items-flexed'>
                            <p className='status-board-value uppercase'>ETA</p>
                            <p className='status-board-value uppercase'>{destinationETA ? destinationETA : "N/A"}</p>
                        </div>
                        <div className='status-board-items-flexed'>
                            <p className='status-board-value uppercase'>Dist</p>
                            <p className='status-board-value uppercase'>{destinationDist ? destinationDist : "N/A"}<span>m</span></p>
                        </div>
                    </div> */}
                    <div className='status-board-item'>
                        <p className='status-board-title uppercase'>Flight Mode</p>
                        <p className='status-board-value uppercase big'>{flightMode ? flightMode : "N/A"}</p>
                    </div>
                </div>
                <OutlineButton title="Clear path"  onClick={()=>selectedAircraft.clearPath()} />

                {/* <div className='status-board-item'>
                    <p className='status-board-title uppercase'>Mission</p>
                    <p className='status-board-value uppercase big'>{missionPlan ? missionPlan : "N/A"}</p>
                </div>

                <div className='status-board-item'>
                    <p className='status-board-title uppercase'>Path</p>
                    <p className='status-board-value uppercase big'>{flightPath ? flightPath : "N/A"}</p>
                </div>
                */}
                
                {/* <div className='status-board-item'>
                    <p className='status-board-title uppercase'>Path</p>
                    <div className='status-board-items-flexed'>
                        <p className='status-board-value uppercase'>Num WP</p>
                        <p className='status-board-value uppercase value'>{pathWPnum ? pathWPnum : "N/A"}</p>
                    </div>
                    <div className='status-board-items-flexed'>
                        <p className='status-board-value uppercase'>Length</p>
                        <p className='status-board-value uppercase value'>{pathLength ? pathLength : "N/A"}<span>m</span></p>
                    </div>
                </div> */}
                {/* {boardItems.map((index, item)=>(
                    <div className='status-board-item' key={`status-item-${index}`}>
                        {index.title}
                        {index.subitems ? (

                        ): ()}
                    </div>
                ))} */}
            </section>
        );
}


import { ExpandMore } from "@mui/icons-material"
import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material"
import { useState } from "react"
import useWindowDimensions from "../../../hooks/useWindowDimensions";

export const Panel = ({ icon, title, children, className, backgroundColor, expanded, onClick=()=>{}, onChange }) =>{
    const [expandPanel, setExpandPanel] = useState(expanded);
    
    const { width } = useWindowDimensions();
    const [fullWidth, setFullWidth] = useState(width > 700);
    const onAccordionChange = (e) => {
        setExpandPanel(!expandPanel);
        {onChange && onChange(e, expandPanel)}
    }
    return (
        <div className='panel'  onClick={() => onClick && onClick()}>
            <Accordion className={className && className} sx={{ overflow: 'hidden' }} expanded={expandPanel} onChange={() => onAccordionChange()}>
                <AccordionSummary
                    sx={{
                        backgroundColor,
                    }}
                    expandIcon={<ExpandMore className="toggle-icon" />}
                    aria-controls={`panel-header-${title}`}
                    id={`panel-header-${title}`}
                    
                >
                    <div className="panel-header-wrap">
                        <span className="panel-icon">{ icon }</span>
                        <span className="panel-title">{ title }</span>
                    </div>
                </AccordionSummary>

                <AccordionDetails
                    sx={{
                        backgroundColor,
                        color: 'white',
                        padding: '10px',
                    }}
                >
                    { children }    
                </AccordionDetails>
            </Accordion>
        </div>
    )
}
// import fleetManagementService from "../../../services/fleet-management-service";

export const maintenanceSchema = {
	loadSingleItem: (fleetManagementContext, id) => fleetManagementContext.getSingleMaintenance(id),
	updateSingleItem: async (fleetManagementContext, id, data) => await fleetManagementContext.updateMaintenance(id, data),
	removeSingleItem: async (fleetManagementContext, id) => await fleetManagementContext.removeMaintenance(id),
	createSingleItem: async (fleetManagementContext, data) => await fleetManagementContext.createMaintenance(data),

	entityName: 'Maintenance',
	//part, partID, last overhauled, certifiedBy date, time in op, time remaining, description
	attributes: [
		{   
				key: 'id',
				path: 'id',
				name: 'ID',
				type: 'text',
				hidden: true,
				disabled: true,
				autogenerated: true,
		},
		{
				key: 'type',
				path: 'data.type',
				name: 'Type',
				type: 'text',
		},
		{
			key: 'aircraftId',
			path: 'data.aircraftId',
			displayValuePath: 'data.aircraft.data.name',
			name: 'Aircraft',
			type: 'remote-select',
			options: {
				loadData: (fleetManagementContext) => fleetManagementContext.fetchAllAircraft(),
				keyPath: 'id',
				labelPath: 'data.name',
			},
		},
		{
			key: 'category',
			path: 'data.category',
			name: 'Category',
			type: 'text',
		},
		{
			key: 'partID',
			path: 'data.partID',
			name: 'Part ID',
			type: 'text',
		},
		// {
		// 	key: 'requestReason',
		// 	path: 'data.requestReason',
		// 	name: 'Reason',
		// 	type: 'text',
		// },
		{
			key: 'safeToUse',
			path: 'data.safeToUse',
			name: 'Vehicle safe to use',
			type: 'text',
		},
		{
			key: 'lastOverhauled',
			path: 'data.lastOverhauled',
			name: 'Last Overhauled',
			type: 'text',
		},
		{
			key: 'certifiedBy',
			path: 'data.certifiedBy',
			name: 'Certified By',
			type: 'text',
		},
		{
			key: 'certificationDate',
			path: 'data.certificationDate',
			name: 'Certification Date',
			type: 'text',
		},
		{
			key: 'timeInOp',
			path: 'data.timeInOp',
			name: 'Time In Op',
			type: 'text',
		},
		{
			key: 'timeRemaining',
			path: 'data.timeRemaining',
			name: 'Time Remaining',
			type: 'text',
		},
		{
			key: 'description',
			path: 'data.description',
			name: 'Description',
			type: 'text',
		},


		// {
		// 	key: 'role',
		// 	path: 'data.role',
		// 	name: 'Role',
		// 	type: 'text',
		// },
	]
};
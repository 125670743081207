import { ArrowBackIos, ArrowForwardIos, ArrowLeftOutlined, Navigation, Pause, PlayArrow } from '@mui/icons-material';
import React, { useEffect, useRef, useState } from 'react';
import HeadingDial from '../../assets/cockpit-icons/heading-dial.svg'
import HeadingMarkers from '../../assets/cockpit-icons/heading-markers.svg';
import AircraftIcon from '../../assets/map-icons/aircraft-marker-icon.svg';
import { AltitudeSpeedSetter } from '../../screens/cockpit-screen/components/altitude-speed-setter/altitude-speed-setter';
import {InputFieldV2} from '../../shared-components/inputs/input-field-v2/input-field-v2';
import { EditData } from '../inputs/edit-data/edit-data';

export const HeadingCompass = ({aircraft, className}) => {
    const currentAircraft = useRef(null);
    const currentSubscriptions = useRef({ hudState: null, generalState: null });
    const [hdgValue, setHdgValue] = useState(0);
    const [flightMode, setFlightMode] = useState(null);
    const [inputHeadingActive, setInputHeadingActive] = useState(true);
    const hdgUpdateInterval = useRef(null);
    const lastDirection = useRef(0);
    const [targetPositionAvailable, setTargetPositionAvailable] = useState(true);
    const [instrumentsActive, setInstrumentsActive] = useState(false);

    const lastHeading = useRef(0);
    const lastSpeed = useRef(0);
    const lastAltitude = useRef(0);
    const [currentAltitude, setCurrentAltitude] = useState(0);

    const setSpeed = (speed) => {
        lastSpeed.current = speed;
        if (instrumentsActive) {
            aircraft.moveForward(speed);
        }
    }

    const setAltitude = (altitude) => {
        lastAltitude.current = altitude;
        if (instrumentsActive) {
            aircraft.setAltitude(altitude);
        }
    }

    const setHeading = (value) => {
        // setInputHeadingActive(false); 
        rotateRef.current = value%360 < 0 ? 360 + value%360 : value%360;
        if (instrumentsActive) {
            aircraft.setYaw(rotateRef.current)
        }
        setRotate(rotateRef.current);
    }

    useEffect(() => {
        if (instrumentsActive) {
            setSpeed(lastSpeed.current);
            setAltitude(lastAltitude.current);
            setHeading(rotateRef.current);
        }
    }, [instrumentsActive])

    useEffect(() => {
        if (aircraft != null) {
            if (currentAircraft.current != aircraft) {
                currentAircraft.current = aircraft;

                if (currentSubscriptions.current.hudState != null) {
                    currentSubscriptions.current.hudState(); // Unsubscribe
                }
                if (currentSubscriptions.current.generalState != null) {
                    currentSubscriptions.current.generalState(); // Unsubscribe
                }

                const { customMode } = aircraft.generalState.getState();

                const { altitude } = aircraft.hudState.getState();
                setCurrentAltitude(altitude);

                setFlightMode(customMode);
                console.log("TP",aircraft.positionState.getState().targetPosition);
                currentSubscriptions.current.hudState = aircraft.hudState.subscribe(() => {
                    const { heading } = aircraft.hudState.getState();
                    setHdgValue(heading);
                })

                currentSubscriptions.current.generalState = aircraft.generalState.subscribe(() => {
                    const { customMode } = aircraft.generalState.getState();
                    setFlightMode(customMode);
                })
            }
        }
    }, [aircraft]);

    const [leftPressed, setLeftPressed] = useState(false);
    const [rightPressed, setRightPressed] = useState(false);
    // const [position, setPosition] = useState(hdgValue || 0);
    const [rotate, setRotate] = useState(0);
    const targetInterval = useRef(null);
    const rotateRef = useRef(rotate || 0);
    const handleMouseDown = (side) => {
        // aircraft.setYaw(250);
        if(targetInterval.current != null) {
            clearInterval(targetInterval.current);
        }

        if(side == 'left') {
            
            lastDirection.current = -1;
            setLeftPressed(true); 
            handleWheelLeft();
            targetInterval.current = setInterval(() => handleWheelLeft(), 200)
        } else {
            lastDirection.current = 1;
            setRightPressed(true); 
            handleWheelRight();
            targetInterval.current = setInterval(() => handleWheelRight(), 200)
        }

        if (hdgUpdateInterval.current != null) {
            clearInterval(hdgUpdateInterval.current);
        }

        hdgUpdateInterval.current = setInterval(() => {
            if(instrumentsActive) {
                aircraft.setYaw(rotateRef.current, lastDirection.current)
            }
        }, 500);
    }
    const handleMouseUp = (side) => {
        if (side == 'left') {
            setLeftPressed(false);  
        } else {
            setRightPressed(false);  
        }

        if (hdgUpdateInterval.current != null) {
            clearInterval(hdgUpdateInterval.current);
        }

        if(instrumentsActive) {
            aircraft.setYaw(rotateRef.current, lastDirection.current)        
        }
       
        clearInterval(targetInterval.current)
     }
    const handleWheelLeft = () => {
        rotateRef.current -= 1;
        setRotate(rotateRef.current);
    }
    const handleWheelRight = () => {
        rotateRef.current += 1;
        setRotate(rotateRef.current);
    }
    useEffect(() => {
        
    }, [rotate]);
    
    return (
        <div className={`heading-compass ${className && className}`}>
            <div className='compass-wrap'>
                <div className='compass-wheel'>
                    <img className='heading-dial' src={HeadingDial}/>
                    <img className='heading-markers' src={HeadingMarkers}/>
                    {
                        flightMode == 'GUIDED' && <div className='pointing-arrow' style={{transform: `rotate(${rotate}deg)`}}>
                            <Navigation className='navigation'/>
                    </div>
                    }
                    <div className='heading-aircraft'  style={{transform: `rotate(${hdgValue}deg)`}}>
                        <img src={AircraftIcon} />
                    </div>
                    {/* <div className='compass-inner-circle'>
                       
                       
                        <div className='heading-value'>
                            <span className='heading-value-label'>{hdgValue}</span>
                        </div>
                        <div className='heading-target-wrap'>
                            <span className='target-label'>Target:</span>
                            <span className='target-value'>{rotate%360 < 0 ? 360 + rotate%360: rotate%360}</span>
                        </div>
                    </div> */}
                </div>
            </div>
            {
                flightMode == 'GUIDED' &&
            <div className='alt-spd-hdg-wrap'>

                <AltitudeSpeedSetter 
                    type='alt' 
                    onUpdate={(x) => setAltitude(x)} 
                    // disabled={aircraft.positionState.getState().targetPosition === null || !instrumentsActive}
                />
            
                <div className='compass-instruments'>
                    <div className='activate-instruments'>
                        <span>ISR</span>
                        <div className={`irs-toggle-wrap pointer `} onClick={() => setInstrumentsActive(!instrumentsActive)}>
                            <div className={`isr-toggle-switch-off ${instrumentsActive && 'active'}`}>
                            </div>
                            <div className={`isr-toggle-switch-on ${instrumentsActive && 'active'}`}>
                            </div>
                        </div>
                    </div>
                    <div className='heading-value'>
                            <span>HDG:</span>
                            <span className='heading-value-label'>{`${hdgValue}`.padStart(3, "0")}</span>
                    </div>
                  
                   
                    
                    <div className='compass-turn-buttons'>
                        <div className={`compass-direction left ${leftPressed && 'active'}`}
                            onMouseDown={() => {handleMouseDown('left')}}
                            onMouseUp={() =>{handleMouseUp('left')}}
                        
                            >
                                
                            <ArrowBackIos/>
                        </div>
                        <div className='heading-input-wrap'>
                            
                            
                                <EditData 
                                    type='integer'
                                    maxLength={3}
                                    pad={[3, '0']}
                                    value={rotate%360 < 0 ? 360 + rotate%360: rotate%360} 
                                    onSubmit={(value) => setHeading(value)}
                                    /> 
                                    {/* : 
                                <span className='current-active-heading-value'>{rotate%360 < 0 ? 360 + `${rotate%360}`.padStart(3, "0"): `${rotate%360}`.padStart(3, "0")}</span>} */}

                        </div>
                        <div className={`compass-direction right ${rightPressed && 'active'}`}
                            onMouseDown={() => {handleMouseDown("right")}}
                            onMouseUp={() => {handleMouseUp("right")}}>
                            
                            <ArrowForwardIos/>
                        </div>
                        
                    </div>
                     
                </div>
                <AltitudeSpeedSetter 
                    type='spd' 
                    onUpdate={(x) => setSpeed(x)} 
                    // disabled={!instrumentsActive} 
                />
            </div>
        }
        </div>
    )
}
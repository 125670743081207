import React, { useState } from "react";
import {DebounceInput} from 'react-debounce-input';

export function InputFieldV2({ 
	onIconClick,
	debounce,
	debounceTimeout,
	type,
	onKeyDown,
	onKeyUp,
	onChange,
	onMouseEnter,
	onMouseLeave,
	onFocus,
	onBlur,
	onSubmit,
	disabled,
	defaultValue,
	value,
	defaultChecked,
	wrapperRef,
	icon,
	step,
	min,
	max,
	pattern,
	placeholder,
	label,
	labelIcon,
	width,
	height,
	padding,
	labelPosition,
	labelUppercase,
	labelFontSize,
	labelColor,
	labelFontFamily,
	textUpercase,
	textSize,
	textColor,
	backgroundColor,
	backgroundColorDisabled,
	backgroundColorHover,
	backgroundColorFocused,
	className,
	style: customStyle,
	autofocus,
	unit,
	maxLength
}) {
	const [hover, setHover] = useState(false);
	const [focused, setFocused] = useState(false);
	const key = `input-${(type || 'text').toLowerCase()}-${(placeholder || '').toLowerCase()}-${(label || '').trim().toLowerCase().replace(' ','-')}-${defaultValue || 'nodefault'}`;
	const [inputValue, setInputValue] = useState('');
	return (
		<div
			ref={wrapperRef}
			className={`input-field-v2 ${className || ''}`}
			style={{
				...(customStyle && customStyle),
				...(labelPosition && 
					labelPosition === 'left' ?
					{
						flexDirection: 'row',
						alignItems: 'center',
					}:
					{
						flexDirection: 'column',
						alignItems: 'flex-start',
					})
			}}
		>
			{
				label &&
				<label 
					htmlFor={key}
					style={{
						...(labelUppercase && { textTransform: 'uppercase' }),
						...(labelFontSize && { fontSize: labelFontSize }),
						...(labelFontFamily && { fontFamily: labelFontFamily }),
						...(labelColor && { color: labelColor }),
					}}
					className={labelIcon && 'label-icon-wrap'}
				>
					{labelIcon && <span>{labelIcon}</span>}
					{label}
				</label>
			}
			<div 
				className="input-wrap"
				style={{
					...(width && { width }),
					...(height && { height }),
					...(padding && { padding }),
					...(backgroundColor && { 
							backgroundColor: disabled ? 
								backgroundColorDisabled || backgroundColor 
								: focused ?
									backgroundColorFocused || backgroundColorHover || backgroundColor 
									:
									hover ?  
										backgroundColorHover || backgroundColor 
									: backgroundColor }),
					"&:hover": {
						...(backgroundColorHover && { background: backgroundColorHover })
					}
				}}
			>
				{
					icon && 
					<div className="icon-wrap" onClick={()=>onIconClick(document.getElementById(key).value)}>
						{ icon }
					</div>
				}
				{ debounce ? 
					<DebounceInput
						debounceTimeout={debounceTimeout}
						autoFocus={autofocus}
						onMouseEnter={(e) => { if (onMouseEnter) { onMouseEnter(e) } setHover(true)}}
						onMouseLeave={(e) => { if (onMouseLeave) { onMouseLeave(e) } setHover(false)}}
						onFocus={(e) => { if (onFocus) { onFocus(e) } setFocused(true)}}
						onBlur={(e) => { if (onBlur) { onBlur(e) } setFocused(false)}}
						key={key}
						defaultChecked={defaultChecked}
						id={key}
						className="input-element"
						type={type}
						onKeyDown={ (e) => { if (onKeyDown) { onKeyDown(e) } if (onSubmit && e.key === 'Enter') { onSubmit(e) } }}
						onKeyUp={onKeyUp}
						onChange={onChange}
						disabled={disabled}
						defaultValue={defaultValue}
						value={value}
						step={step}
						min={min}
						max={max}
						pattern={pattern}
						placeholder={placeholder}
						maxLength={maxLength}
						style={{
							...(textUpercase && { textTransform: 'uppercase' }),
							...(textSize && { fontSize: textSize }),
							...(textColor && { color: textColor }),
						}}
					/>
					:
					<input 
						autoFocus={autofocus}
						onMouseEnter={(e) => { if (onMouseEnter) { onMouseEnter(e) } setHover(true)}}
						onMouseLeave={(e) => { if (onMouseLeave) { onMouseLeave(e) } setHover(false)}}
						onFocus={(e) => { if (onFocus) { onFocus(e) } setFocused(true)}}
						onBlur={(e) => { if (onBlur) { onFocus(e) } setFocused(false)}}
						key={key}
						defaultChecked={defaultChecked}
						id={key}
						className="input-element"
						type={type}
						onKeyDown={ (e) => { if (onKeyDown) { onKeyDown(e) } if (onSubmit && e.key === 'Enter') { onSubmit(e) } }}
						onKeyUp={onKeyUp}
						onChange={onChange}
						disabled={disabled}
						defaultValue={defaultValue}
						value={value}
						maxLength={maxLength}
						step={step}
						min={min}
						max={max}
						pattern={pattern}
						placeholder={placeholder}
						style={{
							...(textUpercase && { textTransform: 'uppercase' }),
							...(textSize && { fontSize: textSize }),
							...(textColor && { color: textColor }),
						}}
					/>
				}
				{unit && <div className="unit">
					{unit}
				</div>}
			</div>
		</div>
	)
}
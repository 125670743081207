import React from 'react';
import { Box, IconButton, Slider, styled, Typography } from '@mui/material';

import LinearProgress from '@mui/material/LinearProgress';


export const FMFlightlogSlider = () => {
    const marks = [{
        label: "0",
        value: 0,
    },
    {
        label: "100",
        value: 100,
    }]
    function valuetext(value) {
        return `${value}`;
    }
    const TinyText = styled(Typography)({
        fontSize: '0.75rem',
        opacity: 0.38,
        fontWeight: 500,
        letterSpacing: 0.2,
      });
    const duration = 200; // seconds
    const [position, setPosition] = React.useState(32);
    function formatDuration(value) {
        const minute = Math.floor(value / 60);
        const secondLeft = value - minute * 60;
        return `${minute}:${secondLeft < 10 ? `0${secondLeft}` : secondLeft}`;
      }
    return(
        <Box sx={{ width: "100%", color: 'white' }}>
                {/* <Slider
                    aria-label="Custom marks"
                    defaultValue={progress}
                    getAriaValueText={valuetext}
                    step={5}
                    valueLabelDisplay="auto"
                    marks={marks}
                    sx={{color: "white"}}
                    valueLabelDisplay="on"
                /> */}
                  <Slider
                    aria-label="time-indicator"
                    size="small"
                    value={position}
                        min={0}
                        step={1}
                        max={duration}
                        onChange={(_, value) => setPosition(value)}
                        sx={{
                            color:  '#fff',
                            height: 10,
                            '& .MuiSlider-thumb': {
                            width: 20,
                            height: 20,
                            transition: '0.3s cubic-bezier(.47,1.64,.41,.8)',
                            '&:before': {
                                boxShadow: '0 2px 12px 0 #fff',
                            },
                            '&:hover, &.Mui-focusVisible': {
                                boxShadow: `#fff 8px ${
                                '#fff/16%'
                                }`,
                            },
                            '&.Mui-active': {
                                width: 20,
                                height: 20,
                            },
                            },
                            '& .MuiSlider-rail': {
                            opacity: 0.28,
                            },
                        }}
                        />
                      <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            mt: -2,
                        }}
                        >
                        <TinyText>{formatDuration(position)}</TinyText>
                        <TinyText>-{formatDuration(duration - position)}</TinyText>
                    </Box>
                </Box>
    )
}
import MAVLinkConstants from '../../../protocol-services/mavlink-constants';
import { toast } from "react-toastify";

// HEARTBEAT (#0)
export function heartbeat (message, aircraft) {
	const {
		type,      													// MAV_TYPE - Vehicle or component type.
		autopilot, 													// MAV_AUTOPILOT - Autopilot type / class
		base_mode, 													// System mode bitmap.
		custom_mode, 												// A bitfield for use for autopilot-specific flags
		system_status, 											// MAV_STATE - System status flag.
		mavlink_version: newMavlinkVersion, // MAVLink version
	} = message;

	// Current state
	const { 
		autopilotType,
		vehicleType,
		mavlinkVersion,
		systemStatus,
		isArmed,
		isAirborn,
		baseMode,
		customMode,
		flightMode,
	} = aircraft.generalState.getState();

	// console.log(message);

	const { generalStateSetters } = aircraft;

	// Non-flight controller type
	const MAV_AUTOPILOT_INVALID = 8;

	// Ignore heartbeat messages from non-flight controller components
	if (autopilot == MAV_AUTOPILOT_INVALID) {
		return;
	}                          
	
	// Autopilot type 
	const newAutopilotType = MAVLinkConstants.MAV_AUTOPILOT[autopilot];
	if (autopilotType == null || autopilotType.value != newAutopilotType.value) {
		generalStateSetters.setAutopilotType(newAutopilotType);
	}

	// Vehicle type
	const newVehicleType = MAVLinkConstants.MAV_TYPE[type];
	if (vehicleType == null || vehicleType.value != newVehicleType.value) {
		generalStateSetters.setVehicleType(newVehicleType);
	}

	// MAVLink version
	if (mavlinkVersion != newMavlinkVersion) {
		generalStateSetters.setMavlinkVersion(newMavlinkVersion);
	}

	// System status
	const newSystemStatus = MAVLinkConstants.MAV_STATE[system_status];
	if (systemStatus == null || systemStatus.value != newSystemStatus.value) {
		generalStateSetters.setSystemStatus(newSystemStatus);

		if (newSystemStatus.label == 'Active') {
			generalStateSetters.setIsMissionStarted(true);
		}
	}

	// Armed status
	let newArmedStatus = null;
	if (base_mode & MAVLinkConstants.MAV_MODE_FLAG.MAV_MODE_FLAG_SAFETY_ARMED) {
		newArmedStatus = true;
	} else {
		newArmedStatus = false;

		if (isAirborn != false) {
			generalStateSetters.setIsAirborn(false);
		}
	}

	if (isArmed !== newArmedStatus) {
		generalStateSetters.setIsArmed(newArmedStatus);

		toast.info(newArmedStatus ? 'Aircraft armed' : 'Aircraft disarmed', { delay: 100 });
	}

	// Flight mode
	let newBaseMode = null;

	if (base_mode &	MAVLinkConstants.MAV_MODE_FLAG.MAV_MODE_FLAG_MANUAL_INPUT_ENABLED) {
		newBaseMode = 'MANUAL';
	}

	if (base_mode &	MAVLinkConstants.MAV_MODE_FLAG.MAV_MODE_FLAG_HIL_ENABLED) {
		newBaseMode = 'HIL';
	}

	if (base_mode &	MAVLinkConstants.MAV_MODE_FLAG.MAV_MODE_FLAG_STABILIZE_ENABLED) {
		newBaseMode = 'STABILIZE';
	}

	if (base_mode &	MAVLinkConstants.MAV_MODE_FLAG.MAV_MODE_FLAG_GUIDED_ENABLED) {
		newBaseMode = 'GUIDED';
	}

	if (base_mode &	MAVLinkConstants.MAV_MODE_FLAG.MAV_MODE_FLAG_AUTO_ENABLED) {
		newBaseMode = 'AUTO';
	}

	// Base mode
	if (baseMode != newBaseMode) {
		generalStateSetters.setBaseMode(newBaseMode);
	}

	// Custom mode
	const newCustomMode = MAVLinkConstants.CUSTOM_MAV_MODE[custom_mode];
	if (customMode != newCustomMode) {
		generalStateSetters.setCustomMode(newCustomMode);
		toast.info(`${newCustomMode} flight mode`);
	}

	if (newCustomMode != 'AUTO') {
		const { isOrbiting } = aircraft.missionState.getState();
		if (isOrbiting) {
			aircraft.missionStateSetters.setIsOrbiting(false);
		}
	}

	// 	// Disarmed aircraft can't be airborn
	// 	aircraft.vehicleState.airborn = false;
	// }

	// // Armed status 
	// let newArmedStatus = null;
	// if (base_mode & MAVLinkConstants.MAV_MODE_FLAG.MAV_MODE_FLAG_SAFETY_ARMED) {
	// 	newArmedStatus = true;

	// 	if (aircraft.flightId == null && !aircraft.flightStarted && aircraft.vehicleState.missionCount !== null && aircraft.vehicleState.missionRallyCount && aircraft.vehicleState.missionCount == aircraft.vehicleState.missionItems.length && aircraft.vehicleState.missionCount == aircraft.vehicleState.rallypointItems.missionRallyCount) {
	// 		aircraft.flightStarted = true;
	// 		fleetManagementService.fetchAllFlights({ aircraftId: aircraft.aircraftId, status: 'PENDING' }).then(flights => {
	// 			if (flights.length == 0) {
	// 				aircraft.flightStarted = true;
	// 				aircraft.takeOffTime = new Date().toISOString();
	// 				fleetManagementService.createFlight({
	// 					status: 'PENDING',
	// 					equipment: aircraft.equipment,
	// 					payloadWeight: this.payloadWeight,
	// 					pilotId: aircraft.pilotId,
	// 					aircraftId: aircraft.rawId,
	// 					takeOffTime: aircraft.takeOffTime,
	// 					missionPlan: aircraft.vehicleState.missionPlan || 'Unnamed',
	// 					flightPath: aircraft.vehicleState.flightPath || 'Unnamed',
	// 					waypoints: aircraft.vehicleState.missionItems,
	// 					rtl: aircraft.vehicleState.rallypointItems,
	// 				}).then(res => {
	// 					aircraft.flightId = res.data.id;
	// 				});
	// 			} else {
	// 				aircraft.flightId = flights[0].id;
	// 				aircraft.takeOffTime = flights[0].data.takeOffTime;
	// 				aircraft.equipment = flights[0].data.equipment;
	// 				aircraft.equipment =  flights[0].data.payloadWeight;
	// 				aircraft.pilotId = flights[0].pilotId;
	// 				aircraft.vehicleState.missionPlan = flights[0].missionPlan;
	// 				aircraft.vehicleState.flightPath = flights[0].flightPath;
	// 			}
	// 		})
	// 	}

	// } else {
	// 	newArmedStatus = false;

	// 	if (aircraft.flightId != null && aircraft.flightStarted) {
	// 		aircraft.flightStarted = false;
	// 		fleetManagementService.updateFlight(aircraft.flightId, { status: 'COMPLETE', landingTime: new Date().toISOString()}).then(() => aircraft.flightId = null);
	// 	} else if (!aircraft.flightEnded) {
	// 		aircraft.flightEnded = true;
	// 		aircraft.takeOffTime = null;
	// 		fleetManagementService.fetchAllFlights({ aircraftId: aircraft.rawId, status: 'PENDING' }).then(
	// 		flights => {
	// 			if (flights.length != 0) {
	// 				aircraft.flightStarted = true;
	// 				fleetManagementService.updateFlight(flights[0].id, {
	// 					status: 'COMPLETE',
	// 					landingTime: new Date().toISOString(),
	// 				}).then((res) => {
	// 					console.log(res);
	// 					aircraft.flightId = null;
	// 				});
	// 			}
	// 		}).catch(err => console.log(err));
	// 	}

	// 	// Disarmed aircraft can't be airborn
	// 	aircraft.vehicleState.airborn = false;
	// }

	// // Notify armed status change
	// if (aircraft.vehicleState.armed != newArmedStatus) {
	// 	aircraft.vehicleState.armed = newArmedStatus;
	// 	aircraft.vehicleState.airborn = false;
	// 	updateRequired = true;

	// 	if (newArmedStatus == 1) {
	// 		aircraft.addNotification('Armed', 'Aircraft armed');
	// 	} else {
	// 		aircraft.addNotification('Disarmed', 'Aircraft disarmed');
	// 	}

	// 	// Change flight mode to specific mode when disarmed
	// 	// if (newArmedStatus == false) {
	// 	// 	setFlightMode('STABILIZE', false);
	// 	// }
	// }

	

	// 	console.log(`[HEARTBEAT] Current flight mode ${newFlightMode}`);
	// }

	// // Custom flight mode flag
	// const customMode = MAVLinkConstants.CUSTOM_MAV_MODE[custom_mode];

	// // Find the closest RTL point
	// if (customMode == 'RTL') {
	// 	if (aircraft.vehicleState.closestRtl == null) {
	// 		setClosestRTL();
	// 	}
	// }

	// // If switched to AUTO mission is in progress / not paused
	// if (customMode == 'AUTO' && aircraft.vehicleState.missionPaused === null) {
	// 	aircraft.vehicleState.missionStarted = true;
	// 	aircraft.vehicleState.missionPaused = false;
	// }

	// if (aircraft.vehicleState.customFlightMode != customMode) {
	// 	aircraft.vehicleState.customFlightMode = customMode;
	// 	updateRequired = true;

	// 	// Create voice notification based on new flight mode
	// 	voiceService.play(`${customMode.toLowerCase()}-flight-mode`);
	// }

	// aircraft.lastMessageTimestamp = new Date();

	// // logBlackBox(null, 'HEARTBEAT');

	// // Update
	// if (updateRequired) {
	// 	aircraft.notify();
	// }
};
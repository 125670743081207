export const aircraftSchema = {
	entityName: 'Aircraft',
	loadSingleItem: (fleetManagementContext, id) => fleetManagementContext.getSingleAircraft(id),
	updateSingleItem: async (fleetManagementContext, id, data) => await fleetManagementContext.updateAircraft(id, data),
	removeSingleItem: async (fleetManagementContext, id) => await fleetManagementContext.removeAircraft(id),
	createSingleItem: async (fleetManagementContext, data) => await fleetManagementContext.createAircraft(data),

	attributes: [
		{   
			key: 'id',
			path: 'id',
			name: 'ID',
			type: 'text',
			hidden: true,
			disabled: true,
			autogenerated: true,
		},
		{   
			key: 'name',
			path: 'data.name',
			name: 'Name',
			type: 'text',
		},
		{   
			key: 'class',
			path: 'data.class',
			name: 'Class',
			type: 'text',
		},
		// {   
		// 	key: 'type',
		// 	path: 'data.type',
		// 	name: 'Type',
		// 	type: 'text',
		// },
		{
			key: 'type',
			path: 'data.type',
			displayValuePath: 'data.aircraftType.data.title',
			name: 'Aircraft type',
			type: 'remote-select',
			tableHidden: true,
			options: {
				loadData: (fleetManagementContext) => fleetManagementContext.aircraftTypes,
				keyPath: 'id',
				labelPath: (obj) => obj.data.title,
			},
		},
		{   
			key: 'serialNumber',
			path: 'data.serialNumber',
			name: 'Serial Number',
			type: 'text',
		},
		{   
			key: 'registrationNumber',
			path: 'data.registrationNumber',
			name: 'Registration Number',
			type: 'text',
		},
		{   
			key: 'procurementDate',
			path: 'data.procurementDate',
			name: 'Procurement Date',
			type: 'datetime-local',
		},
		{   
			key: 'make',
			path: 'data.make',
			name: 'Make',
			type: 'text',
		},
		{   
			key: 'model',
			path: 'data.model',
			name: 'Model',
			type: 'text',
		},
		{   
			key: 'key',
			path: 'data.key',
			name: 'API Key',
			type: 'text',
		},
		{   
			key: 'satId',
			path: 'data.satId',
			name: 'Satellite ID',
			type: 'text',
		},
		{   
			key: 'status',
			path: 'data.status',
			name: 'Status',
			type: 'select',
			options: [{
					key: 'Serviceable',
					label: 'Serviceable',
					},{
						key: 'Non-Serviceable',
						label: 'Non-Serviceable',
			}]
		},
		{   
			key: 'image',
			path: 'data.image',
			name: 'Image',
			type: 'file',
			hidden: true,
		},
	]
};
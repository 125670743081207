import React from 'react';


export const OrbitToolbar = ({selectedAircraft}) => {
   
    return(
        <div className='orbit-toolbar-wrap'>
            <div className='orbit-toolbar-content'>
                <span className='orbiting-status uppercase title'>ORBITING</span>
                <span className='red uppercase' onClick={() => selectedAircraft.stopOrbit()}>Stop</span>
            </div>
        </div>
    )
}
import { ArrowDropDownCircle, Navigation } from "@mui/icons-material";
import { motion } from "framer-motion";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FlightContext } from "../../../contexts/flight-context";
import { InfoPanel } from "../../layout/info-panel/info-panel";
import { InfoCard } from "../info-card/info-card";
import PerfectScrollbar from 'react-perfect-scrollbar';
import { ActiveFlightItem } from "./components/active-flight-item/active-flight-item";
import { AircraftContext } from "../../../contexts/aircraft-context";
import { LabelIconButton } from "../../buttons/label-icon-button/label-icon-button";
import { Panel } from "../../layout/panel/panel";
import { InterfaceContext } from "../../../contexts/interface-context";

export const ActiveFlightsPanel = () => {
	// const [toggled, setToggled] = useState(true);
	const {activeFlightsPanelVisible, setActiveFlightsPanelVisible, scheduledFlightsPanelVisible} = useContext(InterfaceContext);

	// const [isComponentLoaded, setIsComponentLoaded] = useState(false);
	// const [expanded, setExpanded] = useState(false);
	const { 
		pendingFlights, 
		loadPendingFlights,
		isBusy, 
		hasPendingFlightsLoaded, 
		error 
	} = useContext(FlightContext);

	const { connectToAircraft, connectedAircraft } = useContext(AircraftContext);
	const navigate = useNavigate(); 


	const lastPage = useRef(null);

	const location = useLocation();

	if (location.pathname === '/login' || location.pathname === '/') {
		return <></>;
	}

	// if (!hasPendingFlightsLoaded && !isBusy && !error && !isComponentLoaded) {
	// 	setTimeout(() => {
	// 		setIsComponentLoaded(true); 
	// 		loadPendingFlights()
	// 	}, 100);
	// }

	// if (!isComponentLoaded) {
	// 	setTimeout(() => { setToggled(true) }, 100);
	// }

	if (lastPage.current != location.pathname) {
		lastPage.current = location.pathname;
		if (location.pathname == '/mission-planning') {
			setTimeout(() => { setActiveFlightsPanelVisible(false) }, 100);
		} else if (!activeFlightsPanelVisible) {
			setTimeout(() => { setActiveFlightsPanelVisible(true) }, 100);
		}
	}


	return (
		<motion.div
			key="active-flights-panel"
			initial={{ x: 150, opacity: 0 }}
			animate={{ x: 0, opacity: 1 }}
			exit={{ x: 150, opacity: 0 }}
			transition={{ duration: 0.2, ease: "easeOut", delay: 0.3 }}
			className={`active-flights-panel ${activeFlightsPanelVisible && 'toggled'} ${scheduledFlightsPanelVisible && 'scheduled-visible'}`}
		>
			{/* <InfoCard
				key="active-flights-info-card"
				// primaryGradient={"#0F3B60"}
				// secondaryGradient={"#081355"}
			> */}
			<Panel
				key="active-flights-info-card"
				className={`active-flights-info-panel` }
                title={"Active flights"}
                icon={<Navigation/>}
                backgroundColor="#060d1f"
                expanded={activeFlightsPanelVisible}
				onClick={() => setActiveFlightsPanelVisible(!activeFlightsPanelVisible)}
				>
						{/* <InfoPanel
							key="active-flights-info-panel"
							title="Active flights"
							titleIcon={<Navigation/>}
							actionIcons={[{icon: <ArrowDropDownCircle className="collapse-panel-icon"/>, action: () => setToggled(!toggled)}]}
						> */}
							<PerfectScrollbar>
							
								<motion.div
									key="active-flights-list"
									initial={{ opacity: 0, y: -10 }}
									animate={{ opacity: 1, y: 0 }}
									exit={{ opacity: 0, y: -10 }}
								>
								{
									
											(connectedAircraft && connectedAircraft.length > 0) ?
												connectedAircraft.map(ac => (
													<ActiveFlightItem aircraft={ac}/>
												))
											:
											// <div>
												<p className="no-flights-message">There are no active flights</p>
											// </div>
										

								}
								</motion.div>
								
						
						</PerfectScrollbar>
					{/* </InfoPanel> */}
			{/* </InfoCard> */}
			</Panel>
		</motion.div>
	)
}
import { CancelOutlined, Close } from "@mui/icons-material";
import React, { useEffect, useRef} from "react";

export const SlideConfirmation = ({visible, caption, values, onCancel, onConfirm}) => {
	const localValues = useRef({});
	const slider = React.createRef();

	useEffect(() => {
		if (values) {
			localValues.current = Object.keys(values).reduce((prev, curr) => { 
				prev[values[curr].key] = values[curr].default; 
				return prev;
			}, {});
		}
	}, [values]);


	// const confirm = () => {
	// 	this.props.onConfirm(this.values);
	// }

	
	const checkUnlocked = (value) => {
		if (value == 10) {
			onConfirm(localValues.current);
			slider.current.value = 0;
		} else {
			slider.current.value = 0;
		}
	}



		// if (localValues.current != null) {
		// 	for (const value of values.current){
		// 		if (value.default != null && localValues.current[value.key] == null) {
		// 			localValues.current[value.key] = value.default;
		// 		}
		// 	}
		// }

		return (
				<div
					key="slide-confirmation"
					className={`slide-confirmation ${visible ? 'visible': 'hidden'}`}
				>
				<div className="slide-confirmation-wrap" key="slide-confirmation-wrap">
					<div className="slide-confirmation-header">
						<p className="slide-confirmation-caption">{ caption }</p>
						<Close style={{ cursor: 'pointer' }} onClick={() => { slider.current.value = 0; onCancel() }}/>
					</div>
					<div className="slide-confirmation-body ">
						<div className="slide-confirmation-values">
							{
								values && values.map((value,index) => (
									<div className="slide-confirmation-value" key={`slide-confirmation-${index}`}>
										<label>{value.title}</label>
										<input defaultValue={value.default} type={value.type} onChange={(e) => localValues.current[value.key] = (value.type == 'number' ? parseInt(e.target.value) : e.target.value)}/>
										{ value.unit && <span>{value.unit}</span>}
									</div>
								))
							}
						</div>

						<p className="slide-to-confirm">Slide to confirm</p>
						<input 
							className='glow-gray'
							ref={slider}
							type="range" 
							defaultValue={0}
							max={10}
							onMouseUp={(e) => checkUnlocked(e.target.value)}
							onChange={(e) => e.target.value == 10 && checkUnlocked(e.target.value)}
						/>
					</div>
				</div>
			</div>
		)
	
}
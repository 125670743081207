export const personSchema = {
	entityName: 'Person',
	loadSingleItem: (fleetManagementContext, id) => fleetManagementContext.getSinglePerson(id),
	updateSingleItem: async (fleetManagementContext, id, data) => await fleetManagementContext.updatePerson(id, data),
	removeSingleItem: async (fleetManagementContext, id) => await fleetManagementContext.removePerson(id),
	createSingleItem: async (fleetManagementContext, data) => await fleetManagementContext.createPerson(data),

	attributes: [
		{
			key: 'id',
			path: 'id',
			name: 'ID',
			type: 'text',
			hidden: true,
			disabled: true,
			autogenerated: true,
		},
		{
			key: 'firstName',
			path: 'data.firstName',
			name: 'First Name',
			type: 'text',
		},
		{
			key: 'lastName',
			path: 'data.lastName',
			name: 'Last Name',
			type: 'text',
		},
		{
			key: 'userId',
			path: 'data.userId',
			name: 'User ID',
			type: 'text',
		},
		{
			key: 'email',
			path: 'data.email',
			name: 'E-Mail',
			type: 'email',
		},
		{
			key: 'phone',
			path: 'data.phone',
			name: 'Phone',
			type: 'text',
		},
		{
			key: 'role',
			path: 'data.role',
			name: 'Role',
			type: 'select',
			options: [
				{
					key: 'pilot',
					label: 'Pilot',
				},
				{
					key: 'maintenance',
					label: 'Maintenance',
				},
				{
					key: 'observer',
					label: 'Observer',
				}
			]
		},
	]
};
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import React, { useEffect, useRef, useState } from 'react';
import { EditData } from '../../../../shared-components/inputs/edit-data/edit-data';
import { InputFieldV2 } from '../../../../shared-components/inputs/input-field-v2/input-field-v2';

export const AltitudeSpeedSetter = ({type, onUpdate, disabled=false}) => {
    const [inputFieldActive, setInputFieldActive] = useState(false);
    const [upPressed, setUpPressed] = useState(false);
    const [downPressed, setDownPressed] = useState(false);
    const inputValueRef = useRef(0);
    const intervalRef = useRef(null);
    const valueIntervalRef = useRef(null);
    const [input, setInput] = useState(0);
    useEffect(() => {
        
    }, [input]);
   
  const handleDownPressed = (side) => {
        // aircraft.setYaw(250);
        if(intervalRef.current != null) {
            clearInterval(intervalRef.current);
        }

        if(side == 'up') {
            
            // lastDirection.current = -1;
            setUpPressed(true); 
            handleGoUp();
            intervalRef.current = setInterval(() => handleGoUp(), 200)
        } else {
            // lastDirection.current = 1;
            setDownPressed(true); 
            handleGoDown();
            intervalRef.current = setInterval(() => handleGoDown(), 200)
        }

        if (valueIntervalRef.current != null) {
            clearInterval(valueIntervalRef.current);
        }

        valueIntervalRef.current = setInterval(() => {
            // aircraft.setYaw(rotateRef.current, lastDirection.current)
            onUpdate(inputValueRef.current);
        }, 500);
    }
    const handleMouseUp = (side) => {
        if(side == 'up'){
            setUpPressed(false);
        } else {
            setDownPressed(false);
        }

        if (intervalRef.current != null) {
            clearInterval(intervalRef.current);
        }

        // aircraft.setYaw(rotateRef.current, lastDirection.current)        
        onUpdate(inputValueRef.current);
        clearInterval(valueIntervalRef.current);
     }
    const handleGoDown = () => {
        inputValueRef.current -= 1;
        setInput(inputValueRef.current);
    }
    const handleGoUp = () => {
        inputValueRef.current += 1;
        setInput(inputValueRef.current);
    }

  
    const handleSubmitInput = (value) => {
        setInputFieldActive(false);
        if(parseFloat(value) < 0) {
            value = value * (-1);
        }
        const maxLength = 3;
        if (value.length > maxLength) {
            value = value.slice(0, maxLength);
        }
        inputValueRef.current = parseFloat(value);
        
        console.log(inputValueRef.current);
        onUpdate(inputValueRef.current);
        setInput(inputValueRef.current);
    }
    const handleCancel = () => {
        setInputFieldActive(false);
        // inputValueRef.current = null;
        console.log(inputValueRef.current);
    }


  

    return(
        <div className={`alt-spd-setter` }>
            <span className='alt-spd-title'>{type == 'alt' ? "ALT" : "SPD"}</span>
            { <div className={`alt-spd-input-wrap ${disabled && 'disabled'}`} onClick={() => setInputFieldActive(true)}>
                            
                {inputFieldActive && !disabled ? 
                     <EditData 
                        fieldType="number" 
                        type='integer'
                       
                        onSubmit={(value) => handleSubmitInput(value)} 
                        value={input}
                        onBlur={() => handleCancel()}
                        min="0"
                        disabled={disabled}/> : 
                    <span className='current-active-alt-spd-value'>{input} </span>}
                    {type == 'alt' && <span style={{fontSize:"9px"}}> AMSL</span>}
                    {type == 'spd' && <span style={{fontSize:"9px"}}> m/s</span>}
            </div>
        
            }
            <div className='alt-spd-control-wrap'>
                <div className={`alt-spd-increase ${upPressed && 'active'} ${disabled && 'disabled'}`} 
                    onMouseDown={() => {!disabled && handleDownPressed("up");}}
                    onMouseUp={() => { !disabled && handleMouseUp("up");}}
                    >
                    <KeyboardArrowUp/>
                </div>
                <div className={`alt-spd-decrease ${downPressed && 'active'} ${disabled && 'disabled'}`} 
                    onMouseDown={() => { !disabled && handleDownPressed("down")}}
                    onMouseUp={() => {!disabled && handleMouseUp("down");}}
                    
                    >
                    <KeyboardArrowDown/>
                </div>
                
                
            </div>
        </div>
    )
}

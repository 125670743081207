import React, { createContext, useContext, useState, useRef } from "react";
import axios from "axios";

import { ConfigContext } from "./config-context";

import UtilityService from "../helpers/utility-service";



export const WeatherContext = createContext();

// Example of weather api response
// -----------------------------------

const WeatherContextProvider = ({ children }) => {
	const [currentWeather, setCurrentWeather] = useState(null);
	const [isBusy, setIsBusy] = useState(false);
	const [hasLoaded, setHasLoaded] = useState(false);
	const [error, setError] = useState(null);

	const lastLocation = useRef(null);
	const lastUpdate = useRef(null);

	// Weather service config
	const { 
		services: { 
			weatherService: { 
				currentWeatherHost, 
				forecastHost, 
				key, 
				minReportAge, 
				maxReportValidityRadius 
			}
		}
	} = useContext(ConfigContext); 

	const shouldUpdateWeather = ({ latitude, longitude}) => {
		const now = UtilityService.getCurrentTime(true);

		if (lastUpdate.current != null // Old report exists
			&& (
				!isBusy && UtilityService.distance(lastLocation.current, { latitude,longitude }) < maxReportValidityRadius) // Is report geographically close to the previous one 
				&& now - lastUpdate.current <= minReportAge
			) 
		{
			return false;
		}

		return true
	}

	/**
	 * Load the latest weather report
	 * @param {*} latitude 
	 * @param {*} longitude 
	 */
	const reloadWeather = ({ latitude, longitude }, force=false) => {
		if (!force && !shouldUpdateWeather({ latitude, longitude })) {
			return;
		}

		setIsBusy(true);
		setError(null);

		axios.get(`${forecastHost}`, {
			params: {
				key,
				days: 3,
				q: `${latitude},${longitude}`,
				aqi: 'no',
				alerts: 'yes',
			}
		})
		// (new Promise((resolve, reject) => setTimeout(() => resolve({ data: dummyResponse }), 10)))
		.then(res => {
			setCurrentWeather(res.data);

			lastUpdate.current = UtilityService.getCurrentTime(true);
			lastLocation.current = { latitude, longitude };

			setHasLoaded(true);
			setIsBusy(false);
		}).catch((err) => {
			setError(err);
			setIsBusy(false);
		})
	}

	const getWeather = async ({ latitude, longitude}, timestamp) => {
		return new Promise(async (resolve, reject) => {
			const [date, time] = timestamp.split('T');
			const res = await axios.get(`${forecastHost}`, {
				params: {
					key,
					days: 1,
					unixdt: date,
					q: `${latitude},${longitude}`,
					aqi: 'no',
					alerts: 'yes',
				}
			});
			
			// console.log(res.data);

			const hours = res.data.forecast.forecastday[0].hour;
			const startTime = Math.round(new Date(timestamp).getTime() / 1000);

			let min = 99999999;
			let minHourForecast;

			for (const hourForecast of hours) {
				const diff = Math.abs(hourForecast.time_epoch - startTime) < min;
				if (diff < min) {
					min = diff;
					minHourForecast = hourForecast;
				}
			}

			resolve(minHourForecast);
		})
	}

	return (
		<WeatherContext.Provider value={{ 
			currentWeather, 
			isBusy, 
			hasLoaded, 
			reloadWeather, 
			error,
			lastUpdateTimestamp: lastUpdate.current,
			shouldUpdateWeather,
			getWeather,
		}}>
			{ children }
		</WeatherContext.Provider>
	)
};

export default WeatherContextProvider
import { Circle, Close } from '@mui/icons-material';
import { motion } from 'framer-motion';
import React, { useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { FleetManagementActionButtons } from '../../../screens/fleet-management-screen/components/fleet-management-action-buttons/fleet-management-action-buttons';

export const SkyModal = ({
    onClose,
    style,
    title, 
    children, 
    actionButtons, 
    modalVisible,
    disableInputs,
    sendInfo,
    editInfo,
    removeItem,
    labelText,
    editButtonsActive,
    className,
    disableScrollbar, 
    modalIcon,
    modalCloseIcon,
    modalStatusIndicator}) => {
    
    
    // const [title, setTitle] = useState(title);
    // const [actionButtons, setActionButtons] = useState(actionButtons);
    // const [modalVisible, setModalVisible] = useState(modalVisible);
    // const [disableInputs, setDisableInputs] = useState(disableInputs);
    // const [labelText, setLabelText] = useState(labelText);
    // const [disableScrollbar, setDisableScrollbar] = useState(disableScrollbar);

    const closeModal = () => {
        onClose();
    }
    
    
    if (!modalVisible) {
        return <></>;
    } else 
        return(
                // <div className='sky-modal-base'>
                <motion.div 
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.2, ease: [0.130, 0.850, 0.375, 0.960] }}
                    className={`sky-modal`} 
                    key={`sky-modal-${title}`}
                >
                    {modalVisible && 
                        <div className='sky-modal-base' onClick={()=>closeModal()} key={`sky-modal-base-${title}`}>
                        <div className={`sky-modal-background `} key={`sky-modal-background-${title}`}>
                            <motion.div 
                                key={`sky-modal-panel-${title}`}
                                initial={{ scale: 0.98 }}
                                animate={{ scale: 1 }}
                                exit={{ scale: 0.98 }}
                                transition={{ duration: 0.4, ease: [0.130, 0.850, 0.375, 0.960] }}
                                className={`sky-modal-panel ${className && className} ${style && style}`} onClick={(e) => e.stopPropagation()}
                            > 
                                <div className='sky-modal-header'>
                                    <div className='modal-title-status-wrap'>
                                        <h3 className='modal-title uppercase bold'>{modalIcon && <span>{modalIcon}</span>} {title}</h3>
                                        {modalStatusIndicator &&   
                                            <div className={`modal-status ${modalStatusIndicator.status}`}>
                                                <span className={`uppercase`}>{modalStatusIndicator.value}</span>
                                                <Circle sx={{width: "15px", marginLeft: "10px"}}/>
                                            </div>
                                        }
                                    </div>
                                    <div className='modal-close' onClick={()=>closeModal()}>
                                       {modalCloseIcon ? modalCloseIcon : (<Close/>)}
                                    </div>
                                </div>
        
                                {/* option={{ wheelPropagation: false }} */}
                                { disableScrollbar ?
                                    (<div className='sky-modal-content'>{children}</div>) : 
                                    (<div className='sky-modal-content'>
                                        {/* <PerfectScrollbar option={{ wheelPropagation: false }}>   */}
                                        
                                            {children}
                                    
                                        {/* </PerfectScrollbar> */}
                                    </div>)
                                }
                                
                                {   editButtonsActive &&
                                    <div className='sky-modal-bottom'>
                                            <FleetManagementActionButtons 
                                                disableInputs={disableInputs}
                                                sendInfo={sendInfo}
                                                editInfo={editInfo}
                                                removeItem={removeItem}
                                                labelText={labelText}
                                                />
                                    </div>
                                }
                                {actionButtons &&
                                    <div className='sky-modal-bottom'>
                                        
                                            <div className='sky-modal-buttons-left'> 
                                            {
                                                actionButtons
                                                .filter(({ position }) => position == null || position == 'left')
                                                .map(({ btnType, title, action, className }) => (
                                                        <button 
                                                            key={`sky-modal-button-${title}`} 
                                                            className={`${btnType} ${className} sky-modal-button`}
                                                            onClick={() => action && action()}
                                                        >
                                                            { title }
                                                        </button>
                                                ))
                                            }
                                            </div>
                                            <div className='sky-modal-buttons-middle'> 
                                            {
                                                actionButtons
                                                .filter(({ position }) => position == 'middle')
                                                .map(({ btnType, title, action, className }) => (
                                                        <button 
                                                            key={`sky-modal-button-${title}`} 
                                                            className={`${btnType} ${className} sky-modal-button`}
                                                            onClick={() => action && action()}
                                                        >
                                                            { title }
                                                        </button>
                                                ))
                                            }
                                            </div>
                                            <div className='sky-modal-buttons-right'> 
                                            {
                                                actionButtons
                                                .filter(({ position }) => position == 'right')
                                                .map(({ btnType, title, action, className }) => (
                                                        <button 
                                                            key={`sky-modal-button-${title}`} 
                                                            className={`${btnType} ${className} sky-modal-button`}
                                                            onClick={() => action && action()}
                                                        >
                                                            { title }
                                                        </button>
                                                ))
                                            }
                                            </div>
                                    </div>
                                }
                            </motion.div>
                        </div>
                        </div>
                    }
                </motion.div>
        );

    }

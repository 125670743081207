// import fleetManagementService from "../../../services/fleet-management-service";

export const batterySetSchema = {
	entityName: 'Battery Set',
	loadSingleItem: (fleetManagementContext, id) => fleetManagementContext.getSingleBatterySet(id),
	updateSingleItem: async (fleetManagementContext, id, data) => await fleetManagementContext.updateBatterySet(id, data),
	removeSingleItem: async (fleetManagementContext, id) => await fleetManagementContext.removeBatterySet(id),
	createSingleItem: async (fleetManagementContext, data) => await fleetManagementContext.createBatterySet(data),

	attributes: [
			{   
					key: 'id',
					path: 'id',
					name: 'ID',
					type: 'text',
					hidden: true,
					disabled: true,
					autogenerated: true,
			},
			{
				key: 'name',
				path: 'data.name',
				name: 'Name',
				type: 'text',
			},
			{
					key: 'assemblyDate',
					path: 'data.assemblyDate',
					name: 'Assembly Date',
					type: 'datetime-local',
			},
			{
					key: 'batteryType',
					path: 'data.batteryType',
					name: 'Battery Type',
					type: 'text',
			},
			{
					key: 'batteryTypeModel',
					path: 'data.batteryTypeModel',
					name: 'Battery Type Model',
					type: 'text',
			},
			{
				key: 'batteries',
				path: 'data.batteries',
				name: 'Batteries',
				type: 'table',
				fixed: true,
				autogenerated: true,
				attributes: [
					{   
						key: 'id',
						path: 'id',
						name: 'ID',
						type: 'text',
						hidden: true,
						disabled: true,
					},
					{
							key: 'name',
							path: 'data.name',
							name: 'Name',
							type: 'text',
					},
					{
							key: 'serialNumber',
							path: 'data.serialNumber',
							name: 'Serial Number',
							type: 'text',
					},
					{
						key: 'purchaseDate',
						path: 'data.purchaseDate',
						name: 'Purchase Date',
						type: 'datetime-local',
					},
					{
						type: 'button',
						name: 'Remove From Set',
						label: 'Remove Battery',
						action: (fleetManagementContext, id) => fleetManagementContext.updateBattery(id, { batterySetId: null })
					}
				]
			},
			{
					key: 'status',
					path: 'data.status',
					name: 'Status',
					type: 'text',
			},
			// {
			// 		key: 'uploadDate',
			// 		path: 'createdAt',
			// 		name: 'Upload Date',
			// 		type: 'datetime-local',
			// 		disabled: true,
			// },
			// {
			// 		key: 'class',
			// 		path: 'data.class',
			// 		name: 'Battery Class',
			// 		type: 'select',
			// 		options: [{
			// 				key: 'A',
			// 				value: 'Class A',
			// 		},
			// 		{
			// 				key: 'B',
			// 				value: 'Class B',
			// 		},
			// 		{
			// 				key: 'C',
			// 				value: 'Class C',
			// 		}],
			// },
	]
};
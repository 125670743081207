export const aircraftTypeSchema = {
	entityName: 'Aircraft Type',
	loadSingleItem: (fleetManagementContext, id) => fleetManagementContext.getSingleAircraftType(id),
	updateSingleItem: async (fleetManagementContext, id, data) => await fleetManagementContext.updateAircraftType(id, data),
	removeSingleItem: async (fleetManagementContext, id) => await fleetManagementContext.removeAircraftType(id),
	createSingleItem: async (fleetManagementContext, data) => await fleetManagementContext.createAircraftType(data),

	attributes: [
		{   
			key: 'id',
			path: 'id',
			name: 'ID',
			type: 'text',
			hidden: true,
			disabled: true,
			autogenerated: true,
		},
        {   
			key: 'title',
			path: 'data.title',
			name: 'Title',
			type: 'text',
			hidden: false,
			disabled: false,
			autogenerated: false,
		},
        {
			key: 'autopilot',
			path: 'data.autopilot',
            tableHidden: true,
			displayValuePath: 'data.autopilot.data.title',
			name: 'Autopilot',
			type: 'remote-select',
			options: {
				loadData: (fleetManagementContext) => fleetManagementContext.autopilots,
				keyPath: 'id',
				labelPath: (obj) => `${obj.data.title} ${obj.data.version}`,
			},
		},
        {
			key: 'fields',
			path: 'data.fields',
			name: 'Aircraft type config',
			editOnly: true,
			type: 'list',
			itemSchema: [{
				key: 'param',
				title: 'Parameter',
				type: 'text',
			}, 
			{
				key: 'value',
				title: 'Value',
				type: 'text',
			}]
		},
    ]
}
import React, { useContext, useEffect, useState } from 'react';
import { ConfigContext } from '../../../contexts/config-context';
import { MapContext } from '../../../contexts/map-context';
import { TerrainContext } from '../../../contexts/terrain-context';
import UtilityService from '../../../helpers/utility-service';
import { Loading } from '../../layout/loading/loading';
import {OverviewButton} from '../../buttons/overview-button/overview-button';
import { FilterHdrOutlined, KeyboardArrowDown, Landscape } from '@mui/icons-material';
import Chart from "react-apexcharts";
import turf from "turf";
import { MissionContext } from '../../../contexts/mission-context';
import { useLocation } from 'react-router-dom';
import { CockpitContext } from '../../../contexts/cockpit-context';
const lineIntersect = require('@turf/line-intersect').default;

export const TerrainChart = ({currentPath, addMissionWarning=()=>{}, removeMissionWarning=()=>{}}) => {
	
    const {waypoints} = currentPath || { waypoints:[]};
    const pathWaypoints = waypoints;
	const location = useLocation();

	const { pathSegments=[], isBusy, clearTerrainPath, currentCockpitPath, currentMissionPath,createMultipleTerrainPaths} = useContext(TerrainContext);
	const { services: { terrainService: { terrainResolution }}} = useContext(ConfigContext);
	// const [toggled, setToggled] = useState(false);

	const { facilityMapPolygons } = useContext(MapContext);


	const pointDistance = terrainResolution / 2;

	// const elevations = useRef([]);
	// const waypointElevations = useRef([]);
	const [hasAirViolation, setHasAirViolation] = useState(false);
	const [profilePoints, setProfilePoints] = useState([]);
	const [collisionFound, setCollisionFound] = useState(false);
	const [elevations, setElevations] = useState([]);
	const [waypointElevations, setWaypointElevations] = useState([]);
	

	useEffect(() => {
		return () => {
			if(location.pathname === '/cockpit-v2') {
				createMultipleTerrainPaths(currentCockpitPath);
			} else if(location.pathname === '/mission-planning') {
				createMultipleTerrainPaths(currentMissionPath);
			}
			
		}
	}, [location])

	useEffect(() => {
		if (pathSegments != null && pathSegments.length == 0) {
			setHasAirViolation(false);
			setProfilePoints([]);
			setCollisionFound(false);
		} else  {
			let hasAirViolationLocal = false;
			if (facilityMapPolygons != null && pathWaypoints.length > 0) {
				hasAirViolationLocal = UtilityService.findIntersectingFacilityFields(facilityMapPolygons, pathWaypoints).filter(el => el.violation).length > 0;
			}

			if (hasAirViolationLocal) {
				addMissionWarning('airRiskWarning', { type: 'error', text: 'Facility violation detected' });
			} else {
				removeMissionWarning('airRiskWarning');
			}

			setHasAirViolation(hasAirViolationLocal);
		}

		if (facilityMapPolygons != null && pathWaypoints.length > 1) {
			const intersectingFacilityFields = UtilityService.findIntersectingFacilityFields(facilityMapPolygons, pathWaypoints);

			const intersectionPoints = [];
			let baseDist = 0

			for (let i = 1; i < pathWaypoints.length; i += 1) {
				const [point1, point2] = [pathWaypoints[i-1], pathWaypoints[i]];
				const line = turf.lineString([[point1.longitude, point1.latitude], [point2.longitude, point2.latitude]]);

				const currentSegmentIntersections = [];
				for (const polygon of intersectingFacilityFields) {
					const [a, b, c, d] = polygon.coordinates;

					const polyObj = turf.bboxPolygon([
						Math.min(a[1], b[1], c[1], d[1]),
						Math.min(a[0], b[0], c[0], d[0]),
						Math.max(a[1], b[1], c[1], d[1]),
						Math.max(a[0], b[0], c[0], d[0]),
					]);

					const intersections = 
						lineIntersect(line, polyObj).features
						.map(f => ({ 
							latitude: f.geometry.coordinates[1], 
							longitude: f.geometry.coordinates[0],
							value: polygon.value * 0.3048,
						}))
						.map(point => ({ ...point, x: baseDist + UtilityService.distance(point1, point, 0.0001), y: point.value }))

						// console.log(intersections);
					currentSegmentIntersections.push(...intersections);
				}
				// intersectionPoints.push(...currentSegmentIntersections);
				intersectionPoints.push(...currentSegmentIntersections.sort((a, b) => a.x - b.x));
				baseDist += UtilityService.distance(point1, point2);
			}

			if (intersectionPoints[0] != null) {
				setProfilePoints([
					{
						x: 0,
						y: parseInt(intersectionPoints[0].y)
					}, 
					...intersectionPoints, 
					{
						...pathWaypoints.slice(1)[0], 
						x: parseInt(baseDist),
						y: parseInt(intersectionPoints.slice(-1)[0].y)
					}
				]);
			};

			
		}

		

		if (!isBusy && pathSegments.length > 0 && pathSegments[0].elevation.length > 0) {
			// Get terrain elevation array
			let elevationsLocal = [];
			elevationsLocal = []

			let prevDist = 0;
			try {
				if (pathSegments.length > 1) {
					for (let i = 0; i < pathSegments.length; i += 1) {
						const segment = pathSegments[i];

						if (i < pathSegments.length - 1) {
							const nextSegment = pathSegments[i + 1];
							const segmentResolution = UtilityService.distance(segment.waypoint, nextSegment.waypoint) / segment.elevation.length;
							
							for ( let j = 0; j < segment.elevation.length - 1; j += 1) {
								elevationsLocal.push({
									x : prevDist + j * segmentResolution,
									y : segment.elevation[j],
								})
							}
							prevDist += UtilityService.distance(nextSegment.waypoint, segment.waypoint);
						}
						else {
							elevationsLocal.push({
								x: prevDist,
								y: parseInt(segment.elevation[0]),
							})
						}
					} 
					setElevations([...elevationsLocal]);
				}
			} catch (err) {
				setElevations([]);
			}
			

			// Get flight path altitude array
			let waypointElevationsLocal = waypoints.map((wp, index) => ({
				x: index > 0 ? 
					Math.round(Math.abs(UtilityService.distance(waypoints[index - 1], wp)))
					: 0,
				y: wp.amsl,
			}));

			for (let i = 1; i < waypointElevationsLocal.length; i += 1) {
				const currentPoint = waypointElevationsLocal[i];
				const previousPoint = waypointElevationsLocal[i - 1];
				currentPoint.x += previousPoint.x;
			}

			setWaypointElevations([...waypointElevationsLocal]);

			// Detect possible collision points
			for (let i = 0; i < waypointElevationsLocal.length - 1; i += 1) {
				const { k, n } = UtilityService.getLineThroughPoints({ 
					x: 0,
					y: waypointElevationsLocal[i].y,
				},
				{ 
					x: pathSegments[i].elevation.length * pointDistance,
					y: waypointElevationsLocal[i + 1].y,
				})

				let collisionFoundFlag = false;
				for (let j = 0; j < pathSegments[i].elevation.length; j += 1) {
					const y = pathSegments[i].elevation[j];
					const distance = UtilityService.getDistanceBetweenPointAndLine({ x: j * pointDistance, y }, k, n);
					
					if (distance < 5) {
						collisionFoundFlag = true;
						setCollisionFound(true);
						break;
					}
				}

				if (collisionFoundFlag) {
					break;
				} else {
					setCollisionFound(false);
				}
			}
		} else if (!isBusy && pathSegments.length == 0) {
			setElevations([]);
			setWaypointElevations([]);
		}
	}, [currentPath, isBusy]);

	useEffect(() => {
		if (collisionFound) {
			addMissionWarning('terrain-collision', { title: 'Terrain collision', text: 'Possible terrain collision detected', type: 'error' })
		} else {
			removeMissionWarning('terrain-collision');
		}
	}, [collisionFound])

	const pathLength = elevations.length > 0 ? elevations[elevations.length - 1].x : 0;

	const options = {
		position: 'front',
		legend: {
      show: false,
		},
		grid: {
			borderColor: '#dedede2f',		
			// show: false,
		},
		dataLabels: {
			style: {
				colors: ['#FFF']
			}
		},
		chart: {
			animations: {
				enabled: pathLength < 7000,
			},
			toolbar: {
				show: false,
			},
		},
		stroke: {
			show: true,
			lineCap: 'round',
			width: 2,
			dashArray: 0,      
		},
		xaxis: {
			type: 'numeric',
			tickAmount: Math.min(pathLength / 100, 15),
			labels: {
				style: {
					cssClass: 'apexcharts-xaxis-label', 
				}
			}
		},
		fill: {
			type: 'solid',
			color: '#B1B9C4',
		},
		colors: [(collisionFound) ? '#ee6e6e': '#00ffaa', (collisionFound || hasAirViolation) ? '#FF0000' : '#00FF00', '#00aaff'],
		annotations: {
			points: waypointElevations.map((el, index) => (
				{
					...el,
					marker: {
						size: 6,
						fillColor: "#fff",
						strokeColor: index === 0 ? 'green' : index === waypointElevations.length - 1 ? 'red' : 'purple',
						radius: 2,
					},
					label: {
						borderColor: "#FFF",
						offsetY: 0,
						style: {
							color: "#fff",
							background: index === 0 ? 'green' : index === waypointElevations.length - 1 ? 'red' : 'purple',
						},
	
						text: `WP${index + 1}`
					}
				}
			))
		}
	}


   
    return(

        <div className='terrain-chart'>
           { isBusy && <Loading position='absolute'/> }
		{/* { !toggled ? 
				
				// <OverviewButton opened={toggled} blink={collisionFound} status={pathSegments.length == 0 ? 'neutral' : collisionFound ? 'bad' : 'good'} icon={<Landscape/>} name='Terrain Elevation' onClick={() => setToggled(true)}/>
			// :*/}
				<div className="terrain-panel-wrapper">
					<div className='terrain-panel-header'>
						<div className='terrain-panel-title'>
							<span className='uppercase bold terrain-title'><FilterHdrOutlined/> Terrain Elevation</span>	
							<span className={`uppercase bold terrain-status ${collisionFound ? 'bad' : 'good'}`} >Terrain {collisionFound ? "collision" : "cleared"}</span>
						</div>
						{/* <span className="close-panel uppercase bold" onClick={() => setToggled(false)}><KeyboardArrowDown/></span> */}
					</div>
            {currentPath !== null && <Chart
                options={options}
                series={[
                    {
                        name: 'Terrain',
                        data: elevations.map(el => ({ x: el.x, y: Math.round(el.y)})),
                        type: 'area',
                    },
                    {
                        name: 'Flight path',
                        data: waypointElevations.map(el => ({ x: el.x, y: Math.round(el.y)})),
                        type: 'line',
                    },
                    {
                        name: 'Facility map',
                        data: profilePoints.map(el => ({ x: el.x, y: Math.round(el.y)})),
                        type: 'line',
                    }
                ]}
                type="line"
                height="150"
                width="100%"
            />}
            </div>
            {/* } */}
        </div>
    )
}
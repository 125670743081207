import React from 'react';
import { Stack } from '../stack/stack';

export const IconText = ({icon,text, direction, halign}) => {

    return(
        <Stack className='icon-text-wrapper' direction={direction} halign={halign} valign="center">
            <div className='icon-wrapper'>{icon && icon}</div>
            <div className='icon-text'><span>{text}</span></div>
        </Stack>
    )
}
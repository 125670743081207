
import { Close, Logout, MenuOpen, Person, PersonAdd, Settings } from "@mui/icons-material";
import { Avatar, Divider, IconButton, ListItem, ListItemIcon, Menu, MenuItem, Tooltip } from "@mui/material";
import { motion } from "framer-motion";
import React, { useContext, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { AuthContext } from "../../../contexts/auth-context";
import { AuthContextV2 } from "../../../contexts/auth-context-v2";
import { ConfigContext } from "../../../contexts/config-context";
import { InterfaceContext } from "../../../contexts/interface-context";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import { ConfirmationDialog } from "../confirmation-dialog/confirmation-dialog";
import { styled } from '@mui/material/styles';
import Badge from '@mui/material/Badge';

export const MenuBar = ({marginRight}) => {
	const { menuItems } = useContext(ConfigContext);
	const location = useLocation();
	const {menuBarVisible, setMenuBarVisible, setInfoDockOpened} = useContext(InterfaceContext);
	const { width } = useWindowDimensions();
	const [logoutModalVisible, setLogoutModalVisible] = useState(false);
	const breakPoint = 960;
	const {logout} = useContext(AuthContextV2);

	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);
	const handleClick = (event) => {
	  setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
	  setAnchorEl(null);
	};


	if (location.pathname == '/login') {
		return (<></>)
	}
	
	const StyledBadge = styled(Badge)(({ theme }) => ({
		'& .MuiBadge-badge': {
		  backgroundColor: '#44b700',
		  color: '#44b700',
		  boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
		  
		  '&::after': {
			position: 'absolute',
			top: 0,
			left: 0,
			width: '20px',
			height: '20px',
			borderRadius: '50%',
			// animation: 'ripple 1.2s infinite ease-in-out',
			// border: '1px solid currentColor',
			content: '""',
		  },
		},
		// '@keyframes ripple': {
		//   '0%': {
		// 	transform: 'scale(.8)',
		// 	opacity: 1,
		//   },
		//   '100%': {
		// 	transform: 'scale(2.4)',
		// 	opacity: 0,
		//   },
		// },
	  }));
	
	return (
		<motion.div 
			key="menu-bar"
			className={`menu-bar ${menuBarVisible && 'active'} ${width > breakPoint && 'active'} ${marginRight && 'right-margin'}`}
			initial={{ opacity: 0, x: 55 }}
			animate={{ opacity: 1, x: 0 }}
			exit={{ opacity: 0, x: 55 }}
			transition={{ duration: 0.3, delay: 0.1, ease: [0.130, 0.850, 0.375, 0.960] }}
		>
			
			<ConfirmationDialog 
				className='logout-modal'
				visible={logoutModalVisible}
				message={"Do you want to logout?"}
				onConfirm={() => logout()}
				onCancel={() => setLogoutModalVisible(false)}
				onClose={() => setLogoutModalVisible(false)}
			/>
			{!menuBarVisible && width < breakPoint &&
			<div className='menu-bar-button' onClick={()=> {setMenuBarVisible(true); setInfoDockOpened(false);}}>
				<MenuOpen />
			</div>}
			{menuBarVisible && width < breakPoint &&
			<div className='menu-items-wrapper'>
			 {menuItems.map((item, index) => (
				<Link key={`menu-${item.path}`} to={item.path}>
					<motion.div 
						className={`menu-item-icon ${location.pathname == item.path ? 'active' : ''}  ${index == 0 && 'first'}`}
						initial={{ opacity: 0, x: 50}}
						animate={{ opacity: 1, x: 0 }}
						transition={{ duration: 0.2, ease: [0.130, 0.850, 0.375, 0.960], delay: (0.6 + index/10) }}
					>
						{ item.icon }
					</motion.div>
				</Link>
			))}
			<motion.div 
				className={`menu-item-icon ${location.pathname == '/login' ? 'active' : ''}`}
				initial={{ opacity: 0, x: 50}}
				animate={{ opacity: 1, x: 0 }}
				transition={{ duration: 0.2, ease: [0.130, 0.850, 0.375, 0.960], delay: (0.6 + (menuItems.length)/10) }}
				onClick={() => setLogoutModalVisible(true)}
			>
				{/* <Person/> */}
				<StyledBadge
					overlap="square"
					anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
					variant="dot"
					>
					<Avatar alt="Remy Sharp" src="" />
				</StyledBadge>
			</motion.div>
			<motion.div 
				className={`menu-item-icon`}
				onClick={()=>{setMenuBarVisible(false);setInfoDockOpened(true);}}
				initial={{ opacity: 0, x: 50}}
				animate={{ opacity: 1, x: 0 }}
				transition={{ duration: 0.3, ease: [0.130, 0.850, 0.375, 0.960], delay: (0.6 + (menuItems.length+1)/10) }}
			>
				<Close/>
			</motion.div>
			
			</div> }
			{width > breakPoint && 
			<>{menuItems.map((item, index) => (
				<Link key={`menu-${item.path}`} to={item.path}>
					<motion.div 
						className={`menu-item-icon ${location.pathname == item.path ? 'active' : ''} ${index == 0 && 'first'} ${index == menuItems.length - 1 && 'last'}`}
						initial={{ opacity: 0, x: 50}}
						animate={{ opacity: 1, x: 0 }}
						transition={{ duration: 0.2, ease: [0.130, 0.850, 0.375, 0.960], delay: (0.5 + index/10) }}
					>
						{ item.icon }
					</motion.div>
				</Link>
			))}
			<motion.div 
				className={`menu-item-icon ${location.pathname == '/login' ? 'active' : ''}`}
				initial={{ opacity: 0, x: 50}}
				animate={{ opacity: 1, x: 0 }}
				transition={{ duration: 0.2, ease: [0.130, 0.850, 0.375, 0.960], delay: (0.6 + (menuItems.length)/10) }}
				// onClick={() => setLogoutModalVisible(true)}
			>
				 <Tooltip title="User">
					<IconButton
						onClick={open ? handleClose : handleClick}
						size="small"
						sx={{ backgroundColor: '#060d1f', width: "20px", height:"20px" }}
						aria-controls={open ? 'account-menu' : undefined}
						aria-haspopup="true"
						aria-expanded={open ? 'true' : undefined}
					>
					{/* <StyledBadge
					overlap="square"
					anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
					variant="dot"
					sx={{ backgroundColor: '#060d1f', width: "20px", height:"20px" }}
					>  */}
					
					<Avatar alt="Remy Sharp" src=""  sx={{ backgroundColor: '#060d1f', width: "30px", height:"30px" }}/>
				{/* </StyledBadge> */}
				</IconButton>
				</Tooltip>
				<Menu
				anchorEl={anchorEl}
				id="account-menu"
				open={open}
				onClose={handleClose}
				onClick={handleClose}
				PaperProps={{
				elevation: 0,
				sx: {
					overflow: 'visible',
					filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
					mt: 1.5,
					bgcolor: '#060d1f',
					color: 'white',
					'& .MuiAvatar-root': {
					width: 20,
					height: 20,
					ml: -0.5,
					mr: 1,
					},
					'&:before': {
					content: '""',
					display: 'block',
					position: 'absolute',
					top: 0,
					right: 14,
					width: 10,
					height: 10,
					bgcolor: '#060d1f',
					transform: 'translateY(-50%) rotate(45deg)',
					zIndex: 0,
					color: "white"
					},
				},
				}}
				transformOrigin={{ horizontal: 'right', vertical: 'top' }}
				anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
			>
        {/* <MenuItem onClick={handleClose}>
          <Avatar /> Profile
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <Avatar /> My account
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleClose}>
          <ListItemIcon>
            <PersonAdd fontSize="small" />
          </ListItemIcon>
          Add another account
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <ListItemIcon>
            <Settings fontSize="small" />
          </ListItemIcon>
          Settings
        </MenuItem> */}
        <MenuItem onClick={() => setLogoutModalVisible(true)} color="white" >
          <ListItemIcon color="white">
            <Logout fontSize="small" color="white"/>
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
			</motion.div>
			</>
			} 
			
		</motion.div>
	)
}
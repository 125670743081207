import { combineReducers } from "redux";

// attitude
const attitudeReducer = (state={
	pitch: null,
	roll: null,
	yaw: null,
}, action) => {
	switch (action.type) {
		case 'SET_ATTITUDE':
			return action.payload;
		default: return state;
	}
}

export default attitudeReducer;

export const createSetters = (store) => ({
	setAttitude: (attitude) => store.dispatch({ type: 'SET_ATTITUDE', payload: attitude }),
});
import React from 'react';
import { Stack } from '../../../../layout/stack/stack';


export const SideMenuItem = ({
    icon, 
    children, 
    title, 
    onClick, 
    isActive, 
    hasPanel=false,
    panelTitle=null,
    actions=null,
    style: customStyle = {},
    className,
    tooltipPosition
 }) => {

    return(
        <div className={`side-menu-item ${isActive && 'active'} ${className}`} onClick={(e) => onClick && onClick(e)}>
            <span 
                className={`side-menu-item-icon`}
            >
                {title && <span className={`tooltiptext ${tooltipPosition}`}>{title}</span>}
                {icon}
            </span>
            
            { isActive && hasPanel && 
                <div className='side-menu' style={{...customStyle}}>
                    <Stack 
                        className="side-menu-header"
                        direction="horizontal"
                        halign="space-between"
                        valign="center"
                    >
                        <div className="side-menu-title-wrap">
                            {/* <span className="side-menu-icon">{ icon }</span> */}
                            { panelTitle }
                        </div>
                        {
                            actions &&
                            <div>
                                { actions.map((action, index) => (
                                    <span 
                                        style={{ ...(action.blink && { color: 'yellow' })}}
                                        className={`panel-action ${action.blink ? 'blink':undefined}`}
                                        onClick={() => action.action && action.action()}
                                        key={`side-menu-item-action-${index}`}
                                    >
                                        { action.label }
                                    </span>
                                ))}
                            </div>
                        }
                    </Stack>
                    
                    {children}
                </div>
            }   
           
        </div>
    )
}
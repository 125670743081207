// MISSION_CURRENT (#42)
export function missionCurrent (message, aircraft) {
	const {
		seq
	} = message;

	const { isOrbiting, currentSeq } = aircraft.missionState.getState();
	const { customMode } = aircraft.generalState.getState();
	const { uploadedMissionItems } = aircraft.missionState.getState();
	const headedWp = uploadedMissionItems.mission.find(wp => wp.seq == seq);

	if (customMode == 'AUTO' && headedWp != null && headedWp.isLoiter && !isOrbiting) {
		aircraft.missionStateSetters.setIsOrbiting(true);
	}

	if (seq != currentSeq) {
		aircraft.missionStateSetters.setCurrentSeq(seq);
	}
	
		

	if(aircraft.pendingAcks['set-next-waypoint'] != null && aircraft.pendingAcks['set-next-waypoint'].length > 0) {
		const { value, onAccepted } = aircraft.pendingAcks['set-next-waypoint'][0];
		if (value == seq) {
			aircraft.pendingAcks['set-next-waypoint'].shift();
			onAccepted();
		}
	}
}
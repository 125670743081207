// import { combineReducers } from "redux";

// latitude
const hudReducer = (
	state = { 
		airspeed:null,
		groundspeed:null,
		altitude:null,
		climb:null,
		throttle:null,
		heading:null,
	}, action) => {
	switch (action.type) {
		case 'SET_HUD_DATA':
			return action.payload;
		default: return state;
	}
}

export default hudReducer;

export const createSetters = (store) => ({
		setHudData: (hudData) => store.dispatch({ type: 'SET_HUD_DATA', payload: hudData }),
});

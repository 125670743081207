// VFR_HUD (#74)
export function vfrHud (message, aircraft) {
	const {
		airspeed,
		groundspeed,
		heading,
		throttle,
		alt,
		climb,
	} = message;

	const { hudStateSetters, attitudeState, positionState, hudState } = aircraft;
	const { relativeAltitude, rangefinderAltitude } = positionState.getState();
	const { pitch, roll } = attitudeState.getState();

	const { lastTimestamp } = aircraft.hudState.getState();
	const now = Date.now();

	if (lastTimestamp == null || now - lastTimestamp > 50) {
		hudStateSetters.setHudData({ 
			airspeed,                  
			groundspeed,
			altitude: alt,
			relativeAltitude,
			pitch,
			roll,
			rangefinderAltitude,
			climb,
			throttle,
			heading,
			lastTimestamp: now,
		})
	}
}
import React from 'react';

export const EditInfoButtons = ({disableInputs, actionLabel, editInfo, sendInfo}) => {

        return(
            <div className='edit-info-buttons'>
                {disableInputs ? (<button 
                    className="primary"
                    onClick={()=>editInfo()}
                    >{actionLabel}</button>):
                (<div className='edit-user-info-buttons-wrap'><button 
                    className="primary"
                    onClick={()=>sendInfo()}
                    >Save</button>
                <button 
                    className="secondary"
                    onClick={()=>editInfo()}
                    >Cancel</button></div>)}
            </div>
        );
    }

import React, { useContext } from 'react';
import { OutlineButton } from '../../../../shared-components/buttons/outline-button/outline-button';
import { Stack } from '../../../../shared-components/layout/stack/stack';
import { MissionItem } from '../waypoint-panel/components/mission-item-v2/mission-item-v2';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { MissionContext } from '../../../../contexts/mission-context';

export const RallypointsPanel = () => {
    const { 
		rallyPoints,
		removeRallyPoint,
		clearRallyPoints,
	} = useContext(MissionContext);
   
    return(
        <div className='rallypoints-panel'>
             <Stack className='rallypoints-panel-button-title-wrap' direction='horizontal' halign='space-between' valign='center' >
                <p className='title-color section-title uppercase bold'>Rallypoints</p>
                <OutlineButton title='Clear rallypoints' onClick={() => clearRallyPoints()} color="red" size="medium"/>
            </Stack>
            <div className='rallypoints-panel-rps-wrap'>
                <PerfectScrollbar>
                    {rallyPoints.length == 0 ?
                    <p className="no-waypoints-message">Use the tools in the left sidebar to add waypoints</p>
                    : rallyPoints.map((wp, index) => index === 0 ? (
                        <MissionItem missionItem={wp} key={`wp-${wp.id}`} index={index} rallypoint={true}/>
                        ):(
                            <MissionItem 
                                missionItem={wp} key={`wp-${wp.id}`} 
                                index={index} 
                                rallypoint={true}
                                />
                        )
                        )
                    }
                </PerfectScrollbar>
            </div>
        </div>
    )
}
const mqtt = require('mqtt');

class C2Connection {
	constructor({ host, port }, targetUserId, connectingUser) {
			this.host = host;
			this.port = port;
			this.connectingUser = connectingUser;

			this.onDataHandler = null;
			this.onControlMessageHandler = null;
			this.onDisconnectedHandler = null;
			this.onConnectedHandler = null;
			this.targetUserId = targetUserId;
	}

	onMessage(callback) {
			this.onDataHandler = callback;
	}

	onControlMessage(callback) {
		this.onControlMessageHandler = callback;
	}

	onConnected(callback) {
		console.log('Connected!')
		this.onConnectedHandler = callback;
	}

	onSubscribed(callback) {
		this.onSubscribedHandler = callback;
	}

	onDisonnected(callback) {
			this.onDisconnectedHandler = callback;
	}

	async init() {
			this.token = this.c2Token;
	}

	async emit(data) {
		if (!this.connected || !this.subscribed) {
				await this.connect();
		}
		this.client.publish(`to_ac/${this.targetUserId}/m`, data);
}

	disconnect() {
		if (this.subscribed) {
			this.client.unsubscribe(`from_ac/${this.targetUserId}/m`);
			this.client.unsubscribe(`to_ac/${this.targetUserId}/m`);
			console.log('Unsubscribed');
			this.onMessage = null;
		}
		this.client.end();
	}

	async connect(username, password) {
		return new Promise((resolve, reject) => {
			this.init().then(async () => {

				console.log(`Connecting to mqtt, ${this.host}:${this.port}`);

				this.client = mqtt.connect(`${this.host}:${this.port}/mqtt`, {
					username: this.connectingUser ? this.connectingUser.username : null,
					password: this.connectingUser ? this.connectingUser.c2Password : null,
					rejectUnauthorized: true,
					keyPath: __dirname + '/ca.key',
					certPath: __dirname + '/ca.crt',
					ca: __dirname +  '/rootCA.key',
				});

				this.client.on('connect', () => {
					console.log('Connected to MQTT')
					this.connected = true;
					if (this.onSubscribedHandler != null) {
						this.onSubscribedHandler();
					}
		
					this.client.subscribe(`from_ac/${this.targetUserId}/m`, (err) => {
						if (!err) {
							this.subscribed = true;
							resolve();
							console.log('Subscribed to',`from_ac/${this.targetUserId}/m`)
						} else {
							this.subscribed = false;
							console.log(err);
							reject(err);
						}
					});

					this.client.subscribe(`from_ac/${this.targetUserId}/c`, (err) => {
						if (!err) {
							this.subscribed = true;
							resolve();
							console.log('Subscribed to',`from_ac/${this.targetUserId}/c`)
						} else {
							this.subscribed = false;
							console.log(err);
							reject(err);
						}
					});
		
					this.client.on('message', (topic, message) => {
						if (topic === `from_ac/${this.targetUserId}/m`) {
							if (this.onDataHandler) {
								this.onDataHandler(message);
							}
						} else {
							if (this.onControlMessageHandler) {
								this.onControlMessageHandler(message);
							}
						}
					});
				});
			});
		})
	}
}

export default C2Connection;
import React, 
	{ 
		createContext, 
		useContext, 
		useState, 
		useRef, 
		useEffect
	} from "react";
import L from 'leaflet';
import { reshape } from 'mathjs'

import { AircraftContext } from "./aircraft-context";
import { ConfigContext } from "./config-context";

const GeoTIFF = require('geotiff');
const { fromUrl } = GeoTIFF;

export const MapContext = createContext();

const MapContextProvider = ({ children }) => {
	const { 
		mapConfig: {
			mapTileProviders,
			defaultMapCenter, 
			defaultZoom,
		}
	} = useContext(ConfigContext);

	const currentAircraft = useRef(null);
	const { selectedAircraft } = useContext(AircraftContext);

	const movedToAircraftPosition = useRef(false); // REMOVE FOR MULTIPLE AIRCRAFT
	const locationSubscription = useRef(null);

	useEffect(() => {
		if (currentAircraft.current != selectedAircraft) {
			currentAircraft.current = selectedAircraft;
			movedToAircraftPosition.current = false
			if (locationSubscription.current != null) {
				locationSubscription.current();
			}
			if (selectedAircraft != null) {
				locationSubscription.current = selectedAircraft.generalState.subscribe(() => { 
					const aircraftPosition = selectedAircraft.positionState.getState();
					if (aircraftPosition != null && aircraftPosition.latitude != null && aircraftPosition.longitude != null && !movedToAircraftPosition.current) {
						moveToLocation(aircraftPosition);
						movedToAircraftPosition.current = true;
					}
				});
			}
		}
	}, [selectedAircraft]);

	useEffect(() => {
		if (facilityMapVectors.current == null) {
			// console.log('Jeste');
			sampleFaciliyMap()
		}
	})

	// Custom location from search
	const [customLocation, setCustomLocation] = useState(null);
	
	// Facility map polygons
	const [facilityMapPolygons, setFacilityMapPolygons] = useState(null);

	// Current map viewport corner coordinates
	const [currentBounds, setCurrentBounds] = useState(null);
	const [zoom, setZoom] = useState(defaultZoom);
	const mapRef = useRef();

	// Current tool
	const [currentTool, setCurrentTool] = useState('hand-tool');

	// Current tile provider
	const [tileProvider, setTileProvider] = useState(mapTileProviders[0]);

	// Current map center
	const mapCenter = useRef(null);

	// Map overlays
	const [groundRiskPointsVisible, setGroundRiskPointsVisible] = useState(true);
	const [markerLabelsVisible, setMarkerLabelsVisible] = useState(true);
	const [adsbVisible, setAdsbVisible] = useState(false);
	const [facilityMapsVisible, setFacilityMapsVisible] = useState(false);
	const [precipitationVisible, setPrecipitationVisible] = useState(false);
	const [cloudsVisible, setCloudsVisible] = useState(false);

	const facilityMapVectors = useRef(null);

	/**
	 * Set current map bounds and map center
	 * @param {*} bounds 
	 */
	const setBounds = (bounds) => {
		mapCenter.current = {
			latitude: bounds.reduce((prev, curr) => prev + (curr.latitude / 4), 0),
			longitude: bounds.reduce((prev, curr) => prev + (curr.longitude / 4), 0),
		}

		setCurrentBounds(bounds);
	}

	const moveToLocation = ({latitude, longitude}) => {
		if (mapRef.current != null) {
			console.log({latitude, longitude});
			mapRef.current.panTo(new L.LatLng(latitude, longitude));
		}
	}

	const addCustomLocation = ({ latitude, longitude, label, icon }) => {
		console.log({ latitude, longitude, label, icon });
		setCustomLocation({ latitude, longitude, label, icon });
		moveToLocation({ latitude, longitude });
	}

	const clearCustomLocation = () => {
		setCustomLocation(null);
	}

	const sampleFaciliyMap = async () => {
		facilityMapVectors.current = true;
		const url = '/map-layers/oradea_facility_map.tiff';
		const res = await fromUrl(url);
		const img = await res.getImage();
		const rasters = await img.readRasters();
		const [red, green, blue] = rasters;
		
		let data = [];
		let k = 0;
		for (let i = 0; i < img.getHeight() ; i += 1) {
			const row = [];

			for (let j = img.getWidth() - 1; j >= 0 ; j -= 1) {
				row.push(red[k])
				k += 1;
			}

			data = [row, ...data];
		}

		console.log(data);

		const width = img.getWidth();
		const height = img.getHeight();

		const [lon1, lat1, lon2, lat2] = img.getBoundingBox();
		// console.log([lon1, lat1, lon2, lat2]);

		const latitudes = [lat1];
		const longitudes = [lon1];

		const lonSample = (Math.abs(lon1 - lon2)) / width;
		const latSample = (Math.abs(lat1 - lat2)) / height;

		// console.log(latSample, lonSample);

		for (let j = 1; j <= height + 0.00001; j += 1) {
			latitudes.push(latitudes.slice(-1)[0] + latSample);
		}

		for (let i = 1; i <= width + 0.00001; i += 1) {
			longitudes.push(longitudes.slice(-1)[0] + lonSample);
		}

		const polygons = [];

		for (let i = 0; i < latitudes.length - 1; i += 1) {
			for (let j = 0; j < longitudes.length - 1; j += 1) {
				const value = data[i][j];
				if (!isNaN(value)) {
					const polygon = {
						value,
						coordinates: [
							[latitudes[i], longitudes[j]],
							[latitudes[i], longitudes[j + 1] - 0.00000001],
							[latitudes[i + 1] - 0.00000001, longitudes[j + 1] - 0.00000001],
							[latitudes[i + 1] - 0.00000001, longitudes[j]]
						]
					}

					polygons.push(polygon);
				}
			}
		}

		setFacilityMapPolygons(polygons);

		// console.log(img.getBoundingBox());
		// console.log(latitudes, longitudes);
		// .then(img => { console.log(img.getWidth(), img.getHeight()); img.readRasters().then(rasters => { const [red, green, blue] = rasters; console.log(red.length / 98)})}));//console.log(img.getWidth(), img.getHeight(), img.getTileWidth(), img.getTileHeight())));
	}
	
	return (
		<MapContext.Provider value={{
			mapTileProviders,
			currentBounds,
			selectedTileProvider: tileProvider,
			setTileProvider,
			defaultMapCenter,
			defaultZoom,
			setZoom,
			setBounds,
			mapCenter: mapCenter.current,
			zoom,
			currentTool,
			setCurrentTool,
			mapRef,
			moveToLocation,
			groundRiskPointsVisible, 
			setGroundRiskPointsVisible,
			markerLabelsVisible, 
			setMarkerLabelsVisible,
			adsbVisible, 
			setAdsbVisible,
			facilityMapsVisible,
			setFacilityMapsVisible,
			precipitationVisible, 
			setPrecipitationVisible,
			cloudsVisible, 
			setCloudsVisible,
			addCustomLocation,
			clearCustomLocation,
			customLocation,
			facilityMapPolygons
		}}>
		{ children }

		</MapContext.Provider>
	)
}

export default MapContextProvider;
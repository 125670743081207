import { Adjust, MyLocation, Person } from '@mui/icons-material';
import React, { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { AircraftContext } from '../../../../contexts/aircraft-context';
import { useNavigate } from 'react-router-dom';

import Icons from '../../../../helpers/icons';
import { MapContext } from '../../../../contexts/map-context';

export const CenterAircraft = () => {
    const location = useLocation();
    const { selectedAircraft } = useContext(AircraftContext);
    console.log("Selected aircraft",selectedAircraft);
    const navigate = useNavigate();
    const { moveToLocation } = useContext(MapContext);

    return(
        <div className='center-aircraft-wrap'>

           { selectedAircraft &&
            
             <div className='center-aircraft' onClick={() => 
                {  
                    
                    moveToLocation(
                        selectedAircraft.positionState.getState() != null && selectedAircraft.positionState.getState()
                );
                
            }}>
                   <MyLocation/>
            </div>
                  
            }
           
        </div>
    )
}
import React,{useContext, useState, useRef} from 'react';

import { Stack } from '../../../../../../shared-components/layout/stack/stack';
import { SelectInput } from '../../../../../../shared-components/inputs/select-input/select-input';
import { FleetManagementContext } from '../../../../../../contexts/fleet-management-context';
import { AircraftContext } from '../../../../../../contexts/aircraft-context';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Checkbox } from '@mui/material';
import { ConfigContext } from '../../../../../../contexts/config-context';
import { OutlineButton } from '../../../../../../shared-components/buttons/outline-button/outline-button';
import { Info } from '@mui/icons-material';
const { SerialPort } = require('browser-serialport');


export const PreflightCheck = ({ onClose }) => {
    const { allAircraft, allAircraftConnections } = useContext(FleetManagementContext);
    const { logs, toggleLogs } = useContext(ConfigContext);

    // const [availableAircraft, setAvailableAircraft] = useState([allAircraft ? allAircraft.map(ac => ({...ac, connectionType: 'LTE'})) : []]);
    const { connectToAircraft, connectedAircraft } = useContext(AircraftContext);

    const navigate = useNavigate();
    const [selectedAircraft, setSelectedAircraft] = useState(0);
    const [connectionInProgress, setConnectionInProgress] = useState(false);
    const [isConnected, setIsConnected] = useState(false);

    const connectAircraft = () => {
        const aircraft = allAircraftConnections[selectedAircraft];
        setConnectionInProgress(true);

        console.log(aircraft);
        connectToAircraft(aircraft.key, aircraft.name, aircraft.connectionType || 'LTE', navigate)
            .then(() => {
                setIsConnected(true);
                setTimeout(() => onClose(), 1000)
                console.log(connectedAircraft);
            }).finally(() => setConnectionInProgress(false));
    }

    const connectToSerial = async () => {
        connectToAircraft('serial', 'Serial connection', 'SERIAL', navigate)
        .then(() => {
            setIsConnected(true);
            setTimeout(() => onClose(), 1000)
            console.log(connectedAircraft);
        }).catch((err) => {
            console.log(err);
            toast.error('Serial not connected');
        }).finally(() => setConnectionInProgress(false));
    }

    // SerialPort.list((err, ports)=>{
    //     ports.forEach((ports)=>{
    //       console.log(ports);
    //     });
    //   });
    // console.log(SerialPort);


    // console.log(allAircraft ? allAircraft.map(ac => ({ id: ac.id, apiKey: ac.apiKey, name: ac.name })) : []);

    return(
        <div className='preflight-check-wrap'>
            <Stack 
                className='preflight-panel-items-wrap'
                direction='vertical'
                valign='start'
                halign='start'>
                    
                    <div className='preflight-panel-content'>
                        
                        <SelectInput 
                            options={allAircraftConnections ? allAircraftConnections.map((ac, index) => ({ value: index, name: ac.name })).filter(ac => !connectedAircraft.find(conAcc => conAcc.aircraftName == ac.name)) : []} 
                            label='Available Aircraft' 
                            onChange={(e)=>setSelectedAircraft(e.target.value)} 
                            width="100%"/>
                        {/* <Stack direction='vertical' halign='start' valign='end' style={{width:"100%"}}>
                            
                            
                            <Stack className='preflight-cancel-wrap' direction='horizontal' halign='end' valign='center' onClick={()=>setSelectedAircraft(0)}>
                            
                                <CloseOutlined className='red'/>
                                <p className='uppercase'>Cancel</p>
                            
                            </Stack>
                            
                        </Stack> */}
                    </div>
                    <div className='preflight-check-connect-wrap'>
                        <div className={`preflight-check-connect ${isConnected ? 'connected' : connectionInProgress ? 'glow-yellow' : ''}`}
                            onClick={()=>connectAircraft()}>
                            
                                <p className='uppercase'>{ isConnected ? 'Connected' : connectionInProgress ? 'Connecting' : 'Connect' }</p>
                            
                        </div>
                    </div>
                    {/* <div className='serial-connection-wrap'>
                            <div className='serial-logs-wrap'> 
                                <span>Logs
                                     {/* <Info /> */}
                                     {/* </span>
                                <Checkbox onClick={() => toggleLogs()} checked={logs}/>
                            </div>
                            <OutlineButton className="pointer" onClick={() => connectToSerial()} title='Serial Connection'/> */}
                            {/* { console.log(logs) } */}
                          
                    {/* </div>  */}
            </Stack>
        </div>
    )
}
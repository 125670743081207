export default (send, mavlinkService, targetSystem, targetComponent) => ({
	heartbeat() {
		const message = mavlinkService.createHeartbeatMessage(targetSystem, targetComponent);
		send(message);
	},

	armVehicle(confirmation = 0) {
		if (targetSystem != null && targetComponent != null) {
			const message = mavlinkService.createArmVehicleMessage(targetSystem, targetComponent.toExponential, confirmation);
			send(message);
		}
	},

	disarmVehicle(confirmation = 0) {
		if (targetSystem != null && targetComponent != null) {
			const message = mavlinkService.createDisarmVehicleMessage(targetSystem, targetComponent, confirmation);
			send(message);
		}
	},

	setFlightMode(mode, confirmation) {
		if (targetSystem != null && targetComponent != null) {
			const message = mavlinkService.createSetFlightModeMessage(targetSystem, targetComponent, mode, confirmation);
			send(message);
		}
	},

	writePartialWaypointsList(startIndex, endIndex) {
		if (targetSystem != null && targetComponent != null) {
			const message = mavlinkService.createWritePartialWaypointListMessage(this.targetSystem, this.targetComponent, startIndex, endIndex);
			send(message);
		}
	},

	missionCount(numItems, missionType) {
		if (targetSystem != null && targetComponent != null) {
			const message = mavlinkService.createMissionCountMessage(targetSystem, targetComponent, numItems, missionType);
			send(message);
		}
	},

	writeMissionItem(seq, latitude, longitude, altitude, speed, action, missionType, radius) {
		if (targetSystem != null && targetComponent != null) {
			let message = null;
			if (missionType == 'mission') {
				message = mavlinkService.createWriteMissionItemMessage(targetSystem, targetComponent, seq, latitude, longitude, altitude, speed || 0, action || 'goto', radius);
			} else if (missionType == 'rally') {
				message = mavlinkService.createWriteRtlItemMessage(targetSystem, targetComponent, seq, latitude, longitude, altitude);
			}
			send(message);
		}
	},

	announceMissionItems(startIndex, endIndex, missionType) {
		if (targetSystem != null && targetComponent != null) {
			let message = null;
			if (missionType == 'mission') {
				message = mavlinkService.createWritePartialWaypointListMessage(targetSystem, targetComponent, startIndex, endIndex)
			} else if (missionType == 'rally') {
				message = mavlinkService.createWritePartialRtlListMessage(targetSystem, targetComponent, startIndex, endIndex)
			}
			send(message);
		}
	},

	requestMission(missionType) {
		if (targetSystem != null && targetComponent != null) {
			let message = null;
			if (missionType == 'mission') {
				message = mavlinkService.createRequestWaypointsMessage(targetSystem, targetComponent);
			}
			if (missionType == 'rally') {
				message = mavlinkService.createRequestRallyPointsMessage(targetSystem, targetComponent);
			}
			send(message);
		}
	},

	requestMissionItem(seq, missionType) {
		if (targetSystem != null && targetComponent != null) {
			let message = null;
			if (missionType == 'mission') {
				message = mavlinkService.createRequestSingleWaypointMessage(targetSystem, targetComponent, seq);
			}
			if (missionType == 'rally') {
				message = mavlinkService.createRequestSingleRallyPointMessage(targetSystem, targetComponent, seq);
			}
			send(message);
		}
	},

	startMission(startWaypoint, lastWaypoint) {
		if (targetSystem != null && targetComponent != null) {
			const message = mavlinkService.createStartMission(targetSystem, targetComponent, startWaypoint, lastWaypoint);
			send(message);
		}
	},

	takeOff(latitude, longitude, altitude) {
		if (targetSystem != null && targetComponent != null) {
			const message = mavlinkService.createTakeOffMessage(this.targetSystem, this.targetComponent, latitude, longitude, altitude);
			send(message);
		}
	},

	land(latitude = 0, longitude = 0, altitude = 0) {
		if (targetSystem != null && targetComponent != null) {
			const message = mavlinkService.createLandMessage(targetSystem, targetComponent, latitude, longitude, altitude);
			send(message);
		}
	},

	clearAllWaypoints() {
		if (targetSystem != null && targetComponent != null) {
			const message = mavlinkService.createClearAllWaypointsMessage(targetSystem, targetComponent);
			send(message);
		}
	},

	clearAllRtl() {
		if (targetSystem != null && targetComponent != null) {
			const message = mavlinkService.createClearAllRtlMessage(targetSystem, targetComponent);
			send(message);
		}
	},

	setMissionCurrent(seq) {
		if (targetSystem != null && targetComponent != null) {
			const message = mavlinkService.createSetMissionCurrent(targetSystem, targetComponent, seq);
			send(message);
		}
	},

	gotoWaypoint(time_boot_ms, latitude, longitude, altitude, yaw) {
		if (targetSystem != null && targetComponent != null) {
			const message = mavlinkService.createGotoWaypoint(targetSystem, targetComponent, time_boot_ms, latitude, longitude, altitude, yaw);
			send(message);
		}
	},

	setYaw(angle, direction) {
		if (targetSystem != null && targetComponent != null) {
			const message = mavlinkService.createSetYawMessage(targetSystem, targetComponent, angle, direction);
			send(message);
		}
	},

	setAltitude(altitude) {
		if (targetSystem != null && targetComponent != null) {
			const message = mavlinkService.createSetAltitudeMessage(targetSystem, targetComponent, altitude);
			send(message);
		}
	},

	setSpeed(speed) {
		if (targetSystem != null && targetComponent != null) {
			const message = mavlinkService.createSetSpeedMessage(targetSystem, targetComponent, speed);
			send(message);
		}
	},

	moveForward(time_boot_ms, speed) {
		if (targetSystem != null && targetComponent != null) {
			const message = mavlinkService.createMoveForward(targetSystem, targetComponent, time_boot_ms, speed);
			send(message);
		}
},

	setAmslAltitude(time_boot_ms, latitude, longitude, amslAltitude) {
		if (targetSystem != null && targetComponent != null) {
			const message = mavlinkService.createSetAmslAltitude(targetSystem, targetComponent, time_boot_ms, latitude, longitude, amslAltitude);
			send(message);
		}
	},
	
	orbit(latitude, longitude, altitude, radius) {
		if (targetSystem != null && targetComponent != null) {
			const message = mavlinkService.createOrbit(targetSystem, targetComponent, latitude, longitude, altitude, radius);
			send(message);
		}
	}
})
import { ToggleOff, ToggleOn } from '@mui/icons-material';
import React, {useEffect, useState} from 'react';


export const ToggleButton = ({ label, defaultState, onChange, className }) => {
    const [toggleActive, setToggleActive] = useState(defaultState);

    useEffect(() => {
        if (defaultState != toggleActive) {
            setToggleActive(defaultState);
        }
    })

    const toggleState = (newState) => {
        onChange && onChange(newState);
        setToggleActive(newState);
    }

    return(
        <div className={`toggle-button-wrap ${className && className}`} >
            <p className='uppercase'>{label}</p>
           
                {toggleActive ? 
                <ToggleOn onClick={()=>toggleState(false)} className='toggle-on'/> : <ToggleOff onClick={()=> toggleState(true)}/>
                }
          
            
        </div>
    )
}
import { CheckBox, CheckBoxOutlineBlank } from '@mui/icons-material';
import React, { useState } from 'react';
import { Stack } from '../../layout/stack/stack';


export const OptionsCheckbox = ({ options, title, className, optionSelected, onChange, emptyListMessage }) => {
    return(
        <div className={`options-checkbox-wrap ${className}`}>
            {title && <p className='options-checkbox-title'>{title}</p>}
            {options && 
                options.length > 0 ?
                    options.map ((option, index) => (
                    <Stack
                        key={`option-checkbox-${index}`}
                        className={`option-checkbox-wrap ${optionSelected == option && 'active'}`}
                        direction='horizontal' 
                        halign='start'
                        valign='center' 
                        onClick= {() => onChange && onChange(option)} 
                        // setSelectedOption(option.value); console.log(optionSelected, option.value);}}
                        fullsize
                    >
                            {optionSelected == option ? <CheckBox/> : <CheckBoxOutlineBlank/> }
                            <p>{option.title}</p>
                    </Stack>
            ))
            :
                emptyListMessage || 'No items found'
            }
        </div>
        
    )
}
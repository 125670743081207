import React, { useContext, useState } from 'react';
import { FleetManagementContext } from '../../../../contexts/fleet-management-context';
import UtilityService from '../../../../helpers/utility-service';
import { FleetManagementTableContent } from '../fleet-management-table-content/fleet-management-table-content';
import { FMDashboardPanel } from '../fm-dashboard-panel/fm-dashboard-panel';
// import fleetManagementService from "../../../services/fleet-management-service";
import {maintenanceSchema} from '../../../../contexts/schemas/maintenance-schema';



export const FMMaintenancePanel = ({}) => {
    const {maintenance} = useContext(FleetManagementContext);
	const [maintenanceFilter, setMaintenanceFilter] = useState("");

    return(
         /* MAINTENANCE  */
        <div className="fleet-management-card-wrap">
            <FMDashboardPanel 
                className='fleet-management-card tabbed-card' 
                onFilter={(filter) => setMaintenanceFilter(filter)}  
                dashboardPanelToolbar={true}
                title={maintenanceSchema.entityName}
                entityName={maintenanceSchema.entityName}
                schema={maintenanceSchema }
                createItem={()=>{}}
                // {async (data) => {
                //     await fleetManagementService.createMaintenance(data);
                //     maintenanceTableRef.current.loadData();  
                // }}
            >
                <FleetManagementTableContent    
                    // ref={maintenanceTableRef}
                    data={maintenance}
                    loadData={()=>{}}//{() => fleetManagementService.fetchAllMaintenance()}
                    loadSingleItem={()=>{}}//{(id) => fleetManagementService.fetchSingleMaintenance(id)}
                    updateSingleItem={()=>{}}//{(id, updateObj) => fleetManagementService.updateMaintenance(id, updateObj)}
                    deleteSingleItem={()=>{}}//{(id) => fleetManagementService.deleteMaintenance(id)}
                    getTitle={(item) => item.type}
                    schema = {maintenanceSchema}
                    filter = {maintenanceFilter}
                    filterFunction = {(row, filter)=>UtilityService.isRowFiltered(row,filter)}
                    calcCycleColor = {(cycles)=>UtilityService.calcCycleColor(cycles)}
                />  
            </FMDashboardPanel>
        </div> 
    )
}
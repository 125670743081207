import React, { useContext, useRef, useState } from 'react';
import { MissionContext } from '../../../../contexts/mission-context';
import { FileDialog } from '../../../../shared-components/containers/file-dialog/file-dialog';
import { InputField } from '../../../../shared-components/inputs/input-field/input-field';
import { missionSchema } from '../../../../contexts/schemas/mission-schema';
import { FleetManagementEditModal } from '../../../fleet-management-screen/components/fleet-management-edit-modal/fleet-management-edit-modal';
import UtilityService from '../../../../helpers/utility-service';
import { MissionSetup } from '../schedule-flight/components/mission-setup/mission-setup';
import { FleetManagementContext } from '../../../../contexts/fleet-management-context';
import { DataGrid } from '@mui/x-data-grid';

export const FlightLogs = ({className, flights}) =>{
    const {loadMissionPlan } = useContext(MissionContext);
    const [filter, setFilter] = useState('');
    const { allAircraft, personnel } = useContext(FleetManagementContext);
    
    // const filteredFlights = flights.filter(flight => flight.operation.toLowerCase().includes(filter.toLowerCase()));
    const [modalVisible, setModalVisible] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const additionalTabs = [{
         title: "Flight history",
        component: ""
    },{
        title: "Mission Details",
       component: <MissionSetup/>,
   }];
    const getTitle = (item) => {
        return item.title;
    }
  
    const columns = [
        { field: 'id',  flex:1,headerName: 'ID', width: 70 },
        { field: 'missionPlan',  flex:1,headerName: 'Mission Plan', width: 130 },
        { field: 'missionStatus',  flex:1,headerName: 'Status', width: 130 },
         { field: 'missionStartTime', flex:1, headerName: 'Start Time', width: 130 },
         { field: 'missionEndTime', flex:1, headerName: 'End Time', width: 130 },
        { field: 'operator',  flex:1,headerName: 'Operator', width: 130 },
        { field: 'aircraft', flex:1, headerName: 'Aircraft', width: 130 },
        { field: 'authorization', flex:1, headerName: 'Authorization', width: 130 },
        // { field: 'actions', headerName: 'Actions', width: 130 },
      ];

    //   console.log(flights)

      const rows = (flights || []).map(fl => {
        return ({ id: 1, 
        missionPlan: fl.data.missionPlan.title, 
        missionStatus: fl.status, 
        missionStartTime:fl.data.startTime.replace('T',' '), 
        missionEndTime:fl.data.endTime.replace('T',' '), 
        operator:personnel.find(p => p.id === fl.pilotId) ? `${personnel.find(p => p.id === fl.pilotId).data.firstName} ${personnel.find(p => p.id === fl.pilotId).data.lastName}` : fl.pilotId,
        aircraft:allAircraft.find(ac => ac.id === fl.aircraftId) ? allAircraft.find(ac => ac.id === fl.aircraftId).data.name : fl.aircraftId ,
        authorization: fl.isAuthorized == null ? 'Waiting for authorization' : fl.isAuthorized ? 'Authorized' : 'Not authorized' ,
        // actions: "Details" 
    })});
  
      const onRowClicked = (row) => {
        setModalVisible(true); 
        setSelectedItem(row);
      }
  
    return(
        <div className={`flight-logs-wrap ${className && className}`}>
             {modalVisible && 
                  <FleetManagementEditModal
                        modalVisible={modalVisible}
                            schema={missionSchema}
                            data={selectedItem}
                            itemId={selectedItem.id}
                            onClose={() => {setModalVisible(false); setSelectedItem(null)}}
                            loadSingleItem={() => selectedItem}
                            title={getTitle(selectedItem)}
                            entityName={missionSchema.entityName}
                            transformObject={(obj) => UtilityService.transformObject(obj, missionSchema.attributes)}
                            additionalTabs={additionalTabs || []}
            
                        />}
            {/* <InputField 
                className="filter-flights" 
                placeholder="Filter flights by operation"
                width={200}
                onChange={(e)=> setFilter(e.target.value)}
            /> */}


    

        <div className='fm-flightlogs-panel'>
            <DataGrid
                className='flight-logs-table'
                rows={rows}
                columns={columns}
                pageSize={5}
                rowsPerPageOptions={[5]}
                // checkboxSelection
                style={{width: '100%', height:"100%", color: "white", borderRadius: "20px"}}
                onRowClick={(row) => onRowClicked(row)}
            />
           
        </div>
{/*     
            <table className="flights-table">
                <thead>
                    <tr>
                        <th>Mission Plan</th>
                        <th>Status</th>
                        <th>Start time</th>
                        <th>End time</th>
                        <th>Operator</th>
                        <th>Aircraft</th>
                        <th>Authorization</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        // Replace TRUE value !!!
                        true? flights.map(fl => (
                            <tr>
                                <td>{fl.data.missionPlan.title}</td>
                                <td>{fl.status}</td>
                                <td>{fl.data.startTime.replace('T',' ')}</td>
                                <td>{fl.data.endTime.replace('T',' ')}</td>
                                <td>{personnel.find(p => p.id === fl.pilotId) ? `${personnel.find(p => p.id === fl.pilotId).data.firstName} ${personnel.find(p => p.id === fl.pilotId).data.lastName}` : fl.pilotId}</td>
                                <td>{allAircraft.find(ac => ac.id === fl.aircraftId) ? allAircraft.find(ac => ac.id === fl.aircraftId).data.name : fl.aircraftId}</td>
                                <td>{fl.isAuthorized == null ? 'Waiting for authorization' : fl.isAuthorized ? 'Authorized' : 'Not authorized'}</td>
                                <td><a href="#" onClick={() => {setModalVisible(true);setSelectedItem(fl);}}>Details</a></td>
                            </tr> 
                        )) : [].map(fl=> (
                            <tr>
                                <td>{fl.data.missionPlan.name}</td>
                                <td>{fl.status}</td>
                                <td>{fl.startTime}</td>
                                <td>{fl.endTime}</td>
                                <td>{fl.pilotId}</td>
                                <td>{fl.aircraftId}</td>
                                <td>{fl.isAuthorized == null ? 'Waiting for authorization' : fl.isAuthorized ? 'Authorized' : 'Not authorized'}</td>
                                <td><a href="#" onClick={() => {setModalVisible(true); setSelectedItem(fl);}}>Details</a></td>
                            </tr> 
                        ))
                    }
                </tbody>
            </table> */}
        </div>
        
    )
}
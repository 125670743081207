import React from 'react';

import { DataGrid } from '@mui/x-data-grid';
import { flightlogSchema } from '../../../../contexts/schemas/flightlog-schema';

export const FMFlightLogsPanel = ({filter, onRowClicked}) => {
    // const StyledTableCell = styled(TableCell)(({ theme }) => ({
    //     [`&.${tableCellClasses.head}`]: {
    //       backgroundColor: theme.palette.common.black,
    //       color: theme.palette.common.white,
    //     },
    //     [`&.${tableCellClasses.body}`]: {
    //       fontSize: 14,
    //     },
    //   }));
      
    //   const StyledTableRow = styled(TableRow)(({ theme }) => ({
    //     '&:nth-of-type(odd)': {
    //       backgroundColor: theme.palette.action.hover,
    //     },
    //     // hide last border
    //     '&:last-child td, &:last-child th': {
    //       border: 0,
    //     },
    //   }));
    
    const columns = [
      { field: 'id', headerName: 'ID', width: 70 },
      { field: 'flightTime', headerName: 'Time', width: 130 },
      { field: 'flightStartTime', headerName: 'Start Time', width: 130 },
       { field: 'flightEndTime', headerName: 'End Time', width: 130 },
      { field: 'date', headerName: 'Date', width: 130 },
      { field: 'startLocation', headerName: 'Start Location', width: 130 },
      { field: 'endLocation', headerName: 'End Location', width: 130 },
      { field: 'maxAltitude', headerName: 'Max Altitude', width: 130 },
      { field: 'speedKMh', headerName: 'Speed (Km/h)', width: 130 },
      { field: 'speedMs', headerName: 'Speed (m/s)', width: 130 },
      { field: 'speedMPh', headerName: 'Speed (Mp/h)', width: 130 },
      { field: 'batteryStartV', headerName: 'Battery Start Voltage', width: 130 },
      { field: 'batterySagV', headerName: 'Battery Sag Voltage', width: 130 },
      { field: 'batteryEndV', headerName: 'Battery End Voltage', width: 130 },
      { field: 'checklistCompletePre', headerName: 'Preflight Cheklist', width: 130 },
      { field: 'checklistCompletePost', headerName: 'Postflight Cheklist', width: 130 },
      
    ];
    
    const rows = [
      { id: 1, flightTime: '20:20', flightStartTime: '11:20:20', flightEndTime:'06:40:20', date:"02/02/2022", startLocation:"", maxAltitude: "20" },
    
    ];

    const handleRowClicked = (row) => {
        onRowClicked(row);
    }


    
    return(
        <div className='fm-flightlogs-panel'>
            <DataGrid
                className='fm-flightlogs-table'
                rows={rows}
                columns={columns}
                pageSize={5}
                rowsPerPageOptions={[5]}
                // checkboxSelection
                style={{width: '100%', height:"100%", color: "white", borderRadius: "20px"}}
                onRowClick={(row) => handleRowClicked(row.row)}
            />
           
        </div>
    )
}
import V2 from './mavlink-v2';
import V1 from './mavlink-v1';
import MAVLinkConstants from './mavlink-constants';
import React from "react";

const { mavlink20, MAVLink20Processor } = V2;
const { mavlink10, MAVLink10Processor } = V1;

const NAN = 0xffff;

class MAVLinkService {
	constructor(mavlinkVersion=1) {
		this.setVersion(mavlinkVersion);
		this.setSendingVersion(mavlinkVersion);
	}

	setSendingVersion(version) {
		const seq = this.mavlink ? this.mavlink.seq : 0;
		if (version == 1) {
			this.mavlink = mavlink10;
		} else {
			this.mavlink = mavlink20;
		}
		this.mavlink.seq = seq;
		this.mavlink.srcSystem = 254;
		this.mavlink.srcComponent = 200;
	}

	setVersion(mavlinkVersion) {
		if (mavlinkVersion == 1) {
			this.mavlinkVersion = 1;
			// this.mavlink = mavlink10;
			this.mav = new MAVLink10Processor(null, 255, 0);
		} else {
			this.mavlinkVersion = 2;
			// this.mavlink = mavlink20;
			this.mav = new MAVLink20Processor(null, 255, 0);
		}
	}

	switchVersion() {
		const currentVersion = this.mavlinkVersion;
		const newVersion = 1 + currentVersion % 2;
		this.setVersion(newVersion);
	}

	// setTargetSystemAndComponent(targetSystem, targetComponent) {
	// 	this.targetSystem = targetSystem;
	// 	this.targetComponent = targetComponent
	// }

	// create HEARTBEAT message
	createHeartbeatMessage() {
		const msg = new this.mavlink.messages.heartbeat(this.mavlink.messages.MAV_TYPE_GCS, this.mavlink.messages.MAV_AUTOPILOT_ARDUPILOTMEGA);
		return msg.pack(this.mavlink);
	}

	// Request protocol version
	createRequestProtocolVersionMessage(targetSystem, targetComponent) {
		const msg = new mavlink10.messages.command_long(targetSystem, targetComponent, this.mavlink.MAV_CMD_REQUEST_PROTOCOL_VERSION, 1);
    return msg.pack(mavlink10);
	}

	createRequestAutopilotVersionMessage(targetSystem, targetComponent) {
		const msg = new this.mavlink.messages.autopilot_version_request(targetSystem, targetComponent);
		return msg.pack(this.mavlink);
	}

	// create ARM message
	 createArmVehicleMessage(targetSystem, targetComponent, confirmation=0) {
		const msg = new this.mavlink.messages.command_long(targetSystem, targetComponent, this.mavlink.MAV_CMD_COMPONENT_ARM_DISARM, confirmation, 1, 0, 0, 0, 0, 0, 0);
    return msg.pack(this.mavlink);
	}

	// create DISARM message
	 createDisarmVehicleMessage(targetSystem, targetComponent, confirmation=0) {
		var msg = new this.mavlink.messages.command_long(targetSystem, targetComponent, this.mavlink.MAV_CMD_COMPONENT_ARM_DISARM, confirmation, 0, 0, 0, 0, 0, 0, 0);
    return msg.pack(this.mavlink);
	}

	// create REQUEST WAYPOINTS message
	 createRequestWaypointsMessage(targetSystem, targetComponent) {
		let msg = null;
		if (this.mavlinkVersion == 2) {
			msg = new this.mavlink.messages.mission_request_list(targetSystem, targetComponent, this.mavlink.MAV_MISSION_TYPE_MISSION);
		} else {
			msg = new this.mavlink.messages.mission_request_list(targetSystem, targetComponent);
		}
    return msg.pack(this.mavlink);
	}

	// create REQUEST RALLY POINTS message
	 createRequestRallyPointsMessage(targetSystem, targetComponent) {
		const msg = new this.mavlink.messages.mission_request_list(targetSystem, targetComponent, this.mavlink.MAV_MISSION_TYPE_RALLY);
    return msg.pack(this.mavlink);
	}

	// create vertical DO_CHANGE_SPEED message
	createSetVerticalSpeedMessage(targetSystem, targetComponent, speed) {
		const msg = new this.mavlink.messages.command_long(targetSystem, targetComponent, this.mavlink.MAV_CMD_DO_CHANGE_SPEED, 0, speed < 0 ? 3 : 2, speed, -1 ,-1, 0);
    return msg.pack(this.mavlink);
	}

	// create MISSION_SET_CURRENT message
	createSetCurrentItem(targetSystem, targetComponent, seq) {
		const msg = new this.mavlink.messages.mission_set_current(targetSystem, targetComponent, seq);
		return msg.pack(this.mavlink);
	}

	// create CLEAR WAYPOINTS message
	 createClearAllWaypointsMessage(targetSystem, targetComponent) {
		const msg = new this.mavlink.messages.mission_clear_all(targetSystem, targetComponent);
    return msg.pack(this.mavlink);
	}

	// create CLEAR RTL message
	 createClearAllRtlMessage(targetSystem, targetComponent) {
		const msg = new this.mavlink.messages.mission_clear_all(targetSystem, targetComponent, this.mavlink.MAV_MISSION_TYPE_RALLY);
    return msg.pack(this.mavlink);
	}

	// SET_HOME_POSITION
	createSetHomePositionMessage(targetSystem, targetComponent, latitude, longitude, altitude) {
		const msg = new this.mavlink.messages.set_home_position(targetSystem, Math.round(latitude*10000000), Math.round(longitude*10000000), Math.round(altitude * 1000), 0, 0, 0, [1,0,0,0], 0, 0, 0, Math.trunc(new Date().getTime() / 1000));
    return msg.pack(this.mavlink);
	}

	clearAllMissionItems(targetSystem, targetComponent) {
		const msg = new this.mavlink.messages.mission_clear_all(targetSystem, targetComponent);
		return msg.pack(this.mavlink);
	}

	// create REQUEST SINGLE RALLY POINT message
	 createRequestSingleRallyPointMessage(targetSystem, targetComponent, seq) {
		const msg = new this.mavlink.messages.mission_request_int(targetSystem, targetComponent, seq, this.mavlink.MAV_MISSION_TYPE_RALLY);
    return msg.pack(this.mavlink);
	}

	 createRequestSingleWaypointMessage(targetSystem, targetComponent, seq) {
		let msg = null;
		if (this.mavlinkVersion == 2) {
			msg = new this.mavlink.messages.mission_request_int(targetSystem, targetComponent, seq, this.mavlink.MAV_MISSION_TYPE_MISSION);
		} else {
			msg = new this.mavlink.messages.mission_request_int(targetSystem, targetComponent, seq);
		}
    return msg.pack(this.mavlink);
	}

	 createTakeOffMessage(targetSystem, targetComponent, latitude, longitude, altitude) {
		const msg = new this.mavlink.messages.command_long(targetSystem, targetComponent, this.mavlink.MAV_CMD_NAV_TAKEOFF, 0, 0, 0, 0, 0, latitude, longitude, altitude);
		// [ this.target_system , this.target_component , this.command , this.confirmation , this.param1 , this.param2 , this.param3 , this.param4 , this.param5 , this.param6 , this.param7 ] 
		// const msg = new this.mavlink.messages.command_long(targetSystem, targetComponent, this.mavlink.MAV_CMD_NAV_TAKEOFF_LOCAL, 0, 0, 0, 1, 0, 0, 0, 10);
		return msg.pack(this.mavlink);
	}

	 createLandMessage(targetSystem, targetComponent, latitude, longitude, altitude) {
		const msg = new this.mavlink.messages.command_long(targetSystem, targetComponent, this.mavlink.MAV_CMD_NAV_LAND, 0, 0, 1, 0, 0, latitude, longitude, altitude);
		return msg.pack(this.mavlink);
	}

	 createRequestWaypoints(targetSystem, targetComponent) {
		const msg = new this.mavlink.messages.mission_request_list(targetSystem, targetComponent, this.mavlink.MISSION_TYPE_MISSION);
		return msg.pack(this.mavlink);
	}

	 createStartMission(targetSystem, targetComponent, firstWaypoint, lastWaypoint) {
		const msg = new this.mavlink.messages.command_long(targetSystem, targetComponent, this.mavlink.MAV_CMD_MISSION_START, 0, firstWaypoint, lastWaypoint, NAN, NAN, NAN, NAN, NAN);
		return msg.pack(this.mavlink);
	}

	createGetHomePosition(targetSystem, targetComponent) {
		const msg = new this.mavlink.messages.command_long(targetSystem, targetComponent, this.mavlink.MAV_CMD_GET_HOME_POSITION,NAN,NAN,NAN,NAN,NAN,NAN,NAN);
		return msg.pack(this.mavlink);
	}


	 createGotoWaypoint(targetSystem, targetComponent, time_boot_ms, latitude, longitude, altitude) {
		// const msgPartialList = new this.mavlink.messages.mission_write_partial_list(targetSystem, targetComponent, wpNumber, wpNumber, this.mavlink.MISSION_TYPE_MISSION);
		// let msgMissionItem = null;
		// if (this.mavlinkVersion == 2) {
		// 	msgMissionItem = new this.mavlink.messages.mission_item_int(targetSystem, targetComponent, wpNumber, this.mavlink.MAV_FRAME_GLOBAL_RELATIVE_ALT, this.mavlink.MAV_CMD_NAV_WAYPOINT, 2, 1, 0, 0, 0, 0, latitude * 10000000, longitude * 10000000, altitude, this.mavlink.MAV_MISSION_TYPE_MISSION);
		// } else {
		// 	msgMissionItem = new this.mavlink.messages.mission_item_int(targetSystem, targetComponent, wpNumber, this.mavlink.MAV_FRAME_GLOBAL_RELATIVE_ALT, this.mavlink.MAV_CMD_NAV_WAYPOINT, 2, 1, 0, 0, 0, 0, latitude * 10000000, longitude * 10000000, altitude);
		// }
		// return msgMissionItem.pack(this.mavlink);

		// target_component          : Component ID (uint8_t)
		// seq                       : Sequence (uint16_t)
		// frame                     : The coordinate system of the waypoint. (uint8_t)
		// command                   : The scheduled action for the waypoint. (uint16_t)
		// current                   : false:0, true:1 (uint8_t)
		// autocontinue              : Autocontinue to next waypoint (uint8_t)
		// param1                    : PARAM1, see MAV_CMD enum (float)
		// param2                    : PARAM2, see MAV_CMD enum (float)
		// param3                    : PARAM3, see MAV_CMD enum (float)
		// param4                    : PARAM4, see MAV_CMD enum (float)
		// x                         : PARAM5 / local: X coordinate, global: latitude (float)
		// y                         : PARAM6 / local: Y coordinate, global: longitude (float)
		// z                         : PARAM7 / local: Z coordinate, global: altitude (relative or absolute, depending on frame). (float)
		// mission_type              : Mission type. (uint8_t)

		// const msg = new this.mavlink.messages.command_long(
		// 	targetSystem, 
		// 	targetComponent, 
		// 	this.mavlink.message.MAV_CMD_OVERRIDE_GOTO, 
		// 	0, 
		// 	this.mavlink.messages.MAV_GOTO_DO_CONTINUE, 
		// 	this.mavlink.messages.MAV_GOTO_HOLD_AT_SPECIFIED_POSITION,
		// 	this.mavlink.messages.MAV_GOTO_HOLD_AT_SPECIFIED_POSITION,
		// 	this.mavlink.messages.MAV_FRAME_GLOBAL_RELATIVE_ALT_INT,
		// 	latitude * 10000000, 
		// 	longitude * 10000000, 
		// 	altitude,
		// );

		const msg = new this.mavlink.messages.set_position_target_global_int(
			time_boot_ms, 
			targetSystem, 
			targetComponent,
			this.mavlink.MAV_FRAME_GLOBAL_RELATIVE_ALT_INT,
			4088,
			parseInt(latitude * 10000000), 
			parseInt(longitude * 10000000), 
			altitude,
			null,
			null,
			null,
			null,
			null,
			null,
			null,
			null
		)

		console.log(time_boot_ms, 
			targetSystem, 
			targetComponent,
			this.mavlink.MAV_FRAME_GLOBAL_RELATIVE_ALT_INT,
			4088,
			parseInt(latitude * 10000000), 
			parseInt(longitude * 10000000), 
			altitude,
			null,
			null,
			null,
			null,
			null,
			null,
			null,
			null);

		console.log(msg.pack(this.mavlink));
		return msg.pack(this.mavlink);
	}

	createSetAmslAltitude(targetSystem, targetComponent, time_boot_ms, latitude, longitude, amslAltitude) {
		const msg = new this.mavlink.messages.set_position_target_global_int(
			time_boot_ms, 
			targetSystem, 
			targetComponent,
			this.mavlink.MAV_FRAME_GLOBAL_INT,
			2040,
			parseInt(latitude * 10000000), 
			parseInt(longitude * 10000000), 
			amslAltitude,
			null,
			null,
			null,
			null,
			null,
			null,
			null,
			0
		)
		console.log(msg.pack(this.mavlink));
		return msg.pack(this.mavlink);
	}

	createMoveForward(targetSystem, targetComponent, time_boot_ms, speed) {
		// message SET_POSITION_TARGET_LOCAL_NED 0 0 0 9 3527 0 0 0 1 0 0 0 0 0 0 0
		const message = new this.mavlink.messages.set_position_target_local_ned(
			time_boot_ms, 
			targetSystem, 
			targetComponent,
			9,
			3527,
			0,
			0,
			0,
			speed,
			0,
			0,
			0,
			0,
			0,
			0,
			0
		);

		console.log(time_boot_ms, 
			targetSystem, 
			targetComponent,
			12,
			3527,
			0,
			0,
			0,
			speed,
			0,
			0,
			0,
			0,
			0,
			0,
			0);
		return message.pack(this.mavlink);
	}
	
	// MAV_CMD_RUN_PREARM_CHECKS
	createRunPrearmCheck(targetSystem, targetComponent) {
		const message = new this.mavlink.messages.command_long(targetSystem, targetComponent, 401);
		return message.pack(this.mavlink);
	}

	// create PARAM_REQUEST_LIST
	createParamRequestListMessage(targetSystem, targetComponent) {
		const message = new this.mavlink.messages.param_request_list(targetSystem, targetComponent);
		return message.pack(this.mavlink);
	}

	createParamRequestMessage(targetSystem, targetComponent, paramId) {
		const message = new this.mavlink.messages.param_request_read(targetSystem, targetComponent, paramId, -1);
		return message.pack(this.mavlink);
	}

	// create PARTIAL MISSION LIST
	 createMissionCountMessage(targetSystem, targetComponent, numItems, missionType) {
		 let message = null;
		if (this.mavlinkVersion == 2) {
			message = new this.mavlink.messages.mission_count(targetSystem, targetComponent, numItems, missionType == 'mission' ? this.mavlink.MAV_MISSION_TYPE_MISSION : this.mavlink.MAV_MISSION_TYPE_RALLY);
		} else {
			message = new this.mavlink.messages.mission_count(targetSystem, targetComponent, numItems);
		}
		return message.pack(this.mavlink);
	}

	createWritePartialWaypointListMessage(targetSystem, targetComponent, startIndex, endIndex) {
		const message = new this.mavlink.messages.mission_write_partial_list(targetSystem, targetComponent, startIndex, endIndex, this.mavlink.MAV_MISSION_TYPE_MISSION);
		return message.pack(this.mavlink);
	}

	createWritePartialRtlListMessage(targetSystem, targetComponent, startIndex, endIndex) {
		const message = new this.mavlink.messages.mission_write_partial_list(targetSystem, targetComponent, startIndex, endIndex, this.mavlink.MAV_MISSION_TYPE_RALLY);
		return message.pack(this.mavlink);
	}

	 createMissionCountRtlMessage(targetSystem, targetComponent, numItems) {
		const message = new this.mavlink.messages.mission_count(targetSystem, targetComponent, numItems, this.mavlink.MAV_MISSION_TYPE_RALLY);
		return message.pack(this.mavlink);
	}

	// Pause mission
	createPauseMissionMessage(targetSystem, targetComponent) {
		const message = new this.mavlink.messages.command_long(targetSystem, targetComponent, this.mavlink.MAV_CMD_OVERRIDE_GOTO, 0, this.mavlink.MAV_GOTO_DO_HOLD, this.mavlink.MAV_GOTO_HOLD_AT_CURRENT_POSITION, 0, 0, 0, 0, 0);
		return message.pack(this.mavlink);
	}

	// Continue mission
	createContinueMissionMessage(targetSystem, targetComponent) {
		const message = new this.mavlink.messages.command_long(targetSystem, targetComponent, this.mavlink.MAV_CMD_OVERRIDE_GOTO, 0, this.mavlink.MAV_GOTO_DO_CONTINUE, 0, 0, 0, 0, 0, 0);
		return message.pack(this.mavlink);
	}

	// create MISSION ITEM
	 createWriteMissionItemMessage(targetSystem, targetComponent, seq, latitude, longitude, altitude, speed, action, radius) {
		let message = null;
	
		console.log(seq, latitude, longitude, altitude, speed, action);

		if (action == 'goto') {
			message = new this.mavlink.messages.mission_item_int(targetSystem, targetComponent, seq, this.mavlink.MAV_FRAME_GLOBAL_RELATIVE_ALT, this.mavlink.MAV_CMD_NAV_WAYPOINT, 0, 1, 0, 0, 0, 0, Math.round(latitude * 10000000), Math.round(longitude * 10000000), altitude*1.0, this.mavlink.MAV_MISSION_TYPE_MISSION);
		}
		else if (action == 'loiter') {
			message = new this.mavlink.messages.mission_item_int(targetSystem, targetComponent, seq, this.mavlink.MAV_FRAME_GLOBAL_RELATIVE_ALT, this.mavlink.MAV_CMD_NAV_LOITER_TURNS, 0, 1, 99999, 0, radius, NAN, Math.round(latitude * 10000000), Math.round(longitude * 10000000), altitude*1.0, this.mavlink.MAV_MISSION_TYPE_MISSION);
			// console.log([ this.mavlink.MAV_FRAME_GLOBAL_RELATIVE_ALT, this.mavlink.MAV_CMD_NAV_LOITER_TURNS, 0, 1, 0, 0, radius, 0, Math.round(latitude * 10000000), Math.round(longitude * 10000000), altitude*1.0, this.mavlink.MAV_MISSION_TYPE_MISSION ]);
		} 
		else if (action == 'speed') {
			message = new this.mavlink.messages.mission_item_int(targetSystem, targetComponent, seq, this.mavlink.MAV_FRAME_GLOBAL_RELATIVE_ALT, this.mavlink.MAV_CMD_DO_CHANGE_SPEED, 0, 1, 1, speed, -1, 0, Math.round(latitude * 10000000), Math.round(longitude * 10000000), altitude*1.0, this.mavlink.MAV_MISSION_TYPE_MISSION);
		}
		else if (action == 'takeoff') {
			message = new this.mavlink.messages.mission_item_int(targetSystem, targetComponent, seq, this.mavlink.MAV_FRAME_GLOBAL_RELATIVE_ALT, this.mavlink.MAV_CMD_NAV_TAKEOFF, 0, 0, 0, 0, 0, NAN, Math.round(latitude * 10000000), Math.round(longitude * 10000000), altitude, this.mavlink.MAV_MISSION_TYPE_MISSION);
		}
		else if (action == 'land') {
			message = new this.mavlink.messages.mission_item_int(targetSystem, targetComponent, seq, this.mavlink.MAV_FRAME_GLOBAL_RELATIVE_ALT, this.mavlink.MAV_CMD_NAV_LAND, 0, 1, 0, 0, 0, 1, Math.round(latitude * 10000000), Math.round(longitude * 10000000), 0, this.mavlink.MAV_MISSION_TYPE_MISSION);
		}

		return message.pack(this.mavlink);
	}

	createOrbit(targetSystem, targetComponent, latitude, longitude, altitude, radius) {
		const message = new this.mavlink.messages.command_long(targetSystem, targetComponent, this.mavlink.MAV_CMD_DO_ORBIT, 0, radius, NaN, this.mavlink.ORBIT_YAW_BEHAVIOUR_HOLD_FRONT_TANGENT_TO_CIRCLE, NaN, Math.round(latitude), Math.round(longitude), altitude);
		return message.pack(this.mavlink);
	}

	// MANUAL_CONTROL
	createManualControlMessage(targetSystem, targetComponent, x, y, z, r) {
		const message = new this.mavlink.messages.manual_control(targetSystem, x, y, z, r, 0);
		// const message = new this.mavlink.messages.rc_channels_override(targetSystem, targetComponent, 1200 + ((y + 1000) / 2000) * 700, 1200 + ((x + 1000) / 2000) * 700, 1200 + ((r + 1000) / 2000) * 700,  1200 + ((z + 1000) / 2000) * 700, 32767, 32767, 32767, 32767);
		return message.pack(this.mavlink);
	}

	createSetMissionCurrent(targetSystem, targetComponent, seq) {
		const message = new this.mavlink.messages.mission_set_current(targetSystem, targetComponent, seq);
		return message.pack(this.mavlink);
	}

	createMissionClearAll(targetSystem, targetComponent) {
		const message = new this.mavlink.messages.mission_clear_all(targetSystem, targetComponent, this.mavlink.MAV_MISSION_TYPE_ALL);
		return message.pack(this.mavlink);
	}

	// create MISSION ITEM - LAND
	 createWriteMissionItemLandMessage(targetSystem, targetComponent, seq, latitude, longitude, altitude) {
		const message = new this.mavlink.messages.mission_item(targetSystem, targetComponent, seq, this.mavlink.MAV_FRAME_GLOBAL_RELATIVE_ALT, this.mavlink.MAV_CMD_NAV_LAND, 0, 1, 0, 0, 0, NAN,  Math.round(latitude * 10000000),  Math.round(longitude * 10000000), altitude * 1.0, this.mavlink.MAV_MISSION_TYPE_MISSION);
		return message.pack(this.mavlink);
	}

	// create RTL MISSION ITEM
	 createWriteRtlItemMessage(targetSystem, targetComponent, seq, latitude, longitude, altitude) {
		const message = new this.mavlink.messages.mission_item_int(targetSystem, targetComponent, seq, this.mavlink.MAV_FRAME_GLOBAL_RELATIVE_ALT, this.mavlink.MAV_CMD_NAV_RALLY_POINT, 0, 0, 0, 0, 0, 0,  Math.round(latitude * 10000000),  Math.round(longitude * 10000000), altitude * 1.0, this.mavlink.MAV_MISSION_TYPE_RALLY);
		return message.pack(this.mavlink);
	}

	// create RTL MISSION ITEM - LAND
	 createWriteRtlItemLandMessage(targetSystem, targetComponent, seq, latitude, longitude, altitude) {
		const message = new this.mavlink.messages.mission_item(targetSystem, targetComponent, seq, this.mavlink.MAV_FRAME_GLOBAL_RELATIVE_ALT, this.mavlink.MAV_CMD_NAV_LAND, 0, 0, 0, 0, 0, 0, latitude, longitude, altitude, this.mavlink.MAV_MISSION_TYPE_RALLY);
		return message.pack(this.mavlink);
	}

	createSetYawMessage(targetSystem, targetComponent, angle, direction) {
		const message = new this.mavlink.messages.command_long(targetSystem, targetComponent, this.mavlink.MAV_CMD_CONDITION_YAW, 0, angle, 20, direction, 0, 0, 0, 0, 0);
		return message.pack(this.mavlink);
	}

	createSetAltitudeMessage(targetSystem, targetComponent, latitude, longitude, altitude) {
		const message = new this.mavlink.messages.command_long(targetSystem, targetComponent, this.mavlink.MAV_CMD_DO_REPOSITION , 0, -1, 1, 0, NaN, latitude, 0, 0, 0);
		return message.pack(this.mavlink);
	}

	createSetSpeedMessage(targetSystem, targetComponent, speed) {
		const message = new this.mavlink.messages.command_long(targetSystem, targetComponent, this.mavlink.MAV_CMD_DO_CHANGE_SPEED , 0, 0, speed, -1, 0, 0, 0, 0, 0);
		return message.pack(this.mavlink);
	}
	
	// create SET FLIGHT MODE message
	 createSetFlightModeMessage(targetSystem, targetComponent, mode, confirmation) {
		// create a mode change command packet
    // note the "mode" argument is the string value, ie "AUTO"
		let modeInt = null;
    for(const key in MAVLinkConstants.CUSTOM_MAV_MODE) {
			if (MAVLinkConstants.CUSTOM_MAV_MODE[key] === mode) {
					modeInt = key;
			}
		}

		if (modeInt == null) {
			throw new Error(`Invalid flight mode "${mode}"`);
		}

		const msg = new this.mavlink.messages.command_long(targetSystem, targetComponent, this.mavlink.MAV_CMD_DO_SET_MODE, confirmation, this.mavlink.MAV_MODE_FLAG_CUSTOM_MODE_ENABLED, modeInt, 0, 0, 0, 0, 0);
		return msg.pack(this.mavlink);
	}

	decode(messageBuffer) {
		let decoded = null;
		try {
			decoded = this.mav.decode(messageBuffer);
		} catch(err) {
			// console.log(err);
			this.switchVersion();
			decoded = this.mav.decode(messageBuffer);
		}
		finally {
			return { message: decoded, mavlinkVersion: this.mavlinkVersion };
		}
	}
}

export default MAVLinkService;
// SYS_STATUS (1)

// ESTIMATOR_STATUS  (#230)
export function sysStatus (message, aircraft) {
	const {
        onboard_control_sensors_health,
        onboard_control_sensors_enabled
	} = message;

    const time_usec = new Date();

    const logs = [];

    let shouldUpdate = false;
    const newStatus = aircraft.statusState.getState();

    // magStatus: null,

    // Gyro Status
    let { gyroStatus: currentGyroStatus } = newStatus;
    const newGyroStatus = (onboard_control_sensors_health & 1) > 0;
    const gyroPresent = (onboard_control_sensors_enabled & 1) > 0;

    if (gyroPresent && currentGyroStatus !== newGyroStatus) {
        shouldUpdate = true;
        newStatus.gyroStatus = newGyroStatus;
        if (newGyroStatus == true) {
            logs.push({ status: 'good', message: '3D Gyro sensor status - GOOD', timestamp: time_usec });
        } else {
            logs.push({ status: 'bad', message: '3D Gyro sensor status - BAD', timestamp: time_usec });
        }
    }

    // Mag Status
    let { magStatus: currentMagStatus } = newStatus;
    const newMagStatus = (onboard_control_sensors_health) & 2 > 0;
    const magPresent = (onboard_control_sensors_enabled) & 2 > 0;

    if (magPresent && currentMagStatus !== newMagStatus) {
        shouldUpdate = true;
        newStatus.magStatus = newMagStatus;
        if (newMagStatus == true) {
            logs.push({ status: 'good', message: 'Mag sensor status - GOOD', timestamp: time_usec });
        } else {
            logs.push({ status: 'bad', message: 'Mag sensor status - BAD', timestamp: time_usec });
        }
    }

    // Accelerometer Status
    let { accelerometerStatus: currentAccelerometerStatus } = newStatus;
    const newAccelerometerStatus = (onboard_control_sensors_health & 4) > 0;
    const accelerometerPresent = (onboard_control_sensors_enabled & 4) > 0;

    if (accelerometerPresent && currentAccelerometerStatus !== newAccelerometerStatus) {
        shouldUpdate = true;
        newStatus.accelerometerStatus = newAccelerometerStatus;
        if (newAccelerometerStatus == true) {
            logs.push({ status: 'good', message: 'Accelerometer sensor status - GOOD', timestamp: time_usec });
        } else {
            logs.push({ status: 'bad', message: 'Accelerometer sensor status - BAD', timestamp: time_usec });
        }
    }

    if (shouldUpdate) {
        aircraft.statusStateSetters.addLogs(logs);
	    aircraft.statusStateSetters.setStatusData(newStatus);

        console.log('LOGS');
        console.log(logs);
    }
}
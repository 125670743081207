import React, {useContext, useEffect, useRef, useState} from "react";
import { toast } from "react-toastify";
import PerfectScrollbar from 'react-perfect-scrollbar';
import {InputField} from "../../inputs/input-field/input-field";
import {TableFrame} from "../table-frame/table-frame";
import {SkyModal} from "../sky-modal/sky-modal";
import {Loading} from "../../layout/loading/loading";
import {ConfirmationDialog} from "../confirmation-dialog/confirmation-dialog";
import { AddOutlined, CreateNewFolder, CreateNewFolderOutlined, DeleteOutlined, Edit, EditAttributes, EditOutlined, FolderOpen, FolderOutlined, KeyboardArrowDown } from "@mui/icons-material";
import { MissionContext } from "../../../contexts/mission-context";
import UtilityService from "../../../helpers/utility-service";
import { FileSystemContext } from "../../../contexts/file-system-context";

export const FileDialog = (
    {
        dialogType,
        entityName,
        schema,
        onClose,
        onLoad,
        onSave,
        currentFileName,
}) => {
        const [waitingConfirmation, setWaitingConfirmation] = useState(null);
        const [selectedFolder, setSelectedFolder] = useState(null);
        const [editedFolderId,setEditedFolderId] = useState(null);
        const [selectedFile, setSelectedFile] = useState(null);
        const [editedFileId, setEditedFileId] = useState(null);
        const [isBadInput, setIsBadInput] = useState(false);
        const { 
            missionFolders, 
            createFolder: fsCreateFolder, 
            deleteFile: fsDeleteFile, 
            deleteFolder: fsDeleteFolder, 
            renameFolder: fsRenameFolder,
            isBusy: loadingVisible,
        } = useContext(FileSystemContext);
        const pendingFileName = useRef(currentFileName);
        const scrollbarRef = useRef(null);
        const scrollbarBottomRef = React.createRef();

        useEffect(() => {
            if (selectedFolder == null && missionFolders != null && missionFolders.length > 0) {
                setSelectedFolder(missionFolders[0].id);
            }

            checkFileName();
        });

        const saveTitle = "Save " + entityName;
        const loadTitle = "Load " + entityName;
        
        const checkFileName = () => {
            let isBadFilename = false;
            if (selectedFolder != null) {
                missionFolders.find(el => el.id == selectedFolder).data.files.forEach(file => {
                    if (file.data.title == pendingFileName.current) {
                        isBadFilename = true;
                    }
                });
            }

            if (isBadFilename) {
                if (!isBadInput) {
                    setIsBadInput(true);
                }
            } else {
                if (isBadInput) {
                    setIsBadInput(false)
                }
            }
        }

        const loadFile = () => {
            // if (onLoad) {
                if (selectedFile != null) {
                    if (onLoad) {
                        onLoad(selectedFile);                        
                    }
                    onClose();
                }
                // onLoad(selectedFile);
                // onClose();
            // } 
        };
        const saveFile = () => {
            if (!isBadInput) {
                if (onSave) {
                    onSave({ filename: pendingFileName.current, folderId: selectedFolder });
                }
                onClose();
            }
        };

        const createFolder = () => { 
            fsCreateFolder(entityName.toLowerCase(), `New folder (${missionFolders.length + 1})`)
            .then((folder) => {
                toast.success("Folder created");
                setEditedFolderId(null);
                
                const newFolder = {...folder, data: { ...folder.data, files: [] }};
                missionFolders.push(newFolder);
                setSelectedFolder(newFolder.id);
                
                if (scrollbarRef.current != null) {
                    scrollbarRef.current.scrollTop = 1000000;
                }
            })
            .catch((err) => { console.log(err); toast.error("Failed to create folder") });
        };
        const renameFolder = (folder, newName) => { 
            if (folder.data.name == newName) { setEditedFolderId(null); return };
            
            fsRenameFolder(entityName.toLowerCase(), folder.id, newName)
                .then(() => {
                    toast.success("Folder renamed");
                    setEditedFolderId(null);
                })
                .catch((err) => toast.error("Failed to rename folder"));
        };

        const deleteFolder = (folderId) => {
                setWaitingConfirmation({
                    caption: 'Confirm deleting folder and all its contents',
                    action: () => fsDeleteFolder(entityName.toLowerCase(), folderId).then(() => {
                        if (selectedFolder == folderId) {
                            setSelectedFolder(null)
                        }
                        toast.success("Folder deleted");
                        setEditedFolderId(null);
                    })
                    .catch((err) => toast.error("Failed to delete folder"))            
                })
        };

        const renameFile = (file, newName) => {};
        const deleteFile = (fileId) => {
            setWaitingConfirmation({
                caption: 'Confirm deleting file',
                action: () => fsDeleteFile('mission', fileId)
            })
        };

        const getAttribute = (file, att) => {
            if (att.mapValues != null) { 
                return att.mapValues(UtilityService.getObjectValueFromPath(file, att.path));
            }
            return UtilityService.getObjectValueFromPath(file, att.path)
        }

        const actions = dialogType === 'save' ?
                [{
                    title: "Save", 
                    action: () => saveFile(),
                    btnType:"primary",
                    position:'right',
                },
                {
                    title: "Close", 
                    position: "right",
                    action:() => onClose(),
                    btnType:"secondary",
                }]
            :
            dialogType === 'load' ?
                [{
                    title: "Load", 
                    action: () => loadFile(),
                    btnType:"primary",
                    position:'right',
                },
                {
                    title: "Close", 
                    position: "right",
                    action:() => onClose(),
                    btnType:"secondary",
                }]
            :
                [];

        return(
            <SkyModal
                className='file-dialog'
                title={dialogType == 'save' ? (saveTitle) : (loadTitle)}
                modalVisible={true}
                onClose={() => onClose()}
                disableScrollbar={true}
                actionButtons={actions}
                style={{backgroundColor: '#060d1f'}}
                modalIcon={<FolderOpen/>}
                modalCloseIcon={<KeyboardArrowDown/>}
            >
                <ConfirmationDialog 
                    visible={waitingConfirmation != null}
                    message={waitingConfirmation && waitingConfirmation.caption}
                    onConfirm={() => waitingConfirmation && waitingConfirmation.action()}
                    onCancel={() => setWaitingConfirmation(null)}
                    onClose={() => setWaitingConfirmation(null)}
                />
                <section className='file-dialog-wrap' style={{...((loadingVisible || missionFolders == null) && { opacity: 0.8 })}}>
                    { (loadingVisible || missionFolders == null) && <Loading position='absolute'/> }

                    <div className='folder-selection-wrap'>
                        <p className="folder-selection-inscription">Select {entityName.toLowerCase()} folder</p>
                        <div className='folder-list-wrapper'>
                        {/* <PerfectScrollbar containerRef={el => scrollbarRef.current = el}> */}
                            {/* Create new folder action */}
                            <div className='folder-create-new-wrap' onClick={()=>createFolder()}>
                                <CreateNewFolderOutlined/>
                                <p className='uppercase'>Create New Folder</p>
                            </div>

                            { missionFolders && missionFolders.map(folder => (
                                // Folder item
                                <div 
                                    key={`folder-${folder.id}`}
                                    className={'folder-wrap '  + (selectedFolder && (folder.id == selectedFolder) ? 'selected' : "")} 
                                    onClick={() => setSelectedFolder(folder.id)}
                                >
                                    <div className='folder-name-icon'>
                                        <FolderOutlined/>
                                        {
                                            editedFolderId == folder.id ?
                                            <InputField 
                                                // className="folder-name-input" 
                                                width="140px"
                                                type="text" 
                                                defaultValue={folder.data.name} 
                                                onClick={(e) => e.stopPropagation()}
                                                onKeyDown={(e) => {
                                                    if (e.key == 'Enter') {
                                                        const rawValue = e.target.value;
                                                        const value = rawValue ? rawValue.trim() : null;
                                                        renameFolder(folder, value);
                                                    }
                                                }}
                                            />
                                            :
                                            <p>{folder.data.name}</p>
                                        }
                                    </div>
                                    <div className='table-actions-wrap'>
                                        <div className='table-action'>
                                            <Edit onClick={(e) => { e.stopPropagation(); setEditedFolderId(folder.id); }}/>
                                        </div>
                                        <div className='table-action'>
                                            <DeleteOutlined onClick={(e) => { e.stopPropagation(); deleteFolder(folder.id); }}/>
                                        </div>  
                                    </div>
                                </div>
                            ))}

                        {/* </PerfectScrollbar> */}
                        </div>
                    </div>


                    <div className='mission-selection-wrap'>
                        <p className="folder-selection-inscription">Select mission plan</p>
                        <div className='mission-selection-table-wrap'>
                        
                        <PerfectScrollbar>
                            <div className='table-wrap'>
                                <TableFrame 
                                    tableHeader={schema.attributes.map(att => att.label)} 
                                    actions={true}
                                >                                    
                                {
                                    selectedFolder &&
                                    missionFolders.find(el => el.id == selectedFolder) &&
                                    missionFolders.find(el => el.id == selectedFolder).data.files && 
                                    missionFolders.find(el => el.id == selectedFolder).data.files.map((file, rowIndex) => (
                                        <tr 
                                            key={ `file-${file.id}` } 
                                            className={`pointer ${selectedFile && selectedFile.id == file.id && 'selected'}`}
                                            onClick={() => { pendingFileName.current = file.data.title; setSelectedFile(file); pendingFileName.current = file.data.title }}
                                            onDoubleClick={() => { pendingFileName.current = file.data.title; pendingFileName.current = file.data.title; setSelectedFile(file); loadFile(); }}
                                        >
                                             <td style={{ width: 50 }}> 
                                                <div className='table-actions-wrap'>
                                                    {/* <div className='table-action'>
                                                        <EditOutlined onClick={(e) => { 
                                                            e.stopPropagation();
                                                            setEditedFileId(file.id);
                                                        }}/>
                                                    </div> */}
                                                    <div className='table-action'>
                                                        <DeleteOutlined onClick={(e) => { 
                                                            e.stopPropagation();
                                                            deleteFile(file.id);  
                                                        }}/>
                                                    </div>  
                                                </div>
                                            </td>
                                        {
                                            schema.attributes.map((att, fieldIndex) => (
                                                (<td key={`row-${rowIndex}-field-${fieldIndex}-${getAttribute(file, att)}`}>
                                                    {
                                                        editedFileId == file.id && att.key == 'title' ?
                                                        <input 
                                                            className="file-name-input" 
                                                            type="text" 
                                                            defaultValue={getAttribute(file, att)} 
                                                            onClick={(e) => e.stopPropagation()}
                                                            onKeyDown={(e) => {
                                                                if (e.key == 'Enter') {
                                                                    const rawValue = e.target.value;
                                                                    const value = rawValue ? rawValue.trim() : null;
                                                                    renameFile(file, value)
                                                                }
                                                            }}
                                                        />
                                                        :
                                                        getAttribute(file, att)
                                                }
                                                </td>)
                                            ))

                                        }                                       
                                    </tr>
                                    ))
                                }                               
                                </TableFrame>    
                            </div>
                            <span ref={scrollbarBottomRef}></span>
                        </PerfectScrollbar>
                    </div>


                        {dialogType=='save' ? 
                            <div className='mission-save-wrap'>
                                <p>{entityName} Title</p>
                                {/* <EditData 
                                    defaultValue={'Insert ' + entityName + ' Title'}
                                /> */}
                                <InputField
                                    key={`input-title-${selectedFile ? selectedFile.id : null}`}
                                    autofocus
                                    defaultValue={pendingFileName.current}
                                    className={isBadInput ? 'bad-input' : ''}
                                    placeholder={`Insert ${entityName} Title`}
                                    onChange={(e) => { pendingFileName.current = (e.target.value ? e.target.value.trim() : null ); checkFileName() }}
                                    onKeyDown={(e) => e.key == 'Enter' && saveFile()}
                                />
                            </div>:("")
                        }
                    </div>
                </section>
            </SkyModal>
        );
    }


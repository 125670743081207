import { heartbeat } from './heartbeat';
import { commandAck } from './command-ack';
import { globalPositionInt } from './global-position-int';
import { vfrHud } from './vfr-hud';
import { attitude } from './attitude';
import { distanceSensor } from './distance-sensor';
import { missionRequestInt } from './mission-request-int';
import { missionAck } from './mission-ack';
import { missionCount } from './mission-count';
import { missionItemInt } from './mission-item-int';
import { missionCurrent } from './mission-current';
import { batteryStatus } from './battery-status';
import { gpsRawInt } from './gps-raw-int';
import { systemTime } from './system-time';
import { positionTargetGlobalInt } from './position-target-global-int';
import { estimatorStatus } from './estimator-status';
import { sysStatus } from './sys-status';

export default {
	// HEARTBEAT
	heartbeat,
	commandAck,
	globalPositionInt,
	vfrHud,
	attitude,
	distanceSensor,
	missionRequestInt,
	missionAck,
	missionCount,
	missionItemInt,
	missionCurrent,
	batteryStatus,
	gpsRawInt,
	systemTime,
	positionTargetGlobalInt,
	estimatorStatus,
	sysStatus,
}
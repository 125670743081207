import React, { createContext, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import UtilityService from '../helpers/utility-service';
import { Waypoint } from '../helpers/waypoint';
import { FileSystemContext } from './file-system-context';
import { MissionContext } from './mission-context';
import { TerrainContext } from './terrain-context';

export const CockpitContext = createContext();

const CockpitContextProvider = ({ children }) => {

	const [selectedPath, setSelectedPath] = useState(null);
	const [selectedToPoint, setSelectedToPoint] = useState(null);
	const [availableMissions, setAvailableMissions] = useState(null);

	const [missionPlanningAircraft, setMissionPlanningAircraft] = useState(null);

	const {allMissions } = useContext(FileSystemContext);
	const {	
		// currentCockpitPath,
		// currentMissionPath,
		createMultipleTerrainPaths
	} = useContext(TerrainContext);
	
	const location = useLocation();


	// useEffect(() => {
	// 	console.log("CCP", currentCockpitPath);
	// 	console.log("CMP", currentMissionPath);
	// 	if(location.pathname === '/cockpit-v2') {
	// 		setSelectedPath(currentCockpitPath);
	// 		// clearCurrentPath();
	// 	} else if(location.pathname === '/mission-planning') {
	// 		setSelectedPath(currentMissionPath);
	// 		// clearCurrentPath();
	// 	}
	// },[location])
	
	useEffect(() => {
		if (missionPlanningAircraft != null && allMissions != null && allMissions.length > 0) {
			// List available aircraft missions
			const missions = allMissions
				.filter(mission =>
					mission.data.missionAircraftType != null
					&& mission.data.missionAircraftType.id == missionPlanningAircraft.data.type
				);

			const availableflightPaths = [].concat(...missions
				.map(mission => (
					mission.data.flightPaths.map(path => ({
						waypoints: path.waypoints.map((wp, index, waypoints) => new Waypoint(wp.latitude, wp.longitude, wp.label, wp.altitude, wp.speed, index, index == 0, index == waypoints.length - 1)),
						rtlPoints: mission.rtlPoints,
						mission: mission.data.title,
						title: path.title,
					}))
				)))
				.filter(path => path.waypoints.length > 0)
				.map(path => ({
					...path, toPoint: {
						latitude: path.waypoints[0].latitude,
						longitude: path.waypoints[0].longitude,
						altitude: path.waypoints[0].altitude,
						speed: path.waypoints[0].speed,
					}
				}
				))
				.sort((a, b) => {
					return UtilityService.distance({
						latitude: a.toPoint.latitude,
						longitude: a.toPoint.longitude
					},
						{
							latitude: b.toPoint.latitude,
							longitude: b.toPoint.longitude
						}, 0.5)
				})

			const takeOffPoints = [];

			for (const path of availableflightPaths) {
				if (takeOffPoints.length === 0
					|| UtilityService.distance({
						latitude: path.toPoint.latitude,
						longitude: path.toPoint.longitude,
					},
						{
							latitude: takeOffPoints[takeOffPoints.length - 1].toPoint.latitude,
							longitude: takeOffPoints[takeOffPoints.length - 1].toPoint.longitude,
						}, 0.5) > 2) {
					takeOffPoints.push({
						paths: [path],
						toPoint:
							new Waypoint(
								path.toPoint.latitude,
								path.toPoint.longitude,
								`T/O`,
								path.toPoint.altitude,
								path.toPoint.speed,
								0,
								true,
							),
						
					})
				} else {
					takeOffPoints[takeOffPoints.length - 1].paths.push(path);
				}
			}

			// console.log(takeOffPoints);

			setAvailableMissions({ takeOffPoints });
		} else {
			setSelectedToPoint(null);
			setAvailableMissions(null);
			setSelectedPath(null);
		}
	}, [missionPlanningAircraft, allMissions]);

	const selectToPoint = (toPoint) => {
		setSelectedToPoint(toPoint);
		setSelectedPath(null);
	}

	const selectPath = (path) => {
		setSelectedPath(path)
		createMultipleTerrainPaths([path]);
	}



	return (
		<CockpitContext.Provider value={{
			selectToPoint,
			selectedToPoint,
			selectedPath,
			selectPath,
			setSelectedPath,
			availableMissions,
			missionPlanningAircraft,
			setMissionPlanningAircraft,
			
		}}>
			{ children }
		</CockpitContext.Provider>
	)
}

export default CockpitContextProvider;
import React from 'react';


export const FMFlightlogBox = ({title, content, children}) => {
    
    return(
        <div className='fm-flightlog-box'>
            <div className='fm-flightlog-header'>
                <span className='title'>{title}</span>
            </div>
            <div className='fm-flightlog-content'>
                {children}
            </div>
        </div>
    )
}
// GPS_RAW_INT (#24)
export function gpsRawInt (message, aircraft) {
	const {
		time_usec,
		fix_type,
		lat,
		lon,
		alt,
		eph,
		epv,
		vel,
		cog,
		satellites_visible,
		h_acc,
		v_acc,
	} = message;

	const { lastTimestamp } = aircraft.gpsRawState.getState();

	const now = Date.now();

	if (lastTimestamp == null || now - lastTimestamp > 500 ) {
		aircraft.gpsRawStateSetters.setData({ 
			horizontalDilution: eph / 100, 
			verticalDilution: epv / 100, 
			numSatellites: satellites_visible, 
			latitude: lat / 10000000, 
			longitude: lon / 10000000,
			altitude: alt / 1000,
			hAcc: h_acc != null ? h_acc / 1000 : 'N/A',
			vAcc: v_acc != null ? v_acc / 1000 : 'N/A',
			lastTimestamp: now,
		})
	}
}

import React, {useState} from 'react';
import { Stack } from '../stack/stack';
import PerfectScrollbar from 'react-perfect-scrollbar';

export const InfoPanel = ({title, subtitles, actionIcons, titleIcon, className, children, style: customStyle = {}}) => {
//EXAMPLE CALL 
    //     <InfoCard 
//     className='information-dock-card'
//     title='Weather Report'
//     actionIcons={[{icon: <RefreshIcon/>, action: () => {}}]}
//     titleIcon={<CloudIcon/>}
//     action={() => {}} 
//     subtitles = {[
//         {title: (<span>Near <span className='bold'>Windjammer landing</span></span>), 
//         size: 'medium'}, 
//         {title: (<span>Updated 10 minutes ago</span>), 
//         size: 'small'}]}>
// </InfoPanel>
    return (
        <div className={`info-panel ${className ? className : undefined}`} style={{...customStyle}}>
            <Stack
                className="info-panel-header"
                halign='space-between' 
                valign="center" 
                direction="horizontal"  
                style={{marginBottom: "10px"}}
            >
                <Stack
                    halign='space-between' 
                    valign="center" 
                    direction="horizontal"  
                    className='info-panel-title uppercase'>
                        <p style={{marginRight:"5px"}} className='icon small'>{titleIcon}</p> 
                        <p>{title}</p>
                </Stack>
               
                <Stack
                    halign='space-between' 
                    valign="center" 
                    direction="horizontal"  
                    className='uppercase'>
                    {actionIcons && actionIcons.map((actionIcon, index) => 
                        (<p 
                            key={`info-panel-actionIcon-${index}`}
                            onClick={() => actionIcon.action()} 
                            className='icon action small pointer'>
                                {actionIcon.icon}
                        </p>))
                    }
                </Stack>
            </Stack>


            <Stack
                halign='space-between' 
                valign="center" 
                direction="horizontal">
                    {subtitles && subtitles.map((subtitle,index) => (
                        <p key={`info-panel-subtitle-${index}`} className={`subtitle ${subtitle.size && subtitle.size}`}>{subtitle.title}</p>
                    ))}
                </Stack>
            <div className='info-panel-content'>
                    {children}   
            </div>
        </div>
    );
}
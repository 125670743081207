import { Close, Delete, Search } from '@mui/icons-material';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { InputField } from '../input-field/input-field';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useLocation } from "react-router-dom";
import axios from "axios";
import {InterfaceContext} from '../../../contexts/interface-context';
import { ConfigContext } from "../../../contexts/config-context";
import { MapContext } from "../../../contexts/map-context";
import { Stack } from '../../layout/stack/stack';
import Cookies from 'universal-cookie';
import { Fab, IconButton } from '@mui/material';

export const SearchLocation = () => {
    const { services: { geocodeService: { host: remoteUrl }}} = useContext(ConfigContext);
// ?format=json&q=<query>
    // const options = [{location: "some location"},{location: "some location"},{location: "some location"},{location: "some location"},{location: "some location"},{location: "some location"},{location: "some location"},{location: "some location"},{location: "some location"},{location: "some location"},{location: "some location"},{location: "some location"},{location: "some location"},{location: "some location"}, ]
    const [optionsVisible, setOptionsVisible] = useState(false);
    const [options, setOptions] = useState([]);
    const [searchVisible, setSearchVisible] = useState(false);
    const {infoDockOpened} = useContext(InterfaceContext);
    const { addCustomLocation } = useContext(MapContext);
    const location = useLocation();
    const [activeShow, setActiveShow] = useState(false);
    const cookies = new Cookies();
    
    const [savedLocations, setSavedLocations] = useState(cookies.get("savedLocations") || []);
    console.log(savedLocations);

    useEffect(() => {
        cookies.set('savedLocations', savedLocations);

      }, [savedLocations]);

    const runSearch = (query) => {
        if (query != null && query.length > 0) {
            axios.get(remoteUrl, { params: { q: query, format: 'json' }}).then((res) => {
                // console.log(res.data);
                setOptions(res.data ? res.data.map(op => ({ ...op, lat: parseFloat(op.lat), lon: parseFloat(op.lon), display_name: op.display_name.split(',').slice(0,2).join(',') })) : []);
            })
        } else {
            setOptions([]);
        }
    }

    const selectLocation = (location) => {
        console.log(location);
        addCustomLocation({ latitude: location.lat, longitude: location.lon, label: location.display_name, icon: location.icon });
    }

    const saveLocation = (location) => {
        setSavedLocations([...savedLocations, location]);
    }
    const removeLocation = (location) => {
        const currentLocations = [...savedLocations];
        const index = savedLocations.findIndex(el => location.place_id == el.place_id);
        currentLocations.splice(index,1);
        setSavedLocations(currentLocations);
       
    }
	if (location.pathname === '/login' || location.pathname === '/' || location.pathname === '/cockpit' || location.pathname === '/fleet-management' || location.pathname === '/flights') {
		return <></>;
	}
   
    
    return(
        
            <div 
                className={`search-location 
                    ${location.pathname == '/mission-planning' && 'to-left'}
                    ${(location.pathname === '/home' && 'add-margin')}
                    ${(location.pathname === '/home' && infoDockOpened === true) ? 'moved' : 'default'} 
                    ${searchVisible  && 'wider'}`}
                onBlur={()=>setOptionsVisible(!optionsVisible)}
                onFocus={()=>setOptionsVisible(!optionsVisible)}
            >
                {searchVisible ? 
                (<div className='search-location-opened'>
                  <div className='search-input-close-wrap'>  
                    <InputField 
                        label="Search"
                        className='search-location-input-wrap' 
                        icon={<Search/>} 
                        debounce={true}
                        debounceTimeout={400}
                        placeholder='Find location...'
                        onChange={(e) => runSearch(e.target.value.toLowerCase().trim())}
                    />
                    <Close className='white'  onClick={() => setSearchVisible(false)}/>
                </div>
                <div className='saved-locations-box'>
                    <span class='saved-locations-title uppercase'>Saved locations</span>
                    
                    {savedLocations.length === 0 ? 
                        <p>No saved locations</p> :
                        <div className='saved-locations-wrap' onClick={(e) => { e.stopPropagation();}}> 
                            {savedLocations.map((location, index) => (
                                <div className='saved-location' key = {`saved-location-${index}`} >
                                    <span onClick={(e) => { e.stopPropagation(); selectLocation(location) }}>
                                        <img src={location.icon}/> 
                                        {location.display_name}
                                    </span>
                                    
                                    <IconButton aria-label="delete" className='remove-button' size='medium' onMouseDown={(e) => { e.stopPropagation(); removeLocation(location) }} >
                                        <Delete fontSize="12" className='remove-button' />
                                    </IconButton>
                                    {/* <span className='remove-button uppercase' onMouseDown={(e) => { e.stopPropagation(); removeLocation(location) }}>Remove</span> */}
                                </div>
                            ))}
                        </div>
                    }
                </div>
                {optionsVisible && options.length > 0 && 
                    <div className={`search-location-options`} >
                        <PerfectScrollbar>
                        <p class='search-results-title uppercase'>Results</p>
                        {options.map((op,index) => (

                            <Stack direction={"horizontal"} halign={'space-between'} valign={'center'} key={`options-${index}`}>
                            <div>
                                <p onMouseDown={(e) => { e.stopPropagation(); selectLocation(op) }}>
                                    <img src={op.icon}/> 
                                    {op.display_name}
                                </p>
                            </div>
                            <div className='save-location'>
                                <span className='uppercase'  onMouseDown={(e)=>{e.stopPropagation(); saveLocation(op);}}>Save</span>
                            </div>
                            {/* <div className={`search-show-hide ${activeShow && 'active'}`} 
                                onClick={(e)=>{e.stopPropagation();setActiveShow(!activeShow);}}>
                                {activeShow ? <span>Show</span> : <span>Hide</span>}
                            </div> */}
                            </Stack>
                        ))}
                        </PerfectScrollbar>
                    </div>
                }</div>) : 
                (<div className='search-location-closed' onClick={() => setSearchVisible(true)}>
                    <Search/>
                </div>)}
            </div>
    )
}
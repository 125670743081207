import C2Connection from "./c2-connection";

class C2ConnectionService {
	constructor() {
		this.targetIds = {};
	}

	createC2Connection(config, targetUserId, connectingUser) {
		console.log(config, targetUserId);
		if (this.targetIds[targetUserId] != null) {
			const connection = this.targetIds[targetUserId];
			return connection;
		} else {
			const connection = new C2Connection(config, targetUserId, connectingUser);
			if (targetUserId != 'SERIAL') {
				this.targetIds[targetUserId] = connection;
			}
			return connection;
		}
	}

	destroyC2Connection(targetUserId) {
		delete this.targetIds[targetUserId];
	}
}

export default new C2ConnectionService();
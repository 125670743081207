// ESTIMATOR_STATUS  (#230)
export function estimatorStatus (message, aircraft) {
	const {
		time_usec,
        flags,
	} = message;

    console.log('ESTIMATOR STATUS');

    const logs = [];

    let shouldUpdate = false;
    const newStatus = aircraft.statusState.getState();

    // Altitude estimate
    let { altitudeEstimate: currentAltitudeEstimate } = newStatus;
    const newAltitudeEstimate = (flags & 1) > 0;

    if (currentAltitudeEstimate !== newAltitudeEstimate) {
        shouldUpdate = true;
        newStatus.altitudeEstimate = newAltitudeEstimate;
        if (newAltitudeEstimate == true) {
            logs.push({ status: 'good', message: 'Altitude estimate - GOOD', timsetamp: new Date(time_usec) });
        } else {
            logs.push({ status: 'bad', message: 'Altitude estimate - BAD', timsetamp: new Date(time_usec) });
        }
    }

    // Horizontal velocity estimate
    let { horizontalVelocityEstimate: currentHorizontalVelocityEstimate } = newStatus;
    const newHorizontalVelocityEstimate = (flags & 2) > 0;

    if (currentHorizontalVelocityEstimate !== newHorizontalVelocityEstimate) {
        shouldUpdate = true;
        newStatus.horizontalVelocityEstimate = newHorizontalVelocityEstimate;
        if (newHorizontalVelocityEstimate == true) {
            logs.push({ status: 'good', message: 'Horizontal velocity estimate - GOOD', timsetamp: new Date(time_usec) });
        } else {
            logs.push({ status: 'bad', message: 'Horizontal velocity estimate - BAD', timsetamp: new Date(time_usec) });
        }
    }

    // Vertical velocity estimate
    let { verticalVelocityEstimate: currentVerticalVelocityEstimate } = newStatus;
    const newVerticalVelocityEstimate = (flags & 4) > 0;

    if (currentVerticalVelocityEstimate !== newVerticalVelocityEstimate) {
        shouldUpdate = true;
        newStatus.verticalVelocityEstimate = newVerticalVelocityEstimate;
        if (newVerticalVelocityEstimate == true) {
            logs.push({ status: 'good', message: 'Vertical velocity estimate - GOOD', timsetamp: new Date(time_usec) });
        } else {
            logs.push({ status: 'bad', message: 'Vertical velocity estimate - BAD', timsetamp: new Date(time_usec) });
        }
    }

    // Relative horizontal position estimate
    let { relativeHorizontalPositionEstimate: currentRelativeHorizontalPositionEstimate } = newStatus;
    const newRelativeHorizontalPositionEstimate = (flags & 8) > 0;

    if (currentRelativeHorizontalPositionEstimate !== newRelativeHorizontalPositionEstimate) {
        shouldUpdate = true;
        newStatus.relativeHorizontalPositionEstimate = newRelativeHorizontalPositionEstimate;
        if (newRelativeHorizontalPositionEstimate == true) {
            logs.push({ status: 'good', message: 'Relative horizontal position estimate - GOOD', timsetamp: new Date(time_usec) });
        } else {
            logs.push({ status: 'bad', message: 'Relative horizontal position estimate - BAD', timsetamp: new Date(time_usec) });
        }
    }

    // Absolute horizontal position estimate
    let { absoluteHorizontalPositionEstimate: currentAbsoluteHorizontalPositionEstimate } = newStatus;
    const newAbsoluteHorizontalPositionEstimate = (flags & 16) > 0;

    if (currentAbsoluteHorizontalPositionEstimate !== newAbsoluteHorizontalPositionEstimate) {
        shouldUpdate = true;
        newStatus.absoluteHorizontalPositionEstimate = newAbsoluteHorizontalPositionEstimate;
        if (newAbsoluteHorizontalPositionEstimate == true) {
            logs.push({ status: 'good', message: 'Absolute horizontal position estimate - GOOD', timsetamp: new Date(time_usec) });
        } else {
            logs.push({ status: 'bad', message: 'Absolute horizontal position estimate - BAD', timsetamp: new Date(time_usec) });
        }
    }

    // Absolute vertical position estimate
    let { absoluteVerticalPositionEstimate: currentAbsoluteVerticalPositionEstimate } = newStatus;
    const newAbsoluteVerticalPositionEstimate = (flags & 32) > 0;

    if (currentAbsoluteVerticalPositionEstimate !== newAbsoluteVerticalPositionEstimate) {
        shouldUpdate = true;
        newStatus.absoluteVerticalPositionEstimate = newAbsoluteVerticalPositionEstimate;
        if (newAbsoluteVerticalPositionEstimate == true) {
            logs.push({ status: 'good', message: 'Absolute horizontal position estimate - GOOD', timsetamp: new Date(time_usec) });
        } else {
            logs.push({ status: 'bad', message: 'Absolute horizontal position estimate - BAD', timsetamp: new Date(time_usec) });
        }
    }

    // Constant position mode
    let { constantPositionMode: currentConstantPositionMode } = newStatus;
    const newConstantPositionMode = (flags & 64) > 0;

    if (currentConstantPositionMode !== newConstantPositionMode) {
        shouldUpdate = true;
        newStatus.constantPositionMode = newConstantPositionMode;
        if (newConstantPositionMode == true) {
            logs.push({ status: 'info', message: 'Constant position mode - Enabled', timsetamp: new Date(time_usec) });
        } else {
            logs.push({ status: 'info', message: 'Constant position mode - Not enabled', timsetamp: new Date(time_usec) });
        }
    }

    // Pred Pos Horiz Rel
    let { predPosHorizRel: currentPredPosHorizRel } = newStatus;
    const newPredPosHorizRel = (flags & 128) > 0;

    if (currentPredPosHorizRel !== newPredPosHorizRel) {
        shouldUpdate = true;
        newStatus.predPosHorizRel = newPredPosHorizRel;
        if (newPredPosHorizRel == true) {
            logs.push({ status: 'good', message: 'Sufficient data for rel. horiz. pos. estimate', timsetamp: new Date(time_usec) });
        } else {
            logs.push({ status: 'bad', message: 'Insufficient data for rel. horiz. pos. estimate', timsetamp: new Date(time_usec) });
        }
    }

    // Pred Pos Horiz Rel
    let { predPosHorizAbs: currentPredPosHorizAbs } = newStatus;
    const newPredPosHorizAbs = (flags & 256) > 0;

    if (currentPredPosHorizAbs !== newPredPosHorizAbs) {
        shouldUpdate = true;
        newStatus.predPosHorizAbs = newPredPosHorizAbs;
        if (newPredPosHorizAbs == true) {
            logs.push({ status: 'good', message: 'Sufficient data for abs. horiz. pos. estimate', timsetamp: new Date(time_usec) });
        } else {
            logs.push({ status: 'bad', message: 'Insufficient data for abs. horiz. pos. estimate', timsetamp: new Date(time_usec) });
        }
    }

    // GPS Glitch
    let { gpsGlitch: currentGpsGlitch } = newStatus;
    const newGpsGlitch = (flags & 512) > 0;

    if (currentGpsGlitch !== newGpsGlitch) {
        shouldUpdate = true;
        newStatus.gpsGlitch = newGpsGlitch;
        if (newGpsGlitch == true) {
            logs.push({ status: 'bad', message: 'GPS Glitch detected', timsetamp: new Date(time_usec) });
        } else {
            logs.push({ status: 'good', message: 'No GPS Glitch', timsetamp: new Date(time_usec) });
        }
    }

    // Accelerator Error
    let { acceleratorError: currentAcceleratorError } = newStatus;
    const newAcceleratorError = (flags & 512) > 0;

    if (currentAcceleratorError !== newAcceleratorError) {
        shouldUpdate = true;
        newStatus.acceleratorError = newAcceleratorError;
        if (newAcceleratorError == true) {
            logs.push({ status: 'bad', message: 'Bad accelerometer data detected', timsetamp: new Date(time_usec) });
        } else {
            logs.push({ status: 'good', message: 'No bad accelerometer data detected', timsetamp: new Date(time_usec) });
        }
    }

    if (shouldUpdate) {
        aircraft.statusStateSetters.addLogs(logs);
	    aircraft.statusStateSetters.setStatusData(newStatus);

        console.log('LOGS');
        console.log(logs);
    }
}
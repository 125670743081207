import React, { useState,useContext } from 'react';
import { FMDashboardPanel } from '../fm-dashboard-panel/fm-dashboard-panel';

import { FleetManagementTableContent } from '../fleet-management-table-content/fleet-management-table-content';
import { FleetManagementContext } from '../../../../contexts/fleet-management-context';
import UtilityService from '../../../../helpers/utility-service';
import {personSchema} from '../../../../contexts/schemas/person-schema';

export const FMPersonnelPanel = () => {
    const {personnel} = useContext(FleetManagementContext);
    const [personnelFilter, setPersonnelFilter] = useState("");
    const onCreateItem = () => {

    }



    return(
        /* PERSONNEL  */
        <div className="fleet-management-card-wrap">
            <FMDashboardPanel 
                className='fleet-management-card tabbed-card' 
                onFilter={(filter) => setPersonnelFilter(filter || '')}  
                dashboardPanelToolbar={true}
                title='Personnel'
                entityName={personSchema.entityName}
                schema={personSchema }
                createItem= {()=>onCreateItem()}
                
                // {async (data) => {
                //     await fleetManagementService.createPerson(data);
                //     personnelTableRef.current.loadData();  
                // }}
            >
                    
                <FleetManagementTableContent    
                    data={personnel}
                    // loadData={()=>{}}
                    loadSingleItem= {()=> {}}//{(id) => fleetManagementService.fetchSinglePerson(id)}
                    updateSingleItem= {()=> {}}//{(id, updateObj) => fleetManagementService.updatePerson(id, updateObj)}
                    deleteSingleItem= {()=> {}}//{(id) => fleetManagementService.deletePerson(id)}
                    getTitle={(item) => item.firstName && item.lastName ? `${item.firstName} ${item.lastName}` : item.firstName || "Unnamed Person"}
                    schema = {personSchema}
                    filter = {personnelFilter}
                    filterFunction =  {(row, filter) => UtilityService.isRowFiltered(row,filter)}
                />
                
            </FMDashboardPanel>
        </div> 
    )
}
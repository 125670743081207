import React from "react";
import { Marker, Popup, Tooltip } from 'react-leaflet';
import ReactLeafletDriftMarker from "react-leaflet-drift-marker";
import Icons from "./icons";
import { Point } from "./point";

export class ADSBAircraft extends Point {
	constructor(latitude, longitude, label=null, seq=null) {
		super(latitude, longitude, 'ADSB', label, null, null, seq);
	}

	getIconUrl() {
		return Icons.regular.adsbAircraft;
	}

	getMarker(options) {
		return (
			<ReactLeafletDriftMarker
				duration={100} 
				className="adsb-aircraft-marker"
				key={`adsb-aircraft-marker-${this.id}`}
				eventHandlers={{
					click: (e) => {
						if (options.onClick) {
							options.onClick(e, this);
						}
					},
					dragend: (e) => {
						if (options.onPositionUpdated) {
							const { lat: latitude, lng: longitude } = e.target._latlng.wrap();
							options.onPositionUpdated(e, { latitude, longitude });
						}
					}
				}}
				draggable={false}
				position={[this.latitude, this.longitude]} 
				icon={Icons.map.adsbAircraft(options.heading)}
			>
				{ options.markerLabelsVisible &&
						<Tooltip 
						className="marker-tooltip"
						direction="bottom" 
						opacity={1} 
						// offset={[-15,15]}
						permanent
					>
						<p className="waypoint-tooltip">
							{ <span>{options.callsign}<br/>{options.altitude} AMSL</span> }
						</p>
					</Tooltip>
				}

				<Popup>
					<p><b>Altitude:</b> {options.altitude} AMSL</p>
					<p><b>Speed:</b> {options.velocity} KTS</p>
					<p><b>Vertical Rate:</b> {options.vertical_rate > 0 ? '+' + options.vertical_rate : options.vertical_rate} m/s</p>
					<p><b>Country:</b> {options.country}</p>
				</Popup>
			</ReactLeafletDriftMarker>
		)
	}
}
// HEARTBEAT (#0)
export function positionTargetGlobalInt(message, aircraft) {
    const { positionStateSetters } = aircraft;

	const {
        time_boot_ms,
		coordinate_frame,
        type_mask,
        lat_int,
        lon_int,
        alt,
	} = message;

    const { targetPosition } = aircraft.positionState.getState();
    const { latitude, longitude, altitude } = targetPosition || {};

    if (
        latitude == null && lat_int != 0 ||
        latitude != null && lat_int == 0 ||
        Math.abs(lat_int * 10e-8 - latitude) > 0.0000001 || 
        Math.abs(lon_int * 10e-8 - longitude) > 0.0000001 || 
        Math.abs(altitude - alt) > 0.0001
    ) {
        positionStateSetters.setTargetPosition({
            latitude: lat_int * 10e-8, 
            longitude: lon_int * 10e-8, 
            altitude: alt,
        });
    }
}

import React, { useEffect, useRef, useState } from 'react';

export const TelemetryPanel = ({ selectedAircraft }) => {
    const [generalState, setGeneralState] = useState({});
    const [batteryState, setBatteryState] = useState({});
    const [hudState, setHudState] = useState({});
    const [positionState, setPositionState] = useState({});
    const connectedAircraftId = useRef(null);

    console.log();

    useEffect(() => {
        if (selectedAircraft != null && (connectedAircraftId.current == null || (connectedAircraftId.current != selectedAircraft.aircraftId))) {
            connectedAircraftId.current = selectedAircraft.aircraftId;
            
            setGeneralState(selectedAircraft.generalState.getState());
            setHudState(selectedAircraft.hudState.getState());
            setBatteryState(selectedAircraft.batteryState.getState());
            setPositionState(selectedAircraft.positionState.getState());

            setTimeout(() => {
                selectedAircraft.generalState.subscribe(() => {
                    setGeneralState(selectedAircraft.generalState.getState());
                });

                selectedAircraft.hudState.subscribe(() => {
                    setHudState(selectedAircraft.hudState.getState());
                });

                selectedAircraft.batteryState.subscribe(() => {
                    setBatteryState(selectedAircraft.batteryState.getState());
                });

                selectedAircraft.positionState.subscribe(() => {
                    setPositionState(selectedAircraft.positionState.getState());
                })
            }, 500 );
        }
    }, [selectedAircraft]);

    // console.log();

    return (
        <div className='telemetry-panel'>
            <div className='secondary-display-section-header'>
                <span className='title'>Telemetry</span>

            </div>
            {connectedAircraftId.current == null ?
                <div className='telemetry-no-active-flights'>There are no active flights</div>
                :

                (!generalState.isConnected || selectedAircraft.connection == null) ?
                    <div className='telemetry-no-active-flights'>Aircraft not connected</div>
                    :
                    <div className='secondary-display-telemetry-data'>
                        <div className='telemetry-item'>
                            <span className='telemetry-item-title'>HDG</span>
                            <span className='telemetry-item-value'>{ hudState.heading }</span>
                        </div>
                        <div className='telemetry-item'>
                            <span className='telemetry-item-title'>ALT</span>
                            <span className='telemetry-item-value'>{ (hudState.altitude || 0).toFixed(2) } AMSL</span>
                        </div>
                        <div className='telemetry-item'>
                            <span className='telemetry-item-title'>GND SPD</span>
                            <span className='telemetry-item-value'>{ (hudState.groundspeed || 0).toFixed(2) } M/S</span>
                        </div>
                        <div className='telemetry-item'>
                            <span className='telemetry-item-title'>V/S</span>
                            <span className='telemetry-item-value'>{ (hudState.verticalSpeed || 0).toFixed(2) } M/S</span>
                        </div>
                        {/* <div className='telemetry-item'>
                            <span className='telemetry-item-title'>REL ALT</span>
                            <span className='telemetry-item-value'>{ positionState.relativeAltitude } ALP</span>
                        </div> */}
                        <div className='telemetry-item'>
                            <span className='telemetry-item-title'>RNG ALT</span>
                            <span className='telemetry-item-value'>{ positionState.rangefinderAltitude ? `${positionState.rangefinderAltitude.toFixed(2)} AGL` : 'N/A' } </span>
                        </div>
                        <div className='telemetry-data-section'>
                            <div className='telemetry-data-section-title'>Flight status</div>
                            <div className='telemetry-item'>
                                <span className='telemetry-item-title'>State</span>
                                <span className='telemetry-item-value'>{generalState.customMode || 'Preflight'}</span>
                            </div>
                            <div className='telemetry-item'>
                                <span className='telemetry-item-title'>ETA</span>
                                <span className='telemetry-item-value'>N/A</span>
                            </div>
                            <div className='telemetry-item'>
                                <span className='telemetry-item-title'>Time elapsed since boot</span>
                                {/* TODO: Get clock */}
                                <span className='telemetry-item-value'>{selectedAircraft.time_boot_ms != null ? new Date(selectedAircraft.time_boot_ms).toISOString().split('T')[1].split('.')[0] : 'N/A'}</span>
                            </div>
                        </div>
                        <div className='telemetry-data-section'>
                            <div className='telemetry-data-section-title'>Battery Status</div>
                            <div className='telemetry-item'>
                                <span className='telemetry-item-title'>Bat. Percentage</span>
                                <span className='telemetry-item-value'>{ batteryState.remaining }%</span>
                            </div>
                            <div className='telemetry-item'>
                                <span className='telemetry-item-title'>Flight time remaining</span>
                                <span className='telemetry-item-value'>N/A</span>
                            </div>
                            <div className='telemetry-item'>
                                <span className='telemetry-item-title'>Point of safe return</span>
                                <span className='telemetry-item-value'>RTL2</span>
                            </div>
                            <div className='telemetry-item'>
                                <span className='telemetry-item-title'>EQUI-time-point</span>
                                <span className='telemetry-item-value'>RTL2</span>
                            </div>
                        </div>
                        <div className='telemetry-data-section'>
                            <div className='telemetry-data-section-title'>C2 connection information</div>
                            <div className='telemetry-item'>
                                <span className='telemetry-item-title'>Connection type</span>
                                <span className='telemetry-item-value'>{ selectedAircraft.connection.connectionType}</span>
                            </div>
                            <div className='telemetry-item'>
                                <span className='telemetry-item-title'>Connection Strength</span>
                                {/* TODO: How to determine? */}
                                <span className='telemetry-item-value'>N/A</span>
                            </div>

                        </div>
                        <div className='telemetry-data-section'>
                            <div className='telemetry-data-section-title'>Payload information</div>
                            <div className='telemetry-item'>
                                <span className='telemetry-item-title'>Internal temperature</span>
                                <span className='telemetry-item-value'>N/A</span>
                            </div>
                        </div>
                    </div>}
        </div>
    )
}
import React, {useContext, useEffect, useRef, useState} from 'react';
import { SideMenu } from '../../../../shared-components/containers/side-menu/side-menu';
import { SideMenuItem } from '../../../../shared-components/containers/side-menu/components/side-menu-item/side-menu-item';
import Icons  from '../../../../helpers/icons';
import { AircraftContext } from '../../../../contexts/aircraft-context';
import { Tooltip } from '@mui/material';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { OptionsCheckbox } from '../../../../shared-components/inputs/options-checkbox/options_checkbox';
import { Download, Insights, PlayCircleFilledOutlined, PlayCircleOutline, Polyline, Timeline, Upload } from '@mui/icons-material';
import { motion } from "framer-motion";

export const CockpitToolbar = () => {
    const [currentTool, setCurrentTool] = useState(null);
		const { selectedAircraft } = useContext(AircraftContext);
		const currentAircraft = useRef(null);

		const [isArmed, setIsArmed] = useState(false);
		const [missionState, setMissionState] = useState(null);
		const [isAirborn, setIsAirborn] = useState(false);
		const subscriptions = useRef([]);
    // const [preflightPanel, setPreflightPanel] = useState(false);

		useEffect(() => {
			if (selectedAircraft != currentAircraft.current) {
				currentAircraft.current = selectedAircraft;

				if (currentAircraft.current != null) {
					for (const sub of subscriptions.current) {
						sub();
						subscriptions.current = [];
					}
				}

				if (selectedAircraft != null) {
					const { isArmed: isArmedState } = selectedAircraft.generalState.getState();
					setIsArmed(isArmedState);
					subscriptions.current.push(selectedAircraft.generalState.subscribe(() => {
						const { isArmed: isArmedState } = selectedAircraft.generalState.getState();
						setIsArmed(isArmedState);
					}))

					setMissionState(selectedAircraft.missionState.getState());

					subscriptions.current.push(selectedAircraft.missionState.subscribe(() => {
						setMissionState(selectedAircraft.missionState.getState());
					}))

					const { systemStatus } = selectedAircraft.generalState.getState();
					if (systemStatus != null && systemStatus.label == 'Active') {
						setIsAirborn(true);
					} else {
						setIsAirborn(false);
					}
					
					subscriptions.current.push(selectedAircraft.generalState.subscribe(() => {
						const { systemStatus } = selectedAircraft.generalState.getState();
						if (systemStatus != null && systemStatus.label == 'Active') {
							setIsAirborn(true);
						} else {
							setIsAirborn(false);
						}
					}));
				} else {
					setIsArmed(null);
				}
			}
		}, [selectedAircraft])

    return(
		
		<motion.div 
			className="cockpit-toolbar"
			key="cockpit-toolbar"
			initial={{ x: -150, opacity: 0 }}
			animate={{ x: 0, opacity: 1 }}
			exit={{ x: -150, opacity: 0 }}
			transition={{ duration: 0.2, ease: "easeOut", delay: 0.3 }}>
        <SideMenu 
			key="cockpit-toolbar"
			className="cockpit-toolbar"
			direction="left"
		>
			{/* Mission plan */}
			{/* <Tooltip title="Select Path" arrow/> */}
  				<SideMenuItem
				  	hasPanel
					onClick={() => {currentTool == 'path'? setCurrentTool(null) : setCurrentTool('path');}}
					isActive={currentTool === 'path'}
					// icon={<img src={Icons.cockpit.path} alt='path'/>}
					icon={<Timeline className='full-size'/>}
					panelTitle="Select Path"
					actions= {[{label: 'Upload',  blink: missionState && missionState.selectedFlightPath != null, action: () => selectedAircraft.uploadMissionPlan() }, {label: "Clear", action: () => { selectedAircraft && selectedAircraft.clearMissionItems()}}]}
					title="Path Selection"
					tooltipPosition='left'
				>
					 <div className='cockpit-select-path-wrap' >
						 <PerfectScrollbar>
							<OptionsCheckbox 
								title='Choose a Path' 
								options={missionState ? missionState.flightPaths : []} 
								optionSelected={missionState ? missionState.selectedFlightPath : null}
								onChange={(selectedPath) => selectedAircraft.selectFlightPath(missionState.selectedFlightPath == selectedPath ? null : selectedPath)}
								emptyListMessage="No flight paths loaded"
							/>
						</PerfectScrollbar>
					</div>
					<p className="click" onClick={() => selectedAircraft.downloadMissionPlan()}>Download current path</p>
				</SideMenuItem>
			
			{/* <SideMenuItem
				// onClick={() => { selectedAircraft && selectedAircraft.takeOff()}}
				hasPanel
				onClick={() => {currentTool == 'to-land'? setCurrentTool(null) : setCurrentTool('to-land');}}
				isActive={currentTool === 'to-land'}
				actions= {[{label: 'Take off', action: () => selectedAircraft && selectedAircraft.takeOff() }, { label: "Land", action: () => selectedAircraft && selectedAircraft.setFlightMode('LAND') }]}
				// icon={<img src={Icons.cockpit.upload} alt='upload-mission'/>}
				title="Take off / Land"
				panelTitle={"Select Action"}
				icon={<Upload className='full-size'/>}
                tooltipPosition='left'
			/> */}
			{ isAirborn ?
				<SideMenuItem
					onClick={() => selectedAircraft && selectedAircraft.setFlightMode('LAND')}
					icon={<Download/>}
					title="Land"
					tooltipPosition='left'
				/>
				:
				<SideMenuItem
					onClick={() => selectedAircraft && selectedAircraft.takeOff()}
					icon={<Upload/>}
					title="Take off"
					tooltipPosition='left'
				/>
			}
			<SideMenuItem
				onClick={() => selectedAircraft && selectedAircraft.setFlightMode('RTL')}
				isActive={currentTool === 'rtl'}
				icon={<img src={Icons.cockpit.rtl} alt='rtl'/>}
				title="RTL"
                tooltipPosition='left'
			/>
			<SideMenuItem
				onClick={() => selectedAircraft != null && (isArmed ? selectedAircraft.disarm() : selectedAircraft.arm())}
				icon={<img src={isArmed ? Icons.cockpit.disarm : Icons.cockpit.arm} alt='arm'/>}
				title={isArmed ? "Disarm" : "Arm"}
                tooltipPosition='left'
			/>
			<SideMenuItem
				onClick={() => selectedAircraft != null && selectedAircraft.setFlightMode('AUTO')}
				// icon={<img src={Icons.cockpit.start} alt='start'/>}
				icon={<PlayCircleOutline className='full-size'/>}
				title="Start Mission"

                tooltipPosition='left'
			/>
			
        </SideMenu>
		</motion.div>
    )
}
import { toast } from 'react-toastify';
import MAVLinkConstants from '../../../protocol-services/mavlink-constants';

// MISSION_COUNT (#44)
export function missionCount (message, aircraft) {
	const {
		count,
		mission_type, // * MAV2 extension
	} = message;

	const missionTypeLabel = mission_type == null ? 'mission' : mission_type == MAVLinkConstants.MAV_MISSION_TYPE.MAV_MISSION_TYPE_MISSION ? 'mission' : 'rally';

	// MAV2
	if (aircraft.expectingMissionTypeItems != missionTypeLabel) {
		console.log('Unexpected mission count');
		return;
	}

	aircraft.missionCount[missionTypeLabel] = count;
	aircraft.pendingUploadedItems[missionTypeLabel] = [];

	// 		this.vehicleState.operationProgress = 0;
	aircraft.stopTimeout(`download-${missionTypeLabel}-items`);
	const toastIdLabel = missionTypeLabel == 'mission' ? 'downloadMissionItemsToastId' : 'downloadRallyItemsToastId';

	// Request first mission item
	if (count > 0) {
		if (aircraft.toastIds[toastIdLabel] == null) {
			aircraft.toastIds[toastIdLabel] = toast.loading(`Downloading ${missionTypeLabel == 'mission' ? 'mission' : 'rallypoint'} items`, { loading: true, autoClose: false });
			aircraft.requestMissionItem(0, missionTypeLabel);
		}
	} else {
		if (missionTypeLabel == 'mission') {
			aircraft.pendingUploadedItems.mission = [];
			toast.info('No mission items found on the aircraft');
			if (mission_type != null) {
				aircraft.downloadMissionPlan('rally');
			} else {
				aircraft.expectingMissionTypeItems = null;
				aircraft.decodeDownloadedMission();
			}
		} else {
			toast.info('No rally items found on the aircraft');
			aircraft.expectingMissionTypeItems = null;
			aircraft.pendingUploadedItems.rally = [];
			aircraft.decodeDownloadedMission();
		}
	}

}
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Stack } from '../../../../layout/stack/stack';
import { Download, FastForward, Height, PauseCircle, Power, Speed, Upload } from '@mui/icons-material';
import Icons  from '../../../../../helpers/icons';
import { useNavigate } from 'react-router-dom';
import { MapContext } from '../../../../../contexts/map-context';
import { AircraftContext } from '../../../../../contexts/aircraft-context';

export const ActiveFlightItem = ({ aircraft }) => {
    const { selectedAircraft, setSelectedAircraft } = useContext(AircraftContext);
    const { moveToLocation } = useContext(MapContext);
    const navigate = useNavigate();
    const [isConnected, setIsConnected] = useState(false);
    const [connectionType, setConnectionType] = useState(null);
    const { aircraftName } = aircraft;
    const currentAircraft = useRef(null);
    const [generalState, setGeneralState] = useState({});
    const [isAirborn, setIsAirborn] = useState(false);
    const [aircraftStatus, setAircraftStatus] = useState("Unknown");
    const [isArmed, setIsArmed] = useState("disarmed");
    const [airspeed, setAirspeed] = useState(null);
    const [altitude, setAltitude] = useState(null);;
    useEffect(() => {
        if (aircraft != currentAircraft.current) {
            currentAircraft.current = aircraft;
            if (aircraft != null) {
                setIsConnected(aircraft.generalState.getState().isConnected);
                setConnectionType(aircraft.generalState.getState().connectionType);
                aircraft.generalState.subscribe(() => {
                    setIsConnected(aircraft.generalState.getState().isConnected);
                    setConnectionType(aircraft.generalState.getState().connectionType);
                })
            } else {
                setIsConnected(false);
                setConnectionType(null);
            }

            if (currentAircraft.current != null) {

                currentAircraft.current.generalState.subscribe(() => {
                    // console.log("GENERAL STATE",currentAircraft.current.generalState.getState());
                    if(currentAircraft.current.generalState.getState() != null) {
                        setIsAirborn(currentAircraft.current.generalState.getState().systemStatus != null && currentAircraft.current.generalState.getState().systemStatus.label == "Active");
                        setAircraftStatus(currentAircraft.current.generalState.getState().customMode);
                        setIsArmed(currentAircraft.current.generalState.getState().isArmed);
                    }
                   
                  
                    // console.log("GENERAL STATE OF CURR AIR",currentAircraft.current.generalState.getState());
                })
                currentAircraft.current.hudState.subscribe(() => {
                    if(currentAircraft.current.hudState.getState() != null) {
                        setAirspeed(currentAircraft.current.hudState.getState().airspeed.toFixed(1));
                        setAltitude(currentAircraft.current.hudState.getState().altitude.toFixed(2));
                    }
                    // console.log("GENERAL STATE OF CURR AIR",currentAircraft.current.positionState.getState());
                })

            }
            //speed alt iz hud 
            //arm disarm iz general state
            // sys state u general state kaze da li je isAirborn 
        }
    })



    const eta = 'N/A';

    const selectAircraft = (aircraft) => {
        moveToLocation(
            aircraft.positionState.getState() != null && aircraft.positionState.getState()
        );
        setSelectedAircraft(aircraft);
    }
   
    const getAircraftStatus = () => {

    }
    // console.log(aircraft);
    return(
        <Stack
         className={`active-flight-item clickable ${aircraft == selectedAircraft ? 'selected' : ''}`}
         direction='horizontal' 
         halign="start"
         valign={"start"}
         onClick={()=>selectAircraft(aircraft)}
         fullSize>
            <div className='active-flight-icon'>
                <img src={Icons.regular.aircraftMarkerIcon} alt='aircraft' />
                </div>
                <div className='active-flight-item-data' style={{ borderRightColor: aircraft.color }}>
                <Stack direction='horizontal' halign='space-between' v-align='end'>
                    
                        <span style={{ color: isConnected ? 'lime' : 'red'}} className='active-flight-aircraft uppercase white'>{aircraftName}</span>
                        {/* {eta && <p className={`eta bold uppercase`}>ETA {eta}</p>} */}
                        <span className={`connected-status bold uppercase ${isConnected == 'connected' && "confirm" }`}>{connectionType}</span>
                </Stack>
                <Stack direction='horizontal' halign='space-between' valign='center'>
                    
                    {/* <p className={`connected-status bold uppercase ${isConnected == 'connected' && "confirm" }`}>{connectionType} - {
                    isConnected ? 
                        <span style={{ color: 'lime'}}>Connected</span>
                    : 
                        <span style={{ color: 'red' }}>Not connected</span>} */}
                    <div className='active-flight-item-status'>
                        <Stack direction='vertical' halign='flex-start' valign='flex-start'>
                        
                            <div className='active-flight-item-spd'>
                                <p className='title'>SPD</p>
                                <p>{airspeed} m/s</p>
                            </div>
                            <div className='active-flight-item-alt'>
                                <p className='title'> ALT</p>
                                <p>{altitude} AMSL</p>
                            </div>
                        </Stack>
                        {/* <div>
                            <div className='active-flight-item-status-label'>
                                <span>Armed: {isArmed ? "armed" : "disarmed"}</span>
                            </div>
                            <div className='active-flight-item-status-label'>
                                <span>Airborn: {isAirborn ? 'airborn' : "grounded"}</span>
                            </div>
                            <div className='active-flight-item-status-label'>
                                <span>Aircraft Status: {aircraftStatus ? aircraftStatus : 'unknown'}</span>
                            </div>
                         
                        </div> */}
                       
                    </div>
                   
                   {
                    aircraft == selectedAircraft &&
                    <Power className={`disconnect-icon disconnect`} onClick={()=> aircraft.disconnect()}/>
                   }
                </Stack>
            </div>
        </Stack>
    )
}
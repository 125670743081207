import axios from "axios";
import htmlToJson from "html-to-json";
import convert from 'xml-js';

import React, { createContext, useContext, useRef, useState } from "react";
import { ConfigContext } from "./config-context";
import { AuthContext } from "./auth-context";
import UtilityService from "../helpers/utility-service";
import { AuthContextV2 } from "./auth-context-v2";

export const AirportContext = createContext();

const AirportContextProvider = ({ children }) => {
	const { services: {
		airportService: 
		{ 
			notamHost,
			tafHost,
			metarHost, 
			minReportAge, 
			maxReportAge, 
			maxReportValidityRadius, 
			maxAirportProximity
		 },
		 corsService: {
			host: corsHost,
			port: corsPort,
			scope: corsScope, 
			audience: corsAudience,
		}
		},
	 } = useContext(ConfigContext);

	const { getToken } = useContext(AuthContextV2)

	const isLoadingData = useRef(false);

	const [metar, setMetar] = useState(null);
	const [taf, setTaf] = useState(null);
	const [notam, setNotam] = useState(null);
	const [sigmet, setSigmet] = useState(null);
	const [error, setError] = useState(null);

	const [isBusy, setIsBusy] = useState(false);
	const [hasLoaded, setHasLoaded] = useState(false);

	const airportCode = useRef(null);
	const lastUpdate = useRef(null);
	const lastLocation = useRef(null);

	// Fetch new airport report
	const reloadAirportData = ({ latitude, longitude}) => {
		const token = getToken(corsScope, corsAudience);
		if (token == null) { 
			return;
		}
		if (!isBusy && !isLoadingData.current) {
			isLoadingData.current = true;

			// Fetch latest METAR report
			getReport('metar', { latitude, longitude })
			.then(metar => {
				const rawObj = JSON.parse(convert.xml2json(metar.data, {compact: true, spaces: 4}));
				const metarData = rawObj.response.data.METAR || null;

				if (metarData != null) {
					airportCode.current = metarData.station_id._text;
				}

				setMetar(metarData ? metarData.raw_text._text : null);

				// Fetch latest TAF reports
				getReport('taf', { latitude, longitude })
				.then(taf => {
					const rawObj = JSON.parse(convert.xml2json(taf.data, {compact: true, spaces: 4}));
					const tafData = rawObj.response.data.TAF || null;

					if (tafData && tafData.length > 0 && airportCode.current != null) {
						airportCode.current = tafData.forecast[0].station_id._text;
					}

					setTaf(tafData ? tafData.raw_text._text : null);

					// Fetch latest SIGMET reports
					getReport('airsigmet', { latitude, longitude })
					.then(sigmet => {
						const rawObj = JSON.parse(convert.xml2json(sigmet.data, {compact: true, spaces: 4}));
						const sigmetData = rawObj.response.data.AIRSIGMET || null;
						setSigmet(sigmetData ? sigmetData.raw_text._text : null);

						// Fetch latest NOTAM reports
						getReport('notam', { latitude, longitude }, airportCode.current)
						.then(notam => {
							htmlToJson.parse(notam.data, {
									notams: htmlToJson.createParser([`pre`, {
											text:  function (notam) {
													return notam.text();
											}
									}])
							}).then(parsedNotam => {
								lastUpdate.current = UtilityService.getCurrentTime(true);
								lastLocation.current = { latitude, longitude };

								setNotam(parsedNotam.notams.filter);
								setHasLoaded(true);
								setIsBusy(false);
								isLoadingData.current = false;
							});
						})
					})
				})
			}).catch(err => {
				setError(err.message);
				setIsBusy(false);
				isLoadingData.current = false;
			});
		}
	}

	const getReport = (reportType, { latitude, longitude }, airportCode = null) => {
		const token = getToken(corsScope, corsAudience);
		if (token == null) {
			return null;
		}
		switch(reportType) {
			case 'metar':
			case 'taf':
			case 'airsigmet':
				const radialDistance = `${maxAirportProximity};${longitude.toFixed(4)},${latitude.toFixed(4)}`;
				return axios.get(`${corsHost}:${corsPort}/cors`, {
					params: {
						url: reportType === 'metar' ? metarHost : tafHost,
						dataSource: `${reportType}s`,
						requestType: 'retrieve',
						mostRecent: true,
						format: 'xml',
						hoursBeforeNow: Math.ceil(maxReportAge / 3600),
						radialDistance,
					},
					headers: {
						Authorization: `Bearer ${token}`
					}
				});

			case 'notam':
				const bodyFormData = new FormData();
        
				bodyFormData.append('geoIcaoLocId', airportCode);
        bodyFormData.append('geoIcaoRadius', maxAirportProximity);
        bodyFormData.append('actionType', 'radiusSearch');

				return axios.post(`${corsHost}:${corsPort}/cors?url=${notamHost}&multipart=true`, bodyFormData, {headers: { "Content-Type": "multipart/form-data", Authorization: `Bearer ${token}` }});
		}
		

		
	}

	const shouldUpdateAirportData = ({ latitude, longitude}) => {
		const now = UtilityService.getCurrentTime(true);

		if (lastUpdate.current != null // Old report exists
			&& (
				!isBusy && UtilityService.distance(lastLocation.current, { latitude,longitude }) < maxReportValidityRadius) // Is report geographically close to the previous one 
				&& now - lastUpdate.current <= minReportAge
			)
		{
			return false;
		}

		return true
	}

	return (
		<AirportContext.Provider value={{ 
			airportCode: airportCode.current, 
			metar, 
			taf, 
			sigmet, 
			notam, 
			reloadAirportData, 
			shouldUpdateAirportData,
			lastUpdateTimestamp: lastUpdate.current,
			isBusy,
			hasLoaded,
			error,
			}
		}>
			{ children }
		</AirportContext.Provider>
	)
}

export default AirportContextProvider;
import { Close } from '@mui/icons-material';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { CockpitContext } from '../../../../contexts/cockpit-context';
import { FleetManagementContext } from '../../../../contexts/fleet-management-context';
import { MissionContext } from '../../../../contexts/mission-context';


export const AddFlightPanel = ({onClose, onEndMissionPlanning}) => {
    const { setMissionPlanningAircraft } = useContext(CockpitContext);
    const {selectedPath} = useContext(CockpitContext);
    const [missionSelectionVisible,setMissionSelectionVisible] = useState(false);
    const [aircraftSelectionVisible, setAircraftSelectionVisible] = useState(true);
    // const [ynActive, setYNActive] = useState(false);
    const [revenueGenerating, setRevenueGenerating] = useState(false);
    const [selectedMission, setSelectedMission] = useState(false);
    const [compatible, setCompatible] = useState(false);

    const { allAircraft } = useContext(FleetManagementContext);

    const missionData = useRef({ payload: { unit: 'g' }, revenueGenerating: false});

    useEffect(() => {
        if (allAircraft != null && allAircraft.length > 0 && missionData.current.aircraft == null) {
            missionData.current.aircraft = allAircraft[0];
        }
    }, [allAircraft]);

    const confirmAircraftAndPayload = () => {
        const { aircraft, payload: { title: payloadTitle, weight: payloadWeight } } = missionData.current;

        if (aircraft == null) {
            toast.error('Aircraft not selected');
            return;
        }

        if (payloadTitle == null || payloadTitle.trim().length === 0) {
            toast.error('Missing payload title');
            return;
        }

        if (payloadWeight == null || parseFloat(payloadWeight) < 0) {
            toast.error('Missing or invalid payload weight');
            return;
        }

        // console.log(missionData.current);

        setAircraftSelectionVisible(false);
        setMissionSelectionVisible(true);
        setMissionPlanningAircraft(missionData.current.aircraft);
    }
    const onNewMissionSelected = () => {
        console.log(missionData.current);
        onEndMissionPlanning(missionData.current);
    }
    return(
        <div className='add-flight-panel'>
             <div className='secondary-display-section-header'>
                <span className='title'>Add Flight</span>
                <Close className='close-panel' onClick={() => { setMissionPlanningAircraft(null); onClose() }}/>
            </div>
            <div className='add-flight-panel-content'>
                {aircraftSelectionVisible && 
                <div className='add-flight-section-wrap'>
                    <div className='add-flight-section'>
                        <span className='item-title'>Aircraft</span>
                        <select className="aircraft-select" onChange={(e) => missionData.current.aircraft = allAircraft[e.target.value]}>
                            {
                                allAircraft.map((ac, index) => (
                                    <option key={`aircraft-${ac.data.name}-${index}`} value={index}>{ ac.data.name }</option>
                                ))
                            }
                        </select>
                    </div>
                    <div className='add-flight-payload-information'>
                        <span className='title'>Payload</span>
                        <div className='add-flight-payload-box'>
                            <div className='add-flight-section'>
                                <span className='item-title'>Payload title</span>
                                <input onChange={(e) => missionData.current.payload.title = e.target.value.trim()}/>
                            </div>
                            <div className='add-flight-section'>
                                <span className='item-title'>Payload Weight</span>
                                <input type="number" step={0.1} className='payload-weight-input' onChange={(e) => missionData.current.payload.weight = parseFloat(e.target.value)}/>
                                <select className='payload-weight-unit' onChange={(e) => missionData.current.payload.unit = e.target.value}>
                                    <option value="g">g</option>
                                    <option value="kg">Kg</option>
                                </select>
                            </div>
                            <div className='add-flight-section'>
                                <span className='item-title'>Revenue generating</span>
                                <div className='yes-no-box'>
                                    <span className={`yes-no ${revenueGenerating && 'active'}`} onClick={() => { setRevenueGenerating(true); missionData.current.revenueGenerating = true; }}>YES</span> |  
                                    <span className={`yes-no ${!revenueGenerating && 'active'}`} onClick={() => { setRevenueGenerating(false); missionData.current.revenueGenerating = false; }}>NO</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='full-color-button yellow' onClick={() => confirmAircraftAndPayload()}>Confirm Aircraft and Payload</div>
                </div>}
                {missionSelectionVisible &&
                    <div className='mission-selection-section-wrap'>
                         <div className='add-flight-section'>
                            <span className='item-title'>Mission</span>
                            
                            <input className={`uppercase mission-selected ${selectedPath && 'selected'}`} placeholder={selectedPath ? selectedPath.mission : "Not selected"} disabled/>
                        </div>
                        <p>Select mission by choosing the route from the map</p>
                        
                        {selectedPath != null && 
                        <div className='mission-selection-details-wrap'>
                            <span className='title' style={{fontSize:"12px"}}>Route Information</span>
                            <div className='add-flight-section'>
                                <span className='item-title'>Auth. Route ID</span>
                                <span className='mission-selection-detail-value'>{selectedPath.title}</span>
                            </div>
                            {/* <div className='add-flight-section'>
                                <span className='item-title'>Est. time of flight</span>
                                <span className='mission-selection-detail-value'>00:00:00</span>
                            </div> */}
                            <div className='add-flight-section'>
                                <span className='item-title'>Min altitude</span>
                                <span className='mission-selection-detail-value'>{Math.min(...selectedPath.waypoints.map(wp => wp.altitude))} ALP</span>
                            </div>
                            <div className='add-flight-section'>
                                <span className='item-title'>Max altitude</span>
                                <span className='mission-selection-detail-value'>{Math.max(...selectedPath.waypoints.map(wp => wp.altitude))} ALP</span>
                            </div>
                            <div className='add-flight-section'>
                                <span className='item-title'>Min speed</span>
                                <span className='mission-selection-detail-value'>{Math.min(...selectedPath.waypoints.map(wp => wp.speed))} M/S</span>
                            </div>
                            <div className='add-flight-section'>
                                <span className='item-title'>Max speed</span>
                                <span className='mission-selection-detail-value'>{Math.max(...selectedPath.waypoints.map(wp => wp.speed))} M/S</span>
                            </div>
                            <div className='add-flight-section'>
                                <span className='item-title'>A/C compatibility</span>
                                <span className={`mission-selection-detail-value ${compatible && 'compatible'}`}>Compatible</span>
                            </div>
                            
                        </div>}
                        {selectedPath != null && <div className='full-color-button yellow' onClick={() => onNewMissionSelected()}>Confirm Mission</div>}
                    </div>}
                
            </div>
        </div>
    )
}
import { createContext, useContext, useEffect, useRef, useState } from "react";
import axios from "axios";
import { ConfigContext } from "./config-context";
import { MapContext } from "./map-context";
import Cookies from 'js-cookie';

export const RIDContext = createContext();

const RIDContextProvider = ({ children }) => {
    const {
        services: {
            telemetryService: {
                host,
                port,
            }
        }
    } = useContext(ConfigContext);

    const { currentBounds } = useContext(MapContext);

    const [visibleFlights, setVisibleFlights] = useState([]);
    const [selectedFlight, setSelectedFlight] = useState(null);
    const [isBusy, setIsBusy] = useState(false);
    const [error, setError] = useState(null);

    const lastBoundariesData = useRef(null);

    const refresher = useRef(null);

    const getLatestReport = async (flight) => {
        // console.log(flight);
        const res = await axios.get(`${host}:${port}/telemetry/${flight.id}`);

        // TODO: Check expiration!

        if (flight != null) {
            if (flight.reports != null && flight.reports[0] != null && new Date() - new Date(flight.reports[0].timestamp) > 10000) {
                flight.stale = true;
            } else {
                flight.stale = false;
            }
            flight.reports = res.data.data.reports;
        }
    }

    // const getAllActiveFlights = () => {
    //     setIsBusy(true);
    //     axios.get(`${host}:${port}/flights`)
    //         .then((res) => {
    //             console.log(res);
    //             setActiveFlights(res.data.data.flights);
    //             setIsBusy(false);
    //             setError(null);
    //         })
    //         .catch((err) => {
    //             setError(err.message)
    //             setIsBusy(false); 
    //         });

    // }

    const getFlightsInBoundaries = (boundaries) => {
        return null;
        const aircraftList = Cookies.get('connected-aircraft');

        if (lastBoundariesData.current != null) {
            clearTimeout(lastBoundariesData.current.timeout);
        }

        lastBoundariesData.current = {
            boundaries,
            timeout: setTimeout(() => getFlightsInBoundaries(boundaries), 3000)
        };

        let parsedAircraftList = null;
        if (aircraftList != null) {
            parsedAircraftList = JSON.parse(aircraftList);
        }
        const serializedBoundaries = [boundaries[0], boundaries[2]].map(point => `${point.latitude},${point.longitude}`).join(',');
        axios.get(`${host}:${port}/flights`, { params: { boundaries: serializedBoundaries } })
            .then(async (res) => {
                // console.log(res);
                const flights = res.data.data.flights;
                const newFlights = flights
                    .filter(flight => (parsedAircraftList || []).find(ac => ac.regNum == flight.aircraft.registration_number) == null);

                // for (const newFlight of newFlights) {
                //     const existingFlight = visibleFlights.find(el => el.id == newFlight.id);
                //     if (existingFlight != null) {
                //         newFlight.reports = existingFlight.reports;
                //     }
                // }

                // setVisibleFlights(newFlights);

                if (refresher.current != null) {
                    clearInterval(refresher.current);
                }

                if (newFlights.length == 0) {
                    setVisibleFlights([])
                    return;
                }

                refresher.current = setInterval(async () => {
                    const requests = [];
                    for (const flight of newFlights) {
                        requests.push(getLatestReport(flight));
                    }

                    Promise.all(requests).then(() => setVisibleFlights([...newFlights]))
                }, 1000);

                setError(null);
            })
            .catch((err) => {
                setError(err.message)
            });
    }

    // useEffect(() => {
    //     getAllActiveFlights();
    // }, []);

    useEffect(() => {
        if (currentBounds != null) {
            getFlightsInBoundaries(currentBounds);
        }
    }, [currentBounds]);

    return (
        <RIDContext.Provider value={{ visibleFlights, isBusy, error, selectedFlight, setSelectedFlight }}>
            {children}
        </RIDContext.Provider>
    )
}

export default RIDContextProvider;
// ATTITUDE (#30)
export function attitude (message, aircraft) {
	const {
		pitch,
		roll,
		yaw,
	} = message;

	const processedPitch = pitch * 180 / Math.PI;
	const processedRoll = roll * 180 / Math.PI;
	const processedYaw = yaw * 180 / Math.PI;

	const { attitudeStateSetters } = aircraft;

	aircraft.attitudeStateSetters.setAttitude({ 
		pitch: processedPitch,
		roll: processedRoll,
		yaw: processedYaw,
	})
}
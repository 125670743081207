import React,{useState} from 'react';
import searchIcon from '../../../assets/fleet-managment-icons/search.svg';
import plusIcon from '../../../assets/fleet-managment-icons/plus.svg';
import moreIcon from '../../../assets/fleet-managment-icons/more.svg';
import ReactDOM from 'react-dom';
import { Fullscreen, FullscreenExitOutlined } from '@mui/icons-material';

export const PanelTools = ({fullscreenActive, onFilter, openModalFunction, fullscreenTable}) => {
    const [active, setActive] = useState(false);
    const [fullscreen, setFullscreen] = useState(fullscreenActive);
    const toggleSearch = () => {
        setActive(!active)
    }
   
       
        return (
            <div className='panel-tools'>
                <div className='panel-tools-icon panel-search-wrap'>
                    <img src={searchIcon} alt='search' onClick={() => toggleSearch()}/>
                    <input 
                        type='text' 
                        className={"panel-search-bar " + (active && "active")}
                        onChange={(e) => onFilter && onFilter(e.target.value.trim())}
                    />
                </div>
                <div className='panel-tools-icon' onClick={()=>openModalFunction()}>
                    <img src={plusIcon} alt='plus'/>
                </div>
                <div className='panel-tools-icon'>
                    <img src={moreIcon} alt='more'/>
                </div>
                {/* { !fullscreen && 
                    <Fullscreen 
                        className='panel-tools-icon' 
                        onClick={()=>{setFullscreen(true); fullscreenTable(!fullscreen)}} 
                    />}  */}
                {/* { fullscreen && 
                    <FullscreenExitOutlined 
                        className='panel-tools-icon' 
                        onClick={()=>{setFullscreen(false); fullscreenTable(fullscreen)}}/>
                        // fullscreenTable()}
                } */}
            </div>
        );
    }

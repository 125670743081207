import React from 'react';
// import { Titlebar, Color } from 'custom-electron-titlebar';;
import { render } from 'react-dom';

import {App} from './App';
import { BrowserRouter } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { StyledEngineProvider } from '@mui/material/styles';

import AuthContextProvider from './contexts/auth-context';
import ConfigContextProvider from './contexts/config-context';
import AuthContextProviderV2 from './contexts/auth-context-v2';

// window.addEventListener('DOMContentLoaded', () => {
//   new Titlebar({
// 		backgroundColor: Color.fromHex('#001742'),
//   });
// });

// Since we are using HtmlWebpackPlugin WITHOUT a template, we should create our own root node in the body element before rendering into it
let root = document.createElement('div');

root.id = 'root';
document.body.appendChild(root);
const customHistory = createBrowserHistory;

// Now we can render our application into it
render(
	<ConfigContextProvider>
		
		<StyledEngineProvider injectFirst>
			<BrowserRouter history={customHistory}>
				<AuthContextProviderV2>
					<App />
				</AuthContextProviderV2>
			</BrowserRouter>
		</StyledEngineProvider>
	</ConfigContextProvider>, document.getElementById('root'))


// import React from 'react';
// import ReactDOM from 'react-dom/client';
// import './index.css';
// import App from './App';
// import reportWebVitals from './reportWebVitals';

// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// );

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

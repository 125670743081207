import React, { useContext, useState } from 'react';
import { FleetManagementContext } from '../../../../contexts/fleet-management-context';
import UtilityService from '../../../../helpers/utility-service';
import { PanelTabs, Tab } from '../../../../shared-components/containers/panel-tabs/panel-tabs';
import { FleetManagementTableContent } from '../fleet-management-table-content/fleet-management-table-content';
import { FMDashboardPanel } from '../fm-dashboard-panel/fm-dashboard-panel';
import {batterySchema} from '../../../../contexts/schemas/battery-schema';
import {batterySetSchema} from '../../../../contexts/schemas/battery-set-schema';

export const FMBatteryPanel = () => {
    const { batteries } = useContext(FleetManagementContext);
    const {batterySets} = useContext(FleetManagementContext);
    const [batteryTab, setBatteryTab] = useState("Batteries");
	const [batteriesFilter, setBatteriesFilter] = useState("");
	const [batterySetsFilter, setBatterySetsFilter] = useState("");
    return(
        //   {/* BATTERY & BATTERY SET */}
         <div className="fleet-management-card-wrap">
            
            <FMDashboardPanel 
                className='fleet-management-card tabbed-card' 
                onFilter={batteryTab === 'Batteries' ? (filter) => setBatteriesFilter(filter): (filter) => setBatterySetsFilter(filter)}  
                dashboardPanelToolbar={true}
                entityName={batteryTab === 'Batteries' ? batterySchema.entityName : batterySetSchema.entityName}
                schema={batteryTab === 'Batteries' ? batterySchema : batterySetSchema }
                createItem={() => {}}
                // {async (data) => {
                //     if(batteryTab === 'Batteries') {
                //         await fleetManagementService.createBattery(data);
                //         batteriesTableRef.current.loadData();
                //     }
                //     else { 
                //         await fleetManagementService.createBatterySet(data);
                //         batterySetsTableRef.current.loadData();
                //     }
                    
                // }}
            >
                <PanelTabs onOpen={(tabTitle) => setBatteryTab(tabTitle)}>
                    <Tab title='Batteries' key='batteries-table-tab'>
                        <FleetManagementTableContent
                            //ref={batteriesTableRef}
                            data={batteries}//{() => fleetManagementService.fetchAllBatteries()}
                            loadSingleItem={()=>{}}//{(id) => fleetManagementService.fetchSingleBattery(id)}
                            updateSingleItem={()=>{}}//{(id, updateObj) => fleetManagementService.updateBattery(id, updateObj)}
                            deleteSingleItem={()=>{}}//{(id) => fleetManagementService.deleteBattery(id)}
                            getTitle={(item) => item.name || "Unnamed Battery"}
                            schema = {batterySchema}
                            filter = {batteriesFilter}
                            filterFunction = {(row, filter)=>UtilityService.isRowFiltered(row,filter)}
                            calcCycleColor = {(cycles)=>UtilityService.calcCycleColor(cycles)}
                        />
                    </Tab>
                    
                    <Tab title='Battery Sets' key='battery-sets-table-tab'>
                        <FleetManagementTableContent    
                            // ref={batterySetsTableRef}
                            data={batterySets}
                            loadSingleItem= {()=>{}}//{(id) => fleetManagementService.fetchSingleBatterySet(id)}
                            updateSingleItem= {()=>{}}//{(id, updateObj) => fleetManagementService.updateBatterySet(id, updateObj)}
                            deleteSingleItem= {()=>{}}//{(id) => fleetManagementService.deleteBatterySet(id)}
                            getTitle={(item) => item.name || "Unnamed Battery Set"}
                            schema = {batterySetSchema}
                            filter = {batterySetsFilter}
                            filterFunction =  {(row, filter)=>UtilityService.isRowFiltered(row,filter)}//{(row, filter)=>isRowFiltered(row,filter)}
                            calcCycleColor = {(cycles)=>UtilityService.calcCycleColor(cycles)}
                        />    
                    </Tab>
                    
                </PanelTabs>
                
            </FMDashboardPanel>
        </div> 

                          

    )
}
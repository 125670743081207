import MAVLinkConstants from '../../../protocol-services/mavlink-constants';

// MISSION_REQUEST_INT (#40)
export function missionRequestInt (message, aircraft) {
	const {
		seq,
		mission_type,
	} = message;

	console.log(`UAV Requesting ${mission_type}-type item #${seq}`)

	if (mission_type == null || mission_type == MAVLinkConstants.MAV_MISSION_TYPE.MAV_MISSION_TYPE_MISSION) {
		aircraft.stopTimeout('announce-mission-items');
		aircraft.sendMissionItem(seq, 'mission');
	}

	if (mission_type == MAVLinkConstants.MAV_MISSION_TYPE.MAV_MISSION_TYPE_RALLY) {
		aircraft.stopTimeout('announce-rally-items');
		aircraft.sendMissionItem(seq, 'rally');
	}
}
export const checklistSchema = {
	entityName: 'Checklist',
	loadSingleItem: (fleetManagementContext, id) => fleetManagementContext.getSingleChecklist(id),
	updateSingleItem: async (fleetManagementContext, id, data) => await fleetManagementContext.updateChecklist(id, data),
	removeSingleItem: async (fleetManagementContext, id) => await fleetManagementContext.removeChecklist(id),
	createSingleItem: async (fleetManagementContext, data) => await fleetManagementContext.createChecklist(data),

	attributes: [
		{   
				key: 'id',
				path: 'id',
				name: 'ID',
				type: 'text',
				hidden: true,
				disabled: true,
				autogenerated: true,
		},
		{
				key: 'title',
				path: 'data.title',
				name: 'Title',
				type: 'text',
		},
		{
			key: 'items',
			path: 'data.items',
			name: 'Checklist items',
			editOnly: true,
			type: 'list',
			itemSchema: [{
				key: 'attribute',
				title: 'Parameter',
				type: 'text',
			}, 
			{
				key: 'action',
				title: 'Action',
				type: 'text',
			}]
		},
	]
};
import React from "react";

export const Loading = ({width, height, position}) => {

		return (
			<div className="loader-wrap" style={{ 
					...(width && { width }),
					...(height && { height }),
					...(position && { position })
				}}>
				<img src={require('../../../assets/img/loader.svg').default}/>
			</div>
		)
};
import { toast } from "react-toastify";
import MAVLinkConstants from '../../../protocol-services/mavlink-constants';

// MISSION_ITEM_INT (#40)
export function missionItemInt (message, aircraft) {
	const {
		seq,
		frame,
		command,
		current,
		autocontinue,
		param1,
		param2,
		param3,
		param4,
		x,
		y,
		z,
		mission_type,
	} = message;

	const missionTypeLabel = mission_type == null ? 'mission' : mission_type == MAVLinkConstants.MAV_MISSION_TYPE.MAV_MISSION_TYPE_MISSION ? 'mission' : 'rally';

	// MAV2
	if (
		missionTypeLabel != aircraft.expectingMissionTypeItems 
		|| aircraft.pendingUploadedItems[missionTypeLabel][seq] != null
		|| seq > 1 && aircraft.pendingUploadedItems[missionTypeLabel][seq - 1] == null) {
		return;
	}

	aircraft.stopTimeout(`mission-item-${missionTypeLabel}-${seq}-download`);

	const expectedNumItems = aircraft.missionCount[missionTypeLabel];

	aircraft.pendingUploadedItems[missionTypeLabel][seq] = {
		seq,
		latitude: x / 10000000,
		longitude: y / 10000000,
		altitude: z,
		command,
		param1,
		param2,
		param3,
		param4,
	}

	const toastIdLabel = missionTypeLabel == 'mission' ? 'downloadMissionItemsToastId' : 'downloadRallyItemsToastId';

	if (aircraft.pendingUploadedItems[missionTypeLabel].length == expectedNumItems) {
		if (missionTypeLabel == 'mission') {
			toast.update(aircraft.toastIds[toastIdLabel], { render: 'Mission items downloaded', isLoading: false, autoClose: 2000, type: toast.TYPE.SUCCESS, closeButton: true });
			if (mission_type != null) {
				aircraft.downloadMissionPlan('rally');
			} else {
				aircraft.expectingMissionTypeItems = null;
				aircraft.decodeDownloadedMission();	
			}
		} else {
			toast.update(aircraft.toastIds[toastIdLabel], { render: 'Rally points downloaded', isLoading: false, autoClose: 2000, type: toast.TYPE.SUCCESS, closeButton: true });
			aircraft.expectingMissionTypeItems = null;
			aircraft.decodeDownloadedMission();
		}
	} else {
		aircraft.requestMissionItem(seq + 1, missionTypeLabel);
	}
}
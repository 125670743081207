
import React, { useState } from 'react';
// import StatusTokenLabel from '../ui-layout/status-token-label/status-token-label';
import { StatusTokenLabel } from '../status-token-label/status-token-label';
import noimage from '../../../../assets/fleet-managment-icons/drone_no_image.svg';


export const RPATile = ({data, onClick}) => {
   
    const [image, setImage] = useState(data.image || noimage);
    const calcColor = () => {
        let color = 'transparent';
        if (this.props.data.status == 'Serviceable'){
            color = '#3FD17D';
        } else if (this.props.data.status == 'Non-Serviceable'){
            color = 'red';
        } 
        return color;
    }
    

        return(
            <div className="rpa-tile-wrap" onClick = {() => onClick(data)}>
                <div className="rpa-tile">
                    <div className="rpa-tile-image-status" 
                         style={{backgroundImage: `url(${image})`}}
                        >
                        <div className='rpa-tile-status-regnum-wrap'>
                            <div className='rpa-tile-status'>
                                {/* label={this.props.data.status || ""} */}
                                <StatusTokenLabel color={()=>calcColor()} label=""></StatusTokenLabel>
                            </div>
                            <div className='rpa-tile-regnum'>
                                {data.idNum || "No id num"}
                            </div>
                        </div>
                        <div className="rpa-tile-image">
                             {/* <img src={data.image && data.image != "" ? data.image : noimage} alt="drone"/>  */}
                        </div>
                        <div className="rpa-tile-last-flight">
                            {data.subtitle ? <p>{data.subtitleLabel && `${data.subtitleLabel}:`} {data.subtitle}</p> : ""}
                        </div>
                    </div>
                    
                </div>
                <div className="rpa-tile-aircraft-name">
                    {data.title || "No title"}  
                </div>
            </div>
        )
}


export const documentSchema = {
	entityName: 'Document',
	loadSingleItem: (fleetManagementContext, id) => fleetManagementContext.getSingleDocument(id),
	updateSingleItem: async (fleetManagementContext, id, data) => await fleetManagementContext.updateDocument(id, data),
	removeSingleItem: async (fleetManagementContext, id) => await fleetManagementContext.removeDocument(id),
	createSingleItem: async (fleetManagementContext, data) => await fleetManagementContext.createDocument(data),

	attributes: [
		{   
				key: 'id',
				path: 'id',
				name: 'ID',
				type: 'text',
				hidden: true,
				disabled: true,
				autogenerated: true,
		},
		{
				key: 'name',
				path: 'data.name',
				name: 'Name',
				type: 'text',
		},
		{
			key: 'requiresAck',
			path: 'data.requiresAck',
			name: 'Requires Acknowledgement',
			type: 'select',
			options: [{
				key: 'YES',
				label: 'Yes',
			},
			{
				key: 'NO',
				label: 'No',
			}]
		},	
		{
			key: 'description',
			path: 'data.description',
			name: 'Description',
			type: 'text',
		},
		{
			key: 'url',
			path: 'data.url',
			name: 'File',
			type: 'file',
		},
	]
};
import React from 'react';


export const CockpitHeaderPanel = ({title, children}) => {

    return(
        <div className='cockpit-header-panel'>
            <p className='cockpit-header-panel-header'>{title}</p>
            <div className='cockpit-header-panel-data'>
              {children}
            </div>
        </div>
    )
}
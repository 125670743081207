// import fleetManagementService from "../../../services/fleet-management-service";

export const batterySchema = {
	entityName: 'Battery',
	loadSingleItem: (fleetManagementContext, id) => fleetManagementContext.getSingleBattery(id),
	updateSingleItem: async (fleetManagementContext, id, data) => await fleetManagementContext.updateBattery(id, data),
	removeSingleItem: async (fleetManagementContext, id) => await fleetManagementContext.removeBattery(id),
	createSingleItem: async (fleetManagementContext, data) => await fleetManagementContext.createBattery(data),

	attributes: [
			{   
					key: 'id',
					path: 'id',
					name: 'ID',
					type: 'text',
					hidden: true,
					disabled: true,
					autogenerated: true,
			},
			{
					key: 'name',
					path: 'data.name',
					name: 'Name',
					type: 'text',
			},
			{
					key: 'serialNumber',
					path: 'data.serialNumber',
					name: 'Serial Number',
					type: 'text',
			},
			{
					key: 'cycles',
					path: 'data.cycles',
					name: 'Cycles',
					type: 'number',
					min: 0,
					step: 1,
					disabled: true,
			},
			{
					key: 'make',
					path: 'data.make',
					name: 'Make',
					type: 'text',
			},
			{
					key: 'uploadDate',
					path: 'createdAt',
					name: 'Upload Date',
					type: 'datetime-local',
					disabled: true,
					autogenerated: true,
			},
			{
					key: 'class',
					path: 'data.class',
					name: 'Battery Class',
					type: 'select',
					options: [{
							key: 'A',
							label: 'Class A',
					},
					{
							key: 'B',
							label: 'Class B',
					},
					{
							key: 'C',
							label: 'Class C',
					}],
			},
			{
					key: 'purchaseDate',
					path: 'data.purchaseDate',
					name: 'Purchase Date',
					type: 'datetime-local',
			},
			{
					key: 'initialFlightTime',
					path: 'data.initialFlightTime',
					name: 'Initial Flight Time',
					type: 'time',
			},
			{
				key: 'batterySetId',
				path: 'data.batterySetId',
				displayValuePath: 'data.batterySet.data.name',
				name: 'Battery Set',
				type: 'remote-select',
				options: {
					loadData: (fleetManagementContext) => fleetManagementContext.batterySets,
					keyPath: 'id',
					labelPath: (obj) => obj.data.name,
				},
		},
	]
};
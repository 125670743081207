
import React, { useContext, useEffect, useRef, useState } from 'react';
import { MapContainer, Polyline, TileLayer } from 'react-leaflet';
import { MapContext } from '../../../../../../contexts/map-context';
import { SelectInput } from '../../../../../../shared-components/inputs/select-input/select-input';
import { Stack } from '../../../../../../shared-components/layout/stack/stack';
import AntPath from '../../../../../../shared-components/map-display/ant-path';
import { Waypoint } from '../../../../../../helpers/waypoint';
import { FlightContext } from '../../../../../../contexts/flight-context';

export const RiskAssessment = ({allFlightPath, bd='1.23e-4', ug='1.23e-11', pd='1.23e-11', x='1.23e-5'}) => {
    const selectedPath = useRef([]);
    const [preparedWaypoints, setPreparedWaypoints] = useState([]);
    const { selectedTileProvider: tileProvider } = useContext(MapContext);
    const { scheduleFlightData } = useContext(FlightContext);

    console.log(scheduleFlightData);

    const selectPath = (selectedPathIndex) => {
        selectedPath.current = allFlightPath[selectedPathIndex]; 
        setPreparedWaypoints(
            selectedPath.current.waypoints.map(
                wp => new Waypoint(wp.latitude,wp.longitude, null, wp.altitude, wp.speed)
            )
        );
        // console.log("prepared wps", preparedWaypoints);
    }

    useEffect(() => {
        if (preparedWaypoints.length === 0) {
            selectPath(0);
        }
    }, []);
    return(
        <div className='schedule-flight-column-body risk-assessment-wrap'>
            <Stack className='schedule-flight-column-body-section' direction='horizontal' halign='space-between' valign='space-between'>
                <div className='risk-assessment-ground-risk'>
                    <p className='uppercase bold'>Ground Risk</p>
                    <div className='risk-assessment-section'>
                        <p className='uppercase bold'>Ballistic Descent</p>
                        <p className={`big bold ${bd > x ? 'red' : 'green'}`}>{bd}</p>
                    </div>
                    <div className='risk-assessment-section'>
                        <p className='uppercase bold'>Uncontrolled Glide</p>
                        <p className={`big bold ${ug > x ? 'red' : 'green'}`}>{ug}</p>
                    </div>
                    <div className='risk-assessment-section'>
                        <p className='uppercase bold'>Parachute Descent</p>
                        <p className={` big bold ${pd > x ? 'red' : 'green'}`}>{pd}</p>
                    </div>
                </div>
                <Stack className='risk-assessment-flight-path' direction={'vertical'} halign={'start'} valign={'start'}>
                    <SelectInput 
                    
                    options={allFlightPath ? allFlightPath.map(ac => ({ value: ac.index, name: ac.title })) : []} 
                    label='Flight Path' 
                    onChange={(e)=> selectPath(e.target.value)} 
                    
                    />
                    <div className='risk-assessment-map-wrap'>
                    {preparedWaypoints.length !== 0 &&
                    <MapContainer 
						style={{ 
							height: '100%',
							cursor: 'pointer',
						}}
                        
						center={[preparedWaypoints[0].latitude, preparedWaypoints[0].longitude]} 
						zoom={15} 
						scrollWheelZoom={true}
						zoomControl={false}
						// whenCreated={(map) => changeViewport(map)}
					>
                        <TileLayer
							// attribution={tileProvider.attribution}
							url={tileProvider.uri}
						/>
                        {console.log("prepared waypoints",preparedWaypoints)}
                        {selectedPath.current.lenght === 0 && 
                        	
                            <AntPath 
                                key={`path-${preparedWaypoints.map(wp => `${wp.latitude},${wp.longitude}`).join(',')}`}
                                positions={preparedWaypoints.map(wp => [wp.latitude, wp.longitude])}
                                options={{
                                    delay: 5000,
                                    dashArray: [5,10],
                                    weight: 3,
                                    color:  "#E981FFFF",
                                    pulseColor: "white",
                                    paused: false,
                                    reverse: false,
                                    hardwareAccelerated: true,
                                }}
                            />}
                            {
                            <Polyline 
							key={`uploaded-path--${preparedWaypoints.map(wp => `${wp.latitude},${wp.longitude}`).join(',')}`}
							positions={preparedWaypoints.map(wp => wp.getPosition())}
							color="#FFFFFFAA"
							// options={{
							// 	weight: 3,
							// 	color: "#FFFFFF",
							// }}
						    />
                            }

                        {
						preparedWaypoints.map((wp, index) => wp.getMarker({
							onClick: (e, waypoint)=>{},
							draggable: false,
							index: index + 1,
							markerLabelsVisible: true,
						}))
					}


                    </MapContainer>}
                    </div>
                </Stack>
            </Stack>
         </div>
    )
}
import React,{useState} from 'react';
import {Stack} from '../../layout/stack/stack';

export const SelectInput = ({value, label, options, onChange, className, width="100px", icon}) => {

    return (
        <div className={`select-input-wrap ${className ? className : ""}`} style={{width: width}}>
            <Stack direction='horizontal' halign='end' valign='end'>
                {icon && icon}
                {label && <label className={`${icon && 'no-padding'}`}>{label}</label>}
            </Stack>
            <select
                className="needsclick"
                onChange={(e) => onChange(e)}
                value={ value }
            >
                {options.map((option, index) => (
                    <option 
                        value={option.value} 
                        key={`select-option-${option.name}-${index}`}
                        >
                            {option.name}
                    </option>
                ))}
                
            </select>
        </div>
    )
}
import { ArrowBackIos, Camera, FastForward, FastRewind, Pause, PlayArrow, Replay, Stop } from '@mui/icons-material';
import React, { useEffect, useRef, useState } from 'react';
import { flightlogSchema } from '../../../../contexts/schemas/flightlog-schema';
import { SkyModal } from '../../../../shared-components/containers/sky-modal/sky-modal';
import { FMFlightlogBox } from './components/fm-flightlog-box/fm-flightlog-box';
import { FMFlightlogItem } from './components/fm-flightlog-item/fm-flightlog-item';
import flightReportImage from '../../../../assets/img/flightreport.png';
import { MapDisplay } from '../../../../shared-components/map-display/map-display';
import { CockpitScreen } from '../../../cockpit-screen/cockpit-screen';
import {IconButton } from '@mui/material';
import { FMFlightlogSlider } from './components/fm-flightlog-slider/fm-flightlog-slider';
import { HeadingCompass } from '../../../../shared-components/heading-compass/heading-compass';
import { CockpitHeader } from '../../../cockpit-screen/components/cockpit-header/cockpit-header';
import { FlightModeSelectorPanel } from '../../../cockpit-screen/components/flight-mode-selector-panel/flight-mode-selector-panel';
import { CockpitToolbar } from '../../../cockpit-screen/components/cockpit-toolbar/cockpit-toolbar';
import { CockpitMissionPath } from '../../../cockpit-screen/components/cockpit-mission-path/cockpit-mission-path';
import { CockpitControlPanel } from '../../../cockpit-screen/components/cockpit-control-panel/cockpit-control-panel';
import { FMFlightlogCharts } from './components/fm-flightlog-charts/fm-flightlog-charts';
import { FMFlightlogMap } from './components/fm-flightlog-map/fm-flightlog-map';

export const FMFlightlogPage = ({data, handleBack}) => {
    // console.log(

    //     "FL DATA", data
    // )
    const [reportVisible, setReportVisible] = useState(false);
    
    const onClose = () => {
        setReportVisible(false);
    }

 

    const [playingRewind, setPlayingRewind] = useState(false);
    const [progress, setProgress] = useState(0);
    const progressIntervalRef = useRef(null);
    const progressRef = useRef(0);

    const handleRewind = (direction, step=1, max=100, min=0) => {
        console.log("PR REF",progressRef.current);
        if (direction == 'forward') { 
            if (progressRef.current + step >= max) {
                progressRef.current = max;
                setProgress(progressRef.current);
            }
            else {
                progressRef.current += step;
                console.log("PR REF + ",progressRef.current);
                setProgress(progressRef.current);
            }
        } else {
            if (progressRef.current - step <= min) {
                progressRef.current = min;
                setProgress(progressRef.current);
            }
            else {
                progressRef.current -= step;
                setProgress(progressRef.current);
                console.log("PR REF - ",progressRef.current);
            }
        }
        if(progressIntervalRef.current != null) {
            clearInterval(progressIntervalRef.current);
        }

        progressIntervalRef.current = setInterval(() => {
            console.log("INTERVAL");
            if (direction == 'forward') { 
                if (progressRef.current + step*2 >= max) {
                    progressRef.current = max;
                    setProgress(progressRef.current);
                }
                else {
                    progressRef.current += step*2;
                    setProgress(progressRef.current);
                    console.log("PR INTERVAL REF + ",progressRef.current);
                }
            } else {
                if (progressRef.current - step*2 <= min) {
                    progressRef.current = min;
                    setProgress(progressRef.current);
                }
                else {
                    progressRef.current -= step*2;
                    setProgress(progressRef.current);
                    console.log("PR  INTERVAL REF - ",progressRef.current);
                }

            }
        }, 200);
    }
    const handleStopRewind = () => {
        if (progressIntervalRef.current != null) {
            clearInterval(progressIntervalRef.current);
        }
        clearInterval(progressIntervalRef.current);
    }
    const handleRestart = () => {
        handleStopRewind();
        progressRef.current = 0;
        setProgress(progressRef.current);
    }

    useEffect(() => {
        
        
    }, [progress]);
   
    return(
        <div className='fm-flightlog-page'>
              <SkyModal 
                className='flight-report-modal'
                title={'Flight Report Modal'}
                modalVisible={reportVisible}
                onClose={()=>onClose()}
                editButtonsActive={false}
                disableScrollbar={true}>
            <div className='flight-report-modal-content '>
                <div className='flight-report-modal-header'>
                    <span className='uppercase bold title'>Flight Report</span>
                </div>
           
            <div className='fm-flightlog-data-wrap'>
                
                <FMFlightlogBox title='Time and Location'>
                        <FMFlightlogItem title='Flight Time' value="22:22:22"/>
                        <FMFlightlogItem title='Flight Start Time (Local)' value="12:22:22"/>
                        <FMFlightlogItem title='Flight End Time (Local)' value="13:22:22"/>
                        <FMFlightlogItem title='Date' value="02/02/2022"/>
                        <FMFlightlogItem title='Start Location' value=""/>
                        <FMFlightlogItem title='End Location' value=""/>
                </FMFlightlogBox>
                <FMFlightlogBox title='Altitude and Speed'>
                        <FMFlightlogItem title='Max Altitude' value="120"/>
                        <FMFlightlogItem title='Speed (Km/h)' value="30"/>
                        <FMFlightlogItem title='Speed (M/s)' value="300"/>
                        <FMFlightlogItem title='Speed (Mp/h)' value="3000"/>
                </FMFlightlogBox>
                <FMFlightlogBox title='Battery Information'>
                        <FMFlightlogItem title='Battery Start Voltage' value="10"/>
                        <FMFlightlogItem title='Battery Sag Voltage' value="20"/>
                        <FMFlightlogItem title='Battery End Voltage' value="4"/>
                </FMFlightlogBox>
                <FMFlightlogBox title='Additional Information'>
                        <FMFlightlogItem title='Checklist Preflight' value="CONFIRMED"/>
                        <FMFlightlogItem title='Checklist Postflight' value="NOT CONFIRMED"/>
                </FMFlightlogBox>
                <FMFlightlogBox title='Personnel'>
                        <FMFlightlogItem title='Pilot (ID)' value="John Smith (229292904)"/>
                        <FMFlightlogItem title='Pilot (ID)' value="Sam Johnson (229292904)"/>
                        <FMFlightlogItem title='OPs (ID)' value="John Smith (929292)"/>
                        <FMFlightlogItem title='OPs (ID)' value="Sam Johnson (393993)"/>
                </FMFlightlogBox>
                </div>
                <div className='flight-report-map-wrap'>
                    <p className='bold uppercase'>Flight overview</p>
                    <div className='flight-report-map' style={{backgroundImage:`${flightReportImage.default}`}}>
                        
                    </div>
                </div>
                <div className='flight-report-map-wrap'>
                    <p className='bold uppercase'>Flight Charts</p>
                    <div className='fm-flightlog-charts-wrap'>
                        <FMFlightlogCharts/>
                    </div>
                </div> 
               
                <div className='flight-report-map-wrap'>
                    <p className='bold uppercase'>Flight warnings/errors</p>
                </div>

            </div>
        </SkyModal>
            <div className='fm-flightlog-header'>
                
                <div className='fm-flightlog-back' onClick={() => handleBack()}>
                    <ArrowBackIos />
                    <span className='uppercase bold'>Return to Table</span>
                </div>
                <div className='fm-flightlog-back uppercase bold' onClick={() => setReportVisible(true)}>Flight Report Example</div>
            </div>
            <div className='fm-flightlog-healthbar'>

            </div>
            <div className='fm-flightlog-map-rewind'>
                <HeadingCompass/>
                {/* <CockpitHeader/>
                <FlightModeSelectorPanel/>
                <CockpitToolbar/>
                <CockpitControlPanel visible={true}/>  */}
                {/* {selectedAircraft && isAircraftConnected} */}
                {/* { waypoints.length > 0 && customMode == 'AUTO' && */}
                    {/* <CockpitMissionPath/> */}
                {/* } */}
                <MapDisplay key={'map-rewind-wrap'} tileSelectorPosition={'right'}>
                    
                </MapDisplay>
                {/* <FMFlightlogMap/> */}
                {/* <CockpitScreen/> */}
              
                {/* <LinearProgress style={{width:"100%", height: "10px"}} variant="determinate" value={progress} />
                <span>{progress}</span>
                <div className='map-rewind-controls'>
                    <IconButton
                            color="inherit"
                            aria-label="Fast Rewind"
                            // onClick={() =>{setProgress(progress - 1)}}
                            onMouseDown={() => {handleRewind("backward", 1)}}
                            onMouseUp={() => handleStopRewind()}
                            edge="start"
                            sx={{ mr: 2, ...(!playingRewind && { display: 'none' }) }}
                        >
                        <FastRewind/>
                    </IconButton>
                    <IconButton
                            color="inherit"
                            aria-label="Stop"
                            onClick={() =>{setPlayingRewind(false);}}
                            edge="start"
                            sx={{ mr: 2, ...(!playingRewind && { display: 'none' }) }}
                        >
                        <Stop/>
                    </IconButton>
                    <IconButton
                            color="inherit"
                            aria-label="Start"
                            onClick={() =>{setPlayingRewind(true)}}
                            edge="start"
                            sx={{ mr: 2, ...(playingRewind && { display: 'none' }) }}
                        >
                       <PlayArrow/>
                    </IconButton>
                    <IconButton
                            color="inherit"
                            aria-label="Pause"
                            onClick={() =>handleStopRewind()}
                            edge="start"
                            sx={{ mr: 2, ...(!playingRewind && { display: 'none' }) }}
                        >
                         <Pause/>
                    </IconButton>
                    <IconButton
                            color="inherit"
                            aria-label="Fast Forward"
                            // onClick={() =>{setProgress(progress + 1)}}
                            onMouseDown={() => {handleRewind("forward",1)}}
                            onMouseUp={() => handleStopRewind()}
                            edge="start"
                            sx={{ mr: 2, ...(!playingRewind && { display: 'none' }) }}
                        >
                        <FastForward/>
                    </IconButton>
                    <IconButton
                            color="inherit"
                            aria-label="Restart"
                            onClick={() =>{handleRestart()}}
                            edge="start"
                            sx={{ mr: 2, ...(!playingRewind && { display: 'none' }) }}
                        >
                          <Replay/>
                    </IconButton>
                    
                     */}
                  
                {/* </div> */}


                <FMFlightlogSlider/>
        
               
            </div>
            <div className='fm-flightlog-charts-wrap'>
                <FMFlightlogCharts/>
            </div>
            <div className='fm-flightlog-data-wrap'>
                
               <FMFlightlogBox title='Attitude'>
                    <FMFlightlogItem title='AHRS' value="21"/>
                    <FMFlightlogItem title='Roll' value="2121"/>
                    <FMFlightlogItem title='Yaw' value="2"/>
                    <FMFlightlogItem title='Pitch' value=""/>
                    <FMFlightlogItem title='Heading' value=""/>
               </FMFlightlogBox>
               <FMFlightlogBox title='Commands Recieved from GCS'>
                    <FMFlightlogItem title='Ctot' value=""/>
                    <FMFlightlogItem title='Cld' value=""/>
                    <FMFlightlogItem title='CMD alt' value=""/>
                    <FMFlightlogItem title='CMD lat' value=""/>
                    <FMFlightlogItem title='CMD lng' value=""/>
               </FMFlightlogBox>
               <FMFlightlogBox title='Current (battery information)'>
                    <FMFlightlogItem title='Pilot Input Throttle' value=""/>
                    <FMFlightlogItem title='Integrated Throttle' value=""/>
                    <FMFlightlogItem title='Battery Voltage in Volts * 100' value=""/>
                    <FMFlightlogItem title='Board Voltage' value=""/>
                    <FMFlightlogItem title='Total Current Drawn from Battery' value=""/>
                    <FMFlightlogItem title='Battery Start Voltage' value=""/>
                    <FMFlightlogItem title='Battery Sag Voltage' value=""/>
                    <FMFlightlogItem title='Battery End Voltage' value=""/>
               </FMFlightlogBox>
               <FMFlightlogBox title='Time and Location'>
                    <FMFlightlogItem title='Flight Start Time' value=""/>
                    <FMFlightlogItem title='Flight End Time' value=""/>
                    <FMFlightlogItem title='Date' value=""/>
                    <FMFlightlogItem title='Start Location' value=""/>
                    <FMFlightlogItem title='End Location' value=""/>
               </FMFlightlogBox>
               <FMFlightlogBox title='Altitude and Speed'>
                    <FMFlightlogItem title='Max Altitude' value=""/>
                    <FMFlightlogItem title='Speed (Km/h)' value=""/>
                    <FMFlightlogItem title='Speed (M/s)' value=""/>
                    <FMFlightlogItem title='Speed (Mp/h)' value=""/>
               </FMFlightlogBox>
               <FMFlightlogBox title='Additional Information'>
                    <FMFlightlogItem title='Checklist Preflight' value=""/>
                    <FMFlightlogItem title='Checklist Postflight' value=""/>
                    <FMFlightlogItem title='Time Remaining' value=""/>
                    <FMFlightlogItem title='Description' value=""/>
               </FMFlightlogBox>
            </div>
        </div>
    )
}
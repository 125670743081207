import React from "react";
import PerfectScrollbar from 'react-perfect-scrollbar';
import {InputField} from '../../../../shared-components/inputs/input-field/input-field';
//input icon replaced with input field
import checkcircle from '../../../../assets/fleet-managment-icons/check-circle.svg';

export const RPACreate = () => {

        return(
            <form className='rpa-creation-form-wrap'>
                <PerfectScrollbar>
                <InputField 
                    id='rpa-creation-name-designation' 
                    placeholder='Name/Designation'
                    icon={checkcircle}
                    alt=''/>
                {/* <InputIcon id='rpa-creation-classification' placeholder='RPA Classification'/> */}
                <label htmlFor='rpa-creation-classification'>RPA Classification</label>
                <select className='needsclick' id="rpa-creation-classification" name="RPA Classification" placeholder='RPA Classification'>
                    <option value='def' disabled defaultValue>RPA Classification</option>
                    <option value="class1">Class 1</option>
                    <option value="class2">Class 2</option>
                    <option value="class3">Class 3</option>
                    <option value="class4">Class4</option>
                </select>
                <InputField 
                    id='rpa-creation-owner' 
                    placeholder='RPA Owner'/>
                <label htmlFor='rpa-creation-esthft'>Estimated Historical Flight Time</label>
                <div className='rpa-creation-esthft'>
                    <InputField letter="H"/>
                    <InputField letter="M"/>
                    <InputField className='last' letter="S"/>
                </div>
                
                <InputField id='rpa-creation-call-sign' placeholder='Call Sign'/>
                <InputField 
                    id='rpa-creation-est-procurment-date' 
                    placeholder='Estimated Procurment Date' 
                    labelText='Estimated Procurment Date'
                    type='date'/>
                <InputField 
                    id='rpa-creation-max-visual-dist' 
                    placeholder='Maximum Visual Distance'/>
                 </PerfectScrollbar>
            </form>
        );}
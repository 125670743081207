import React, {useState, useRef} from "react";

export const ListInput = ({items, schema, title, editable, onUpdate }) => {
	const [localItems, setLocalItems] = useState(items || []);
	const currentLocalItems = useRef(items || []);
	const pendingObj = useRef({});

	const addItem = () => {
		// console.log(pendingObj);
		currentLocalItems.current = [...currentLocalItems.current, {...pendingObj.current}];
		pendingObj.current = {};
		setLocalItems([...currentLocalItems.current])
		console.log(currentLocalItems.current);
		onUpdate(currentLocalItems.current);
	}

	const deleteItem = (index) => {
		currentLocalItems.current.splice(index, 1)
		setLocalItems([...currentLocalItems.current]);
		onUpdate(currentLocalItems.current);
	}

		return (
			<div className="list-input">
				<h4 className="list-input-title">{title}</h4>
				<table className="list-input-table">
					<thead>
						<tr>
						{
							schema.map(att => (
								<th>{att.title}</th>
							))
						}
						{ editable && <th>Actions</th> }
						</tr>
					</thead>
					<tbody>
					{
						localItems.map((item, index) => (
							<tr className="list-input-row">
								{
									schema.map(att => (
										<td>
											{
												editable ?
													<input
														className="list-input-field"
														type={att.type || 'text'}
														defaultValue={item[att.key]}
														onChange={(e) => { item[att.key] = e.target.value; onUpdate(localItems)}}
													/>
												:
												(item[att.key])
											}
										</td>
									))
								}
								{ editable && <td><button className="error" onClick={() => deleteItem(index)}>Delete</button></td> }
							</tr>
						))
					}
					{
						editable &&
						<tr>
							{ 
								schema.map(att => (
									<td>
										<input
											key={`list-input-${localItems.length+1}`}
											defaultValue={pendingObj.current[att.key]}
											className="list-input-field"
											type={att.type || 'text'}
											onChange={(e) => pendingObj.current[att.key] = e.target.value}
											/>
									</td>
								))
							}
							<td>
								<button className="primary" onClick={() => addItem()}>Add item</button>
							</td>
						</tr>
					}
					</tbody>
				</table>

				{
					localItems.length == 0 &&
					<p className="no-items">No items</p>
				}
			</div>
		);
	}

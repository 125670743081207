import React from "react";
import {SkyModal} from "../sky-modal/sky-modal";

export const ConfirmationDialog = ({ message, visible, onConfirm, onCancel, onClose, className }) => {
	return  (
		<SkyModal
			className={`confirmation-dialog  ${className && className} `}
			title={'Confirmation required'}
			modalVisible={ visible }
			onClose={() => onClose()}
			actionButtons={[
				{
					title: 'Confirm',
					action: () => { onConfirm(); onClose() },
					btnType:"primary",
					position:'middle',
				},
				{
					title: 'Cancel',
					action: () => { onCancel(); onClose() },
					btnType:"secondary",
					position:'middle',
				}
			]}
		>
			<p className="confirmation-message">{ message }</p>
		</SkyModal>
	)
}

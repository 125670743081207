import React, {useContext, useState} from 'react';
// import { ConfigContext } from '../../../../contexts/config-context';
import UtilityService from '../../../../helpers/utility-service';
import { FleetManagementTableContent } from '../fleet-management-table-content/fleet-management-table-content';
import { FMDashboardPanel } from '../fm-dashboard-panel/fm-dashboard-panel';
import { checklistSchema } from '../../../../contexts/schemas/checklist-schema';
import { FleetManagementContext } from '../../../../contexts/fleet-management-context';

export const FMChecklistsPanel = () => {
    const {checklists} = useContext(FleetManagementContext);
	const [checklistFilter, setChecklistFilter] = useState("");
    return(
         /* CHECKLISTS  */
        <div className="fleet-management-card-wrap">
        <FMDashboardPanel 
                className='fleet-management-card tabbed-card' 
                onFilter={(filter) => setChecklistFilter(filter)}  
                dashboardPanelToolbar={true}
                title='Checklists'
                entityName={checklistSchema.entityName}
                schema={checklistSchema}
                createItem={()=>{}}
                // {async (data) => {
                //     await fleetManagementService.createChecklist(data);
                //     checklistsTableRef.current.loadData();  
                // }}
            >
                <FleetManagementTableContent    
                    // ref={checklistsTableRef}
                    data={checklists}
                    loadData={() => {}}//{() => fleetManagementService.fetchAllChecklists()}
                    loadSingleItem={() => {}}//{(id) => fleetManagementService.fetchSingleChecklist(id)}
                    updateSingleItem={() => {}}//{(id, updateObj) => fleetManagementService.updateChecklist(id, updateObj)}
                    deleteSingleItem={() => {}}//{(id) => fleetManagementService.deleteChecklist(id)}
                    getTitle={(item) => item.title}
                    schema = {checklistSchema}
                    filter = {checklistFilter}
                    filterFunction = {(row, filter)=>UtilityService.isRowFiltered(row,filter)}
                    // calcCycleColor = {(cycles)=>calcCycleColor(cycles)}
                />  
            </FMDashboardPanel>
        </div>
    )
}
import React, { useState, useRef } from 'react';

import PerfectScrollbar from 'react-perfect-scrollbar';

import {SkyModal} from '../../shared-components/containers/sky-modal/sky-modal';
import { FMBatteryPanel } from './components/fm-battery-panel/fm-battery-panel';
import { FMChecklistsPanel } from './components/fm-checklists-panel/fm-checklists-panel';
import { FMDashboardPanel } from './components/fm-dashboard-panel/fm-dashboard-panel';
import { FMDocumentsPanel } from './components/fm-documents-panel/fm-documents-panel';
import { FMEquipmentPanel } from './components/fm-equipment-panel/fm-equipment-panel';
import { FMMaintenancePanel } from './components/fm-maintenance-panel/fm-maintenance-panel';
import { FMPersonnelPanel } from './components/fm-personnel-panel/fm-personnel-panel';
import { FMRPAPanel } from './components/fm-rpa-panel/fm-rpa-panel';
import {motion} from 'framer-motion';
import { FMSideMenu } from './components/fm-side-menu/fm-side-menu';
import { FMFlightLogsPanel } from './components/fm-flightlogs-panel/fm-flightlogs-panel';
import { FMFlightlogPage } from './components/fm-flightlog-page/fm-flightlog-page';

export const FleetManagementScreen = () => {
    const [openFMSection, setOpenFMSection] = useState('aircraft');
    const [title, setTitle] = useState("Aircraft, Aircraft Type & Autopilot");
    const handleChangeFMTab = (selectedTab, t) => {
        setOpenFMSection(selectedTab);
        setTitle(t);
    }
    const [showLog, setShowLog] = useState(false);
    const handleLogChosen = (log) => {
        setShowLog(log);
    }
    const handleReturnToTable = () => {
        setShowLog(false);
    }
    return(
        <div className="fleet-management-motion-wrap" key='fleet-management-motion-wrap'>
            <motion.div
            key="fleet-management-motion"
            initial={{ y:-500, opacity:0}}
            animate={{ y:0 , opacity:1}}
            exit={{ y:-500, opacity:0 }}
            transition={{ duration:0.3, delay: 0.15, ease: 'easeInOut'}}
            
        >
            <div className='fleet-management-screen-wrap'>
               
                    <PerfectScrollbar>  
                        <div className='fleet-management-page-content'>
                            <FMSideMenu onOpenSection={(section, sectionTitle)=>handleChangeFMTab(section, sectionTitle)} selectedTab={openFMSection}>
                                <p className='fleet-management-header-title uppercase bold'>{title}</p>
                                {openFMSection == 'aircraft' && <FMRPAPanel filter=''/>}
                                {openFMSection == 'battery' && <FMBatteryPanel filter=''/> }
                                {openFMSection == 'equipment' && <FMEquipmentPanel filter=''/>}
                                {openFMSection == 'personnel' && <FMPersonnelPanel/>}
                                {openFMSection == 'maintenance' && <FMMaintenancePanel filter=''/>}
                                {openFMSection == 'documents' && <FMDocumentsPanel filter=''/>}
                                {openFMSection == 'checklists' && <FMChecklistsPanel filter=''/>}
                                {openFMSection == 'flightlog' && (showLog ? 
                                    <FMFlightlogPage data={showLog} handleBack={() => handleReturnToTable()}/> : 
                                    <FMFlightLogsPanel filter='' onRowClicked={(log) => handleLogChosen(log)}/>)}
                            </FMSideMenu>
                        </div>
                    </PerfectScrollbar>
                
            </div>
        </motion.div>
    </div>
    );
}
const constants = {
	MAV_TYPE: {
		0: { value: 'MAV_TYPE_GENERIC', label: 'Generic micro air vehicle' },
		1: { value: 'MAV_TYPE_FIXED_WING', label: 'Fixed wing aircraft' },
		2: { value: 'MAV_TYPE_QUADROTOR', label: 'Quadrotor' },
		3: { value: 'MAV_TYPE_COAXIAL', label: 'Coaxial helicopter' },
		4: { value: 'MAV_TYPE_HELICOPTER', label: 'Normal helicopter with tail rotor' },
		5: { value: 'MAV_TYPE_ANTENNA_TRACKER', label: 'Ground installation' },
		6: { value: 'MAV_TYPE_GCS', label: 'Operator control unit / ground control station' },
		7: { value: 'MAV_TYPE_AIRSHIP', label: 'Airship, controlled' },
		8: { value: 'MAV_TYPE_FREE_BALLOON', label: 'Free balloon, uncontrolled' },
		9: { value: 'MAV_TYPE_ROCKET', label: 'Rocket' },
		10: { value: 'MAV_TYPE_GROUND_ROVER', label: 'Ground rover' },
		11: { value: 'MAV_TYPE_SURFACE_BOAT', label: 'Surface vessel, boat, ship' },
	},

	MAV_AUTOPILOT: {
		0: { value: 'MAV_AUTOPILOT_GENERIC', label: 'Generic autopilot, full support for everything' },
		1: { value: 'MAV_AUTOPILOT_RESERVED', label: 'Reserved for future use' },
		2: { value: 'MAV_AUTOPILOT_SLUGS', label: 'SLUGS autopilot' },
		3: { value: 'MAV_AUTOPILOT_ARDUPILOTMEGA', label: 'ArduPilot' },
		// ...
		8: { value: 'MAV_AUTOPILOT_INVALID', label: 'Invalid'},
		// ...
		12: { value: 'MAV_AUTOPILOT_PX4', label: 'PX4' },
	},

	MAV_STATE: {
		0: { value: 'MAV_STATE_UNINIT', label: 'Uninitialized', description: 'Uninitialized system, state is unknown' },
		1: { value: 'MAV_STATE_BOOT', label: 'Booting up', description: 'System is booting up' },
		2: { value: 'MAV_STATE_CALIBRATING', label: 'Calibrating', description: 'System is calibrating and not flight-ready' },
		3: { value: 'MAV_STATE_STANDBY', label: 'Standby', description: 'System is grounded and on standby. It can be launched any time' },
		4: { value: 'MAV_STATE_ACTIVE', label: 'Active', description: 'System is active and might be already airborne. Motors are engaged' },
		5: { value: 'MAV_STATE_CRITICAL', label: 'Critical', description: 'System is in a non-normal flight mode. It can however still navigate' },
		6: { value: 'MAV_STATE_EMERGENCY', label: 'Emergency', description: 'System is in a non-normal flight mode. It lost control over parts or over the whole airframe. It is in mayday and going down' },
		7: { value: 'MAV_STATE_POWEROFF', label: 'Poweroff', description: 'System just initialized its power-down sequence, will shut down now' },
		8: { value: 'MAV_STATE_FLIGHT_TERMINATION', label: 'Flight termination', description: 'System is terminating itself' },
		8: { value: 'MAV_STATE_ENUM_END', label: 'End', description: '' },
	},

	MAV_MODE_FLAG: {
		MAV_MODE_FLAG_SAFETY_ARMED: 128,
		MAV_MODE_FLAG_MANUAL_INPUT_ENABLED: 64,
		MAV_MODE_FLAG_HIL_ENABLED: 32,
		MAV_MODE_FLAG_STABILIZE_ENABLED: 16,
		MAV_MODE_FLAG_GUIDED_ENABLED: 8,
		MAV_MODE_FLAG_AUTO_ENABLED: 4,
		MAV_MODE_FLAG_TEST_ENABLED: 2,
		MAV_MODE_FLAG_CUSTOM_MODE_ENABLED: 1,
	},

	MAV_BATTERY_FUNCTION: {
		0: { value: 'MAV_BATTERY_FUNCTION_UNKNOWN', label: 'Unknown', description: 'Battery function is unknown' },
		1: { value: 'MAV_BATTERY_FUNCTION_ALL', label: 'All flight systems', description: 'Battery supports all flight systems' },
		2: { value: 'MAV_BATTERY_FUNCTION_PROPULSION', label: 'Propulsion system', description: 'Battery for the propulsion system' },
		2: { value: 'MAV_BATTERY_FUNCTION_PROPULSION', label: 'Propulsion system', description: 'Battery for the propulsion system' },
	},

	MAV_BATTERY_TYPE: {
		0: { value: 'MAV_BATTERY_TYPE_UNKNOWN', label: 'Unknown', description: 'Not specified' },
		1: { value: 'MAV_BATTERY_TYPE_LIPO', label: 'Lithium polymer', description: 'Lithium polymer battery' },
		2: { value: 'MAV_BATTERY_TYPE_LIFE', label: 'Lithium-iron-phosphate', description: 'Lithium-iron-phosphate battery' },
		3: { value: 'MAV_BATTERY_TYPE_LION', label: 'Lithium-ION', description: 'Lithium-ION battery' },
		4: { value: 'MAV_BATTERY_TYPE_NIMH', label: 'Nickel metal hydride', description: 'Nickel metal hydride battery' },
	},

	MAV_BATTERY_CHARGE_STATE: {
		0: { value: 'MAV_BATTERY_CHARGE_STATE_UNDEFINED', label: 'Undefined', description: 'Low battery state is not provided' },
		1: { value: 'MAV_BATTERY_CHARGE_STATE_OK', label: 'OK', description: 'Battery is not in low state. Normal operation' },
		2: { value: 'MAV_BATTERY_CHARGE_STATE_LOW', label: 'Low', description: 'Battery state is low, warn and monitor close' },
		3: { value: 'MAV_BATTERY_CHARGE_STATE_CRITICAL', label: 'Critical', description: 'Battery state is critical, return or abort immediately' },
		4: { value: 'MAV_BATTERY_CHARGE_STATE_EMERGENCY', label: 'Emergency', description: 'Battery state is too low for ordinary abort sequence. Perform fastest possible emergency stop to prevent damage' },
		5: { value: 'MAV_BATTERY_CHARGE_STATE_FAILED', label: 'Failed', description: 'Battery failed, damage unavoidable' },
		6: { value: 'MAV_BATTERY_CHARGE_STATE_UNHEALTHY', label: 'Unhealty', description: 'Battery is diagnosed to be defective or an error occurred, usage is discouraged / prohibited' },
		7: { value: 'MAV_BATTERY_CHARGE_STATE_CHARGING', label: 'Charging', description: 'Battery is charging' },
	},

	MAV_BATTERY_MODE: {
		0: { value: '	MAV_BATTERY_MODE_UNKNOWN', label: 'Unknown or normal', description: 'Battery mode not supported/unknown battery mode/normal operation' },
		1: { value: 'MAV_BATTERY_MODE_AUTO_DISCHARGING', label: 'Auto discharging', description: 'Battery is auto discharging (towards storage level)' },
		2: { value: 'MAV_BATTERY_MODE_HOT_SWAP', label: 'Hot swap', description: 'Battery in hot-swap mode (current limited to prevent spikes that might damage sensitive electrical circuits)' },
	},

	MAV_BATTERY_FAULT: {
		1: { value: 'MAV_BATTERY_FAULT_DEEP_DISCHARGE', label: 'Deep discharge', description: 'Battery has deep discharged' },
		2: { value: 'MAV_BATTERY_FAULT_SPIKES', label: 'Fault spikes', description: 'Voltage spikes' },
		4: { value: 'MAV_BATTERY_FAULT_CELL_FAIL', label: 'Cell fail', description: 'One or more cells have failed' },
		8: { value: 'MAV_BATTERY_FAULT_OVER_CURRENT', label: 'Over-current', description: 'Over-current fault' },
		16: { value: 'MAV_BATTERY_FAULT_OVER_TEMPERATURE', label: 'Over-temperature', description: 'Over-temperature fault' },
		32: { value: 'MAV_BATTERY_FAULT_UNDER_TEMPERATURE', label: 'Under-temperature', description: 'Under-temperature fault' },
		64: { value: 'MAV_BATTERY_FAULT_INCOMPATIBLE_VOLTAGE', label: 'Incompatible voltage', description: 'Vehicle voltage is not compatible with this battery (batteries on same power rail should have similar voltage)' },
		128: { value: 'MAV_BATTERY_FAULT_INCOMPATIBLE_FIRMWARE', label: 'Incompatible firmware', description: 'Battery firmware is not compatible with current autopilot firmware' },
		256: { value: 'BATTERY_FAULT_INCOMPATIBLE_CELLS_CONFIGURATION', label: 'Incompatible cells configuration', description: 'Battery is not compatible due to cell configuration (e.g. 5s1p when vehicle requires 6s)' },
	},

	ESTIMATOR_STATUS_FLAGS: {
		1: { value: 'ESTIMATOR_ATTITUDE', label: 'ESTIMATOR_ATTITUDE', description: 'True if the attitude estimate is good' },
		2: { value: 'ESTIMATOR_VELOCITY_HORIZ', label: 'ESTIMATOR_VELOCITY_HORIZ', description: 'True if the horizontal velocity estimate is good' },
		4: { value: 'ESTIMATOR_VELOCITY_VERT', label: 'ESTIMATOR_VELOCITY_VERT', description: 'True if the vertical velocity estimate is good' },
		8: { value: 'ESTIMATOR_POS_HORIZ_REL', label: 'ESTIMATOR_POS_HORIZ_REL', description: 'True if the horizontal position (relative) estimate is good' },
		16: { value: 'ESTIMATOR_POS_HORIZ_ABS', label: 'ESTIMATOR_POS_HORIZ_ABS', description: 'True if the horizontal position (absolute) estimate is good' },
		32: { value: 'ESTIMATOR_POS_VERT_ABS', label: 'ESTIMATOR_POS_VERT_ABS', description: 'True if the vertical position (absolute) estimate is good' },
		64: { value: 'ESTIMATOR_POS_VERT_AGL', label: 'ESTIMATOR_POS_VERT_AGL', description: 'True if the vertical position (above ground) estimate is good' },
		128: { value: 'ESTIMATOR_CONST_POS_MODE', label: 'ESTIMATOR_CONST_POS_MODE', description: 'True if the EKF is in a constant position mode and is not using external measurements (eg GPS or optical flow)' },
		256: { value: 'ESTIMATOR_PRED_POS_HORIZ_REL', label: 'ESTIMATOR_PRED_POS_HORIZ_REL', description: 'True if the EKF has sufficient data to enter a mode that will provide a (relative) position estimate' },
		512: { value: 'ESTIMATOR_PRED_POS_HORIZ_ABS', label: 'ESTIMATOR_PRED_POS_HORIZ_ABS', description: 'True if the EKF has sufficient data to enter a mode that will provide a (absolute) position estimate' },
		1024: { value: 'ESTIMATOR_GPS_GLITCH', label: 'ESTIMATOR_GPS_GLITCH', description: 'True if the EKF has detected a GPS glitch' },
		2048: { value: 'ESTIMATOR_ACCEL_ERROR', label: 'ESTIMATOR_ACCEL_ERROR', description: 'True if the EKF has detected bad accelerometer data' },
	},

	MAV_SYS_STATUS_SENSOR: {
		1: { value: 'MAV_SYS_STATUS_SENSOR_3D_GYRO', label: 'MAV_SYS_STATUS_SENSOR_3D_GYRO', description: '0x01 3D gyro' },
		2: { value: 'MAV_SYS_STATUS_SENSOR_3D_ACCEL', label: 'MAV_SYS_STATUS_SENSOR_3D_ACCEL', description: '0x02 3D accelerometer' },
		4: { value: '	MAV_SYS_STATUS_SENSOR_3D_MAG', label: '	MAV_SYS_STATUS_SENSOR_3D_MAG', description: '0x04 3D magnetometer' },
	},

	HL_FAILURE_FLAG_GPS: {
		1: { value: 'HL_FAILURE_FLAG_GPS', label: 'GPS failure', description: 'GPS failure' },
		2: { value: 'HL_FAILURE_FLAG_DIFFERENTIAL_PRESSURE', label: 'Differential pressure', description: 'Differential pressure sensor failure' },
		4: { value: 'HL_FAILURE_FLAG_ABSOLUTE_PRESSURE', label: 'Absolute pressure', description: 'Absolute pressure sensor failure' },
		8: { value: 'HL_FAILURE_FLAG_3D_ACCEL', label: 'Accelerometer failure', description: 'Accelerometer sensor failure' },
		16: { value: 'HL_FAILURE_FLAG_3D_GYRO', label: 'Gyroscope failure', description: 'Gyroscope sensor failure' },
		32: { value: 'HL_FAILURE_FLAG_3D_MAG', label: 'Magnetometer failure', description: 'Magnetometer sensor failure' },
		64: { value: 'HL_FAILURE_FLAG_TERRAIN', label: 'Terrain subsystem failure', description: 'Terrain subsystem failure' },
		128: { value: 'HL_FAILURE_FLAG_BATTERY', label: 'Battery failure', description: 'Battery failure/critical low battery' },
		256: { value: 'HL_FAILURE_FLAG_RC_RECEIVER', label: 'RC receiver failure', description: 'RC receiver failure/no rc connection' },
		512: { value: 'HL_FAILURE_FLAG_OFFBOARD_LINK', label: 'Offboard link failure', description: 'Offboard link failure' },
		1024: { value: 'HL_FAILURE_FLAG_ENGINE', label: 'Engine failure', description: 'Engine failure' },
		2048: { value: 'HL_FAILURE_FLAG_GEOFENCE', label: 'Geofence violation', description: 'Geofence violation' },
		4096: { value: 'HL_FAILURE_FLAG_ESTIMATOR', label: 'Estimator failure', description: 'Estimator failure, for example measurement rejection or large variances' },
		8192: { value: 'HL_FAILURE_FLAG_MISSION', label: 'Mission failure', description: 'Mission failure' },
	},

	MAV_RESULT: {
		MAV_RESULT_ACCEPTED: 0,
		MAV_RESULT_TEMPORARILY_REJECTED: 1,
		MAV_RESULT_DENIED: 2,
		MAV_RESULT_UNSUPPORTED: 3,
		MAV_RESULT_FAILED: 4,
		MAV_RESULT_IN_PROGRESS: 5,
		MAV_RESULT_CANCELLED: 6,
	},

	MAV_CMD: {
		MAV_CMD_DO_SET_MODE: 176,
		MAV_CMD_COMPONENT_ARM_DISARM: 400,
		MAV_CMD_NAV_TAKEOFF: 22,
		MAV_CMD_NAV_LAND: 21,
		MAV_CMD_OVERRIDE_GOTO: 252,
		MAV_CMD_NAV_WAYPOINT: 16,
		MAV_CMD_NAV_LOITER_UNLIM: 17,
		MAV_CMD_NAV_LOITER_TURNS : 18,
		MAV_CMD_MISSION_START: 300,
		MISSION_CLEAR_ALL: 45,
		MAV_CMD_RUN_PREARM_CHECKS: 401,
		SET_HOME_POSITION: 243,
		MAV_CMD_DO_CHANGE_SPEED: 178,
		MAV_CMD_DO_ORBIT: 34,
	},

	MAV_MISSION: {
		MISSION_ACK: 47, 
	},
	
	MAV_MISSION_RESULT: {
		0: {
			value: 'MAV_MISSION_ACCEPTED',
			label: 'Mission accepted OK'
		},
		1: {
			value: 'MAV_MISSION_ERROR',
			label: 'Generic error / not accepting mission commands at all right now.'
		}, 
		2: {
			value: 'MAV_MISSION_UNSUPPORTED_FRAME',
			label: 'Coordinate frame is not supported.'
		}, 
		3: {
			value: 'MAV_MISSION_UNSUPPORTED',
			label: 'Command is not supported.',
		}, 
		4: {
			value: 'MAV_MISSION_NO_SPACE',
			label: 'Mission items exceed storage space.',
		},
		5: {
			value: 'MAV_MISSION_INVALID',
			label: 'One of the parameters has an invalid value.',
		},
		6: {
			value: 'MAV_MISSION_INVALID_PARAM1',
			label: 'param1 has an invalid value.',
		},
		7: {
			value: 'MAV_MISSION_INVALID_PARAM2',
			label: 'param2 has an invalid value.',
		},
		8: {
			value: 'MAV_MISSION_INVALID_PARAM3',
			label: 'param3 has an invalid value.',
		},
		9: {
			value: 'MAV_MISSION_INVALID_PARAM4',
			label: 'param4 has an invalid value.',
		},
		10: {
			value: 'MAV_MISSION_INVALID_PARAM5_X',
			label: 'x / param5 has an invalid value.',
		},
		11: {
			value: 'MAV_MISSION_INVALID_PARAM6_Y',
			label: 'y / param6 has an invalid value.',
		},
		12: {
			value: 'MAV_MISSION_INVALID_PARAM7',
			label: 'z / param7 has an invalid value.',
		},
		13: {
			value: 'MAV_MISSION_INVALID_SEQUENCE',
			label: 'Mission item received out of sequence.',
		},
		14: {
			value: 'MAV_MISSION_DENIED',
			label: 'Not accepting any mission commands from this communication partner.',
		},
		15: {
			value: 'MAV_MISSION_OPERATION_CANCELLED',
			label: 'Current mission operation cancelled (e.g. mission upload, mission download).',
		},
	},

	CUSTOM_MAV_MODE: {
		0 : 'STABILIZE',
		1 : 'ACRO',
		2 : 'ALT_HOLD',
		3 : 'AUTO',
		4 : 'GUIDED',
		5 : 'LOITER',
		6 : 'RTL',
		7 : 'CIRCLE',
		8 : 'POSITION',
		9 : 'LAND',
		10 : 'OF_LOITER',
		11 : 'DRIFT',
		13 : 'SPORT',
		14 : 'FLIP',
		15 : 'AUTOTUNE',
		16 : 'POSHOLD',
		17 : 'BRAKE',
		18 : 'THROW',
		19 : 'AVOID_ADSB',
		20 : 'GUIDED_NOGPS',
		21 : 'SMART_RTL',
		22 : 'FLOWHOLD',
		23 : 'FOLLOW',
		24 : 'ZIGZAG',
	},

	MAV_MODE_FLAG: {
		MAV_MODE_FLAG_SAFETY_ARMED: 128,
		MAV_MODE_FLAG_MANUAL_INPUT_ENABLED: 64,
		MAV_MODE_FLAG_HIL_ENABLED: 32,
		MAV_MODE_FLAG_STABILIZE_ENABLED: 16,
		MAV_MODE_FLAG_GUIDED_ENABLED: 8,
		MAV_MODE_FLAG_AUTO_ENABLED: 4,
		MAV_MODE_FLAG_TEST_ENABLED: 2,
		MAV_MODE_FLAG_CUSTOM_MODE_ENABLED: 1,
	},

	MAV_MISSION_TYPE: {
		MAV_MISSION_TYPE_MISSION: 0,
		MAV_MISSION_TYPE_FENCE: 1,
		MAV_MISSION_TYPE_RALLY: 2,
		MAV_MISSION_TYPE_ALL: 255,
	},

	MAV_LANDED_STATE: {
		0: {
			value: 'MAV_LANDED_STATE_UNDEFINED',
			label: 'Undefined',
		},
		1: {
			value: 'MAV_LANDED_STATE_ON_GROUND',
			label: 'Grounded',
		},
		2: {
			value: 'MAV_LANDED_STATE_IN_AIR',
			label: 'Airborn',
		},
		3: {
			value: 'MAV_LANDED_STATE_TAKEOFF',
			label: 'Takeoff',
		},
		3: {
			value: 'MAV_LANDED_STATE_LANDING',
			label: 'Landing',
		}
	},

	MAV_SYS_STATUS_SENSOR: {
		MAV_SYS_STATUS_SENSOR_3D_GYRO: { index: 1, title: '3D gyro' },
		MAV_SYS_STATUS_SENSOR_3D_ACCEL: { index: 2, title: '3D accelerometer' },
		MAV_SYS_STATUS_SENSOR_3D_MAG: { index: 4, title: '3D magnetometer' },
		MAV_SYS_STATUS_SENSOR_ABSOLUTE_PRESSURE: { index: 8, title: 'Absolute pressure sensor' },
		MAV_SYS_STATUS_SENSOR_DIFFERENTIAL_PRESSURE: { index: 16, title: 'Differential pressure sensor' },
		MAV_SYS_STATUS_SENSOR_GPS: { index: 32, title: 'GPS' },
		MAV_SYS_STATUS_SENSOR_OPTICAL_FLOW: { index: 64, title: 'Optical flow sensor' },
		MAV_SYS_STATUS_SENSOR_VISION_POSITION: { index: 128, title: 'Computer vision position sensor' },
		MAV_SYS_STATUS_SENSOR_LASER_POSITION: { index: 256, title: 'laser based position sensor' },
		MAV_SYS_STATUS_SENSOR_EXTERNAL_GROUND_TRUTH: { index: 512, title: 'External ground truth sensor' },
		MAV_SYS_STATUS_SENSOR_ANGULAR_RATE_CONTROL: { index: 1024, title: '3D angular rate control sensor' },
		MAV_SYS_STATUS_SENSOR_ATTITUDE_STABILIZATION: { index: 2048, title: 'Attitude stabilization sensor' },
		MAV_SYS_STATUS_SENSOR_YAW_POSITION: { index: 4096, title: 'Yaw position sensor' },
		MAV_SYS_STATUS_SENSOR_Z_ALTITUDE_CONTROL: { index: 8192, title: 'Z/altitude control sensor' },
		MAV_SYS_STATUS_SENSOR_XY_POSITION_CONTROL: { index: 16384, title: 'X/Y position control sensor' },
		MAV_SYS_STATUS_SENSOR_MOTOR_OUTPUTS: { index: 32768, title: 'Motor outputs / control sensor' },
		MAV_SYS_STATUS_SENSOR_RC_RECEIVER: { index: 65536, title: 'RC receiver' },
		MAV_SYS_STATUS_SENSOR_3D_GYRO2: { index: 131072, title: '2nd 3D gyro' },
		MAV_SYS_STATUS_SENSOR_3D_ACCEL2: { index: 262144, title: '2nd 3D accelerometer' },
		MAV_SYS_STATUS_SENSOR_3D_MAG2: { index: 524288, title: '2nd 3D magnetometer' },
		MAV_SYS_STATUS_GEOFENCE: { index: 1048576, title: 'Geofence sensor' },
		MAV_SYS_STATUS_AHRS: { index: 2097152, title: 'AHRS subsystem' },
		MAV_SYS_STATUS_TERRAIN: { index: 4194304, title: 'Terrain subsystem' },
		MAV_SYS_STATUS_REVERSE_MOTOR: { index: 8388608, title: 'Motors are reversed' },
		MAV_SYS_STATUS_LOGGING: { index: 16777216, title: 'Logging subsystem' },
		MAV_SYS_STATUS_SENSOR_BATTERY: { index: 33554432, title: 'Battery sensor' },
		MAV_SYS_STATUS_SENSOR_PROXIMITY: { index: 67108864, title: 'Proximity sensor' },
		MAV_SYS_STATUS_SENSOR_SATCOM: { index: 134217728, title: 'Satellite Communication subsystem' },
		MAV_SYS_STATUS_PREARM_CHECK: { index: 268435456, title: 'Pre-arm check' },
		MAV_SYS_STATUS_OBSTACLE_AVOIDANCE: { index: 536870912, title: 'Avoidance/collision prevention' },
		MAV_SYS_STATUS_SENSOR_PROPULSION: { index: 1073741824, title: 'Propulsion (actuator, esc, motor or propellor) system' },
		MAV_SYS_STATUS_SENSOR_ENUM_END: { index: 1073741825, title: '' },
	},

	MAV_PROTOCOL_CAPABILITY: {
		//...
		MAV_PROTOCOL_CAPABILITY_MAVLINK2: 8192,
		MAV_PROTOCOL_CAPABILITY_MISSION_RALLY: 32768,
		//...
	}
};

export default constants;

import React from "react";
import { Circle, Marker, Popup, Tooltip } from 'react-leaflet'
import { Loading } from "../shared-components/layout/loading/loading";
import Icons from "./icons";
import { Point } from "./point";

export class Waypoint extends Point {
	constructor(latitude, longitude, label=null, altitude=10, speed=5, seq=null, isTakeOff=false, isLanding=false, isLoiter=false, radius=0) {
		super(latitude, longitude, isLoiter ? 'ORB' : 'WP', label, altitude, speed, seq, isTakeOff, isLanding, isLoiter, radius);
	}

	getIconUrl() {
		if (this.isTakeOff) {
			return Icons.regular.takeoffIcon;
		}
		if (this.isLanding) {
			return Icons.regular.landingIcon;
		}
		return Icons.regular.waypointIcon;
	}

	getMarker(options, altitudeDatum, page) {
		return (
			<Marker 
				className="waypoint-marker"
				key={`waypoint-marker-${this.id}`}
				opacity={options.opacity != null ? options.opacity : 1}
				eventHandlers={{
					click: (e) => {
						if (options.onClick) {
							options.onClick(e, this);
						}
					},
					dragend: (e) => {
						if (options.onPositionUpdated) {
							const { lat: latitude, lng: longitude } = e.target._latlng.wrap();
							options.onPositionUpdated(e, { latitude, longitude });
						}
					}
				}}
				draggable={options.draggable != null ? options.draggable : true}
				position={[this.latitude, this.longitude]} 
				icon={
						this.isLoiter ?
						Icons.map.intersectionWaypointIcon
						:
						this.isTakeOff ? 
						Icons.map.takeoffIcon
						:
						this.isLanding ?
						Icons.map.landingIcon
						:
						Icons.map.waypointIcon
				}
			>
				{ options.markerLabelsVisible && page != '/home' &&
					<Tooltip 
						className="marker-tooltip"
						direction="right" 
						opacity={1} 
						offset={[-15,30]}
						permanent
					>
						<p className="waypoint-tooltip">
							<span>
							{ options.label ? this.label : (this.isLoiter ? 'ORB' : options.index != null && `WP${options.index}`)}
							{
								!this.isLoiter && (
									this.isTakeOff ? 
										' | T/O'
										:
										this.isLanding ?
										' | LND'
										:
										''
									)
							}
							</span>
							<br/>
							<span>{
									((page != '/cockpit' && page != '/cockpit-v2' && altitudeDatum == 'AGL' ? 
										this.agl : 
										page != '/cockpit' && page != '/cockpit-v2' && altitudeDatum == 'AMSL' ? 
										page != '/cockpit' && page != '/cockpit-v2' && this.amsl : this.altitude) 
									|| <div style={{ height: 20, width: 20, display: 'inline-block' }}>
											<Loading position="absolute" height={20} width={20}/>
										</div>) } {page != '/cockpit' && page != '/cockpit-v2' ? altitudeDatum : 'ALP'} | {this.speed != null ? `${this.speed} M/S` : 'N/A'}</span>
						</p>
					</Tooltip>
				}

				{
					this.isLoiter &&
					<Circle radius={this.radius} center={[this.latitude, this.longitude]} dashArray="5" dashOffset="5" color="white"/>
				}

				{
					options.pathTitle && 
						<Tooltip 
							className={`route-tooltip ${options.isSelectedRoute && 'selected'}`}
							autoClose={false}
							interactive={true}
							direction='top'
							permanent
							offset={[-15,30]}
							onClick={() => console.log(options.pathTitle) || (options.onClick && options.onClick())}
						>
							<p>{options.pathTitle}</p>
						</Tooltip>
				}
			</Marker>
		)
	}
}
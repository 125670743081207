import axios from "axios";
import React, { createContext, useContext, useState, useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";
import UtilityService from "../helpers/utility-service";
import { CockpitContext } from "./cockpit-context";
import { ConfigContext } from "./config-context";
import { MissionContext } from "./mission-context";

export const TerrainContext = createContext();
const defaultPath = [];

const TerrainContextProvider = ({ children }) => {
	const { services: {
			terrainService: {
				host,
				terrainResolution,
			}
		}
	} = useContext(ConfigContext);
	const location = useLocation();
	/*
	Path Segment:
	{
		startWaypoint,
		points,
		elevation,
		cancelToken,
	}
  */

	
	const [currentPathIndex, setCurrentPathIndex] = useState(0);
 	const [paths, setPaths] = useState([defaultPath]);
	const [pathSegments, setPathSegments] = useState(defaultPath);
	let [isBusy, setIsBusy] = useState(0);
	const busyFlag = useRef(0);
	const recalculateBounceTimer = useRef(null);
	const canceledRequests = useRef({});
	const pendingRequest = useRef(null);
	const [currentMissionPath, setCurrentMissionPath] = useState(null);
	const [currentCockpitPath, setCurrentCockpitPath] = useState(null);

	// useEffect(() => {

	// 	clearTerrainPath();
	// 	if(location.pathname === '/cockpit-v2') {
	// 		setCurrentCockpitPath(selectedPath);
	// 	} else if(location.pathname === '/mission-planning') {
	// 		setCurrentMissionPath(selectedPath);
	// 	}
			
	// }, [location])
	
	/**
	 * New path created by copying existing path
	 */
	const createNewTerrainPath = (flightPath) => {
		const newPathSegments = UtilityService.clone(pathSegments);

		for (let i = 0; i < flightPath.waypoints.length; i += 1) {
			const wp = flightPath.waypoints[i];

			const { waypoint: segmentWaypoint } = newPathSegments[i];
			wp.altitude = segmentWaypoint.altitude;
			wp.agl = segmentWaypoint.agl;
			wp.alp = segmentWaypoint.alp;
			wp.amsl = segmentWaypoint.amsl;

			newPathSegments.waypoint = wp;
		}

		const newCount = paths.length;
		setCurrentPathIndex(newCount);
		setPaths([...paths, newPathSegments]);
		setPathSegments(newPathSegments);
	}

	/**
	 * Change selected path segments
	 * @param {} pathIndex 
	 */
	const selectTerrainPath = (pathIndex) => {
		setCurrentPathIndex(pathIndex);
		setPathSegments(paths[pathIndex]);
	}

	/**
	 * Change selected path segments
	 * @param {} pathIndex 
	 */
	 const deleteTerrainPath = (pathIndex) => {
		if (paths.length > 1) {
			if (pathIndex < paths.length - 1) {
				setCurrentPathIndex(pathIndex);
				setPathSegments(paths[pathIndex + 1]);
			} else {
				setCurrentPathIndex(pathIndex - 1);
				setPathSegments(paths[pathIndex - 1]);
			}
				
			paths.splice(pathIndex, 1)
			setPaths([...paths]);
		}
	}

	/**
	 * Recalculate elevation values
	 */
	const recalculate = () => {
		if (recalculateBounceTimer.current) {
			clearTimeout(recalculateBounceTimer.current);
			canceledRequests.current[pendingRequest.current] = true;
			pendingRequest.current = null;
		}

		recalculateBounceTimer.current = setTimeout(() => {
			const reqId = UtilityService.generateId();
			pendingRequest.current = reqId;

			busyFlag.current += 1;
			setIsBusy(busyFlag.current);

			const newSegments = [];

			for (let i = 0; i < pathSegments.length; i += 1) {
				const segment = pathSegments[i];
				let nextSegment = null;

				if (i < pathSegments.length - 1) {
					nextSegment = pathSegments[i + 1];
				}

				const newSegment = {
					waypoint: segment.waypoint,
					elevation: [],
				};

				newSegments.push(newSegment);
			}

			const promises = [];

			for (let i = 0; i < newSegments.length; i += 1) {
				const segment = newSegments[i];
				promises.push(updatePathElevation(segment, i < newSegments.length - 1 ? newSegments[i + 1].waypoint : null));
			}

			Promise.all(promises)
				.then(() => {
					if (canceledRequests.current[reqId] == null) {

						// Update altitudes of path waypoints
						for (let i = 0; i < newSegments.length; i += 1) {
							const segment = newSegments[i];
							const { waypoint } = segment;
							const startWaypoint = newSegments[0].waypoint;
							waypoint.elevation = segment.elevation[0]
							waypoint.alp = waypoint.altitude;

							console.log(waypoint.elevation);

							if (waypoint.altitude == 'X' && i > 0) {
								const { waypoint: prevWaypoint } = newSegments[i - 1];
								const diff = waypoint.elevation - prevWaypoint.elevation;

								waypoint.agl = prevWaypoint.agl;
								waypoint.amsl = prevWaypoint.amsl + diff;
								waypoint.altitude = prevWaypoint.altitude + diff;
								waypoint.alp = prevWaypoint.alp + diff;
							}
							else if (i == 0) {
								waypoint.agl = waypoint.altitude;
								waypoint.amsl = waypoint.elevation + waypoint.altitude;
							} else {
								waypoint.agl = startWaypoint.elevation + waypoint.altitude - waypoint.elevation;
								waypoint.amsl = waypoint.elevation + waypoint.agl;
							}
						}
						setPathSegments(newSegments);
						paths[currentPathIndex] = newSegments;
					}
				})
				.catch((err) => console.log(err))
				.finally(() => {
					busyFlag.current -= 1;
					setIsBusy(busyFlag.current);

					if (canceledRequests.current[reqId] != null) {
						delete canceledRequests.current[reqId];
					}

					if (pendingRequest.current == reqId) {
						pendingRequest.current = null;
					}
				});
		}, 100);
	}


	/**
	 * Get single point elevation
	 * @param {*} pointIndex 
	 * @returns 
	 */
	const getPointElevation = (pointIndex) => {
		if (pathSegments[pointIndex] == null || pathSegments[pointIndex].elevation == null) {
			return null;
		}

		return pathSegments[pointIndex].elevation[0];
	}

	/**
	 * Update path elevation data
	 * @param {*} pathSegment 
	 */
	const updatePathElevation = (pathSegment, toWaypoint) => {
		return new Promise((resolve, reject) => {
			const { waypoint: fromWaypoint } = pathSegment;
			const points = toWaypoint != null ?
				`${fromWaypoint.latitude},${fromWaypoint.longitude},${toWaypoint.latitude},${toWaypoint.longitude}`
				:
				`${fromWaypoint.latitude},${fromWaypoint.longitude}`;

			if (toWaypoint != null) {
				axios.get(host + '/path', { params: { points } })
				.then(res => {
					// Update path
					pathSegment.elevation = res.data.data[0].profile;
					resolve();
				})
				.catch(err => {
					reject(err);
				});	
			} else {
				axios.get(host, { params: { points } })
				.then(res => {
					// Update path
					pathSegment.elevation = res.data.data;
					resolve();
				})
				.catch(err => {
					reject(err);
				});	
			}
		});
	}
	
	/**
	 * Remove path segment
	 * @param {*} segmentIndex 
	 */
	const removeSegment = (segmentIndex, shouldRecalculate=true) => {
		pathSegments.splice(segmentIndex, 1);
		if (shouldRecalculate) {
			recalculate();
		}
	}

	/**
	 * Add path segment for new waypoint
	 * @param {*} waypoint 
	 * @param {*} segmentIndex 
	 */
	const addSegment = (waypoint, segmentIndex) => {
		if (segmentIndex != null) {
			pathSegments.splice(segmentIndex, 0, { waypoint, elevation: [0] })
		} else {
			pathSegments.push({ waypoint, elevation: [0] })
		}

		recalculate();
	}

	/**
	 * Update segment data (updated waypoint position)
	 * @param {*} waypoint 
	 * @param {*} segmentIndex 
	 */
	const updateSegment = async (waypoint, segmentIndex) => {
		pathSegments[segmentIndex].waypoint = waypoint;
		recalculate();
	}		

	const createMultipleTerrainPaths = (flightPaths) => {
		setPaths([]);
		const newPaths = [];
		
		const globalPromises = [];

		busyFlag.current += 1;
		setIsBusy(busyFlag.current);

		for (const path of flightPaths) {
			const promises = [];
			const currentPathSegments = [];

			for (const waypoint of path.waypoints) {
				currentPathSegments.push({ waypoint, elevation: [] });
			}

			for (let i = 0; i < currentPathSegments.length; i += 1) {
				const segment = currentPathSegments[i];
				promises.push(updatePathElevation(segment, i < currentPathSegments.length - 1 ? currentPathSegments[i + 1].waypoint : null));
			}

			newPaths.push(currentPathSegments);
	
			globalPromises.push(new Promise((resolve, reject) => {
				Promise.all(promises).then(() => {
					// Update altitudes of path waypoints
					for (let i = 0; i < currentPathSegments.length; i += 1) {
						const segment = currentPathSegments[i];
						const { waypoint } = segment;
						const startWaypoint = currentPathSegments[0].waypoint;
						waypoint.elevation = segment.elevation[0]
						waypoint.alp = waypoint.altitude;

						if (i == 0) {
							waypoint.agl = waypoint.altitude;
							waypoint.amsl = waypoint.elevation + waypoint.altitude;
						} else {
							waypoint.agl = startWaypoint.elevation + waypoint.altitude - waypoint.elevation;
							waypoint.amsl = waypoint.elevation + waypoint.agl;
						}
					}
					resolve();
				}).catch(err => reject(err));
			}))
		}

		Promise.all(globalPromises).then(() => {
			setPaths(newPaths);
			setPathSegments(newPaths[0]);

			busyFlag.current -= 1;
			setIsBusy(busyFlag.current);
		}).catch(err => console.log(err));
	}

	const getCarpetData = async (center) => {
		const { latitude, longitude } = center;
		const nePoint = UtilityService.getShiftedPoint(center, 424.26, 424.26);
		const swPoint = UtilityService.getShiftedPoint(center, -424.26, -424.26);

		const res = await axios.get(`${host}/carpet`, {
			params: {
				points: `${swPoint.latitude},${swPoint.longitude},${nePoint.latitude},${nePoint.longitude}`,
			}
		})

		return res.data.data.carpet.reverse();
	}

	const clearTerrainPath = () => {
		paths[currentPathIndex] = [];
		setPaths([...paths]);
		setPathSegments([]);
	}
	const loadPath = ({path}) => {
		clearTerrainPath();
	}
	return (
		<TerrainContext.Provider value={{
			isBusy: isBusy > 0,
			pathSegments,
			getPointElevation,
			addSegment,
			updateSegment,
			removeSegment,
			createNewTerrainPath,
			selectTerrainPath,
			deleteTerrainPath,
			createMultipleTerrainPaths,
			getCarpetData,
			clearTerrainPath,
			loadPath,
			setCurrentCockpitPath,
			setCurrentMissionPath,
			currentMissionPath: currentMissionPath || [],
			currentCockpitPath: currentCockpitPath || []
		}}>
			{ children }
		</TerrainContext.Provider>
	)
}

export default TerrainContextProvider;
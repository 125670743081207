import { Check, Delete, Edit } from '@mui/icons-material';
import { TreeItem } from '@mui/lab';
import React, { useContext, useRef, useState } from 'react';
import { MissionContext } from '../../../../../../../contexts/mission-context';
import { WaypointsLibraryContext } from '../../../../../../../contexts/waypoints-library-context';


export const WaypointsLibraryItem = ({waypoints, folder, title, type, visible}) => {
    const {removeWaypoint, updateWaypoint} = useContext(WaypointsLibraryContext);
    const [isEditable, setIsEditable] = useState(false);
    const wpRef = useRef(null);
    const {addWaypoint} = useContext(MissionContext);

    const onEditWaypoint = (id) =>{
        setIsEditable(id);
        console.log("ID",id);
    }
    const onAddWaypointToList = (waypoint) => {
        console.log(waypoint);
        addWaypoint(parseFloat(waypoint.data.latitude), parseFloat(waypoint.data.longitude), null, waypoint.title, null);
        
    }
    const handleUpdateWaypoint = (id, data) => {
        // console.log(data);
        if(data != null && data != "") {
            updateWaypoint(id, {title: data});
            setIsEditable(false);
        }
       
    }
    const handleRemoveWaypoint = (id) => {
        if(window.confirm("Are you sure you want to delete waypoint?")) { 
            removeWaypoint(id);
        }
    }
    
    return(
        visible && <TreeItem nodeId={`${folder}-${type}`} label={title}>
            {waypoints.map((wp, index) => (
            <TreeItem 
                nodeId={`${folder}-${type}-${index}`} 
                key={`${folder}-${type}-${index}`} 
                label={
                    <div className='library-folder-item'>

                        {isEditable === wp.id ? 
                            <input className='library-input' defaultValue={wp.title} ref={wpRef}/> : 
                            <div className='library-folder-item-name'>{wp.title}</div>
                        }

                        <div className='library-folder-item-actions'>
                            {!isEditable && isEditable != wp.id && 
                                <span onClick={(e) =>{ e.preventDefault();onAddWaypointToList(wp)}} className='library-folder-action-icon uppercase'>add</span>}

                            {isEditable  && isEditable === wp.id ?  
                            <Check className='green' onClick={()=> handleUpdateWaypoint(wp.id, wpRef.current.value)}/> :
                                <Edit onClick={(e) =>{ e.preventDefault();onEditWaypoint(wp.id)}} className='library-folder-action-icon'/>  
                                
                            }

                            <Delete  onClick= {(e) =>{ e.preventDefault();handleRemoveWaypoint(wp.id)}} className='library-folder-action-icon delete'/>
                            
                        </div>
                    </div>
                    }
                onClick= {(e) =>{e.stopPropagation();}}>

            </TreeItem> 
            ))
        }
        </TreeItem> 
    )
}


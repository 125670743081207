import React from "react";
import UtilityService from "./utility-service";

export class Point {
	constructor(latitude, longitude, type, label=null, altitude, speed, seq, isTakeOff, isLanding, isLoiter, radius) {
		this.latitude = latitude;
		this.longitude = longitude;
		this.altitude = altitude || 10;
		this.speed = speed || 5;
		this.id = UtilityService.generateId();
		this.label = label || this.id.slice(-5);
		this.type = type;
		this.seq = seq;
		this.isTakeOff = isTakeOff || false;
		this.isLanding = isLanding || false;
		this.isLoiter = isLoiter || false;
		this.radius = radius;
	}

	getPosition() {
		return [this.latitude, this.longitude];
	}
}
import React, { useContext, useEffect, useRef, useState } from 'react';
import { AircraftContext } from '../../../../contexts/aircraft-context';

import { Stack } from '../../../../shared-components/layout/stack/stack';
import PerfectScrollbar from 'react-perfect-scrollbar';
import UtilityService from '../../../../helpers/utility-service';

export const CockpitMissionPath = () => {
    const width = 400;

    const { selectedAircraft } = useContext(AircraftContext);
    const currentAircraft = useRef(null);
    const [waypoints, setWaypoints] = useState([]);
    const [progress, setProgress] = useState(0);
    const [selectedMissionItem, setSelectedMissionItem] = useState(null);
    const totalDistance = useRef(1);
    const lastPosition = useRef(null);

    const currentWaypoints = useRef([]);
    const currentSeq = useRef(null);
	const subscriptions = useRef([]);

    useEffect(() => {
        if (selectedAircraft != currentAircraft.current) {
            currentAircraft.current = selectedAircraft;
            
            if (currentAircraft.current != null) {
				for (const sub of subscriptions.current) {
					sub();
					subscriptions.current = [];
				}
			} 

            if (selectedAircraft != null) {
                const processed = processWaypoints(selectedAircraft.missionState.getState().uploadedMissionItems.mission);
                setWaypoints(processed);
                currentWaypoints.current = processed;
                currentSeq.current = selectedAircraft.missionState.getState().currentSeq;
                setSelectedMissionItem(currentSeq.current);

                subscriptions.current.push(selectedAircraft.missionState.subscribe(() => {
                    currentSeq.current = selectedAircraft.missionState.getState().currentSeq;
                    setSelectedMissionItem(selectedAircraft.missionState.getState().currentSeq);
                    
                    const processed = processWaypoints(selectedAircraft.missionState.getState().uploadedMissionItems.mission);
                    setWaypoints(processed);

                    const { latitude, longitude } = selectedAircraft.positionState.getState();
                    processPositionChange({ latitude, longitude });
                    currentWaypoints.current = processed;
                }));

                subscriptions.current.push(selectedAircraft.positionState.subscribe(() => {
                    const { latitude, longitude } = selectedAircraft.positionState.getState();
                    processPositionChange({ latitude, longitude })
                }));
            }
        }
    })

    const processPositionChange = ({ latitude, longitude}) => {    
        const waypoints = currentWaypoints.current;
        const currentWaypointSeq = currentSeq.current;

        if (latitude != null && longitude != null && (lastPosition.current == null || UtilityService.distance(lastPosition.current, { latitude, longitude }) > 2) && currentWaypointSeq  != null && waypoints.length > 0) {
            
            lastPosition.current = { latitude, longitude };
            let currentDist = 0;
            let lastWp = null;

            for (let i = 0; i < waypoints.length; i+= 1) {
                const wp = waypoints[i]
                if (wp.data.seq >= currentWaypointSeq) {
                    break;
                }
                
                if (lastWp != null) {
                    currentDist += UtilityService.distance(wp.data, waypoints[i - 1].data);
                } 
                lastWp = wp.data;
                
            }


            if (lastWp != null) {
                currentDist += UtilityService.distance(lastWp, { latitude, longitude });
            }

            setProgress(currentDist / totalDistance.current * 100);
        }
    }


    const processWaypoints = (waypoints) => {
        // const processed = waypoints.map((wp, index) => ({ data: wp, dist: index == 0 ? 0 : waypoints[index - 1].dist + UtilityService.distance(wp, waypoints[index - 1]) }));
        const processed = [];
        let totalDist = 0;
        for (let i = 0; i < waypoints.length; i += 1) {
            const wp = waypoints[i];

            if (i > 0) {
                totalDist += UtilityService.distance(waypoints[i - 1], wp);
            }

            processed.push({ data: wp, dist: totalDist })
        }

        totalDistance.current = totalDist;
        return processed.map(wp => ({ ...wp, dist: wp.dist / totalDist * width }));
    }

    return (
        <div className='cockpit-mission-path-wrap'>
            <div className='cockpit-mission-path' style={{ width }}>
                <div className="progress-bar" style={{ width: `${Math.max(0, Math.min(progress, 100))}%`}}></div>
                {waypoints && waypoints.map((wp, index)=>(
                    <div 
                        onClick={() => selectedAircraft && selectedAircraft.setNextMissionItem(wp.data.seq)}
                        key={`cockpit-mission-path-wp-${index}-${selectedMissionItem}`} 
                        className={`cockpit-mission-path-wp  ${wp.active ? 'active' : ""} ${index == 0 ? 'first' : ""} ${index == waypoints.length - 1 ? "last":""}`}
                        style={{ left: wp.dist - 7 }}
                    >
                        <div className={`wp-marker`} style={{...(selectedAircraft != null && wp.data.seq == selectedMissionItem && { borderColor: 'yellow', borderWidth: 3 })}}></div>
                        <p className='uppercase bold'>WP{index + 1}</p>
                    </div>
                ))}
            </div>
            {/* <PerfectScrollbar>
            <div className='cockpit-mission-dots-wrap'>
            
            </div>
            </PerfectScrollbar> */}
        </div>
    )
}
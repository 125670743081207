const statusReducer = (
	state = { 
		gyroStatus: null,
		accelerometerStatus: null,
		magStatus: null,
		
        altitudeEstimate: null,
		horizontalVelocityEstimate: null,
		verticalVelocityEstimate: null,
        relativeHorizontalPositionEstimate: null,
        absoluteHorizontalPositionEstimate: null,
        absoluteVerticalPositionEstimate: null,
        constantPositionMode: null,
        predPosHorizRel: null,
        predPosHorizAbs: null,

        gpsGlitch: null,
        acceleratorError: null,

		logs: [],
	}, action) => {
	switch (action.type) {
		case 'SET_STATUS_DATA':
			return { ...state, ...action.payload };
		case 'ADD_LOGS':
			state.logs.push(...action.payload)
			return state;
		case 'CLEAR_LOGS':
			state.logs = [];
			return state;
		default: return state;
	}
}

export default statusReducer;

export const createSetters = (store) => ({
		setStatusData: (statusData) => store.dispatch({ type: 'SET_STATUS_DATA', payload: statusData }),
		addLogs: (logs) => store.dispatch({ type: 'ADD_LOGS', payload: logs }),
		clearLogs: () => store.dispatch({ type: 'CLEAR_LOGS' }),
});

import React from 'react';

export const RiskAssessmentAir = () => {
    
    return(
        <div className='risk-assessment-air'>
             <table className='analysis-table'>
                        <thead>
                            <th className='uppercase analysis-type blue'>Analysis type</th>
                            <th className='uppercase blue'>Risk level</th>
                            <th className='uppercase blue'>Score</th>
                        </thead>
                        <tbody>
                           <tr>
                                <td className='uppercase bold'>Ballistic descent</td>
                                <td className={`uppercase`}></td>
                                <td className='bold'></td>
                            </tr>
                            <tr>
                                <td className='uppercase bold'>Uncontrolled Glide</td>
                                <td className={`uppercase`}></td>
                                <td className='bold'></td>
                            </tr>
                          <tr>
                                <td className='uppercase bold'>Parachute Descent</td>
                                <td className={`uppercase`}></td>
                                <td className='bold'></td>
                            </tr>
                        </tbody>
                    </table>
        </div>
    )
}
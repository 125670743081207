import React from 'react';

export const StatusTokenLabel = ({color, label}) =>{
    
        return(
            <div className='status-token-label-wrap'>
                <div className='status-token' style={{backgroundColor: color}}></div>
                <p>{label}</p>
            </div>
        );

    
}

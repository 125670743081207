import React,{useState} from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { PanelTools } from '../../../../shared-components/buttons/panel-tools/panel-tools';
import {FleetManagementCreateModal} from '../fleet-management-create-modal/fleet-management-create-modal';
export const FMDashboardPanel = (
    {title,
    noHeader,  
    children,
    titleOver,
    dashboardPanelToolbar,
    className,
    schema,
    createItem, 
    onFilter}) =>{

    const [fullscreenActive, setFullscreenActive] = useState(false);
    const [createModalVisible, setCreateModalVisible] = useState(false);
    const childrenArray = Array.isArray(children) ? children : [children];
    const onOpenCreateModal = () => {
        setCreateModalVisible(true);
        // return;
    }
    return(
        <section className={`fm-dashboard-panel-wrap 
                                ${className || ''} 
                                ${titleOver ? 'title-over' : ''} 
                                ${fullscreenActive ? 'fullscreen-dashboard-active' : ""}`}>
                 { !noHeader && 
                 <div className='dashboard-panel-header'>
                    { title && <h3 className="panel-title">{ title }</h3> }
                    { dashboardPanelToolbar ? 
                        <PanelTools 
                            fullscreenTable = {()=> setFullscreenActive(!fullscreenActive)}
                            fullscreenActive = {fullscreenActive}
                            openModalFunction={() => onOpenCreateModal()}
                            onFilter={(filter) => onFilter && onFilter(filter)} 
                            /> : ""} 
                            
                </div>  }
                <PerfectScrollbar className={"panel-content-wrap"} style={{...((title == null || title.length == 0 || noHeader) && { position: 'absolute', top: 0})}}>
                    { childrenArray.filter(el => {
                        
                        return true;
                    }) }
                </PerfectScrollbar>
                { createModalVisible &&
                    <FleetManagementCreateModal
                        modalVisible={createModalVisible}
                        schema={schema}//{schema}
                        onClose={() => setCreateModalVisible(false)}
                        createItem={()=>{createItem();setCreateModalVisible(false);}}//{async (id) => { await createItem(id); setCreateModalVisible(false) }}
                        title={`Create New ${schema.entityName}`}
                        entityName={schema.entityName}//{schema.entityName}
                    />
                }
        </section>
    )
}
import React from 'react';

export const FMFlightlogItem = ({title, value}) => {
    return (
        <div className='fm-flightlog-item'>
            <span className='fm-flightlog-item-name'>
                {title}:
            </span>
            <span className='fm-flightlog-item-value'>
                {value}
            </span>
        </div>
    )
}
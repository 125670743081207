import React, { createContext, useContext, useEffect, useRef, useState } from "react";
import Cookies from 'js-cookie';
import { ConfigContext } from "./config-context";
import { toast } from "react-toastify";
import axios from "axios";
import { AuthContextV2 } from "./auth-context-v2";


export const WaypointsLibraryContext = createContext();

const WaypointsLibraryContextProvider = ({ children }) => {
	const { getToken, token } = useContext(AuthContextV2);
	const [currentLevel, setCurrentLevel] = useState(false);
	const [folders, setFolders] = useState(null);
	const [isBusy, setIsBusy] = useState(false);
	const [error, setError] = useState(null);
    const [allFolders, setAllFolders] = useState(null);
    const [allWaypoints, setAllWaypoints] = useState(null);
	const [libraryVisible,setLibraryVisible] = useState(false);

	const isLoading = useRef(false);

    const { services: {
		fleetManagementService: {
			host,
			port,
			audience,
			scopes: {
				getAircraft: getAircraftScope,
			}
		}
	}} = useContext(ConfigContext);

    useEffect(() => {
		const token = getToken();
		console.log(token, allFolders, allWaypoints)
		if (token != null && allFolders == null && allWaypoints == null) {
			fetchAllFolders();
            fetchAllWaypoints();
		}
	}, [token]);
	// useEffect(() => {
	// 	fetchAllFolders();
    //     fetchAllWaypoints();
	// }, []);


    //FOLDERS

    const fetchAllFolders = async () => {
		isLoading.current = true;
		setIsBusy(true);
		const token = getToken(getAircraftScope, audience);

		const res = await axios.get(`${host}:${port}/waypoints-folder`, {
			headers: {
				Authorization: `Bearer ${token}`,
			}
		});
		
		isLoading.current = false;
		setAllFolders(res.data);
		setFolders(res.data);
		setIsBusy(false);
	}
    /**
	 * Fetch folder by id
	 * @param {*}  folderId
	 * @returns 
	 */
	const fetchSingleFolder = (id) => {
		return allFolders.find(el => el.id === id);
	}
    
    const createFolder = async (data) => {
		setIsBusy(true);
		const token = getToken(getAircraftScope, audience);

		const res = await axios.post(`${host}:${port}/waypoints-folder`, data, {
			headers: {
				Authorization: `Bearer ${token}`,
			}
		});
		
		await fetchAllFolders();
	}


    const removeFolder = async (id) => {
		setIsBusy(true);
		const token = getToken(getAircraftScope, audience);

		const res = await axios.delete(`${host}:${port}/waypoints-folder/${id}`, {
			headers: {
				Authorization: `Bearer ${token}`,
			}
		});
		
		await fetchAllFolders();
	}


	const updateFolder = async (id, data) => {
		setIsBusy(true);
		const token = getToken(getAircraftScope, audience);
        
		const res = await axios.put(`${host}:${port}/waypoints-folder/${id}`, {...{title: data}}, {
			headers: {
				Authorization: `Bearer ${token}`,
			}
		});
		
		await fetchAllFolders();
	}

    //WAYPOINTS IN FOLDERS 

    const fetchAllWaypoints = async () => {
		setIsBusy(true);
		const token = getToken(getAircraftScope, audience);

		const res = await axios.get(`${host}:${port}/waypoints`, {
			headers: {
				Authorization: `Bearer ${token}`,
			}
		});
		
		setAllWaypoints(res.data);
		setFolders(res.data);
		setIsBusy(false);
	}
    /**
	 * Fetch folder by id
	 * @param {*}  folderId
	 * @returns 
	 */
	const fetchSingleWaypoint= async(id) => {
		return allWaypoints.find(el => el.id === id);
	}
    
    const createWaypoint = async (data) => {
		setIsBusy(true);
		const token = getToken(getAircraftScope, audience);

		const res = await axios.post(`${host}:${port}/waypoints`, data, {
			headers: {
				Authorization: `Bearer ${token}`,
			}
		});
		
		await fetchAllFolders();
	}


    const removeWaypoint = async (id) => {
		setIsBusy(true);
		const token = getToken(getAircraftScope, audience);

		const res = await axios.delete(`${host}:${port}/waypoints/${id}`, {
			headers: {
				Authorization: `Bearer ${token}`,
			}
		});
		
		await fetchAllFolders();
	}


	const updateWaypoint = async (id, data) => {
		setIsBusy(true);
		const token = getToken(getAircraftScope, audience);

		const res = await axios.put(`${host}:${port}/waypoints/${id}`,  {...data, data}, {
			headers: {
				Authorization: `Bearer ${token	}`,
			}
		});
		
		await fetchAllWaypoints();
	}
    return (
		<WaypointsLibraryContext.Provider 
			value={{ 
                isBusy,
                allFolders: allFolders || [],
                allWaypoints: allWaypoints || [],
                fetchAllFolders,
                fetchSingleFolder,
                createFolder,
                removeFolder,
                updateFolder,
                fetchAllWaypoints,
                fetchSingleWaypoint,
                createWaypoint,
                removeWaypoint,
                updateWaypoint,
				currentLevel,
				setCurrentLevel,
				libraryVisible,
				setLibraryVisible
			
            }}
            >
                { children }
            </WaypointsLibraryContext.Provider>
        )
}


export default WaypointsLibraryContextProvider;
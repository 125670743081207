import { Close, Person, Visibility } from '@mui/icons-material';
import React, { useContext, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { AircraftContext } from '../../../../contexts/aircraft-context';
import { InterfaceContext } from '../../../../contexts/interface-context';
import { MapContext } from '../../../../contexts/map-context';
import { ToggleButton } from '../../../buttons/toggle-button/toggle-button';
import { CirclePanel } from '../../../containers/circle-panel/circle-panel';
import { Stack } from '../../../layout/stack/stack';


export const ToggleMapOptions = ({position, className}) => {
    const { 
        setGroundRiskPointsVisible, 
        groundRiskPointsVisible,
        markerLabelsVisible,
        setMarkerLabelsVisible,
        adsbVisible,
        setAdsbVisible,
        facilityMapsVisible,
        setFacilityMapsVisible,

        // Weather overlays
        setPrecipitationVisible,
        precipitationVisible,
        cloudsVisible, 
        setCloudsVisible
    } = useContext(MapContext);
  
    const {mapOptionsVisible, setMapOptionsVisible} = useContext(InterfaceContext);
  
    return(
        <div className={`toggle-map-options-wrap ${className && className} ${position == 'left' ? 'left' : 'right'} ${mapOptionsVisible && "active-options"}`}>
            <CirclePanel icon={<Visibility/>} title='Map Options' panelVisible={mapOptionsVisible} setPanelVisible={setMapOptionsVisible}>
                <div className='circle-panel-section-wrap'>
                    <div className='circle-panel-section-title'>
                        <span className='uppercase'>Map Indicators</span>
                    </div>
                    <ToggleButton defaultState={groundRiskPointsVisible} onChange={(newState) => setGroundRiskPointsVisible(newState)} label="Risk Points"/>
                    <ToggleButton defaultState={markerLabelsVisible} onChange={(newState) => setMarkerLabelsVisible(newState)} label="Marker Labels"/>
                </div>
                <div className='circle-panel-section-wrap'>
                    <div className='circle-panel-section-title'>
                        <span className='uppercase'>Traffic</span>
                    </div>
                    <ToggleButton defaultState={adsbVisible} onChange={(newState) => setAdsbVisible(newState)} label="ADSB"/>
                </div>
                <div className='circle-panel-section-wrap'>
                    <div className='circle-panel-section-title'>
                        <span className='uppercase'>Map overlays</span>
                    </div>
                    <ToggleButton defaultState={facilityMapsVisible} onChange={(newState) => setFacilityMapsVisible(newState)} label="Facility Maps"/>
                    <ToggleButton defaultState={precipitationVisible} onChange={(newState) => setPrecipitationVisible(newState)} label="Precipitation (mm)"/>
                    <ToggleButton defaultState={cloudsVisible} onChange={(newState) => setCloudsVisible(newState)} label="Clouds (%)"/>
                </div>
                
                
                {/* <ToggleButton label="Weather Stations"/>  */}
            </CirclePanel>
         
        </div>
    )
}
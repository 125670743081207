import React, { useState, useRef, useEffect, useContext } from "react";
import { SkyModal } from "../../../../shared-components/containers/sky-modal/sky-modal";

import {Loading} from "../../../../shared-components/layout/loading/loading";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import UtilityService from "../../../../helpers/utility-service";
import { InputField } from "../../../../shared-components/inputs/input-field/input-field";

import { FleetManagementContext } from "../../../../contexts/fleet-management-context";

export const FleetManagementCreateModal = ({title, modalVisible, onClose, schema, entityName }) => {
		const fleetManagementContext = useContext(FleetManagementContext);

		const pendingObj = useRef({});
		const [remoteSelects, setRemoteSelects] = useState(
			schema.attributes.filter(att => att.type === 'remote-select').reduce((prev, att) =>
				{
					const refId = att.refId || UtilityService.generateId();
					att.refId = refId;
					prev[refId] = {
						loadData: att.options.loadData,
						keyPath: att.options.keyPath,
						labelPath: att.options.labelPath,
						options: [],
					};

					return prev;
				}, {}));

		const [showLoader, setShowLoader] = useState(false);

		for (const att of schema.attributes) {
			if (!att.autogenerated && !att.editOnly) {
				pendingObj.current[att.key] = null;
			}
		}	

		const loadRemoteSelects = async () => {
			for (const refId of Object.keys(remoteSelects)) {
				const remoteSelect = remoteSelects[refId];
				const { loadData, keyPath, labelPath } = remoteSelect;
	
				const rawData = loadData(fleetManagementContext);
				remoteSelect.options = rawData.map(el => ({
					key: UtilityService.getFromPath(el, keyPath),
					label: labelPath(el),
				}));
			}
	
			console.log(remoteSelects);
			setRemoteSelects({...remoteSelects});
		}

		useEffect(() => {
			loadRemoteSelects();
		}, [])

	const createItem = async() => {

		try {
			setShowLoader(true);
			await schema.createSingleItem(fleetManagementContext, pendingObj.current);
			onClose();
			toast(`${entityName} Created`, {
				style: {
					backgroundColor: 'green'
				},
				autoClose: 3000,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				});
		} catch(err) {
			console.log(err);
			toast(`Failed to create ${entityName}, check your inputs`, {
				style: {
					backgroundColor: 'red'
				},
				position: "top-right",
				autoClose: 3000,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				});
		}

	}

	// render() {
	// 	const { title, modalVisible, onClose, schema, entityName } = this.props;
	// 	const { remoteSelects, showLoader } = this.state;

		return (
			 <SkyModal
			 	className={'fm-create-modal'}
				title={title}
				modalVisible={modalVisible}
				onClose={() => onClose()}
				actionButtons={[
				{
					title: "Cancel", 
					action: () => onClose(),
					btnType:"secondary",

				},
				// {
				// 	title: "Create " + this.props.entityName, 
				// 	action: ()=>this.createItem(),
				// 	btnType: "primary",
				// 	// position: 'middle'
				// },
				{
					title: "Create " + entityName, 
					action: () => createItem(),
					btnType: "primary",
					position: "right"
				}
				]}
				>
			{/* // <Modal 
			// 		className='modal-rpa-table-data-entry-wrap'
			// 		title={title}
			// 		modalVisible={modalVisible}
			// 		onCloseModal={() => onClose()}
			// >    */}
			
					<div 
					className='modal-rpa-table-data-entry '>
						{ showLoader &&
										<Loading position="absolute"/>
								}

							<div className=' modal-rpa-data-form '>
								{
									schema.attributes.filter(att => !att.autogenerated && !att.editOnly).map(att => {
										switch(att.type) {
											case 'text':
											case 'number':
											case 'datetime-local':
											case 'time':
											case 'email':
											case 'phone':
											case 'file':
												return (
													<InputField 
														className='filled-input'
														id={`${schema.entityName}-details-${att.name}`}
														placeholder={att.name}
														type={att.type}
														min={att.min}
														max={att.max}
														step={att.step || 1}
														onChange={(e) => pendingObj.current[att.key] = (att.type !== 'file' ? e.target.value : e.target.files[0])}
														alt=''
														labelText={att.name}/>
												)
												case 'select':
													return (
														<div className='details-select'>
															<label htmlFor='personnel-details-role'>{att.name}</label>
															<select 
																className='filled-input needsclick'
																placeholder={att.name}
																style={{ padding: 0, color: 'white' }}
																onChange={(e) => { e.target.value === '-' ? pendingObj.current[att.key] = null : pendingObj.current[att.key] = e.target.value }}
																>
																	<option key={null} value={null}>-</option>
																{
																		att.options.map((option) => 
																				<option key={option.key} value={option.key}>{option.label}</option>
																		)
																}
															
															</select>
														</div>
													) 
													case 'remote-select':
														return (
															<div className='details-select'>
																<label htmlFor='personnel-details-role'>{att.name}</label>
																<select 
																	className='filled-input needsclick'
																	placeholder={att.name} 
																	style={{ padding: 0, color: 'white' }}
																	onChange={(e) => { e.target.value === '-' ? pendingObj.current[att.key] = null : pendingObj.current[att.key] = e.target.value }}
																>
																	<option key={null} value={null}>-</option>
																	{
																			remoteSelects[att.refId].options.map((option) => 
																					<option key={option.key} value={option.key}>{option.label}</option>
																			)
																	}
																
															</select>
														</div>
													)
										}
									})
								}
								</div>
						
					</div>
	
			</SkyModal>
		)
	
}

import React from "react";

export function Stack({ direction, halign, valign, children, fullSize, style: customStyle, className, onClick }) {
	const calcStyle = () => {
		let flexDirection = null;
		let justifyContent = null;
		let alignItems = null;
		let height = null;
		let width = null;

		switch(direction) {
			case 'horizontal':
				flexDirection = 'row'; 
				
				switch(halign) {
					case 'start': justifyContent = 'flex-start'; break;
					case 'center': justifyContent = 'center'; break;
					case 'end': justifyContent = 'flex-end'; break;
					case 'space-between': justifyContent = 'space-between'; break;
					default: new Error(`Invalid halign value: ${halign}`);
				};

				switch(valign) {
					case 'start': alignItems = 'flex-start'; break;
					case 'center': alignItems = 'center'; break;
					case 'end': alignItems = 'flex-end'; break;
					default: new Error(`Invalid valign value: ${valign}`);
				}

				break;
			case 'vertical':
				flexDirection = 'column';

				switch(valign) {
					case 'start': justifyContent = 'flex-start'; break;
					case 'center': justifyContent = 'center'; break;
					case 'end': justifyContent = 'flex-end'; break;
					default: new Error(`Invalid valign value: ${valign}`);
				};

				switch(halign) {
					case 'start': alignItems = 'flex-start'; break;
					case 'center': alignItems = 'center'; break;
					case 'end': alignItems = 'flex-end'; break;
					case 'space-between': alignItems = 'space-between'; break;
					default: new Error(`Invalid halign value: ${halign}`);
				}

				break;

			default:
				throw new Error(`Invalid stack direction: ${direction}`);
		}

		if (fullSize) {
			height = '100%';
			width =  '100%';
		}

		return {
			flexDirection,
			justifyContent,
			alignItems,
			height,
			width,
		};
	}

	const style = calcStyle();

	return (
		<div 
			className={`stack ${className ? className : ''}`}
			style={{
				...style,
				...customStyle,
			}}
			onClick={() => onClick && onClick()}
		>
			{ children }
		</div>
	)
}
import MAVLinkConstants from '../../../protocol-services/mavlink-constants';

function getMavMissionResult(type) {
	return MAVLinkConstants.MAV_MISSION_RESULT[type];
}

// MISSION_ACK (#47)
export function missionAck (message, aircraft) {
	const {
		type,
		mission_type,
	} = message;

	const { 
		pendingMissionAcks,     // Stack of pending mission acks []]
		missionState,
	} = aircraft;

	console.log('MISSION_ACK:');
	console.log(type, mission_type);
	console.log(getMavMissionResult(type).value);

	// const { preparedMissionItems: { mission, rally } } = missionState.getState();

	if (pendingMissionAcks.length == 0) {
		console.log('Unexpected MISSION_ACK');
		return;
	}

	const statusMessage = getMavMissionResult(type).value;
	if (statusMessage == 'MAV_MISSION_INVALID_SEQUENCE') { return; }

	const { action, onAccepted, onFailed, missionType } = pendingMissionAcks.pop();


	if (statusMessage == 'MAV_MISSION_ACCEPTED') {
		onAccepted()
	} else {
		onFailed()
	}
}
import React, { useContext, useState } from 'react';
import { motion } from "framer-motion";
import { InfoCard } from '../../../../shared-components/containers/info-card/info-card';
import { InfoPanel } from '../../../../shared-components/layout/info-panel/info-panel';
import { Navigation, ArrowDropDownCircle, Warning, Check, WarningAmber } from '@mui/icons-material';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { MissionContext } from '../../../../contexts/mission-context';

export const WarningsPanel = () => {
	const [toggled, setToggled] = useState(false);
	const { missionWarnings } = useContext(MissionContext);
	const warningsList = missionWarnings; 
	// TODO: Cockpit warnings

	let titleColor = 'white';
	if (warningsList.length != 0 && warningsList.filter(w => w.type == 'warning').length != 0) {
		titleColor = 'orange';
	}
	if (warningsList.length != 0 && warningsList.filter(w => w.type == 'error').length != 0) {
		titleColor = 'red';
	}
		
		

    return(
        // <motion.div
		// 	key="warnings-panel"
		// 	initial={{ y: 150, opacity: 0 }}
		// 	animate={{ y: 0, opacity: 1 }}
		// 	exit={{ y: 150, opacity: 0 }}
		// 	transition={{ duration: 0.2, ease: "easeOut", delay: 0.3 }}
			
		// >
		<motion.div
				initial={{ opacity: 0, y: 55 }}
				animate={{ opacity: 1, y: 0 }}
				exit={{ opacity: 0, y: 55 }}
				transition={{ duration: 0.3, delay: 0.2, ease: [0.130, 0.850, 0.375, 0.960] }}
			>
			<div
				className={`warnings-panel ${toggled && 'toggled'}`}
				
			>
						<div className={`warning-icon-big ${titleColor != 'white' && `${titleColor} blink`} ${toggled && 'toggled'}`} onClick={ () => setToggled(!toggled)}>
							<WarningAmber/> 
							{toggled && <p>Warnings</p>}
						</div>
						{toggled &&
						<div
							className={`warnings-panel-content ${titleColor && titleColor}`}
							key="warnings-panel-content"
							
							// title={`Warnings ${missionWarnings.length > 0 ? `(${missionWarnings.length})`: ''}`}
							
							// actionIcons={[{icon: <ArrowDropDownCircle className="collapse-panel-icon"/>, action:}]}
						>
							
							<PerfectScrollbar>
							{ toggled &&
								<motion.div
									key="warnings-list"
									initial={{ opacity: 0, y: -10 }}
									animate={{ opacity: 1, y: 0 }}
									exit={{ opacity: 0, y: -10 }}
								>
									{
										warningsList.length > 0 ?
											warningsList.map((warning, index) => (
												<p key={`warning-${index}`} className={`uppercase bold warning-text `}><Warning className={`${warning.type == 'warning' ? 'orange' : 'red' }`}/><span>{warning.text}</span></p>
											))
										:
										<p className="uppercase bold no-warnings"><Check/> No warnings</p>
									}
                                </motion.div>
                            }
                            </PerfectScrollbar>
				</div>}
            </div>
        </motion.div>
    )
}
import { createContext, useContext, useState } from "react";
import jwt from 'jwt-decode';
import Cookies from "js-cookie";
import { ConfigContext } from "./config-context";

export const AuthContextV2 = createContext();

const AuthContextProviderV2 = ({ children }) => {
    const { logoutRoute } = useContext(ConfigContext);
    const [token, setToken] = useState(null);
    const [idToken, setIdToken] = useState(null);

    /**
     * Check if token has expired
     * @param {} decodedToken 
     * @returns 
     */
    const isExpired = (decodedToken) => {
        const { exp } = decodedToken;
        const { now } = Math.trunc(Date.now() / 1000); // Convert ms to s

        return exp < now;
    }

    /**
     * Check if token is valid
     * @param {} accessToken 
     * @returns 
     */
    const isValid = (accessToken) => {
        const decodedToken = jwt(accessToken);

        // TODO: Verify token details

        if (!(decodedToken['cognito:groups'] || []).includes('Operators')) { // Allow operators
            return false;
        }

        if (isExpired(decodedToken)) {
            return false;
            // TODO: Logout
        } else {
            return true;
        }
    }

    /**
     * Get access token
     * @returns 
     */
    const getToken = () => {
        return token;
    }

    /**
     * Logout user
     */
    const logout = () => {
        Cookies.remove('token');
        window.location.href = logoutRoute;
    }

    return (
        <AuthContextV2.Provider value={{ getToken, setToken, idToken, setIdToken, isValid, logout, token }}>
            { children }
        </AuthContextV2.Provider>
    )
}

export default AuthContextProviderV2;
import React, {useState} from 'react';
import { Stack } from '../../layout/stack/stack';

export const InfoCard = ({children, className, primaryGradient, secondaryGradient}) => {

    return (
        <div className={`info-card ${className && className}`} 
            style={{background: primaryGradient,
            background: `linear-gradient(125deg, ${primaryGradient} 0%, ${secondaryGradient} 100%)`}}
            >
            <div>
                {children}
            </div>
        </div>
    );
}
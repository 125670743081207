export const equipmentSchema = {
	entityName: 'Equipment',
	loadSingleItem: (fleetManagementContext, id) => fleetManagementContext.getSingleEquipment(id),
	updateSingleItem: async (fleetManagementContext, id, data) => await fleetManagementContext.updateEquipment(id, data),
	removeSingleItem: async (fleetManagementContext, id) => await fleetManagementContext.removeEquipment(id),
	createSingleItem: async (fleetManagementContext, data) => await fleetManagementContext.createEquipment(data),

	attributes: [
		{   
				key: 'id',
				path: 'id',
				name: 'ID',
				type: 'text',
				hidden: true,
				disabled: true,
				autogenerated: true,
		},
		{
				key: 'name',
				path: 'data.name',
				name: 'Name',
				type: 'text',
		},
		{
			key: 'category',
			path: 'data.category',
			name: 'Category',
			type: 'text',
		},
		{
			key: 'serial',
			path: 'data.serial',
			name: 'Serial Number',
			type: 'text',
		},
		{
			key: 'make',
			path: 'data.make',
			name: 'Make',
			type: 'text',
		},
		{
			key: 'model',
			path: 'data.model',
			name: 'Model',
			type: 'text',
		},
	]
};
import React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import { Article, Assignment, Backpack, BatteryFull, Book, Build, DocumentScanner, Flight, Person } from '@mui/icons-material';

const drawerWidth = 240;
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
      flexGrow: 1,
      padding: theme.spacing(3),
     
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginLeft: `-${drawerWidth}px`,
      ...(open && {
        transition: theme.transitions.create('margin', {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
      }),
    }),
  );

  const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
  })(({ theme, open }) => ({

    backgroundColor: "#00091A!important",
    borderRight: "1px solid #fefefe",
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
      
    }),
    ...(open && {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: `${drawerWidth}px`,
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  }));
  
  const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
    
  }));

export const FMSideMenu = ({children, onOpenSection, selectedTab}) => {
    const theme = useTheme();
    const [open, setOpen] = React.useState(true);
    const handleDrawerOpen = () => {
      setOpen(true);
    };
  
    const handleDrawerClose = () => {
      setOpen(false);
    };
    const fmOptions = [
        {
            name: "Aircraft",
            title: "Aircraft, Aircraft Type and Autopilot",
            icon: <Flight/>,
            clickedItem: 'aircraft'
        },
        {
            name: "Battery",
            title: "Batteries and Battery Sets",
            icon: <BatteryFull/>,
            clickedItem: 'battery'
        },
        {
            name: "Equipment",
            title: "Equipment",
            icon: <Backpack/>,
            clickedItem: 'equipment'
        },
        {
            name: "Personnel",
            title: "Personnel",
            icon: <Person/>,
            clickedItem: 'personnel'
        },
        {
            name: "Maintenance",
            title: "Maintenance",
            icon: <Build/>,
            clickedItem: 'maintenance'
        },
        {
            name: "Documents",
            title: "Documents",
            icon: <Article/>,
            clickedItem: 'documents'
        },
        {
            name: "Checklists",
            title: "Checklists",
            icon: <Assignment/>,
            clickedItem: 'checklists'
        },
        {
          name: "Flight Logs",
          title: "Flight Logs",
          icon: <Book/>,
          clickedItem: 'flightlog'
      },
        
    ]
    return (
        <div className='fm-side-menu'>
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                <AppBar position="fixed" open={open}>
                    <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        sx={{ mr: 2, ...(open && { display: 'none' }) }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" noWrap component="div">
                        <span className='uppercase'>Fleet Management</span>
                    </Typography>
                    </Toolbar>
                </AppBar>
                <Drawer
                    sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        boxSizing: 'border-box',
                    },
                    }}
                    variant="persistent"
                    anchor="left"
                    open={open}
                >
                    <DrawerHeader>
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                    </IconButton>
                    </DrawerHeader>
                    <Divider />
                    <List>
                    {fmOptions.map((option, index) => (
                        <ListItem key={option.clickedItem} disablePadding>
                        <ListItemButton onClick={() => onOpenSection(option.clickedItem, option.title)} className={`${selectedTab == option.clickedItem && 'active'}`}>
                            <ListItemIcon>
                            {option.icon}
                            </ListItemIcon>
                            <ListItemText primary={option.name} />
                        </ListItemButton>
                        </ListItem>
                        
                    ))}
                    </List>
                   
                   
                </Drawer>
              
                    <Main open={open} >
                        {/* <DrawerHeader /> */}
                        {children}
                    </Main>
             
                </Box>
        </div>
    )
}
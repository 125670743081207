import React, { useContext, useRef, useState } from "react";
import RefreshIcon from '@mui/icons-material/Refresh';
import AirplanemodeActiveIcon from '@mui/icons-material/AirplanemodeActive';
import { AirportContext } from "../../../../contexts/airport-context";
import { MapContext } from "../../../../contexts/map-context";
import UtilityService from "../../../../helpers/utility-service";
import { Tabs  }  from "../../../../shared-components/containers/tabs/tabs";   
import { InfoCard } from "../../../../shared-components/containers/info-card/info-card";
import { InfoPanel } from "../../../../shared-components/layout/info-panel/info-panel";
import TabPanel from '@mui/lab/TabPanel';
import PerfectScrollbar from 'react-perfect-scrollbar';

export const AirportInfoWidget = () => {
	const { 
		airportCode,
		isBusy, 
		hasLoaded, 
		reloadAirportData, 
		shouldUpdateAirportData, 
		lastUpdateTimestamp, 
		notam, 
		metar, 
		taf, 
		sigmet,
		error,
	} = useContext(AirportContext);
	const { mapCenter, zoom } = useContext(MapContext);

	const reloadInterval = useRef(null);
	const updatedTimeInterval = useRef(null);
	const [now, setNow] = useState(UtilityService.getCurrentTime(true));

	let tabs = [];

	if (hasLoaded) {
		tabs = [
			{
				name: "notam",
				noDataMessage: 'No active NOTAM messages',
        value: '1',
        data: notam.map(n => ({
            // from: '23/2/2022 04:30',
            // to: '22/3/2023 04:30',
            message: n.text,
        }))
			},
			{
				name: "metar",
        value: '2',
				noDataMessage: 'No active METAR messages',
        data: !metar ?  [] : [{					
					// from: '23/2/2022 04:30',
					// to: '22/3/2023 04:30',
					message: metar,
				}]
			},
			{
				name: "taf",
        value: '3',
				noDataMessage: 'No active TAF messages',
        data: !taf ? [] : [{					
					// from: '23/2/2022 04:30',
					// to: '22/3/2023 04:30',
					message: taf,
				}]
			},
			{
				name: "sigmet",
        value: '4',
				noDataMessage: 'No active  SIGMET messages',
        data: !sigmet ? [] : [{					
					// from: '23/2/2022 04:30',
					// to: '22/3/2023 04:30',
					message: sigmet,
				}]
			}
		]
	}

	let lastUpdateMinutes = 0;

	if (mapCenter != null) {
		if (zoom > 12 && shouldUpdateAirportData(mapCenter) && !isBusy) {
		
			// Initialize regular airport update
			setTimeout(() => reloadAirportData(mapCenter), 100);

			if (reloadInterval.current != null) {
				clearTimeout(reloadInterval.current);
			}
			reloadInterval.current = setInterval(() => reloadAirportData(mapCenter), 600001);
		} 
	}

	// Update age of airport report in minutes		
	lastUpdateMinutes = Math.floor((UtilityService.getCurrentTime(true)	- lastUpdateTimestamp) / 60);

	// Initialize regular report age update
	if (updatedTimeInterval.current == null) {
		updatedTimeInterval.current = setInterval(() => {
			setNow(UtilityService.getCurrentTime(true))
		}, 40000);	
	}

	return (
		<InfoCard
				primaryGradient={"#0F3B60"}
				secondaryGradient={"#0F4F7F"}
		>
				{ isBusy && hasLoaded == null? 
				<p>Loading...</p>
				:
				(
					error != null ?
					(
						<InfoPanel
							className='information-dock-panel'
							title='Airport Information'
							actionIcons={[{icon: <RefreshIcon/>, action: () => reloadAirportData(mapCenter)}]}
							// titleIcon={<Cloud/>}
							subtitles = {[
								{
									title: (
										<span>Failed to load airport data</span>
									),
								}]
							}
						/>
					)
					:
			<InfoPanel 
				className='information-dock-card airport-information'
				style={{ ...(isBusy && { opacity: 0.7 }) }}
				title='Airport Information'
				actionIcons={[{icon: <RefreshIcon/>, action: () => reloadAirportData(mapCenter)}]}
				titleIcon={<AirplanemodeActiveIcon/>}
				action={() => {}} 
				subtitles = {error ? 
							[
								{
									title: (
										<span>Failed to load weather</span>
									),
								}
							]
								:
							[
								{
									title: (<span>Closest airport <span className='bold'>{ airportCode }</span></span>), 
									size: 'medium', 
								}, 
								{
									title: (
										<span>
											Updated { lastUpdateMinutes < 1 ? 
												'less than 1 minute ' 
												: 
												(lastUpdateMinutes < 2 ? '1 minute ' 
													: `${lastUpdateMinutes} minutes `)} ago
										</span>
									), 
									size: 'small'
								}
							]
						}>
				<Tabs tabs={tabs} >
					{tabs.map((tab, index) => (
						
							<TabPanel 
							className='airport-information-tab-wrap'
							key={`tab-content-index-${index}`}
							value={tab.value}>
								<PerfectScrollbar  options={{wheelPropagation: false}}>
									{tab.data.length === 0 ? 
									<p>{ tab.noDataMessage }</p>
									:
									tab.data.map((content, index) => (
											<div className='airport-information-content-message' key={`tab-data-index-${index}`}>
													{/* <p className='uppercase light-title'>From {content.from} to {content.to}</p> */}
													
														<p className='uppercase'>{content.message}</p>
												
											</div>
									))}
								</PerfectScrollbar>
							</TabPanel>
						
					))}
				</Tabs>
			</InfoPanel>
		)}
		</InfoCard>
	)
};
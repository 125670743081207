import { combineReducers } from "redux";

// flightPaths
const flightPathsReducer = (state = null, action) => {
	switch (action.type) {
		case 'SET_FLIGHT_PATHS':
			return action.payload;
		default: return state;
	}
}

// rallyPoints
const rallyPointsReducer = (state = null, action) => {
	switch (action.type) {
		case 'SET_RALLY_POINTS':
			return action.payload;
		default: return state;
	}
}

// selectedFlightPath
const selectedFlightPathReducer = (state = null, action) => {
	switch (action.type) {
		case 'SET_SELECTED_FLIGHT_PATH':
			return action.payload;
		default: return state;
	}
}

// preparedMissionItems
const preparedMissionItemsReducer = (state = { mission: [], rally: [] }, action) => {
	switch (action.type) {
		case 'SET_PREPARED_MISSION_ITEMS':
			return action.payload;
		default: return state;
	}
}

// uploadedMissionItems
const uploadedMissionItemsReducer = (state = { mission: [], rally: []}, action) => {
	switch (action.type) {
		case 'SET_UPLOADED_MISSION_ITEMS':
			return action.payload;
		default: return state;
	}
}

const secondaryMissionReducer = (state = { mission: [], rally: []}, action) => {
	switch (action.type) {
		case 'SET_SECONDATY_MISSION_ITEMS':
			return action.payload;
		default: return state;
	}
}

// currentSeq
const currentSeqReducer = (state = 0, action) => {
	switch (action.type) {
		case 'SET_CURRENT_SEQ':
			return action.payload;
		default: return state;
	}
}

const isOrbitingReducer = (state = false, action) => {
	switch (action.type) {
		case 'SET_IS_ORBITING':
			return action.payload;
		default: return state;
	}
}

export default combineReducers({
	flightPaths: flightPathsReducer,
	rallyPoints: rallyPointsReducer,
	selectedFlightPath: selectedFlightPathReducer,
	preparedMissionItems: preparedMissionItemsReducer,
	uploadedMissionItems: uploadedMissionItemsReducer,
	currentSeq: currentSeqReducer,
	secondaryMission: secondaryMissionReducer,
	isOrbiting: isOrbitingReducer,
});

export const createSetters = (store) => ({
	setFlightPaths: (flightPaths) => store.dispatch({ type: 'SET_FLIGHT_PATHS', payload: flightPaths }),
	setRallyPoints: (rallyPoints) => store.dispatch({ type: 'SET_RALLY_POINTS', payload: rallyPoints }),
	setSelectedFlightPath: (flightPath) => store.dispatch({ type: 'SET_SELECTED_FLIGHT_PATH', payload: flightPath }),
	setUploadedMissionItems: (uploadedMissionItems) => store.dispatch({ type: 'SET_UPLOADED_MISSION_ITEMS', payload: uploadedMissionItems }),
	setPreparedMissionItems: (preparedMissionItems) => store.dispatch({ type: 'SET_PREPARED_MISSION_ITEMS', payload: preparedMissionItems }),
	setSecondaryMissionItems: (preparedMissionItems) => store.dispatch({ type: 'SET_SECONDATY_MISSION_ITEMS', payload: preparedMissionItems }),
	setCurrentSeq: (currentSeq) => store.dispatch({ type: 'SET_CURRENT_SEQ', payload: currentSeq }),
	setIsOrbiting: (isOrbiting) => store.dispatch({ type: 'SET_IS_ORBITING', payload: isOrbiting }),
});

import React, { useContext, useState } from 'react';
import UtilityService from '../../../../helpers/utility-service';
import {FleetManagementContext} from '../../../../contexts/fleet-management-context';
import { FleetManagementTableContent } from '../fleet-management-table-content/fleet-management-table-content';
import { FMDashboardPanel } from '../fm-dashboard-panel/fm-dashboard-panel';
import { documentSchema } from '../../../../contexts/schemas/document-schema';

export const FMDocumentsPanel = () => {
    const {documents} = useContext(FleetManagementContext);
	const [documentFilter, setDocumentFilter] = useState("");
	

    return(
             /* DOCUMENTS  */
        <div className="fleet-management-card-wrap">
        <FMDashboardPanel 
                className='fleet-management-card tabbed-card' 
                onFilter={(filter) => setDocumentFilter(filter)}  
                dashboardPanelToolbar={true}
                title='Documents'
                entityName={documentSchema.entityName}
                schema={documentSchema }
                createItem={() => {}}
                // {async (data) => {   
                //     await fleetManagementService.createDocument(data);
                //     documentsTableRef.current.loadData();  
                // }}
            >
                <FleetManagementTableContent    
                    // ref={documentsTableRef}
                    data={documents}
                    loadData={() => {}}//{() => fleetManagementService.fetchAllDocuments()}
                    loadSingleItem={() => {}}//{(id) => fleetManagementService.fetchSingleDocument(id)}
                    updateSingleItem={() => {}}//{(id, updateObj) => fleetManagementService.updateDocument(id, updateObj)}
                    deleteSingleItem={() => {}}//{(id) => fleetManagementService.deleteDocument(id)}
                    getTitle={(item) => item.type}
                    schema = {documentSchema}
                    filter = {documentFilter}
                    filterFunction = {(row, filter)=>UtilityService.isRowFiltered(row,filter)}
                    // calcCycleColor = {(cycles)=>calcCycleColor(cycles)}
                />  
            </FMDashboardPanel>
        </div> 
    )
}
import React, { createContext, useState, useContext, useEffect, useRef } from "react";
import axios from "axios";
import { ConfigContext } from './config-context';
import { AuthContext } from './auth-context';
import UtilityService from "../helpers/utility-service";
import { AuthContextV2 } from "./auth-context-v2";
const SerialPort = require('browser-serialport');

export const FleetManagementContext = createContext();

const FleetManagementContextProvider = ({ children }) => {
	const { getToken } = useContext(AuthContextV2);
	const { services: {
		fleetManagementService: {
			host,
			port,
			audience,
			scopes: {
				getAircraft: getAircraftScope,
				getAircraftTypes: getAircraftTypesScope,
				getAutopilots: getAutopilotsScope,
				getBatteries: getBatteriesScope,
				getBatterySets: getBatterySetsScope,
				getPersonnel: getPersonnelScope,
				getMaintenance: getMaintenanceScope,
				getDocuments: getDocumentsScope,
				getEquipment: getEquipmentScope,
				getChecklists: getChecklistsScope
			}
		}
	}} = useContext(ConfigContext);
	const allAircraft = useRef(null);
	const [allAircraftConnections, setAllAircraftConnections] = useState([]);
	const isBusyFlag = useRef(false);
	const portInterval = useRef(null);
	const serialPorts = useRef([]);

	
	

	const [aircraftTypes, setAircraftTypes] = useState(null);
	const allAircraftTypes = useRef(null);
	const isAircraftTypesBusyFlag = useRef(false);

	const [autopilots, setAutopilots] = useState(null);
	const allAutopilots = useRef(null);
	const isAutopilotsBusyFlag = useRef(false);
	// FM screen
	// Batteries
	const [batteries, setBatteries] = useState(null);
	const allBatteries = useRef(null);
	const isBatteriesBusyFlag = useRef(false);

	const fetchAllBatteries = async () => {
		isBatteriesBusyFlag.current = true;
		const token = getToken(getBatteriesScope, audience);

		const res = await axios.get(`${host}:${port}/batteries`, {
			headers: {
				Authorization: `Bearer ${token}`,
			}
		});
		
		allBatteries.current = res.data;
		setBatteries(res.data);
		isBatteriesBusyFlag.current = false;
	}

	// ---

	const getSingleBattery = (id) => {
		return allBatteries.current.find(el => el.id === id);
	}

	// ---

	const updateBattery = async (id, data) => {
		isBatteriesBusyFlag.current = true;
		const token = getToken(getBatteriesScope, audience);

		const res = await axios.put(`${host}:${port}/batteries/${id}`, data, {
			headers: {
				Authorization: `Bearer ${token}`,
			}
		});
		
		await fetchAllBatterySets();
		await fetchAllBatteries();
	}

	// ---

	const removeBattery = async (id) => {
		isBatteriesBusyFlag.current = true;
		const token = getToken(getBatteriesScope, audience);

		const res = await axios.delete(`${host}:${port}/batteries/${id}`, {
			headers: {
				Authorization: `Bearer ${token}`,
			}
		});
		
		await fetchAllBatterySets();
		await fetchAllBatteries();
	}

	// ---

	const createBattery = async (data) => {
		isBatteriesBusyFlag.current = true;
		const token = getToken(getBatteriesScope, audience);

		const res = await axios.post(`${host}:${port}/batteries`, data, {
			headers: {
				Authorization: `Bearer ${token}`,
			}
		});
		
		await fetchAllBatteries();
	}


	// Battery Sets
	const [batterySets, setBatterySets] = useState(null);
	const allBatterySets = useRef(null);
	const isBatterySetsBusyFlag = useRef(false);

	const fetchAllBatterySets = async () => {
		isBatterySetsBusyFlag.current = true;
		const token = getToken(getBatterySetsScope, audience);

		const res = await axios.get(`${host}:${port}/battery-sets`, {
			headers: {
				Authorization: `Bearer ${token}`,
			}
		});
		
		allBatterySets.current = res.data
		setBatterySets(res.data);
		isBatterySetsBusyFlag.current = false;
	}

	// ---
	
	const getSingleBatterySet = (id) => {
		console.log(batterySets);
		return allBatterySets.current.find(el => el.id === id);
	}

	// ---

	const updateBatterySet = async (id, data) => {
		isBatterySetsBusyFlag.current = true;
		const token = getToken(getBatteriesScope, audience);

		const res = await axios.put(`${host}:${port}/battery-sets/${id}`, data, {
			headers: {
				Authorization: `Bearer ${token}`,
			}
		});
		
		await fetchAllBatteries();
		await fetchAllBatterySets();
	}

	// ---

	const removeBatterySet = async (id) => {
		isBatterySetsBusyFlag.current = true;
		const token = getToken(getBatteriesScope, audience);

		const res = await axios.delete(`${host}:${port}/battery-sets/${id}`, {
			headers: {
				Authorization: `Bearer ${token}`,
			}
		});
		
		await fetchAllBatterySets();
		await fetchAllBatteries();
	}

	// ---

	const createBatterySet = async (data) => {
		isBatterySetsBusyFlag.current = true;
		const token = getToken(getBatteriesScope, audience);

		const res = await axios.post(`${host}:${port}/battery-sets`, data, {
			headers: {
				Authorization: `Bearer ${token}`,
			}
		});
		
		await fetchAllBatterySets();
	}

	// Personnel
	const [personnel, setPersonnel] = useState(null);
	const allPersonnel = useRef(null);
	const isPersonnelBusyFlag = useRef(false);

	const fetchAllPersonnel = async () => {
		isPersonnelBusyFlag.current = true;
		const token = getToken(getPersonnelScope, audience);

		const res = await axios.get(`${host}:${port}/personnel`, {
			headers: {
				Authorization: `Bearer ${token}`,
			}
		});
		
		allPersonnel.current = res.data;
		setPersonnel(res.data);
		isPersonnelBusyFlag.current = false;
	}

	// ---

	const getSinglePerson = (id) => {
		return allPersonnel.current.find(el => el.id === id);
	}

	// ---

	const updatePerson = async (id, data) => {
		isPersonnelBusyFlag.current = true;
		const token = getToken(getBatteriesScope, audience);

		const res = await axios.put(`${host}:${port}/personnel/${id}`, data, {
			headers: {
				Authorization: `Bearer ${token}`,
			}
		});
		
		await fetchAllPersonnel();
	}

	// ---

	const removePerson = async (id) => {
		isPersonnelBusyFlag.current = true;
		const token = getToken(getBatteriesScope, audience);

		const res = await axios.delete(`${host}:${port}/personnel/${id}`, {
			headers: {
				Authorization: `Bearer ${token}`,
			}
		});
		
		await fetchAllPersonnel();
	}

	// ---

	const createPerson = async (data) => {
		isPersonnelBusyFlag.current = true;
		const token = getToken(getBatteriesScope, audience);

		const res = await axios.post(`${host}:${port}/personnel`, data, {
			headers: {
				Authorization: `Bearer ${token}`,
			}
		});
		
		await fetchAllPersonnel();
	}

	// Maintenance
	const [maintenance, setMaintenance] = useState(null);
	const allMaintenance = useRef(null);
	const isMaintenanceBusyFlag = useRef(false);

	const fetchAllMaintenance = async () => {
		isMaintenanceBusyFlag.current = true;
		const token = getToken(getMaintenanceScope, audience);

		const res = await axios.get(`${host}:${port}/maintenance`, {
			headers: {
				Authorization: `Bearer ${token}`,
			}
		});
		
		allMaintenance.current = res.data;
		setMaintenance(res.data);
		isMaintenanceBusyFlag.current = false;
	}

	// ---

	const getSingleMaintenance = (id) => {
		return allMaintenance.current.find(el => el.id === id);
	}

	// ---

	const updateMaintenance = async (id, data) => {
		isMaintenanceBusyFlag.current = true;
		const token = getToken(getBatteriesScope, audience);

		const res = await axios.put(`${host}:${port}/maintenance/${id}`, data, {
			headers: {
				Authorization: `Bearer ${token}`,
			}
		});
		
		await fetchAllMaintenance();
	}

	// ---

	const removeMaintenance = async (id) => {
		isMaintenanceBusyFlag.current = true;
		const token = getToken(getBatteriesScope, audience);

		const res = await axios.delete(`${host}:${port}/maintenance/${id}`, {
			headers: {
				Authorization: `Bearer ${token}`,
			}
		});
		
		await fetchAllMaintenance();
	}

	// ---

	const createMaintenance = async (data) => {
		isMaintenanceBusyFlag.current = true;
		const token = getToken(getBatteriesScope, audience);

		const res = await axios.post(`${host}:${port}/maintenance`, data, {
			headers: {
				Authorization: `Bearer ${token}`,
			}
		});
		
		await fetchAllMaintenance();
	}

	// Documents
	const [documents, setDocuments] = useState(null);
	const allDocuments = useRef(null);
	const isDocumentsBusyFlag = useRef(false);

	const fetchAllDocuments = async () => {
		isDocumentsBusyFlag.current = true;
		const token = getToken(getDocumentsScope, audience);

		const res = await axios.get(`${host}:${port}/documents`, {
			headers: {
				Authorization: `Bearer ${token}`,
			}
		});
		
		allDocuments.current = res.data;
		setDocuments(res.data);
		isDocumentsBusyFlag.current = false;
	}

	// ---

	const getSingleDocument = (id) => {
		return allDocuments.current.find(el => el.id === id);
	}

	// ---

	const updateDocument = async (id, data) => {
		isDocumentsBusyFlag.current = true;
		const token = getToken(getBatteriesScope, audience);

		const bodyFormData = UtilityService.createFormData(data);

		const res = await axios.put(`${host}:${port}/documents/${id}`, bodyFormData, {
			headers: {
				Authorization: `Bearer ${token}`,
			}
		});
		
		await fetchAllDocuments();
	}

	// ---

	const removeDocument = async (id) => {
		isDocumentsBusyFlag.current = true;
		const token = getToken(getBatteriesScope, audience);

		const res = await axios.delete(`${host}:${port}/documents/${id}`, {
			headers: {
				Authorization: `Bearer ${token}`,
			}
		});
		
		await fetchAllDocuments();
	}

	// ---

	const createDocument = async (data) => {
		isDocumentsBusyFlag.current = true;
		const token = getToken(getBatteriesScope, audience);

		const bodyFormData = UtilityService.createFormData(data);

		const res = await axios.post(`${host}:${port}/documents`, bodyFormData, {
			headers: {
				Authorization: `Bearer ${token}`,
			}
		});
		
		await fetchAllDocuments();
	}

	// Equipment
	const [equipment, setEquipment] = useState(null);
	const isEquipmentBusyFlag = useRef(false);
	const allEquipment = useRef(null);

	const fetchAllEquipment = async () => {
		isEquipmentBusyFlag.current = true;
		const token = getToken(getEquipmentScope, audience);

		const res = await axios.get(`${host}:${port}/equipment`, {
			headers: {
				Authorization: `Bearer ${token}`,
			}
		});
		
		allEquipment.current = res.data;
		setEquipment(res.data);
		isEquipmentBusyFlag.current = false;
	}

	// ---

	const getSingleEquipment = (id) => {
		return allEquipment.current.find(el => el.id === id);
	}

	// ---

	const updateEquipment = async (id, data) => {
		isEquipmentBusyFlag.current = true;
		const token = getToken(getBatteriesScope, audience);

		const res = await axios.put(`${host}:${port}/equipment/${id}`, data, {
			headers: {
				Authorization: `Bearer ${token}`,
			}
		});
		
		await fetchAllEquipment();
	}

	// ---

	const removeEquipment = async (id) => {
		isEquipmentBusyFlag.current = true;
		const token = getToken(getBatteriesScope, audience);

		const res = await axios.delete(`${host}:${port}/equipment/${id}`, {
			headers: {
				Authorization: `Bearer ${token}`,
			}
		});
		
		await fetchAllEquipment();
	}

	// ---

	const createEquipment = async (data) => {
		isEquipmentBusyFlag.current = true;
		const token = getToken(getBatteriesScope, audience);

		const res = await axios.post(`${host}:${port}/equipment`, data, {
			headers: {
				Authorization: `Bearer ${token}`,
			}
		});
		
		await fetchAllEquipment();
	}

	// Checklist
	const [checklists, setChecklists] = useState(null);
	const allChecklists = useRef(null);
	const isChecklistsBusyFlag = useRef(false);

	const fetchAllChecklists = async () => {
		isChecklistsBusyFlag.current = true;
		const token = getToken(getChecklistsScope, audience);

		const res = await axios.get(`${host}:${port}/checklists`, {
			headers: {
				Authorization: `Bearer ${token}`,
			}
		});
		
		allChecklists.current = res.data;
		setChecklists(res.data);
		isChecklistsBusyFlag.current = false;
	}

	// ---

	const getSingleChecklist = (id) => {
		return allChecklists.current.find(el => el.id === id);
	}

	// ---

	const updateChecklist = async (id, data) => {
		isChecklistsBusyFlag.current = true;
		const token = getToken(getChecklistsScope, audience);

		const res = await axios.put(`${host}:${port}/checklists/${id}`, data, {
			headers: {
				Authorization: `Bearer ${token}`,
			}
		});
		
	await fetchAllChecklists();
	}

	// ---

	const removeChecklist = async (id) => {
		isChecklistsBusyFlag.current = true;
		const token = getToken(getChecklistsScope, audience);

		const res = await axios.delete(`${host}:${port}/checklists/${id}`, {
			headers: {
				Authorization: `Bearer ${token}`,
			}
		});
		
		await fetchAllChecklists();
	}

		// ---

		const createChecklist = async (data) => {
			isChecklistsBusyFlag.current = true;
			const token = getToken(getChecklistsScope, audience);
	
			const res = await axios.post(`${host}:${port}/checklists`, data, {
				headers: {
					Authorization: `Bearer ${token}`,
				}
			});
			
			await fetchAllChecklists();
		}
		//Aircraft Type
		const fetchAllAircraftTypes = async () => {
			isAircraftTypesBusyFlag.current = true;
			const token = getToken(getAircraftTypesScope, audience);
	
			const res = await axios.get(`${host}:${port}/aircraft-types`, {
				headers: {
					Authorization: `Bearer ${token}`,
				}
			});
			allAircraftTypes.current = res.data;
			setAircraftTypes(res.data);
			isAircraftTypesBusyFlag.current = false;
		}
	
		// ---
	
		const getSingleAircraftType = (id) => {
			return allAircraftTypes.current.find(el => el.id === id);
		}
	
		// ---
	
		const updateAircraftType = async (id, data) => {
			allAircraftTypes.current = true;
			const token = getToken(getAircraftTypesScope, audience);
	
			const res = await axios.put(`${host}:${port}/aircraft-types/${id}`, data, {
				headers: {
					Authorization: `Bearer ${token}`,
				}
			});
			
			await fetchAllAircraftTypes();
		}
	
		// ---
	
		const removeAircraftType = async (id) => {
			allAircraftTypes.current = true;
			const token = getToken(getAircraftTypesScope, audience);
	
			const res = await axios.delete(`${host}:${port}/aircraft-types/${id}`, {
				headers: {
					Authorization: `Bearer ${token}`,
				}
			});
			
			await fetchAllAircraftTypes();
		}
	
		// ---
	
		const createAircraftType = async (data) => {
			allAircraftTypes.current = true;
			const token = getToken(getAircraftTypesScope, audience);
			const res = await axios.post(`${host}:${port}/aircraft-types`, data, {
				headers: {
					Authorization: `Bearer ${token}`,
				}
			});
			
			await fetchAllAircraftTypes();
		}
		//Autopilot
		const fetchAllAutopilots = async () => {
			isAutopilotsBusyFlag.current = true;
			const token = getToken(getAutopilotsScope, audience);
	
			const res = await axios.get(`${host}:${port}/autopilots`, {
				headers: {
					Authorization: `Bearer ${token}`,
				}
			});
			allAutopilots.current = res.data;
			setAutopilots(res.data);
			isAutopilotsBusyFlag.current = false;
		}
	
		// ---
	
		const getSingleAutopilot = (id) => {
			return allAutopilots.current.find(el => el.id === id);
		}
	
		// ---
	
		const updateAutopilot = async (id, data) => {
			isAutopilotsBusyFlag.current = true;
			const token = getToken(getAutopilotsScope, audience);
	
			const res = await axios.put(`${host}:${port}/autopilots/${id}`, data, {
				headers: {
					Authorization: `Bearer ${token}`,
				}
			});
			
			await fetchAllAutopilots();
		}
	
		// ---
	
		const removeAutopilot = async (id) => {
			isAutopilotsBusyFlag.current = true;
			const token = getToken(getAutopilotsScope, audience);
	
			const res = await axios.delete(`${host}:${port}/autopilots/${id}`, {
				headers: {
					Authorization: `Bearer ${token}`,
				}
			});
			
			await fetchAllAutopilots();
		}
	
		// ---
	
		const createAutopilot = async (data) => {
			isAutopilotsBusyFlag.current = true;
			const token = getToken(getAutopilotsScope, audience);
			console.log(token);
	
			const bodyFormData = UtilityService.createFormData(data);
	
			const res = await axios.post(`${host}:${port}/autopilots`, bodyFormData, {
				headers: {
					Authorization: `Bearer ${token}`,
				}
			});
			
			await fetchAllAutopilots();
		}
		
	//Aircraft
	const fetchAllAircraft = async () => {
		isBusyFlag.current = true;
		const token = getToken(getAircraftScope, audience);

		const res = await axios.get(`${host}:${port}/aircraft`, {
			headers: {
				Authorization: `Bearer ${token}`,
			}
		});
		allAircraft.current = res.data;
		setAllAircraftConnections([...res.data.map(ac => ({ key: ac.data.key, id: ac.id, name: ac.data.name, connectionType: 'LTE'})), ...serialPorts.current]);
		isBusyFlag.current = false;
	}

	// ---

	const getSingleAircraft = (id) => {
		return allAircraft.current.find(el => el.id === id);
	}

	// ---

	const updateAircraft = async (id, data) => {
		isBusyFlag.current = true;
		const token = getToken(getBatteriesScope, audience);

		const bodyFormData = UtilityService.createFormData(data);

		const res = await axios.put(`${host}:${port}/aircraft/${id}`, bodyFormData, {
			headers: {
				Authorization: `Bearer ${token}`,
			}
		});
		
		await fetchAllAircraft();
	}

	// ---

	const removeAircraft = async (id) => {
		isBusyFlag.current = true;
		const token = getToken(getBatteriesScope, audience);

		const res = await axios.delete(`${host}:${port}/aircraft/${id}`, {
			headers: {
				Authorization: `Bearer ${token}`,
			}
		});
		
		await fetchAllAircraft();
	}

	// ---

	const createAircraft = async (data) => {
		isBusyFlag.current = true;
		const token = getToken(getBatteriesScope, audience);

		const bodyFormData = UtilityService.createFormData(data);

		const res = await axios.post(`${host}:${port}/aircraft`, bodyFormData, {
			headers: {
				Authorization: `Bearer ${token}`,
			}
		});
		
		await fetchAllAircraft();
	}

	useEffect(() => {
		const token = getToken(getAircraftScope, audience);
		if (token != null && isBusyFlag.current == false && allAircraft.current == null) {
			fetchAllAircraft();

			if (portInterval.current != null) {
				clearInterval(portInterval.current)
			}
			
		// 	portInterval.current = setInterval(() => {
    //     SerialPort.list((ports) => {
		// 			console.log(ports);
		// 		const processedPorts = ports.map(port => ({ key: port.path, name: port.path.split('/').slice(-1)[0], id: port.path, connectionType: 'SERIAL' }));
    //         if (JSON.stringify(serialPorts.current) != JSON.stringify(processedPorts)) {
    //             serialPorts.current = processedPorts;
    //             setAllAircraftConnections([... (allAircraft == null ? [] : allAircraft.current.map(ac => ({ key: ac.data.key, id: ac.id, name: ac.data.name, connectionType: 'LTE'}))), ...processedPorts]);
    //         }
    //     });
    // }, 1000);
		}

		const batteryToken = getToken(getBatteriesScope, audience);
		if (batteryToken != null && isBatteriesBusyFlag.current == false && batteries == null) {
			fetchAllBatteries()
		}

		const batterySetsToken = getToken(getBatterySetsScope, audience);
		if (batterySetsToken != null && isBatterySetsBusyFlag.current == false && batterySets == null) {
			fetchAllBatterySets()
		}

		const personnelToken = getToken(getPersonnelScope, audience);
		if (personnelToken != null && isPersonnelBusyFlag.current == false && personnel == null) {
			fetchAllPersonnel()
		}

		const maintenanceToken = getToken(getMaintenanceScope, audience);
		if (maintenanceToken != null && isMaintenanceBusyFlag.current == false && maintenance == null) {
			fetchAllMaintenance()
		}

		const documentsToken = getToken(getDocumentsScope, audience);
		if (documentsToken != null && isDocumentsBusyFlag.current == false && documents == null) {
			fetchAllDocuments()
		}

		const equipmentToken = getToken(getEquipmentScope, audience);
		if (equipmentToken != null && isEquipmentBusyFlag.current == false && equipment == null) {
			fetchAllEquipment()
		}

		const checklistsToken = getToken(getChecklistsScope, audience);
		if (checklistsToken != null && isChecklistsBusyFlag.current == false && checklists == null) {
			fetchAllChecklists()
		}

		const aircraftTypesToken = getToken(getAircraftTypesScope, audience);
		if (aircraftTypesToken != null && isAircraftTypesBusyFlag.current == false && aircraftTypes == null) {
			fetchAllAircraftTypes()
		}

		const autopilotsToken = getToken(getAutopilotsScope, audience);
		if (autopilotsToken != null && isAutopilotsBusyFlag.current == false && autopilots == null) {
			fetchAllAutopilots()
		}
	})

	return (
		<FleetManagementContext.Provider 
			value={{ 
				allAircraft: allAircraft.current, 
				allAircraftConnections,
				aircraftTypes: allAircraftTypes.current,
				batteries: allBatteries.current,
				batterySets: allBatterySets.current,
				personnel: allPersonnel.current,
				documents: allDocuments.current,
				maintenance: allMaintenance.current,
				equipment: allEquipment.current,
				checklists: allChecklists.current,
				autopilots: allAutopilots.current,
				fetchAllBatterySets,
				fetchAllBatteries,
				fetchAllAircraft,
				
				getSingleBattery,
				getSingleBatterySet,
				getSingleMaintenance,
				getSingleDocument,
				getSinglePerson,
				getSingleEquipment,
				getSingleChecklist,
				getSingleAircraft,
				getSingleAircraftType,
				getSingleAutopilot,

				updateBattery,
				updateBatterySet,
				updatePerson,
				updateDocument,
				updateEquipment,
				updateMaintenance,
				updateAircraft,				
				updateChecklist,
				updateAircraftType,
				updateAutopilot,

				removeBattery,
				removeBatterySet,
				removePerson,
				removeChecklist,
				removeDocument,
				removeEquipment,
				removeMaintenance,
				removeAircraft,
				removeAircraftType,
				removeAutopilot,

				createBattery,
				createBatterySet,
				createPerson,
				createChecklist,
				createDocument,
				createEquipment,
				createMaintenance,
				createAircraft,
				createAircraftType,
				createAutopilot
			}}
		>
			{ children }
		</FleetManagementContext.Provider>
	)
};

export default FleetManagementContextProvider;
import axios from "axios";
import React, { createContext, useContext, useEffect, useRef, useState } from "react";
import { AuthContext } from "./auth-context";
import { AuthContextV2 } from "./auth-context-v2";
import { ConfigContext } from "./config-context";

export const FlightContext = createContext();

const FlightContextProvider = ({ children }) => {
	const { getToken } = useContext(AuthContextV2);
	const { 
		services: { 
			flightService: { 
				host, 
				port, 
				audience, 
				scopes: {
					getAllFlights: getAllFlightsScope
				}
			}
		}
	} = useContext(ConfigContext);

	const loadingFlag = useRef(false);

	const [flights, setflights] = useState(null);
	const [isBusy, setIsBusy] = useState(false);
	const [error, setError] = useState(null);
	const [pendingFlights, setPendingFlights] = useState(null);
	const [hasPendingFlightsLoaded, setHasPendingFlightsLoaded] = useState(false);
	const scheduleFlightData = useRef({});
	/**
	 * Fetch flights by aircraft/status/operator
	 * @param {*} aircraftId 
	 * @param {*} status 
	 * @param {*} operatorId 
	 * @returns 
	 */
	const fetchFlights = (aircraftId=null, status=null, operatorId=null) =>  {
		return new Promise((resolve, reject) => {
			const token = getToken(getAllFlightsScope, audience);
			
			if (token == null) {
				setError('Unauthorized');
				return;
			}

			console.log(token)
			
			axios.get(`${host}:${port}/flights`, {
				params: {
					...(aircraftId && { aircraftId }),
					...(status && { status }),
					...(operatorId && { operatorId }),
				},
				headers: {
					Authorization: `Bearer ${token}`,
				}
			}).then(response => {
				// TODO: Create connection for each flight
				setflights(response.data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)));
				resolve(response.data);
				
			}).catch(err => reject(err.message));
		});
	}

	const updateFlight = (flightId, status) =>  {
		return new Promise((resolve, reject) => {
			const token = getToken(getAllFlightsScope, audience);


			if (token == null) {
				setError('Unauthorized');
				return;
			}
			
			axios.put(`${host}:${port}/flights/${flightId}`, {
					status,
				},
				{
				headers: {
					Authorization: `Bearer ${token}`,
				}
			}).then(() => {
				fetchFlights();
				
			}).catch(err => reject(err.message));
		});
	}

	const loadPendingFlights = (operatorId) => {
		if (isBusy || loadingFlag.current) { return }

		loadingFlag.current = true;
		setIsBusy(true);

		fetchFlights(null, 'PENDING', operatorId)
			.then(pendingFlights => {
				console.log(pendingFlights);
				setPendingFlights(pendingFlights);
				setHasPendingFlightsLoaded(true);
			})
			.catch(err => { console.log(err) ; setError(err)  })
			.finally(() => setIsBusy(false));
	}

	const scheduleFlight = () => {
		return new Promise((resolve, reject) => {
			const token = getToken(getAllFlightsScope, audience);

			if (token == null) {
				setError('Unauthorized');
				reject('Unauthorized');
			}
			
			axios.post(`${host}:${port}/flights`, 
				{
					...scheduleFlightData.current,
					missionPlan: scheduleFlightData.current.missionPlan.data,
					flightPath: scheduleFlightData.current.flightPath,
					riskEstimations: [],
					notes: [],
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
				}
			}).then(response => {
				fetchFlights();
				resolve(response.data);
				
			}).catch(err => reject(err.message));
		});
	}

	useEffect(() => {
		const token = getToken(getAllFlightsScope, audience);

		if (token != null && flights == null) {
			setflights([]);
			fetchFlights(null, null, null)
		}
	});

	return (
		<FlightContext.Provider value={{
				flights: flights || [],
				isBusy,
				hasPendingFlightsLoaded,
				loadPendingFlights,
				scheduleFlightData,
				scheduleFlight,
				updateFlight
			}}
		>
			{ children }
		</FlightContext.Provider>
	)
}

export default FlightContextProvider;
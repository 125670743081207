
import { BarChart, Circle, KeyboardArrowDown, Warning } from '@mui/icons-material';
import React, {useState, useContext, useRef} from 'react';
import { toast } from 'react-toastify';
import { FleetManagementContext } from '../../../../contexts/fleet-management-context';
import { MissionContext } from '../../../../contexts/mission-context';
import { RiskAssessmentContext } from '../../../../contexts/risk-assessment-context';
import { LabelIconButton } from '../../../../shared-components/buttons/label-icon-button/label-icon-button';
import { SkyModal } from '../../../../shared-components/containers/sky-modal/sky-modal';
import { InputField } from '../../../../shared-components/inputs/input-field/input-field';
import { SelectInput } from '../../../../shared-components/inputs/select-input/select-input';
import { Loading } from '../../../../shared-components/layout/loading/loading';
import {Bolt} from '@mui/icons-material/';
import { RiskAssessmentGround } from './components/risk-assessment-ground/risk-assessment-ground';
import { RiskAssessmentAir } from './components/risk-assessment-air/risk-assessment-air';

export const RiskAssessmentModal = ({visible, title, onClose}) => {
    const [selectedTab, setSelectedTab] = useState('1');
    const { groundRiskScore } = useContext(RiskAssessmentContext);
    return(
        <SkyModal
        className='risk-assessment-modal'
        modalVisible={true}
        onClose={() => onClose()}
        disableScrollbar={true}
        modalIcon={<Bolt/>}
        modalCloseIcon={<KeyboardArrowDown/>}
        title="Risk Assessment"
        modalStatusIndicator={
            groundRiskScore == null ?
                {status: 'neutral', value: 'Not Evaluated'}
            :
                groundRiskScore.totalScore < 1e-6 ?
                    {status: 'good', value: 'Acceptable'}
                :
                    {status: 'bad', value: 'Not Acceptable'}
        }

    >
        <div className='risk-assessment-modal-content'>
            <div className='risk-assessment-tabs'>
                <div onClick={() => {console.log(selectedTab); setSelectedTab('1'); console.log(selectedTab);}}>
                    <span className={`uppercase ${selectedTab == '1' && 'selected'}`}>Ground Risk</span>
                </div>
                <div onClick={() => setSelectedTab('2')}>
                    <span className={`uppercase ${selectedTab == '2' && 'selected'}`}>Air Risk</span>
                </div>
            </div>
            <div className={`risk-assessment-selected-tab-content`}>
                {selectedTab == '1' && <RiskAssessmentGround/>}
                {selectedTab == '2' && <RiskAssessmentAir/>}
            </div>
        </div>
    </SkyModal>
        // <div className='risk-assessment-modal-wrap'>
        //     <div className='risk-assessment-modal'>
        //         <div className='risk-assessment-modal-header'>
        //             <div className='risk-assessment-modal-header-left'>
        //                 <Bolt className='blue'/> 
        //                 <p className='risk-assessment-title uppercase bold'>Risk Assessment</p>
        //                 <div className='risk-status'>
        //                     <p className={`uppercase`}> Acceptable </p>
        //                     <Circle sx={{width: "15px", marginLeft: "10px"}}/>
        //                 </div>
        //             </div>
        //             <KeyboardArrowDown className='blue risk-assessment-modal-close' onClick={() => onClose()}/>
        //         </div>
        //         <div className='risk-assessment-modal-content'>
        //             <div className='risk-assessment-tabs'>
        //                 <div onClick={() => {console.log(selectedTab); setSelectedTab('1'); console.log(selectedTab);}}>
        //                     <span className={`uppercase ${selectedTab == '1' && 'selected'}`}>Ground Risk</span>
        //                 </div>
        //                 <div onClick={() => setSelectedTab('2')}>
        //                     <span className={`uppercase ${selectedTab == '2' && 'selected'}`}>Air Risk</span>
        //                 </div>
        //             </div>
        //             <div className={`risk-assessment-selected-tab-content`}>
        //                 {selectedTab == '1' && <RiskAssessmentGround/>}
        //                 {selectedTab == '2' && <RiskAssessmentAir/>}
        //             </div>
                    
        //         </div>
        //     </div>
        // </div>
    )
}
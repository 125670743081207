// import { combineReducers } from "redux";

// latitude
const confirmationReducer = (
	state = { 
		latitude: null, 
		longitude: null, 
		altitude: null, 
		relativeAltitude: null, 
		heading: null,
		path: [],
	}, action) => {
	switch (action.type) {
		case 'SET_WAITING_COMMAND':
			return action.payload;
		default: return state;
	}
}

export default confirmationReducer;

export const createSetters = (store) => ({
		setWaitingCommand: (waitingCommand) => store.dispatch({ type: 'SET_WAITING_COMMAND', payload: waitingCommand }),
});

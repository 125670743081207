import React, { createRef, useContext, useEffect, useRef, useState } from "react";
import { AircraftContext } from "../../../../contexts/aircraft-context";
import { TerrainContext } from "../../../../contexts/terrain-context";
import icons from "../../../../helpers/icons";
import UtilityService from "../../../../helpers/utility-service";

export const TerrainAwarenessWidget = () => {
	const canvasRef = useRef(null);
	const { selectedAircraft } = useContext(AircraftContext);
	const { getCarpetData } = useContext(TerrainContext);

	const currentAircraft = useRef(null);
	const centerPosition = useRef(null);
	const loadedData = useRef(false);
	const subscriptions = useRef([]);

	const aircraftHeading = useRef(0);
	const aircraftMarkerRef = createRef();

	useEffect(() => {
		if (currentAircraft.current != selectedAircraft) {
			currentAircraft.current = selectedAircraft;

			// if (currentAircraft.current != null) {
			for (const sub of subscriptions.current) {
				sub();
				subscriptions.current = [];
			}
			// }

			if (selectedAircraft != null) {
				const { latitude, longitude, altitude, heading } = selectedAircraft.positionState.getState();
				if (parseInt(heading) != parseInt(aircraftHeading.current)) {
					aircraftHeading.current = parseInt(heading || 0);
				}

				if (canvasRef.current != null && latitude != null && longitude != null && altitude != null) {
					centerPosition.current = { latitude, longitude, altitude };
					const ctx = canvasRef.current.getContext('2d');
					console.log(latitude, longitude, altitude);
					drawImage(ctx);
				}

				subscriptions.current.push(selectedAircraft.positionState.subscribe(() => {
					const { latitude, longitude, altitude, heading } = selectedAircraft.positionState.getState();

					if (parseInt(heading) != parseInt(aircraftHeading.current)) {
						aircraftHeading.current = parseInt(heading || 0);
						if (aircraftMarkerRef.current) {
							aircraftMarkerRef.current.style.transform = `rotate(${aircraftHeading.current}deg)`;
						}
					}

					if (latitude != null && longitude != null) {
						if (centerPosition.current == null || UtilityService.distance(centerPosition.current, { latitude, longitude}) > 5 ) {

							if (canvasRef.current != null) {
								centerPosition.current = { latitude, longitude, altitude };
								const ctx = canvasRef.current.getContext('2d');
								drawImage(ctx);
							}
						} else if (centerPosition != null && Math.abs(altitude - centerPosition.current.altitude) > 1) {
							if (canvasRef.current != null) {
								centerPosition.current.altitude = altitude;
								const ctx = canvasRef.current.getContext('2d');
								drawImage(ctx, false);
							}
						}
					}
				}))
			}
		}
	}, [selectedAircraft])

	const drawImage = async (ctx, fetch=true) => {
		if (fetch) {
			loadedData.current = await getCarpetData(centerPosition.current);
		}
		const data = loadedData.current;
		// console.log(data);

		const processedData = data.map(row => row.map(el => 
			el < centerPosition.current.altitude - 50 ? 0 
			: 
			el < centerPosition.current.altitude - 30 ? 1 
			: 
			el < centerPosition.current.altitude - 10 ? 2 
			: 
			el < centerPosition.current.altitude ? 3
			:
			4
			))
		.reduce((prevRow, currRow) => {
			const parsedRow = currRow.reduce((prevEl, currEl) => {
				return prevEl.concat(
					currEl == 4 ?
						[255, 0, 0, 255]
						:
					currEl == 3 ?
						[255, 100, 0, 255]
						:
					currEl == 2 ? 
						[255, 255, 0, 255]
						:
					currEl == 1 ? 
						[0, 37, 38, 255]
						:
						[0, 0, 0, 0]
				)
			}, [])

			return prevRow.concat(parsedRow);
		}, []);

		ctx.imageSmoothingEnabled = true;

		// console.log(data.length, data[0].length);
		const imgData = ctx.createImageData(data[0].length, data.length) //ctx.createImageData(data.length, data[0].length);
		for (let i = 0; i < processedData.length;  i += 1) {
			imgData.data[i] = processedData[i];
		}

		// console.log(imgData);
		// ctx.putImageData(imgData, 0, 0, 0, 0, 1000, 1000);
		// ctx.fillStyle = '#000000'
		// ctx.fillRect(0, 0, 1000, 1000)

		ctx.clearRect(0, 0, 190, 190);

		createImageBitmap(imgData).then((imgBitmap) => {
			ctx.drawImage(imgBitmap, 0, 0, 190, 190);
		});
	
	}

	return (
		<div className="terrain-awareness-widget">
			<canvas key={'terrain-canvas'} width="1000" height="1000" className="terrain-awareness-widget-canvas" ref={canvasRef}/>
			<img ref={aircraftMarkerRef} style={{ transform: `rotate(${aircraftHeading.current}deg)` }} src={icons.regular.aircraftMarkerIcon} className="aircraft-indicator"/>
		</div>
	)
}
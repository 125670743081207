import { Add } from '@mui/icons-material';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { TerrainPanel } from "../mission-planning-screen/components/terrain-panel/terrain-panel";
import { AircraftContext } from '../../contexts/aircraft-context';
import { OutlineButton } from '../../shared-components/buttons/outline-button/outline-button';
import { AddFlightPanel } from './components/add-flight-panel/add-flight-panel';
import { SecondaryDisplayFlightItem } from './components/secondary-display-flight-item/secondary-display-flight-item';
import { TelemetryPanel } from './components/telemetry-panel/telemetry-panel';
import { motion } from 'framer-motion';
import { FlightPathProfile } from './components/flight-path-profile/flight-path-profile';
import { TerrainChart } from '../../shared-components/charts/terrain-chart/terrain-chart';
import { MissionContext } from '../../contexts/mission-context';
import { TerrainContext } from '../../contexts/terrain-context';
import { CockpitContext } from '../../contexts/cockpit-context';
import { HeadingCompass } from '../../shared-components/heading-compass/heading-compass';
import { SlideConfirmation } from '../../shared-components/layout/slide-confirmation/slide-confirmation';
import { MapContext } from '../../contexts/map-context';

export const CockpitScreenV2 = () => {
    const [toggleButtons, setToggleButtons] = useState(false);
    const [addFlightPanelActive, setAddFlightPanelActive] = useState(false);
    const [telemetryPanelActive, setTelemetryPanelActive] = useState(false);
    const [flightControlState, setFlightControlState] = useState('not-selected');
    // const [aircraftStatusInformation, setAircraftStatusInformation] = useState('not-active');
    const { selectedPath, setMissionPlanningAircraft } = useContext(CockpitContext);
    const { moveToLocation } = useContext(MapContext);
    const [selectedMission, setSelectedMission] = useState(false);
    const { connectedAircraft, connectToAircraft, selectedAircraft, setSelectedAircraft } = useContext(AircraftContext);
    const { isBusy, createMultipleTerrainPaths } = useContext(TerrainContext);
    const [waitingConfirmation, setWaitingConfirmation] = useState(null);

    const [generalState, setGeneralState] = useState({});

    const currentAircraft = useRef(null);
    const subscriptions = useRef([])

    const terrainLoaded = useRef(false);

    useEffect(() => {
        if (!terrainLoaded.current && selectedPath != null) {
            terrainLoaded.current = true;
            createMultipleTerrainPaths([selectedPath]);
        }
    }, [selectedPath]);

    useEffect(() => {
        subscribe();
    }, [selectedAircraft])

    const subscribe = () => {
        if (selectedAircraft != currentAircraft.current) {
            if (currentAircraft.current != null) {
                for (const sub of subscriptions.current) {
                    sub();
                    subscriptions.current = [];
                }
            }

            currentAircraft.current = selectedAircraft;

            if (selectedAircraft != null) {
                setWaitingConfirmation(selectedAircraft.confirmationState.getState());
                setGeneralState(selectedAircraft.generalState.getState());

                subscriptions.current.push(selectedAircraft.confirmationState.subscribe(() => {
                    setWaitingConfirmation(selectedAircraft.confirmationState.getState());
                }))

                subscriptions.current.push(selectedAircraft.generalState.subscribe(() => {
                    setGeneralState(selectedAircraft.generalState.getState());
                }))
            }
        }
    }

    if (currentAircraft.current != selectedAircraft) {
        subscribe();
    }

    const onDisconnect = () => {
        selectedAircraft.disconnect();
        // setSelectedAircraft(null);
    }

    const selectAircraft = (ac) => {
        setSelectedAircraft(ac);
        moveToLocation(
            ac.positionState.getState() != null && ac.positionState.getState()
        );
    }

    const onAddNewFlight = () => {
        // setSelectedAircraft(null);
        setTelemetryPanelActive(false);
        setAddFlightPanelActive(true);
    }
    const onCloseAddFlight = () => {
        setAddFlightPanelActive(false);
    }
    const onConfirmMissionPlan = (missionData) => {
        const { aircraft: { data: { key, name } } } = missionData;
        setMissionPlanningAircraft(null);
        // console.log(selectedPath);

        const selectedMission = {
            title: selectedPath.mission,
            rtlPoints: selectedPath.rtlPoints || [],
            flightPaths: [{
                primary: true,
                title: selectedPath.title,
                waypoints: selectedPath.waypoints,
            }]
        };

        connectToAircraft(key, name, 'LTE', null, null, false, selectedMission);
        setAddFlightPanelActive(false);
        setTelemetryPanelActive(true);
        // setFlightControlState('connect');
        // setAircraftStatusInformation("not-connected");
    }
    const onConnect = () => {
        selectedAircraft.connect();
        // setFlightControlState('start');
        // setAircraftStatusInformation("connected");
    }

    const uploadMission = () => {
        selectedAircraft.uploadMissionPlan();
    }

    const startMission = () => {
        selectedAircraft.startMission();
    }

    const returnToLaunch = () => {
        selectedAircraft.setFlightMode('RTL', true);
    }

    return (
        <div className='cockpit-screen-v2' key="cockpit-v2">

            {/* <TerrainPanel/> */}
            {/* <FlightPathProfile/> */}

            {selectedPath && <TerrainChart currentPath={selectedPath} />}


            <motion.div
                key="cockpit-screen-secondary-display"
                className={"cockpit-screen-secondary-display"}
                initial={{ x: 300, zIndex: 10000 }}
                exit={{ x: 300, zIndex: 10000 }}
                animate={{ x: 0, zIndex: 10000 }}
                transition={{ duration: 0.4, ease: [0.130, 0.850, 0.375, 0.960] }}
            >

                <SlideConfirmation
                    key={`slide-confirmation-element-${selectedAircraft ? selectedAircraft.aircraftId : ''}`}
                    visible={waitingConfirmation != null && waitingConfirmation.caption != null}
                    caption={waitingConfirmation ? waitingConfirmation.caption : null}
                    values={waitingConfirmation ? waitingConfirmation.values : null}
                    onCancel={() => {
                        if (waitingConfirmation) {
                            selectedAircraft.confirmationStateSetters.setWaitingCommand(null);
                            if (waitingConfirmation.onAbort) { waitingConfirmation.onAbort() }
                        }
                    }}
                    onConfirm={(values) => {
                        if (waitingConfirmation && waitingConfirmation.command) {
                            waitingConfirmation.command(values);
                        }
                        selectedAircraft.confirmationStateSetters.setWaitingCommand(null);
                    }}
                />


                <div className='secondary-display-active-flights secondary-display-section'>
                    <div className='secondary-display-section-header'>
                        <span className='title'>Active flights</span>
                        <div className='full-color-button yellow' onClick={() => onAddNewFlight(true)}><Add /> Add flight</div>
                    </div>
                    <div>
                        {/* <SecondaryDisplayFlightItem
                            flight={{
                                aircraftName: "SKYY0303-22",
                                aircraftStatus: '<PREFLIGHT>',
                                altitude: "123.0",
                                speed: "0.0",
                                timeToGo: "00:01:02"
                            }
                            }
                        /> */}
                        {connectedAircraft.map(ac => (
                            <SecondaryDisplayFlightItem onClick={() => selectAircraft(ac)} flight={ac} isSelected={selectedAircraft == ac} />
                        ))
                        }

                        {(new Array(5 - connectedAircraft.length).fill(0)).map(el => (
                            <SecondaryDisplayFlightItem />
                        )
                        )}
                    </div>
                </div>
                <div className='secondary-display-section secondary-display-middle-section'>

                    {addFlightPanelActive &&
                        <AddFlightPanel
                            onEndMissionPlanning={(missionData) => onConfirmMissionPlan(missionData)}
                            onClose={() => setAddFlightPanelActive(false)}
                        />
                    }
                    {selectedAircraft != null && <TelemetryPanel selectedAircraft={selectedAircraft} />}
                </div>
                {/* {console.log(selectedAircraft)} */}
                <div className='secondary-display-flight-control secondary-display-section'>
                    <div className='secondary-display-section-header'>
                        <span className='title'>Flight Control</span>
                        {selectedAircraft == null && <p className='not-selected'>Flight not selected</p>}

                        {/* <div className='full-color-button lime' onClick={() => setToggleButtons(true)}>Start Mission</div> */}

                        <div className='flight-control-buttons'>
                            {
                                selectedAircraft != null && !generalState.isConnected &&
                                <div className='full-color-button lime' onClick={() => onConnect()}>Connect</div>
                            }
                            {
                                selectedAircraft != null && (generalState.isConnected && !generalState.isMissionInProgress && !generalState.isMissionUploaded) &&
                                <div className='full-color-button lime' onClick={() => uploadMission()}>Upload Mission</div>
                            }
                            {
                                selectedAircraft != null && (generalState.isConnected && !generalState.isMissionInProgress && generalState.isMissionUploaded) &&
                                <div className='full-color-button lime' onClick={() => startMission()}>Start Mission</div>
                            }
                            {
                                selectedAircraft != null && (generalState.isConnected && generalState.isMissionInProgress && generalState.isMissionUploaded && generalState.systemStatus != null && generalState.systemStatus.label == 'Active' && generalState.customMode != 'RTL') &&
                                <div className='full-color-button red' onClick={() => returnToLaunch()}>RTL</div>
                            }
                            {/* <div className='full-color-button orange' onClick={() => setToggleButtons(false)}>Pause Mission</div>
                                <div className='full-color-button orange' onClick={() => setToggleButtons(false)}>Emergency</div>
                                <div className='full-color-button red' onClick={() => setToggleButtons(false)}>RTL</div>
                                <div className='full-color-button orange' onClick={() => setToggleButtons(false)}>Safety LND</div> */}
                                {
                            selectedAircraft != null && generalState.isConnected && generalState.systemStatus != null && generalState.systemStatus.label != 'Active' &&
                            <div className='full-color-button red' onClick={() => onDisconnect()}>End Flight</div>
                        }
                        </div>
                        
                    </div>
                </div>

            </motion.div>
        </div>
    )
}
import React, {useContext, useState} from 'react';  
import {Stack} from '../../../../shared-components/layout/stack/stack';
import ArrowDropDownCircleSharpIcon from '@mui/icons-material/ArrowDropDownCircleSharp';
import { motion } from "framer-motion";
import PerfectScrollbar from 'react-perfect-scrollbar';
import { InterfaceContext } from '../../../../contexts/interface-context';
import { ConfigContext } from '../../../../contexts/config-context';
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';

export const InformationDock = ({children}) => {
    // const [isToggled, setIsToggled] = useState(true);
    const {infoDockOpened, setInfoDockOpened, menuBarVisible,setMenuBarVisible} = useContext(InterfaceContext);
    return(
        <motion.div 
            key="information-dock"
            className={`information-dock ${infoDockOpened ? 'full-size' : 'collapsed'}`}  
            initial={{  x: -300, opacity: 1}} 
            exit={{ x: -300, opacity: 0 }} 
            animate={{ x:0, opacity: 1 }}
            transition={{ duration: 0.4,  ease: [0.130, 0.850, 0.375, 0.960] }}
        >
            <Stack 
                halign='space-between' 
                valign="center" 
                direction="horizontal"  
                style={{marginBottom: "10px"}}
            >
                <p className='information-dock-title uppercase'>Information Dock</p>
              
                {!infoDockOpened && <ArrowForwardIos 
                    key='info-dock-arrow-icon'
                    // className={`icon pointer ${infoDockOpened ? 'back-arrow' : 'forth-arrow'}`} 
                    className='forth-arrow pointer'
                    onClick={() => {
                        setInfoDockOpened(true);  
                        setMenuBarVisible(true);
                            // if(infoDockOpened){
                            //     // console.log("1:",menuBarVisible);
                            //     setMenuBarVisible(false);
                                
                            //     // console.log("2:",menuBarVisible);
                            // } else {
                            //     setInfoDockOpened(true); 
                            // }
                    }}
                />}
               {infoDockOpened && 
                <ArrowBackIos 
                    key='info-dock-arrow-icon-back'
                    // className={`icon pointer ${infoDockOpened ? 'back-arrow' : 'forth-arrow'}`} 
                    className='back-arrow pointer'
                     onClick={() => { 
                        setInfoDockOpened(false);
                        setMenuBarVisible(false);
                     } }
                />}
            </Stack>
           
            <div className='information-dock-content-wrap'>
                {/* <PerfectScrollbar> */}
                    {children}
                {/* </PerfectScrollbar> */}
            </div>
        </motion.div>
    );
}
import React, { useContext, useEffect, useRef, useState } from "react";
import { TerrainPanel } from "./components/terrain-panel/terrain-panel";
import { MapToolbar } from "./components/map-toolbar/map-toolbar";
import { MissionPlanToolbar } from "./components/mission-plan-toolbar/mission-plan-toolbar";
import {UnitSelectorPanel} from './components/unit-selector-panel/unit-selector-panel';
import { FileDialog } from "../../shared-components/containers/file-dialog/file-dialog";
import { WarningsPanel } from "./components/warnings-panel/warnings-panel";
import { motion } from "framer-motion";
import { RiskAssessmentModal } from "./components/risk-assessment-modal/risk_assessment-modal";
import { RiskAssessmentPanel } from "./components/risk-assessment-modal/components/risk-assessement-panel/risk-assessement-panel";
// import { MissionPlanPanel } from "./components/mission-plan-toolbar/components/mission-plan-panel/mission-plan-panel";
import { InfoCard } from "../../shared-components/containers/info-card/info-card";
import { InfoPanel } from "../../shared-components/layout/info-panel/info-panel";
import { MissionInfo } from "./components/mission-info/mission-info";
import { ArrowDropDown, Article, DocumentScanner, KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import PerfectScrollbar from 'react-perfect-scrollbar';
import { FlightPathPanel } from "./components/mission-plan-toolbar/components/flight-path-panel/flight-path-panel";
import { MissionContext } from "../../contexts/mission-context";
import { MissionPlanPanelContent } from "./components/waypoint-panel/waypoint-panel";
import { MissionPlanPanel } from "./components/mission-plan-panel-v2/mission-plan-panel-v2";
import { FleetManagementContext } from "../../contexts/fleet-management-context";
import { TerrainContext } from "../../contexts/terrain-context";

export const MissionPlanningScreen = () => {	
	const [missionPlanToggled,setMissionPlanToggled] = useState(false);
	const { 
		currentPath: { 
			waypoints 
		}, 
		rallyPoints, 
		isSaved, 
		loadMissionPlan, 
		saveMissionPlan,
		missionMeta,
		missionAircraftType,
		setMissionAircraftType,
	} = useContext(MissionContext); 
	const [activeWaypointsPanelTab, setActiveWaypointsPanelTab] = useState('waypoints');
	const { aircraftTypes } = useContext(FleetManagementContext);
	const terrainLoaded = useRef(false);
	const { createMultipleTerrainPaths } = useContext(TerrainContext);

	useEffect(() => {
		if (missionAircraftType == null && aircraftTypes != null) {
			setMissionAircraftType(aircraftTypes[0] || null);
		}
	}, [missionAircraftType, aircraftTypes]);

	useEffect(() => {
		if (!terrainLoaded.current && waypoints != null && waypoints.length > 0) {
			terrainLoaded.current = true;
			createMultipleTerrainPaths([{ waypoints }]);
		}
	}, [waypoints]);
	
	return (
		<div className="mission-planning-screen">
			<WarningsPanel/>
			<MapToolbar/>
			{/* <UnitSelectorPanel/> */}
			<MissionInfo/>

			{/* <MissionPlanToolbar/>	 */}
			<motion.div
				initial={{ opacity: 0, y: 55 }}
				animate={{ opacity: 1, y: 0 }}
				exit={{ opacity: 0, y: 55 }}
				transition={{ duration: 0.3, delay: 0.2, ease: [0.130, 0.850, 0.375, 0.960] }}
			>
				<TerrainPanel/>
				<RiskAssessmentPanel/>
				<MissionPlanPanel panelClass='mission-plan-panel-accordion'/>

				{/* <MissionPlanPanelContent
					waypoints={waypoints} 
					rallyPoints={rallyPoints}
				/> */}

				{/* <InfoCard
					key="mission-plan-panel-card"
					primaryGradient='#060d1f'
					secondaryGradient='#060d1f'
				>
					<InfoPanel
						key="mission-planning-panel"
						title="Mission Plan"
						titleIcon={<Article/>}
						actionIcons={[{icon: missionPlanToggled ? <KeyboardArrowDown className="collapse-panel-icon"/> : <KeyboardArrowUp className="collapse-panel-icon"/>, action: () => setMissionPlanToggled(!missionPlanToggled)}]}
					>{/* <FlightPathPanel activeTab={activeWaypointsPanelTab} waypoints={waypoints} rallyPoints={rallyPoints}/> */}
							
						
					{/* </InfoPanel> */}
				{/* </InfoCard> */}
			
			</motion.div>
		</div>
	)
}
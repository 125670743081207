import { Folder } from '@mui/icons-material';
import React, { useContext, useRef, useState, useEffect } from 'react';
import { FleetManagementContext } from '../../../../../../contexts/fleet-management-context';
import { FlightContext } from '../../../../../../contexts/flight-context';
import { MissionContext } from '../../../../../../contexts/mission-context';
import { missionSchema } from '../../../../../../contexts/schemas/mission-schema';
import { FileDialog } from '../../../../../../shared-components/containers/file-dialog/file-dialog';
import { InputField } from '../../../../../../shared-components/inputs/input-field/input-field';
import { SelectInput } from '../../../../../../shared-components/inputs/select-input/select-input';
import { Stack } from '../../../../../../shared-components/layout/stack/stack';


export const MissionSetup = () => {
    const { personnel } = useContext(FleetManagementContext);
    const selectedOperator = useRef(personnel.length > 0 ? personnel[0].id : "-");
    const [openFileDialogVisible, setOpenFileDialogVisible] = useState(false);
    const [currentMissionPlan, setCurrentMissionPlan] = useState(null);
    const [selectedMissionPlan, setSelectedMissionPlan] = useState(null);
    // const [currentFlightPath, setCurrentFlightPath] = useState(null);
    const { scheduleFlightData } = useContext(FlightContext);
    const loadMissionPlan = (plan) => {
        console.log(plan);
        // setCurrentMissionPlan(plan.data.title);
        setSelectedMissionPlan(plan);
        scheduleFlightData.current.missionPlan = plan;
    }

    useEffect(() => {
        if (personnel.length > 0 && scheduleFlightData.current.pilotId == null) {
            scheduleFlightData.current.pilotId = personnel[0].id;
        }
    }, [personnel]);

    useEffect(() => {
        if (selectedMissionPlan != null) {
            scheduleFlightData.current.flightPath = selectedMissionPlan.data.flightPaths[0];
        }

        console.log(scheduleFlightData)
    }, [selectedMissionPlan]);

    const onChangeOperator = (pilot) => {
        console.log(scheduleFlightData.current.pilotId);
        scheduleFlightData.current.pilotId = pilot;
        console.log("pilot",pilot);
        console.log("schf",scheduleFlightData.current.pilotId);
 
    }
   
    return (
        <div className='schedule-flight-column-body mission-setup'>
            {openFileDialogVisible &&
                <FileDialog
                    dialogType="load"
                    entityName='Mission'
                    schema={missionSchema}
                    onClose={() => setOpenFileDialogVisible(false)}
                    onLoad={(missionPlan) => loadMissionPlan(missionPlan)}
                />}
            <div className='mission-setup-title-description'>

                <InputField
                    className='filled-input'
                    id={`mission-setup-flight-title`}
                    type='text'
                    defaultValue={scheduleFlightData.current.title}
                    onChange={(e) => scheduleFlightData.current.title = (e.target.value)}
                    label="Flight Title*"
                    labelColor={"white"}
                    labelFontSize={"16px"}
                    labelUppercase={false} />
                <label htmlFor="mission-setup-flight-description" className='text-area-label'>Flight Description*</label>
                <textarea
                    defaultValue={scheduleFlightData.current.description}
                    id={`mission-setup-flight-description`}
                    className='text-area'
                    rows="10"
                    cols="20"
                    onChange={(e) => { scheduleFlightData.current.description = e.target.value; }} />
                {/* <InputField
                    defaultValue={scheduleFlightData.current.missionPlan ? scheduleFlightData.current.missionPlan.data.title : null}
                    onIconClick={() => setOpenFileDialogVisible(true)}
                    className='filled-input select-mission'
                    id={`mission-setup-mission-selection`}
                    type='text'
                    disabled={true}
                    value={selectedMissionPlan ? selectedMissionPlan.data.title : 'Not selected'}
                    label="Select Mission*"
                    labelColor={"white"}
                    labelFontSize={"16px"}
                    labelUppercase={false}
                    icon={<Folder />}
                /> */}
                
               
                {/* <SelectInput
                    defaultValue={scheduleFlightData.current.pilotId}
                    value={selectedOperator}
                    options={personnel ? personnel.map(ac => ({ value: ac.id, name: `${ac.data.firstName} ${ac.data.lastName}` })) : []}
                    label='Select Operator'
                    onChange={(e) => scheduleFlightData.current.pilotId = e.target.value}

                /> */}
                 <SelectInput
                        // defaultValue={scheduleFlightData.current.pilotId}
                        // value={scheduleFlightData.current.pilotId}
                        options={personnel ? personnel.map(ac => ({ value: ac.id, name: `${ac.data.firstName} ${ac.data.lastName}` })) : []}
                        label='Select Operator'
                        onChange={(e) => onChangeOperator(e.target.value)}

                    />
                <Stack direction='horizontal' halign={'start'} valign={'start'} className='mission-setup-title-description-section'>
                    <InputField
                        defaultValue={scheduleFlightData.current.missionPlan ? scheduleFlightData.current.missionPlan.data.title : null}
                        onIconClick={() => setOpenFileDialogVisible(true)}
                        className='filled-input select-mission'
                        id={`mission-setup-mission-selection`}
                        type='text'
                        disabled={true}
                        value={currentMissionPlan}
                        label="Select Mission*"
                        labelColor={"white"}
                        labelFontSize={"16px"}
                        labelUppercase={false}
                        icon={<Folder className='pointer'/>}
                    />
                    <SelectInput
                        placeholder={'Mission not selected'}
                        options={selectedMissionPlan ? selectedMissionPlan.data.flightPaths.map(fp => ({ value: fp.index, name: fp.title })) : []}
                        label='Select Flight Path'
                        onChange={(e) => {
                            scheduleFlightData.current.flightPath = selectedMissionPlan.data.flightPaths.find(fp => fp.index == e.target.value)
                        }}

                    />
                   
                </Stack>            
            </div>

            <Stack direction='horizontal' halign={'start'} valign={'start'} className='mission-setup-title-description-section'>
                <InputField
                    className='filled-input'
                    id={`mission-setup-start`}
                    type='datetime-local'
                    defaultValue={scheduleFlightData.current.startTime}
                    onChange={(e) => scheduleFlightData.current.startTime = (e.target.value)}
                    label="Start Time*"
                    labelColor={"white"}
                    labelFontSize={"16px"}
                    labelUppercase={false} />
                <InputField
                    className='filled-input'
                    id={`mission-setup-end`}
                    type='datetime-local'
                    defaultValue={scheduleFlightData.current.endTime}
                    onChange={(e) => scheduleFlightData.current.endTime = (e.target.value)}
                    label={`End Time*`}
                    labelColor={"white"}
                    labelFontSize={"16px"}
                    labelUppercase={false} />
            </Stack>
        </div>

    )
}
import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';

import PerfectScrollbar from 'react-perfect-scrollbar';

export const Tabs = ({height='100%', tabs, children, className}) => {
    const [value, setValue] = useState('1');
   
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return(
    <TabContext value={value} className={className && className}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleChange}>
                {tabs.map((tab, index) => (
                    <Tab key={`tab-index-${index} ${value === tab.value && "active"}`}
                        label={tab.name} 
                        value={tab.value} />
                ))}
            </TabList>
        </Box>
        <div style={{height: height}}>
            <PerfectScrollbar>
                {children}
            </PerfectScrollbar>
       </div>
    </TabContext>
    );
}
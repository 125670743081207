import { ArrowUpward, Cloud, FireExtinguisher, Opacity, RemoveRedEye, Thermostat } from '@mui/icons-material';
import React, { useContext, useEffect, useState } from 'react';
import { IconText } from '../../../../../../shared-components/layout/icon-text/icon-text';
import { Stack } from '../../../../../../shared-components/layout/stack/stack';
import { WeatherInformationWidget } from '../../../../../home-screen/components/weather-information-widget/weather-information-widget';
// Contexts
import { WeatherContext } from "../../../../../../contexts/weather-context";
import { FlightContext } from '../../../../../../contexts/flight-context';

export const WeatherForecast = ({ }) => {
    const { 
		currentWeather, 
        getWeather,
		error, 
	 } = useContext(WeatherContext);

     const { scheduleFlightData } = useContext(FlightContext);
    //  const location = useRef(null);

     const [flightWeather, setFlightWeather] = useState({
         condition: {},
     });
    
    useEffect(() => {
        if (flightWeather.temp_c == null) { 
            flightWeather.temp_c = 0;
            console.log(scheduleFlightData.current.missionPlan.data.flightPaths[0].waypoints[0]);
            getWeather(scheduleFlightData.current.missionPlan.data.flightPaths[0].waypoints[0], scheduleFlightData.current.startTime).then((res) => {
                setFlightWeather(res);   
            })
        }
    }, [])

    return(
        <div className='schedule-flight-column-body mission-setup'>
           { error == null &&
        
            <div className='current-weather-info'>
                <Stack direction='vertical'>
                    <Stack direction='horizontal' halign='start' valign="center">
                        <img className="current-condition-icon" src={`http:${flightWeather.condition.icon}`}/>
                        <span>{ flightWeather.condition.text}</span>
                    </Stack>
                    <Stack direction='horizontal' halign='space-between' width='100%'>
                        <IconText direction='horizontal' icon={<Thermostat/>} text={`${flightWeather.temp_c} ºC`}/>
                        <IconText direction='horizontal' icon={<Cloud/>} text={`${flightWeather.cloud} %`}/>
                        <IconText direction='horizontal' icon={<ArrowUpward style={{ transform: `rotate(${flightWeather.wind_degree}deg)`}}/>} text={<span className='smallfont'>{flightWeather.wind_degree}° <br/> {(flightWeather.wind_kph * 0.54).toFixed(1)} kts</span>}/>
                    </Stack>
                    <Stack direction='horizontal' halign='space-between' width='100%'>
                        <IconText direction='horizontal' icon={<Opacity/>} text={`${(flightWeather.precip_in * 100).toFixed(1)} %`}/>
                        <IconText direction='horizontal' icon={<RemoveRedEye/>} text={`${flightWeather.vis_km} Km`}/>
                        <IconText direction='horizontal' icon={<FireExtinguisher/>} text={`${flightWeather.pressure_mb} mb`}/>

                    </Stack>
                </Stack>
                
            </div>
        }
        </div>
    )
}
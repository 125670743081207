
import { Add, ArrowBackIos, ArrowForwardIos, ArrowLeft, ArrowRight, BatteryStd, BatteryStdOutlined, Close, HomeRepairService, Inventory, WorkOutline } from '@mui/icons-material';
import React, { useContext, useRef, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { FleetManagementContext } from '../../../../contexts/fleet-management-context';
import { RiskAssessmentContext } from '../../../../contexts/risk-assessment-context';
import {LabelIconButton} from '../../../../shared-components/buttons/label-icon-button/label-icon-button';

import {Stack} from '../../../../shared-components/layout/stack/stack';

import PerfectScrollbar from 'react-perfect-scrollbar';
import { MissionSetup } from './components/mission-setup/mission-setup';
import { AircraftSetup } from './components/aircraft-setup/aircraft-setup';
import { RiskAssessment } from './components/risk-assessment/risk-assessment';
import { WeatherForecast } from './components/weather-forecast/weather-forecast';
import { FlightContext } from '../../../../contexts/flight-context';
import { SkyModal } from '../../../../shared-components/containers/sky-modal/sky-modal';

import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

export const ScheduleFlight = () =>{

    const allOperators = [{id: '222', data : {name:'Mission Plan 1'}},{id: '2222', data : {name:'Mission Plan 2'}}];
    const selectedOperator = useRef(allOperators[0].id);
    const navigate = useNavigate();
   
    // const {allBatteries} = useContext(FleetManagementContext);
    
    // console.log(allBatteries);
    // const selectedBattery = useRef(allBatteries[0] || "");
    const [step, setStep] = useState(0);
    // const {allOperators} = useContext(FleetManagementContext);
    // console.log(allOperators);
    const { scheduleFlightData, scheduleFlight } = useContext(FlightContext);
    const [showWarningPanel, setShowWarningPanel] = useState(false);
    const [activeStep, setActiveStep] = React.useState(0);

    const handleNext = () => {
      setStep((prevActiveStep) => prevActiveStep + 1);
    };
  
    const handleBack = () => {
        setStep((prevActiveStep) => prevActiveStep - 1);
    };
  
    const handleReset = () => {
        setStep(0);
    };


    const scheduleCurrentFlight = () => {
        scheduleFlight().then(() => {
            window.alert("Flight scheduled!");
            // scheduleFlightData.current = {};
        }).catch((err) => {
            window.alert("Failed to create flight");
            console.log(err)
        })
    }
    
    const checkFlightData = () => {
        //title, description, missionPlan, startTime, endTime
        const p = scheduleFlightData.current;
        if (p.missionPlan != null && p.title != null 
            &&  p.missionPlan != null && p.startTime != null && p.endTime != null) {
                setShowWarningPanel(false);
                handleNext();
                console.log("Change step fut", step);
            } else {
                setShowWarningPanel(true);
                console.log("WP/step",showWarningPanel, step);
            }
    }
    const changeStep = () => {
        console.log("Change step curr", step);
        if (step === 0 || step === 1) {
            checkFlightData();
            // handleNext();
           
        }
        else if 
            (step === 2) { scheduleCurrentFlight()}
        
    }
    const steps = [
        {
          label: 'Mission Setup',
        },
        {
          label: 'Aircraft Setup',
        
        },
        {
          label: 'Awareness Overview',
         
        },
      ];
    return(
        <div className='schedule-flight-wrap'>
            {showWarningPanel && 
            <SkyModal
            className='warning-panel'
            onClose = {() =>setShowWarningPanel(false)}
            title = {"Missing Fields"}  
            modalVisible = {showWarningPanel}
            disableScrollbar>
                <p>Please fill in all the required fields</p>
            </SkyModal>}
            <Stack direction='horizontal' halign={'start'} valign={'start'} className='schedule-flight-content-scroll-wrap'>
            {/* <PerfectScrollbar> */}
                <VerticalLinearStepper steps={steps} activeStep={step}/>
                {step === 0 ?
                
                <Stack direction='vertical' halign={'start'} valign={'start'} className='schedule-flight-content-wrap'>
                    <div className='schedule-flight-column mission-setup-wrap'>
                        <div className='schedule-flight-column-header'>
                            <p className='uppercase bold'>Mission Setup</p>
                        </div>
                        <MissionSetup />
                    </div>
                    {/* <div className='schedule-flight-column aircraft-setup-wrap'>
                        <div className='schedule-flight-column-header aircraft-setup-header'>
                            <p className='uppercase bold'>Aircraft Setup</p>
                            <div className='schedule-flight-column-header-template'>
                                
                            </div>
                        </div>
                        <AircraftSetup allOperators={allOperators} selectedOperator={selectedOperator}/>
                    </div> */}
                </Stack>
                : step === 1 ? 
                <Stack direction='horizontal' halign={'start'} valign={'start'} className='schedule-flight-content-wrap'>
                    <div className='schedule-flight-column aircraft-setup-wrap'>
                        <div className='schedule-flight-column-header aircraft-setup-header'>
                            <p className='uppercase bold'>Aircraft Setup</p>
                            <div className='schedule-flight-column-header-template'>
                                {/* <LabelIconButton title='load template'/>
                                <LabelIconButton title='save template'/> */}
                            </div>
                        </div>
                        <AircraftSetup allOperators={allOperators} selectedOperator={selectedOperator}/>
                    </div>
                </Stack>
                : step === 2 ?
            
                    <Stack direction='vertical' halign={'start'} valign={'start'} className='schedule-flight-content-wrap'>
                        <div className='schedule-flight-column risk-assessment-column-wrap'>
                            <div className='schedule-flight-column-header'>
                                <p className='uppercase bold'>Risk Assessment</p>
                            </div>
                            <RiskAssessment allFlightPath={!scheduleFlightData.current.missionPlan ? [] : scheduleFlightData.current.missionPlan.data.flightPaths} />
                        </div>
                        <div className='schedule-flight-column'>
                            <div className='schedule-flight-column-header'>
                                <p className='uppercase bold'>Weather Forecast</p>
                            </div>
                            <WeatherForecast />
                        </div>
                    </Stack>
                : 
                <Stack direction='horizontal' halign={'start'} valign={'start'} className='schedule-flight-content-wrap'>

                </Stack>
                }
                {/* </PerfectScrollbar> */}
            </Stack>
            <div className='schedule-flight-button-wrap'>
                { (step === 1 || step === 2) && 
                <Button className='schedule-flight-button back' 
                    onClick={() => handleBack()}>
                    <Stack direction='horizontal' halign={'center'} valign={'start'}>
                        <ArrowBackIos/> 
                        <p>Back</p>
                    </Stack>
                </Button> }
                <Button className='schedule-flight-button' 
                    onClick={() => changeStep()}>
                    {(step === 0 || step === 1) ? 
                        <Stack direction='horizontal' halign={'center'} valign={'start'}>
                            <p>Next</p> 
                            <ArrowForwardIos/>
                        </Stack> 
                        : 
                        <Stack  direction='horizontal' halign={'center'} valign={'start'}>
                            <p>Schedule</p> 
                            <ArrowForwardIos/>
                        </Stack>}

                </Button> 
            </div>
            
           
        </div>
    )
}



export default function VerticalLinearStepper({children, steps=[], activeStep}) {

  return (
    <Box sx={{ width: 300, margin:1 }}>
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((step, index) => (
          <Step key={step.label}>
            <StepLabel
              optional={
                index === 2 ? (
                  <Typography variant="caption">Last step</Typography>
                ) : null
              }
            >
              {step.label}
            </StepLabel>
            <StepContent>
              {/* <Typography>{step.description}</Typography>
              <Box sx={{ mb: 2 }}>
                <div>
                  <Button
                    variant="contained"
                    onClick={handleNext}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    {index === steps.length - 1 ? 'Finish' : 'Continue'}
                  </Button>
                  <Button
                    disabled={index === 0}
                    onClick={handleBack}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    Back
                  </Button>
                </div>
              </Box> */}
              {children}
            </StepContent>
          </Step>
        ))}
      </Stepper>
     
    </Box>
  );
}
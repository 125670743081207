import React, {useContext, useState} from 'react';
import { SkyModal } from '../../../../shared-components/containers/sky-modal/sky-modal';
import { FMDashboardPanel } from '../fm-dashboard-panel/fm-dashboard-panel';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { RPACreate } from '../rpa-create/rpa-create';
import { FleetManagementGridContent } from '../fleet-management-grid-content/fleet-management-grid-content';
import { FleetManagementContext } from '../../../../contexts/fleet-management-context';
import UtilityService from '../../../../helpers/utility-service';
import { aircraftSchema } from '../../../../contexts/schemas/aircraft-schema';
import { aircraftTypeSchema } from '../../../../contexts/schemas/aircraft-type-schema';
import { autopilotSchema } from '../../../../contexts/schemas/autopilot-schema';
import { PanelTabs,Tab } from '../../../../shared-components/containers/panel-tabs/panel-tabs';
import { FleetManagementTableContent } from '../fleet-management-table-content/fleet-management-table-content';

export const FMRPAPanel = ({
filter, typeFilter, apilotFilter
}) => {
    //create modal, edit, 
	const [aircraftTab, setAircraftTab] = useState("Aircraft");
    const [openRPAModal, setOpenRPAmodal] = useState(false);

    const [aircraftFilter, setAircraftFilter] = useState(filter || "");
	const {allAircraft} = useContext(FleetManagementContext);
    const aircraftData = allAircraft || [];//
	
	const [aircraftTypeFilter, setAircraftTypeFilter] = useState(typeFilter || "");
	const {aircraftTypes} = useContext(FleetManagementContext);
    const aircraftTypeData =  aircraftTypes || [];//

	const [autopilotFilter, setAutopilotFilter] = useState(apilotFilter || "");
	const {autopilots} = useContext(FleetManagementContext);
    const autopilotData =  autopilots || [];//

    const onOpenRPAModal = () => {
        setOpenRPAmodal(true);
    }
    return(
		<div className='fm-rpa-panel-wrap fmpanel'>
		<SkyModal 
			title={'RPA creation' }
			modalVisible={openRPAModal}
			onClose={() => setOpenRPAmodal(false)}
			className={"modal-content"}
		>
			
			<PerfectScrollbar>
			{aircraftTab === 'Aircraft' &&
				<RPACreate/>
			}
				{/* <div></div> */}
			</PerfectScrollbar>
			<div className='rpa-creation-buttons-wrap'>
				<button className='rpa-creation-btn-blue'>
					Bulk Upload
				</button>
				<button className='rpa-creation-btn-green'>
					Create
				</button>
			</div>
			
		</SkyModal>
		 {/* Remotely piloted AIRCRAFT          */}
		 <div className="fleet-page-wrap">
		 	{/* <p className='fleet-management-header-title uppercase bold'>Aircraft, Aircraft Type & Autopilot</p> */}
			<div className="fleet-management-card-wrap">
				<FMDashboardPanel 
					className='fleet-management-card tabbed-card' 
					onFilter={aircraftTab === 'Aircraft' ? (filter) => setAircraftFilter(filter): aircraftTab === 'Aircraft Type' ? (filter) => setAircraftTypeFilter(filter) : (filter) => setAutopilotFilter(filter) }  
					dashboardPanelToolbar={true}
					entityName={aircraftTab === 'Aircraft' ? aircraftSchema.entityName : aircraftTab === "Aircraft Type" ? aircraftTypeSchema.entityName : autopilotSchema.entityName}
					schema={aircraftTab === 'Aircraft' ? aircraftSchema : aircraftTab === "Aircraft Type" ? aircraftTypeSchema : autopilotSchema }
					createItem={()=>onOpenRPAModal()}
					data={aircraftTab === 'Aircraft' ? aircraftData : aircraftTab === "Aircraft Type" ? aircraftTypeData : autopilotData}
					>
					<PanelTabs onOpen={(tabTitle) => setAircraftTab(tabTitle)}>
						<Tab title='Aircraft' key='aircraft-table-tab'>
						{aircraftData.length != 0 ? <FleetManagementGridContent
							// getTitle={(item) => item.title}
							getSubtitle={(item) => item.procurementDate.split('T')[0]}
							subtitleLabel='Procurement date'
							getImage={(item) => item.image}
							getIdNum={(item) => item.registrationNumber}
						
							// ref={aircraftTableRef}
							data={aircraftData}
							loadData={aircraftData}//{() => fleetManagementService.fetchAllAircraft()}
							loadSingleItem={aircraftData}//{(id) => fleetManagementService.fetchSingleAircraft(id)}
							updateSingleItem={()=>{}}//{(id, updateObj) => fleetManagementService.updateAircraft(id, updateObj)}
							deleteSingleItem={()=>{}}//{(id) => fleetManagementService.deleteAircraft(id)}
							getTitle={(item) => item.name || "Unnamed Aircraft"}
							getStatus={(item) => item.status || 'Serviceable'}
							schema = {aircraftSchema}
							filter = {aircraftFilter}
							filterFunction = {(row, aircraftFilter)=>UtilityService.isRowFiltered(row,aircraftFilter)}
							calcCycleColor = {(cycles)=>UtilityService.calcCycleColor(cycles)}
							editViewNumCols={2}
							additionalTabs={[{
								//  title: "Flight history",
								// component: AircraftFlightHistory,
							}]}
						/> : <div className='no-aircraft-added'><p>No aircraft added</p></div>}
							
						</Tab>
						
						<Tab title='Aircraft Type' key='aircraft-type-table-tab'>
							<FleetManagementTableContent    
								// ref={batterySetsTableRef}
								data={aircraftTypeData}
								loadSingleItem= {()=>{}}//{(id) => fleetManagementService.fetchSingleBatterySet(id)}
								updateSingleItem= {()=>{}}//{(id, updateObj) => fleetManagementService.updateBatterySet(id, updateObj)}
								deleteSingleItem= {()=>{}}//{(id) => fleetManagementService.deleteBatterySet(id)}
								getTitle={(item) => item.title || "Unnamed Aicraft Type"}
								schema = {aircraftTypeSchema}
								filter = {aircraftTypeFilter}
								filterFunction =  {(row, filter)=>UtilityService.isRowFiltered(row,filter)}//{(row, filter)=>isRowFiltered(row,filter)}
								// calcCycleColor = {(cycles)=>UtilityService.calcCycleColor(cycles)}
							/>   
						</Tab>
						<Tab title='Autopilot' key='autopilot-table-tab'>
							<FleetManagementTableContent
								//ref={batteriesTableRef}
								data={autopilotData}//{() => fleetManagementService.fetchAllBatteries()}
								loadSingleItem={()=>{}}//{(id) => fleetManagementService.fetchSingleBattery(id)}
								updateSingleItem={()=>{}}//{(id, updateObj) => fleetManagementService.updateBattery(id, updateObj)}
								deleteSingleItem={()=>{}}//{(id) => fleetManagementService.deleteBattery(id)}
								getTitle={(item) => item.title || "Unnamed Autopilot"}
								schema = {autopilotSchema}
								filter = {autopilotFilter}
								filterFunction = {(row, filter)=>UtilityService.isRowFiltered(row,filter)}
								calcCycleColor = {(cycles)=>UtilityService.calcCycleColor(cycles)}
							/>
						</Tab>
					</PanelTabs>
				</FMDashboardPanel>
			</div>
     	</div>
        </div>
	
       
		
    );
}

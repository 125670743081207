// DISTANCE_SENSOR (#132)
export function distanceSensor (message, aircraft) {
	const {
		current_distance
	} = message;

	let altSensorData = 'N/A';
	try {
		altSensorData = parseFloat(current_distance / 100).toFixed(2)
	} catch (err) {
		console.log(err);
		altSensorData = 0;
	}

	aircraft.positionStateSetters.setDistanceSensorData(altSensorData)
}
import { MissionContext } from "../mission-context";

export const missionSchema = {
    entityName: 'Mission',
	loadSingleItem: (MissionContext, id) => MissionContext.loadMissionPlan(id),
	// updateSingleItem: async (MissionContext, id, data) => await MissionContext.updatePerson(id, data),
	// removeSingleItem: async (MissionContext, id) => await MissionContext.removePerson(id),
	// createSingleItem: async (MissionContext, data) => await MissionContext.createPerson(data),

    attributes:[
        {
                "key": "title",
                "label": "Title",
                "path": "data.title",
        },
        {
                "key": "createdAtDate",
                "label": "Creation Date",
                "path": "createdAt",
                "mapValues": (value) => value.split('T')[0],  //  "2021-01-01T12:34:56" -> "2021-01-01" 
        },
        {
                "key": "createdAtDate",
                "label": "Creation Time",
                "path": "createdAt",
                "mapValues": (value) => value.split('T')[1],  //  "2021-01-01T12:34:56" -> "12:34:56""
        },
        {
                "key": "flightPaths",
                "label": "#Flight Paths",
                "path": "data.flightPaths",
                "mapValues": (value) => value != null ? value.length : 0,  // "[{...},{...}]" -> 2
        },
    ]
};

import { motion } from "framer-motion";
import React, { useContext, useState } from "react";
import { MapContext } from "../../../../contexts/map-context";
import { Stack } from "../../../layout/stack/stack";

export const TileProviderSelector = ({ position, className }) => {
    const { 
        selectedTileProvider, 
        mapTileProviders,
        setTileProvider,
    } = useContext(MapContext);

    const [toggled, setToggled] = useState(false);

    return (
        <motion.div 
            key="tile-provider-selector"
            className={`tile-provider-selector ${className && className} ${position == 'left' ? 'left' : 'right'}`}
            onDoubleClick={(e) => e.stopPropagation()}
            onMouseLeave={(e) => { if (toggled) { setToggled(false) }}}
        >
            <Stack 
                direction="vertical" 
                key="tile-provider-wrap"
            >
                <div 
                    className="selected-provider" 
                    onClick={(e) => { e.stopPropagation(); setToggled(!toggled) }}
                    onMouseOver={(e) => { if (!toggled) { setToggled(true) }}}
                >
                    <img src={ selectedTileProvider.thumbnail }/>
                </div>

                
                { 
                    toggled && 
                    mapTileProviders
                        .filter(provider => provider.uri != selectedTileProvider.uri)
                        .map((provider, index) => (
                            <motion.div 
                                key={`tile-provider-${provider.uri}`}
                                className="available-provider" 
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setTileProvider(provider);
                                    setToggled(false);
                                }}
                                initial={{ y: 10, opacity: 0}}
                                animate={{ y: 0, opacity: 1 }}
                                exit={{ y: 10, opacity: 0 }}
                                transition={{ duration: 0.1 }}
                            >
                            <img src={provider.thumbnail}/>
                        </motion.div>
                    ))
                }
            </Stack>
        </motion.div>
    )
}
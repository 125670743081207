import React, { useState } from "react";
import {PanelTools} from '../../buttons/panel-tools/panel-tools';
import PerfectScrollbar from "react-perfect-scrollbar";

export const Tab = ({scrollable, children}) => {
	
		return (
		<div className="panel-tab">
			{ scrollable ? 
			//  <PerfectScrollbar option={{ wheelPropagation: false }} > 
			<div className="scrollable">
				{ children }
			</div>
			// </PerfectScrollbar> 
			: children}
		</div>)
	}



export const PanelTabs = ({children, title, onOpen, panelTools, onFilter}) => {
	
	const childrenFiltered = (Array.isArray(children) ? children : [children]).filter(el => Object.keys(el).length > 0).flat();
	const [activeTab, setActiveTab] = useState(Array.isArray(children) ? children.find(el => Object.keys(el).length > 0).props.title: children.props.title);
	if (activeTab && onOpen) {
		onOpen(activeTab);
		
	}
	const selectTab = (title) => {
		
		setActiveTab(title);
	}
		return (
			<div className="panel-tabs">
				<div className="panel-tabs-header-wrap">
					<div className='panel-tabs-header'>
						{ childrenFiltered.map((el,index) => (
								
								<span 
									key={`"tab-"${index}`}
									onClick={() => {
										setActiveTab(el.props.title);
										if (onOpen) {
											onOpen(el.props.title);
										}
									}} 
									className={`panel-tab-label ${activeTab == el.props.title ? 'active': ''}`}>
										{el.props.title}
									</span>
							))
						}
						
					</div>
					{ panelTools ?  <PanelTools onFilter={onFilter} /> : ""}
				</div>
				<div className="panel-tabs-body">
					{/* <PerfectScrollbar option={{ wheelPropagation: false }} > */}
					{ childrenFiltered.filter(el => el.props.title == activeTab) }
					{/* </PerfectScrollbar> */}
				</div>
			</div>
		)
	
}
import { BlurCircular, LocationSearching, PanToolRounded } from "@mui/icons-material";
import React, { useContext } from "react";
import { MapContext } from "../../../../contexts/map-context";
import { SideMenuItem } from "../../../../shared-components/containers/side-menu/components/side-menu-item/side-menu-item";
import { SideMenu } from "../../../../shared-components/containers/side-menu/side-menu";
import Icons from '../../../../helpers/icons';

export const MapToolbar = () => {
	const { currentTool, setCurrentTool } = useContext(MapContext);

	return (
		<SideMenu 
			key="map-toolbar"
			className="map-toolbar"
			direction="left"
		>
			<SideMenuItem
				onClick={() => setCurrentTool('hand-tool')}
				isActive={currentTool === 'hand-tool'}
				icon={<PanToolRounded/>}
				title={"Hand Tool"}
				tooltipPosition={"left"}
			/>
			<SideMenuItem
				onClick={() => setCurrentTool('edit-path')}
				isActive={currentTool === 'edit-path'}
				icon={<LocationSearching/>}
				title="Waypoints"
				tooltipPosition={"left"}
			/>
			<SideMenuItem
				onClick={() => setCurrentTool('edit-rtl')}
				isActive={currentTool === 'edit-rtl'}
				icon={<img style={{fill: "white"}} src={Icons.regular.rtlIcon} alt='rtl'/>}
				title="RTL"
				tooltipPosition={"left"}
			/>
		</SideMenu>
	)
}
const UINT16_MAX = 65535;

// BATTERY_STATUS (#147)
export function batteryStatus (message, aircraft) {
	const { 
		id,
		battery_function,
		type,
		temperature: battery_temperature,
		voltages, 
		current_battery, 
		current_consumed,
		energy_consumed,
		battery_remaining,
		time_remaining,
		charge_state,
		voltages_ext,
		mode,
		fault_bitmask,
	} = message;

	const { batteries, remaining, lastTimestamp } = aircraft.batteryState.getState();
	const now = Date.now();

	const { current, voltage, currentConsumed, temperature } = ((batteries != null && batteries[id]) || {});

	if (lastTimestamp == null || now - lastTimestamp > 1000 ) {
		aircraft.batteryStateSetters.setTimestamp(now);
		// Current
		const processedBatteryCurrent = current_battery * 10;
		if (current != processedBatteryCurrent) {
			aircraft.batteryStateSetters.setCurrent(id, processedBatteryCurrent);
		}

		// Voltages
		const processedVoltages = voltages[0] / 1000;
		if (voltage != processedVoltages) {
			aircraft.batteryStateSetters.setVoltage(id, processedVoltages);
		}

		// Consumed current
		if (currentConsumed != current_consumed) {
			aircraft.batteryStateSetters.setCurrentConsumed(id, current_consumed);
		}

		// Temperature
		const processedTemperature = battery_temperature / 1000;
		if (temperature != processedTemperature) {
			aircraft.batteryStateSetters.setTemperature(id,processedTemperature);
		}

		// Remaining
		if (remaining != battery_remaining) {
			aircraft.batteryStateSetters.setRemaining(battery_remaining);
		}
	}
}
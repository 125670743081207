import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { InterfaceContext } from "../../../../contexts/interface-context";
import useWindowDimensions from "../../../../hooks/useWindowDimensions";
import { Stack } from "../../../../shared-components/layout/stack/stack";

export const Shortcuts = ({ shortcuts }) => {
	const {infoDockOpened} = useContext(InterfaceContext);
	const { height, width } = useWindowDimensions();
	const breakPoint = 880;
	return (
		<div className={`shortcuts ${infoDockOpened && 'moved'}`}>
			<Stack
				direction="vertical"
			>
				{/* <p className="shortcuts-title">Shortcuts</p> */}
				<Stack
					direction="horizontal"
					halign="space-between"
				>
					{
						shortcuts.map(shortcut => (
							<Link key={`shortcut-${shortcut.label}`} to={shortcut.path}>
								<div className={`single-shortcut ${width < breakPoint && infoDockOpened && 'buttoned' }`}>
									<span className={`shortcut-icon ${width < breakPoint && infoDockOpened && 'buttoned' }`}>{ shortcut.icon }</span>
									{width < breakPoint && !infoDockOpened && shortcut.label }
									{width > breakPoint && shortcut.label}
								</div>
							</Link>
						))
					}
				</Stack>
			</Stack>

		</div>
	)
}
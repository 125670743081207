import { useMemo } from "react";

function generateBars(min, max, side, stepSmall, stepBig) {	
	const barValues = [];	
	for (let i = max; i >= min; i-= stepSmall) {
		barValues.push(i);
	}

	const bars = barValues.map((value, index) =><span key={`bar-${side}-${index}-${value}-big`} data-before-content={Math.round(value)} className="bar-big"></span>)

	return bars;
}

export const Bars = ({ min, max, side, stepSmall, stepBig }) => {
	
	const bars = useMemo(() => generateBars(min, max, side, stepSmall, stepBig));
	return <div className="bars" key={`bars-${min}-${max}-${side}`}>
		{ bars }
	</div>

}
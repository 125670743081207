import { combineReducers } from "redux";

// latitude
const positionReducer = (
	state = { 
		latitude: null, 
		longitude: null, 
		altitude: null, 
		relativeAltitude: null, 
		heading: null,
		path: [],
		rangefinderAltitude: null,
		pendingDirectPoint: null,
		targetPosition: null,
		time_boot_ms: null,
		orbitDraft: null,
	}, action) => {
	switch (action.type) {
		case 'SET_NEW_POSITION':
			return {...state, ...action.payload};
		case 'SET_DISTANCE_SENSOR_DATA':
			return { ...state, rangefinderAltitude: action.payload };
		case 'SET_PENDING_DIRECT_POINT':
			return { ...state, pendingDirectPoint: action.payload };
		case 'SET_NEW_PATH':
			return { ...state, path:  action.payload };
		case 'SET_TARGET_POSITION':
			return { ...state, targetPosition:  action.payload };
		case 'SET_ORBIT_DRAFT':
			return { ...state, orbitDraft:  action.payload };
		case 'SET_PAUSED_TARGET_POSITION':
			return { ...state, pausedTargetPosition:  action.payload };
		default: return state;
	}
}

export const createSetters = (store) => ({
		setPosition: (position) => store.dispatch({ type: 'SET_NEW_POSITION', payload: position }),
		setDistanceSensorData: (rangefinderAltitude) => store.dispatch({ type: 'SET_DISTANCE_SENSOR_DATA', payload: rangefinderAltitude }),
		setPendingDirectPoint: (coords) => store.dispatch({ type: 'SET_PENDING_DIRECT_POINT', payload: coords }),
		setPath: (newPath) => store.dispatch({ type: 'SET_NEW_PATH', payload: newPath }),
		setTargetPosition: (targetPosition) => store.dispatch({ type: 'SET_TARGET_POSITION', payload: targetPosition }),
		setPausedTargetPosition: (targetPosition) => store.dispatch({ type: 'SET_PAUSED_TARGET_POSITION', payload: targetPosition }),
		setOrbitDraft: (orbit) => store.dispatch({ type: 'SET_ORBIT_DRAFT', payload: orbit }),
});

export default positionReducer;
import { Add, ExpandMore, KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material"
import { useContext, useEffect, useRef, useState } from "react"
import { EditData } from "../../../../../../shared-components/inputs/edit-data/edit-data";
import { Stack } from "../../../../../../shared-components/layout/stack/stack"
import Icons from '../../../../../../helpers/icons';
import { Panel } from "../../../../../../shared-components/layout/panel/panel";
import { Accordion, AccordionDetails, AccordionSummary, Select } from "@mui/material";
import { MissionContext } from "../../../../../../contexts/mission-context";
import { OutlineButton } from "../../../../../../shared-components/buttons/outline-button/outline-button";
import UtilityService from "../../../../../../helpers/utility-service";
import { WaypointsLibraryContext } from "../../../../../../contexts/waypoints-library-context";

export const MissionItem = ({ missionItem, heading, index, distance, rallypoint=false}) => {
    const [openWP, setOpenWP] = useState(false);
    const { altitudeDatum, updateWaypoint, removeWaypoint, removeRallyPoint } = useContext(MissionContext);
    const libraryTitleRef = useRef(null);
    const { amsl, agl, alp } = missionItem;
    const altitudes =  { amsl, agl, alp };
    const [addToLibraryVisible, setAddToLibraryVisible] = useState(false);
    // console.log(altitudes);
    const {allFolders, createWaypoint, fetchAllFolders, currentLevel, setCurrentLevel, libraryVisible, setLibraryVisible, allWaypoints} = useContext(WaypointsLibraryContext);
    const [selectedFolder, setSelectedFolder] = useState(allFolders && allFolders.length > 0 ? allFolders[0].folderId : null);
    const [selectedType, setSelectedType] = useState('midpoint');
    const [libraryAddErr, setLibraryAddErr] = useState(null);
    // console.log("MI",missionItem);
    useEffect(() => {
        if(selectedFolder === null && allFolders && allFolders.length > 0) {
            console.log("All folders, ",allFolders.folderId);
            setSelectedFolder(allFolders[0].id);
        }
        // console.log("All folders, EFF ",allFolders && allFolders.length > 0 && allFolders[0].id || null);
        setSelectedFolder((allFolders && allFolders.length > 0 && allFolders[0].id) || null);
        // console.log("sf", allFolders[0].id);
      }, [allFolders]);

    const addToLibrary = () => {
        // const waypoint = currentPath.waypoints[waypointIndex];

        if(libraryTitleRef.current.value !== null && libraryTitleRef.current.value !== "") {
            // fetchAllFolders();
            if(allWaypoints.find((el) => el.title === libraryTitleRef.current.value) !== undefined) {
                setLibraryAddErr("Name already exists. Please choose another.");
                return;
            }
            const wpData = {
                "title": libraryTitleRef.current.value,
                "type": selectedType,
                "latitude": missionItem.latitude.toString(), 
                "longitude": missionItem.longitude.toString(), 
                "altitude": "22",
                "folderId": selectedFolder,
            };
            // console.log(wpData);
            createWaypoint(wpData);
            setCurrentLevel(selectedFolder);
            fetchAllFolders();
            setAddToLibraryVisible(false);
        }
        else {
            setLibraryAddErr("Please insert waypoint name");
        }
        
    }
    const handleRemoveFromList = (index) => {
        if(window.confirm("Are you sure you want to remove waypoint from the list?")) { 
            removeWaypoint(index)
        }
    }
    return (
        <div className="mission-item-wrap">
            <Accordion sx={{ overflow: 'hidden', border: '1px solid #0F4F7F', backgroundColor: '#060d1f', borderRadius: '4px'}}>
                <AccordionSummary
                    sx={{
                        // backgroundColor: '#060d1f',
                    }}
                    expandIcon={<ExpandMore className="toggle-icon"/>}
                    aria-controls={`panel-header-wp`}
                    id={`panel-header-wp`}
                >
                    {!rallypoint && 
                    <div className="panel-header-wrap">

                        <span className="panel-icon">
                            <img src={Icons.regular.waypointIcon} alt='wp'/>
                        </span>

                        <span className="panel-title">WP{index + 1}</span>
                        
                    </div>}
                    {rallypoint &&
                    <div className="panel-header-wrap">
                        <span className="panel-icon">
                            <img src={Icons.regular.rtlIcon} alt='rp'/>
                        </span>

                        <span className="panel-title">RTL{index + 1}</span>
                    </div>}
                </AccordionSummary>

                {!rallypoint && <AccordionDetails
                    sx={{
                        // backgroundColor: '#060d1f',
                        color: 'white',
                        padding: '10px',
                    }}
                >
                   
                    <Stack direction='vertical' halign='space-between' className="mission-item-content">
                   
                    <div className='mission-item-actions-wrap'>
                        <span className="remove-item" onClick={() => handleRemoveFromList(index) }>Remove</span>
                        {addToLibraryVisible ?
                            <div className="save-item-box">
                                <span className="save-item" onClick={() => addToLibrary(index)}>Save</span>
                                <span className="save-item" onClick={() => {setAddToLibraryVisible(false);setLibraryVisible(false);}}>Cancel</span>
                            </div> :  <span className="save-item" onClick={() => {setAddToLibraryVisible(true); setLibraryVisible(true);}}>Add to library</span> }
                    </div>
                    {addToLibraryVisible && <div className="mission-item-add-to-library-box">
                        <input className="mission-item-add-to-library-box-title" ref={libraryTitleRef} placeholder='Insert name' onChange={() => setLibraryAddErr(null)}/>
                        {libraryAddErr !== null && <span className="red">{libraryAddErr}</span> }
                        {/* <div className='mission-item-add-to-library-selects-wrap'> */}
                            {/* <div> */}
                                <label form="select-waypoints-folder">Select Folder</label>
                                <select className="select-waypoints-folder" onChange={e => setSelectedFolder(e.target.value)} >
                                    {allFolders !== null && allFolders.map((folder, index) => (
                                        <option key={`folder-${index}`} value={folder.id} selected={selectedFolder === folder.id}>{folder.title}</option>
                                    ))}
                                   
                                </select>
                                {allFolders.length === 0 && <span className="red">Please first add a folder to the library</span> }
                                <label form="select-waypoints-folder">Select Waypoint Type</label>
                                <select className="select-waypoints-folder" onChange={e => setSelectedType(e.target.value)} >
                                    <option key={`wp-type-takeoff`} value={"takeoff"} selected={selectedType === "takeoff"}>Take Off</option>
                                    <option key={`wp-type-waypoint`} value={"midpoint"} selected={selectedType === "midpoint"}>Waypoint</option>
                                    <option key={`wp-type-landing`} value={"landing"} selected={selectedType === "landing"}>Landing</option>
                                </select>
                                
                            {/* </div> */}
                        {/* </div> */}
                    </div>}
                    {/* <Stack className='mission-item-attribute' direction='horizontal' halign='space-between' valign='center'>
                         <span className='bold uppercase small'>Title</span>
                         <EditData onSubmit={(title) => updateWaypoint(index, { title })} defaultValue={missionItem.label} value={missionItem.label} type="string"/>
                        
                                    </Stack> */}
                     <Stack className='mission-item-attribute' direction='horizontal' halign='space-between' valign='center'>
                         <span className='bold uppercase small'>Latitude</span>
                         <EditData onSubmit={(latitude) => updateWaypoint(index, { position: { latitude, longitude: missionItem.longitude } })} defaultValue={missionItem.latitude} value={missionItem.latitude} type="float"/>
                     </Stack>
                     <Stack className='mission-item-attribute' direction='horizontal' halign='space-between' valign='center'>
                         <span className='bold uppercase small'>Longitude</span>
                         <EditData onSubmit={(longitude) => updateWaypoint(index, { position: { latitude: missionItem.latitude, longitude } })} defaultValue={missionItem.longitude} value={missionItem.longitude} type="float"/>
                     </Stack>
                    <Stack className='mission-item-attribute' direction='horizontal' halign='space-between' valign='center'>
                         <span className='bold uppercase small'>Speed</span>
                         <EditData onSubmit={(speed) => updateWaypoint(index, { speed })} defaultValue={missionItem.speed} value={missionItem.speed} postfixText='M/S' type="float"/>
                         {/* <span>M/S</span> */}
                     </Stack>
                     <Stack className='mission-item-attribute' direction='horizontal' halign='space-between' valign='center'>
                         <span className='bold uppercase small'>Altitude</span>
                         <EditData 
                            onSubmit={(altitude) => updateWaypoint(index, { altitude })} value={altitudes[altitudeDatum.toLowerCase()]} postfixText={altitudeDatum} type="float"/>
                         {/* <span>AGL</span> */}
                     </Stack>
                    
                     {/* <OutlineButton title='Remove waypoint' color="red" onClick={() => {removeWaypoint()}}/> */}
                 </Stack>
                </AccordionDetails>}
                {rallypoint && 
                    <AccordionDetails>
                        <Stack direction='vertical' halign='space-between' className="mission-item-content">
                        <span className="remove-rp" onClick={() => removeRallyPoint(index)}>Remove rallypoint</span>
                            <Stack className='mission-item-attribute' direction='horizontal' halign='space-between' valign='center'>
                                <span className='bold uppercase small'>Latitude</span>
                                <span className='bold uppercase small'>{missionItem.latitude}</span>
                                {/* <EditData onSubmit={(latitude) => updateWaypoint(index, { position: { latitude, longitude: missionItem.longitude } })} defaultValue={missionItem.latitude} value={missionItem.latitude} type="float"/> */}
                            </Stack>
                            <Stack className='mission-item-attribute' direction='horizontal' halign='space-between' valign='center'>
                                <span className='bold uppercase small'>Longitude</span>
                                <span className='bold uppercase small'>{missionItem.longitude}</span>
                                {/* <EditData onSubmit={(longitude) => updateWaypoint(index, { position: { latitude: missionItem.latitude, longitude } })} defaultValue={missionItem.longitude} value={missionItem.longitude} type="float"/> */}
                            </Stack>
                        </Stack>
                    </AccordionDetails>}
            </Accordion>

            { distance != null && heading != null && 
                <div className='mission-item-dst-hdg-wrap'>
                    <p>DST {distance >= 1000 ?
                                UtilityService.distanceToKilometers(distance)+" KM " : distance+" M " }
                                      | {UtilityService.distanceToNauticalMiles(distance)} NM | HDG {`${heading}`.padStart(3,'0')}</p>
                  
                </div>
            }
        </div>        
    )
}
import React, { useEffect, useRef, useState } from 'react';
import { Stack } from '../../../../shared-components/layout/stack/stack';
import Icons from '../../../../helpers/icons';

export const SecondaryDisplayFlightItem = ({ isSelected, flight, onClick }) => {
    const currentAircraftId = useRef(null);
    const [generalState, setGeneralState] = useState({});
    const [hudState, setHudState] = useState({});

    useEffect(() => {
        if (flight != null && currentAircraftId.current != flight.aircraftId) {
            setGeneralState(flight.generalState.getState());
            setHudState(flight.hudState.getState());

            flight.generalState.subscribe(() => {
                setGeneralState(flight.generalState.getState());
            });

            flight.hudState.subscribe(() => {
                setHudState(flight.hudState.getState());
            });
        }
    }, [flight]);

    return (
        <div className={`secondary-display-flight-item ${flight && 'active'} ${isSelected && 'selected'}`} style={{ ...(flight != null && { backgroundColor: flight.color }) }} onClick={() => flight != null && onClick && onClick(flight)}>
            {flight && <div className='secondary-display-flight-item-title-icon-wrap'>
                <div className='secondary-display-flight-icon-wrap'>
                    <img src={Icons.regular.aircraftMarkerIcon} alt='aircraft' style={{ width: "30px", height: "30px" }} />
                </div>
                <Stack direction='vertical'>
                    <span className='secondary-display-flight-item-aircraft-name'>{flight.aircraftName}</span>
                    <span className='secondary-display-flight-item-aircraft-status'> {(generalState && generalState.isConnected) ? 'Connected' : 'Not connected'}</span>
                    <span className='secondary-display-flight-item-aircraft-status'> {
                        generalState.isConnected ?
                            
                                generalState.isMissionUploaded ?
                                    generalState.customMode == 'RTL' ?
                                    'RTL'
                                    :
                                    generalState.isMissionInProgress ?
                                        'In flight'
                                        :
                                        'Preflight'
                                    :
                                    'Preflight'
                            :
                            'Preflight'}
                    </span>
                </Stack>
            </div>}
            {flight && <div className='secondary-display-flight-item-data'>
                <div className='secondary-display-flight-item-data-value-box'>
                    <span className='box-title'>ALT: </span>
                    <span>{hudState && hudState.altitude != null ? `${hudState.altitude.toFixed(1)} AMSL` : 'N/A'}</span>
                </div>
                <div className='secondary-display-flight-item-data-value-box'>
                    <span className='box-title'>SPD: </span>
                    <span>{hudState && hudState.airspeed != null ? `${hudState.airspeed.toFixed(1)} M/S` : 'N/A'}</span>
                </div>
                <div className='secondary-display-flight-item-data-value-box'>
                    <span className='box-title'>ttg: </span>
                    <span>N/A</span>
                </div>
            </div>}
        </div>
    )
}
import React, { useRef, useState } from "react";
import speedBar from '../../../../../../assets/cockpit-icons/speed-bar.svg';
import { Bars } from "./bars";

// class InfoScreen extends React.Component {
export const InfoScreen = ({roll, pitch, heading}) => {
	// constructor(props) {
	// 	super(props);

	// 	this.pitchBarValues = [];
		
	// 	for (let i = 180; i >= -180; i -= 2.5 ) {
	// 		this.pitchBarValues.push(i);
	// 	}

	// 	this.pitchBars = this.pitchBarValues.map(barValue => {
	// 		if (Math.round(barValue) != 0 && Math.round(barValue) % 10 == 0) {
	// 			return (<span data-pitch={barValue} className="pitch-bar-big"></span>);
	// 		} else if (Math.round(barValue) != 0 && Math.round(barValue) % 5 == 0) {
	// 			return (<span className="pitch-bar-medium"></span>);
	// 		} else if (Math.round(barValue) != 0) {
	// 			return (<span className="pitch-bar-small"></span>);
	// 		} else {
	// 			return (<span className="pitch-bar-zero"></span>);
	// 		}
	// 	});
	// }
	const pitchBarValues = [];
		
		for (let i = 180; i >= -180; i -= 2.5 ) {
			pitchBarValues.push(i);
		}

		let pitchBars = pitchBarValues.map((barValue,index) => {
			if (Math.round(barValue) != 0 && Math.round(barValue) % 10 == 0) {
				return (<span data-pitch={barValue} className="pitch-bar-big" key={`pitch-bar-big-${index}`}></span>);
			} else if (Math.round(barValue) != 0 && Math.round(barValue) % 5 == 0) {
				return (<span className="pitch-bar-medium" key={`pitch-bar-medium-${index}`}></span>);
			} else if (Math.round(barValue) != 0) {
				return (<span className="pitch-bar-small" key={`pitch-bar-small-${index}`}></span>);
			} else {
				return (<span className="pitch-bar-zero" key={`pitch-bar-zero-${index}`}></span>);
			}
		});
	// render() {
		// const { roll, pitch } = this.props;
		// const { pitchBars } = this;
		
		return (
			<div className="info-screen">
				<p className="heading-indicator">HDG { `${heading}`.padStart(3, '0') }</p>
				<div className="horizon-wrap">
					<div className="horizon" style={{ transform: `rotate(${-roll}deg) translateY(${pitch / 2.5 * 7}px)`}}>
						<div className="pitch-bars">
							{
								pitchBars
							}
						</div>
						<div className="sky"></div>
						<div className="ground"></div>
					</div>
					<div className="aircraft">
						<span className="left-bar"></span>
						<span className="dot"></span>
						<span className="right-bar"></span>
					</div>
				</div>
			</div>
		)
	
}

// class VerticalBar extends React.Component {
export const VerticalBar = ({side, min, max, stepSmall, stepBig, value, unit, formatedValue, onDatumChanged}) => {
	// constructor(props) {
	// 	super(props);

	// 	this.side = props.side;
	// 	const { min, max, stepSmall, stepBig } = props;
	// 	this.barValues = [];
	// 	this.stepSmall = stepSmall;
	// 	this.stepBig = stepBig;
	// 	for (let i = max; i >= min; i-= stepSmall) {
	// 		this.barValues.push(i);
	// 	}
	
	// 	this.bars = this.barValues.map((value, index) => {
	// 		return Math.floor((value / stepSmall)) % stepBig == 0 ?
	// 			<span data-before-content={Math.round(value)} className="bar-big"></span>
	// 			:
	// 			<span className="bar-small"></span>
	// 	})
		
	// }
	const barValues = [];
	const altitudeUnit = ["AMSL", "ALP", "AGL"];
	const airspeedUnit = ["m/s"];
	// const { side, bars, stepSmall, stepBig } = this;
	// const { value, unit, formatedValue } = this.props;
		
	// for (let i = Math.min(max, value + 3); i >= Math.max(min, value - 3); i-= stepSmall) {
	// 	barValues.push(i);
	// }

	// let bars = barValues.map((value, index) => {
	// 	return Math.floor((value / stepSmall)) % stepBig == 0 ?
	// 		<span key={`bar-${side}-${index}-${value}-big`} data-before-content={Math.round(value)} className="bar-big"></span>
	// 		:
	// 		<span key={`bar-${side}-${index}-${value}-small`} className="bar-small"></span>
	// })
	
	
		return (
			<div className={`vertical-bar side-${side == 'left' ? 'left' : 'right'}`}>
				{/* <p className="bar-unit">{unit}</p> */}
				<select 
					className='bar-unit needsclick'
					defaultValue={unit}
					onChange={(e) => onDatumChanged(e.target.value)}
				>
					{(side != 'left' ? altitudeUnit : airspeedUnit).map((mode, index)=>(
							<option key={`unit-${index}`} value={mode} className='uppercase'>{mode}</option>
						) )}
				</select>
				<div className="current-value">{formatedValue || value}</div>
				{/* <div className="bar-wrap" style={{ transform: `translateY(${((value / stepSmall)-3) * 3}px)` }}>
					<Bars key={`bars-${side}`} min={min} max={max} side={side} stepSmall={stepSmall} stepBig={stepBig} />
				</div> */}
			</div>
		)
	
}

// class PrimaryFlightDisplay extends React.Component {
export const PrimaryFlightDisplay = ({pitch, roll, altitude, relativeAltitude, rangefinderAltitude, airspeed, heading}) => {
	
	const [altitudeDatum, setAltitudeDatum] = useState("ALP");
	
	const airspeedFormated = airspeed.toFixed(1);
	const altitudeFormated = altitude.toFixed(1);

		return (
			<div className="primary-flight-display">
				<VerticalBar 
					side="left" 
					min={0} 
					max={20} 
					value={airspeed} 
					stepSmall={0.1} 
					formatedValue={airspeedFormated} 
					stepBig={10} 
					unit="m/s"/>
				<InfoScreen roll={roll} pitch={pitch} heading={heading}/>
				<VerticalBar 
					side="right" 
					min={0} 
					max={5000} 
					value={altitudeDatum == 'AMSL' ? altitude : (altitudeDatum == 'ALP' ? relativeAltitude : rangefinderAltitude)}
					stepSmall={1} 
					formatedValue={altitudeDatum == 'AMSL' ? altitude.toFixed(2) : (altitudeDatum == 'ALP' ? relativeAltitude.toFixed(2) : rangefinderAltitude)} 
					stepBig={10} 
					onDatumChanged={(value) => setAltitudeDatum(value)}
					unit={altitudeDatum}
				/>
			</div>
		)
}

// export default React.memo(PrimaryFlightDisplay);

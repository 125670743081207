import axios from "axios";
import React, { createContext, useContext, useEffect, useRef, useState } from "react";
import { AuthContext } from "./auth-context";
import { AuthContextV2 } from "./auth-context-v2";
import { ConfigContext } from "./config-context";

export const FileSystemContext = createContext();

const FileSystemContextProvider = ({ children }) => {
	const { getToken } = useContext(AuthContextV2);
	const { 
		services: { 
			missionPlanService: { 
				host,
				port,
				audience, 
				scopes: {
					getAllMissionPlans: getAllMissionPlansScope,
					saveMissionPlan: saveMissionPlansScope,
				}
			}
		}
	} = useContext(ConfigContext);

	const [missionFolders, setMissionFolders] = useState(null);
	const [allMissions, setAllMissions] = useState([]);
	const [isBusy, setIsBusy] = useState(false);
	const [error, setError] = useState(null);
	const isBusyFlag = useRef(false);

	const getFolders = async (entity='mission') => {
		isBusyFlag.current = true;
		setIsBusy(true);
		
		const token = getToken(getAllMissionPlansScope, audience);
		
		try {
			const res = await axios.get(`${host}:${port}/folders`, { params: {
				entity,
			}, headers: {
					Authorization: `Bearer ${token}`,
				}
			});

			if (entity == 'mission') {
				console.log(res.data);
				setAllMissions([].concat(...res.data.map(folder => folder.data.files)))
				setMissionFolders(res.data);
			}
		} catch(err) {
			console.log(err);
			setError(err.message);
		} finally {
			isBusyFlag.current = false;
			setIsBusy(false);
		}
	}

	const createFolder = async (entity, name) => {
		setIsBusy(true);
		const token = getToken(saveMissionPlansScope, audience);

		const res = await axios.post(`${host}:${port}/folders`, { entity, name }, { headers: {
			Authorization: `Bearer ${token}`,
		}});

		getFolders(entity);
		return res.data.data;
	}

	const deleteFile = async (entity, missionId) => {
		setIsBusy(true);
		const token = getToken(saveMissionPlansScope, audience);

		const res = await axios.put(`${host}:${port}/mission-plan/${missionId}`, { folderId: null }, { headers: {
			Authorization: `Bearer ${token}`,
		}});

		getFolders(entity);
		return res.data.data;
	};

	const deleteFolder = async (entity, folderId) => {
		setIsBusy(true);
		const token = getToken(saveMissionPlansScope, audience);

		const res = await axios.delete(`${host}:${port}/folders/${folderId}`, { headers: {
			Authorization: `Bearer ${token}`,
		}});

		getFolders(entity);
	}

	const renameFolder = async (entity, folderId, newName) => {
		setIsBusy(true);
		const token = getToken(saveMissionPlansScope, audience);

		const res = await axios.put(`${host}:${port}/folders/${folderId}`, { name: newName }, { headers: {
			Authorization: `Bearer ${token}`,
		}});

		getFolders(entity);
	}

	useEffect(() => {
		const token = getToken(getAllMissionPlansScope, audience);
		if (token != null && missionFolders == null && !isBusyFlag.current && !error) {
			isBusyFlag.current = true;
			getFolders('mission');
		}
	});

	

	return (
		<FileSystemContext.Provider value={{ 
				missionFolders, 
				createFolder, 
				deleteFolder, 
				deleteFile,
				renameFolder,
				getFolders,
				allMissions,
				isBusy }}>
			{ children }
		</FileSystemContext.Provider>
	)
}

export default FileSystemContextProvider;
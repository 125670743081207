import React from 'react';


export const TableFrame = ({className, tableHeader, actions, children}) => {

        return(
            <table className={`table-frame ${className || ''}`}>
                <thead className='table-frame-head'>
                    <tr>
                        {
                            actions && (<th style={{ width: 50 }}>Actions</th>) 
                        }
                        {tableHeader.map((colTitle, index) => (
                            <th key={`table-header-col-${index}`}>{colTitle}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {children}
                    
                </tbody>
            </table>
        );
    
}

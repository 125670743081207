import React, {useState} from "react";
import { EditInfoButtons } from "../../../../shared-components/buttons/edit-info-buttons/edit-info-buttons";
import { SkyModal } from "../../../../shared-components/containers/sky-modal/sky-modal";


export const FleetManagementActionButtons = ({disableInputs, editInfo, removeItem, labelText, sendInfo,}) =>{
        const [areYouSure, setAreYouSure] = useState(false);


        return(
            <div className='fm-details-buttons-wrap'>
                <EditInfoButtons 
                    disableInputs={disableInputs}
                    editInfo={()=>editInfo()}
                    actionLabel={labelText}
                    sendInfo={()=>sendInfo()}
                    ></EditInfoButtons>
                 <button 
                    className="error"
                    onClick={() => setAreYouSure(true)}
                    >Remove</button> 
                <SkyModal
                        className='modal-removeitem'
                        title="Remove Item"
                        modalVisible={areYouSure}
                        onClose={() => setAreYouSure(false)}
                        actionButtons= {[{
                            	title: "Remove", 
                            	action: ()=>removeItem(),
                            	btnType:"error",
                                position:'right'
                            },{
                                	title: "Cancel", 
                                	action: () => setAreYouSure(false),
                                	btnType:"secondary",
                
                                }]}
                    >
                        <div className="modal-removeitem-content">

                            <h3>Are you sure?</h3>
                            
                        </div>
                    </SkyModal>
                </div>
        );
    
}

import { Article, Timeline } from "@mui/icons-material"
import { useState } from "react"
import { Panel } from "../../../../shared-components/layout/panel/panel"
import Icons from "../../../../helpers/icons";
// import { FlightPathPanel } from "../mission-plan-toolbar/components/flight-path-panel/flight-path-panel";
// import { MissionWaypointTabContent } from "../mission-waypoint-tab-content/mission-waypoint-tab-content";
import { WaypointPanel } from "../waypoint-panel/waypoint-panel";
import { RallypointsPanel } from "../rallypoints-panel/rallypoints-panel";
import useWindowDimensions from "../../../../hooks/useWindowDimensions";
import { motion } from "framer-motion";

export const MissionPlanPanel = ({panelClass}) =>{
    const [activeTab, setActiveTab] = useState('waypoints');
    
    const { width } = useWindowDimensions();
    
    const [fullWidth, setFullWidth] = useState(width > 700);
    const [expanded, setExpanded] = useState(true);
    return (
        <motion.div 
        key="mission-plan-panel-motion"
        className="mission-plan-panel"
        initial={{  x:300, zIndex:1}} 
        exit={{ x: 300, zIndex:1 }} 
        animate={{ x:0, zIndex:1  }}
        transition={{ duration: 0.4,  ease: [0.130, 0.850, 0.375, 0.960] }}
    >
        
            <Panel 
                className={`${!fullWidth && !expanded  && 'icon-only'} ${panelClass && panelClass}` }
                title={"Mission plan"}
                icon={<Timeline/>}
                backgroundColor="#060d1f"
                expanded={expanded}
            >

                <div className="mission-plan-panel-tabs">
                    <div className="mission-plan-panel-tab-header">
                        <div 
                            className={`mission-plan-panel-tab ${activeTab === 'waypoints' ? 'active' : ''}`}
                            onClick={() => setActiveTab('waypoints')}
                        >
                            <span className="tab-icon">
                                <img src={Icons.regular.waypointIconOutline}/>
                            </span>
                            waypoints
                        </div>
                        <div 
                            className={`mission-plan-panel-tab ${activeTab === 'rallypoints' ? 'active' : ''}`}
                            onClick={() => setActiveTab('rallypoints')}
                        >
                            <span className="tab-icon">
                                <img src={Icons.regular.rtlIcon}/>
                            </span>
                            Rally points
                        </div>
                    </div>

                    <div className="selected-tab">
                        { activeTab === 'waypoints' && <WaypointPanel activeTab={activeTab}/> }
                        { activeTab === 'rallypoints' && <RallypointsPanel activeTab={activeTab}/> }
                    </div>
                </div>
            </Panel>
       
        </motion.div>
    )
}
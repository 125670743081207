import React, { createContext, useState } from "react";
import rawConfig from "../config";

export const ConfigContext = createContext();

const ConfigContextProvider = ({ children }) => {
	const toggleLogs = () => {
		config.logs = !config.logs;
		setConfig({...config});
	}

	const [config, setConfig] = useState({ ...rawConfig, toggleLogs });

	return (
		<ConfigContext.Provider value={config}>
			{ children }
		</ConfigContext.Provider>
	)
}

export default ConfigContextProvider;
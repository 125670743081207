import React, { useContext, useEffect, useRef, useState } from 'react';
import { OutlineButton } from '../../../../../../shared-components/buttons/outline-button/outline-button';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { ArrowLeft, Check, ChevronRight, Delete, Edit, ExpandMore } from '@mui/icons-material';
import { TreeView, TreeItem } from '@mui/lab';
import { WaypointsLibraryContext } from '../../../../../../contexts/waypoints-library-context';
import { InputFieldV2 } from '../../../../../../shared-components/inputs/input-field-v2/input-field-v2';
import { InputField } from '../../../../../../shared-components/inputs/input-field/input-field';
import { IconButton, Input } from '@mui/material';
import { WaypointsLibraryItem } from './waypoints-library-item/waypoints-library-item';
import { Loading } from '../../../../../../shared-components/layout/loading/loading';

export const WaypointsLibrary = ({saveMode=false}) => {
    const [selectedFolder, setSelectedFolder] = useState(false);
    const [WPsVisible, setWPsVisible] = useState(false);
    const [selectedWP, setSelectedWP] = useState(false);

    const [isEditable, setIsEditable] = useState(false);
    const [createNewFolderVisible, setCreateNewFolderVisible] = useState(false);
    const newFolderName = useRef(null);
    const folderRef = useRef(null);

    const {allFolders, removeWaypoint, removeFolder, updateWaypoint, updateFolder, createFolder, isBusy, currentLevel, setCurrentLevel} = useContext(WaypointsLibraryContext);
    const [libraryVisible, setLibraryVisible] = useState(false);
    const [expanded, setExpanded] = useState([]);

	useEffect(() => {
		setExpanded([currentLevel]);
	},[currentLevel])
    
    useEffect(() => {
        // console.log(allFolders);
        if(allFolders) {
            setLibraryVisible(true);
        }
        
    }, [allFolders]);

    const onEditFolder = (id) =>{
        setIsEditable(id);
        console.log("ID",id);
    }
   
    const handleCreateNewFolder = () => {
        const folderData = {
            "title": newFolderName.current.value,
            "data":{}
        }
        createFolder(folderData);
        setCreateNewFolderVisible(false);
    }
    const handleRemoveFolder = (id) => {
        if(window.confirm("Are you sure you want to delete folder and all it's waypoints?")) { 
            removeFolder(id);
        }
    }
    const handleUpdateFolder = (id, data) => {
        if(data != null && data != "") {
            updateFolder(id, data);
            setIsEditable(false);
        }
       
    }
    return(
        <div className={`waypoints-library-wrap ${createNewFolderVisible && 'add-new-folder-visible'}`}>
            <p className='title'>Waypoints Library</p>
            <div className='waypoints-library'>
                {libraryVisible && !isBusy &&
                 (allFolders.length > 0 ? <TreeView
                    aria-label="file system navigator"
                    defaultCollapseIcon={<ExpandMore />}
                    defaultExpandIcon={<ChevronRight />}
                    sx={{ height: 320, flexGrow: 1, maxWidth: 270, overflowY: 'auto', fontSize:'12px' }}
                    // expanded={expanded}/
                    >
                    {allFolders.map((folder, index) => (
                         <TreeItem 
                            nodeId={folder.id} 
                            key={`folder-${index}`}
                            label={
                            <div className='library-folder'>
                                <div className='library-folder-name'>
                                {isEditable === folder.id ? 
                                     <input className='library-input' defaultValue={folder.title} ref={folderRef}/> : 
                                     folder.title
                                }
                                </div>
                                <div className='library-folder-actions'>
                                    {isEditable && isEditable === folder.id  ? 
                                    <Check className='green' onClick={()=> handleUpdateFolder(folder.id, folderRef.current.value)}/>:
                                        <Edit onClick={(e) =>{e.preventDefault(); onEditFolder(folder.id)}} className='library-folder-action-icon'/> 
                                        
                                    }
                                    
                                    {/* <IconButton aria-label="delete" size='small'> */}
                                        <Delete onClick= {(e) =>{e.preventDefault();handleRemoveFolder(folder.id);}} className='library-folder-action-icon delete'/>
                                    {/* </IconButton> */}
                                </div>
                            </div>
                            }
                            onClick= {(e) =>{e.stopPropagation();}}>
                                 {/* <TreeItem nodeId={`${folder.id}-takeoff`} label={"Take Off Points"}>
                                {folder.data.waypoints.filter(el => el.type == "initial").map((wp, index) => (
                                   <WaypointsLibraryItem nodeId={`${folder.id}-takeoff-${index}`} wp={wp} />
                                ))}
                                </TreeItem> */}
                                {/* <TreeItem nodeId={`${folder.id}-midpoint`} label={"Midpoints"}>
                                {folder.data.waypoints.filter(el => el.type == "midpoint").map((wp, index) => (
                                   <WaypointsLibraryItem nodeId={`${folder.id}-midpoint-${index}`} wp={wp} />
                                ))}
                                </TreeItem> */}
                               
                                <WaypointsLibraryItem 
                                    waypoints={folder.data.waypoints.filter(el => el.type == 'takeoff')} 
                                    folder={folder.title} 
                                    title={"Take Off Points"} 
                                    type={"takeoff"}  
                                    visible={folder.data.waypoints.filter(el => el.type == 'takeoff').length > 0}/>
                                <WaypointsLibraryItem 
                                    waypoints={folder.data.waypoints.filter(el => el.type == 'midpoint')} 
                                    folder={folder.title} 
                                    title={"Midpoints"} 
                                    type={"midpoint"}
                                    visible={folder.data.waypoints.filter(el => el.type == 'midpoint').length > 0}/>
                                <WaypointsLibraryItem 
                                    waypoints={folder.data.waypoints.filter(el => el.type == 'landing')} 
                                    folder={folder.title} 
                                    title={"Landing Points"} 
                                    type={"landing"} 
                                    visible={folder.data.waypoints.filter(el => el.type == 'landing').length > 0} />
                         </TreeItem>
                    ))}
                   
                </TreeView> : <span className='no-folders'>No available folders</span>)
                }
               
                {isBusy && <Loading/>}
                {/* </PerfectScrollbar> */}
            </div>
            <div className='waypoints-library-new-folder'>
                <OutlineButton title='Create new folder' onClick={() =>setCreateNewFolderVisible(true)} className='create-new-folder-button'/>
                {createNewFolderVisible && 
                    <div className='new-folder-input-box'>
                        <input className='library-input' placeholder={"Insert folder name"} ref={newFolderName}/>
                       
                        <span className='uppercase green' onClick={() => handleCreateNewFolder()}>save</span>
                        <span className='uppercase' onClick={() => setCreateNewFolderVisible(false)}>cancel</span>
                    </div>}
            </div>
        </div>
    )
}
import React, { useState, useEffect } from "react";
import PerfectScrollbar from 'react-perfect-scrollbar';
import { TableFrame } from "../../../../shared-components/containers/table-frame/table-frame";
import {StatusTokenLabel} from "../status-token-label/status-token-label";
import {FleetManagementEditModal} from "../fleet-management-edit-modal/fleet-management-edit-modal";
import { Loading } from "../../../../shared-components/layout/loading/loading";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import UtilityService from "../../services/utility-service";
import UtilityService from "../../../../helpers/utility-service";
import { DataGrid } from "@mui/x-data-grid";
import { TableRow } from "@mui/material";

export const FleetManagementTableContent = (
    {schema, 
    // loadData,
    entityName, 
    additionalTabs, 
    filter, 
    filterFunction, 
    getTitle,
    data=[],
    }) => {
   
    const [selectedItem, setSelectedItem] = useState(null);
    const [editModalVisible, setEditModalVisible] = useState(false);
    const [showLoader, setShowLoader] = useState(false);

    // useEffect(() => {
    // }, [data])
    
    // { id: 1, lastName: 'Snow', firstName: 'Jon', age: 35 },
        
        const columns = ((schema.attributes || []).filter(att => !att.hidden && !att.tableHidden && !['remote-table', 'table', 'image'].includes(att.type)) || []).map(att => 
            {
                // console.log("COLUMNS", att.key);
            return(
                {
                field: att.key,
                headerName: att.name,
                type: att.type,
                flex:1,
                renderCell:  (params) => att.key === 'cycles' ? 
                                <StatusTokenLabel label = {params.row[att.key]} color={() => UtilityService.calcCycleColor(parseInt(params.row[att.key]))}/>
                                :
                            att.type === 'remote-select' ?
                            params.row[`${att.key}-displayValue`]
                            :
                            att.type === 'file' ?
                            <a onClick={(e) => e.stopPropagation()} href={params.row[att.key]}>Link</a>
                            :
                            att.type === 'list' ?
                            <p>{params.row[att.key] ? params.row[att.key].length : 0} item(s)</p>
                            :
                            params.row[att.key] 
                })
            });
            
        const rows = (data || []).map(el => UtilityService.transformObject(el, schema.attributes)).filter((row) => filterFunction(row, filter))

        // schema.attributes.filter(att => !att.hidden && !att.tableHidden && !['remote-table', 'table', 'image'].includes(att.type)).map((att, fieldIndex) => (
        //         {
        //             att.key === 'cycles' ? 
        //                 <StatusTokenLabel label = {row[att.key]} color={() => UtilityService.calcCycleColor(parseInt(row[att.key]))}/>
        //                 :
        //             att.type === 'remote-select' ?
        //             row[`${att.key}-displayValue`]
        //             :
        //             att.type === 'file' ?
        //             <a onClick={(e) => e.stopPropagation()} href={row[att.key]}>Link</a>
        //             :
        //             att.type === 'list' ?
        //             <p>{row[att.key] ? row[att.key].length : 0} item(s)</p>
        //             :
        //             row[att.key] 
        //         }
        // ))
       
      
        // console.log("ROWS",rows)
        return (
            // <PerfectScrollbar options={{wheelPropagation: false}}>
            <div className="fm-table-content-wrap">
                { showLoader && <Loading/> } 
                    <div className='fleet-management-table-content'>
                        {/* <PerfectScrollbar> */} 
                            <DataGrid
                                className="fleet-management-table-content"
                                style={{height:"97%", minHeight:"200px", width: "100%", color: "white", borderRadius:"10px"}}
                                rows={rows}
                                columns={columns}
                                pageSize={15}
                                rowsPerPageOptions={[15]}
                                onRowClick={(row)=>{setSelectedItem(row.row);  setEditModalVisible(true);}} 
                                // checkboxSelecion
                            />
                            {/* <TableFrame tableHeader={((schema.attributes || []).filter(att => !att.hidden && !att.tableHidden && !['remote-table', 'table', 'image'].includes(att.type)) || []).map(att => att.name)}>
                                {
                                    (data || []).map(el => UtilityService.transformObject(el, schema.attributes)).filter((row) => filterFunction(row, filter)).map((row, rowIndex) => (
                                        <tr key={Math.random()} onClick={()=>{setSelectedItem(row); setEditModalVisible(true);}} className='pointer'>
                                        {
                                            schema.attributes.filter(att => !att.hidden && !att.tableHidden && !['remote-table', 'table', 'image'].includes(att.type)).map((att, fieldIndex) => (
                                                <td key={`${schema.entityName}-row-${rowIndex}-${fieldIndex}`}>
                                                    {
                                                        att.key === 'cycles' ? 
                                                            <StatusTokenLabel label = {row[att.key]} color={() => UtilityService.calcCycleColor(parseInt(row[att.key]))}/>
                                                            :
                                                        att.type === 'remote-select' ?
                                                        row[`${att.key}-displayValue`]
                                                        :
                                                        att.type === 'file' ?
                                                        <a onClick={(e) => e.stopPropagation()} href={row[att.key]}>Link</a>
                                                        :
                                                        att.type === 'list' ?
                                                        <p>{row[att.key] ? row[att.key].length : 0} item(s)</p>
                                                        :
                                                        row[att.key] 
                                                    }
                                                </td>
                                            ))
                                        }
                                        </tr>
                                    ))
                                }                               
                            </TableFrame> */}
                        {/* </PerfectScrollbar>     */}
                    </div>

                    { selectedItem &&
                        <FleetManagementEditModal
                            modalVisible={editModalVisible}
                            schema={schema}
                            data={selectedItem}
                            itemId={selectedItem.id}
                            onClose={() => {setEditModalVisible(false); setSelectedItem(null)}}
                            loadSingleItem={() => selectedItem}
                            title={getTitle(selectedItem)}
                            entityName={schema.entityName}
                            transformObject={(obj) => UtilityService.transformObject(obj, schema.attributes)}
                            additionalTabs={additionalTabs || []}
                        />
                    }
            </div>
            // </PerfectScrollbar>
        );
    }
